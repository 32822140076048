<template>
  <div>
    <div>  <!-- ADD ASS NOTES -->
      <form>
        <div class="row" v-if="loadingPreData">
          <div class="col-md-12 text-center loader-color">
              <b>Please wait while system is loading Tariff details</b>
          </div>
          <div class="col-md-12 text-center">
              <br>
          </div>
          <div class="col-md-12 text-center">
              <pulse-loader
              :color="color"
              :size="size"
              />
          </div>
        </div>
        <!-- notifications -->
        <div class="row" v-if="!loadingPreData">
          <div class="col-md-10" v-if="assesment === null">
              Please fill in below form to place Tariff Details
          </div>
          <div class="col-md-10" v-if="isAssementAvailable && edit">
              Please fill in below form to update Tariff Details
          </div>
          <div class="col-md-10" v-if="isAssementAvailable && !edit">
              Tariff Details
          </div>
          <div class="col-md-2 text-right" v-if="statusProp === cfaStatuses.REJECTED_ASSESSMENT">
            <button class="btn btn-primary btn-space mr-2 custom-btn" v-if="isAssementAvailable"
                      data-dismiss="modal" @click.prevent="enableEdit">
                {{ !edit ? 'Edit' : 'Cancel Edit' }}
              </button>
          </div>
        </div>
        <br />
        <card v-if="!loadingPreData">
          <div class="row">
            <!-- assessment type-->
            <!-- <div class="col-md-4">
                <base-input v-model="assessmentType.name" label="Assessment Type" placeholder="Assessment Type"
                          type="text" class="special-bottom" :disabled="true"></base-input>
            </div> -->
            <!-- Tansad Number-->
            <div class="col-md-4 pt-1" v-if="assessmentType !== {} && !assessmentType.id">
              <div class="row">
                <div class="col-md-12" style="font-weight: 400;color: black !important;">
                  TANSAD NUMBER ( FORMAT: XXXX-XX-XXXXXXX )
                </div>
              </div>
              <div class="row">
                <div class="col-md-3"><base-input class="special-bottom" maxlength="4"
                  @input="shiftCursor('fourDigit' , 'twoDigit')" id="fourDigit"
                  type="text" v-model="TANSADNumber4" :disabled="(isAssementAvailable && edit === false) || assessmentType === {}"></base-input>
                </div>
                <div class="col-md-1" style="font-size:29px;margin-top:-9px;">-</div>
                <div class="col-md-2"><base-input class="special-bottom" maxlength="2"
                  @input="shiftCursor('twoDigit' , 'sevenDigit')" id="twoDigit"
                  type="text" v-model="TANSADNumber2" :disabled="(isAssementAvailable && edit === false) || assessmentType === {}"></base-input>
                </div>
                <div class="col-md-1" style="font-size:29px;margin-top:-9px;">-</div>
                <div class="col-md-5"><base-input class="special-bottom" maxlength="7"
                  @input="shiftCursor('sevenDigit' , 'tarrifItem')" id="sevenDigit"
                  type="text" v-model="TANSADNumber7" :disabled="(isAssementAvailable && edit === false) || assessmentType === {}"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div v-if="TANSADNumberError">
                    <div class="error" v-if="!$v.TANSADNumber4.required">Required</div>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <div v-if="TANSADNumberError">
                    <div class="error" v-if="!$v.TANSADNumber2.required">Required</div>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-5">
                  <div v-if="TANSADNumberError">
                    <div class="error" v-if="!$v.TANSADNumber7.required">Required</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-if="TANSADNumberFormatError" class="col-md-12">
                  <div class="error">Invalid TANSAD Number Format</div>
                </div>
              </div>
            </div >

            <div class="col-md-4 pt-1" v-if="assessmentType !== {} && assessmentType.id">
              <div class="row">
                <div class="col-md-12" style="font-weight: 400;color: black !important;">
                  BT NUMBER ( FORMAT: XXXXXXXXX-XXXX-T-XXXXXXX )
                </div>
              </div>
              <div class="row">
                <div class="col-md-3"><base-input class="special-bottom" maxlength="9"
                  @input="shiftCursor('bnineDigit' , 'bfourDigit')" id="bnineDigit"
                  type="text" v-model="btNumber9" :disabled="true"></base-input>
                </div>
                <div class="col-md-1" style="font-size:29px;margin-top:-9px;">-</div>
                <div class="col-md-2"><base-input class="special-bottom" maxlength="4"
                  @input="shiftCursor('bfourDigit' , 'bsevenDigit')" id="bfourDigit"
                  type="text" v-model="btNumber4" :disabled="(isAssementAvailable && edit === false) || assessmentType === {}"></base-input>
                </div>
                <div class="col-md-1" style="font-size:29px;margin-top:-9px;">-</div>
                <div class="col-md-1" style="font-size:23px;margin-top:-3px;color:grey;">T</div>
                <div class="col-md-1" style="font-size:29px;margin-top:-9px;">-</div>
                <div class="col-md-3"><base-input class="special-bottom" maxlength="7"
                  @input="shiftCursor('bsevenDigit' , 'tarrifItem')" id="bsevenDigit"
                  type="text" v-model="btNumber7" :disabled="(isAssementAvailable && edit === false) || assessmentType === {}"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div v-if="btNumberError">
                    <div class="error" v-if="!$v.btNumber9.required">Required</div>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <div v-if="btNumberError">
                    <div class="error" v-if="!$v.btNumber4.required">Required</div>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-5">
                  <div v-if="btNumberError">
                    <div class="error" v-if="!$v.btNumber7.required">Required</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-if="btNumberFormatError" class="col-md-12">
                  <div class="error">Invalid TANSAD Number Format</div>
                </div>
              </div>
            </div >

            <!-- is multiple -->
            <div class="col-md-4">
              <label>Multiple Tariff</label>
              <select class="form-control" v-model="multipleTariffs" :disabled="(isAssementAvailable && edit === false) || assessmentType === {}" @change="filterTariffs">
                <option :value="true">Yes</option>
                <option :value="false" selected>No</option>
              </select>
            </div>

            <!-- btNumber -->
            <!-- <div class="col-md-4" v-if="assessmentType !== {} && assessmentType.id">
              <base-input v-model="btNumber" label="BT Number" placeholder="BT Number"
                          type="text" class="special-bottom" :disabled="(isAssementAvailable && edit === false) || assessmentType === {}"></base-input>
              <div v-if="btNumberError">
                <div class="error" v-if="!$v.btNumber.required">This field is required</div>
              </div>
            </div> -->
          </div>
          <!-- select tariff -->
          <div class="row">
            <div class="col-md-8">
              <label class="typo__label" v-if="filteredTariffItems.length > 0">Search Tariff Items</label>
              <label class="typo__label" v-else>
                  <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                  Loading tariff items, please wait
              </label>
              <list-select
              :list="filteredTariffItems"
              :isDisabled="(isAssementAvailable && edit === false) || assessmentType === {} || filteredTariffItems.length <= 0"
              id="tarrifItem"
              class="form-control"
              :selected-item="selectedTariffItem"
              option-value="tariffItemId"
              option-text="tariffItemName"
              placeholder="search for tariff item"
              @select="populateSelectedTariffItem"
              />
              <div v-if="tariffItemNameError">
                <div class="error" v-if="!$v.tariffItemId.required">This field is required</div>
              </div>
            </div>
            <!-- custom value -->
            <div class="col-md-4">
              <base-input v-model="customValue" @input="commafyThisAmountWithDecimal('customValue')"
              label="Customs Value (TZS)" placeholder="Customs Value (TZS)" type="text" class="special-bottom"
              :disabled="(isAssementAvailable && edit === false) || assessmentType === {}">
              </base-input>
              <div v-if="customValueError">
                <div class="error" v-if="!$v.customValue.required">This field is required</div>
              </div>
              <div v-if="customValueError2">
                <div class="error">Invalid Amount</div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <!--excange rate-->
            <!-- <div class="col-md-3">
              <base-input v-model="AnExchangeRate" @input="commafyThisAmountWithDecimal('AnExchangeRate')"
              label="Exchange Rate" placeholder="Exchange Rate" type="text" class="special-bottom"
              :disabled="(isAssementAvailable && edit === false) || assessmentType === {}">
              </base-input>
              <div v-if="AnExchangeRateError">
                <div class="error" v-if="!$v.AnExchangeRate.required">This field is required</div>
              </div>
              <div v-if="AnExchangeRateError2">
                <div class="error">Invalid Amount</div>
              </div>
            </div> -->
            <!--currency-->
            <!-- <div class="col-md-6">
              <label class="typo__label" v-if="currenciesSearch.length > 0">Search Assessment Currency</label>
              <label class="typo__label" v-else>
                  <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                  Loading assessment currency, please wait
              </label>
              <list-select
              :list="currenciesSearch"
              :isDisabled="(isAssementAvailable && edit === false) || assessmentType === {} || currenciesSearch.length <= 0"
              class="form-control"
              :selected-item="selectedCurrency"
              option-value="currencyId"
              option-text="currencyName"
              placeholder="search for assessment currency"
              @select="populateSelectedCurrency"
              />
              <div v-if="currencyError">
                <div class="error" v-if="!$v.currencyId.required">This field is required</div>
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="col-md-4">
              <base-input v-model="vesselName" label="Vessel Name" placeholder="Vessel Name"
                          type="text" class="special-bottom" :disabled="(isAssementAvailable && edit === false) || (fieldFactor !== 'cargoVolume'
                          && specialGasLiquedfied !== 'gas liquefied') || assessmentType === {}"></base-input>
              <div v-if="vesselNameError">
                <div class="error" v-if="!$v.vesselName.required">This field is required</div>
              </div>
            </div>
            <!-- cargo cbm -->
            <div class="col-md-4">
              <base-input v-model="cargoCbm" @input="commafyThisAmountWithDecimal('cargoCBM')"
              label="Cargo CBM" placeholder="Cargo CBM" type="text" class="special-bottom"
              :disabled="(isAssementAvailable && edit === false) || fieldFactor !== 'cargoCBM' || assessmentType === {}">
              </base-input>
              <div v-if="cargoCbmError">
                <div class="error" v-if="!$v.cargoCbm.required">This field is required</div>
              </div>
              <div v-if="notCargoCbm">
                <div class="error">Invalid Cargo CBM</div>
              </div>
            </div>
            <!-- cargo tonnage -->
            <div class="col-md-4">
              <base-input v-model="cargoTonnage" @input="commafyThisAmountWithDecimal('cargoTonnage')"
              label="Metric Tonne" placeholder="Metric Tonne" type="text" class="special-bottom"
              :disabled="(isAssementAvailable && edit === false) || fieldFactor !== 'cargoTonnage' || assessmentType === {}">
              </base-input>
              <div v-if="cargoTonnageError">
                <div class="error" v-if="!$v.cargoTonnage.required">This field is required</div>
              </div>
              <div v-if="notCargoTonnage">
                <div class="error">Invalid Metric Tonne</div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- cargo quantity -->
            <div class="col-md-4">
              <base-input v-model="AnQuantity" @input="commafyThisAmountWithDecimal('quantity')" onpaste="return false;"
              :label="labelText" :placeholder="labelText" type="text" class="special-bottom"
              :disabled="(isAssementAvailable && edit === false) || fieldFactor !== 'quantity' || assessmentType === {}">
              </base-input>
              <div v-if="AnQuantityError">
                <div class="error" v-if="!$v.AnQuantity.required">This field is required</div>
              </div>
              <div v-if="notAnQuantity">
                <div class="error">Invalid Quantity</div>
              </div>
            </div>
            <!-- cargo volume -->
            <div class="col-md-4">
              <base-input v-model="cargoVolume" @input="commafyThisAmountWithDecimal('cargoVolume')"
              label="Litres" placeholder="Litres" type="text" class="special-bottom"
              :disabled="(isAssementAvailable && edit === false) || fieldFactor !== 'cargoVolume' || assessmentType === {}">
              </base-input>
              <div v-if="cargoVolumeError">
                <div class="error" v-if="!$v.cargoVolume.required">This field is required</div>
              </div>
              <div v-if="notCargoVolume">
                <div class="error">Invalid Litres</div>
              </div>
            </div>
            <!-- cargo invoice number -->
<!--            <div class="col-md-4">-->
<!--              <base-input v-model="invoiceNumberAss" label="Invoice Number" placeholder="Invoice Number"-->
<!--                          type="text" class="special-bottom" :disabled="(isAssementAvailable && edit === false) || assessmentType === {}"></base-input>-->
<!--              <div v-if="invoiceNumberError">-->
<!--                <div class="error" v-if="!$v.invoiceNumberAss.required">This field is required</div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="row" v-if="multipleTariffs">
            <div class="col-md-8">
              <label class="typo__label" v-if="filteredTariffItems.length > 0">Search Tariff Items for Additional Container</label>
              <label class="typo__label" v-else>
                  <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                  Loading tariff items, please wait
              </label>
              <list-select
                :list="filteredTariffItems"
                id="tarrifItem2"
                class="form-control"
                :isDisabled="(isAssementAvailable && edit === false) || assessmentType === {} || filteredTariffItems.length <= 0"
                :selected-item="selectedTariffItemAdditionalObject"
                option-value="tariffItemId"
                option-text="tariffItemName"
                placeholder="search for tariff item"
                @select="populateSelectedTariffItemAdditionalTarrif"
              />
              <div v-if="selectedTariffItemAdditionalError">
                <div
                  class="error text-danger"
                  v-if="!$v.selectedTariffItemAdditional.required"
                >
                  This field is required
                </div>
              </div>

            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="quantityAdd">Container Quantity</label>
                <input type="number" class="form-control" placeholder="Container Quantity" id="quantityAdd" v-model="quantityAdditional" :disabled="(isAssementAvailable && edit === false) || assessmentType === {}">
                <div v-if="quantityAdditionalError">
                  <div
                    class="error text-danger"
                    v-if="!$v.quantityAdditional.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- reject messages -->
          <div class="row" v-if="isAssementAvailable" >
            <div class="col-md-12 mt-4" v-if="assesment.rejectMessages != null && assesment.rejectMessages.length > 0">
              <data-table
                :tableHeaders="tableHeaders"
                :tableData="filteredRequests"
                :tableDataKeys="tableDataKeys"
                :hasFilter="false"
                :hasPagination="false"
                :isPageable="false"
                :noDataFound="noDataFound"
                :permissions="[ {key: 'searchPermission', value: 'CFAREPORT_ASYNC_REPORT_GET'} ]">
              </data-table>

            </div>
          </div>
          <hr />
          <!-- Save assessment and close modal -->
          <div class="text-center row">
            <div class="col-md-10 text-right loader-div-gif pt-2">
              <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
            </div>
            <div class="col-md-2 text-right">

              <button type="submit" class="btn btn-primary btn-space mr-2 custom-btn" :disabled="loading"
                      data-dismiss="modal" @click.prevent="saveAssessmentNotice" v-if="!isAssementAvailable && edit === false && assessmentType !== {}">
                Save
              </button>
              <button type="submit" class="btn btn-primary btn-space mr-2 custom-btn" :disabled="loading"
                      data-dismiss="modal" @click.prevent="saveAssessmentNotice" v-if="isAssementAvailable && edit === true && assessmentType !== {}">
                Save
              </button>

              <button class="btn btn-default btn-fill custom-btn-all" @click.prevent="closeModal()"  :disabled="loading">Close</button>
            </div>
          </div>
        </card>
      </form>
    </div>
  </div>

</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import API from "../../../api";
    import API_REVENUE from '../../../api/rev'
    import { ListSelect } from 'vue-search-select'
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import Modal from "../../../components/Inputs/Modal";
    import ModalPace from "../../../components/Inputs/ModalPlanAndCostEstimation";
    import {CFA_REQUEST_STATUSE, TASAC_TIN} from "../../../mixins/constants";


    export default {

        name: "assessment",

      components:{

        PulseLoader,
        Modal,
        ModalPace,
        ListSelect,
        DataTable: () => import('../../../components/DataTable/dataTable'),


      },

      props:['requestId','statusProp', 'regime'],


      validations:{

        tariffItemId: {required},
        customValue: {required},
        // AnExchangeRate: {required},
        cargoVolume:{required},
        AnQuantity: { required },
        cargoTonnage: { required },
        TANSADNumber4: {required},
        TANSADNumber2: {required},
        TANSADNumber7: {required},
        btNumber9: { required },
        btNumber4: { required },
        btNumber7: { required },
        //invoiceNumberAss: {required},
        // currencyId: {required},
        btNumber: {required},
        vesselName: {required},
        quantityAdditional:{required},
        selectedTariffItemAdditional:{required},
        currency: {

          currencyId: {required}

        },

      },

      created() {

          this.getAssesmentByRequestId()
          this.loadTariffItems()
          this.loadIsBt();

      },

      data () {

          return {
            tableHeaders: ['Rejected By', 'Date', 'Message'],
            tableDataKeys: ['createdBy', 'createdDate', 'rejectMessage'],
            filteredRequests:[],
            assesment: null,
            isAssementAvailable:false,
            currencies:[],
            showAssementModal:false,
            edit:false,
            cfaStatuses:CFA_REQUEST_STATUSE,
            color: 'green',
            size: '12px',
            size1: '9px',
            loading: false,
            rejectMessage: '',
            labelText: 'Unit',
            noDataFound:false,

            tariffItemId:"",
            assessmentType: {},
            btNumber:"",
            btNumberError:false,
            assessmentTypes:[
              {name: "NORMAL ASSESSMENT", id: false},
              {name: "BT ASSESSMENT", id: true},
            ],
            tariffItemNameError:false,
            tariffItems:[],
            filteredTariffItems: [],
            AnExchangeRate: null,
            AnExchangeRateError:false,
            AnExchangeRateError2:false,
            currenciesSearch:[],
            currencyId: null,
            currencyError:false,
            TANSADNumber4:"",
            TANSADNumber2:"",
            TANSADNumber7:"",
            btNumber9: TASAC_TIN,
            btNumber4: new Date().getFullYear(),
            btNumber7: '',
            TANSADNumberError:false,
            TANSADNumberFormatError:false,
            btNumberError:false,
            btNumberFormatError:false,
            customValue:"",
            customValueError:false,
            customValueError2:false,
            cargoCbm:"",
            cargoCbmError:false,
            notCargoCbm:false,
            cargoTonnage:"",
            cargoTonnageError:false,
            notCargoTonnage:false,
            AnQuantity:"",
            AnQuantityError:false,
            notAnQuantity:false,
            cargoVolume:"",
            cargoVolumeError:false,
            notCargoVolume:false,
            //invoiceNumberAss:"",
            invoiceNumberError:false,
            vesselNameError: false,
            assessmentNoticeId:null,
            selectedTariffItem: {},
            selectedTariffItemAdditionalObject: {},
            selectedTariffItemAdditional:"",
            quantityAdditional:"",
            selectedTariffItemAdditionalError:false,
            quantityAdditionalError:false,
            additionalCargos:[],
            multipleTariffs:false,
            selectedCurrency: {},
            fieldFactor: '',
            specialGasLiquedfied: '',
            tariffItemUnit: '',
            vesselName: '',
            loadingPreData: false,
            showStart:false,
            isAssessmentFRomTeSWS: false

          }

      },

      methods: {

        filterTariffs () {

          this.filteredTariffItems = []

          if(this.multipleTariffs) {

              for (let x = 0; x < this.tariffItems.length; x++) {

                if(this.tariffItems[x].tariffItemUnit === 'FEU' || this.tariffItems[x].tariffItemUnit ===  'TEU' || this.tariffItems[x].tariffItemUnit === 'Containerized Consignment') {

                  this.filteredTariffItems.push(this.tariffItems[x])

                }

              }

          } else {

            this.filteredTariffItems = this.tariffItems

          }

          if((this.selectedTariffItem.tariffItemUnit === 'FEU' || this.selectedTariffItem.tariffItemUnit === 'TEU' || this.selectedTariffItem.tariffItemUnit === 'Containerized Consignment') && !this.multipleTariffs) {

              this.fieldFactor = ''
              this.cargoCbmError = false
              this.cargoTonnageError = false
              this.AnQuantityError = false
              this.cargoVolumeError = false
              this.vesselNameError = false
              this.labelText = 'Unit'
              this.selectedTariffItem = {}
              this.tariffItemId = ''

          }

          if((this.selectedTariffItem.tariffItemUnit !== 'FEU' || this.selectedTariffItem.tariffItemUnit !== 'TEU' || this.selectedTariffItem.tariffItemUnit === 'Containerized Consignment') && this.multipleTariffs) {

              this.fieldFactor = ''
              this.cargoCbmError = false
              this.cargoTonnageError = false
              this.AnQuantityError = false
              this.cargoVolumeError = false
              this.vesselNameError = false
              this.labelText = 'Unit'
              this.selectedTariffItem = {}
              this.tariffItemId = ''

          }

        },

        commafyThisAmountWithDecimal (flag) {

          switch (flag) {

            case 'cargoCBM':

              this.cargoCbm = this.commafyAmountWithDecimal(this.cargoCbm);

              break;

            case 'customValue':

              this.customValue = this.commafyAmountWithDecimal(this.customValue);

              break;

            case 'cargoTonnage':

              this.cargoTonnage = this.commafyAmountWithDecimal(this.cargoTonnage);

              break;

            case 'quantity':

              this.AnQuantity = this.commafyAmountWithoutDecimal(this.AnQuantity);

              break;

            // case 'AnExchangeRate':

            //   this.AnExchangeRate = this.commafyAmountWithDecimal(this.AnExchangeRate);

            //   break;

            default: //cargoVolume

              this.cargoVolume = this.commafyAmountWithDecimal(this.cargoVolume);

              break;

          }

        },

        tariffItemAndFormFieldMapper (factor) {

          let fieldFactor;

          switch (factor) {

            case 'TEU':
            case 'FEU':
            case 'Unit':
            case 'Containerized Consignment':
            case 'Unit Varying':

                fieldFactor = 'quantity';
                this.AnQuantity = ''
                // if(!this.isAssessmentFRomTeSWS) {this.cargoCbm = '' }
                // if(!this.isAssessmentFRomTeSWS) {this.cargoVolume = '' }
                // if(!this.isAssessmentFRomTeSWS) {this.cargoTonnage = '' }

                break;

            case 'CBM':

                fieldFactor = 'cargoCBM';
                // if(!this.isAssessmentFRomTeSWS) {this.AnQuantity = ''}
                // if(!this.isAssessmentFRomTeSWS) {this.cargoVolume = ''}
                // if(!this.isAssessmentFRomTeSWS) {this.cargoTonnage = ''}

                break;

            case 'Ltr':

                fieldFactor = 'cargoVolume';
                // if(!this.isAssessmentFRomTeSWS) {this.AnQuantity = ''}
                // if(!this.isAssessmentFRomTeSWS) {this.cargoCbm = ''}
                // if(!this.isAssessmentFRomTeSWS) {this.cargoTonnage = ''}

                break;

            case 'Consignment':
            case 'BL':
            case 'Value':

                fieldFactor = 'non';

                break;

            case 'MT':
            case 'Ton':
            case 'HTN':
            case 'Mineral Ton':
            case 'Chemical Ton':
            case 'MT_NEW':

                fieldFactor = 'cargoTonnage';
                // if(!this.isAssessmentFRomTeSWS) {this.AnQuantity = ''}
                // if(!this.isAssessmentFRomTeSWS) {this.cargoCbm = ''}
                // if(!this.isAssessmentFRomTeSWS) {this.cargoVolume = ''}

                break;

            default: // Any other

                break;

          }

          return fieldFactor

        },

        populateSelectedTariffItemAdditionalTarrif(object){

          this.selectedTariffItemAdditionalObject = object
          this.selectedTariffItemAdditional = object.tariffItemId

        },

        populateSelectedTariffItem (object) {
          console.log('377373',object)

          this.selectedTariffItem = object
          this.tariffItemId = this.selectedTariffItem.tariffItemId
          this.getContainerLabel(object.tariffItemUnit)
          this.fieldFactor = this.tariffItemAndFormFieldMapper(object.tariffItemUnit)
          this.cargoCbmError = false
          this.cargoTonnageError = false
          this.AnQuantityError = false
          this.cargoVolumeError = false
          this.vesselNameError = false
          typeof this.selectedTariffItem.tariffItemName !== 'undefined' ? this.specialGasLiquedfied = this.selectedTariffItem.tariffItemName.toLowerCase() : this.specialGasLiquedfied = ''
          if(this.multipleTariffs) {

            this.disableSameSelectedTariffOnMultipleTariff(this.selectedTariffItem)

          }

        },

        disableSameSelectedTariffOnMultipleTariff (data) {

          for (let x = 0; x < this.tariffItems.length; x++) {

            if(data.tariffItemId === this.tariffItems[x].tariffItemId) {

              this.filteredTariffItems.splice(this.filteredTariffItems.indexOf(this.tariffItems[x]), 1)

            }

          }

        },

        getContainerLabel (object) {

          if(object === 'FEU' || object === 'TEU' || object === 'Containerized Consignment') {

              this.labelText = 'Container Quantity'

            } else {

              this.labelText = 'Unit'

            }

        },

        // populateSelectedCurrency (object) {

        //   this.selectedCurrency = object;
        //   this.currencyId = this.selectedCurrency.currencyId


        // },

        shiftCursor(currentId, nextid) {

          let currentIdDom = document.getElementById(currentId);

          if(currentId === 'fourDigit') {

            if(currentIdDom.value.length === 4) {

              document.getElementById(currentId).blur();
              document.getElementById(nextid).focus();

            }

          } else if (currentId === 'twoDigit') {

            if(currentIdDom.value.length === 2) {

              document.getElementById(currentId).blur();
              document.getElementById(nextid).focus();

            }


          } else if (currentId === 'fourDigitEdit') {

            if(currentIdDom.value.length === 4) {

              document.getElementById(currentId).blur();
              document.getElementById(nextid).focus();

            }


          } else if (currentId === 'twoDigitEdit') {

            if(currentIdDom.value.length === 2) {

              document.getElementById(currentId).blur();
              document.getElementById(nextid).focus();

            }


          } else if (currentId === 'sevenDigit') {

            if(currentIdDom.value.length === 7) {

              document.getElementById(currentId).blur();
              // document.getElementById(nextid).focus();

            }


          } else if (currentId === 'bnineDigit') {

              if(currentIdDom.value.length === 9) {

                document.getElementById(currentId).blur();
                document.getElementById(nextid).focus();

              }

          } else if (currentId === 'bfourDigit') {

              if(currentIdDom.value.length === 4) {

                document.getElementById(currentId).blur();
                document.getElementById(nextid).focus();

              }

          } else if (currentId === 'bsevenDigit') {

              if(currentIdDom.value.length === 7) {

                document.getElementById(currentId).blur();
                // document.getElementById(nextid).focus();

              }

          } else {

            if(currentIdDom.value.length === 7) {

              document.getElementById(currentId).blur();
              document.getElementById(nextid).focus();

            }

          }

        },

        loadIsBt() {

          if(this.regime === 'SCT') {

            this.assessmentType = { name: "BT ASSESSMENT", id: true }

          } else {

            this.assessmentType = { name: "NORMAL ASSESSMENT", id: false }

          }

        },

        enableEdit () {

          this.edit =! this.edit;
          if(this.edit) { this.fieldFactor = this.tariffItemAndFormFieldMapper(this.tariffItemUnit); }

        },

        saveAssessmentNotice () {

          let flag = 0;

          if(!this.assessmentType.id) {

            if(this.$v.TANSADNumber4.$invalid || this.$v.TANSADNumber2.$invalid || this.$v.TANSADNumber7.$invalid) {

              if (this.$v.TANSADNumber4.$invalid) { this.TANSADNumberError = true; }
              if (this.$v.TANSADNumber2.$invalid) { this.TANSADNumberError = true; }
              if (this.$v.TANSADNumber7.$invalid) { this.TANSADNumberError = true; }
              flag = 1

            }

          } else {

              // if (this.$v.btNumber.$invalid) {

              //     this.btNumberError = true;
              //     flag = 1

              // }

              if(this.$v.btNumber9.$invalid || this.$v.btNumber4.$invalid || this.$v.btNumber7.$invalid) {

                if (this.$v.btNumber9.$invalid) { this.btNumberError = true; }
                if (this.$v.btNumber4.$invalid) { this.btNumberError = true; }
                if (this.$v.btNumber7.$invalid) { this.btNumberError = true; }
                flag = 1

              }

          }

          if(flag === 0) {

            if (this.$v.tariffItemId.$invalid || this.$v.customValue.$invalid || this.assesment === null) {

              if (this.$v.tariffItemId.$invalid) {

                this.tariffItemNameError = true;
                flag = 1

              } else {

                switch (this.fieldFactor) {

                  case 'cargoCBM':
                    if(this.$v.cargoCbm.$invalid) { this.cargoCbmError = true;flag = 1 }

                    break;

                  case 'cargoTonnage':
                    if(this.$v.cargoTonnage.$invalid) { this.cargoTonnageError = true;flag = 1 }

                    break;

                  case 'quantity':
                    if(this.$v.AnQuantity.$invalid) { this.AnQuantityError = true;flag = 1 }

                    break;

                  case 'cargoVolume':
                    if(this.$v.cargoVolume.$invalid) { this.cargoVolumeError = true;flag = 1 }
                    if(this.$v.vesselName.$invalid) { this.vesselNameError = true;flag = 1 }

                    break;

                  default: //non

                    break;

                }

              }


              if (this.$v.customValue.$invalid) { this.customValueError = true;flag = 1 }
              // if (this.$v.AnExchangeRate.$invalid) { this.AnExchangeRateError = true;flag = 1 }
              // if (this.$v.currencyId.$invalid) { this.currencyError = true;flag = 1 }
              //if (this.$v.invoiceNumberAss.$invalid) { this.invoiceNumberError = true;flag = 1 }

            }

            if (this.$v.tariffItemId.$invalid || this.$v.customValue.$invalid || this.isAssessmentFRomTeSWS) {

              if (this.$v.tariffItemId.$invalid) {

                this.tariffItemNameError = true;
                flag = 1

              } else {

                switch (this.fieldFactor) {

                  case 'cargoCBM':
                    if(this.$v.cargoCbm.$invalid) { this.cargoCbmError = true;flag = 1 }

                    break;

                  case 'cargoTonnage':
                    if(this.$v.cargoTonnage.$invalid) { this.cargoTonnageError = true;flag = 1 }

                    if(this.specialGasLiquedfied === 'gas liquefied') { if(this.$v.vesselName.$invalid) { this.vesselNameError = true;flag = 1 } }

                    break;

                  case 'quantity':
                    if(this.$v.AnQuantity.$invalid) { this.AnQuantityError = true;flag = 1 }

                    break;

                  case 'cargoVolume':
                    if(this.$v.cargoVolume.$invalid) { this.cargoVolumeError = true;flag = 1 }
                    if(this.$v.vesselName.$invalid) { this.vesselNameError = true;flag = 1 }

                    break;

                  default: //non

                    break;

                }

              }


              if (this.$v.customValue.$invalid) { this.customValueError = true;flag = 1 }
              // if (this.$v.AnExchangeRate.$invalid) { this.AnExchangeRateError = true;flag = 1 }
              // if (this.$v.currencyId.$invalid) { this.currencyError = true;flag = 1 }
              //if (this.$v.invoiceNumberAss.$invalid) { this.invoiceNumberError = true;flag = 1 }

            }

            if (this.$v.tariffItemId.$invalid || this.$v.customValue.$invalid || this.isAssementAvailable) {

              if (this.$v.tariffItemId.$invalid) {

                this.tariffItemNameError = true;
                flag = 1

              } else {

                switch (this.fieldFactor) {

                  case 'cargoCBM':
                    if(this.$v.cargoCbm.$invalid) { this.cargoCbmError = true;flag = 1 }

                    break;

                  case 'cargoTonnage':
                    if(this.$v.cargoTonnage.$invalid) { this.cargoTonnageError = true;flag = 1 }

                    if(this.specialGasLiquedfied === 'gas liquefied') { if(this.$v.vesselName.$invalid) { this.vesselNameError = true;flag = 1 } }

                    break;

                  case 'quantity':
                    if(this.$v.AnQuantity.$invalid) { this.AnQuantityError = true;flag = 1 }

                    break;

                  case 'cargoVolume':
                    if(this.$v.cargoVolume.$invalid) { this.cargoVolumeError = true;flag = 1 }
                    if(this.$v.vesselName.$invalid) { this.vesselNameError = true;flag = 1 }

                    break;

                  default: //non

                    break;

                }

              }


              if (this.$v.customValue.$invalid) { this.customValueError = true;flag = 1 }
              // if (this.$v.AnExchangeRate.$invalid) { this.AnExchangeRateError = true;flag = 1 }
              // if (this.$v.currencyId.$invalid) { this.currencyError = true;flag = 1 }
              //if (this.$v.invoiceNumberAss.$invalid) { this.invoiceNumberError = true;flag = 1 }

            }


            if (flag === 0) {

              let data = {

                  assessmentNoticeId:this.assessmentNoticeId,
                  cargoCbm: Number(this.removeComma(this.cargoCbm)),
                  bt: this.assessmentType.id,
                  cargoGroupId: null,
                  multipleTariffs: this.multipleTariffs,
                  additionalCargos:null,
                  cargoTonnage: Number(this.removeComma(this.cargoTonnage)),
                  cargoVolume: Number(this.removeComma(this.cargoVolume)),
                  currencyId: this.currencyId,
                  customValue: Number(this.removeComma(this.customValue)),
                  exchangeRate: this.AnExchangeRate,
                  isPBPA:this.selectedTariffItem.tariffItemName === 'PBPA',
                //invoiceNumber: this.invoiceNumberAss,
                  quantity: Number(this.removeComma(this.AnQuantity)),
                  requestId: parseInt(this.requestId),
                  tansadNo: this.assessmentType.id ? this.btNumber9 + '-' + this.btNumber4 + '-T-' + this.btNumber7 : this.TANSADNumber4 + '-' + this.TANSADNumber2 + '-' + this.TANSADNumber7,
                  tariffItemId: this.tariffItemId,
                  vesselName: this.vesselName

              };

              let multipleTarifsError = false;

              if(this.multipleTariffs){

                if (this.quantityAdditional === "" && this.selectedTariffItemAdditional === "") {

                      multipleTarifsError = true;
                      this.quantityAdditionalError = true;
                      this.selectedTariffItemAdditionalError = true;

                      this.loadNotification('error', 4000, 'Multiple Tariff Error', 'Please add additional cargo in multiple tariff section', 'response', 1000 , true  , true);

                } else {

                      multipleTarifsError = false;
                      this.quantityAdditionalError = false;
                      this.selectedTariffItemAdditionalError = false;

                      data.additionalCargos = [
                          {
                            quantity: this.quantityAdditional,
                            tariffItemId: this.selectedTariffItemAdditional
                          }
                      ];
                }
              }

              if(!this.edit && !multipleTarifsError && flag === 0) {
                this.loading = true;
                  API.postRequestAssessmentNotice(JSON.stringify(data)).then((response) => {

                    this.assessmentNoticeId = null;

                    if(response.status) {

                      this.loading = false;
                      this.edit = false;
                      this.loadNotification('success', 4000, 'Success', 'Assessment has been successfully Saved', 'response', 1000 , true  , true);
                      this.closeModal();
                      this.getAssesmentByRequestId();
                      this.setInit()
                      data = {}

                    }else {

                      this.loading = false;
                      this.edit = false;
                      this.loadNotification('error', 4000, 'Error', 'Assessment Not Saved, please retry', 'response', 1000 , true  , true)

                    }

                  }).catch(() => {
                    this.loading = false;

                    this.edit = false;
                    this.loadNotification('error', 4000, 'Assessment Placement', 'Assessment not placed, please retry', 'response', 1000 , true  , true)

                  });

              }

              if (this.edit && !multipleTarifsError && flag === 0) {

                  this.loading = true;

                  API.updateRequestAssessmentNoticeV2(JSON.stringify(data)).then((response) => {

                      if(response.status) {

                        this.loading = false;
                        this.edit = false;
                        this.loadNotification('success', 6000, 'Success', 'Assessment has been successfully updated', 'response', 1000 , true  , true);
                        this.closeModal();
                        this.getAssesmentByRequestId();
                        this.setInit()
                        data = {}

                      }else {

                        this.loading = false;
                        this.edit = false;
                        this.loadNotification('error', 6000, 'Error', 'Assessment not Updated, please retry', 'response', 1000 , true  , true)

                      }

                    }).catch(() => {
                    this.loading = false;
                      this.edit = false;
                      this.loadNotification('error', 6000, 'Error', 'Assessment not Updated, please retry', 'response', 1000 , true  , true)

                    });

              }

            } else {
            }

          }

        },

        // loadCurrenciesSearch() {

        //   this.loadingPreData = true;

        //   API.getAllCurrencies().then(
        //     response => {

        //       if (response.data.status) {

        //         let myResponse = response.data.data;

        //         for (let v = 0; v < myResponse.length; v++) {

        //           let myObject = {

        //             "currencyId": myResponse[v].currencyId,
        //             "currencyName": myResponse[v].currencyName + " ( " + myResponse[v].currencyCode + " )"

        //           };

        //           this.currenciesSearch.push(myObject);

        //         }

        //         this.loadingPreData = false

        //       } else {

        //       }
        //     }
        //   ).catch(() => {

        //     this.loadingPreData = false

        //   });
        // },

        sortingData(data) {

          this.filteredRequests = []

          if(data !== null) {

            for(let x = 0 ; x < data.length ; x++) {

                const requestObject = {
                  ...data[x],
                  createdDate: this.dateTimeToHuman(data[x].createdDate),
                };

                this.filteredRequests.push(requestObject)

            }

          }

        },

        loadAssessmentNoticeFromTeSWS(){

          API.getPreviewAssesmentDataFromDeclaration(this.requestId).then((response) => {

            this.loadingPreData = false;

            let tansad="";
            let TANSADNumber4 = "";
            let tansad27 = "";
            let TANSADNumber2 = "";
            let TANSADNumber7 = "";

            let bt;
            let btNumber9;
            let btNumber4;
            let btNumber7;

            if(response.data.data !== null) {

              this.assesment = response.data.data;
              this.isAssessmentFRomTeSWS = true

              if(!this.assesment.bt && this.assesment.tansadNo !== null){

                tansad = this.assesment.tansadNo;

                TANSADNumber4 = tansad.slice(0,4)
                TANSADNumber2 = tansad.slice(4,6)
                TANSADNumber7 = tansad.slice(6,)

              } else if (this.assesment.bt) { //if(this.assesment.bt){

                this.btNumber9 = this.assesment.tansadNo.slice(0,8);
                this.btNumber4 = this.assesment.tansadNo.slice(8,12);
                this.btNumber4 = this.assesment.tansadNo.slice(12,);

              }
              this.cargoGroupId = this.assesment.cargoGroupId;
              this.assesment.quantity !== null ? this.AnQuantity =  this.putComma(this.assesment.quantity, 0) : this.AnQuantity = ''
              this.assesment.cargoCbm !== null ? this.cargoCbm =  this.putComma(this.assesment.cargoCbm) : this.cargoCbm = ''
              this.assesment.cargoTonnage !== null ? this.cargoTonnage =  this.putComma(this.assesment.cargoTonnage/1000) : this.cargoTonnage = ''
              this.assesment.cargoVolume !== null ? this.cargoVolume =  this.putComma(this.assesment.cargoVolume) : this.cargoVolume = ''
              this.customValue =this.assesment.customValue!==null? this.putComma(this.assesment.customValue):"";
              // this.AnExchangeRate =this.AnExchangeRate? this.putComma(this.assesment.exchangeRate):"";
              //this.invoiceNumberAss =this.invoiceNumberAss? this.assesment.invoiceNumber:"";
              this.assessmentNoticeId = this.assesment.assessmentNoticeId;

              if(this.assesment.tansadNo!==null){

                this.TANSADNumber2 = TANSADNumber2;
                this.TANSADNumber4 = TANSADNumber4;
                this.TANSADNumber7 = TANSADNumber7;

              } else  if (this.assesment.bt){

                this.btNumber9 = btNumber9;
                this.btNumber4 = btNumber4;
                this.btNumber7 = btNumber7;
                this.assesment.bt ? this.assessmentType = { name: "BT ASSESSMENT", id: true } : this.assessmentType = { name: "NORMAL ASSESSMENT", id: false }

              }

              this.vesselName =this.assesment.vesselName!==null? this.assesment.vesselName:"";

              if(this.multipleTariffs!==null){
                this.multipleTariffs = this.assesment.multipleTariffs
              }

              if(this.assesment.additionalCargos !== null) {

                this.selectedTariffItemAdditionalObject = {

                  'tariffItemId': this.assesment.additionalCargos[0].tariffItemId,
                  'tariffItemName': this.assesment.additionalCargos[0].tariffItemName

                };

                this.quantityAdditional = this.assesment.additionalCargos[0].quantity

              }
              this.tariffItemUnit = this.assesment.tariffItemUnit;
              this.getContainerLabel(this.tariffItemUnit)

            } else {

              this.assesment = null

            }

          }).catch((e)=>{

            this.myloader = false;
            this.loadingPreData = false

          })

        },

        getAssesmentByRequestId(){

          this.loadingPreData = true;

          this.edit = false;
          API.getRequestAssessmentNotice(this.requestId).then((response) => {

            this.loadingPreData = false;

            let tansad="";
            let TANSADNumber4 = "";
            let tansad27 = "";
            let TANSADNumber2 = "";
            let TANSADNumber7 = "";

            let bt;
            let btNumber9;
            let btNumber4;
            let btNumber7;

            if(response.data.data !== null) {

              this.assesment = response.data.data;
              this.isAssessmentFRomTeSWS = false
              this.isAssementAvailable = true;
              this.sortingData(this.assesment.rejectMessages);

              if(!this.assesment.bt){

                tansad = this.assesment.tansadNo;
                TANSADNumber4 = tansad.split(/-(.+)/)[0];
                tansad27 = tansad.split(/-(.+)/)[1];
                TANSADNumber2 = tansad27.split(/-(.+)/)[0];
                TANSADNumber7 = tansad27.split("-")[1];

              } else { //if(this.assesment.bt){

                //this.btNumber = this.assesment.tansadNo;

                bt = this.assesment.tansadNo;
                btNumber9 = bt.split('-')[0];
                btNumber4 = bt.split('-')[1];
                btNumber7 = bt.split('-')[3];

              }

              this.cargoGroupId = this.assesment.cargoGroupId;
              this.assesment.quantity !== 0 ? this.AnQuantity =  this.putComma(this.assesment.quantity, 0) : this.AnQuantity = ''
              this.assesment.cargoCbm !== 0 ? this.cargoCbm =  this.putComma(this.assesment.cargoCbm) : this.cargoCbm = ''
              this.assesment.cargoTonnage !== 0 ? this.cargoTonnage =  this.putComma(this.assesment.cargoTonnage) : this.cargoTonnage = ''
              this.assesment.cargoVolume !== 0 ? this.cargoVolume =  this.putComma(this.assesment.cargoVolume) : this.cargoVolume = ''
              // this.currencyId = this.assesment.currency.currencyId;
              this.customValue = this.putComma(this.assesment.customValue);
              // this.AnExchangeRate = this.putComma(this.assesment.exchangeRate);
              //this.invoiceNumberAss = this.assesment.invoiceNumber;
              this.tansadNo = this.assesment.tansadNo;
              this.rejectMessage = this.assesment.rejectMessage;
              this.assessmentNoticeId = this.assesment.assessmentNoticeId;
              this.tariffItemId = this.assesment.tariffItemId;
              this.TANSADNumber2 = TANSADNumber2;
              this.TANSADNumber4 = TANSADNumber4;
              this.TANSADNumber7 = TANSADNumber7;
              this.btNumber9 = btNumber9;
              this.btNumber4 = btNumber4;
              this.btNumber7 = btNumber7;
              this.assesment.bt ? this.assessmentType = { name: "BT ASSESSMENT", id: true } : this.assessmentType = { name: "NORMAL ASSESSMENT", id: false }
              this.vesselName = this.assesment.vesselName;
              this.multipleTariffs = this.assesment.multipleTariffs;

              this.selectedTariffItem = {

                  'tariffItemId': this.assesment.tariffItemId,
                  'tariffItemName': this.assesment.tariffItemName

              };

              // this.selectedCurrency = {

              //     'currencyId': this.assesment.currency.currencyId,
              //     'currencyName': this.assesment.currency.currencyName + " - " + this.assesment.currency.countryName

              // };

              if(this.assesment.additionalCargos !== null) {

                this.selectedTariffItemAdditionalObject = {

                    'tariffItemId': this.assesment.additionalCargos[0].tariffItemId,
                    'tariffItemName': this.assesment.additionalCargos[0].tariffItemName

                };

                this.quantityAdditional = this.assesment.additionalCargos[0].quantity

              }
              this.tariffItemUnit = this.assesment.tariffItemUnit;
              this.getContainerLabel(this.tariffItemUnit)

            }else {
              //if assessment not available for this request then check if there is data from declaration
              //to populate this assessment
              this.loadAssessmentNoticeFromTeSWS();
            }

          }).catch((e)=>{

            this.myloader = false;
            this.loadingPreData = false

          })

        },

        loadTariffItems() {

          this.loadingPreData = true;

          API_REVENUE.getTariffItemsActiveByService('CFA').then(
            response => {

              this.tariffItems = response.data.data;
              this.filteredTariffItems = response.data.data
              this.loadingPreData = false;

            }).catch((e) => {

            this.myloader = false;
            this.loadingPreData = false;

          })
        },

        closeModal () {

          this.edit = false;
          this.assessmentNoticeId = null;
          this.$emit('closeModal');

        },

        setInit () {

          this.$emit('setInit')

        }
      }
    }
</script>

<style scoped>
.error{

  color: red;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.card label {
    /* color: rgba(213,154,24, .9) !important; */
    color: black !important;
}

.is-active-additional-details, .is-active-additional-details :hover {
  color: #ffffff !important;
  padding: 0 0 2px 0;
  background-color: green !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.is-active-main-details, .is-active-main-details :hover {
  color: #ffffff !important;
  padding: 0 0 2px 0;
  background-color: #3498db !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}


</style>
