<template>
<div>
  <card>

    <ul class="nav-justified">
      <li>
        <a @click.prevent="setMainActive('ShippingOrder' , 'AGENCY_SO')" :class="[ isMainActive('ShippingOrder') ? 'is-active-shipping-order' : '' ]" href="#ShippingOrder">Shipping Order</a>
      </li>
      <li>
        <a @click.prevent="setMainActive('DeliveryOrder' , 'AGENCY_DO')" :class="[ isMainActive('DeliveryOrder') ? 'is-active-delivery-order' : '' ]" href="#DeliveryOrder">Delivery Order</a>
      </li>
    </ul>

    <div class="tab-content py-3" id="myMainTabContent">
      <card class="tab-pane fade pt-3" :class="{ 'active show': isMainActive('ShippingOrder') }" id="ShippingOrder">
        <ul class="nav-justified">
          <li>
            <a @click.prevent="setActive('Billed' , 'Not Approved')" :class="[ isActive('Billed') ? 'is-active-verified' : '' ]" href="#Billed1">Waiting Approval</a>
          </li>
          <li>
            <a @click.prevent="setActive('WaitingControlNumber' , 'Waiting Control Number')" :class="[ isActive('WaitingControlNumber') ? 'is-active-waiting-control-number' : '' ]" href="#WaitingControlNumber1">Waiting GePG Control No.</a>
          </li>
          <li>
            <a @click.prevent="setActive('Approved' , 'Approved')" :class="[ isActive('Approved') ? 'is-active-major' : '' ]" href="#Approved1">Approved</a>
          </li>
          <li>
            <a @click.prevent="setActive('Paid' , 'Paid')" :class="[ isActive('Paid') ? 'is-active-minor' : '' ]" href="#Paid1">Paid</a>
          </li>
          <li>
            <a @click.prevent="setActive('Cancelled' , 'Canceled')" :class="[ isActive('Cancelled') ? 'is-active-returned' : '' ]" href="#Cancelled1">Cancelled</a>
          </li>
        </ul>

        <div class="tab-content py-3" id="myTabContent">
          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Billed') }" id="Billed1">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while the system is loading agency unapproved bills for approval'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>
          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Approved') }" id="Approved1">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while system is loading agency approved bills'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>

          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('WaitingControlNumber') }" id="WaitingControlNumber1">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while system is loading agency waiting GePG control number bills'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>
          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Paid') }" id="Paid1">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while system is loading agency paid bills'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>
          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Cancelled') }" id="Cancelled1">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while system is loading agency cancelled bills'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>
        </div>
      </card>
      <card class="tab-pane fade pt-3" :class="{ 'active show': isMainActive('DeliveryOrder') }" id="DeliveryOrder">
        <ul class="nav-justified">
          <li>
            <a @click.prevent="setActive('Billed' , 'Not Approved')" :class="[ isActive('Billed') ? 'is-active-verified' : '' ]" href="#Billed">Billed</a>
          </li>
          <li>
            <a @click.prevent="setActive('WaitingControlNumber' , 'Waiting Control Number')" :class="[ isActive('WaitingControlNumber') ? 'is-active-waiting-control-number' : '' ]" href="#WaitingControlNumber">Waiting GePG Control No.</a>
          </li>
          <li>
            <a @click.prevent="setActive('Approved' , 'Approved')" :class="[ isActive('Approved') ? 'is-active-major' : '' ]" href="#Approved">Approved</a>
          </li>
          <li>
            <a @click.prevent="setActive('Paid' , 'Paid')" :class="[ isActive('Paid') ? 'is-active-minor' : '' ]" href="#Paid">Paid</a>
          </li>
          <li>
            <a @click.prevent="setActive('Cancelled' , 'Canceled')" :class="[ isActive('Cancelled') ? 'is-active-returned' : '' ]" href="#Cancelled">Cancelled</a>
          </li>
        </ul>

        <div class="tab-content py-3" id="myTabContent2">
          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Billed') }" id="Billed">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while system is loading agency unapproved bills for approval'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>
          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Approved') }" id="Approved">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while system is loading agency approved bills'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>

          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('WaitingControlNumber') }" id="WaitingControlNumber">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while system is loading agency waiting GePG control number bills'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>
          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Paid') }" id="Paid">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while system is loading agency paid bills'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>
          <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Cancelled') }" id="Cancelled">
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="requests"
              :tableDataKeys="tableDataKeys"
              :pageSizeProp="pageSize"
              :pageNo="pageNo"
              :myLoader="myLoader"
              :reloadAllRequest="reloadAllRequest"
              :myFirstLoader="myFirstLoader"
              :noDataFound="noDataFound"
              :is-hovered="isHovered"
              :searchParameterProp="searchParameter"
              :loadingTitle="'Please wait while system is loading agency cancelled bills'"
              :hasManageButton="false"
              :placeholder="'Search by bill number or customer'"
              :totalElements="totalElements"
              :totalPages="totalPages"
              :isFirst="isFirst"
              :isLast="isLast"
              :pageNumber="pageNumber"
              :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Approve Bill' , 'class' : 'btn-success'}]"
              @showToolTip="showToolTip"
              @hideToolTip="hideToolTip"
              @onChange="onChange"
              @goToPage="goToPage"
              @goToFinishTallying="goToApplication">

            </data-table>
          </div>
        </div>
      </card>
    </div>
  </card>
  <global-modal v-if="myLoader">
    <div slot="globalLoader">
      <div class="row text-center">
        <div class="col-md-12">Loading, Please wait</div>
        <div class="col-md-12">
          <br/>
        </div>
        <div class="col-md-12">
          <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
        </div>
      </div>
    </div>
  </global-modal>
</div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import PulseLoader from "vue-spinner/src/PulseLoader.vue";
  import GlobalModal from "../../../../components/globalLoader";
  import DataTable from "../../../../components/DataTable/updatedTable";

    export default {

        name: "billsWaitingApproval",

      components: {

        PulseLoader,
        GlobalModal,
        DataTable,

      },

      created() {

        this.$store.dispatch("pagenames/currentPageName", "Agency BillS");

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
        this.setActive('Billed');
        this.$store.dispatch("shippingtallying/erroralerting", false);
        this.$store.dispatch("shippingtallying/successalerting", false);

      },

      computed: {

        ...mapGetters("notifications", [

          "getNewAppPage"

        ]),

        ...mapGetters("shippingtallybilling", [

          "getRequestList",
          "getPageCount",
          "getMyFirstLoader",
          "getNoDataFound",
          "getSuccessAlert",
          "getErrorMessage",
          "getErrorAlert",
          "getSuccessMessage",
          "getReloadAllRequest",
          "getMyLoader",
          "getSearchParameter",
          "getHasSearchedVariable",
          "getPageNo",
          "getPageSize",
          "getRequestId",
          "getTotalElement",
          "getTotalPage",
          "getPageNumber",
          "getIsFirst",
          "getIsLast",
          "getTalliedDocumentId",
        ]),

      },

      data() {

        return {

          pageNo: 0,
          pageSize: 15,
          totalElements: "",
          totalPages: "",
          isFirst: false,
          isLast: false,
          pageNumber: '',
          billType: '',
          billStatus: 'Not Approved',

          color: "green",
          size: "12px",
          loading: true,

          searchParameter: "",
          hasSearchedVariable: false,
          reloadAllRequest: false,
          noDataFound: false,
          pdfFormatError: false,
          loadAllHeaderLoader: false,
          allAttachmentVerified: false,
          myLoader: false,
          myFirstLoader: false,
          isSearchable: false,
          requests: [],
          isHovered: [],
          activeMainItem: "ShippingOrder",
          activeItem: 'Billed',

          ifThereIsParam: "",

          finalUserPermissions: [],
          tableHeaders: ['Bill Number', 'Customer', 'Total Amount', 'Billed Date'],
          tableDataKeys: ['billNo', 'company', 'billVatedAmountTzs', 'dateBilled'],

        }

      },

      methods: {

        ...mapActions("shippingtallybilling", [

          "getAllTallyBillsOnCreated",
          "getAllTallyBillsAfterCreated",
          "approveTallyBill",

        ]),

        loadParameters(billStatus) {

          return new Promise((resolve) => {

            this.$store.dispatch("shippingtallybilling/pageSizing", this.pageSize);
            this.$store.dispatch("shippingtallybilling/pageNumbering", this.pageNo);
            this.$store.dispatch("shippingtallybilling/billtyping", this.billType);
            this.$store.dispatch("shippingtallybilling/billstatus", billStatus);

            this.billStatus = billStatus;

            resolve();

          })

        },

        setAllGetters(flag) {

          this.requests = this.getRequestList;
          flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
          flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
          this.noDataFound = this.getNoDataFound;
          this.SuccessMessage = this.getSuccessMessage;
          this.ErrorMessage = this.getErrorMessage;
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessAlert = this.getSuccessAlert;
          this.pageNo = this.getPageNo;
          this.pageSize = this.getPageSize;

          this.totalElements = this.getTotalElement;
          this.totalPages = this.getTotalPage;
          this.pageNumber = this.getPageNumber;
          this.isFirst = this.getIsFirst;
          this.isLast = this.getIsLast;

        },

        loadPageableRequestOnCreated(billStatus) {

          this.loadParameters(billStatus).then(() => {

            this.getAllTallyBillsOnCreated({}).then(() => {

              this.setAllGetters('onCreated');

            }).catch(() => {


            });

            this.myFirstLoader = this.getMyFirstLoader;

          }).catch(() => {


          });

        },

        loadPageableRequestsAfterCreated(billStatus) {

          this.loadParameters(billStatus).then(() => {

            this.getAllTallyBillsAfterCreated({}).then(() => {

              this.setAllGetters('afterCreated');

            });

            this.myLoader = this.getMyLoader;

          }).catch(() => {


          });

        },

        onChange(event) {

          this.$store.dispatch("shippingtallying/pageSizing", event);
          this.$store.dispatch("shippingtallying/pageNumbering", 0);

          this.pageSize = this.getPageSize;
          this.pageNo = this.getPageNo;

          if (this.getHasSearchedVariable) {

            this.searchRequestByName(this.getSearchParameter);

          } else {

            this.loadPageableRequestsAfterCreated(this.billStatus);
          }

        },

        goToApplication(bill) {

          document.cookie = "browser-local-cashed-url-back=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
          this.$router.push("/clearing-and-forwarding/application-bill-view/" + bill.billId);

        },

        goToPage(page) {

          this.pageNo = page;

          this.loadPageableRequestsAfterCreated(this.billStatus);

        },

        showToolTip (object) {

          this.isHovered = [object];
        },

        hideToolTip (object) {

          this.isHovered.splice(this.isHovered.indexOf(object), 1)

        },

        isActive(menuItem) {

          return this.activeItem === menuItem;

        },

        setActive(menuItem , billStatus) {

          this.activeItem = menuItem;
          this.$store.dispatch("shippingtallybilling/requestlisting", []);
          this.requests = [];
          this.billStatus = billStatus;
          this.loadPageableRequestOnCreated(billStatus);

        },

        isMainActive(menuMainItem) {

          return this.activeMainItem === menuMainItem;

        },

        setMainActive(menuMainItem , billType) {

          this.activeMainItem = menuMainItem;
          this.billType = billType;
          this.setActive('Billed');
          this.loadPageableRequestOnCreated('Not Approved');

        },

      }

    }
</script>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: 224px;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  .is-active-new, .is-active-new :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: gray !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-verified, .is-active-verified :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-major, .is-active-major :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #2F4F4F !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-minor, .is-active-minor :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-returned, .is-active-returned :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: red !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-waiting-control-number , .is-active-waiting-control-number :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #DCDCDC !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-shipping-order , .is-active-shipping-order:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #556b2f !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-delivery-order , .is-active-delivery-order:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #d2b48c !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

</style>
