<template>
  <div class="card">
    <div
      class="tab__header card-header"
      v-if="totalDiscrepancies <= 1"
    >
      <a
        href="#"
        class="card-title row"
        @click.prevent="active = !active"
      >
        <div class="col-md-11">
          <strong>
            {{ listId }}. Discrepancy for <b><i>{{ fieldName }}</i></b> is
            <span class="badge badge-light text-danger"> <i> {{ totalDiscrepancies }} </i>  </span>
          </strong>
        </div>
        <div class="col-md-1 text-right">
          <font-awesome-icon
            icon="angle-down"
            v-show="!active"
          />
          <font-awesome-icon
            icon="angle-up"
            v-show="active"
          />
        </div>
      </a>
    </div>
    <div
      class="tab__header card-header"
      v-if="totalDiscrepancies > 1"
    >
      <a
        href="#"
        class="card-title row"
        @click.prevent="active = !active"
      >
        <div class="col-md-11">
          <strong>
            {{ listId }}. Discrepancies for <b><i>{{ fieldName }}</i></b> are
            <span class="badge badge-light text-danger"> <i> {{ totalDiscrepancies }} </i>  </span>
          </strong>
        </div>
        <div class="col-md-1 text-right">
          <font-awesome-icon
            icon="angle-down"
            v-show="!active"
          />
          <font-awesome-icon
            icon="angle-up"
            v-show="active"
          />
        </div>
      </a>
    </div>
    <div
      class="tab__content p-2 card-body"
      v-show="active"
    >
      <slot />
    </div>
  </div>
</template>
<script>

export default {

  name: 'Accordion',

  props: {
    voyage: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    listId: Number,
    totalDiscrepancies: Number
  },
  data () {
    return {
      active: false

    }
  }

}
</script>

<style>

  .card .card-header {
    background-color: rgba(220 , 220 , 220 , .5);
    padding: 5px 15px 5px 15px;
  }

</style>
