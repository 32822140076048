<template>
  <div>
    <form
      method="post"
      @submit.prevent="saveNonMonetaryActivity"
      enctype="multipart/form-data"
    >
      <card>
        <div class="row">
          <div class="col-md-12">
            <label>Search Activity Name</label>
            <model-list-select
              :list="assignedActiveActivitiesPerUser"
              v-model="activityName"
              class="form-control"
              option-value="assignmentId"
              option-text="activityName"
              placeholder="activity name"
              @input="assignedChanged"></model-list-select>
            <div v-if="activityNameError">
              <div
                class="error"
                v-if="!$v.activityName.required"
              >
                This field is required
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-6">
            <label>**update if status has changed**</label>
            <model-list-select
              :list="assignmentStatus"
              id="statusDropdown"
              v-model="statusDescription"
              class="form-control"
              option-value="statusId"
              option-text="statusDescription"
              placeholder="status description"
              @input="updateTempStatus"></model-list-select>
            <div v-if="statusDescriptionError">
              <div
                class="error"
                v-if="!$v.statusDescription.required"
              >
                This field is required
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <label class="custom-font">Activity Date</label>
            <!-- <input type="datetime-local" class="form-control" id="aDate" v-model="activityDate" placeholder="Activity Date" :max="todayDate"> -->
            <date-picker type="date" style="width:100% !important;" v-model="activityDate" @change="setFormattedActivityDate" format="DD-MM-YYYY" :disabled-date="validateActivityDate"></date-picker>
            <div v-if="activityDateError">
              <div
                class="error"
                v-if="!$v.activityDate.required"
              >
                This field is required
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="fileUpload btn btn-info btn-fill">
              <span>Choose Activity Attachment..</span>
              <input
                type="file"
                name="attachment"
                label="Upload File"
                ref="file"
                class="upload"
                id="first_upload"
                @change.prevent="uploadFile"
              >
            </div>
            <label
              for="first_upload"
              id="label-upload"
            >
              {{ file ? 'Selected File: ' + '" ' + file + ' "' : 'No File Selected' }}
            </label>
            <div
              v-if="pdfFormatError"
              class="red-color"
            >
              Attach pdf document only
            </div>
          </div>
        </div>
        <br>

        <div class="row">
          <div class="col-md-12 text-bold">
            <label>Description (If any)</label>
            <textarea
              class="form-control"
              placeholder="Description"
              rows="3"
              v-model="activityDescription"></textarea>
            <div v-if="activityDescriptionError">
              <div
                class="error"
                v-if="!$v.activityDescription.required"
              >
                This field is required
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-6">
            <b>Staff Assigned Activities and Status</b>
          </div>
          <div class="col-md-6 text-right">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="getAssignmentsLoader"></pulse-loader>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">

            <table class="table table-striped">
              <thead>
              <tr>
                <th
                  scope="col"
                  width="5%"
                >
                  #
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  File No.(BL No.)
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Activity
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Assigned Person
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Date Assigned
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Assigned By
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Updated By
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Date Updated
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Current Status
                </th>
                <th
                  scope="col"
                  width="15%"
                >
                  remarks
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(list,index) in assignedActivitiesPerUser"
                :key="index"
                v-if="list.userId === parseInt(cookieUserId)"
              >
                <th scope="row">
                  {{ index + 1 }}
                </th>
                <td>{{ list.fileNo +" " }} ({{ list.blNumber }})</td>
                <td>{{ list.activityName }}</td>
                <td><b>{{ list.firstName + " " }} {{ list.lastName }}</b></td>
                <td>{{ dateTimeToHuman(list.createdDate) }}</td>
                <td>{{ list.createdByFirstName + " " }}{{ list.createdByLastName }}</td>
                <td>{{ list.lastModifiedByFirstName + " " }}{{ list.lastModifiedByLastName }}</td>
                <td>{{ dateTimeToHuman(list.lastModifiedDate) }}</td>
                <td
                  v-if="list.statusDescription === 'Completed'"
                  class="text-success"
                >
                  <b>{{ list.statusDescription }}</b>
                </td>
                <td v-else>
                  <b>{{ list.statusDescription }}</b>
                </td>
                <td>{{ list.remarks }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr>

        <div
          class="text-center row"
        >
          <div class="col-md-8 text-right loader-div-gif">
            <div class="row">
              <div
                class="col-md-10"
                v-if="sendRequestLoader"
              >
                Please wait ....
              </div>
              <div class="col-md-2">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="sendRequestLoader"
                  style="margin-top: 4px;"></pulse-loader>
              </div>
            </div>
          </div>

          <div class="col-md-4 text-right">
            <button
              type="submit"
              class="btn btn-primary btn-space"
              data-dismiss="modal"
              @click.prevent="saveNonMonetaryActivity"
            >
              Update
            </button>
            <button
              class="btn btn-default btn-fill"
              @click.prevent="closeModal"
            >
              Close
            </button>
          </div>
        </div>
      </card>
    </form>
  </div>
</template>

<script>
  import API from '../../../api/index'
  import  { ModelListSelect, MultiSelect } from 'vue-search-select';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import { required } from 'vuelidate/lib/validators'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    name: "Activity",

    props: {
      requestId: {required: false},
      selectedRequest: {type: Object, required: false}
    },

    components: {
      ModelListSelect,
      MultiSelect,
      PulseLoader,
      DatePicker,
    },

    data() {
      return {
        activityName: '',
        activityDate: '',
        statusDescription: '',
        activityDateError: false,
        reaSignAction: false,
        activityNameError: false,
        statusDescriptionError: false,
        activityDescriptionError: false,
        assignedActivitiesPerUser: [],
        assignedActiveActivitiesPerUserTempo: [],
        assignedActiveActivitiesPerUser: [],
        notAssignedActivity: [],
        optionId: [],
        assignmentStatus: [],
        getDetailedAssignment: {},
        options: [],
        assignedUserId: '',
        sendRequestLoader: false,
        getAssignmentsLoader: false,
        todayDate: new Date().toJSON().slice(0, 10),
        SuccessMessage: '',
        SuccessAlert: false,
        loading: true,
        ErrorAlert: false,
        updatedAssigment: {},
        activityDescription: '',
        pdfFormatError: false,
        myFlag: false,
        color: 'green',
        size: '12px',
        mySize: '9px',
        sizeVerify: '10px',
        file: '',
        fileData: '',
        updatedAssignmentStatus: '',
        updatedActivityId: '',
        updatedAssigmentId: '',
        updatedCreatedBy: '',
        updatedCreatedDate: '',
        updatedRemarks: '',
        updatedRequestId: '',
        updatedUserId: '',
        cookieUserId: '',
        activityDateFormatted: ''
      }
    },

    validations: {
      activityName: { required },
      activityDescription: { required },
      statusDescription: { required },
      activityDate: { required },
      reAssignRemarks: { required }
    },

    created() {
      this.getAssignments(this.requestId);
      this.getStatus();
      this.cookieUserId = window.$cookies.get('browser-local-permits-agent');
    },

    methods: {

      setFormattedActivityDate () {

        // this.activityDateFormatted = this.$moment(this.activityDate).local().format("YYYY-MM-DDTHH:mm:ss.000Z").split('.')[0] + 'Z' //2021-12-19T08:14:25.364Z
        this.activityDateFormatted = this.$moment(this.activityDate).local().format("YYYY-MM-DD") //2021-12-19T08:14:25.364Z


      },

      validateActivityDate (date) {

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date > today

      },

      saveNonMonetaryActivity() {

        if (this.$v.activityName.$invalid || this.$v.activityDate.$invalid || this.$v.statusDescription.$invalid ||
          this.$v.activityDescription.$invalid) {
          if (this.$v.activityName.$invalid) {
            this.activityNameError = true
          }
          if (this.$v.activityDescription.$invalid) {
            this.activityDescriptionError = true
          }
          if (this.$v.activityDate.$invalid) {
            this.activityDateError = true
          }
          if (this.$v.statusDescription.$invalid) {
            this.statusDescriptionError = true
          }
        } else {
          if (!this.myFlag) {
            this.pdfFormatError = false;

            API.getAllAssignmentDetailedByRequest(this.requestId).then(
              response => {
                if (response.data.status) {
                  for (let k = 0; k < response.data.data.length; k++) {
                    if (response.data.data[k].assignmentId === this.activityName) {
                      this.assignedActivityId = response.data.data[k].activityId;

                      this.sendRequestLoader = true;

                      const formData = new FormData();

                      formData.append('file', this.fileData);
                      formData.append('activityId', this.assignedActivityId);

                      if (this.activityDescription !== '') {
                        formData.append('activityDescription', this.activityDescription)
                      } else {
                        formData.append('activityDescription', '')
                      }

                      formData.append('activityDate', this.activityDateFormatted);
                      formData.append('statusId', this.statusDescription);
                      formData.append('requestId', this.requestId);
                      formData.append('userId', this.assignedUserId);

                      API.updateAssignment(JSON.stringify(this.updatedAssigment)).then(
                        response => {
                          if (response.data.status) {

                            this.assignedActivitiesPerUser = [];
                            this.assignedActiveActivitiesPerUserTempo = []
                            this.assignedActiveActivitiesPerUser = []

                            this.sendRequestLoader = false;
                            this.updatedAssigment = {};
                            this.activityDescription = '';
                            this.fileData = '';
                            this.file = '';

                            API.postNonMonetaryActivity(formData).then(
                              response => {
                                if (response.data.status) {
                                  this.SuccessMessage = ', Activity added to Application [ ' + this.requestName + ' ]';
                                  this.SuccessAlert = true;
                                  this.ErrorAlert = false;
                                  this.sendRequestLoader = false;
                                  this.ErrorMessage = '';
                                  this.activityName = '';
                                  this.activityDate = '';
                                  this.activityDateFormatted = ''
                                  this.statusDescription = '';
                                  this.assignedActivityId = '';
                                  this.assignedUserId = '';
                                  this.activityNameError = false;
                                  this.activityDateError = false;
                                  this.loadAssignedActivity()
                                } else {
                                  this.ErrorMessage = ', Activity not added, please contact system administrator';
                                  this.SuccessAlert = false;
                                  this.ErrorAlert = true;
                                  this.sendRequestLoader = false;
                                  this.SuccessMessage = '';
                                  this.activityName = '';
                                  this.activityDate = '';
                                  this.activityDateFormatted = ''
                                  this.fileData = '';
                                  this.file = '';
                                  this.activityNameError = false;
                                  this.activityDateError = false
                                }
                              }
                            ).catch(() => {

                            });

                            this.getAssignments(this.requestId)
                          } else {
                            this.sendRequestLoader = false
                          }
                        }
                      ).catch(() => {
                        this.sendRequestLoader = false
                      });

                      break
                    }
                  }
                } else {

                }
              }).catch(() => {
              this.getAssignmentsLoader = false
            })
          }
        }
      },

      assignedChanged (a) {
        for (let i = 0; i < this.getDetailedAssignment.length; i++) {
          if (this.getDetailedAssignment[i].assignmentId === a) {
            this.updatedAssignmentStatus = this.getDetailedAssignment[i].statusId;
            this.updatedActivityId = this.getDetailedAssignment[i].activityId;
            this.updatedAssigmentId = a;
            this.updatedCreatedBy = this.getDetailedAssignment[i].createdBy;
            this.updatedCreatedDate = this.getDetailedAssignment[i].createdDate;
            this.updatedRemarks = this.getDetailedAssignment[i].remarks;
            this.updatedRequestId = this.getDetailedAssignment[i].requestId;
            this.updatedUserId = this.getDetailedAssignment[i].userId
          }
        }

        this.updatedAssigment = {

          activityId: this.updatedActivityId,
          assignmentId: this.updatedAssigmentId,
          createdBy: this.updatedCreatedBy,
          createdDate: this.updatedCreatedDate,
          remarks: this.updatedRemarks,
          requestId: this.updatedRequestId,
          statusId: this.updatedAssignmentStatus,
          userId: this.updatedUserId
        }
      },

      updateTempStatus (a) {
        this.updatedAssignmentStatus = a;
        this.updatedAssigment.statusId = this.updatedAssignmentStatus
      },

      getAssignments (data) {
        this.notAssignedActivity = [];
        this.optionId = [];
        this.getAssignmentsLoader = true;

        API.getAllAssignmentDetailedByRequest(data).then(
          response => {
            if (response.data.status) {
              this.getDetailedAssignment = response.data.data;

              for (let i = 0; i < this.getDetailedAssignment.length; i++) {

                if (this.getDetailedAssignment[i].userId === parseInt(this.cookieUserId)) {

                  this.assignedActivitiesPerUser.push(this.getDetailedAssignment[i]);
                  this.assignedActiveActivitiesPerUserTempo.push(this.getDetailedAssignment[i])
                  this.assignedUserId = this.getDetailedAssignment[i].userId
                }

              }

              for (let index = 0; index < this.assignedActiveActivitiesPerUserTempo.length; index++) {

                  if (this.assignedActiveActivitiesPerUserTempo[index].statusDescription !== 'Re Assigned' && this.assignedActiveActivitiesPerUserTempo[index].statusDescription !== 'Completed') {

                      this.assignedActiveActivitiesPerUser.push(this.assignedActiveActivitiesPerUserTempo[index])

                  }

              }

              this.getAssignmentsLoader = false;
              this.reaSignAction=false;
            } else {

            }
          }).catch(() => {
          this.getAssignmentsLoader = false
        });
      },

      uploadFile () {
        this.fileData = this.$refs.file.files[0];

        this.file = this.fileData.name;

        if (this.fileData.type !== 'application/pdf') {
          this.pdfFormatError = true;
          this.myFlag = true;
          this.fileData = '';
          this.file = ''
        } else {
          this.pdfFormatError = false;
          this.myFlag = false
        }
      },

      getStatus() {
        API.getStatus().then(
          response => {
            if (response.data.status) {
              let respData=response.data.data;

              //Remove Re Assigned from list
              for(let i=0;i<respData.length;i++){
                if(respData[i].statusDescription!=='Re Assigned' && respData[i].statusDescription!=='New'){
                  this.assignmentStatus.push(respData[i]);
                }
              }
              this.assignedUserData.statusId = 4;
            } else {

            }
          }
        ).catch(e => {

        })
      },

      closeModal() {
        this.$emit('closeModal')
      }
    }
  }
</script>

<style scoped>

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    /*font-weight: 700;*/
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 16px;
    font-weight: normal;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  #label-upload {
    position: relative;
    top: 16px;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
  }

  ul.nav-justified li a:hover {
    color: #d59a18;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }

  .tooltipe:hover .tooltiptext {
    visibility: visible;
    cursor: help;
  }

  .tooltipe:hover .tooltiptext2 {
    visibility: visible;
    cursor: help;
  }

  .insert-bl .save-bl:hover {
    cursor: pointer;
  }

  .error{
    color: red;
  }

  .btn-space {
    margin-right: 10px !important;
  }

</style>

<style>
.mx-datepicker-popup{
  z-index: 9999 !important;
}
</style>
