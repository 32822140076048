<template>
  <div />
</template>
<script>
import { mapActions } from 'vuex'
// import API from "../api/index";

export default {

  name: 'Logout',

  methods: {

    ...mapActions('login',
      ['logout']
    )

    // logoutUser() {
    //
    //   return new Promise((resolve, reject) => {
    //
    //     API.logoutAuthenticatedUser().then(res => {
    //
    //       if (res.data.status) {
    //
    //         this.logout();
    //
    //         this.$router.push("/");
    //
    //       } else {
    //
    //
    //       }
    //
    //       resolve();
    //
    //     }).catch(err => {
    //
    //       reject(err);
    //
    //     });
    //
    //   });
    //
    // }

  },

  created () {
    // this.logoutUser();

    this.logout()

    this.$router.push('/login')
  }

}
</script>
