<template>
  <div>
    <card>

      <ul class="nav-justified">
        <li>
          <a @click.prevent="setMainActive('ShippingOrder')"
             :class="[ isMainActive('ShippingOrder') ? 'is-active-shipping-order' : '' ]" href="#ShippingOrder">Shipping
            Order</a>
        </li>
        <li>
          <a @click.prevent="setMainActive('DeliveryOrder')"
             :class="[ isMainActive('DeliveryOrder') ? 'is-active-delivery-order' : '' ]" href="#DeliveryOrder">Delivery
            Order</a>
        </li>
      </ul>

      <div class="tab-content py-3" id="myMainTabContent">
        <div class="tab-pane fade pt-3" :class="{ 'active show': isMainActive('ShippingOrder') }" id="ShippingOrder">
          <div class="row">
            <div class="col-12">
              <crud-notification
                :SuccessMessage="SuccessMessage"
                :ErrorMessage="ErrorMessage"
                :SuccessAlert="SuccessAlert"
                :ErrorAlert="ErrorAlert"
                @fadeMe="fadeMe"
                @enter="enter">

              </crud-notification>
            </div>
            <div class="col-md-12">
              <br/>
            </div>
          </div>
          <data-table
            :tableHeaders="tableHeaders"
            :tableData="requests"
            :tableDataKeys="tableDataKeys"
            :myLoader="myLoader"
            :myFirstLoader="myFirstLoader"
            :noDataFound="noDataFound"
            :loadingTitle="'Please wait while system is loading Loading agency charges'"
            :hasManageButton="false"
            :hasSearchForm="false"
            :buttonList="[{'code' : 'BillGenerate' , 'name' : 'View Charge' , 'class' : 'btn-primary'} ]"
            :isPageable="false"
            @goToBillGenerate="goToApplication">

          </data-table>
        </div>
        <div class="tab-pane fade pt-3" :class="{ 'active show': isMainActive('DeliveryOrder') }" id="DeliveryOrder">

        </div>
      </div>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">Loading, Please wait</div>
          <div class="col-md-12">
            <br/>
          </div>
          <div class="col-md-12">
            <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import PulseLoader from "vue-spinner/src/PulseLoader.vue";
  import GlobalModal from "../../../../components/globalLoader";
  import DataTable from "../../../../components/DataTable/updatedTable";
  import CrudNotification from "../../../../components/DataTable/crudNotification";

  export default {

    name: 'AgencyRCharges',

    components: {

      PulseLoader,
      GlobalModal,
      DataTable,
      CrudNotification,

    },

    created() {

      this.$store.dispatch("pagenames/currentPageName", "Agency Charges");

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadPageableRequestOnCreated();

      this.$store.dispatch("agencyChargeList/erroralerting", false);
      this.$store.dispatch("agencyChargeList/successalerting", false);

    },

    data() {

      return {

        color: "green",
        size: "12px",
        loading: true,

        noDataFound: false,
        myLoader: false,
        myFirstLoader: false,
        requests: [],

        finalUserPermissions: [],
        tableHeaders: ['shipping Order No', 'Shipper Name', 'container Size','number Of Container' , 'amendment', 'ETA', 'ETD', 'Current Order Status', 'Charged At'],
        tableDataKeys: ['shippingOrderNo', 'shipperName', 'containerSize','numberOfContainer' , 'amendment', 'expectedDepatureDate', 'expectedArrivalDate', 'shippingOrderStatus', 'createdAt'],

        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: "",
        ErrorMessage: "",

        activeMainItem: "ShippingOrder",

      }

    },

    computed: {

      ...mapGetters("notifications", [

        "getNewAppPage"

      ]),

      ...mapGetters({

        getRequestList: "agencyChargeList/getRequestList",
        getSuccessMessage: "agencyChargeList/getSuccessMessage",
        getErrorAlert: "agencyChargeList/getErrorAlert",
        getErrorMessage: "agencyChargeList/getErrorMessage",
        getSuccessAlert: "agencyChargeList/getSuccessAlert",
        getMyLoader: "agencyChargeList/getMyLoader",
        getMyFirstLoader: "agencyChargeList/getMyFirstLoader",
      }),

    },

    methods: {

      ...mapActions("agencyChargeList", [

        "getAllUnbilledShippingOrderCharges",

      ]),

      setAllGetters(flag) {

        this.requests = this.getRequestList.content;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;

      },

      formatDetails(data) {

        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].voyage = data[i].vessel.voyageNo
            data[i].exDepartDate = this.dateToHuman(data[i].expectedDepatureDate);
          }
        }
        return data;

      },


      loadPageableRequestOnCreated() {

        this.getAllUnbilledShippingOrderCharges({}).then(() => {

          this.setAllGetters('onCreated');

        }).catch(() => {


        });

        this.myFirstLoader = this.getMyFirstLoader;

      },

      goToApplication(chargeObject) {

        document.cookie = "browser-local-cashed-url-back=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
        this.$router.push("/shipping-agency/generate-bill/" + chargeObject.id);

      },

      fadeMe: function () {

        if (this.SuccessAlert) {

          this.SuccessAlert = !this.SuccessAlert;
          this.$store.dispatch("agencyChargeList/successalerting", this.SuccessAlert);

        } else {

          this.ErrorAlert = !this.ErrorAlert;
          this.$store.dispatch("agencyChargeList/erroralerting", this.ErrorAlert);

        }

      },

      enter: function (el, done) {

        let that = this;

        setTimeout(function () {

          that.SuccessAlert = false;
          that.ErrorAlert = false;
          this.$store.dispatch("agencyChargeList/erroralerting", that.ErrorAlert);
          this.$store.dispatch("agencyChargeList/successalerting", that.SuccessAlert);

        }, 3000); // hide the message after 3 seconds
      },

      isMainActive(menuMainItem) {

        return this.activeMainItem === menuMainItem;

      },

      setMainActive(menuMainItem) {

        this.activeMainItem = menuMainItem;

        // this.loadActiveTab(menuItem);

      },

    }

  };
</script>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: 150px;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  .is-active-shipping-order, .is-active-shipping-order:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #556b2f !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-delivery-order, .is-active-delivery-order:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #d2b48c !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
</style>
