export const VESSEL_TYPES = [
    { name: "DRY BULK VESSEL", id: "Dry Bulk" },
    { name: "PURE CAR CARRIER VESSEL", id: "Pure Car Carrier" },
    { name: "CONTAINER VESSEL", id: "Container" },
    { name: "LIQUID BULK VESSEL", id: "Liquid Bulk" },
    { name: "MILITARY VESSEL", id: "Military" },
    { name: "CRUISE VESSEL", id: "Cruise" },
    { name: "EXHIBITION VESSEL", id: "Exhibition" },
    { name: "OTHER VESSEL", id: "Other" }
];

export const BILL_STATUSES = [
    { name: "Not approved", id: "Not Approved" },
    { name: "Submitting to GePG", id: "Submiting to GePG" },
    { name: "Waiting control number", id: "Waiting Control Number" },
    { name: "Paid", id: "Paid" },
    { name: "Not paid", id: "Not Paid" },
    // { name: "Cancelling", id: "Canceling" },
    { name: "Cancelled", id: "Canceled" },
    { name: "Expired", id: "Bill Expired" }
];

export const MARITIME_STATUSES = [
    { name: "CREATED", id: "CREATED" },
    { name: "ACTIVE", id: "ACTIVE" },
    { name: "EXECUTED", id: "EXECUTED" },
    { name: "CANCELLED", id: "CANCELLED" },
    { name: "OTHER", id: "OTHER" }
];

export const DATE_CATEGORIES = [
    { name: "EXPECTED ARRIVAL", id: "EXPECTED_ARRIVAL" },
    { name: "EXPECTED ARRIVAL", id: "EXPECTED_DEPARTURE" },
    { name: "ACTUAL ARRIVAL", id: "ACTUAL_ARRIVAL" },
    { name: "ACTUAL DEPARTURE", id: "ACTUAL_DEPARTURE" },
    { name: "CREATED", id: "CREATED" },
];

export const BILL_TYPES = [
    { name: "CLEARING AND FORWARDING", id: "cfa" },
    { name: "SHIPPING TALLY", id: "tally" },
    { name: "SHIPPING AGENCY", id: "agency" },
    { name: "DOCUMENT CONTROL", id: "dc" }
];

export const BILL_CATEGORIES = [
    { name: "AGENCY BILL OF LOADING", id: "BL" },
    { name: "AGENCY DELIVERY ORDER", id: "AGENCY_DO" },
    { name: "AGENCY SHIPPING ORDER", id: "AGENCY_SO" },
    { name: "CLEARING AND FORWARDING", id: "CFA" },
    { name: "DOCUMENT CONTROL", id: "DC" },
    { name: "TALLY LOADING AND DISCHARGE", id: "TALLY_LD" },
    { name: "TALLY STUFFING AND DESTUFFING", id: "TALLY_SD" }
];

export const CARGO_DELIVERY_TYPES = [
    { name: "LOCAL DELIVERY", id: "LOCAL_DELIVERY" },
    { name: "TRANSIT", id: "TRANSIT" }
];

export const CONTRACT_TYPES = [
    { name: 'CHARTER', id: 'Charter' },
    { name: 'LINER', id: 'Liner' },
    { name: 'OTHER', id: 'Other' }
];

export const CFA_APPLICATION_TYPES = [
    { name: 'IMPORT', id: 'IMP' },
    { name: 'EXPORT', id: 'EXP' }
];

export const CFA_REPORT_APPLICATION_TYPES = [
    { name: 'IMPORT', id: 'import' },
    { name: 'EXPORT', id: 'export' }
];

export const CFA_REQUEST_STATUSE = {

    DRAFT: 'Draft Request',
    SUBMITTED: 'Request Submitted',
    REJECTED_MAJOR: 'Request Returned For Major Changes',
    REJECTED_MINOR: 'Request Returned For Minor Changes',
    RETURNED: 'Request Resubmitted After Changes',
    APPROVED: 'Request Accepted',
    ASSESSED: 'Request Assessed',
    REJECTED_ASSESSMENT: 'Request Assessment Rejected',
    CHARGED: 'Request Charged',
    REJECTED_CHARGE: 'Request Charge Rejected',
    BILLED: 'Request Billed',
    BILL_APPROVED: 'Request Assessment Sent To GEPG',
    PAID: 'Request Bill Paid',
    CLOSED: 'Request Closed',
    TO_BE_AMENDED: 'Request amendment',
    AMENDED: 'Request amendment done',
    DEFAULT_AMENDED: 'No amendment',
    DUPLICATE: 'Request is duplicate',
    DISMISSED: "Request is dismissed"

};

export const CFA_REQUEST_STATUSES = [
    
    { name: 'Draft', id: 'DRAFT',  },
    { name: 'Submitted', id: 'SUBMITTED',  },
    { name: 'Returned For Major Changes', id: 'REJECTED_MAJOR',  },
    { name: 'Returned For Minor Changes', id: 'REJECTED_MINOR',  },
    { name: 'Resubmitted After Changes', id: 'RETURNED',  },
    { name: 'Accepted', id: 'APPROVED',  },
    { name: 'Tariffed', id: 'ASSESSED',  },
    { name: 'Assessment Rejected', id: 'REJECTED_ASSESSMENT',  },
    { name: 'Charged', id: 'CHARGED',  },
    { name: 'Charge Rejected', id: 'REJECTED_CHARGE',  },
    { name: 'Billed', id: 'BILLED',  },
    { name: 'Bill Approved', id: 'BILL_APPROVED',  },
    { name: 'Bill Paid', id: 'PAID',  },
    { name: 'Closed', id: 'CLOSED',  },
    { name: 'Amendment', id: 'TO_BE_AMENDED',  },
    { name: 'Amendment done', id: 'AMENDED',  },
    { name: 'No amendment', id: 'DEFAULT_AMENDED',  },
    { name: 'Duplicate', id: 'DUPLICATE',  },
    { name: 'Dismissed', id: 'DISMISSED',  }

];

export const CARGO_UNITS = [
    { name: 'GRAM (G)',            id: 'G' },
    { name: 'KILOGRAM (KG)',       id: 'KG' },
    { name: 'METRIC TONNE (MT)',   id: 'MT' }
];

export const DISMISSAL_REASONS = [
    { name: 'Duplicate',           id: 'DUPLICATE',  },
    { name: 'Order Cancellation',  id: 'ORDER_CANCELLATION',  },
    { name: 'Other',               id: 'OTHER',  }
];

export const NOTIFICATION_TYPES = [
    {name: 'new import', id: 'CFA_NEW_IMPORT_APPLICATION'},
    {name: 'new export', id: 'CFA_NEW_EXPORT_APPLICATION'},
    {name: 'returned', id: 'CFA_APPLICATION_RETURNED_TO_CUSTOMER'},
    {name: 'returned', id: 'CFA_APPLICATION_RETURNED_FROM_CUSTOMER'},
    {name: 'message', id: 'CFA_INCOMING_MESSAGE'},
    {name: 'message', id: 'CFA_CUSTOMER_INCOMING_MESSAGE'}
];

export const MARINE_VESSEL_OPERATIONS = [
    { name: "LOADING", id: "LOADING" },
    { name: "DISCHARGE", id: "DISCHARGE" },
    { name: "DISCHARGE & LOADING", id: "DISCHARGE & LOADING" },
    { name: "BUNKERING", id: "BUNKERING" },
    { name: "CREW CHANGE", id: "CREW CHANGE" },
    { name: "ENGINE REPAIR", id: "ENGINE REPAIR" },
    { name: "DIPLOMATIC", id: "DIPLOMATIC" },
    { name: "CASH TO MASTER (CTM)", id: "CASH TO MASTER (CTM)" },
    { name: "OTHER", id: "OTHER" }
]

export const PRODUCT_TYPES = [
    { name: 'General Products', id: 'General Products' },
    { name: 'Bulk Oil and Gas', id: 'Bulk Oil and Gas' }
];

export const ISSUE_TYPES = [
    { name: 'MAJOR',            id: 'Major Issue' },
    { name: 'MINOR',       id: 'Minor Issue' },
    { name: 'NO ISSUE',   id: 'None' }
];

export const ISSUE_TYPE = {

        MAJOR: 'Major Issue',
        MINOR: 'Minor Issue',
        NONE: 'None'

};

export const CUSTOMER_TYPE = [

    {name: "Clearing And Forwarding", id: "CFA"},
    {name: "Document Control", id: "DC"},

];

export const TARIFF_TYPE = [
    {name: "Clearing And Forwarding", id: "CFA"},
    {name: "Document Control", id: "DC"},
    {name: "Ship Tallying", id: "TALLY"},
    {name: "Shipping Agency", id: "AGENCY"},

];

export const TASAC_TIN = '138486419'


