<template>
<div>
<card>
  <modal v-if="modal">
      <div slot="body">
        <card>
          <div class="row">
            <div class="col-md-6">
              <label for="code">Attachment Name</label>
              <input type="text" class="form-control" id="name" v-model="attachmentTypesName">
              <div v-if="!$v.attachmentTypesName.required && attachmentTypesNameError" class="text-danger">
                    This field is required
              </div>
            </div>
            <div class="col-md-6">
              <label for="code">Attachment Code</label>
              <input type="text" class="form-control" id="code" v-model="attachmentGroupCode">
              <div v-if="!$v.attachmentGroupCode.required && attachmentGroupCodeError" class="text-danger">
                    This field is required
              </div>
            </div>
            <div class="col-md-12 mt-5">
              <div class="row">
                <div class="col-md-12"><hr /></div>
                <div class="col-md-10 text-right pt-2">
                  <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                  />
                </div>
                <div class="col-md-2">
                  <button @click="closeModal"
                  :disabled="loading"
                  class="btn btn-fill btn-outline-info float-right custom-btn-all"
                  type="button"> Close</button>
                  <button @click.prevent="addAttahmentsType"
                  :disabled="loading"
                  class="btn btn-primary float-right mr-2 custom-btn"
                  type="button">{{ edit? "Edit" : "Save" }}</button>
                </div>
              </div>
            </div>
          </div>
        </card>
    </div>
  </modal>

  <div class="row mb-3">
    <div class="col-md-12">
    <button
      class="btn btn-primary mr-2 custom-btn"
      style="float:right;"
      @click.prevent="openModal()"
    >
      <b>Add Attachment Type</b>
    </button>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-12">
      <data-table
        :tableHeaders="tableHeaders"
        :tableDataKeys="tableDataKeys"
        :tableData="filteredRequests"
        :pageSizeProp="pageSize"
        :pageNo="pageNo"
        :myFirstLoader="myFirstLoader"
        :noDataFound="noDataFound"
        :loadingTitle="'Please wait while the system is loading delivery order attachment types'"
        :searchPlaceholder="'Search by attachment type'"
        :has-view-button="false"
        :has-custom-button="true"
        custom-button-name="Edit"
        :totalElements="totalElements"
        :totalPages="totalPages"
        :isFirst="isFirst"
        :isLast="isLast"
        :pageNumber="pageNumber"
        :hasFilter="false"
        :hasPlainFilter="true"
        :permissions="[ {key: 'listPermission', value: 'DELIVERY-ORDER-ATTACHMENT-TYPE_LIST-PAGEABLE_GET'} ]"
        @fireCustomMethod="selectedType"
        @searchPlain="searchAttachmentType"
      >
      <div slot = "pagination">
        <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </paginate>
      </div>
      </data-table>
    </div>
  </div>
</card>
<global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading Applications, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
</div>
</template>

<script>
import AGENY_API from "../../../api/agency";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from "../../../components/Inputs/Modal";
import DataTable from '../../../components/DataTable/dataTable';
import { required } from 'vuelidate/lib/validators'
import GlobalModal from '../../../components/globalLoader'
import { mapActions, mapGetters } from 'vuex'


export default {

  name: "deliveryOrderAttachmentType",

  components: {

    PulseLoader,
    Modal,
    DataTable,
    GlobalModal

  },

  computed: {

    ...mapGetters('doattachmenttype', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      'getPageSize',
      'getRequestId',
      'getTotalElement',
      'getTotalPage',
      'getPageNumber',
      'getIsFirst',
      'getIsLast'

    ]),

    getIsPage: {

      get: function () {
          return this.isPage
      },
      set: function (newValue) {
          this.isPage = newValue;
      }

    }

  },

  created() {

      this.loadPageableDeliveryOderAttachmentsOnCreated()

  },

  data(){

    return{

      isPage: 0,
      attachementTypes:[],
      requests: [],
      filteredRequests: [],
      loading: false,
      color: 'green',
      size: '12px',
      pageCount:0,
      pageNo:0,
      pageSize: 15,
      tableHeaders:["Attachment Name","Attachment Code"],
      tableDataKeys:["attachmentTypesName","attachmentGroupCode"],
      noDataFound:false,
      modal:false,
      addModal:false,
      editModal:false,
      attachmentGroupCode: "",
      attachmentTypeId: null,
      edit:false,
      attachmentTypesName: "",
      totalElements: "",
      totalPages: "",
      isFirst: false,
      isLast: false,
      pageNumber: '',
      myLoader: false,
      myFirstLoader: false,
      attachmentTypesNameError: false,
      attachmentGroupCodeError: false

    }
  },

  validations: {

    attachmentTypesName: { required },
    attachmentGroupCode: { required },

  },

  methods:{

    ...mapActions('doattachmenttype', [

      'loadDeliveryOderAttachmentsPageableOnCreated',
      'loadDeliveryOderAttachmentsPageableAfterCreated',
      'searchWithGeneralChargeParams'

    ]),

    sortingData(data) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {

          ...data[x]

          }

          this.filteredRequests.push(requestObject)

      }

    },

    setAllGetters(flag) {

      this.requests = this.getRequestList;
      flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
      flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
      this.noDataFound = this.getNoDataFound;
      this.SuccessMessage = this.getSuccessMessage;
      this.ErrorMessage = this.getErrorMessage;
      this.ErrorAlert = this.getErrorAlert;
      this.SuccessAlert = this.getSuccessAlert;
      this.pageNo = this.getPageNo;
      this.pageSize = this.getPageSize;

      this.totalElements = this.getTotalElement;
      this.totalPages = this.getTotalPage;
      this.pageNumber = this.getPageNumber;
      this.isFirst = this.getIsFirst;
      this.isLast = this.getIsLast;

      this.sortingData(this.requests)

    },

    loadParameters() {

      return new Promise((resolve) => {

        this.$store.dispatch('doattachmenttype/pageSizing', this.pageSize)
        this.$store.dispatch('doattachmenttype/pageNumbering', this.pageNo)

        resolve()

      })

    },

    loadPageableDeliveryOderAttachmentsOnCreated() {

      this.filteredRequests = []

      this.loadParameters().then(() => {

        this.loadDeliveryOderAttachmentsPageableOnCreated({}).then(() => {

          this.setAllGetters('onCreated')

        }).catch(() => {})

        this.myFirstLoader = this.getMyFirstLoader
      })

    },

    loadPageableDeliveryOderAttachmentsAfterCreated(pageNo , pageSize) {

        this.loadParameters(pageNo , pageSize).then(() => {

          this.loadDeliveryOderAttachmentsPageableAfterCreated({}).then(() => {

            this.setAllGetters('afterCreated');

          });

          this.myLoader = this.getMyLoader;

        }).catch(() => {


        });

      },

    onChange(event) {

      this.$store.dispatch("doattachmenttype/pageSizing", event);
      this.$store.dispatch("doattachmenttype/pageNumbering", 0);
      this.isPage = 0

      this.pageSize = this.getPageSize;
      this.pageNo = this.getPageNo;

      this.loadPageableDeliveryOderAttachmentsOnCreated(this.pageNo , this.pageSize);
      this.filteredRequests = []

    },

    goToPage(page) {

      if(page > 0) {page = page - 1;}
      this.isPage = page + 1

      this.pageNo = page;

      this.loadPageableDeliveryOderAttachmentsAfterCreated(this.pageNo , this.pageSize);
      this.filteredRequests = []

    },

    selectedType(data) {

      this.attachmentGroupCode = data.attachmentGroupCode;
      this.attachmentTypesName = data.attachmentTypesName;
      this.attachmentTypeId = data.attachmentTypeId;
      this.edit = true;

      this.openModal();

    },

    addAttahmentsType() {

      if (this.$v.attachmentGroupCode.$invalid) {

        this.attachmentGroupCodeError = true;

      }
      if (this.$v.attachmentTypesName.$invalid) {

        this.attachmentTypesNameError = true;

      } else {

        this.loading = true;

        if(!this.edit) {

          AGENY_API.addDeliveryOrderAttachmentsTypes({
            attachmentGroupCode: this.attachmentGroupCode.toUpperCase(),
            attachmentTypeId: null,
            attachmentTypesName: this.attachmentTypesName.toUpperCase(),
          }).then((resp) => {

            this.loading = false;

            if(resp.data.status) {

              
              this.loadPageableDeliveryOderAttachmentsAfterCreated(0 , this.pageSize)

              this.loadNotification('success', 4000, 'Attachment Save',
              'Attachment has been successfully saved',
              'response', 1000 , true  , true)

            } else {

              this.loadNotification('error', 4000, 'Attachment Save',
              'Attachment not saved',
              'response', 1000 , true  , true)
            }

            this.modal = false;

          }).catch(() => {

            this.loading = false
            this.loadNotification('error', 4000, 'Attachment Save',
              'Attachment not saved, please check your internet connection',
              'response', 1000 , true  , true)

          })

        } else {

          AGENY_API.updateDeliveryOrderAttachmentsTypes({
            attachmentGroupCode: this.attachmentGroupCode.toUpperCase(),
            attachmentTypeId: this.attachmentTypeId,
            attachmentTypesName: this.attachmentTypesName.toUpperCase(),
          }).then((resp) => {

            this.loading = false;

            if(resp.data.status){

                this.loadPageableDeliveryOderAttachmentsAfterCreated(0 , this.pageSize)

                this.attachmentGroupCode = ''
                this.attachmentTypesName = ''
              
                this.loadNotification('success', 4000, 'Attachment Save',
                'Attachment has been successfully saved',
                'response', 1000 , true  , true)

            } else {

              this.loadNotification('error', 4000, 'Attachment Save',
              'Attachment not saved',
              'response', 1000 , true  , true)

            }

            this.modal = false
            this.edit = false

          }).catch(() => {

            this.loading = false
            this.loadNotification('error', 4000, 'Attachment Save',
              'Attachment not saved, please check your internet connection',
              'response', 1000 , true  , true)

          })

        }
        
      }

    },

    loadSearchParameters(params) {

        // let fromApplicationPageNoOrNot
        // typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0

        // let firstPart = params.split("&page=")[0]
        // let secondPart = firstPart.split("size=")[0]
        // this.newParams = ""

        // this.isPage = fromApplicationPageNo
        // this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + fromApplicationPageNoOrNot

        return new Promise((resolve) => {

          this.$store.dispatch("cfabilling/pageSizing", this.pageSize);
          this.$store.dispatch("cfabilling/pageNumbering", 0);
          this.$store.dispatch("cfabilling/paramsSearching", params);

          resolve();

        })

      },

    searchAttachmentType(params) {

        this.newParams = params

        this.filteredRequests = []

        this.loadSearchParameters(params).then(() => {

          this.searchWithGeneralChargeParams({}).then(() => {

            this.setAllGetters('onCreated')

          }).catch(() => {


          });

          this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {


        });

      },

    openModal(){

      this.modal = true;

    },

    closeModal(){

      this.modal=false;
      this.addModal=false;
      this.editModal=false;

      this.attachmentGroupCode="";
      this.attachmentTypesName="";
      this.attachmentTypeId=null;
      this.edit=false;

    }
  }
}
</script>

<style scoped>

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

</style>
