<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="search">Search</label>
            <input type="text" class="form-control" id="search" v-model="searchParameter">
            <div v-if="searchParameterError">
              <div class="error" v-if="!$v.searchParameter.required">
                This field is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right mt-4">
            <button class="btn btn-primary custom-btn mr-2" :disabled="loading" @click.prevent="searchAttachmentType">Search</button>
            <button class="btn btn-fill btn-outline-info custom-btn-all" :disabled="loading" @click.prevent="clearSearch">Clear search</button>
        </div>
      </div>
    </card>

    <card>
    <modal v-if="AddModalDiv">
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <form
              method="POST"
              @submit.prevent="saveAttachmentType"
            >
              <div class="row">
                <div class="col-md-9">
                  <base-input
                    type="text"
                    label="Name"
                    placeholder="Name"
                    v-model="attachmenttype.attachmentTypesName"
                    class="special-bottom"
                  />
                  <div v-if="attachmentTypesNameError">
                    <div
                      class="error"
                      v-if="!$v.attachmenttype.attachmentTypesName.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <base-input
                    type="text"
                    label="Group Code"
                    placeholder="Group Code"
                    v-model="attachmenttype.attachmentGroupCode"
                    class="special-bottom"
                  />
                  <div v-if="attachmentTypesGroupError">
                    <div
                      class="error"
                      v-if="!$v.attachmenttype.attachmentGroupCode.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Is mandatory attachment?</label>
                  <select class="form-control" v-model="attachmenttype.mandatory">
                    <option v-for="mandatoryType in mandatoryTypes" :value="mandatoryType.id" :key="mandatoryType.id">{{ mandatoryType.name }}</option>
                  </select>
                  <!-- <model-list-select
                    :list="mandatoryTypes"
                    v-model="attachmenttype.mandatory"
                    class="form-control"
                    option-value="id"
                    option-text="name"
                    placeholder="Mandatory"
                  >
                  </model-list-select> -->
                </div>
                <div class="col-md-6">
                  <label>Operation Type</label>
                  <select class="form-control" v-model="attachmenttype.category">
                    <option v-for="attachmentCategory in attachmentCategories" :value="attachmentCategory.id" :key="attachmentCategory.id">{{ attachmentCategory.name }}</option>
                  </select>
                  <!-- <model-list-select
                    :list="attachmentCategories"
                    v-model="attachmenttype.category"
                    class="form-control"
                    option-value="id"
                    option-text="name"
                    placeholder="Operation Type"
                  >
                  </model-list-select> -->
                </div>
              </div>
              <div class="text-center row">
                <div class="col-md-7 text-right loader-div">
                  <span v-if="editMyLoader">Please wait ....</span>
                </div>
                <div class="col-md-2 left loader-div-gif">
                  <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                    v-if="editMyLoader"
                  />
                </div>
                <div
                  class="col-md-3"
                  v-if="AddModalDiv"
                >
                  <button
                    type="button"
                    class="btn btn-fill btn-outline-info float-right"
                    @click="closeModal()"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary btn-space  float-right"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </modal>
    <div class="row">
      <div class="col-md-12">
            <button class="btn btn-success float-right custom-btn" @click.prevent="openModal(null,true)">Add new attachment</button>
        <!-- <span class="float-right pointer" @click="openModal(null,true)">
        <font-awesome-icon icon="plus" color="green" size="lg"/>
        </span> -->
      </div>
      <div class="col-md-12">
        <data-table
          :tableHeaders="tableHeaders"
          :tableData="filteredRequests"
          :tableDataKeys="tableDataKeys"
          :pageSizeProp="pageSize"
          :pageNo="pageNo"
          :hasFilter="false"
          :myLoader="loading"
          :myFirstLoader="loading"
          :noDataFound="noDataFound"
          :searchParameterProp="searchParameter"
          :loadingTitle="'Please wait while system is loading Attachment Types'"
          :hasManageButton="false"
          :totalElements="totalElements"
          :totalPages="totalPages"
          :isFirst="isFirst"
          :isLast="isLast"
          :isHovered="[]"
          :pageNumber="pageNumber"
          :has-custom-button="true"
          custom-button-name="Edit"
          :is-hovered="false"
          :permissions="[ {key: 'searchPermission', value: 'ATTACHMENTS_CONFIG_POST'} ]"
          @fireCustomMethod="editAttachmentType"
          @onChange="onChange"
        >
          <div slot = "pagination">
            <paginate
              v-model="getIsPage"
              :page-count=parseInt(totalPages)
              :page-range="3"
              :margin-pages="2"
              :click-handler="goToPage"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          </div>
        </data-table>

      </div>

    </div>

  </card>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import {ModelListSelect} from 'vue-search-select';


export default {

  name: 'AttachmentType',

  components: {

    PulseLoader,
    Modal,
    ModelListSelect,
    DataTable: () => import('../../../components/DataTable/dataTable'),

  },

  computed:{

    ...mapGetters('attachmenttypes', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete'

    ]),

    getIsPage: {

      get: function () {
        return this.isPage
      },
      set: function (newValue) {
        this.isPage = newValue;
      }

    }
  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Application Attachment Types')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableAttachment()
  },

  data () {
    return {

      tableHeaders:['Name','Group Code','Mandatory','Category'],
      tableDataKeys:['attachmentTypesName','attachmentGroupCode','mandatory','category'],

      array: [],
      finalUserPermissions: [],
      getAttachmentType: [],
      filteredRequests:[],

      data: {},

      color: 'green',
      size: '15px',
      loading: false,

      SuccessMessage: '',
      ErrorMessage: '',

      attachmentTypesName: '',
      attachmentGroupCode: '',
      attachmentTypeId: '',
      searchParamenter:'',
      searchParameterError:false,
      error: '',

      attachmenttype: {
        attachmentTypeId:null,
        attachmentTypesName: '',
        attachmentGroupCode: '',
        mandatory:false,
        category:"BOTH",
      },

      mandatoryTypes:[

        {name: "No", id: false},
        {name: "Yes", id: true}

      ],
      attachmentCategories:[
        {name: "BOTH", id: "BOTH"},
        {name: "EXPORT", id: "EXPORT"},
        {name: "IMPORT", id: "IMPORT"},
      ],

      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      attachmentTypesNameError: false,
      attachmentTypesGroupError: false,
      searchData: true,

      pageNo: 0,
      pageSize: 15,
      totalElements: "",
      totalPages: "",
      isFirst: false,
      isLast: false,
      pageNumber: '',
      pageCount: 1,
      noDataFound:false,
      searchParameter: '',
      isPage: 0,
      edit:false,

    }
  },

  validations: {

    attachmenttype: {

      attachmentTypesName: { required },
      attachmentGroupCode: { required }

    },

    searchParameter: { required },

    attachmentTypesName: { required },
    attachmentGroupCode: { required }

  },

  methods: {

    goToPage(page) {
      console.log(page);
      if(page > 0) {page = page - 1;}
      console.log('rr',page)
      this.isPage = page + 1;
      this.pageNo=page;

      this.loadPageableAttachment();

    },

    onChange(event) {
      this.pageSize=event;
      this.pageNo=0;
      this.loadPageableAttachment();
    },


    sortingData(data) {
      this.filteredRequests=[];
      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {
          ...data[x],
          mandatory:data[x].mandatory? 'YES':'NO',
          createdDate: this.dateTimeToHuman(data[x].createdDate),
        };

        this.filteredRequests.push(requestObject)

      }

    },

    clearSearch () {

      this.loadPageableAttachment()
      this.searchParameter = ''

    },

    loadPageableAttachment () {

      this.loading = true;

      API.getPagebleAttachments(this.pageNo, this.pageSize, this.sortBy).then(
        response => {
          if (response.data.status) {
            this.loading = false;

            this.totalPages=response.data.data.totalPages;
            this.totalElements=response.data.data.totalElements;
            this.pageNumber=response.data.data.number;
            this.isFirst=response.data.data.first;
            this.isLast=response.data.data.isLast;

            if (response.data.data.content.length > 0) {
              this.noDataFound=false;
              this.sortingData(response.data.data.content);
            }else {
              this.noDataFound=true;
            }

            this.myloader = false
          } else {
            this.noDataFound=true;
          }

        }
      ).catch(e => {
        this.loading = false;
        this.notifyUser('error','Error','Failed to load Attachment Types');
      });

    },

    toggle () {

      this.showSection = !this.showSection

    },

    searchAttachmentType () {

      if (this.$v.searchParameter.$invalid) {

        this.searchParameterError=true;

      } else {

        this.loading = true;
        this.reloadAllRequest = true;
        this.hasSearchedVariable = true;
        this.searchParameterError=false;

        API.searchAttachment(this.searchParameter,this.pageNo, this.pageSize).then(
          response => {

            if (response.data.status) {

              this.loading = false;

              this.totalPages=response.data.data.totalPages;
              this.totalElements=response.data.data.totalElements;
              this.pageNumber=response.data.data.number;
              this.isFirst=response.data.data.first;
              this.isLast=response.data.data.isLast;

              if (response.data.data.content.length > 0) {

                this.noDataFound=false;
                this.sortingData(response.data.data.content);

              }else {

                this.noDataFound=true;

              }

            } else {

              this.loading = false

            }
          }
        ).catch(e => {

          this.notifyUser('error','Error','Failed to Search Attachment Types');

        })
      }
    },


    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadPageableAttachment();
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParamenter = ''
      }
    },

    reloadByIcon () {
      this.loadPageableAttachment();

      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParamenter = ''
    },

    hasSearched () {
      this.hasSearchedVariable = true
    },

    loadSingleAttachmentType (data) {
      API.getIndividualAttachment(data).then(
        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        }
      ).catch(e => {
        // this.state.errors.push(e)
      })
    },

    saveAttachmentType () {

      if (this.$v.attachmenttype.attachmentTypesName.$invalid || this.$v.attachmenttype.attachmentGroupCode.$invalid) {
        if (this.$v.attachmenttype.attachmentTypesName.$invalid) {
          this.attachmentTypesNameError = true
        }
        if (this.$v.attachmenttype.attachmentGroupCode.$invalid) {
          this.attachmentTypesGroupError = true
        }
      } else {

        this.editMyLoader = true;

        let toSave = {

            attachmentTypeId:this.attachmenttype.attachmentTypeId,
            attachmentTypesName: this.attachmenttype.attachmentTypesName,
            attachmentGroupCode: this.attachmenttype.attachmentGroupCode,
            mandatory:this.attachmenttype.mandatory,
            category:this.attachmenttype.category,

        };

        if(this.edit){

          API.updateAttachment(toSave).then(
            response => {

              if (response.data.status) {

                this.loadNotification('success', 4000, 'Success', 'Attachment type has been successfully updated', 'response', 1000 , true  , true);
                this.loadPageableAttachment();
                this.closeModal();

              } else {

                this.loadNotification('error', 4000, 'Success', 'Attachment not updated', 'response', 1000 , true  , true);

              }
            }
          ).catch(() => {

            this.loadNotification('error', 4000, 'Success', 'Attachment not updated', 'response', 1000 , true  , true);

          });


        }else {

          API.postAttachment(toSave).then(
            response => {

              if (response.data.status) {

                this.attachmenttype.attachmentTypesName = '';
                this.attachmenttype.attachmentGroupCode = '';
                this.loadPageableAttachment();
                this.closeModal();
                this.loadNotification('success', 4000, 'Success', 'Attachment type has been successfully saved', 'response', 1000 , true  , true);

              } else {

                this.loadNotification('error', 4000, 'Success', 'Attachment not saved', 'response', 1000 , true  , true);

              }
            }
          ).catch(() => {

            this.loadNotification('error', 4000, 'Success', 'Attachment not saved', 'response', 1000 , true  , true);

          })

        }

      }
    },

    editAttachmentType (data) {

      this.edit = true;
      let newDataObject = {

        ...data,
        mandatory: data.mandatory === 'YES' ? true : false


      }

      this.openModal(newDataObject, false);
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      var that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
      }, 3000) // hide the message after 3 seconds
    },

    openModal (list,isnew) {
      
      this.AddModalDiv = true;
      if(isnew === true){

      } else {

        this.attachmenttype = list;

      }
    },

    closeModal () {

      this.AddModalDiv = false;
      this.edit=false;

      this.attachmenttype={
        attachmentTypeId:null,
          attachmentTypesName: '',
          attachmentGroupCode: '',
          mandatory:false,
          areaUsed:"BOTH",
      };
      this.attachmentTypesNameError = false;
      this.attachmentTypesGroupError = false;
    }

  }

}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  .pointer{
    cursor: pointer;
  }

  .custom-btn-all {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
        background-color: #FFFFFF !important;
    }
</style>
