<template>
  <div>
    <div
      class="row"
      v-if="myFirstLoader">
      <div class="col-md-12 text-center">
        <b>{{ loadingTitle }}</b>
      </div>
      <div class="col-md-12 text-center">
        <br>
      </div>
      <div class="col-md-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        />
      </div>
    </div>
    <div
      class="row"
      v-if="!myFirstLoader">
      <div
        class="col-md-6 noprint"
        id="entries">
        <div
          class="row"
          v-if="isPageable">
          <div class="col-md-3 text-left">
            Entries per page
          </div>

          <div class="col-md-2 float-left">
            <select
              name="PageSize"
              @change="onChange"
              class=""
              v-model="pageSize"
              style="width:73px;"
              :class="[ allowPerPageSelection <= 1 ? 'disabled-click' : 'enabled-click']">
              <option value="15">
                15
              </option>
              <option value="25">
                25
              </option>
              <option value="50">
                50
              </option>
              <option value="75">
                75
              </option>
              <option value="100">
                100
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6 noprint" id="queryDate">
        <div
          class="row"
          v-if=hasSearchForm>
          <div
            class="col-md-12 text-right"
            v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH-FLAG_{REQUESTTYPEFLAG}_POST')
              && hasDateQuery">
            <form
              class="search"
              @submit.prevent="queryByDate">
              <div class="form-group float-right">
                <div
                  class="input-group"
                  :class="[ parseInt(totalPages) === 0 && !noDataFound ? 'disabled-click' : 'enabled-click']">
                  <div
                    class="refresh-all"
                    title="load all requests"
                    v-if="reloadAllRequest"
                    @click.prevent="reloadDateQueryByIcon">
                    <font-awesome-icon icon="sync-alt"/>
                  </div>
                  <label
                    for="dateQuery"
                    style="margin-right: 4px">Search by {{ dateProp }}
                    date </label>
                  <input
                    placeholder="Date"
                    id="dateQuery"
                    v-model="dateParameter"
                    style="min-width: 300px"
                    @input="loadDateDetails"
                    type="date"
                    pattern="\d{4}-\d{2}-\d{2}">
                  <span
                    class="input-group-addon"
                    style="margin-left:4px "
                    @click.prevent="queryByDate">
                    <font-awesome-icon icon="search"/></span>
                </div>
              </div>
            </form>
          </div>
          <div
            class="col-md-12 text-right"
            v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH-FLAG_{REQUESTTYPEFLAG}_POST')
              && !hasDateQuery">
            <form
              class="search"
              @submit.prevent="searchRequest">
              <div class="form-group float-right">
                <div
                  class="input-group"
                  :class="[ parseInt(totalPages) === 0 && !noDataFound ? 'disabled-click' : 'enabled-click']">
                  <div
                    class="refresh-all"
                    title="load all requests"
                    v-if="reloadAllRequest"
                    @click.prevent="reloadByIcon">
                    <font-awesome-icon icon="sync-alt"/>
                  </div>
                  <input
                    type="text"
                    :placeholder="placeholder"
                    id="inputGroup"
                    v-model="searchParameter"
                    @input="loadAllPageable"
                    class="normal-search">
                  <span
                    class="input-group-addon"
                    @click.prevent="searchRequest">
                    <font-awesome-icon icon="search"/></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row text-right" v-if=hasRefreshButton>
          <button class="btn mr-2 btn-primary custom-btn"
                  v-if="!myLoader"
                  @click.prevent="goToRefresh()">
            <b>Refresh</b>
          </button>
        </div>
      </div>
      <div class="col-md-12 table-responsive">
        <table class="table col-md-12 table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th v-for="(tableHeader , indexHT) in tableHeaders" :key="indexHT">
              {{ tableHeader }}
            </th>
            <th class="text-center noprint"
                v-if="hasDuplicateButton || hasEditButton || hasViewButton || hasManageButton
                    || hasCustomButton || hasDeleteButton || buttonList.length > 0">
              Action
            </th>
            <th style="display: none"/>
          </tr>
          </thead>
          <tbody>
          <!--   For data that HAS "completed" field name and should only show rows whose completed status is false  -->
          <tr v-for="(data , indexTD) in tableData" :key="indexTD" v-if="!noDataFound">
            <!-- && typeof data.completed !== 'undefined' && disabled && !data.completed  -->
            <td v-if="isPageable">
              {{ (indexTD + (parseInt(pageSize) * parseInt(pageNumber))) + 1 }}
            </td>
            <td v-if="!isPageable">
              {{ indexTD + 1 }}
            </td>
            <td v-for="(tableDataKey , index_tableDataKey) in tableDataKeys" :key="index_tableDataKey" v-bind:class="{ rejected: redStatusKey?data[redStatusKey.key]=== redStatusKey.value:false}"
                @mouseover="hover = true, currentId = indexTD, getMouseLocation($event)" @mouseleave="hover = false ">
                <span v-if="hasAdditionalDetails && popupKeys">
                  <span v-if="hover && indexTD ===  currentId && tableDataKey === 'containerNo'" class="blur-popup popup-info" v-bind:style="styleObject">
                    <p v-for="(popupkey, index_popupkey) in popupKeys" style="line-height: 1rem" :key="index_popupkey">
                      <span v-if="data[popupkey]">
                      {{camelCaseToTitleCase(popupkey)}}: {{data[popupkey] ? data[popupkey] : '' }}
                      </span>
                    </p>
                  </span>
                </span>

              <span v-if="tableDataKey === 'customer' || tableDataKey === 'company'">
                  <span class="more-customer-details" @click="showToolTip(data)">
                    {{ typeof data[tableDataKey].name !== "undefined" ? (data[tableDataKey].name).toUpperCase() : (data[tableDataKey].companyName).toUpperCase()}}</span>
                  <span v-if="isHovered.includes(data)" class="customer-info">
                    <p><u><b>Customer Information</b> (click <a @click="hideToolTip(data)"
                                                                class="close-info">here</a> to close)</u></p>
                    <b>TIN:</b> {{ typeof data[tableDataKey].tin !== "undefined" ? data[tableDataKey].tin : data[tableDataKey].tinNo }}<br>
                    <b>VRN:</b> {{ data[tableDataKey].vrn }} <br>
                    <b>MOBILE:</b> {{ typeof data[tableDataKey].mobile !== "undefined" ? data[tableDataKey].mobile : data[tableDataKey].companyTelephone }} <br>
                    <b>EMAIL:</b> {{ data[tableDataKey].email }} <br>
                    <b>CUSTOMER TYPE:</b>
                    {{ data[tableDataKey].customerType === 'ind001' ? 'Individual' : 'company' }} <br>
                  </span>
                </span>
              <span v-if="tableDataKey === 'sealNumbers' && data[tableDataKey] !== null">
                  <span class="more-customer-details" @click="showToolTip(data)">
                    {{ (data[tableDataKey].sealNumber).toUpperCase() }}</span>
                  <span v-if="isHovered.includes(data)" class="customer-info">
                    <p><u><b>Seal Information</b> (click <a @click="hideToolTip(data)" class="close-info">here</a> to close)</u></p>
                    <b>TIN:</b> {{ data[tableDataKey].sealNumber }}<br>
                    <b>VRN:</b> {{ data[tableDataKey].sealNumberIssuer }} <br>
                    <b>MOBILE:</b> {{ data[tableDataKey].sealNumberCondition }} <br>
                    <b>EMAIL:</b> {{ data[tableDataKey].description }} <br>
                  </span>
                </span>
              <span v-if="tableDataKey !== 'customer'&& tableDataKey !== 'grossWeight' && tableDataKey !== 'company'&&
                  tableDataKey !== 'tareWeight' && tableDataKey !== 'netWeight' &&
                  tableDataKey !== 'requestDate' && tableDataKey !== 'createdAt' &&
                  tableDataKey !== 'createDateTime' &&
                  tableDataKey !== 'updatedAt' && tableDataKey !== 'completed' &&
                  tableDataKey !== 'fullEmptyStatus' &&
                  tableDataKey !== 'billed' && tableDataKey !== 'grossWeight' &&
                  tableDataKey !== 'updatedAt' && tableDataKey !== 'isCancelled' &&
                  tableDataKey !== 'dateBilled' && tableDataKey !== 'isBilled' &&
                  tableDataKey !== 'active' && tableDataKey !== 'billVatedAmountTzs' &&
                  tableDataKey !== 'assessmentNotice' && tableDataKey !== 'sealNumbers' &&
                  tableDataKey !== 'containerSize' && tableDataKey !== 'startTime' &&
                  tableDataKey !== 'endTime' && tableDataKey !== 'eta' && tableDataKey !== 'etd' &&
                  tableDataKey !== 'ata' && tableDataKey !== 'atb' && tableDataKey !== 'notification' &&
                  tableDataKey !== 'atc' && tableDataKey !== 'atd' && tableDataKey !== 'containerSize' &&
                  tableDataKey !== 'closed' && tableDataKey !== 'document' && tableDataKey !== 'sharedByClerks' &&
                  tableDataKey !== 'mayShiftShipBeforMAKAYEMAKAYEeCompletion'" @mouseover="hover = true, currentId = indexTD" @mouseleave="hover = false">
              {{ data[tableDataKey] }}
              </span>
              <span v-if="tableDataKey === 'assessmentNotice.invoiceNumber'">
                  {{ data['assessmentNotice'].invoiceNumber }}
              </span>
              <span v-if="tableDataKey === 'assessmentNotice.tansadNo'">
                  {{ data['assessmentNotice'].tansadNo }}
              </span>
              <span v-if="tableDataKey === 'documentTasacControlNumber'">
                  {{ data['document'] !== null ? data['document'].tasacControlNumber : data['manifest'].applicationReference }}
                </span>
                <span v-if="tableDataKey === 'mailSent'">
                  {{ data.mailSent ? 'MAILED' : 'NOT MAILED' }}
                </span>
                <span v-if="tableDataKey === 'documentChargeStatus'">
                  {{ data.billed ? 'BILLED' : 'CHARGED' }}
                </span>
              <span v-if="tableDataKey === 'documentCarrierName'">
                  {{ data['document'] !== null ? data['document'].carrierName : data['manifest'].senderId }}
                </span>
              <span v-if="tableDataKey === 'documentVoyageNumber'">
                  {{ data['document'] !== null ? data['document'].voyageNumber : data['manifest'].voyageNumber }}
                </span>
              <span v-if="tableDataKey === 'notification'">
                  {{ data[tableDataKey].vesselName }}
                </span>
              <span v-if="tableDataKey === 'containerSize'">
                  {{ getContainerName(data[tableDataKey]) }}
                </span>
              <span v-if="tableDataKey === 'dateBilled'">
                  {{data[tableDataKey] ? dateToHuman(data[tableDataKey]) : ''}}
                </span>
              <span v-if="tableDataKey === 'requestDate'">
                  {{ data[tableDataKey] ? dateTimeToHuman(data[tableDataKey]) : ''  }}
                </span>
              <span v-if="tableDataKey === 'startTime'">
                  {{ data[tableDataKey] ? dateTimeToHuman(data[tableDataKey]) : ''  }}
                </span>
              <span v-if="tableDataKey === 'endTime'">
                  {{ data[tableDataKey] ? dateTimeToHuman(data[tableDataKey]) : ''  }}
                </span>
              <span v-if="tableDataKey === 'createdAt' || tableDataKey === 'createDateTime' ||
                  tableDataKey === 'updatedAt'||
                  tableDataKey === 'eta' || tableDataKey === 'etd' ||
                  tableDataKey === 'ata' || tableDataKey === 'atb' ||
                  tableDataKey === 'atc' || tableDataKey === 'atd'">
                  {{ data[tableDataKey] ? dateTimeToHuman(data[tableDataKey]) : ''  }}
                </span>
              <span v-if="tableDataKey === 'tareWeight' ">
                  {{ data[tableDataKey] }} {{ data['tareWeightUnit'] }}
                </span>
              <span v-if="tableDataKey === 'isCancelled'">
                  {{ checkBooleanValue(data[tableDataKey]) }}
                </span>
              <span v-if="tableDataKey === 'grossWeight' ">
                  {{ data[tableDataKey] }} {{ data['grossWeightUnit'] }}
                </span>
              <span v-if="tableDataKey === 'netWeight' ">
                  {{ data[tableDataKey] }} {{ data['netWeightUnit'] }}
                </span>
              <span v-if="tableDataKey === 'completed' || tableDataKey==='fullEmptyStatus' ">
                  {{ data[tableDataKey] ? 'YES' : 'NO' }}
                </span>

              <span v-if="tableDataKey === 'billed' ">
                  {{ data[tableDataKey] ? 'YES' : 'NO' }}
                </span>
              <span v-if="tableDataKey === 'closed' ">
                  {{ data[tableDataKey] ? 'YES' : 'NO' }}
                </span>
                <span v-if="tableDataKey === 'mayShiftShipBeforeCompletion' ">
                  {{ data[tableDataKey] ? 'YES' : 'NO' }}
                </span>
                <span v-if="tableDataKey === 'sharedByClerks' ">
                  {{ data[tableDataKey] ? 'YES' : 'NO' }}
                </span>
                <span v-if="tableDataKey === 'isBilled' ">
                  {{ data[tableDataKey] ? 'YES' : 'NO' }}
                </span>
              <span v-if="tableDataKey === 'active' ">
                  {{ data[tableDataKey] ? 'ACTIVE' : 'INACTIVE' }}
                </span>
              <span v-if="tableDataKey === 'billVatedAmountTzs' ">
                  <b>{{ putComma(data[tableDataKey]) }} TZS</b>
                </span>
            </td>
            <td class="btn-success-custom text-center noprint">
              <button class="btn btn-success mr-2 custom-btn"
                      v-if="!myLoader && !data['isDuplicate'] && hasCustomButton "
                      @click.prevent="fireCustomMethod(data)">
                <b>{{ customButtonName }}</b>
              </button>

              <button class="btn btn-success mr-2 custom-btn"
                      v-if="!myLoader && !data['isDuplicate'] && hasManageButton "
                      @click.prevent="goToApplication(data)">
                <b>Manage</b>
              </button>

              <button class="btn btn-default mr-2 custom-btn"
                      v-if="!myLoader && !data['isDuplicate'] && hasViewButton "
                      @click.prevent="goToView(data)">
                <b>View</b>
              </button>

              <button class="btn btn-primary mr-2 custom-btn"
                      v-if="!myLoader && !data['isDuplicate'] && hasEditButton "
                      @click.prevent="goToEdit(data)">
                <b>Edit</b>
              </button>

              <button class="btn btn-danger custom-btn" style="width: 150px"
                      @click.prevent="markAsDuplicate(data['requestId'],
                        data['blNumber'], data['customer'].name)" v-if="!myLoader && !data['isDuplicate'] &&
                        hasDuplicateButton">
                <b>Mark as duplicate</b>
              </button>

              <button class="btn btn-danger mr-2 custom-btn"
                      v-if="!myLoader && !data['isDuplicate'] && hasDeleteButton "
                      @click.prevent="goToDelete(data)">
                <b>Delete</b>
              </button>

              <button class="btn mr-2 custom-btn" v-for="(name , index) in buttonList" :key="index"
                      :class="[name.class , name.code === 'FinishTallying' && data.completed && disabled ? 'disabled-click' : '']"
                      :disabled="name.code === 'FinishTallying' && data.completed && disabled"
                      v-if="!myLoader && !data['isDuplicate'] && buttonList.length > 0 "
                      @click.prevent="fireCustomButton(data , name.code)">
                <b>{{ name.name }}</b>
              </button>
            </td>
            <td style="display: none"/>
          </tr>

          <!--   For data that HAS "completed" field name and should show all rows  -->
          <!--          <tr v-for="(data , indexTD) in tableData" :key="indexTD"-->
          <!--              v-if="!noDataFound && typeof data.completed !== 'undefined' && !disabled">-->
          <!--            <td v-if="isPageable">{{ (indexTD + (parseInt(pageSize) * parseInt(pageNumber))) + 1 }}</td>-->
          <!--            <td v-if="!isPageable">{{ indexTD + 1 }}</td>-->
          <!--            <td v-for="tableDataKey in tableDataKeys">-->
          <!--                        <span v-if="tableDataKey === 'customer' || tableDataKey === 'company'">-->
          <!--                            <span class="more-customer-details" @click="showToolTip(data)">-->
          <!--                                {{ (data[tableDataKey].name).toUpperCase() }}</span>-->
          <!--                                <span v-if="isHovered.includes(data)" class="customer-info">-->
          <!--                                     <p><u><b>Customer Information</b> (click <a @click="hideToolTip(data)"-->
          <!--                                                                                 class="close-info">here</a> to close)</u></p>-->
          <!--                                     <b>TIN:</b> {{ data[tableDataKey].tin }}<br/>-->
          <!--                                     <b>VRN:</b> {{ data[tableDataKey].vrn }} <br/>-->
          <!--                                     <b>MOBILE:</b> {{ data[tableDataKey].mobile }} <br/>-->
          <!--                                     <b>EMAIL:</b> {{ data[tableDataKey].email }} <br/>-->
          <!--                                     <b>CUSTOMER TYPE:</b>-->
          <!--                                    {{ data[tableDataKey].customerType === 'ind001' ? 'Individual' : 'company' }} <br/>-->
          <!--                                </span>-->
          <!--                        </span>-->
          <!--              <span v-if="tableDataKey !== 'customer'&& tableDataKey !== 'grossWeight' && tableDataKey !== 'company'&&-->
          <!--                                        tableDataKey !== 'tareWeight'  && tableDataKey !== 'netWeight' &&-->
          <!--                                        tableDataKey !== 'requestDate' && tableDataKey !== 'createdAt' &&-->
          <!--                                        tableDataKey !== 'updatedAt' && tableDataKey !== 'completed' &&-->
          <!--                                        tableDataKey !== 'billed' && tableDataKey !== 'grossWeight' &&-->
          <!--                                        tableDataKey !== 'updatedAt' && tableDataKey !== 'isCancelled' &&-->
          <!--                                        tableDataKey !== 'active' && tableDataKey !== 'billVatedAmountTzs'">-->
          <!--                                {{ data[tableDataKey] }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'requestDate'">-->
          <!--                                {{data[tableDataKey] ? dateTimeToHuman(data[tableDataKey]) : '' }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'createdAt' || tableDataKey === 'updatedAt'">-->
          <!--                                {{data[tableDataKey] ? dateTimeToHuman(data[tableDataKey]) : '' }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'tareWeight' ">-->
          <!--                                {{ data[tableDataKey] }} {{ data['tareWeightUnit']}}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'isCancelled'">-->
          <!--                                {{ checkBooleanValue(data[tableDataKey])}}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'grossWeight' ">-->
          <!--                                {{ data[tableDataKey] }} {{ data['grossWeightUnit']}}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'netWeight' ">-->
          <!--                                {{ data[tableDataKey] }} {{ data['netWeightUnit']}}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'completed' ">-->
          <!--                                {{ data[tableDataKey] ? 'YES' : 'NO' }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'billed' ">-->
          <!--                                {{ data[tableDataKey] ? 'YES' : 'NO' }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'active' ">-->
          <!--                                {{ data[tableDataKey] ? 'ACTIVE' : 'INACTIVE' }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'billVatedAmountTzs' ">-->
          <!--                                <b>{{ data[tableDataKey] }} TZS</b>-->
          <!--                            </span>-->

          <!--            </td>-->
          <!--            <td class="btn-success-custom text-center noprint">-->
          <!--              <button class="btn btn-success mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasCustomButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="fireCustomMethod(data)">-->
          <!--                <b>{{ customButtonName }}</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-success mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasManageButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="goToApplication(data)">-->
          <!--                <b>Manage</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-default mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasViewButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="goToView(data)">-->
          <!--                <b>View</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-primary mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasEditButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="goToEdit(data)">-->
          <!--                <b>Edit</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-danger custom-btn" style="width: 150px"-->
          <!--                      @click.prevent="markAsDuplicate(data['requestId'],-->
          <!--                                 data['blNumber'], data['customer'].name)" v-if="!myLoader && !data['isDuplicate'] &&-->
          <!--                                 finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET') &&-->
          <!--                                 hasDuplicateButton">-->
          <!--                <b>Mark as duplicate</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-danger mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasDeleteButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="goToDelete(data)">-->
          <!--                <b>Delete</b>-->
          <!--              </button>-->

          <!--              <button class="btn mr-2 custom-btn" v-for="(name , index) in buttonList" :key="index"-->
          <!--                      v-bind:class="[name.class , name.code === 'FinishTallying' && data.completed && disabled ? 'disabled-click' : '']"-->
          <!--                      :disabled="name.code === 'FinishTallying' && data.completed && disabled"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && buttonList.length > 0 &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="fireCustomButton(data , name.code)">-->
          <!--                <b>{{ name.name }}</b>-->
          <!--              </button>-->
          <!--            </td>-->
          <!--            <td style="display: none"></td>-->
          <!--          </tr>-->

          <!--   For data that HAS NO "completed" field name  -->
          <!--          <tr v-for="(data , indexTD) in tableData" :key="indexTD"-->
          <!--              v-if="!noDataFound && typeof data.completed === 'undefined'">-->
          <!--            <td v-if="isPageable">{{ (indexTD + (parseInt(pageSize) * parseInt(pageNumber))) + 1 }}</td>-->
          <!--            <td v-if="!isPageable">{{ indexTD + 1 }}</td>-->
          <!--            <td v-for="tableDataKey in tableDataKeys">-->
          <!--                        <span v-if="tableDataKey === 'customer' || tableDataKey === 'company'">-->
          <!--                            <span class="more-customer-details" @click="showToolTip(data)">-->
          <!--                                {{ (data[tableDataKey].name).toUpperCase() }}</span>-->
          <!--                                <span v-if="isHovered.includes(data)" class="customer-info">-->
          <!--                                     <p><u><b>Customer Information</b> (click <a @click="hideToolTip(data)"-->
          <!--                                                                                 class="close-info">here</a> to close)</u></p>-->
          <!--                                     <b>TIN:</b> {{ data[tableDataKey].tin }}<br/>-->
          <!--                                     <b>VRN:</b> {{ data[tableDataKey].vrn }} <br/>-->
          <!--                                     <b>MOBILE:</b> {{ data[tableDataKey].mobile }} <br/>-->
          <!--                                     <b>EMAIL:</b> {{ data[tableDataKey].email }} <br/>-->
          <!--                                     <b>CUSTOMER TYPE:</b>-->
          <!--                                    {{ data[tableDataKey].customerType === 'ind001' ? 'Individual' : 'company' }} <br/>-->
          <!--                                </span>-->
          <!--                        </span>-->
          <!--              <span v-if="tableDataKey !== 'customer'&& tableDataKey !== 'grossWeight' && tableDataKey !== 'company'&&-->
          <!--                                        tableDataKey !== 'tareWeight'  && tableDataKey !== 'netWeight' &&-->
          <!--                                        tableDataKey !== 'requestDate' && tableDataKey !== 'createdAt' &&-->
          <!--                                        tableDataKey !== 'updatedAt' && tableDataKey !== 'completed' &&-->
          <!--                                        tableDataKey !== 'billed' && tableDataKey !== 'grossWeight' &&-->
          <!--                                        tableDataKey !== 'updatedAt' && tableDataKey !== 'isCancelled' && tableDataKey !== 'dateBilled' &&-->
          <!--                                        tableDataKey !== 'active' && tableDataKey !== 'billVatedAmountTzs'">-->
          <!--                                {{ data[tableDataKey] }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'dateBilled'">-->
          <!--                                {{dateToHuman(data[tableDataKey])}}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'requestDate'">-->
          <!--                                {{data[tableDataKey] ? dateTimeToHuman(data[tableDataKey]) : '' }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'createdAt' || tableDataKey === 'updatedAt'">-->
          <!--                                {{data[tableDataKey] ? dateTimeToHuman(data[tableDataKey]) : '' }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'tareWeight' ">-->
          <!--                                {{ data[tableDataKey] }} {{ data['tareWeightUnit']}}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'isCancelled'">-->
          <!--                                {{ checkBooleanValue(data[tableDataKey])}}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'grossWeight' ">-->
          <!--                                {{ data[tableDataKey] }} {{ data['grossWeightUnit']}}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'netWeight' ">-->
          <!--                                {{ data[tableDataKey] }} {{ data['netWeightUnit']}}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'completed' ">-->
          <!--                                {{ data[tableDataKey] ? 'YES' : 'NO' }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'billed' ">-->
          <!--                                {{ data[tableDataKey] ? 'YES' : 'NO' }}-->
          <!--                            </span>-->
          <!--              <span v-if="tableDataKey === 'active' ">-->
          <!--                                {{ data[tableDataKey] ? 'ACTIVE' : 'INACTIVE' }}-->
          <!--              </span>-->
          <!--              <span v-if="tableDataKey === 'billVatedAmountTzs' ">-->
          <!--                                <b>{{ data[tableDataKey] }} TZS</b>-->
          <!--                            </span>-->

          <!--            </td>-->
          <!--            <td class="btn-success-custom text-center noprint">-->
          <!--              <button class="btn btn-success mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasCustomButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="fireCustomMethod(data)">-->
          <!--                <b>{{ customButtonName }}</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-success mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasManageButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="goToApplication(data)">-->
          <!--                <b>Manage</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-default mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasViewButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="goToView(data)">-->
          <!--                <b>View</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-primary mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasEditButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="goToEdit(data)">-->
          <!--                <b>Edit</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-danger custom-btn" style="width: 150px"-->
          <!--                      @click.prevent="markAsDuplicate(data['requestId'],-->
          <!--                                 data['blNumber'], data['customer'].name)" v-if="!myLoader && !data['isDuplicate'] &&-->
          <!--                                 finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET') &&-->
          <!--                                 hasDuplicateButton">-->
          <!--                <b>Mark as duplicate</b>-->
          <!--              </button>-->

          <!--              <button class="btn btn-danger mr-2 custom-btn"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && hasDeleteButton &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="goToDelete(data)">-->
          <!--                <b>Delete</b>-->
          <!--              </button>-->

          <!--              <button class="btn mr-2 custom-btn" v-for="(name , index) in buttonList" :key="index"-->
          <!--                      v-bind:class="[name.class , name.code === 'FinishTallying' && data.completed && disabled ? 'disabled-click' : '']"-->
          <!--                      :disabled="name.code === 'FinishTallying' && data.completed && disabled"-->
          <!--                      v-if="!myLoader && !data['isDuplicate'] && buttonList.length > 0 &&-->
          <!--                                     finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"-->
          <!--                      @click.prevent="fireCustomButton(data , name.code)">-->
          <!--                <b>{{ name.name }}</b>-->
          <!--              </button>-->
          <!--            </td>-->
          <!--            <td style="display: none"></td>-->
          <!--          </tr>-->
          </tbody>
        </table>
        <div class="col-md-12 text-center bg-grey" v-if="noDataFound">
          No data found
        </div>
        <br v-if="isPageable">
        <div class="col-md-12 noprint" id="footer-entries" v-if="isPageable && totalPages > 0">
          <div class="row">
            <div class="col-md-3">
              <span v-if="tableData.length > 0">
                Showing page <b>{{ pageNo + 1 }} </b> of {{ totalPages }}
              </span>
            </div>

            <div class="col-md-9 text-right disable-highlight down-pagination">
              <ul class="nav-justified">
                <li v-for="(page , index_page) in totalPages" :key="index_page" class="auto-number"
                    @click="goToPage(parseInt(page) - 1)"
                    v-if="Math.abs(parseInt(page) - pageNo) < 7 || parseInt(page) === parseInt(totalPages) || parseInt(page) === 1"
                    :class="[ parseInt(page) - 1 === parseInt(pageNo) ? 'active-page disabled-click' : 'enabled-click' ,
                              (parseInt(page) === parseInt(totalPages) && Math.abs(parseInt(page) - pageNo) > 7) ? 'last' : '',
                              (parseInt(page) === 1 && Math.abs(parseInt(page) - pageNo) > 7) ? 'first' : '']">
                  {{ page }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import SvgIcon from '../SvgLoader/svgLoader';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

  export default {

    name: 'UpdatedTable',

    data() {
      return {
        finalUserPermissions: [],
        searchParameter: '',
        dateParameter: '',
        pageSize: 15,
        color: 'green',
        size: '12px',
        loading: true,
        hover: false,
        currentId: '',
        baseButtonName: 'goTo',
        styleObject:{
          // marginLeft: 0.0,
          marginBottom: 0.0
        }
      };
    },

    props: {

      showRedStatus:false,


      buttonList: {

        type: Array,
        default: () => []

      },
      tableHeaders: {

        type: Array,
        default: []

      },
      tableData: {

        type: Array,
        default: () => []

      },
      tableDataKeys: {

        type: Array,
        default: []

      },
      redStatusKey:Object,
      noDataFound: {

        type: Boolean,
        default: false

      },
      pageNo: '',
      pageSizeProp: '',

      isFirst: {

        type: Boolean,
        default: false

      },
      hasDropDownButton: {

        type: Boolean,
        default: false

      },
      hasEditButton: {

        type: Boolean,
        default: false

      },
      hasDeleteButton: {

        type: Boolean,
        default: false

      },
      hasDuplicateButton: {

        type: Boolean,
        default: false

      },
      hasNoAction: {

        type: Boolean,
        default: true

      },
      hasDateQuery: {

        type: Boolean,
        default: false

      },
      hasAdditionalDetails: {

        type: Boolean,
        default: false

      },

      popupKeys: {
        type: Array,
        default: () => []
      },

      isHovered: {

        type: Array,
        default: () => []

      },
      myLoader: {

        type: Boolean,
        default: false

      },
      disabled: {

        type: Boolean,
        default: false

      },
      searchParameterProp: String,
      dateParameterProp: Date,
      reloadAllRequest: '',
      myFirstLoader: {

        type: Boolean,
        default: false

      },

      loadingTitle: '',

      isLast: {

        type: Boolean,
        default: false

      },
      isPageable: {
        type: Boolean,
        default: true
      },
      totalElements: '',
      totalPages: '',
      pageNumber: '',
      placeholder: '',
      dateProp: '',
      buttonName: '',
      customButtonName: '',
      hasSearchForm: {

        type: Boolean,
        default: true

      },
      hasRefreshButton: {
        type: Boolean,
        default: false
      },
      hasManageButton: true,
      hasCustomButton: false,
      hasViewButton: {

        type: Boolean,
        default: false

      }

    },

    components: {

      SvgIcon,
      PulseLoader

    },

    computed: {

      allowPerPageSelection() {
        return this.totalElements / 15;
      },
    },

    created() {
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    },

    methods: {
      getMouseLocation(event){
        // console.log(event.clientX); // x coordinate
        // console.log(event.clientY);
        let ml = event.clientX
        let mt  =event.pageY
        // console.log( ' the value of event client y is '+ Math.abs(event.clientY - 500))
        this.styleObject =  {
          marginBottom: String(mt  - 500 ) + 'px'
        }
      },

      checkBooleanValue(data) {
        if (data === true) {
          return 'YES';
        }
        if (data === false) {
          return 'NO';
        } else {
          return 'N/A';
        }
      },
      goToPage(page) {
        this.$emit('goToPage', page);
      },

      searchRequest() {
        this.$emit('searchRequest', this.searchParameter);
      },
      queryByDate() {
        this.$emit('queryByDate', this.dateParameter);
      },
      onChange() {
        this.$emit('onChange', this.pageSize);
      },

      prevPage() {
        this.$emit('prevPage');
      },

      nextPage() {
        this.$emit('nextPage');
      },

      firstPage() {
        this.$emit('firstPage');
      },

      lastPage() {
        this.$emit('lastPage');
      },

      loadAllPageable() {
        this.$emit('loadAllPageable', this.searchParameter);
      },

      loadDateDetails() {
        this.$emit('loadDateDetails', this.dateParameter);
      },

      reloadByIcon() {
        this.searchParameter = '';

        this.$emit('reloadByIcon', this.searchParameter);
      },

      reloadDateQueryByIcon() {
        this.dateParameter = '';
        this.$emit('reloadDateQueryByIcon', this.dateParameter);
      },

      showToolTip(requestId) {
        this.$emit('showToolTip', requestId);
      },

      hideToolTip(requestId) {
        this.$emit('hideToolTip', requestId);
      },

      markAsDuplicate(requestId, blNumber, customerName) {
        this.$emit('markAsDuplicate', requestId, blNumber, customerName);
      },

      goToApplication(objectId) {
        this.$emit('goToApplication', objectId);
      },

      goToRefresh() {
        this.$emit('goToRefresh');
      },

      goToView(objectId) {
        this.$emit('goToView', objectId);
      },

      goToEdit(objectId) {
        this.$emit('goToEdit', objectId);
      },

      goToDelete(objectId) {
        this.$emit('goToDelete', objectId);
      },

      fireCustomButton(object, code) {
        this.$emit(this.baseButtonName + code, object);
      },
      fireCustomMethod(object) {
        this.$emit('fireCustomMethod', object);
      }
    }
  };
</script>

<style scoped>

  .normal-search {
    min-width: 400px;
  }

  ul.nav-justified li.first::after {
    content: '...'
  }

  ul.nav-justified li.last::before {
    content: '...'
  }

  ul.nav-justified {
    list-style-type: none;
    font-size: 17px;
  }

  ul.nav-justified li {
    display: inline;
    padding: 0 6px 0 6px;
    font-weight: bold;
    border: 1px solid #ddd;
    margin: 0 4px;
    border-radius: 3px;
  }

  ul.nav-justified li.auto-number:hover {
    color: blue;
    fill: blue;
    cursor: pointer;
    font-weight: bold;
    background-color: rgba(153, 186, 221, .5) !important;
    padding: 0 6px 0 6px;
    text-align: center;
    border-radius: 3px;
  }

  ul.nav-justified li:hover {
    cursor: pointer;
    font-weight: bold;
    padding: 0 6px 0 6px;
  }

  ul.nav-justified li.active-page {
    color: white;
    cursor: pointer;
    font-weight: bold;
    background-color: green !important;
    padding: 0 6px 0 6px;
    text-align: center;
    border-radius: 3px;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(153, 186, 221, .5) !important;
    font-weight: normal !important;
  }

  tbody tr {
    color: #212529 !important;
    font-weight: 400 !important;
  }

  .table td, .table th {
    text-align: center;
  }

  .search .form-group .input-group #inputGroup {
    width: 250px;
    padding-top: 3.5px;
  }

  .search .form-group .input-group .input-group-addon:hover {
    cursor: pointer;
    background-color: rgba(220, 220, 220, .5);
  }

  .more-customer-details:hover {
    cursor: pointer;
    color: #d59a18;
  }

  .customer-info {
    position: absolute;
    bottom: 40px;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d59a18;
    text-align: left
  }

  .popup-info {
    position: absolute;
    z-index: 5;
    /*bottom: 40px;*/
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d59a18;
    text-align: left
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
  }

  .custom-btn-name {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
  }

  .close-info {
    color: blue !important;
  }

  .close-info:hover {
    cursor: pointer;
    color: green !important;
  }

  .custom-btn-custom-name {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 120px;
    background-color: #FFFFFF !important;
  }

  .btn-success-custom {
    padding: 0 4px 0 4px;
    color: #3cb371;
  }

  .btn-success-custom:hover {
    cursor: pointer;
    color: green;
  }

  .blur-popup {
    margin-top: 25px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.2);
  }

  .refresh-all {
    margin-top: 6px;
    margin-right: 20px
  }

  .refresh-all:hover {
    cursor: pointer;
  }

  .search .form-group .input-group .input-group-addon {
    width: 40px;
    text-align: center;
    padding-top: 3.5px;
    background-color: rgba(220, 220, 220, .3);
  }

  .search .form-group .input-group .input-group-addon:hover {
    cursor: pointer;
    background-color: rgba(220, 220, 220, .5);
  }

  .bg-grey {
    background-color: #dcdcdc;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  .disable-highlight ul li {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  .down-pagination {
    line-height: 5px;
    font-size: 20px;
    padding-right: 0 !important;
  }

  .up-pagination {
    font-size: 20px
  }

  .nc-icon-inner {
    font-size: 13px !important;
    fill: #000000;
  }

  .nc-icon-inner-disabled {
    font-size: 13px !important;
    fill: #dcdcdc;
  }

  .disabled-click {
    pointer-events: none !important;
    cursor: not-allowed !important;
    color: #dcdcdc !important;
    border-color: #dcdcdc !important;;
  }

  .enabled-click {
    pointer-events: auto;
  }
  .rejected{
    background-color: red;
  }

</style>
