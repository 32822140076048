<template>
  <div>
    <div>
      <div>
        <card class="row" v-if="!reaSignAction">
          <div
            class="col-md-12 text-center text-danger"
            v-if="activityAlreadyAssigned"
          >
            {{activityAlreadyAssignedMessage}}
          </div>
          <div
            class="col-md-12 alert-dismissible fade show"
            role="alert"
            :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
            style="border-radius:5px;"
            @enter="enter">
            <strong v-if="SuccessAlert">Successfully!</strong> <span
            v-if="SuccessAlert">
              {{ SuccessMessage }}</span>
            <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-if="SuccessAlert"
              style="line-height:2px;"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-if="ErrorAlert"
              style="line-height:2px;"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <br>
          <div class="col-12">
            <div class="row">
              <div class="col-md-4">
                <label>Search Staff</label>
                <model-list-select
                  :list="usersFull"
                  v-model="assignedUserData.userId"
                  class="form-control special-bottom"
                  option-value="userId"
                  option-text="fullName"
                  placeholder="Search Staff"></model-list-select>
                <div v-if="isUserId">
                  <div
                    class="error"
                    v-if="!$v.assignedUserData.userId.required"
                  >
                    This field is
                    required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Search Activity</label>
                <model-list-select
                  :list="options"
                  v-model="assignedUserData.activityId"
                  class="form-control special-bottom"
                  option-value="activityId"
                  option-text="activityName"
                  placeholder="Search Activity"></model-list-select>
                <div v-if="isActivityId">
                  <div
                    class="error"
                    v-if="!$v.assignedUserData.activityId.required"
                  >
                    This field is
                    required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Search Status</label>
                <model-list-select
                  :list="assignmentStatus"
                  v-model="assignedUserData.statusId"
                  class="form-control special-bottom"
                  option-value="statusId"
                  option-text="statusDescription"
                  placeholder="Search Status"></model-list-select>
                <div v-if="isStatusId">
                  <div
                    class="error"
                    v-if="!$v.assignedUserData.statusId.required"
                  >
                    This field is
                    required
                  </div>
                </div>
              </div>
            </div>

            <br>

            <!-- <div class="row">
              <div class="col-md-6">
                <label>Search Status</label>
                <model-list-select
                  :list="assignmentStatus"
                  v-model="assignedUserData.statusId"
                  class="form-control special-bottom"
                  option-value="statusId"
                  option-text="statusDescription"
                  placeholder="Search Status"></model-list-select>
                <div v-if="isStatusId">
                  <div
                    class="error"
                    v-if="!$v.assignedUserData.statusId.required"
                  >
                    This field is
                    required
                  </div>
                </div>
              </div>
            </div> -->

            <br>
            <div class="row">
              <div class="col-md-12">
                <label>Add Remarks / Instructions</label>
                <textarea
                  placeholder="Add Remarks / Instructions"
                  class="form-control special-bottom"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  v-model="assignedUserData.remarks"></textarea>
                <div v-if="isRemarks">
                  <div
                    class="error"
                    v-if="!$v.assignedUserData.remarks.required"
                  >
                    This field is
                    required
                  </div>
                </div>
              </div>
            </div>

            <br>

            <div class="text-center row">
              <div class="col-md-8 text-right loader-div-gif">
                <div class="row">
                  <div
                    class="col-md-10"
                    v-if="myLoader"
                  >
                    Please wait ....
                  </div>
                  <div class="col-md-2">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myLoader"
                      style="margin-top: 4px;"></pulse-loader>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-right">
                <button
                  type="submit"
                  class="btn btn-primary btn-space custom-btn"
                  data-dismiss="modal"
                  v-if="singleRequest.status !== 'Request Closed' && singleRequest.status !== 'Request Bill Paid'"
                  @click.prevent="assignUser"
                >
                  Assign
                </button>
                <button
                  class="btn btn-default btn-fill custom-btn-all"
                  @click.prevent="closeModal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </card>

        <div class="row">
          <div class="col-md-6">
            <b>{{reaSignAction? "Reassign User":"Staff Assignment History"}}</b>
          </div>
          <div class="col-md-6 text-right">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="getAssignmentsLoader"></pulse-loader>
            <pulse-loader
              :loading="reassignLoader"
              :color="color"
              :size="size"></pulse-loader>
          </div>
        </div>
        <hr>
        <div class="row" v-if="!reaSignAction">
          <div class="col-12">
            <table class="table table-striped">
              <thead>
              <tr>
                <th
                  scope="col"
                  width="5%"
                >
                  #
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  File No.(BL No.)
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Activity
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Assigned Person
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Date Assigned
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Assigned By
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Updated By
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Date Updated
                </th>
                <th
                  scope="col"
                  width="10%"
                >
                  Current Status
                </th>
                <th
                  scope="col"
                  width="15%"
                >
                  remarks
                </th>
                <th
                  scope="col"
                  width="15%"
                >
                  Reassign
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(list,index) in getDetailedAssignment"
                :key="list.assignmentId"
              >
                <th scope="row">
                  {{ index + 1 }}
                </th>
                <td>{{ list.fileNo +" " }} ({{ list.blNumber }})</td>
                <td>{{ list.activityName }}</td>
                <td><b>{{ list.firstName + " " }} {{ list.lastName }}</b></td>
                <td>{{ dateTimeToHuman(list.createdDate) }}</td>
                <td>{{ list.createdByFirstName + " " }}{{ list.createdByLastName }}</td>
                <td>{{ list.lastModifiedByFirstName + " " }}{{ list.lastModifiedByLastName }}</td>
                <td>{{ dateTimeToHuman(list.lastModifiedDate) }}</td>
                <td
                  v-if="list.statusDescription === 'Completed'"
                  class="text-success"
                >
                  <b>{{ list.statusDescription }}</b>
                </td>
                <td v-else>
                  <b>{{ list.statusDescription }}</b>
                </td>
                <td>{{ list.remarks }}</td>
                <td>
                  <button
                    @click="reassignView(list.assignmentId)"
                    v-if="list.statusDescription !== 'Re Assigned' && list.statusDescription !== 'Completed'"
                    class="btn btn-primary custom-btn" :disabled="getBillDetailsLoader">
                    Reassign
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-if="reaSignAction">
          <div class="col-md-12">
            <label>Search Staff To Reassign</label>
            <model-list-select
              :list="usersFull"
              v-model="selectedUserToReasign"
              class="form-control special-bottom"
              option-value="userId"
              option-text="fullName"
              placeholder="Search Staff"></model-list-select>
            <div v-if="selectedUserToReasignError">
              <div
                class="error"
                v-if="!$v.selectedUserToReasign.required"
              >
                This field is
                required
              </div>
            </div>

            <br>
            <div class="row">
              <div class="col-md-12">
                <label>Add Remarks / Instructions</label>
                <textarea
                  placeholder="Add Remarks / Instructions"
                  class="form-control special-bottom"
                  id="reassignRemark"
                  rows="5"
                  v-model="reAssignRemarks"></textarea>
                <div v-if="reAssignRemarksError">
                  <div
                    class="error"
                    v-if="!$v.reAssignRemarks.required"
                  >
                    This field is
                    required
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="col-md-12 text-right">
            <br/>
            <button class="btn btn-primary btn-space custom-btn" type="button" @click.prevent="reassign"
                    :disabled="reassignLoader">Reassign Officer
            </button>
            <button class="btn btn-fill btn-outline-info float-right custom-btn-all" type="button"
                    @click.prevent="reaSignAction=false" :disabled="reassignLoader">Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import  { ModelListSelect, MultiSelect } from 'vue-search-select';
  import API from '../../../api/index'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import { required } from 'vuelidate/lib/validators'
  import Modal from '../../../components/Inputs/Modal';

  export default {
    name: "Assignment",

    props:{
      requestId : {required: false},
      selectedRequest: {type: Object, required: false}
    },

    components:{
      ModelListSelect,
      MultiSelect,
      PulseLoader,
      Modal
    },

    data() {
      return {
        color: 'green',
        size: '12px',
        mySize: '9px',
        sizeVerify: '10px',
        singleRequest: {},
        SuccessAlert: false,
        ErrorAlert: false,
        getDetailedAssignment: [],
        getAssignmentsLoader: false,
        isActivityId: false,
        isRemarks: false,
        isRequestId: false,
        isStatusId: false,
        isUserId: false,
        activityAlreadyAssigned: false,
        activityAssignedDescription:[],
        activityAlreadyAssignedMessage:"",
        myLoader: false,
        loading: true,
        getBillDetailsLoader:false,
        notAssignedActivity: [],
        optionId: [],
        assignmentStatus: [],
        assignedActivitiesPerUser: [],
        options: [],
        assignedUserId: '',
        users: [],
        usersFull: [],
        assignedList: [],
        assignedUserData: {

          activityId: '',
          assignmentId: '',
          remarks: '',
          requestId: '',
          statusId: '',
          userId: ''
        },
        todayDate: new Date().toJSON().slice(0, 10),
        sendRequestLoader: false,

        selectedActivityId:null,
        reaSignAction:false,
        selectedUserToReasign:null,
        selectedUserToReasignError:false,
        reassignLoader:false,
        reAssignRemarks: '',
        reAssignRemarksError: false

      }
    },

    created() {
      this.getUsers();
      this.assignedUserData.requestId = this.requestId;
      this.singleRequest = this.selectedRequest;
      this.getAssignments(this.requestId);
      this.loadActivities();
      this.getStatus()
    },

    validations: {

      selectedUserToReasign:{required},
      activityName: { required },
      activityDescription: { required },
      statusDescription: { required },
      activityDate: { required },
      reAssignRemarks: { required }

    },

    methods: {
      assignUser () {
        if (this.$v.$error) {
          if (this.$v.activityId.$invalid) {
            this.isActivityId = true
          }
          if (this.$v.remarks.$invalid) {
            this.isRemarks = true
          }
          if (this.$v.requestId.$invalid) {
            this.isRequestId = true
          }
          if (this.$v.statusId.$invalid) {
            this.isStatusId = true
          }
          if (this.$v.userId.$invalid) {
            this.isUserId = true
          }
        } else {

          this.activityAlreadyAssigned = false

          this.activityAssignedDescription=[];
          for (let i = 0; i < this.getDetailedAssignment.length; i++) {
            this.assignedList.push(this.getDetailedAssignment[i].activityId);

            // if (this.getDetailedAssignment[i].activityId === this.assignedUserData.activityId &&
            //   this.getDetailedAssignment[i].userId === this.assignedUserData.userId) {
            //   this.activityAlreadyAssigned = true;
            //   this.assignedList = [];
            //   break
            // }

            if (this.getDetailedAssignment[i].activityId === this.assignedUserData.activityId) {

              this.activityAssignedName = this.getDetailedAssignment[i].activityName;
              this.activityAssignedUserName = this.getDetailedAssignment[i].userName;
              this.activityAssignedDescription.push(this.getDetailedAssignment[i].statusDescription);

              this.activityAlreadyAssigned = false
            }
          }

          if (!this.activityAlreadyAssigned) {

            let continue2=false;
            for (let i=0;i< this.activityAssignedDescription.length;i++){
              if(this.activityAssignedDescription[i]==="Completed"){
                continue2=true;
              }else {
                continue2=false;
                break;
              }
            }


            if (this.assignedList.includes(this.assignedUserData.activityId)  && !continue2){

              this.activityAlreadyAssignedMessage=`Activity has been assigned to ${this.activityAssignedUserName}, to re-assign go to appropriate menu`;
              //this.activityAlreadyAssignedMessage=`Activity ${this.activityAssignedName}  has already been assigned to User:(${this.activityAssignedUserName}),please choose another activity or Reassign to another user`;
              this.activityAlreadyAssigned = true;

            } else {

              this.myLoader = true;

              this.activityAlreadyAssigned = false;
              this.activityAlreadyAssignedMessage="";

              API.postAssignment(JSON.stringify(this.assignedUserData)).then(
                response => {
                  if (response.data.status) {
                    this.ErrorMessage = '';
                    this.SuccessAlert = true;
                    this.ErrorAlert = false;
                    this.SuccessMessage = ', User Assigned to selected activity';
                    this.myLoader = false;

                    this.assignedUserData.activityId = '';
                    this.assignedUserData.remarks = '';
                    // this.assignedUserData.statusId = '';
                    this.assignedUserData.userId = '';
                    this.assignedList = [];
                    this.usersfull = [];

                    this.getAssignments(this.assignedUserData.requestId);

                    setTimeout(() => {

                    }, 3000)
                  } else {

                  }
                }).catch(() => {
                this.assignedList = [];
                this.assignedUserData.activityId = '';
                this.isActivityId = false;
                this.assignedUserData.remarks = '';
                this.isRemarks = false;
                this.assignedUserData.requestId = '';
                this.isRequestId = false;
                this.assignedUserData.statusId = '';
                this.isStatusId = false;
                this.assignedUserData.userId = '';
                this.isUserId = false;
                this.myLoader = false
              })
            }
          }
        }
      },

      enter: function (el, done) {
        const that = this;

        setTimeout(function () {
          that.SuccessAlert = false;
          that.ErrorAlert = false
        }, 3000) // hide the message after 3 seconds
      },

      getStatus () {
        API.getStatus().then(
          response => {
            if (response.data.status) {

              let respData=response.data.data;

              for(let i=0;i<respData.length;i++){
                if(respData[i].statusDescription!=='Re Assigned'){
                  this.assignmentStatus.push(respData[i]);
                }
              }
              this.assignedUserData.statusId=4;
            } else {

            }
          }
        ).catch(e => {

        })
      },

      loadActivities () {
        API.getActivity().then(
          response => {
            if (response.data.status) {
              this.options = response.data.data
            } else {

            }
          }
        ).catch(() => {

        })
      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert
        } else {
          this.ErrorAlert = !this.ErrorAlert
        }
      },

      getUsers () {
        API.getUsers().then(
          response => {
            if (response.data.status) {
              this.users = response.data.data

              if (this.users !== null) {
                for (let i = 0; i < this.users.length; i++) {
                  const myObject = {
                    userId: this.users[i].userId,
                    fullName: this.users[i].fullName
                  };

                  this.usersFull.push(myObject)
                }
              }
            } else {

            }
          }).catch(() => {

        })
      },

      getAssignments (data) {
        this.assignedActivitiesPerUser = [];
        this.notAssignedActivity = [];
        this.optionId = [];
        this.getAssignmentsLoader = true;

        API.getAllAssignmentDetailedByRequest(data).then(
          response => {
            if (response.data.status) {
              this.getDetailedAssignment = response.data.data;
              for (let i = 0; i < this.getDetailedAssignment.length; i++) {
                if (this.getDetailedAssignment[i].userId === parseInt(this.cookieUserId)) {
                  this.assignedActivitiesPerUser.push(this.getDetailedAssignment[i]);
                  this.assignedUserId = this.getDetailedAssignment[i].userId
                }
              }

              this.getAssignmentsLoader = false;
              this.reaSignAction=false;
            } else {

            }
          }).catch(() => {
          this.getAssignmentsLoader = false
        });
      },

      reassignView(activityId){
        this.selectedActivityId=activityId;
        this.reaSignAction=true;
      },

      reassign(){

        if(this.selectedUserToReasign!==null && this.reAssignRemarks!==''){
          this.selectedUserToReasignError=false;
          this.reAssignRemarksError=false;
          this.activityAlreadyAssigned=false;

          this.reassignLoader=true;
          API.reasignActivity(this.selectedActivityId,this.selectedUserToReasign, this.reAssignRemarks).then((resp)=>{
            this.reassignLoader=false;

            if(resp.data.status){
              this.getAssignments(this.requestId);
              this.selectedUserToReasign=null;
              this.loadNotification('success', 4000, 'Request Message',
                'Activity Reassigned',
                'response', 1000 , true  , true);
            }else {
              this.loadNotification('error', 4000, 'Request Message',
                'Activity Failed To be Reassigned',
                'response', 1000 , true  , true);
            }

          }).catch((err)=>{
            this.reassignLoader=false;
            this.loadNotification('error', 4000, 'Request Message',
              'Activity Failed To be Reassigned',
              'response', 1000 , true  , true);
          })

        }else {
          this.selectedUserToReasignError=true;
          this.reAssignRemarksError=true;
          this.activityAlreadyAssigned=false;

        }

      },

      closeModal(){
        this.$emit('closeModal');
      }
    }

  }
</script>

<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .dg-btn--ok {
    display: none !important;
  }


  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
  }

  ul.nav-justified li a:hover {
    color: #d59a18;
  }


  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .tooltipe:hover .tooltiptext {
    visibility: visible;
    cursor: help;
  }

  .tooltiptext2::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltipe:hover .tooltiptext2 {
    visibility: visible;
    cursor: help;
  }

  .insert-bl .save-bl:hover {
    cursor: pointer;
  }

  .custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
  }

  .error{
    color: red;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
  }

  .btn-space {
    margin-right: 10px !important;
  }

</style>
