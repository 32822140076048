<template>
  <div>
  <div
    class="row div-table-row"
    v-if="typeof singleRequest.requestAttachDtos !== 'undefined'
                && singleRequest.requestAttachDtos.length > 0"
  >
    <div
      class="col-md-1 div-table div-table-title text-center"
      style="border-right: 1px solid #a9a9a9"
    >
      #
    </div>
    <div
      class="col-md-8 div-table div-table-title text-center"
      style="border-right: 1px solid #a9a9a9"
    >
      Attachment Type
    </div>
    <div
      class="col-md-3 div-table div-table-title text-center"
      style="border-right: 1px solid #a9a9a9"
    >
      View
    </div>
  </div>

  <div
    class="row div-table-row"
    v-if="typeof singleRequest.requestAttachDtos !== 'undefined'
                && singleRequest.requestAttachDtos.length > 0"
    v-for="(attachment , index_attachment) in singleRequest.requestAttachDtos" :key="index_attachment"
  >
    <div class="col-md-1 div-table div-table-value text-center">
      {{ index_attachment + 1 }}
    </div>
    <div class="col-md-8 div-table div-table-value">
      {{ attachment.attachmentName }}
    </div>
    <div
      class="col-md-3 div-table div-table-value text-center text-link"
      @click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)"
    >
      click to view
    </div>
  </div>

  <div
    class="row div-table-row"
    v-if="typeof singleRequest.requestAttachDtos !== 'undefined'
                && singleRequest.requestAttachDtos.length <= 0"
  >
    <div class="col-md-12 div-table div-table-value">
      <b>No attachment(s) found</b>
    </div>
  </div>
  </div>
</template>

<script>
  import API from "../../../../api";

  export default {
    name: "cfaAttachments.vue",

    props: ['requestId'],

    data() {
      return {
        singleRequest: {}
      }
    },

    created() {
      this.getRequestDetails(this.requestId);
    },

    methods: {
      getRequestDetails(requestId){
        API.getFullRequestDetails(requestId).then(
          response => {
            if (response.data.status) {
              this.singleRequest = response.data.data;
            } else {

            }
          }
        ).catch(e => {
          this.ErrorMessage = ', Application attachment not loaded, please contact system administrator';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = '';
          this.SuccessAlert = false;
        })
      },

      getFileFromMinio (fileUrl, fileName) {
        console.log("Im here");
        this.loadParameters(fileUrl, fileName).then(() => {
          this.loadFileFromMinio({}).then((res) => {
            if(res.status === 204) {
              this.loadNotification('warn', 4000, 'File Download',
                'File not found, please contact system admin',
                'response', 1000 , true  , true)

            } else {

              this.loadNotification('success', 4000, 'File Download',
                'File has been successfully downloaded, please have a look at it',
                'response', 1000 , true  , true)
            }
            this.globalLoader = this.getGlobalLoader
          }).catch(() => {

            this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

            this.globalLoader = this.getGlobalLoader
          });
          this.globalLoader = this.getGlobalLoader
        }).catch(() => {
        })
      },

      loadParameters (fileUrl, fileName) {

        return new Promise((resolve) => {

          this.$store.dispatch('global/fileUrling', fileUrl);
          this.$store.dispatch('global/fileNaming', fileName);

          resolve()
        })

      },
    }
  }
</script>

<style scoped>

  .custom-table, .custom-table th, .custom-table td {
    border: 1px solid #DCDCDC;
    border-collapse: collapse;
  }

  .custom-table th, .custom-table td {
    padding: 8px;
    text-align: left;
  }

  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
  }

  ul.nav-justified li a:hover {
    color: #d59a18;
  }

  .text-link {
    color: blue;
  }

  .text-link:hover {
    color: #d59a18;
    cursor: pointer;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .tooltipe:hover .tooltiptext {
    visibility: visible;
    cursor: help;
  }

  .tooltipe:hover .tooltiptext2 {
    visibility: visible;
    cursor: help;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  .insert-bl .save-bl:hover {
    cursor: pointer;
  }

</style>
