<template>
  <div>
    <card v-if="!thisFirstRequestLoader"
      style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-justified">
            <li v-if="singleRequest.billed">
              <button
                @click.prevent="openModal(singleRequest , 'CANCEL_REQUEST')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn"
              >Edit Bill Comment
              </button>
            </li>
            <li>
              <button
                @click.prevent="openPdfViewer(singleRequest.assessmentNotice.assessmentNoticeDocUrl , 'ASSESSMENT_FOR_APPLICATION_FILE_NUMBER_' + singleRequest.fileNo, 'view')"
                class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn"
              >
                Download Assessment
              </button>
            </li>
            <li>
            <button
                @click="goToApplication(singleRequest.requestId)"
                class="btn btn-dark-blue-like btn-dark-blue-like-fill mr-2 custom-btn">
                View Associated Application
            </button>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card style="font-size: 12px">
      <div
        class="row"
        v-if="thisFirstRequestLoader"
      >
        <div class="col-md-12 text-center loader-color">
          <b>Please wait while system is loading application charge details</b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
          />
        </div>
      </div>
      <div
        class="row"
        v-if="!thisFirstRequestLoader"
      >
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Application Details
            </h6>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Current Application Stage / Status
              </div>
              <div
                v-if="singleRequest.status === 'Request Submitted' "
                class="col-md-3 div-table div-table-value"
                style="color: #696969;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Accepted' "
                class="col-md-3 div-table div-table-value"
                style="color: #3498db;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Returned For Major Changes' "
                class="col-md-3 div-table div-table-value"
                style="color: #ff0000;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Returned For Minor Changes' "
                class="col-md-3 div-table div-table-value"
                style="color: #ffd700;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Resubmitted After Changes' "
                class="col-md-3 div-table div-table-value"
                style="color: #2f4f4f;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Assessed' "
                class="col-md-3 div-table div-table-value"
                style="color: #00ffff;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Assessment Rejected' "
                class="col-md-3 div-table div-table-value"
                style="color: #808000;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Charged' "
                class="col-md-3 div-table div-table-value"
                style="color: #008080;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Billed' "
                class="col-md-3 div-table div-table-value"
                style="color: #8b4513;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Assessment Sent To GEPG' "
                class="col-md-3 div-table div-table-value"
                style="color: #800080;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Bill Paid' "
                class="col-md-3 div-table div-table-value"
                style="color: #008000;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Request Closed' "
                class="col-md-3 div-table div-table-value"
                style="color: #000000;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Duplicate' "
                class="col-md-3 div-table div-table-value"
                style="color: #ff0000;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Application Type
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.requestType === 'IMP' ? 'IMPORT' : 'EXPORT' }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                BL/Airway Bill/RCN
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.blNumber }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Application Created Date
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ dateTimeToHuman(singleRequest.requestDate) }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                File Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.fileNo }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Customer Name - ( TIN )
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ typeof singleRequest.customer !== 'undefined' ?
                  (singleRequest.customer.name).toUpperCase() + ' - ( ' + singleRequest.customer.tin + ' )' :
                  '' }}
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Assessment Notice Details
            </h6>

            <!-- <div class="legend">
              <h6 class="legend-title">
                Container
              </h6>

              <div class="legend">
                <div class="row div-table-row">
                  <div class="col-md-3 div-table div-table-title">
                    Size
                  </div>
                  <div class="col-md-3 div-table div-table-value" />
                  <div class="col-md-3 div-table div-table-title">
                    Number
                  </div>
                  <div class="col-md-3 div-table div-table-value" />
                </div>

                <br>

                <div class="row div-table-row">
                  <div class="col-md-6 div-table div-table-title">
                    <b>Total</b>
                  </div>
                  <div class="col-md-6 div-table div-table-value" />
                </div>
              </div>
            </div> -->

            <!-- <br> -->

            <!-- <div class="legend" v-if="singleRequest.assessmentNotice !== null">
              <h6 class="legend-title">
                Details
              </h6> -->

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Custom Value (TZS)
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.customValue !== null ? putComma(singleRequest.assessmentNotice.customValue) : '' }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Exchange Rate
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.exchangeRate !== null ? singleRequest.assessmentNotice.exchangeRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") + " TZS" : '' }}
                </div>
              </div>

              <br>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Cargo Tonnage
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.cargoTonnage === 0 ? 'N/A' : putComma(singleRequest.assessmentNotice.cargoTonnage) }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Litres
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.cargoVolume === 0 ? 'N/A' : putComma(singleRequest.assessmentNotice.cargoVolume) }}
                </div>
              </div>

              <br>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Cargo CBM
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.cargoCbm === 0 ? 'N/A' : putComma(singleRequest.assessmentNotice.cargoCbm) }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  TANSAD/BT Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.tansadNo }}
                </div>
              </div>

              <br>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  {{ labelText }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.quantity === 0 ? 'N/A' : putComma(singleRequest.assessmentNotice.quantity, 0) }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Tariff Used
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.tariffItemName }}
                </div>
              </div>

              <br>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Vessel Name
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.vesselName }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Tariff Item Unit
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.tariffItemUnit }}
                </div>
              </div>

              <br>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Invoice Number
                </div>
                <div class="col-md-9 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.invoiceNumber }}
                </div>
              </div>
            <!-- </div> -->
            <br />
            <div class="legend" v-if="singleRequest.assessmentNotice !== null && singleRequest.assessmentNotice.additionalCargos !== null">
              <h6 class="legend-title" v-if="singleRequest.assessmentNotice.multipleTariffs">
                Additional Information
              </h6>
              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  {{ labelText }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.additionalCargos[0].quantity}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Tariff Used
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNotice.additionalCargos[0].tariffItemName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="col-md-12" v-if="singleRequest.requestCharges.length > 0">
          <div class="legend">
            <h6 class="legend-title">
              Charge(s) Details
            </h6>

            <div
              class="row div-table-row"
              v-if="singleRequest.requestCharges.length > 0"
            >
              <div class="col-md-3 div-table div-table-title">
                Charged Date
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ dateTimeToHuman(singleRequest.requestCharges[0].createdDate) }}
              </div>
              <!-- <div class="col-md-3 div-table div-table-title">
                Currency Used
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.requestCharges[0].chargeCurrency }}
              </div> -->
              <div class="col-md-3 div-table div-table-title">
                Charged By
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.requestCharges[0].createdBy }}
              </div>
            </div>

            <!-- <br>

            <div
              class="row div-table-row"
              v-if="singleRequest.requestCharges.length > 0">
              <div class="col-md-3 div-table div-table-title">
                Charged By
              </div>
              <div class="col-md-9 div-table div-table-value">
                {{ singleRequest.requestCharges[0].createdBy }}
              </div>
            </div> -->

            <br>

            <div
              class="row div-table-row"
              v-if="singleRequest.requestCharges.length > 0"
            >
              <div class="col-md-12 div-table div-table-title text-center" style="border-bottom: 1px solid #a9a9a9">
                Service(s) Charged
              </div>
              <div class="col-md-1 div-table div-table-title text-center" style="border-right: 1px solid #a9a9a9">
                S/N
              </div>
              <div class="col-md-6 div-table div-table-title text-center" style="border-right: 1px solid #a9a9a9">
                Service Name
              </div>
              <div class="col-md-3 div-table div-table-title text-center">
                Cancelled Date
              </div>
              <div class="col-md-2 div-table div-table-title text-center" style="border-left: 1px solid #a9a9a9">
                Status
              </div>
            </div>
            <div class="row div-table-row" v-for="(service , index) in singleRequest.requestCharges" :key="index">
              <div class="col-md-1 div-table div-table-value text-center" v-if="singleRequest.requestCharges.length > 0">
                {{ index + 1 }}
              </div>
              <div class="col-md-6 div-table div-table-value text-center" v-if="singleRequest.requestCharges.length > 0">
                {{ service.tariffFee.tariffFeeName }}
              </div>
              <div class="col-md-3 div-table div-table-value text-center" v-if="singleRequest.requestCharges.length > 0">
                <span v-if="service.isCancelled && !singleRequest.billed">
                  <span class="text-link">Date</span>:&nbsp;&nbsp;&nbsp;{{dateTimeToHuman(singleRequest.requestCharges[index].lastModifiedDate)}}
                </span>
              </div>
              <div class="col-md-2 div-table div-table-value text-center" v-if="singleRequest.requestCharges.length > 0">
                <b
                  class="cancel-charge"
                  v-if="service.isCancelled && !singleRequest.billed"
                >
                  Cancelled &nbsp;&nbsp;&nbsp;
                  <font-awesome-icon icon="comment"  :style="{color:'red'}" :title="service.cancelReason"/>

                </b>
                <b
                  class="cancel-charge red-link"
                  v-if="!service.isCancelled && !singleRequest.billed"
                  @click="showCancelChargeForm(service.requestChargeId,service.tariffFee.tariffFeeName , false)"
                >
                  Cancel
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row text-right"
        v-if="!cancelChargeFormShower && !cancelBillingFormShower && !thisFirstRequestLoader"
      >
        <div class="col-md-12">
          <button
            type="submit"
            class="btn btn-primary btn-space mr-2 custom-btn"
            v-if="singleRequest.status!=='Request Billed'"
            @click="showCancelChargeForm(singleRequest.requestId, null , true)"
            data-dismiss="modal"
          >
            Generate Bill
          </button>
          <button
            class="btn btn-default btn-fill custom-btn"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
      <br>
      <div
        class="row"
        style="padding: 0 15px 0 15px"
      >
        <div
          class="col-md-12 alert-dismissible fade show"
          role="alert"
          :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
          style="border-radius:5px;"
          @enter="enter"
        >
          <strong v-if="SuccessAlert">Successfully!</strong> <span
            v-if="SuccessAlert"
          >{{ SuccessMessage }}</span>
          <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            v-if="SuccessAlert"
            style="line-height:2px;"
            @click="fadeMe"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            v-if="ErrorAlert"
            style="line-height:2px;"
            @click="fadeMe"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <br v-if="SuccessAlert || ErrorAlert">

      <div class="row" v-if="cancelBillingFormShower && !thisFirstRequestLoader">
        <!-- BILL GENERATION  -->
        <div class="col-md-12 mb-3" v-if="singleRequest.isPBPA">
          <base-input v-model="pbpaAmount" @input="commafyThisAmountWithDecimal('pbpaBill')"
                      label="PBPA BILL AMOUNT(TZS)" placeholder="PBPA BILL AMOUNT(TZS)" type="text" class="special-bottom">
          </base-input>
          <div v-if="pbpaAmountError">
            <div
              class="error"
              v-if="!$v.pbpaAmount.required"
            >
              This Charge is for PBPA so this field is required
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label for="cancelCharge">Provide Comment for bill generation</label>
          <textarea
            class="form-control"
            id="generateCharge"
            rows="4"
            v-model="billComment"
          />
          <div v-if="billCommentError">
            <div
              class="error"
              v-if="$v.billComment.required"
            >
              This field is required
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <br>
        </div>
        <div class="col-md-12 text-right">
          <div class="row">
            <div class="col-md-10 pt-3">
              <pulse-loader
                :loading="billingLoader"
                :color="color"
                :size="size"
              />
            </div>
            <div class="col-md-2">
              <button
                type="submit"
                class="btn btn-primary btn-space mr-2 custom-btn"
                data-dismiss="modal"
                :disabled="billingLoader"
                @click.prevent="generateCfaBill(chargeIdToBill)"
              >
                Generate Bill
              </button>

              <button
                class="btn btn-default btn-fill custom-btn"
                @click.prevent="showCancelChargeForm(null , null , true)"
                :disabled="billingLoader"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        v-if="cancelChargeFormShower && !thisFirstRequestLoader"
      >
        <!-- CHARGE CANCELLATION  -->
        <div class="col-md-12">
          <label for="cancelCharge">Provide Reason for Cancelling Charge [ <b>{{ chargeNameToCancel }}</b> ]</label>
          <textarea
            class="form-control"
            id="cancelCharge"
            rows="4"
            v-model="cancelChargeComment"
          />
          <div v-if="isCancelChargeReason">
            <div
              class="error"
              v-if="$v.cancelChargeComment.required"
            >
              This field is required
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <br>
        </div>
        <div class="col-md-12 text-right">
          <div class="row">
            <div class="col-md-8 pt-3">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                v-if="cancelChargeLoader"
              />
            </div>
            <div class="col-md-4">
              <button
                type="submit"
                class="btn btn-danger btn-space mr-2 custom-btn"
                data-dismiss="modal"
                @click.prevent="cancelChargedRequest(chargeIdToCancel)"
              >
                Cancel Charge
              </button>

              <button
                class="btn btn-default btn-fill custom-btn"
                @click.prevent="showCancelChargeForm(null , null , false)"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </card>
    <modal v-if="editBillCommentModal">
      <!-- EDIT BILL COMMENT  -->
      <div
        slot="body"
        class="modal-title col-md-12"
      >
        <div
          class="row"
          style="padding: 0 15px 0 15px"
        >
          <div
            class="col-md-12 alert-dismissible fade show"
            role="alert"
            :class="[ SuccessAlertEditComment ? 'alert-success' : '' , ErrorAlertEditComment ? 'alert-danger' : '' ]"
            style="border-radius:5px;"
            @enter="enterEditComment"
          >
            <strong v-if="SuccessAlertEditComment">Successfully!</strong> <span
              v-if="SuccessAlertEditComment"
            >{{ SuccessMessageEditComment }}</span>
            <strong v-if="ErrorAlertEditComment">Error!</strong> <span v-if="ErrorAlertEditComment">{{ ErrorMessageEditComment }}</span>

            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-if="SuccessAlertEditComment"
              style="line-height:2px;"
              @click="fadeMeEditComment"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-if="ErrorAlertEditComment"
              style="line-height:2px;"
              @click="fadeMeEditComment"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="col-md-12">
            <br>
          </div>
        </div>
        <div class="row">
          <table
            style="width:100%"
            class="custom-table"
          >
            <thead>
              <tr>
                <th
                  class="notice-table-label text-center"
                  colspan="5"
                >
                  Bill Service(s) / Item(s) Description
                </th>
              </tr>
              <tr>
                <th>#</th>
                <th>Item Name</th>
                <th>Comment Description</th>
                <th>GF CODE</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(billItem , index_billItem) in billItemsDescriptions" :key="index_billItem">
                <td v-if="!viewBillDescriptionLoader && !noDataFound">{{ index_billItem + 1 }}</td>
                <td v-if="!viewBillDescriptionLoader && !noDataFound">
                  {{ billItem.serviceName }}
                </td>
                <td v-if="!viewBillDescriptionLoader && !noDataFound">
                  {{ billItem.description }}
                </td>
                <td v-if="!viewBillDescriptionLoader && !noDataFound">
                  {{ billItem.gfsCode }}
                </td>
                <td v-if="!viewBillDescriptionLoader && !noDataFound">
                  <span
                    class="text-success cancel-charge"
                    @click="showEditCommentForm(billItem.billItemId, billItem.billId, billItem.serviceName , billItem.description)"
                  >
                    Edit comment
                  </span>
                </td>
              </tr>
              <tr v-if="noDataFound">
                <td
                  colspan="5"
                  class="text-center"
                >
                  No data found
                </td>
              </tr>
              <tr v-if="viewBillDescriptionLoader">
                <td
                  colspan="5"
                  class="text-center"
                >
                  <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-md-12"
          v-if="!commentEditFormShower"
        >
          <br>
        </div>
        <div
          class="col-md-12 text-right pr-0"
          v-if="!commentEditFormShower"
        >
          <div class="row">
            <div class="col-md-12">
              <button
                class="btn btn-default btn-fill custom-btn"
                @click.prevent="closeModal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="commentEditFormShower"
        >
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <label for="editBillComment">EDIT BILL COMMENT FOR ITEM CHARGE [ <b>{{ itemNameBillToEdit }}</b> ]</label>
            <textarea
              class="form-control"
              id="editBillComment"
              rows="4"
              v-model="billCommentEdit"
            />
            <div v-if="isBillCommentEditReason">
              <div
                class="error"
                v-if="$v.billCommentEdit.required"
              >
                This field is required
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
          <div class="col-md-12 text-right">
            <div class="row">
              <div class="col-md-7 pt-3">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="billDescriptionEditLoader"
                />
              </div>
              <div class="col-md-5">
                <button
                  type="submit"
                  class="btn btn-primary mr-2 custom-btn"
                  data-dismiss="modal"
                  @click.prevent="editBillItemComment(chargeItemIdToEdit)"
                >
                  Edit Comment
                </button>

                <button
                  class="btn btn-default btn-fill mr-2 custom-btn"
                  @click.prevent="showEditCommentForm(null , null , null , null)"
                >
                  Hide Form
                </button>

                <button
                  class="btn btn-default btn-fill custom-btn"
                  @click.prevent="closeModal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Preparing requested file, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
    @downloadPdf="openPdfViewer(singleRequest.assessmentNotice.assessmentNoticeDocUrl , 'ASSESSMENT_FOR_APPLICATION_FILE_NUMBER_' + singleRequest.fileNo)">

    </pdf-viewer>
  </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import API from '../../../../api'
import API_REVENUE from '../../../../api/rev'
import API_CFA from '../../../../api/cfa'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../../components/Inputs/Modal'
import { mapActions, mapGetters } from 'vuex';
import GlobalModal from '../../../../components/globalLoader'
  import PdfViewer from '../../../../components/pdfViewer.vue'

export default {

  name: 'Application',

  components: {

    PulseLoader,
    Modal,
    GlobalModal,
    PdfViewer

  },

  validations: {

    cancelChargeComment: { required },
    billComment: { required },
    billCommentEdit: { required },
    pbpaAmount:{required},

  },

  created () {

    if (this.$route.params.requestId) {
      this.$store.dispatch('pagenames/currentPageName', 'Application Charges Billing')

      this.requestId = this.$route.params.requestId

      this.loadThisRequestChargesOnCreated(this.$route.params.requestId)
    }

  },

  computed: {

    ...mapGetters('global', [

      'getGlobalLoader'

    ])

  },

  data () {
    return {

      color: 'green',
      size: '12px',
      loading: true,

      requestId: '',
      singleRequest: {},
      globalLoader: false,
      labelText: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: '',

      SuccessAlertEditComment: false,
      ErrorAlertEditComment: false,
      SuccessMessageEditComment: '',
      ErrorMessageEditComment: '',

      thisFirstRequestLoader: false,
      applicationStatus: '',

      cancelChargeComment: '',
      cancelChargeFormShower: false,
      chargeIdToCancel: '',
      chargeNameToCancel: '',
      isCancelChargeReason: false,
      cancelChargeLoader: false,

      billComment: '',
      pbpaAmount:'',
      cancelBillingFormShower: false,
      chargeIdToBill: '',
      billingLoader: false,
      editBillCommentModal: false,

      viewBillDescriptionLoader: false,
      billItemsDescriptions: [],
      noDataFound: false,
      commentEditFormShower: false,
      chargeItemIdToEdit: '',
      billCommentEdit: '',
      isBillCommentEditReason: false,
      itemNameBillToEdit: '',
      chargeItemBillIdToEdit: '',
      billDescriptionEditLoader: false,
      billCommentError: false,
      pbpaAmountError:false,
      scr: '',
      showPdfViewer: false,
    }
  },

  methods: {

    ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    commafyThisAmountWithDecimal (flag) {

      switch (flag) {

        case 'pbpaBill':
          this.pbpaAmount = this.commafyAmountWithDecimal(this.pbpaAmount);
          break;
        default: //cargoVolume
          this.pbpaAmount = this.commafyAmountWithDecimal(this.pbpaAmount);
          break;

      }

    },

    loadParameters (fileUrl, fileName) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    openPdfViewer (fileUrl, fileName, action) {

        this.getFileFromMinio(fileUrl, fileName, action).then(() => {

            this.showPdfViewer = true

        }).catch(() => {})

    },

    closePdfViewer () {

        this.showPdfViewer = false
        this.scr = ''

    },

    getFileFromMinio (fileUrl, fileName, action) {

        return new Promise((resolve, reject) => {

            this.loadParameters(fileUrl, fileName).then(() => {

            this.loadFileFromMinio({action}).then((res) => {

                if(res[1].status === 204) {

                    this.loadNotification('warn', 4000, '',
                    'File not found, please contact system admin',
                    'response', 1000 , true  , true)

                } else {

                    this.loadNotification('success', 4000, '',
                    'File has been successfully loaded, please have a look at it',
                    'response', 1000 , true  , true)
                    this.scr = res[0]
                    resolve()


                }

                    this.globalLoader = this.getGlobalLoader

                }).catch((error) => {

                this.loadNotification('error', 4000, '',
                    'File not loaded, please contact system admin',
                    'response', 1000 , true  , true)

                this.globalLoader = false

                })

                this.globalLoader = this.getGlobalLoader

            }).catch(() => {

                this.globalLoader = false

            })

        })

    },

    // getFileFromMinio (fileUrl, fileName) {

    //   if(fileUrl !== null && fileUrl !== '') {


    //     this.loadParameters(fileUrl, fileName).then(() => {

    //     this.loadFileFromMinio({}).then((res) => {

    //         if(res.status === 204) {

    //             this.loadNotification('warn', 4000, 'File Download',
    //             'File not found, please contact system admin',
    //             'response', 1000 , true  , true)

    //         } else {

    //           this.loadNotification('success', 4000, 'File Download',
    //             'File has been successfully downloaded, please have a look at it',
    //             'response', 1000 , true  , true)


    //         }

    //           this.globalLoader = this.getGlobalLoader

    //       }).catch(() => {

    //         this.loadNotification('error', 4000, 'File Download',
    //             'File not downloaded, please check your internet connection',
    //             'response', 1000 , true  , true)

    //         this.globalLoader = this.getGlobalLoader

    //       })

    //       this.globalLoader = this.getGlobalLoader

    //     }).catch(() => {

    //     })


    //   } else {

    //     this.loadNotification('error', 4000, 'File Download',
    //             'File url not found',
    //             'response', 1000 , true  , true)
    //     this.globalLoader = false

    //   }

    // },


    loadThisRequestChargesOnCreated (data) {
      this.thisFirstRequestLoader = true

      API_CFA.getRequestCharges(data).then(
        response => {
          if (response.data.status) {

            this.singleRequest = response.data.data

            if(Object.keys(this.singleRequest.assessmentNotice).length > 0) {

              if(this.singleRequest.assessmentNotice.tariffItemUnit === 'FEU' || this.singleRequest.assessmentNotice.tariffItemUnit === 'TEU' || this.singleRequest.assessmentNotice.tariffItemUnit === 'Containerized Consignment') {

                this.labelText = 'Container Quantity'

              } else {

                this.labelText = 'Unit'

              }

            }

            this.applicationStatus = this.getApplicationStatus(this.singleRequest.status, 1)

            this.thisFirstRequestLoader = false
          } else {

          }
        }
      ).catch(() => {
        this.thisFirstRequestLoader = false
      })
    },

    showEditCommentForm (id = null, billId, name = null, Message = null) {
      this.commentEditFormShower = !this.commentEditFormShower

      if (this.commentEditFormShower) {
        this.chargeItemIdToEdit = id
        this.chargeItemBillIdToEdit = billId
        this.billCommentEdit = Message
        this.itemNameBillToEdit = name
      } else {
        this.chargeItemIdToEdit = ''
        this.billCommentEdit = ''
        this.itemNameBillToEdit = ''
        this.chargeItemBillIdToEdit = ''
      }
    },

    editBillItemComment () {

      this.billDescriptionEditLoader = true

      API_REVENUE.ammendBillDescription(this.chargeItemIdToEdit, this.chargeItemBillIdToEdit, this.billCommentEdit)
        .then((response) => {
          if (response.data.status) {
            this.getBillDescriptions(this.singleRequest.requestId)

            this.billDescriptionEditLoader = false
            this.ErrorMessageEditComment = ''
            this.SuccessAlertEditComment = true
            this.ErrorAlertEditComment = false
            this.commentEditFormShower = false
            this.SuccessMessageEditComment = ', Bill item comment has been updated'
          } else {
            this.billDescriptionEditLoader = false
          }
        }).catch(() => {
          this.billDescriptionEditLoader = false
          this.ErrorMessageEditComment = ', Failed to update bill item comment'
          this.SuccessAlertEditComment = false
          this.ErrorAlertEditComment = true
          this.SuccessMessageEditComment = ''
        })
    },

    showCancelChargeForm (id = null, name = null, flag) {

      let vesselName
      //this.singleRequest.assessmentNotice.tariffItemUnit === 'Ltr'
      this.singleRequest.assessmentNotice.tariffItemUnit === 'Ltr' ? vesselName = ', Vessel Name: ' + this.singleRequest.assessmentNotice.vesselName : vesselName = ''

      this.billComment = 'For TANSAD Number: ' + this.singleRequest.assessmentNotice.tansadNo +
      ', B/L Number: ' + this.singleRequest.blNumber + ', Invoice Number: ' +
      this.singleRequest.assessmentNotice.invoiceNumber + vesselName

      if (flag) {

        this.cancelBillingFormShower = !this.cancelBillingFormShower

        if (this.cancelBillingFormShower) {

          this.chargeIdToBill = id
          this.scrollToBottom()

        } else {

          this.chargeIdToBill = ''
        }

      } else {

        this.cancelChargeFormShower = !this.cancelChargeFormShower

        if (this.cancelChargeFormShower) {

          this.chargeIdToCancel = id
          this.chargeNameToCancel = name
          this.scrollToBottom()

        } else {

          this.chargeIdToCancel = ''
          this.chargeNameToCancel = ''

        }

      }

    },

    cancelChargedRequest (chargeId) {
      if (this.$v.cancelChargeComment.$invalid) {
        this.isCancelChargeReason = true
      } else {
        this.cancelChargeLoader = true

        API.cancelCharge(chargeId, this.cancelChargeComment).then((response) => {
          if (response.data.status) {
            this.cancelChargeLoader = false
            this.cancelChargeComment = ''
            this.ErrorMessage = ''
            this.SuccessAlert = true
            this.ErrorAlert = false
            this.SuccessMessage = ', Charge Successfully Cancelled'
            this.cancelChargeFormShower = false
            if (window.$cookies.isKey('browser-local-cashed-url-back')) {
              this.$router.push(window.$cookies.get('browser-local-cashed-url-back'))

              window.$cookies.remove('browser-local-cashed-url-back')
            }
          } else {
            this.cancelChargeLoader = false
            this.cancelChargeComment = ''
            this.ErrorMessage = response.data.message + ' [ Go to the top of the page to cancel this bill ]'
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.SuccessMessage = ''
          }
        }).catch(() => {
          this.cancelChargeLoader = false
          this.ErrorMessage = ', Failed To cancel, please contact system administrator';
          this.SuccessAlert = false;
          this.ErrorAlert = true
          this.SuccessMessage = ''
        })
      }
    },

    generateCfaBill (requestId) {

      this.billCommentError = false
      this.pbpaAmountError=false;

      if(this.singleRequest.isPBPA){
        if(this.pbpaAmount===''){
          this.pbpaAmountError=true;
        }else {
          this.billingLoader = true;
          this.billCreation(requestId);
        }
      }else {
        this.billingLoader = true;
        this.billCreation(requestId);
      }



    },

    billCreation(requestId){
      API_REVENUE.generateCFABill(requestId,this.billComment,this.removeComma(this.pbpaAmount)).then(response => {

        if (response.data.status) {

          this.billingLoader = false
          this.billComment = ''
          this.cancelBillingFormShower = false
          this.loadThisRequestChargesOnCreated(this.$route.params.requestId)
          this.loadNotification('success', 4000, 'Bill Generation', 'Bill has been successfully created', 'response', 1000 , true  , true)

        } else {

          this.myloader = false;
          this.billingLoader = false;
          this.loadNotification('error', 4000, 'Bill Generation', response.data.message, 'response', 1000 , true  , true)

        }

      }).catch(() => {

        this.loadNotification('error', 4000, 'Bill Generation', 'Bill not generated, please try or contact system admin', 'response', 1000 , true  , true)
        this.myloader = false;
        this.billingLoader = false;

      });
    },

    getBillDescriptions (requestId) {
      this.viewBillDescriptionLoader = true

      API_REVENUE.getChargesItemByRequestId(requestId, true).then((response) => {
        if (response.data.status) {
          this.viewBillDescriptionLoader = false

          this.billItemsDescriptions = response.data.data

          if (this.billItemsDescriptions.length > 0) { this.noDataFound = false } else { this.noDataFound = true }
        } else {
          this.viewBillDescriptionLoader = false
        }
      }).catch(() => {

      })
    },

    openModal (requestData = null, actionButton) {
      if (actionButton === 'CANCEL_REQUEST') {
        this.editBillCommentModal = true
        this.billComment = requestData
        this.getBillDescriptions(requestData.requestId)
      }
    },

    closeModal () {
      this.editBillCommentModal = false
    },

    goBack () {
      if (window.$cookies.isKey('browser-local-cashed-url-back')) {
        this.$router.push(window.$cookies.get('browser-local-cashed-url-back'))

        window.$cookies.remove('browser-local-cashed-url-back')
      }
    },

    goToApplication (id) {

        document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
        this.$router.push({name: 'CustomerApplicationDetails', params: {requestId: id}})

    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000)
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enterEditComment: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlertEditComment = false
        that.ErrorAlertEditComment = false
      }, 3000)
    },

    fadeMeEditComment: function () {
      if (this.SuccessAlertEditComment) {
        this.SuccessAlertEditComment = !this.SuccessAlertEditComment
      } else {
        this.ErrorAlertEditComment = !this.ErrorAlertEditComment
      }
    }

  }

}
</script>

<style scoped>

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .div-table-row {
        padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }

    .notice-table-label {
        width: 250px;
        background-color: #99badd;
        font-weight: normal;
    }

    .custom-table, .custom-table th, .custom-table td {
        border: 1px solid #DCDCDC;
        border-collapse: collapse;
    }

    .custom-table th, .custom-table td {
        padding: 8px;
        text-align: left;
    }

    .cancel-charge:hover {
        cursor: pointer !important;
    }

    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    .text-link {
        color: blue;
    }

    .text-link:hover {
        color: #d59a18;
        cursor: pointer;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }

    .btn-blue:hover {
        border-color: #0000ff;
        color: #0000ff;
        background-color: #ffffff;
    }

    .btn-blue {
        border-color: #4169e1;
        color: #4169e1;
    }

    .btn-blue-fill {
        background-color: #4169e1;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-purple:hover {
        border-color: #800080;
        color: #800080;
        background-color: #ffffff;
    }

    .btn-purple {
        border-color: #9932cc;
        color: #9932cc;
    }

    .btn-purple-fill {
        background-color: #9932cc;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-success-custom:hover {
        border-color: #008000;
        color: #008000;
        background-color: #ffffff;
    }

    .btn-success-custom {
        border-color: rgba(0, 128, 0, .5);
        color: rgba(0, 128, 0, .5);
    }

    .btn-success-custom-fill {
        background-color: rgba(0, 128, 0, .8);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-dark-blue-like:hover {
        border-color:  #154360 ;
        color:  #154360 ;
        background-color: #ffffff;
    }

    .btn-dark-blue-like {
        border-color: rgba( 21, 67, 96 , .5);
        color: rgba( 21, 67, 96 , .5);
    }

    .btn-dark-blue-like-fill {
        background-color: rgb( 21, 67, 96 );
        opacity: 1;
        color: #FFFFFF;
    }

    .alert-success {
        padding-top:10px;
        padding-bottom: 10px;
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    .alert-danger {
        padding-top:10px;
        padding-bottom: 10px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    .red-link {
      color: red;
      font-size: 15px;
    }

    .red-link:hover {
      color: #d59a18;
    }

    .error{
      color: red;
    }


</style>
