<template>
  <transition name="modal col-md-12">
    <div>
      <div class="modal  modal-mask" style="display: block">
        <div class="modal-dialog medium-modal-dialog" role="document" :style="'max-width:' + maxWidth + 'px !important'">
          <div class="modal-content col-md-12">
            <div class="modal-header col-md-12">
              <slot name="header" />
            </div>

            <div class="modal-body col-md-12">
              <slot name="body" />
            </div>

            <div class="modal-footer col-md-12 text-right">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {

  name: 'Modal',

  props: {

    maxWidth: {

        type: Number,
        default: () => 1200

    },

  }

}
</script>

<style>

  .modal-mask {
    position: fixed;
    z-index: 4910;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
    transition: opacity .3s ease;
    overflow-y: auto !important;
  }

  /* .medium-modal-dialog {
    max-width: 1200px !important;
  } */

</style>
