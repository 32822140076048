<template>
  <div class="content">
    <div class="col-md-12">
      <card>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="text-left">
                Documents Reconciliation Details
              </div>
            </div>
            <div class="col-md-6 text-left">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                v-if="myloader"
              />
            </div>
          </div>
        </div>
        <hr>

        <div class="tabs">
          <a
            @click="activetabmain=7"
            :class="[ activetabmain === 7 ? 'active' : '' ]"
          >
            Controlled cargo manifest against TANCIS Manifest
            <span class="notification-issues">{{ discrepancies.length }}</span>
          </a>
        </div>
        <div class="content">
          <div
            v-if="activetabmain === 7"
            class="tabcontent"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">
                    #
                  </th>
                  <th scope="col">
                    BL Number
                  </th>
                  <th scope="col">
                    TPA Call ID
                  </th>
                  <th scope="col">
                    Field
                  </th>
                  <th scope="col">
                    Controlled
                  </th>
                  <th scope="col">
                    TANCIS
                  </th>
                  <th scope="col">
                    Description
                  </th>
                  <th scope="col">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(bl, index) in discrepancies"
                  :key="bl.discrepancyId"
                >
                  <td scope="row">
                    {{ index + 1 }}
                  </td>
                  <td>{{ bl.blNumber }}</td>
                  <td>{{ bl.tpaCallId }}</td>
                  <td>{{ bl.fieldName }}</td>
                  <td>{{ bl.originalValue ? bl.originalValue : "Nil" }}</td>
                  <td>{{ bl.newValue }}</td>
                  <td>{{ bl.description }}</td>

                  <td :class="[ bl.originalValue ? 'text-warning' : 'text-danger']">
                    {{ bl.originalValue ? "Altered" : "Not Found" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

  name: 'ReconciliedDoc',

  components: {

    PulseLoader

  },

  data () {
    return {
      TPACallId: '',
      discrepancies: [],
      activetab: 1,
      activetabmain: 7,

      color: 'green',
      size: '15px',
      loading: true,
      myLoader: false

    }
  },
  created () {
    this.TPACallId = this.$route.params.TPACallId
    this.loadReconciliation()
  },
  methods: {

    loadReconciliation () {
      this.myloader = true

      API.getTancisExportReconciliationDocument(this.TPACallId).then(
        response => {
          if (response.data.status) {
            this.discrepancies = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {

      })
    }
  }

}
</script>
<style scoped>
  .tabs {
    overflow: hidden;
    /*margin-left: 20px;*/
    margin-bottom: -2px;
    /*hide bottom border*/
  }

  .tabs ul {
    list-style-type: none;
    margin-left: 20px;
  }

  .tabs a{
    float: left;
    cursor: pointer;
    padding: 12px 24px;
    transition: background-color 0.2s;
    border: 1px solid #dcdcdc;
    border-right: none;
    background-color: #f1f1f1;
    /*border-radius: 10px 10px 0 0;*/
    /*font-weight: bold;*/
  }
  .tabs a:last-child {
    border-right: 1px solid #ccc;
  }

  /* Change background color of tabs on hover */
  .tabs a:hover {
    background-color: #aaa;
    color: #fff;
  }

  /* Styling for active tab */
  .tabs a.active {
    background-color: #fff;
    color: #484848;
    border-bottom: 2px solid #fff;
    cursor: default;
  }

  /* Style the tab content */
  .tabcontent {
    padding: 30px;
    border: 1px solid #dcdcdc;
    /*border-radius: 10px;*/
    /*box-shadow: 3px 3px 6px #e1e1e1*/
  }
</style>
<style>
  .notification-issues {
    /*position: absolute;*/
    background-color: #FB404B;
    text-align: center;
    border-radius: 10px;
    min-width: 18px;
    padding: 0 5px;
    height: 18px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
  }

  .notification-no-issues {
    background-color: green;
    text-align: center;
    border-radius: 10px;
    min-width: 18px;
    padding: 0 5px;
    height: 18px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
  }
</style>
