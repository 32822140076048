<template>
  <transition name="modal col-md-12">
    <div>
      <div
        class="modal-mask-global-loader"
        style="display: block"
      >
        <div class="col-md-12 text-center global-loader-modal-dialog">
          <slot name="globalLoader" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {

  name: 'GlobalLoader'

}
</script>

<style>

    .modal-mask-global-loader {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto;
    }

    .global-loader-modal-dialog {
        position: fixed;
        top: 40%;
        left: 39%;
        max-width: 410px !important;
        background-color: white;
        border-radius: 5px;
        padding: 5px 3px 5px 5px;
        border: 2px solid #d59a18;
    }

</style>
