<template>
  <div class="content">
    <card>
      <div class="row">
        <div
          @click="toggle"
          class="col-md-12 toggle-code-key"
        >
          <div class="row">
            <div class="col-md-6">
              Attachment Type group keys and icons color keys
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon
                icon="angle-down"
                v-show="!showSection"
              />
              <font-awesome-icon
                icon="angle-up"
                v-show="showSection"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          v-show="showSection"
        >
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 30px"
              v-show="showSection"
            >
              <b>icons keys</b>
            </div>
            <div class="col-md-12">
              <div
                class="col-md-12"
                v-show="showSection"
              >
                <div class="row">
                  <div class="col-md-4">
                    <span>
                      <font-awesome-icon
                        class="edit-fa-icon"
                        icon="edit"
                      /><i class=" text-muted"> Edit Attachment Type</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        </div>-->
      </div>
    </card>
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successful!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>

      <div class="row">
        <div class="col-md-4">
          <div class="text-left">
            Attachment List
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
        </div>
        <div class="col-md-4 text-right">
          <span
            class="all-fa-icons btn-space"
            title="add new request"
            v-if="finalUserPermissions.includes('ACTIVITY_CONFIG_POST')"
            @click.prevent="openModal(null , null , actionButton = 'AddModal')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>

      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('ACTIVITY_SEARCH_POST')"
            >
              <form
                class="search"
                @submit.prevent="loadPageableActivities(pageNo, pageSize)"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParamenter"
                      @input="loadAllPageble"
                    >
                    <span
                      class="input-group-addon"
                      @click.prevent="loadSearchRequestActivities(pageNo, pageSize)"
                    >
                      <font-awesome-icon icon="search" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">
            #
          </th>
          <th scope="col">
            Attachment Type Name
          </th>

          <th scope="col">
            Action
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="(list, index) in getActivity"
          :key="list.activityId"
        >
          <th scope="row">
            {{ index + 1 }}
          </th>
          <td>
            {{ list.attachmentTypesName }}
          </td><td>
          <div
            class=" all-fa-icons edit-fa-icon"
            v-if="finalUserPermissions.includes('ACTIVITY_CONFIG_PUT')"
            @click.prevent="openModal(list.attachmentTypeId , list.attachmentTypesName , actionButton = 'EditModal')"
            title="edit"
          >
            <font-awesome-icon icon="edit" />
          </div>
        </td>
        </tr>
        </tbody>
      </table>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Activity <b>{{ activityName }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            New Attachment Type
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Activity: <b>{{ data.activityName }}</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>
      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editActivity"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-12">
                <base-input
                  type="text"
                  label="Activity Name"
                  placeholder="Activity Name"
                  v-model="attachmentType.attachmentTypesName"
                  value="activityName"
                />
                <div v-if="attachmentTypeError">
                  <div
                    class="error"
                    v-if="!$v.attachmentType.attachmentTypesName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveAttachmentType"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-12">
                <base-input
                  type="text"
                  label="Attachment Name"
                  placeholder="Attachment Name"
                  v-model="attachmentType.attachmentTypesName"
                />
                <div v-if="attachmentTypeError">
                  <div
                    class="error"
                    v-if="!$v.attachmentType.attachmentTypesName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  type="text"
                  label="Attachment Code"
                  placeholder="Attachment Code"
                  v-model="attachmentType.attachmentGroupCode"
                />
              </div>
            </div>


            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Activity Name
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.attachmentTypesName }}
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>

  <!-- <div>
        <card>
            <data-table
                    :tableHeaders="tableHeaders"
                    :tableData="requests"
                    :tableDataKeys="tableDataKeys"
                    :pageSizeProp="pageSize"
                    :pageNo="pageNo"
                    :isHovered="isHovered"
                    :myLoader="myLoader"
                    :pageCount="pageCount"
                    :reloadAllRequest="reloadAllRequest"
                    :myFirstLoader="myFirstLoader"
                    :noDataFound="noDataFound"
                    :searchParameterProp="searchParameter"
                    :status="status"
                    :loadingTitle="'Please wait while system is loading assessed applications list'"
                    @showToolTip="showToolTip"
                    @hideToolTip="hideToolTip"
                    @onChange="onChange"
                    @goToApplication="goToApplication"
                    @searchRequest="searchRequestByName"
                    @reloadByIcon="reloadByIcon"
                    @loadAllPageable="loadAllPageable"
                    @firstPage="firstPage"
                    @prevPage="prevPage"
                    @nextPage="nextPage"
                    @lastPage="lastPage">
            </data-table>
        </card>
        <global-modal v-if="myLoader">
            <div slot="globalLoader">
                <div class="row text-center">
                    <div class="col-md-12">
                        Loading Applications, Please wait
                    </div>
                    <div class="col-md-12">
                        <br/>
                    </div>
                    <div class="col-md-12">
                        <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                    </div>
                </div>
            </div>
        </global-modal>
    </div> -->
</template>
<script>
  import API from '../../../api/agency'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import Modal from '../../../components/Inputs/Modal'
  import { mapGetters } from 'vuex'
  import { required } from 'vuelidate/lib/validators'

  export default {

    name: 'SAattachmentType',

    components: {

      PulseLoader,
      Modal

    },

    created () {
      this.$store.dispatch('pagenames/currentPageName', 'Shipping Agent Attachment Types');

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadPageableActivities(this.pageNo, this.pageSize)
    },

    computed: {

      ...mapGetters('activities', [

        'getValuesBeforeEdit',
        'getNameBeforeDelete'

      ])

    },

    data () {
      return {

        array: [],
        getActivity: [],
        finalUserPermissions: [],
        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        docPageNo: 0,
        docPageCount: 0,
        docSortBy: 'id',
        sortBy: 'activityId',
        docPageSize: 0,
        getPageableDoc: 0,
        searchParameter: '',
        hasSearchedVariable: false,
        reloadAllRequest: false,
        noDataFound: false,
        data: {},

        color: 'green',
        size: '15px',
        loading: true,

        SuccessMessage: '',
        ErrorMessage: '',
        activityName: '',
        activityId: '',
        error: '',
        searchParamenter:'',
        NameBeforeSubmit: '',
        attachmentType: {
          attachmentGroupCode:null,
          attachmentTypesName: '',
          attachmentTypeId:null
        },

        SuccessAlert: false,
        ErrorAlert: false,
        showSection: true,
        showModal: false,
        myLoader: false,
        editMyLoader: false,
        EditModalDiv: false,
        AddModalDiv: false,
        ShowModalDiv: false,
        attachmentTypeError: false

      }
    },

    validations: {

      attachmentType: {
        attachmentTypesName: { required }
      },

      searchParameter: { required },

      activityName: { required }

    },
    methods: {

      toggle () {
        this.showSection = !this.showSection
      },

      loadSearchRequestActivities (pageNo, pageSize) {
        if (this.$v.searchParameter.$invalid) {

        } else {
          this.myloader = true
          this.reloadAllRequest = true
          this.hasSearchedVariable = true

          API.getSearchPageableActivities(pageNo, pageSize, this.searchParameter).then(
            response => {
              if (response.data.status) {
                this.myloader = false

                if (response.data.data.length > 0) {
                  this.getActivity = response.data.data
                  this.pageCount = response.data.message
                  this.docPageCount = this.pageCount
                  this.docPageNo = this.pageNo
                  this.docPageSize = this.pageSize
                  this.myloader = false
                } else {
                  this.noDataFound = true
                  this.pageCount = response.data.message
                  this.docPageCount = this.pageCount
                  this.docPageNo = this.pageNo
                  this.docPageSize = this.pageSize
                  this.myloader = false
                }
              } else {
                this.noDataFound = true
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.getPageableDoc = response.data.data
                this.myloader = false
              }
            }
          ).catch(e => {
            this.myloader = false
          })
        }
      },

      onChange (event) {
        this.pageSize = event.target.value
        this.pageNo = 0
        if (this.hasSearchedVariable) {
          this.loadSearchRequestActivities(this.pageNo, this.pageSize)
        } else {
          this.loadPageableActivities(this.pageNo, this.pageSize, this.sortBy)
        }
        this.docPageNo = this.pageNo
        this.docPageSize = this.pageSize
        this.docPageCount = this.pageCount
        this.docSortBy = this.sortBy
      },

      nextPage () {
        this.pageNo++
        this.docPageNo = this.pageNo
        if (this.hasSearchedVariable) {
          this.loadSearchRequestActivities(this.pageNo, this.pageSize)
        } else {
          this.loadPageableActivities(this.pageNo, this.pageSize)
        }
      },

      prevPage () {
        this.pageNo--
        this.docPageNo = this.pageNo
        if (this.hasSearchedVariable) {
          this.loadSearchRequestActivities(this.pageNo, this.pageSize)
        } else {
          this.loadPageableActivities(this.pageNo, this.pageSize)
        }
      },

      firstPage () {
        this.pageNo = 0
        this.docPageNo = this.pageNo

        if (this.hasSearchedVariable) {
          this.loadSearchRequestActivities(this.pageNo, this.pageSize)
        } else {
          this.loadPageableActivities(this.pageNo, this.pageSize)
        }
      },

      lastPage () {
        this.pageNo = this.pageCount - 1
        this.docPageNo = this.pageNo
        if (this.hasSearchedVariable) {
          this.loadSearchRequestActivities(this.pageNo, this.pageSize)
        } else {
          this.loadPageableActivities(this.pageNo, this.pageSize)
        }
      },

      loadAllPageble () {
        if (this.searchParameter === '' && this.hasSearchedVariable === true) {
          this.loadPageableActivities(this.pageNo, this.pageSize)
          this.noDataFound = false
          this.hasSearchedVariable = false
          this.reloadAllRequest = false
          this.searchParamenter = ''
        }
      },

      reloadByIcon () {
        this.loadPageableActivities(this.pageNo, this.pageSize)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParamenter = ''
      },

      loadPageableActivities (pageNo, pageSize) {
        return new Promise((resolve, reject) => {
          this.myloader = true;

          API.getSearchPageableAttachmenttypes(pageNo, pageSize,this.searchParamenter).then(
            response => {
              if (response.data.status) {
                this.pageCount = response.data.message;
                this.docPageCount = this.pageCount;
                this.docPageNo = this.pageNo;
                this.docPageSize = this.pageSize;
                this.getActivity = response.data.data;
                this.myloader = false
              } else {

              }

              resolve()
            }
          ).catch(e => {
            this.ErrorMessage = ' Requests Not loaded ' + e
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.myloader = false

            reject(e)
          })
        })
      },

      loadActivity () {
        this.myloader = true;

        API.getActivity(this.pageNo, this.pageSize, this.sortBy).then(
          response => {
            if (response.data.status) {
              this.getActivity = response.data.data;
              this.pageCount = response.data.message;
              this.docPageCount = this.pageCount;
              this.docPageNo = this.pageNo;
              this.docPageSize = this.pageSize;
              this.myloader = false;

            } else {

            }
          }
        ).catch(e => {
          this.ErrorMessage = ' Record not added'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false
        })
      },

      loadSingleActivity (data) {

        API.getAttachmentTypeById(data).then(
          response => {
            if (response.data.status) {
              this.data = response.data.data
            } else {
              this.data = 'Data not found'
            }
          }
        ).catch(e => {
          // this.state.errors.push(e)
        })
      },

      saveAttachmentType () {
        if (this.$v.attachmentType.attachmentTypesName.$invalid) {
          this.attachmentTypeError = true
        } else {
          this.editMyLoader = true
          // setTimeout(() => {

          API.postAttachmentType(JSON.stringify(this.attachmentType)).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = '"' + response.data.data.activityName + '" Attachment Type Saved';
                this.SuccessAlert = true;
                this.ErrorMessage = '';
                this.ErrorAlert = false;
                this.closeModal();
                this.loadPageableActivities(this.pageNo, this.pageSize);
                this.editMyLoader = false;
                this.activity.activityName = '';
              } else {

              }
            }
          ).catch(e => {
            this.editMyLoader = false;
            this.ErrorMessage = ' Record not added';
            this.SuccessMessage = '';
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.editMyLoader = false;
            this.activity.activityName = '';
            this.closeModal()
          })

          // }, 2000);
        }
      },

      deleteActivity (data, name) {
        this.$store.dispatch('activities/nameBeforeDelete', name)

        this.$dialog.confirm('If you delete Activity [ ' + name + " ], it'll be gone forever.", {

          loader: true
        })
          .then((dialog) => {
            // setTimeout(() => {

            API.deleteActivity(data).then(
              response => {
                if (response.data.status) {
                  this.SuccessMessage = ' Cargo type [ ' + this.getNameBeforeDelete + ' ] has been deleted'
                  this.ErrorMessage = ''
                  this.SuccessAlert = true
                  this.ErrorAlert = false
                  this.loadPageableActivities(this.pageNo, this.pageSize)
                } else {

                }
              }
            ).catch(e => {
              this.ErrorMessage = ' Record not deleted'
              this.SuccessMessage = ''
              this.SuccessAlert = false
              this.ErrorAlert = true
            })

            dialog.close()
            // }, 2500);
          })
          .catch(() => {
            this.myloader = false
            this.SuccessAlert = false
          })
      },

      editActivity () {


        if (this.$v.attachmentType.attachmentTypesName.$invalid) {
          this.attachmentTypeError = true
        } else {
          this.editMyLoader = true;
          this.attachmentTypeError=false;

          API.updateAttachmentType(this.attachmentType).then((resp)=>{
            this.editMyLoader = false;
            this.attachmentType={
              attachmentGroupCode:null,
              attachmentTypesName: '',
              attachmentTypeId:null
            };
            this.loadPageableActivities(this.pageNo, this.pageSize)
          }).catch((error)=>{
            this.editMyLoader = false;
            this.attachmentTypeError=false;
          });

        }
      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert
        } else {
          this.ErrorAlert = !this.ErrorAlert
        }
      },

      enter: function (el, done) {
        const that = this

        setTimeout(function () {
          that.SuccessAlert = false
          that.ErrorAlert = false
        }, 3000) // hide the message after 3 seconds
      },

      openModal (activityId = null, activityName = null, actionButton) {
        if (actionButton === 'EditModal') {
          console.log(activityName)
          this.EditModalDiv = true
          this.showModal = true
          this.ShowModalDiv = false
          this.attachmentType.attachmentTypesName = activityName;
          this.attachmentType.attachmentTypeId = activityId

          this.$store.dispatch('activities/valuesBeforeEdit', [this.activityName])
        } else if (actionButton === 'AddModal') {
          this.EditModalDiv = false
          this.AddModalDiv = true
          this.ShowModalDiv = false
          this.showModal = true
        } else {
          this.loadSingleActivity(activityId)
          this.EditModalDiv = false
          this.AddModalDiv = false
          this.ShowModalDiv = true
          this.showModal = true
        }
      },

      closeModal () {
        this.showModal = false;
        this.EditModalDiv = false;
        this.AddModalDiv = false;
        this.attachmentType={
          attachmentGroupCode:null,
            attachmentTypesName: '',
            attachmentTypeId:null
        };

        this.activityNameError = false
      }

    }

  }
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }
</style>
