<template>
  <div class="container-fluid">
    <modal v-if="showEditUserModal">
      <div slot="body">
        <div class="col-md-12">
          <edit-cf-agent-user :user-id="selectedUserId"></edit-cf-agent-user>
        </div>
        <div class="col-md-12">
          <div class="btn btn-default float-right" @click="closeModal">Close</div>
        </div>
      </div>
    </modal>
    <modal v-if="showModal">
      <div slot="body">
        <div class="col-md-12">
          <new-cf-agent-user
            @closeModal="closeModal"
            @loadUsers="getCompanyUsers"
          />
        </div>
        <div class="col-md-12">
          <div class="btn btn-default float-right" @click="closeModal">Close</div>
        </div>
      </div>
    </modal>
    <div class="row mb-3">
      <div class="col-md-12">
        <button
          class="btn btn-success mr-2 custom-btn"
          style="float:right;"
          @click.prevent="openModal('NEW_CF_AGENT')"
        >
          <b>Add CF User</b>
        </button>
      </div>
    </div>
    <card>
      <div class="container-fluid">
        <data-table
          :table-headers="tableHeaders"
          :table-data="cfaAgentsUsers"
          :table-data-keys="tableDataKeys"
          :my-first-loader="myFirstLoader"
          :page-size-prop="pageSize"
          :page-no="pageNo"
          :is-pageable="isPageable"
          :total-elements="totalElements"
          :no-data-found="noDataFound"
          :is-first="isFirst"
          :is-last="isLast"
          :total-pages="totalPages"
          :page-number="pageNumber"
          :date-prop="'Created At '"
          :has-manage-button="false"
          :has-search-form="false"
          :has-view-button="false"
          :has-edit-button="true"
          @goToEdit="editUser"
          @onChange="onChange"
          :loading-title="'Please wait while system is loading Clearing & Forwarding Agents Users'"
        />

      </div>
    </card>

  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { mapActions, mapGetters } from 'vuex'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import GlobalModal from '../../../components/globalLoader'
  import DataTable from '../../../components/DataTable/updatedTable'
  import Modal from '../../../components/Inputs/Modal'
  import CrudNotification from "../../../components/DataTable/crudNotification";
  import API from "../../../api";
  import newCfAgentUser from "./subComponent/newCfAgentUser";
  import editCfAgentUser from "./subComponent/editCfAgentUser";

  export default {
        name: "CFAgentUsers",

    created() {
          this.getCompanyUsers();
    },

    components:{
      PulseLoader,
      CrudNotification,
      DataTable,
      Modal,
      newCfAgentUser,
      editCfAgentUser
    },

    data(){

          return{
            cfaAgentsUsers:[],
            tableHeaders:["TIN","PHONE","USERNAME",'FIRSTNAME',"MIDDLENAME","LASTNAME","EMAIL","DATE CREATED"],
            tableDataKeys:["tin", "phone", "username", "firstName", "middleName", "lastName", "email", "createdTime"],
            myFirstLoader: false,
            pageSize: 15,
            totalElements: "",
            totalPages: "",
            isFirst: false,
            isLast: false,
            pageNumber: '',
            myLoader: false,
            pageCount:0,
            pageNo:1,
            isPageable:false,
            noDataFound:false,
            showModal:false,
            showEditUserModal:false,
            selectedUserId:'',
            loadedUser:{
                id: "",
                checkno: null,
                type: null,
                tin: null,
                phone: "",
                username: "",
                firstName: "",
                middleName: "",
                customerId: "",
                sseToken: null,
                lastName: "",
                email: "",
                enabled: true,
                firstTime: true,
                createdTime: "",
                designationId: null,
                departmentId: null,
                customerType: "",
                updatedTime: null,
                roles: null,
                name: ""
            }
          }
    },

    methods:{
      onChange () {},
      goToPage () {},

      getCompanyUsers(){
        API.getCfaAgentsUsers(this.$route.params.cfAgentId,'SHIP_AGENCY').then((resp)=>{

          this.cfaAgentsUsers=resp.data.data;
          console.log('hh',resp.data.data);
        }).catch((err)=>{

        });
      },

      editUser(data){
        this.showEditUserModal=true;
        this.selectedUserId=data.id;
      },



      openModal(type){
        if(type==="NEW_CF_AGENT"){
          this.showModal=true;
        }else {
          this.showEditUserModal=true;
        }
      },

      closeModal(){
        this.showModal=false;
        this.showEditUserModal=false;
        this.selectedUserId="";
      }
    }

    }
</script>

<style scoped>

</style>
