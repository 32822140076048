<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong>
        <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong>
        <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>

      <div class="text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Department List
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            v-if="finalUserPermissions.includes('DEPARTMENT_CONFIG_POST')"
            @click.prevent="openModal(null , null , null, null , actionButton = 'AddModal')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>

      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('DEPARTMENT_SEARCH-PAGEABLE_POST')"
            >
              <form
                class="search"
                @submit.prevent="loadSearchRequestDepartmentList(pageNo , pageSize)"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParamenter"
                      @input="loadAllPageble"
                    >
                    <span
                      class="input-group-addon"
                      @click.prevent="loadSearchRequestDepartmentList(pageNo , pageSize)"
                    >
                      <font-awesome-icon icon="search" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Department Name
            </th>
            <th scope="col">
              Department Code
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getDepartment"
            :key="list.departmentId"
            v-if="!noDataFound"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>{{ list.departmentName }}</td>
            <td>{{ list.departmentCode }}</td>

            <td class="special-limit">
              <div
                class="all-fa-icons fa-icons-space edit-fa-icon"
                v-if="finalUserPermissions.includes('DEPARTMENT_CONFIG_PUT')"
                @click.prevent="openModal(list.departmentId , list.departmentName , list.departmentCode, 1 ,
                                          actionButton = 'EditModal')"
                title="edit"
              >
                <font-awesome-icon icon="edit" />
              </div>

              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                v-if="finalUserPermissions.includes('DEPARTMENT_CONFIG_{DEPARTMENTID}_GET')"
                @click.prevent="openModal(list.departmentId , null , null , null, actionButton = 'ShowModal')"
                title="view"
              >
                <font-awesome-icon icon="eye" />
              </div>

              <div
                class="all-fa-icons fa-icons-space delete-fa-icon"
                v-if="finalUserPermissions.includes('DEPARTMENT_CONFIG_{DEPARTMENTID}_DELETE')"
                @click.prevent="deleteDepartment(list.departmentId , list.departmentName)"
                title="delete"
              >
                <font-awesome-icon icon="trash-alt" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Department
            <b>{{ departmentName }} ({{ departmentCode }})</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register Department
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Department:
            <b>{{ data.departmentName }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="UploadModalDiv"
          >
            Upload Excel for Department
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editDepartment"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <select
                  class="form-control special-bottom"
                  v-model="companyId"
                >
                  <option value="">
                    Select Company
                  </option>
                  <option
                    v-for="(company, index) in getCompany"
                    :value="company.companyId"
                    :selected="companyId === company.companyId"
                    :key="index"
                  >
                    {{ company.companyName }}
                  </option>
                </select>
                <div v-if="companyIdError">
                  <div
                    class="error"
                    v-if="!$v.companyId.required"
                  >
                    Select company
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Department Name"
                  placeholder="Department Name"
                  v-model="departmentName"
                  value="departmentName"
                  class="special-bottom"
                />
                <div v-if="departmentNameError">
                  <div
                    class="error"
                    v-if="!$v.departmentName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Department Code"
                  placeholder="Department Code"
                  v-model="departmentCode"
                  value="departmentCode"
                  class="special-bottom"
                />
                <div v-if="departmentCodeError">
                  <div
                    class="error"
                    v-if="!$v.departmentCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveDepartment"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <label for="myselect">Select Company</label>
                <select
                  id="myselect"
                  name="myselect"
                  class="form-control special-bottom"
                  v-model="department.company.companyId"
                >
                  <option
                    v-for="(company, index) in getCompany"
                    :value="company.companyId"
                    :key="index"
                  >
                    {{ company.companyName }}
                  </option>
                </select>
                <div v-if="companyIdError">
                  <div
                    class="error"
                    v-if="!$v.department.company.companyId.required"
                  >
                    Select company
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Department Name"
                  placeholder="Department Name"
                  v-model="department.departmentName"
                  class="special-bottom"
                />
                <div v-if="departmentNameError">
                  <div
                    class="error"
                    v-if="!$v.department.departmentName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Department Code"
                  placeholder="Department Code"
                  v-model="department.departmentCode"
                  class="special-bottom"
                />
                <div v-if="departmentCodeError">
                  <div
                    class="error"
                    v-if="!$v.department.departmentCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Department Name:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.departmentName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Department Code:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.departmentCode }}
                  </div>
                </div>
              </div>
            </div>

            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'DepartmentList',

  components: {
    PulseLoader,
    Modal
  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Departments List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableDepartmentList(this.pageNo, this.pageSize)
    this.loadCompany()
  },

  computed: {
    ...mapGetters(['getCityNameBeforeEdit'])
  },

  data () {
    return {
      array: [],
      getDepartment: [],
      getCompany: [],
      getDepartmentArray: [],
      finalUserPermissions: [],

      pageNo: 0,
      pageSize: 15,
      sortBy: 'id',
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      docSortBy: 'id',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,

      data: {},

      color: 'green',
      size: '15px',
      loading: true,

      SuccessMessage: '',
      ErrorMessage: '',

      departmentName: '',
      departmentCode: '',
      departmentId: '',
      companyId: '',

      error: '',

      fileData: '',
      file: '',

      department: {
        departmentName: '',
        departmentCode: '',
        company: {
          companyId: ''
        }
      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,
      UploadModalDiv: false,

      departmentNameError: false,
      departmentCodeError: false,
      companyIdError: false
    }
  },

  validations: {

    department: {

      departmentName: { required },
      departmentCode: { required },
      company: {

        companyId: { required }

      }

    },

    departmentName: { required },
    departmentCode: { required },
    companyId: { required }

  },

  methods: {

    loadSearchRequestDepartmentList (pageNo, pageSize) {
      this.myloader = true
      this.reloadAllRequest = true
      this.hasSearchedVariable = true

      API.getSearchPageableDepartmentList(pageNo, pageSize, this.searchParameter).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            if (response.data.data.length > 0) {
              this.getDepartment = response.data.data
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            }
          } else {
            this.noDataFound = true
            this.pageCount = response.data.message
            this.docPageCount = this.pageCount
            this.docPageNo = this.pageNo
            this.docPageSize = this.pageSize
            this.getPageableDoc = response.data.data
            this.myloader = false
          }
        }
      ).catch(e => {
        this.myloader = false
      })
    },

    loadPageableDepartmentList (pageNo, pageSize) {
      return new Promise((resolve, reject) => {
        this.myloader = true

        API.getPageableDepartmentList(pageNo, pageSize).then(
          response => {
            if (response.data.status) {
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getDepartment = response.data.data
              this.myloader = false
            } else {

            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ' Requests Not loaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false

          reject(e)
        })
      })
    },

    onChange (event) {
      this.pageSize = event.target.value
      this.pageNo = 0
      if (this.hasSearchedVariable) {
        this.loadSearchRequestDepartmentList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableDepartmentList(this.pageNo, this.pageSize)
      }
      this.docPageNo = this.pageNo
      this.docPageSize = this.pageSize
      this.docPageCount = this.pageCount
    },

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestDepartmentList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableDepartmentList(this.pageNo, this.pageSize)
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestDepartmentList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableDepartmentList(this.pageNo, this.pageSize)
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.loadSearchRequestCityList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCityList(this.pageNo, this.pageSize)
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestDepartmentList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableDepartmentList(this.pageNo, this.pageSize)
      }
    },

    reloadByIcon () {
      this.loadPageableDepartmentList(this.pageNo, this.pageSize)
      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParamenter = ''
    },

    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadPageableDepartmentList(this.pageNo, this.pageSize)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParamenter = ''
      }
    },

    loadDepartment () {
      this.myloader = true

      API.getDepartment().then(response => {
        if (response.data.status) {
          this.getDepartment = response.data.data
          this.myloader = false
        } else {

        }
      })
        .catch(e => {
          this.ErrorMessage = ' Record not loaded'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false
        })
    },

    loadCompany () {
      // for company select box

      API.getCompany().then(response => {
        if (response.data.status) {
          this.getCompany = response.data.data
        } else {
        }
      })
        .catch(e => {
          this.ErrorMessage = ' Record not loaded'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false
        })
    },

    loadSingleDepartment (data) {
      API.getIndividualDepartment(data)
        .then(response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        })
        .catch(e => {
          // this.state.errors.push(e)
        })
    },

    saveDepartment () {
      if (this.$v.department.departmentName.$invalid || this.$v.department.departmentCode.$invalid ||
          this.$v.department.company.companyId.$invalid) {
        if (this.$v.department.departmentName.$invalid) {
          this.departmentNameError = true
        }
        if (this.$v.department.departmentCode.$invalid) {
          this.departmentCodeError = true
        }
        if (this.$v.department.company.companyId.$invalid) {
          this.companyIdError = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API.postDepartment(JSON.stringify(this.department))
          .then(response => {
            if (response.data.status) {
              this.SuccessMessage =
                  '"' + response.data.data.departmentName + '" department saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadPageableDepartmentList(this.pageNo, this.pageSize)
              this.editMyLoader = false
              this.department.departmentName = ''
              this.department.departmentCode = ''
              this.department.company.companyId = ''
            } else {
            }
          })
          .catch(e => {
            this.editMyLoader = false
            this.ErrorMessage = ' Record not added'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.editMyLoader = false
            this.department.departmentName = ''
            this.closeModal()
          })

        // }, 2000);
      }
    },

    deleteDepartment (data, name) {
      this.$store.dispatch('commitCityNameBeforeEdit', name)

      this.$dialog
        .confirm(
          'If you delete cargo type [ ' + name + " ], it'll be gone forever.",
          {
            loader: true
          }
        )
        .then(dialog => {
          // setTimeout(() => {

          API.deleteDepartment(data)
            .then(response => {
              if (response.data.status) {
                this.SuccessMessage =
                    ' Company type [ ' +
                    this.getDepartmentNameBeforeEdit +
                    ' ] has been deleted'
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                window.scrollTo(10, 0)
                this.loadPageableDepartmentList(this.pageNo, this.pageSize)
                this.$el.scrollTop = 0
              } else {
              }
            })
            .catch(e => {
              this.ErrorMessage = ' Record not deleted'
              this.SuccessMessage = ''
              this.SuccessAlert = false
              this.ErrorAlert = true
            })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editDepartment () {
      if (this.$v.departmentName.$invalid || this.$v.departmentCode.$invalid ||
          this.$v.companyId.$invalid) {
        if (this.$v.departmentName.$invalid) {
          this.departmentNameError = true
        }
        if (this.$v.departmentCode.$invalid) {
          this.departmentCodeError = true
        }
        if (this.$v.companyId.$invalid) {
          this.companyIdError = true
        }
      } else {
        this.editMyLoader = true

        this.array = {
          departmentName: this.departmentName,
          departmentCode: this.departmentCode,
          departmentId: this.departmentId,
          company: {
            companyId: this.companyId
          }
        }

        // console.log(JSON.stringify(this.array).replace(/[\[\]']+/g, ''));

        // setTimeout(() => {

        API.updateDepartment(JSON.stringify(this.array))
          .then(response => {
            if (response.data.status) {
              // this.SuccessMessage =  " " + this.countryName + " Record edited To " + response.data.data.countryName;
              this.SuccessMessage = ' Record edited'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              window.scrollTo(10, 0)
              this.closeModal()
              this.loadPageableDepartmentList(this.pageNo, this.pageSize)
              this.editMyLoader = false
            } else {
            }
          })
          .catch(e => {
            this.errors = e
            this.editMyLoader = false
            this.ErrorMessage = ' Record not edited'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.editMyLoader = false
            this.closeModal()
          })

        // }, 2000);
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (
      departmentId = null,
      departmentName = null,
      departmentCode = null,
      companyId = null,
      actionButton
    ) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.ShowModalDiv = false
        this.UploadModalDiv = false
        this.departmentName = departmentName
        this.departmentCode = departmentCode
        this.departmentId = departmentId
        this.companyId = companyId
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.UploadModalDiv = false
      } else {
        this.loadSingleDepartment(departmentId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
        this.UploadModalDiv = false
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.UploadModalDiv = false

      this.department.departmentName = ''
      this.department.departmentCode = ''
      this.department.company.companyId = ''

      this.departmentCodeError = false
      this.departmentNameError = false
      this.companyIdError = false
    },

    uploadFile (event) {
      this.fileData = event.target.files[0]
      this.file = this.fileData.name

      this.myloader = true

      const formData = new FormData()

      formData.append('file', this.fileData)

      API.uploadDepartmentExcel(formData)
        .then(response => {
          if (response.data.status) {
            response.data.data.map(value => {
              this.getDepartmentArray.push(value.departmentName)
            })

            this.SuccessAlert = true
            this.ErrorAlert = false
            this.SuccessMessage =
                ' Departments Added [ ' + this.getDepartmentArray + ' ]'
            this.myloader = false
            this.loadDepartment()
            this.$el.scrollTop = 0
          } else {
          }
        })
        .catch(e => {
          this.ErrorMessage = ' Record not sent'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false
        })
    }
  }
}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.5;
    text-decoration: none;
    /*top:-7px;*/
    font-weight: normal;
  }
  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
