<template>
    <div>
        <div class="row" v-if="myFirstLoader">
            <div class="col-md-12 text-center loading-text-color">
                <b>{{ loadingTitle }}</b>
            </div>
            <div class="col-md-12 text-center">
                <br>
            </div>
            <div class="col-md-12 text-center">
                <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                />
            </div>
        </div>
        <div class="row" v-if="!myFirstLoader">
            <div class="col-md-12 table-header" v-if="hasFilter && checkIfUserIsPermitted(finalUserPermissions, permissions)">
                <form @submit.prevent="searchModifier">
                    <div class="row">
                        <div class="col-md-12" v-if="whichModuleAndType === 'doRequest'">
                            <label>CFA Agent </label>
                            <list-select
                            :list="allCfaAgents"
                            class="form-control"
                            :selected-item="doRequestCfaAgent"
                            option-value="cfAgentId"
                            option-text="agentName"
                            placeholder="Type in cfa agent name to search"
                            @select="populateDoRequestCfaAgent"
                            />
                        </div>
                        <div class="col-md-12" v-if="whichModuleAndType === 'cfa' || whichModuleAndType === 'cfaCharge'">
                            <label class="typo__label" v-if="allCustomers.length > 0">Search TASAC customer</label>
                            <label class="typo__label" v-else>
                                <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                                Loading TASAC customers, please wait
                            </label>
                            <list-select
                            :list="allCustomers"
                            class="form-control"
                            :selected-item="cfaApplicationCustomer"
                            option-text="name"
                            option-value="customerId"
                            placeholder="Search TASAC customer"
                            @select="populateCfaApplicationCustomer"
                            />
                        </div>
                        <div class="col-md-12" v-if="whichModuleAndType === 'cfaApplication'">
                            <label class="typo__label" v-if="allCustomers.length > 0">Search TASAC customer(s)</label>
                            <label class="typo__label" v-else>
                                <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                                Loading TASAC customers, please wait
                            </label>
                            <multiselect v-model="customerIds"
                            :options="allCustomers"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :hide-selected="true"
                            :taggable="true"
                            :disabled="allCustomers.length <= 0"
                            placeholder="Search TASAC customer(s)"
                            label="name"
                            track-by="customerId"
                            :preselect-first="false"
                            >
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'customer' : 'customers'}} selected</span>
                                </template>
                            </multiselect>
                            <!-- <pre class="language-json"><code>{{ customerIds  }}</code></pre> -->
                        </div>
                        <div class="col-md-6" v-if="(whichModuleAndType === 'cfaApplication' || whichModuleAndType === 'cfaCustomerApplication') && applicationCurrentStatus !== 'new' && applicationCurrentStatus !== 'accepted' && applicationCurrentStatus !== 'minor-issue'
                        && applicationCurrentStatus !== 'major-issue' && applicationCurrentStatus !== 'returned'">

                            <label class="typo__label" v-if="activeTariffItems.length > 0">Select tariff Items</label>
                            <label class="typo__label" v-else>
                                <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                                Loading tariff Items, please wait
                            </label>
                            <multiselect v-model="tariffItem"
                            :options="activeTariffItems"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :disabled="activeTariffItems.length <= 0"
                            placeholder="Select tariff Items"
                            label="tariffItemName"
                            track-by="tariffItemId"
                            :preselect-first="false"
                            >

                            </multiselect>
                            <!-- <pre class="language-json"><code>{{ tariffItem  }}</code></pre> -->
                        </div>
                        <div class="col-md-6" v-if="whichModuleAndType === 'cfa' || whichModuleAndType === 'cfaCustomerBill'">
                            <label class="typo__label" v-if="activeTariffItems.length > 0">Select tariff Items</label>
                            <label class="typo__label" v-else>
                                <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                                Loading tariff Items, please wait
                            </label>
                            <multiselect v-model="tariffItem"
                            :options="activeTariffItems"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :disabled="activeTariffItems.length <= 0"
                            placeholder="Select tariff Items"
                            label="tariffItemName"
                            track-by="tariffItemId"
                            :preselect-first="false"
                            >

                            </multiselect>
                            <!-- <pre class="language-json"><code>{{ tariffItem  }}</code></pre> -->
                        </div>
                        <div class="col-md-6" v-if="whichModuleAndType === 'cfaApplication' || whichModuleAndType === 'cfaCustomerApplication'">
                            <label class="typo__label" v-if="stations.length > 0">Select stations</label>
                            <label class="typo__label" v-else>
                                <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                                Loading stations, please wait
                            </label>
                            <multiselect v-model="stationIds"
                            :options="stations"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :hide-selected="true"
                            :disabled="stations.length <= 0"
                            placeholder="Select stations"
                            label="stationDescription"
                            track-by="stationId"
                            :preselect-first="false"
                            >
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'station' : 'stations'}} selected</span>
                                </template>
                            </multiselect>
                            <!-- <pre class="language-json"><code>{{ stationIds  }}</code></pre> -->
                        </div>
                        <div class="col-md-12" v-if="whichModuleAndType === 'dc' || whichModuleAndType === 'dcCharge'">
                            <label>company </label>
                            <list-select
                            :list="allCompanies"
                            class="form-control"
                            :selected-item="dcChargeCompany"
                            :custom-text="customCompanyCodeAndName"
                            option-value="companyId"
                            placeholder="Type in company name to search"
                            @select="populateDcCharge"
                            />
                        </div>
                        <div class="col-md-12" v-if="whichModuleAndType === 'dcEdi'">
                            <label v-if="ediCompaniesLoader"><pulse-loader :color="color" :size="size" class="float-left mr-4 pt-1" /> Loading companies, please wait</label>
                            <label v-if="!ediCompaniesLoader">Search for company </label>
                            <list-select
                            :list="allCompanies"
                            class="form-control"
                            :selected-item="ediCompany"
                            :custom-text="customCompanyCodeAndName"
                            :isDisabled="ediCompaniesLoader"
                            option-value="companyCode"
                            placeholder="Type in company name to search"
                            @select="populateEdiCompany"
                            />
                        </div>
                        <div class="col-md-12" v-if="whichModuleAndType === 'allBillSummary'">
                            <label>all customer</label>
                            <list-select
                            :list="allCustomers"
                            class="form-control"
                            :selected-item="allBillSummaryCustomer"
                            :isDisabled="ediCompaniesLoader"
                            option-value="customerId"
                            option-text="name"
                            placeholder="Type in customer name to search"
                            @select="populateAllBillSummaryCustomer"
                            />
                        </div>

                        <div class="col-md-4" v-if="whichModuleAndType === 'vesselPrincipal'">
                            <base-input class="special-bottom" placeholder="Account Number" label="Account Number"
                            type="text" v-model="accountNo"></base-input>
                        </div>
                        <div class="col-md-4" v-if="whichModuleAndType === 'vesselPrincipal'">
                            <base-input class="special-bottom" placeholder="Carrier Name" label="Carrier Name"
                            type="text" v-model="carrierCode"></base-input>
                        </div>
                        <div class="col-md-4" v-if="whichModuleAndType === 'vesselPrincipal'">
                            <base-input class="special-bottom" placeholder="Principal Name" label="Principal Name"
                            type="text" v-model="name"></base-input>
                        </div>

                        <div class="col-md-3" v-if="whichModuleAndType === 'dcCharge' || whichModuleAndType === 'dcEdi' || whichModuleAndType === 'doRequest' || whichModuleAndType === 'vesselParticular'">
                            <base-input class="special-bottom" placeholder="Voyage Number" label="Voyage Number"
                            type="text" v-model="voyageNumber"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'dcCharge' || whichModuleAndType === 'dcEdi' || whichModuleAndType === 'vesselParticular'">
                            <base-input class="special-bottom" placeholder="Vessel Name" label="Vessel Name"
                            type="text" v-model="vesselName"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'dcCharge' || whichModuleAndType === 'dcEdi'">
                            <base-input class="special-bottom" placeholder="TASAC Control Number" label="TASAC Control Number"
                            type="text" v-model="tasacControlNumber"></base-input>
                        </div>
                        <div class="col-md-6" v-if="whichModuleAndType === 'disbursementDeposit'">
                            <base-input class="special-bottom" placeholder="Payer Name" label="Payer Name"
                            type="text" v-model="payerName"></base-input>
                        </div>
                        <div class="col-md-6" v-if="whichModuleAndType === 'disbursementDeposit'">
                            <base-input class="special-bottom" placeholder="Payment Reference" label="Payment Reference"
                            type="text" v-model="paymentReference"></base-input>
                        </div>
                        <div class="col-md-3" style="padding-right:50px;padding-left:50px;padding-top:39px;" v-if="whichModuleAndType === 'dcEdi'">
                            <div class="custom-control float-left">
                            <input id="true" value="true" name="charged" type="radio" v-model="charged">&nbsp;
                            <label for="true">Charged</label>
                            </div>
                            <div class="custom-control float-right">
                            <input id="false" value="false" name="charged" type="radio" v-model="charged">&nbsp;
                            <label for="false">Not Charged</label>
                            </div>
                        </div>
                        <div class="col-md-3"  v-if="whichModuleAndType === 'dcCharge'">
                            <label>Operation Type</label>
                            <select class="form-control" v-model="operationType">
                                <option value="" selected>Select operation type</option>
                                <option value="import">IMPORT</option>
                                <option value="export">EXPORT</option>
                            </select>
                        </div>
                        <div class="col-md-3"  v-if="whichModuleAndType === 'dcCharge'">
                            <label>Document Status</label>
                            <select class="form-control" v-model="documentStatus">
                                <option value="" selected>Select document status</option>
                                <option value="billed">BILLED</option>
                                <option value="unBilled">CHARGED</option>
                                <option value="cancelled">CANCELLED</option>
                            </select>
                        </div>
                        <div class="col-md-3"  v-if="whichModuleAndType === 'doRequest'">
                            <label>Delivery Order Status</label>
                            <select class="form-control" v-model="doRequestStatus">
                                <option value="" selected>Select delivery order status</option>
                                <option value="CREATED">CREATED</option>
                                <option value="ACCEPTED">ACCEPTED</option>
                                <option value="REJECTED">REJECTED</option>
                                <option value="NO_BL_FOUND">NO BL FOUND</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'SAServiceProvider'">
                            <base-input class="special-bottom" placeholder="Name" label="Name"
                            type="text" v-model="name"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'SAServiceProvider'">
                            <base-input class="special-bottom" placeholder="Account Number" label="Account Number"
                            type="text" v-model="accountNo"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'SAServiceProvider'">
                            <base-input class="special-bottom" placeholder="TIN" label="TIN"
                            type="text" v-model="tinNo"></base-input>
                        </div>
                        <div class="col-md-3" style="padding-right:50px;padding-left:50px;padding-top:39px;" v-if="whichModuleAndType === 'SAServiceProvider'">
                            <div class="custom-control float-left">
                            <input id="true" value="true" name="charged" type="radio" v-model="isGovernment">&nbsp;
                            <label for="true">Goverment</label>
                            </div>
                            <div class="custom-control float-right">
                            <input id="false" value="false" name="charged" type="radio" v-model="isGovernment">&nbsp;
                            <label for="false">Non Goverment</label>
                            </div>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'SAServiceProvider'">
                            <base-input class="special-bottom" placeholder="VRN" label="VRN"
                            type="text" v-model="vrn"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'doRequest'">
                            <base-input class="special-bottom" placeholder="Destination" label="Destination"
                            type="text" v-model="destination"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'vesselParticular'">
                            <base-input class="special-bottom" placeholder="Arrival Port" label="Arrival Port"
                            type="text" v-model="arrivalPort"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'vesselParticular'">
                            <base-input class="special-bottom" placeholder="Destination Port" label="Destination Port"
                            type="text" v-model="destinationPort"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'vesselParticular'">
                            <base-input class="special-bottom" placeholder="IMO" label="IMO"
                            type="text" v-model="imo"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'doRequest'">
                            <base-input class="special-bottom" placeholder="TRA Licence Number" label="TRA Licence Number"
                            type="text" v-model="traLicenceNumber"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'doRequest'">
                            <base-input class="special-bottom" placeholder="Consignee Name" label="Consignee Name"
                            type="text" v-model="consigneeName"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'cfaCharge' || whichModuleAndType==='cfaApplication' || whichModuleAndType === 'cfaCustomerApplication' ">
                            <base-input class="special-bottom" placeholder="BL Number" label="BL Number"
                            type="text" v-model="blNumber"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'cfaCharge' || whichModuleAndType==='cfaApplication' || whichModuleAndType === 'cfaCustomerApplication'"> <!--  || whichModuleAndType === 'cfaCustomerBill' -->
                            <base-input class="special-bottom" placeholder="File Number" label="File Number"
                            type="text" v-model="fileNo"></base-input>
                        </div>
                        <!-- <div class="col-md-3"  v-if="whichModuleAndType === 'cfaApplication'">
                            <label>Dismissal Case</label>
                            <select class="form-control" v-model="dismissCase">
                            <option value="" selected>Select dismissal case</option>
                            <option value="DUPLICATE">Duplicate</option>
                            <option value="ORDER_CANCELLATION">Order Cancellation</option>
                            <option value="OTHER">Other</option>

                            </select>
                        </div> -->
                        <div class="col-md-3" v-if="(whichModuleAndType === 'cfaApplication' || whichModuleAndType === 'cfaCustomerApplication') && (applicationCurrentStatus === 'request is dismissed' || applicationCurrentStatus === 'all')">
                            <label class="typo__label">Select dismissal Reason</label>
                            <multiselect v-model="dismissCase"
                            :options="dismissCases"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Select dismissal Reason"
                            label="name"
                            track-by="id"
                            :preselect-first="false"
                            >

                            </multiselect>
                            <!-- <pre class="language-json"><code>{{ dismissCase  }}</code></pre> -->
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'cfa' || whichModuleAndType === 'dc' || whichModuleAndType === 'allBillSummary' || whichModuleAndType === 'cfaCustomerBill' || whichModuleAndType === 'blBill'">
                            <base-input class="special-bottom" placeholder="Control Number" label="Control Number"
                            type="text" v-model="controlNumber"></base-input>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'cfa' || whichModuleAndType === 'dc' || whichModuleAndType === 'allBillSummary' || whichModuleAndType === 'cfaCustomerBill'">
                            <base-input class="special-bottom" placeholder="Invoice Number" label="Invoice Number"
                            type="text" v-model="invoiceNo"></base-input>
                        </div>
                        <!-- <div class="col-md-3" v-if="whichModuleAndType === 'cfa' || whichModuleAndType === 'dc' || whichModuleAndType === 'allBillSummary' || whichModuleAndType === 'cfaCustomerBill'">

                            <base-input class="special-bottom" placeholder="TANSAD Number" label="TANSAD Number"
                            type="text" v-model="tansadNo"></base-input>
                        </div> -->
                        <div class="col-md-3"  v-if="whichModuleAndType === 'cfaCharge'">
                            <label>Application Type</label>
                            <select class="form-control" v-model="requestType">
                                <option value="" selected>Select application type</option>
                                <option value="import">IMPORT</option>
                                <option value="export">EXPORT</option>
                            </select>
                        </div>
                        <div class="col-md-3"  v-if="whichModuleAndType === 'doRequest'">
                            <label>Cargo Delivery Type</label>
                            <select class="form-control" v-model="cargoDeliveryType">
                                <option value="" selected>Select cargo delivery type</option>
                                <option v-for="(cdType, index_cdType) in cargoDeliveryTypes" :value="cdType.id" :key="index_cdType">{{ cdType.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="(whichModuleAndType === 'allBillSummary'
                        || whichModuleAndType === 'cfaCustomerBill' || whichModuleAndType === 'cfaCharge' || whichModuleAndType === 'cfaApplication')
                        && applicationCurrentStatus !== 'request submitted' && applicationCurrentStatus !== 'request accepted' && applicationCurrentStatus !== 'request returned for major changes'
                        && applicationCurrentStatus !== 'request returned for minor changes' && applicationCurrentStatus !== 'request resubmitted after changes'">
                            <div class="row" style="margin-left:1px;margin-right:1px;">
                                <div class="col-md-12" style="font-size:12px;">
                                BT NUMBER
                                </div>
                                <div class="col-md-12" style="margin-top:-9px;">
                                    <base-input  id="sevenBTDigit" type="text" placeholder="BT Number" v-model="BTNumber7"></base-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'cfa'">
                            <base-input class="special-bottom" placeholder="TANSAD / BT Number" label="TANSAD / BT Number"
                            type="text" v-model="tansadOrBtNo"></base-input>
                        </div>
                        <!-- <div class="col-md-3" v-if="whichModuleAndType === 'cfaApplication' || whichModuleAndType === 'cfaCustomerApplication'">
                            <base-input class="special-bottom" placeholder="TANSAD Number" label="TANSAD Number"
                            type="text" v-model="tansadNumber"></base-input>
                        </div> {{BTTasacTin}}-2021-T-0004854 | {{BTTasacTin}}-2022-T-0000003-->
                        <div class="col-md-3" v-if="(whichModuleAndType === 'allBillSummary'
                        || whichModuleAndType === 'cfaCustomerBill' || whichModuleAndType === 'cfaCharge' || whichModuleAndType === 'cfaApplication')
                        && applicationCurrentStatus !== 'request submitted' && applicationCurrentStatus !== 'request accepted' && applicationCurrentStatus !== 'request returned for major changes'
                        && applicationCurrentStatus !== 'request returned for minor changes' && applicationCurrentStatus !== 'request resubmitted after changes'">
                            <div class="row" style="margin-left:1px;margin-right:1px;">
                                <div class="col-md-12" style="font-size:12px;">
                                TANSAD NUMBER
                                </div>
                                <div class="col-md-12" style="margin-top:-9px;">
                                  <base-input  id="sevenDigit" type="text" placeholder="Tansad Number"
                                v-model="TANSADNumber7"></base-input>
                                </div>
                            </div>
                        </div >

                        <div class="col-md-3" v-if="whichModuleAndType === 'cfaCharge'">
                            <label>Select Regime Type</label>
                            <select class="form-control" v-model="regimeId">
                                <option value="" selected>Select regime type</option>
                                <option v-for="(getRegime, index_getRegime) in getRegimes" :value="getRegime.regimeId" :key="index_getRegime">{{ getRegime.regimeName }}</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'cfaApplication' || whichModuleAndType === 'cfaCustomerApplication'">
                            <label class="typo__label">Select regime(s)</label>
                            <multiselect v-model="regimeIds"
                            :options="getRegimes"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :hide-selected="true"
                            placeholder="Select regime(s)"
                            label="regimeName"
                            track-by="regimeId"
                            :preselect-first="false"
                            >
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'regime' : 'regimes'}} selected</span>
                                </template>
                            </multiselect>
                            <!-- <pre class="language-json"><code>{{ regimeIds  }}</code></pre> -->
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'cfaCharge'">
                            <label>Select Application Status</label>
                            <select class="form-control" v-model="applicationStatus">
                                <option value="" selected>Select application status</option>
                                <option v-for="(applicationStatus, index_applicationStatus) in applicationStatusesProp" :value="applicationStatus.id" :key="index_applicationStatus">{{ applicationStatus.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'cfaCharge'">
                            <label>Select Station</label>
                            <select class="form-control" v-model="stationId">
                                <option value="" selected>Select Station</option>
                                <option v-for="(getStation, index_getStation) in getStations" :value="billStatus.stationId" :key="index_getStation">{{ getStation.stationName }}</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'allBillSummary' || whichModuleAndType === 'cfaCustomerBill'">
                            <label>Select bill status</label>
                            <select class="form-control" v-model="billStatus">
                                <option value="" selected>Select bill status</option>
                                <option v-for="(billStatus, index_billStatus) in billStatuses" :value="billStatus.id" :key="index_billStatus">{{ billStatus.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'vesselParticular'">
                            <label>Select date category</label>
                            <select class="form-control" v-model="dateCategory">
                                <option value="" selected>Select date category</option>
                                <option v-for="(dateCategory, index_dateCategory) in dateCategories" :value="dateCategory.id" :key="index_dateCategory">{{ dateCategory.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'vesselParticular'">
                            <label>Select maritime vessel status</label>
                            <select class="form-control" v-model="maritimeVesselStatus">
                                <option value="" selected>Select maritime vessel status</option>
                                <option v-for="(marinetimeStatus, index_marinetimeStatus) in marinetimeStatuses" :value="marinetimeStatus.id" :key="index_marinetimeStatus">{{ marinetimeStatus.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'vesselParticular'">
                            <label>Select vessel type</label>
                            <select class="form-control" v-model="vesselType">
                                <option value="" selected>Select vessel type</option>
                                <option v-for="(vesselType, index_vesselType) in vesselTypes" :value="vesselType.id" :key="index_vesselType">{{ vesselType.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="whichModuleAndType === 'allBillSummary'">
                            <label>Select Bill Type</label>
                            <select class="form-control" v-model="dateCategory">
                                <option value="" selected>Select bill type</option>
                                <option v-for="(billType, index_billType) in billTypes" :value="billType.id" :key="index_billType">{{ billType.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3" v-if="(whichModuleAndType === 'cfaApplication' || whichModuleAndType === 'cfaCustomerApplication') && applicationCurrentStatus === 'all'">
                            <label class="typo__label">Select application status(es)</label>
                            <multiselect v-model="applicationStatuses"
                            :options="applicationStatusesProp"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :hide-selected="true"
                            placeholder="Select application status(es)"
                            label="name"
                            track-by="id"
                            :preselect-first="false"
                            >
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'status' : 'statuses'}} selected</span>
                                </template>
                            </multiselect>
                            <!-- <pre class="language-json"><code>{{ applicationStatuses  }}</code></pre> -->
                        </div>
                        <div class="col-md-3">
                            <base-input
                            v-if="whichModuleAndType !== 'disbursementDeposit'"
                            type="date"
                            label="Start Date"
                            v-model="startDate"
                            :max="todayDate"
                            />
                        </div>
                        <div class="col-md-3">
                            <base-input
                            v-if="whichModuleAndType !== 'disbursementDeposit'"
                            type="date"
                            label="End Date"
                            v-model="endDate"
                            :min="startDate"
                            :max="todayDate"
                            :disabled="startDate === ''"
                            />
                        </div>
                      <div class="col-md-3" v-if="whichModuleAndType === 'cfaCharge' || whichModuleAndType==='cfaApplication' || whichModuleAndType === 'cfaCustomerApplication' || whichModuleAndType === 'cfa' ">
                        <base-input class="special-bottom" placeholder="Vessel Name" label="Vessel"
                                    type="text" v-model="vessel"></base-input>
                      </div>

                    </div>
                    <div class="text-center row">
                    <div class="col-md-6 text-right loader-div">
                    </div>
                    <div class="col-md-2 left loader-div-gif">
                    </div>
                    <div class="col-md-4">
                        <button
                        @click.prevent="resetParams"
                        class="btn btn-fill btn-outline-info float-right custom-btn-all"
                        data-dismiss="modal"
                        >
                        Reset
                        </button>
                        <button
                        type="submit"
                        class="btn btn-primary btn-space float-right custom-btn"
                        data-dismiss="modal"
                        >
                        Search
                        </button>
                    </div>
                    </div>
                </form>
            </div>
            <div class="col-md-12 text-right" v-if="hasPlainFilter">
                <form class="search" @submit.prevent="searchPlain">
                <div class="row">
                    <div class="col-md-12">
                            <base-input class="special-bottom" :placeholder="searchPlaceholder"
                            type="text" v-model="searchParameter"></base-input>
                    </div>
                </div>
                <div class="text-center row">
                    <div class="col-md-6 text-right loader-div">
                    </div>
                    <div class="col-md-2 left loader-div-gif">
                    </div>
                    <div class="col-md-4">
                        <button
                        @click.prevent="resetParams"
                        class="btn btn-fill btn-outline-info float-right custom-btn-all"
                        data-dismiss="modal"
                        >
                        Clear filter
                        </button>
                        <button
                        type="submit"
                        class="btn btn-primary btn-space float-right custom-btn"
                        data-dismiss="modal"
                        >
                        Filter List
                        </button>
                    </div>
                    </div>
                </form>
            </div>
            <div class="col-md-12 text-left mt-2 mb-2" v-if="hasPagination">
                <select
                    name="PageSize"
                    @change="onChange"
                    class="custom-select"
                    v-model="checkPageSize"
                    :class="[ allowPerPageSelection <= 1 ? 'disabled-click' : 'enabled-click']">
                    <option value="15">
                        15 / page
                    </option>
                    <option value="25">
                        25 / page
                    </option>
                    <option value="50">
                        50 / page
                    </option>
                    <option value="75">
                        75 / page
                    </option>
                    <option value="100">
                        100 / page
                    </option>
                </select>
            </div>

            <div class="col-md-12 table-responsive" v-if="checkIfUserIsPermitted(finalUserPermissions, permissions)">
                <table class="table col-md-12 table-hover" id = "myTable">
                    <thead>
                    <tr class="custom-table-header">
                        <th>#</th>
                        <th v-for="(tableHeader , indexHT) in tableHeaders" :key="indexHT" class="text-left" @click="sortTable(indexHT)">{{ tableHeader }}</th>
                        <th class="text-center noprint"
                            v-if="hasDuplicateButton || hasEditButton || hasViewButton || hasManageButton
                            || hasCustomButton || hasDeleteButton || hasViewMoreAssessment || hasDeleteIconButton || hasAddIconButton || hasEditIconButton
                            || hasDownloadIconButton || hasSendPaymentRequestIconButton || buttonList.length > 0">
                        Action
                        </th>
                        <th v-else></th>
                        <th style="display: none"/>
                    </tr>
                    </thead>
                    <tbody v-if="!noDataFound || tableData.length > 0">
                        <tr v-for="(data , indexTD) in tableData" :key="indexTD" :class="[categoryItemIds.includes(data.disbursementInvoiceItemId) || coloredRow.includes(data.itemId) ? 'background-color' : '']">
                        <td v-if="isPageable">
                        {{ (indexTD + (parseInt(pageSize) * parseInt(pageNumber))) + 1 }}
                        </td>
                        <td v-if="!isPageable">
                        {{ indexTD + 1 }}
                        </td>
                        <td v-for="(tableDataKey , index_tableDataKey) in tableDataKeys" :key="index_tableDataKey"
                            @mouseover="hover = true, currentId = indexTD, getMouseLocation($event)" @mouseleave="hover = false" class="text-left">
                            <span v-if="typeof data[tableDataKey] !== 'undefined' && tableDataKey !== 'customer' && tableDataKey !== 'company' && tableDataKey !== 'cargoExpectedTime'
                            && tableDataKey !== 'cfaAgent' && tableDataKey !== 'serviceProvider' && tableDataKey !== 'status' && tableDataKey !== 'activityDescription'
                            && tableDataKey !== 'attachUrl'">{{ data[tableDataKey] }}</span>

                            <span v-if="typeof data[tableDataKey] !== 'undefined' && tableDataKey === 'status' && coloredStatus" :class="getColoredStatus(data[tableDataKey])">
                                <b>{{ getApplicationStatus(data[tableDataKey], newApplicationStatus) }}</b>
                            </span>

                            <!--  -->

                            <span v-if="typeof data[tableDataKey] !== 'undefined' && tableDataKey === 'activityDescription'" :title="data[tableDataKey]">
                                {{ data[tableDataKey].length > 40 ? data[tableDataKey].substring(0, 40) + "..." : data[tableDataKey] }}
                            </span>

                            <span v-if="typeof data[tableDataKey] !== 'undefined' && tableDataKey === 'attachUrl'" class="download-link"
                            @click="viewDocument(data[tableDataKey] , (data['activityName']).toUpperCase() + '_FOR_APPLICATION_WITH_FILE_NUMBER_', 'view', null, null)">
                                {{ data[tableDataKey] !== null ? 'click to view' : '' }}
                            </span>

                            <span v-if="typeof data[tableDataKey] !== 'undefined' && tableDataKey === 'status' && !coloredStatus">
                                <b>{{ data[tableDataKey] }}</b>
                            </span>

                            <span v-if="typeof data[tableDataKey] !== 'undefined' && tableDataKey === 'cargoExpectedTime'"
                                :class="[calculateExpectedTimeLeft(data[tableDataKey]).split('****')[1] <= 7 ? 'blink-me' : '',
                                calculateExpectedTimeLeft(data[tableDataKey]).split('****')[1] < 4 ? 'text-danger' : '',
                                calculateExpectedTimeLeft(data[tableDataKey]).split('****')[1] < 8 && calculateExpectedTimeLeft(data[tableDataKey]).split('****')[1] > 3 ? 'text-success' : '']">
                                <b> {{ calculateExpectedTimeLeft(data[tableDataKey]).split('****')[0] }} </b>
                            </span>

                            <span v-if="typeof data[tableDataKey] !== 'undefined' && tableDataKey === 'customer' || tableDataKey === 'company'">
                            <span class="more-customer-details" @click="showToolTip(data)" v-if="data[tableDataKey] !== null">
                                {{ typeof data[tableDataKey].name !== "undefined" && data[tableDataKey].name !== null ? data[tableDataKey].name.toUpperCase() : '' }}
                                {{ typeof data[tableDataKey].companyName !== "undefined" && data[tableDataKey].companyName !== null ? data[tableDataKey].companyName.toUpperCase() : '' }}
                            </span>
                            <span v-else>
                            </span>
                            <span v-if="isHovered.includes(data)" class="customer-info">
                                <p><u><b>Customer Information</b> (click <a @click="hideToolTip(data)" class="close-info">here</a> to close)</u></p>
                                <b v-if="typeof data[tableDataKey].customerType === 'undefined'">Code:</b> {{ typeof data[tableDataKey].customerType === 'undefined' ? data[tableDataKey].companyCode : "" }} <br>
                                <b>TIN:</b> {{ typeof data[tableDataKey].tin !== "undefined" ? data[tableDataKey].tin : data[tableDataKey].tinNo }}<br>
                                <b>VRN:</b> {{ data[tableDataKey].vrn }} <br>
                                <b>MOBILE:</b> {{ typeof data[tableDataKey].mobile !== "undefined" ? data[tableDataKey].mobile : data[tableDataKey].companyTelephone }}<br>
                                <b>EMAIL:</b> {{ data[tableDataKey].email }}<br>
                                <b>CUSTOMER TYPE:</b>
                                {{ data[tableDataKey].customerType === 'ind001' ? 'Individual' : 'company' }}<br>
                            </span>
                            </span>
                            <span v-if="typeof data[tableDataKey] !== 'undefined' && tableDataKey === 'cfaAgent'">
                            <span class="more-customer-details" @click="showToolTip(data)" v-if="data[tableDataKey] !== null">
                                {{ data[tableDataKey].agentName.toUpperCase() }}
                            </span>
                            <span v-else>
                            </span>
                            <span v-if="isHovered.includes(data)" class="customer-info">
                                <p><u><b>CFA Agent Information</b> (click <a @click="hideToolTip(data)" class="close-info">here</a> to close)</u></p>
                                <b>NAME:</b> {{ data[tableDataKey].agentName }}<br>
                                <b>TIN:</b> {{ data[tableDataKey].agentTin }} <br>
                                <b>MOBILE:</b> {{ data[tableDataKey].agentPhone }}<br>
                                <b>EMAIL:</b> {{ data[tableDataKey].agentEmail }}<br>
                                <b>ADDRESS</b> {{ data[tableDataKey].agentAddress }}<br />
                            </span>
                            </span>

                            <span v-if="typeof data[tableDataKey] !== 'undefined' && tableDataKey === 'serviceProvider'">
                            <span class="more-customer-details" @click="showToolTip(data)" v-if="data[tableDataKey] !== null">
                                {{ data[tableDataKey].name.toUpperCase() }}
                            </span>
                            <span v-else>
                            </span>
                            <span v-if="isHovered.includes(data)" class="customer-info">
                                <p><u><b>Service Provider Information</b> (click <a @click="hideToolTip(data)" class="close-info">here</a> to close)</u></p>
                                <b>NAME:</b> {{ data[tableDataKey].name }}<br>
                                <b>ACCOUNT NUMBER:</b> {{ data[tableDataKey].accountNo }} <br>
                                <b>TIN:</b> {{ data[tableDataKey].tinNo }} <br>
                                <b>VRN:</b> {{ data[tableDataKey].vrn }}<br>
                                <b>MOBILE:</b> {{ data[tableDataKey].phoneNumber }}<br>
                                <b>ADDRESS</b> {{ data[tableDataKey].address }}<br />
                                <b>SECTOR TYPE:</b>
                                {{ data[tableDataKey].government ? 'Government' : 'Non Government' }}<br>
                            </span>
                            </span>
                        </td>
                        <td class="btn-success-custom text-center noprint">
                            <button class="btn btn-success mr-2 custom-btn" v-if="!myLoader && !data['isDuplicate'] && hasCustomButton" @click.prevent="fireCustomMethod(data)">
                                <b>{{ customButtonName }}</b>
                            </button>
                            <button class="btn btn-success mr-2 custom-btn"  v-if="!myLoader && hasManageButton" @click.prevent="goToApplication(data)">
                                <b>Manage</b>
                            </button>
                            <button class="btn btn-default mr-2 custom-btn" v-if="!myLoader && !data['isDuplicate'] && hasViewButton" @click.prevent="goToView(data)">
                                <b>View</b>
                            </button>
                            <button class="btn btn-primary mr-2 custom-btn" v-if="!myLoader && !data['isDuplicate'] && hasEditButton" @click.prevent="goToEdit(data)">
                                <b>Edit</b>
                            </button>
                            <button class="btn btn-danger custom-btn" style="width: 150px" @click.prevent="markAsDuplicate(data['requestId'],
                                data['blNumber'], data['customer'].name)" v-if="!myLoader && !data['isDuplicate'] && hasDuplicateButton">
                                <b>Mark as duplicate</b>
                            </button>
                            <button class="btn btn-danger mr-2 custom-btn" v-if="!myLoader && !data['isDuplicate'] && hasDeleteButton " @click.prevent="goToDelete(data)">
                                <b>Delete</b>
                            </button>
                            <button class="btn btn-primary mr-2 custom-btn" v-if="!myLoader && !data['isDuplicate'] && hasViewMoreAssessment
                            && (data.documentName === 'Assessment Notice' || data.documentName === 'Release Notice') " @click.prevent="goToViewMoreAssessment(data)">
                                <b>View More</b>
                            </button>

                            <button class="btn mr-2 custom-btn" v-for="(name , indexBTN) in buttonList" :key="indexBTN"
                                :class="[name.class , name.code === 'FinishTallying' && data.completed && disabled ? 'disabled-click' : '']"
                                :disabled="(name.code === 'FinishTallying' && data.completed && disabled)"
                                @click.prevent="fireCustomButton(data , name.code)">
                                <b v-if="!myLoader && !data['isDuplicate'] && buttonList.length > 0 ">
                                {{ name.name }}
                                </b>
                            </button>
                            <div class="btn btn-circle delete-circle mr-2" title = "remove from list" v-if="hasDeleteIconButton" @click.prevent="removeItemFromList(data)">
                                <font-awesome-icon class="delete-fa-icon delete-icon" icon="trash-alt"/>
                            </div>
                            <div class="btn btn-circle add-circle mr-2" title = "remove from list" v-if="hasAddIconButton" @click.prevent="addItemFromList(data)">
                                <font-awesome-icon class="plus-container" icon="plus"/>
                            </div>
                            <div class="btn btn-circle edit-circle mr-2" title = "edit item" v-if="hasEditIconButton" @click.prevent="editItemFromList(data)">
                                <font-awesome-icon class="plus-container" icon="edit"/>
                            </div>
                            <div class="btn btn-circle edit-circle mr-2" title = "download item" v-if="hasDownloadIconButton" @click.prevent="downloadItem(data)">
                                <font-awesome-icon class="plus-container" icon="download"/>
                            </div>
                            <div class="btn btn-circle add-circle" title = "add item to payment request list"
                            v-if="hasSendPaymentRequestIconButton && !data.paymentRequested && !categoryItemIds.includes(data.disbursementInvoiceItemId)" @click.prevent="addItemToPaymentRequest(data)">
                                <font-awesome-icon class="plus-container" icon="plus"/>
                            </div>
                            <div class="btn btn-circle delete-circle" title = "remove item to payment request list"
                            v-if="hasSendPaymentRequestIconButton && !data.paymentRequested && categoryItemIds.includes(data.disbursementInvoiceItemId)" @click.prevent="removeItemFromPaymentRequest(data)">
                                <font-awesome-icon class="delete-fa-icon delete-icon" icon="minus"/>
                            </div>
                            <!-- <div class="btn btn-circle invoice-circle-done" title = "send payment request" v-if="hasSendPaymentRequestIconButton && data.paymentRequested">
                                <font-awesome-icon class="plus-container" icon="file-invoice-dollar"/>
                            </div> -->
                        </td>
                        <td style="display: none"/>
                    </tr>
                    </tbody>
                </table>
                <div class="row text-center" v-if="noDataFound || tableData.length <= 0">
                    <div class="text-center col-md-12">
                        No data found
                    </div>
                </div>
            </div>
            <div class="col-md-4 table-footer pt-3" style="font-size:18px;padding-left:55px;" v-if="hasPagination && checkIfUserIsPermitted(finalUserPermissions, permissions)">
                {{ tableData.length !== 0 ? showingPageRanger : '' }}
            </div>
            <div class="col-md-4 table-footer" v-if="hasPagination">
            </div>
            <div class="col-md-4 table-footer pt-3" v-if="hasPagination && checkIfUserIsPermitted(finalUserPermissions, permissions)"
            :class="[totalPages <= 1 ? 'disabled-click' : 'enabled-click']">
                <slot name="pagination" class="noselect"></slot>
            </div>
            <div class="col-md-12 text-center text-danger" v-if="!checkIfUserIsPermitted(finalUserPermissions, permissions)">
                <p>You dont have permission to view this list, please contact system administrator</p>
            </div>

        </div>
    </div>
</template>
<script>

import {mapGetters} from "vuex"
import SvgIcon from '../SvgLoader/svgLoader'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { ModelListSelect, ListSelect } from 'vue-search-select'
import Multiselect from 'vue-multiselect'
import { VESSEL_TYPES, BILL_STATUSES, MARITIME_STATUSES, DATE_CATEGORIES, BILL_TYPES, CARGO_DELIVERY_TYPES, DISMISSAL_REASONS, TASAC_TIN } from '../../mixins/constants'

export default {

    name: 'dataTable',

    data() {

        return {

            finalUserPermissions: [],
            pageSize: 15,
            color: 'green',
            size: '12px',
            size1: '9px',
            loading: true,
            hover: false,
            currentId: '',
            baseButtonName: 'goTo',
            styleObject:{
                marginBottom: 0.0
            },
            billStatuses: BILL_STATUSES,
            marinetimeStatuses: MARITIME_STATUSES,
            vesselTypes: VESSEL_TYPES,
            dateCategories: DATE_CATEGORIES,
            billTypes: BILL_TYPES,
            dismissCases: DISMISSAL_REASONS,
            todayDate: new Date().toJSON().slice(0, 10),
            startDate: '',                                        /* new and old filter */
            endDate: '',                                          /* new and old filter */
            billType: '',
            billStatus: '',
            customerId: '',
            controlNumber: '',
            invoiceNo: '',
            tansadNo: '',
            blNumber: '',                                         /* new and old filter */
            vessel:'',
            fileNo: '',                                           /* new and old filter */
            dismissCase: null,                                       /* new and old filter */
            requestType: '',                                      /* new and old filter */
            regimeId: '',
            stationId: '',
            tansadNumber: null,                                     /* new filter */
            tariffItem: null,                                       /* new filter */
            assigmentUserId: '',                                  /* new filter  and from backend*/
            customerIds: [],                                      /* new filter */
            regimeIds: [],                                        /* new filter */
            stationIds: [],                                       /* new filter */
            applicationStatuses: [],                              /* new filter */
            voyageNumber: '',
            vesselName: '',
            tasacControlNumber: '',
            operationType: '',
            cancelled: false,
            billed: false,
            documentStatus: '',
            charged: '',
            hasSearchRequest: false,
            selectedCustomerName: '',
            cargoDeliveryTypes: CARGO_DELIVERY_TYPES,
            cargoDeliveryType: '',
            destination: '',
            cfAgentId: '',
            consigneeName: '',
            doRequestStatus: '',
            traLicenceNumber: '',
            companyId: '',
            maritimeVesselStatus: '',
            searchParameter: '',
            destinationPort: '',
            arrivalPort: '',
            dateCategory: '',
            vesselType: '',
            imo: '',
            name: '',
            accountNo: '',
            tinNo: '',
            vrn: '',
            isGovernment: '',
            ediCompany: {},
            dcChargeCompany: {},
            cfaApplicationCustomer: {},
            doRequestCfaAgent: {},
            allBillSummaryCustomer: {},
            showingPageRanger: '',
            applicationStatus: '',
            payerName: '',
            paymentReference: '',
            multipleParams: [],
            myTariffItem: '',
            TANSADNumber4: '',
            TANSADNumber2: '',
            TANSADNumber7: '',
            BTTasacTin: TASAC_TIN,
            BTNumber4: '',
            BTNumber7: '',
            tansadOrBtNo: '',
            carrierCode: null,
            newApplicationStatus: 1

        }

    },

    components: {

        SvgIcon,
        PulseLoader,
        ModelListSelect,
        ListSelect,
        Multiselect

    },

    computed: {

        allowPerPageSelection() {

            return this.totalElements / 15;

        },

        checkPageSize: {

            get: function () {

                return this.pageSize

            },

            set: function (newValue) {

                this.pageSize = newValue

            }

        },

        ...mapGetters('global' , [

            'getAllCustomers'

        ]),

        ...mapGetters('deliveryorderrequest' , [

            'getHasSearchedVariable'

        ]),
    },

    created() {

        if(this.pageSizeProp !== 0) { this.pageSize = this.pageSizeProp }

        this.showingPageRanger = this.showingPageRange();

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
        this.finalUserPermissions.includes('CFA_CUSTOMER') ? this.newApplicationStatus = 1 : this.newApplicationStatus = 0

        if(localStorage.getItem("search-params") !== null) {

            if (this.whichModuleAndType !== 'cfaApplication' && this.whichModuleAndType !== 'cfaCustomerApplication') {

                this.puplateFIlterFilild();
                if(this.myTariffItem !== '') {

                    for(let w = 0 ; w < this.activeTariffItems.length ; w++) {

                        if(this.activeTariffItems[w].tariffItemId == this.myTariffItem) {

                            this.tariffItem = this.activeTariffItems[w]
                            break;

                        }

                    }

                }

            } else {

                this.populateFieldWithMulitipleParameters()

            }

        } else {

            localStorage.removeItem("search-params")
            localStorage.removeItem("cfa-customer")
            this.selectedCustomerName = ''

        }

        if(localStorage.getItem("edi-company") !== null) {

            this.ediCompany = JSON.parse(localStorage.getItem("edi-company"))
            this.companyId = this.ediCompany.companyCode

        }

        if(localStorage.getItem("all-bill-summary-customer") !== null) {

            this.allBillSummaryCustomer = JSON.parse(localStorage.getItem("all-bill-summary-customer"))
            this.customerId = this.allBillSummaryCustomer.customerId

        }

        if(localStorage.getItem("cfa-application-customer") !== null) {

            this.cfaApplicationCustomer = JSON.parse(localStorage.getItem("cfa-application-customer"))
            this.customerId = this.cfaApplicationCustomer.customerId

        }

        if(localStorage.getItem("dc-charge-company") !== null) {

            this.dcChargeCompany = JSON.parse(localStorage.getItem("dc-charge-company"))
            this.customerId = this.dcChargeCompany.customerId

        }

        if(localStorage.getItem("do-request-fca-agent") !== null) {

            this.doRequestCfaAgent = JSON.parse(localStorage.getItem("do-request-fca-agent"))
            this.cfAgentId = this.doRequestCfaAgent.cfAgentId

        }

    },

    props: {

        ediCompaniesLoader: {

            type: Boolean,
            default: false

        },

        coloredStatus: {

            type: Boolean,
            default: false

        },

        hasDeleteIconButton: {

            type: Boolean,
            default: false

        },
        hasAddIconButton: {

            type: Boolean,
            default: false

        },
        applicationCurrentStatus: {

            type: String,
            default: ''

        },

        hasEditIconButton: {

            type: Boolean,
            default: false

        },

        hasDownloadIconButton: {

            type: Boolean,
            default: false

        },

        hasSendPaymentRequestIconButton: {

            type: Boolean,
            default: false

        },

        categoryItemIds: {

            type: Array,
            default: () => []

        },

        allCustomers: {

            type: Array,
            default: () => []

        },

        stations: {

            type: Array,
            default: () => []

        },

        activeTariffItems: {

            type: Array,
            default: () => []

        },

        allCfaAgents: {

            type: Array,
            default: () => []

        },

        getRegimes: {

            type: Array,
            default: () => []

        },

        applicationStatusesProp: {

            type: Array,
            default: () => []

        },

        getStations: {

            type: Array,
            default: () => []

        },
        allCompanies: {

            type: Array,
            default: () => []

        },
        buttonList: {

            type: Array,
            default: () => []

        },
        tableHeaders: {

            type: Array,
            default: []

        },
        tableData: {

            type: Array,
            default: () => []

        },
        tableDataKeys: {

            type: Array,
            default: []

        },
        noDataFound: {

            type: Boolean,
            default: false

        },
        hasPlainFilter: {

            type: Boolean,
            default: false

        },
        whichModuleAndType: '',
        pageNo: '',
        pageSizeProp: {

            type: Number,
            default: () => 0

        },
        searchPlaceholder: '',

        isFirst: {

            type: Boolean,
            default: false

        },
        hasDropDownButton: {

            type: Boolean,
            default: false

        },
        hasEditButton: {

            type: Boolean,
            default: false

        },
        hasDeleteButton: {

            type: Boolean,
            default: false

        },
        hasViewMoreAssessment: {

            type: Boolean,
            default: false

        },
        hasDuplicateButton: {

            type: Boolean,
            default: false

        },
        hasNoAction: {

            type: Boolean,
            default: true

        },
        hasDateQuery: {

            type: Boolean,
            default: false

        },
        hasAdditionalDetails: {

            type: Boolean,
            default: false

        },
        hasFilter: {

            type: Boolean,
            default: true

        },
        hasPagination: {

            type: Boolean,
            default: true

        },
        popupKeys: {

            type: Array,
            default: () => []

        },

        isHovered: {

            type: Array,
            default: () => []

        },

        coloredRow: {

            type: Array,
            default: () => []

        },

        myLoader: {

            type: Boolean,
            default: false

        },
        disabled: {

            type: Boolean,
            default: false

        },
        searchParameterProp: String,
        reloadAllRequest: '',
        myFirstLoader: {

            type: Boolean,
            default: false

        },

        loadingTitle: '',

        isLast: {

            type: Boolean,
            default: false

        },
        isPageable: {
            type: Boolean,
            default: true
        },
        totalElements: '',
        totalPages: '',
        pageNumber: '',
        buttonName: '',
        customButtonName: '',
        permissions: {

            type: Array,
            default: () => []

        },
        hasSearchForm: {

            type: Boolean,
            default: true

        },
        hasManageButton: true,
        hasCustomButton: false,
        hasViewButton: {

            type: Boolean,
            default: false

        }

    },

    updated () {

        this.showingPageRanger = this.showingPageRange()

        if(localStorage.getItem("remove-customer-name") !== null) {
            this.selectedCustomerName = ''
            localStorage.removeItem("remove-customer-name")
            this.$mount();
        }

    },

    methods: {

        calculateExpectedTimeLeft (expectedDate) {

            // expectedDate = "02-12-2021"
            let dayMessage = ''
            let expectedDiff = ''

            if(expectedDate !== null && expectedDate !== '') {

                let expectedDate1 = expectedDate.split('-')
                let todayDate = new Date()

                // console.log('todayDate', todayDate.toISOString().split('T')[0])

                // console.log('today', this.$moment().format('DD-MM-YYYY'))
                // console.log('expectedDate', expectedDate)

                // console.log('this.$moment(expectedDate).format("YYYY, MM, DD")', this.$moment(expectedDate1[2] + '-' + expectedDate1[1] + '-' + expectedDate1[0]).format('YYYY, MM, DD'))
                // console.log('this.$moment().format("YYYY, MM, DD")', this.$moment(new Date()).format('YYYY, MM, DD'))

                // console.log('new Date', new Date(this.$moment(expectedDate1[2] + '-' + expectedDate1[1] + '-' + expectedDate1[0]).format('YYYY, MM, DD')))

                let expectedDateFormatted = this.$moment(new Date(this.$moment(expectedDate1[2] + '-' + expectedDate1[1] + '-' + expectedDate1[0]).format('YYYY, MM, DD')))
                let todayDateFormatted = this.$moment(new Date(this.$moment(todayDate.toISOString().split('T')[0]).format('YYYY, MM, DD')))
                // let expectedDateFormatted = this.$moment(this.$moment(new Date()).format('YYYY, MM, DD'));
                // console.log('a.diff 1', expectedDateFormatted.diff(todayDateFormatted, 'days'))

                expectedDiff = expectedDateFormatted.diff(todayDateFormatted, 'days')

                //{{ expectedDiff  <= 1 ? (-1*expectedDiff) === 1 ? 'Has passed by ' + (-1*expectedDiff) + ' day' : 'Has passed by ' + (-1*expectedDiff) + ' days'  :
                //expectedDiff === 1 ? expectedDiff + ' day remaining' : expectedDiff + ' days remaining' }}

                // console.log('this.expectedDiff', this.expectedDiff)

                if(expectedDiff < 0) {

                    if((expectedDiff * -1) === 1) {

                        dayMessage = expectedDate + '( Passed by 1 day )'

                    } else {

                        dayMessage = expectedDate + '( Passed by '+ (expectedDiff * -1) + ' days )'

                    }

                } else if (expectedDiff === 0) {

                        dayMessage = expectedDate + '( Today )'

                } else {

                    if(expectedDiff === 1) {

                        dayMessage = expectedDate + '( 1 day remaining )'

                    } else {

                        dayMessage = expectedDate + '( ' + expectedDiff + ' days remaining )'

                    }

                }


            }


            return dayMessage + '****' + expectedDiff;

        },

        shiftCursor(currentId, nextid) {

            let currentIdDom = document.getElementById(currentId);

            if(currentId === 'fourDigit') {

                if(currentIdDom.value.length === 4) {

                document.getElementById(currentId).blur();
                document.getElementById(nextid).focus();

                }

            } else if (currentId === 'twoDigit') {

                if(currentIdDom.value.length === 2) {

                document.getElementById(currentId).blur();
                document.getElementById(nextid).focus();

                }


            } else if (currentId === 'fourDigitEdit') {

                if(currentIdDom.value.length === 4) {

                document.getElementById(currentId).blur();
                document.getElementById(nextid).focus();

                }


            } else if (currentId === 'twoDigitEdit') {

                if(currentIdDom.value.length === 2) {

                document.getElementById(currentId).blur();
                document.getElementById(nextid).focus();

                }


            } else if (currentId === 'sevenDigit') {

                if(currentIdDom.value.length === 7) {

                document.getElementById(currentId).blur();
                // document.getElementById(nextid).focus();

                }

            } else if (currentId === 'fourBTDigit') {

                if(currentIdDom.value.length === 4) {

                document.getElementById(currentId).blur();
                document.getElementById(nextid).focus();

                }

            } else if (currentId === 'sevenBTDigit') {

                if(currentIdDom.value.length === 7) {

                document.getElementById(currentId).blur();
                // document.getElementById(nextid).focus();

                }

            } else {

                if(currentIdDom.value.length === 7) {

                document.getElementById(currentId).blur();
                // document.getElementById(nextid).focus();

                }

            }

        },

        sortTable(p) {

            return this.tableData.sort(function(a,b) {
            return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
        });

        },

        showingPageRange () {

            if(this.pageNo + 1 === 1) {

                if(this.tableData.length < this.pageSize) {

                    return 'Showing ' + ((this.pageSize * this.pageNo) + 1) + ' to ' + (this.totalElements) + ' of ' + this.totalElements + ' entries'

                } else {

                    return 'Showing ' + ((this.pageSize * this.pageNo) + 1) + ' to ' + (this.pageSize * (this.pageNo + 1)) + ' of ' + this.totalElements + ' entries'

                }

            } else {

                if(this.totalPages === (this.pageNo + 1) && this.tableData.length < this.pageSize) {

                    return 'Showing ' + ((this.pageSize * this.pageNo) + 1) + ' to ' + (this.totalElements) + ' of ' + this.totalElements + ' entries'

                } else {

                    return 'Showing ' + ((this.pageSize * this.pageNo) + 1) + ' to ' + (this.pageSize * (this.pageNo + 1)) + ' of ' + this.totalElements + ' entries'

                }

            }

        },

        populateDcCharge (object) {

            this.dcChargeCompany = object
            this.customerId = this.dcChargeCompany.companyId
            localStorage.setItem("dc-charge-company", JSON.stringify(this.dcChargeCompany))

        },

        populateCfaApplicationCustomer (object) {

            this.cfaApplicationCustomer = object
            this.customerId = this.cfaApplicationCustomer.customerId
            localStorage.setItem("cfa-application-customer", JSON.stringify(this.cfaApplicationCustomer))

        },

        populateAllBillSummaryCustomer (object) {

            this.allBillSummaryCustomer = object
            this.customerId = this.allBillSummaryCustomer.customerId
            localStorage.setItem("all-bill-summary-customer", JSON.stringify(this.allBillSummaryCustomer))

        },

        populateEdiCompany (object) {

            this.ediCompany = object
            this.companyId = this.ediCompany.companyCode
            localStorage.setItem("edi-company", JSON.stringify(this.ediCompany))

        },

        populateDoRequestCfaAgent (object) {

            this.doRequestCfaAgent = object
            this.cfAgentId = this.doRequestCfaAgent.cfAgentId
            localStorage.setItem("do-request-fca-agent", JSON.stringify(this.doRequestCfaAgent))

        },

        checkIfUserIsPermitted (finalUserPermissions , permissions) {

            for(let x = 0 ; x < finalUserPermissions.length ; x++) {

                if (finalUserPermissions.includes(permissions[x].value)) {

                    return true

                } else {

                    return false

                }

            }

        },

        populateFieldWithMulitipleParameters () {

            let params = JSON.parse(localStorage.getItem("search-params"))
            let allCustomers = localStorage.getItem('search-params-clist') !== null ? JSON.parse(localStorage.getItem('search-params-clist')) : ''
            let regimes = localStorage.getItem('search-params-rlist') !== null ? JSON.parse(localStorage.getItem('search-params-rlist')) : ''
            let stations = localStorage.getItem('search-params-slist') !== null ? JSON.parse(localStorage.getItem('search-params-slist')) : ''
            let applicationStatuses = localStorage.getItem('search-params-alist') !== null ? JSON.parse(localStorage.getItem('search-params-alist')) : ''
            let tariffItems = localStorage.getItem('search-params-tlist') !== null ? JSON.parse(localStorage.getItem('search-params-tlist')) : ''
            let tansadNumber = ''

                if(params.applicationStatuses !== null) {

                    for(let d = 0 ; d < applicationStatuses.length ; d++) {

                        if(params.applicationStatuses.includes(applicationStatuses[d].id)) {

                            this.applicationStatuses.push(applicationStatuses[d])

                        }

                    }

                } else {

                    this.applicationStatuses = null

                }

                if(params.customerIds !== null) {

                    for(let a = 0 ; a < allCustomers.length ; a++) {

                        if(params.customerIds.includes(allCustomers[a].customerId)) {

                            this.customerIds.push(allCustomers[a])

                        }

                    }

                } else {

                    this.customerIds = null

                }

                if(params.regimeIds !== null) {

                    for(let b = 0 ; b < regimes.length ; b++) {

                        if(params.regimeIds.includes(regimes[b].regimeId)) {

                            this.regimeIds.push(regimes[b])

                        }

                    }

                } else {

                    this.regimeIds = null

                }

                if(params.stationIds !== null) {

                    for(let c = 0 ; c < stations.length ; c++) {

                        if(params.stationIds.includes(stations[c].stationId)) {

                            this.stationIds.push(stations[c])

                        }

                    }

                } else {

                    this.stationIds = null

                }

                if(params.tariffItem !== null) {

                    for(let c = 0 ; c < tariffItems.length ; c++) {

                        if(params.tariffItem === tariffItems[c].tariffItemId) {

                            this.tariffItem = tariffItems[c]
                            break;

                        }

                    }

                } else {

                    this.tariffItem = null

                }

                if(params.tansadNo !== null && params.tansadNo !== undefined) {

                    this.TANSADNumber4 = params.tansadNo.split('-')[0]
                    this.TANSADNumber2 = params.tansadNo.split('-')[1]
                    this.TANSADNumber7 = params.tansadNo.split('-')[2]

                }

                params.assigmentUserId === null ? this.assigmentUserId = null : this.assigmentUserId = params.assigmentUserId
                params.blNumber === null ? this.blNumber = null : this.blNumber = params.blNumber
                params.dismissCase === null ? this.dismissCase = null : this.dismissCase = params.dismissCase.id
                params.endDate === null ? this.endDate = null : this.endDate = params.endDate
                params.fileNo === null ? this.fileNo = null : this.fileNo = params.fileNo
                params.requestType === null ? this.requestType = null : this.requestType = params.requestType
                params.startDate === null ? this.startDate = null : this.startDate = params.startDate
                params.tansadNo === null ? this.tansadNo = null : this.tansadNo = params.tansadNo

                params.accountNo === null ? this.accountNo = null : this.accountNo = params.accountNo
                params.carrierCode === null ? this.carrierCode = null : this.carrierCode = params.carrierCode
                params.name === null ? this.name = null : this.name = params.name

        },

        puplateFIlterFilild() {

            let cachedINputArray = []
            const urlParams = new URLSearchParams(localStorage.getItem("search-params"));
            const entries = urlParams.entries();
            for(const entry of entries) {

                cachedINputArray.push({ 'key': entry[0] , "value": entry[1] })

            }

            for(let x = 0 ; x < cachedINputArray.length ; x++) {

                switch(cachedINputArray[x].key) {

                    case 'startDate':
                        this.startDate = cachedINputArray[x].value
                        break;
                    case 'status':
                        this.billStatus = cachedINputArray[x].value
                        break;
                    case 'endDate':
                        this.endDate = cachedINputArray[x].value
                        break;
                    case 'controlNumber':
                        this.controlNumber = cachedINputArray[x].value
                        break;
                    case 'invoiceNo':
                        this.invoiceNo = cachedINputArray[x].value
                        break;
                    case 'payerName':
                        this.payerName = cachedINputArray[x].value
                        break;
                    case 'paymentReference':
                        this.paymentReference = cachedINputArray[x].value
                        break;
                    case 'tansadNo':
                        this.tansadNo = cachedINputArray[x].value
                        break;
                    case 'requestType':
                        this.requestType = cachedINputArray[x].value
                        break;
                    case 'fileNo':
                        this.fileNo = cachedINputArray[x].value
                        break;
                    case 'stationId':
                        this.stationId = cachedINputArray[x].value
                        break;
                    case 'blNumber':
                        this.blNumber = cachedINputArray[x].value
                        break;
                    case 'tariffItemId':
                        this.myTariffItem = cachedINputArray[x].value
                        break;
                    case 'regimeId':
                        this.regimeId = cachedINputArray[x].value
                        break;
                    case 'applicationStatus':
                        this.applicationStatus = cachedINputArray[x].value
                        break;
                    case 'voyageNumber':
                        this.voyageNumber = cachedINputArray[x].value
                        break;
                    case 'destinationPort':
                        this.destinationPort = cachedINputArray[x].value
                        break;
                    case 'arrivalPort':
                        this.arrivalPort = cachedINputArray[x].value
                        break;
                    case 'dateCategory':
                        this.dateCategory = cachedINputArray[x].value
                        break;
                    case 'vesselType':
                        this.vesselType = cachedINputArray[x].value
                        break;
                    case 'imo':
                        this.imo = cachedINputArray[x].value
                        break;
                    case 'vesselName':
                        this.vesselName = cachedINputArray[x].value
                        break;
                    case 'tasacControlNumber':
                        this.tasacControlNumber = cachedINputArray[x].value
                        break;
                    case 'operationType':
                        this.operationType = cachedINputArray[x].value
                        break;
                    case 'cargoDeliveryType':
                        this.cargoDeliveryType = cachedINputArray[x].value
                        break;
                    case 'destination':
                        this.destination = cachedINputArray[x].value
                        break;
                    case 'consigneeName':
                        this.consigneeName = cachedINputArray[x].value
                        break;
                    case 'doRequestStatus':
                        this.doRequestStatus = cachedINputArray[x].value
                        break;
                    case 'traLicenceNumber':
                        this.traLicenceNumber = cachedINputArray[x].value
                        break;
                    case 'name':
                        this.name = cachedINputArray[x].value
                        break;
                    case 'accountNo':
                        this.accountNo = cachedINputArray[x].value
                        break;
                    case 'tinNo':
                        this.tinNo = cachedINputArray[x].value
                        break;
                    case 'vrn':
                        this.vrn = cachedINputArray[x].value
                        break;
                    case 'isGovernment':
                        this.isGovernment = cachedINputArray[x].value
                        break;
                    case 'cancelled':
                        if(cachedINputArray[x].value) {
                            this.documentStatus = 'cancelled'
                        }
                        break;
                    case 'billed':
                        if(cachedINputArray[x].value) {
                            this.documentStatus = 'billed'
                        } else {
                            this.documentStatus = 'unBilled'
                        }
                        break;
                    case 'charged':
                        this.charged = cachedINputArray[x].value
                        break;
                    default: //pageNo
                        // this.pageNo = cachedINputArray[x].value
                        break;

                }

            }

        },

        customCompanyCodeAndName (item) {

            return `${item.companyName} [ ${item.companyCode} ]`

        },

        getMouseLocation(event){

            let ml = event.clientX
            let mt  =event.pageY
            this.styleObject =  {

                marginBottom: String(mt  - 500 ) + 'px'

            }

        },

        searchPlain (params) {
            this.$emit('searchPlain', params);
        },
        goToPage(page) {
            this.$emit('goToPage', page);
        },

        removeItemFromList (object) {
            this.$emit('removeItemFromList', object);
        },
        addItemFromList (object) {
            this.$emit('addItemFromList', object);
        },
        editItemFromList (object) {
            this.$emit('editItemFromList', object);
        },

        downloadItem (object) {
            this.$emit('downloadItem', object);
        },

        addItemToPaymentRequest (object) {
            this.$emit('addItemToPaymentRequest', object);
        },

        removeItemFromPaymentRequest (object) {
            this.$emit('removeItemFromPaymentRequest', object);
        },

        viewDocument (url, name, view, x1, x2) {
            this.$emit('viewDocument', url, name, view, x1, x2);
        },

        onChange() {
            this.$emit('onChange', this.pageSize);
        },

        showToolTip(requestId) {
            this.$emit('showToolTip', requestId);
        },

        hideToolTip(requestId) {
            this.$emit('hideToolTip', requestId);
        },

        markAsDuplicate(requestId, blNumber, customerName) {
            this.$emit('markAsDuplicate', requestId, blNumber, customerName);
        },

        goToApplication(objectId) {
            this.$emit('goToApplication', objectId);
        },

        goToView(objectId) {
            this.$emit('goToView', objectId);
        },

        goToEdit(objectId) {
            this.$emit('goToEdit', objectId);
        },

        goToDelete(objectId) {
            this.$emit('goToDelete', objectId);
        },

        goToViewMoreAssessment(object) {
            this.$emit('goToViewMoreAssessment', object);
        },

        fireCustomButton(object, code) {
            this.$emit(this.baseButtonName + code, object);
        },

        fireCustomMethod(object) {
            this.$emit('fireCustomMethod', object);
        },

        searchModifier () {

            if(this.whichModuleAndType === 'cfaApplication' || this.whichModuleAndType === 'cfaCustomerApplication' || this.whichModuleAndType === 'vesselPrincipal') {

                this.filterWithMultipleParameters()

            } else {

                this.search()

            }

        },

        filterWithMultipleParameters() {

            this.hasSearchRequest = true;
            let regimeIds = []
            let applicationStatuses = []
            let customerIds = []
            let stationIds = []

            if(this.regimeIds !== null && this.regimeIds.length >  0) {

                for(let x = 0 ; x < this.regimeIds.length ; x++) {

                    regimeIds.push(this.regimeIds[x].regimeId)

                }

            }

            if(this.applicationStatuses !== null && this.applicationStatuses.length >  0) {

                for(let y = 0 ; y < this.applicationStatuses.length ; y++) {

                    applicationStatuses.push(this.applicationStatuses[y].id)

                }

            }

            if(this.customerIds !== null && this.customerIds.length >  0) {

                for(let w = 0 ; w < this.customerIds.length ; w++) {

                    customerIds.push(this.customerIds[w].customerId)

                }

            }

            if(this.stationIds !== null && this.stationIds.length >  0) {

                for(let w = 0 ; w < this.stationIds.length ; w++) {

                    stationIds.push(this.stationIds[w].stationId)

                }

            }

            let tansadNumber = ''
            let flag = 0

            if(this.TANSADNumber7 !== '' ) {

                tansadNumber = this.trimString(this.TANSADNumber7)

            } else {
                tansadNumber = this.trimString(this.TANSADNumber7)
                let tansadNumberArray = tansadNumber.split('-')
                for (let i = 0; i < tansadNumberArray.length; i++) {

                    if(tansadNumberArray[i] !== '') {

                        flag = 1

                    }


                }

                tansadNumber = null
            }

            if(tansadNumber === null) {

                if( this.BTNumber7 !== '') {

                    tansadNumber = this.trimString(this.BTNumber7)
                    flag = 0

                } else {

                    tansadNumber = this.trimString(this.BTNumber7)
                    let bTNumberArray = tansadNumber.split('-')
                    for (let i = 0; i < bTNumberArray.length; i++) {

                        if(bTNumberArray[i] !== '') {

                            flag = 1

                        }


                    }

                    tansadNumber = null
                }

            }

            if(flag === 0) {

                this.multipleParams = {

                    applicationStatuses: applicationStatuses.length <= 0 ? null : applicationStatuses,
                    assigmentUserId: this.assigmentUserId === '' || this.assigmentUserId === null ? null : this.assigmentUserId,
                    blNumber: this.blNumber === '' || this.blNumber === null ? null : this.trimString(this.blNumber),
                    customerIds: customerIds.length <= 0 ? null : customerIds,
                    dismissCase: this.dismissCase === null ? null : this.dismissCase.id,
                    endDate: this.endDate === '' || this.endDate === null ? null : this.endDate,
                    fileNo: this.fileNo === '' || this.fileNo === null ? null : this.trimString(this.fileNo),
                    regimeIds: regimeIds.length <= 0 ? null : regimeIds,
                    requestType: this.requestType === '' || this.requestType === null ? null : this.requestType,
                    startDate: this.startDate === '' || this.startDate === null ? null : this.startDate,
                    stationIds: stationIds.length <= 0 ? null : stationIds,
                    tansadNo: tansadNumber,
                    tariffItem: this.tariffItem === null ? null : this.tariffItem.tariffItemId,

                    accountNo: this.accountNo === null ? null : this.accountNo,
                    carrierCode: this.carrierCode === null ? null : this.carrierCode,
                    name: this.name === null ? null : this.name,

                }

                this.$emit('filterWithMultipleParameters', this.multipleParams);

            } else {

                this.loadNotification('error', 6000, 'Application Filter','Invalid TANSAD Number','response', 1000 , true  , true)

            }

        },

        search() {

            this.hasSearchRequest = true;
            let inputArray = []
            let params = ''
            this.selectedCustomerName = ''
            let documentStatus = {}
            let dcEdiNewOrOld = ''
            let operationType = ''
            let operationTypeValue = ''

            if(localStorage.getItem("operation-type") !== null && localStorage.getItem("tab-status") !== null) {

                dcEdiNewOrOld = {name: 'searchCategory=' , value: localStorage.getItem("tab-status").toUpperCase()}

                if(localStorage.getItem("operation-type") == 0) {

                    operationTypeValue = 'import'

                } else if(localStorage.getItem("operation-type") == 1) {

                    operationTypeValue = 'export'

                } else {

                    operationTypeValue = localStorage.getItem("operation-type").toLowerCase()

                }

                operationType = {name: 'operationType=' , value: operationTypeValue}

            } else {


                operationType = {name: 'operationType=' , value: this.trimString(this.operationType)}

            }

            let tansadNumber = ''
            let flag = 0

            if(this.TANSADNumber7 !== '' ) {

                tansadNumber = this.trimString(this.TANSADNumber7)

            } else {

                tansadNumber = this.trimString(this.TANSADNumber7)
                let tansadNumberArray = tansadNumber.split('-')
                for (let i = 0; i < tansadNumberArray.length; i++) {

                    if(tansadNumberArray[i] !== '') {

                        flag = 1

                    }


                }

                tansadNumber = null
            }

            if(tansadNumber === null) {

                if(this.BTNumber7 !== '') {

                    tansadNumber = this.trimString(this.BTNumber7)
                    flag = 0

                } else {

                    tansadNumber = this.trimString(this.BTNumber7)
                    let bTNumberArray = tansadNumber.split('-')
                    for (let i = 0; i < bTNumberArray.length; i++) {

                        if(bTNumberArray[i] !== '') {

                            flag = 1

                        }


                    }

                    tansadNumber = null
                }

            }

            if(tansadNumber === null) {

                if(this.tansadOrBtNo !== '') {

                    tansadNumber = this.tansadOrBtNo

                } else {

                    tansadNumber = null

                }

            }

            switch (this.documentStatus) {

                case 'billed':
                    documentStatus = {name: 'billed=' , value: true}
                    break;
                case 'unBilled':
                    documentStatus = {name: 'billed=' , value: false}
                    break;
                case 'cancelled':
                    documentStatus = {name: 'cancelled=' , value: true}
                    break;
                default:
                    documentStatus
                    break;

            }

            inputArray.push(
                                /* COMMON SEARCH PARAMS */
                            {name: 'startDate=' , value: this.startDate !== undefined && this.startDate !== null ? this.trimString(this.startDate) : ''},
                            {name: 'endDate=' , value: this.endDate !== undefined && this.endDate !== null ? this.trimString(this.endDate) : ''},
                            {name: 'customerId=' , value: this.customerId !== undefined && this.customerId !== null ? this.trimString(this.customerId) : ''},
                            {name: 'companyId=' , value: this.companyId !== undefined && this.companyId !== null ? this.trimString(this.companyId) : ''},

                                /* MANIFEST AND CFA BILLS SEARCH PARAMS */
                            {name: 'status=' , value: localStorage.getItem("bill-status") !== null ? localStorage.getItem("bill-status") : this.billStatus !== undefined ? this.trimString(this.billStatus) : ''},
                            {name: 'billType=' , value: localStorage.getItem("bill-type") !== null ? localStorage.getItem("bill-type") : this.billType !== undefined ? this.trimString(this.billType) : ''},
                            {name: 'controlNumber=' , value: this.controlNumber !== undefined && this.controlNumber !== null ? this.trimString(this.controlNumber) : ''},
                            {name: 'invoiceNo=' , value: this.invoiceNo !== undefined && this.invoiceNo !== null ? this.trimString(this.invoiceNo) : ''},
                            {name: 'tansadNo=' , value: tansadNumber !== undefined && tansadNumber !== null ? this.trimString(tansadNumber) : ''},
                            // {name: 'dismissCase=' , value: this.dismissCase !== undefined ? this.trimString(this.dismissCase) : ''},


                                /* CFA CHARGES SEARCH PARAMS */
                            {name: 'requestType=', value: this.requestType !== undefined && this.requestType !== null ? this.trimString(this.requestType) : ''},
                            {name: 'vessel=', value: this.vessel !== undefined && this.vessel !== null ? this.trimString(this.vessel) : ''},
                            {name: 'fileNo=', value: this.fileNo !== undefined && this.fileNo !== null ? this.trimString(this.fileNo) : ''},
                            {name: 'stationId=' , value: this.stationId !== undefined && this.stationId !== null ? this.trimString(this.stationId) : ''},
                            {name: 'blNumber=' , value: this.blNumber !== undefined && this.blNumber !== null ? this.trimString(this.blNumber) : ''},
                            {name: 'regimeId=' , value: this.regimeId !== undefined && this.regimeId !== null ? this.trimString(this.regimeId) : ''},
                            {name: 'applicationStatus=' , value: this.applicationStatus !== undefined && this.applicationStatus !== null ? this.trimString(this.applicationStatus) : ''},

                                /* DELIVERY ORDER REQUEST */
                            {name: 'cargoDeliveryType=', value: this.cargoDeliveryType !== undefined && this.cargoDeliveryType !== null ? this.trimString(this.cargoDeliveryType) : ''},
                            {name: 'destination=', value: this.destination !== undefined && this.destination !== null ? this.trimString(this.destination) : ''},
                            {name: 'consigneeName=', value: this.consigneeName !== undefined && this.consigneeName !== null ? this.trimString(this.consigneeName) : ''},
                            {name: 'cfAgentId=' , value: this.cfAgentId !== undefined && this.cfAgentId !== null ? this.trimString(this.cfAgentId) : ''},
                            {name: 'doRequestStatus=' , value: this.doRequestStatus !== undefined && this.doRequestStatus !== null ? this.trimString(this.doRequestStatus) : ''},
                            {name: 'traLicenceNumber=' , value: this.traLicenceNumber !== undefined && this.traLicenceNumber !== null ? this.trimString(this.traLicenceNumber) : ''},

                                /* MARINETIME VESSEL */
                            {name: 'maritimeVesselStatus=' , value: this.maritimeVesselStatus !== undefined && this.maritimeVesselStatus !== null ? this.trimString(this.maritimeVesselStatus) : ''},
                            {name: 'destinationPort=' , value: this.destinationPort !== undefined && this.destinationPort !== null ? this.trimString(this.destinationPort) : ''},
                            {name: 'arrivalPort=' , value: this.arrivalPort !== undefined && this.arrivalPort !== null ? this.trimString(this.arrivalPort) : ''},
                            {name: 'dateCategory=' , value: this.dateCategory !== undefined && this.dateCategory !== null ? this.trimString(this.dateCategory) : ''},
                            {name: 'vesselType=' , value: this.vesselType !== undefined && this.vesselType !== null ? this.trimString(this.vesselType) : ''},
                            {name: 'imo=' , value: this.imo !== undefined && this.imo !== null ? this.trimString(this.imo) : ''},

                                /* DISBURSEMENT DEPOSIT */
                            {name: 'payerName=' , value: this.payerName !== undefined && this.payerName !== null ? this.trimString(this.payerName) : ''},
                            {name: 'paymentReference=' , value: this.paymentReference !== undefined && this.paymentReference !== null ? this.trimString(this.paymentReference) : ''},

                                /* SHIPPING AGENCY SERVICE PROVIDER */
                            {name: 'name=' , value: this.name !== undefined && this.name !== null ? this.trimString(this.name) : ''},
                            {name: 'accountNo=' , value: this.accountNo !== undefined && this.accountNo !== null ? this.trimString(this.accountNo) : ''},
                            {name: 'tinNo=' , value: this.tinNo !== undefined && this.tinNo !== null ? this.trimString(this.tinNo) : ''},
                            {name: 'vrn=' , value: this.vrn !== undefined && this.vrn !== null ? this.trimString(this.vrn) : ''},
                            {name: 'isGovernment=' , value: this.isGovernment !== undefined ? this.isGovernment : ''},

                                /* REVENUE */
                            {name: 'tariffItemId=' , value: this.tariffItem !== null ? this.tariffItem.tariffItemId : ''},

                                /* DC CHARGES SEARCH PARAMS */
                            {name: 'voyageNumber=' , value: this.voyageNumber !== undefined && this.voyageNumber !== null ? this.trimString(this.voyageNumber) : ''},
                            {name: 'vesselName=' , value: this.vesselName !== undefined && this.vesselName !== null ? this.trimString(this.vesselName) : ''},
                            {name: 'tasacControlNumber=' , value: this.tasacControlNumber !== undefined && this.tasacControlNumber !== null ? this.trimString(this.tasacControlNumber) : ''},
                            {name: 'charged=' , value: this.charged !== undefined ? this.charged : ''},
                            documentStatus,
                            dcEdiNewOrOld,
                            operationType,

                                /* COMMON SEARCH PARAMS (SHOULD ALWAYS STAY HERE AT THE END OF THIS ARRAY AND IN ORDER ) */
                            {name: 'size=', value: this.pageSize},
                            {name: 'page=', value: this.pageNo}

                            );

            for(let x = 0 ; x < inputArray.length ; x++) {

                if(typeof inputArray[x].value !== 'undefined' && inputArray[x].value !== '' && params === '') {

                    params += '?' + inputArray[x].name + inputArray[x].value

                } else if(typeof inputArray[x].value !== 'undefined' && inputArray[x].value !== '' && params !== '') {

                    params += '&' + inputArray[x].name + inputArray[x].value

                } else {

                    params += ''

                }

            }

            this.$emit('search', params);

        },

        resetParamsInInputs () {

            this.startDate = ''
            this.endDate = ''
            this.billStatus = ''
            this.startDate = ''
            this.endDate = ''
            this.billType = ''
            this.dismissCase = null
            this.tansadNumber =  null
            this.tariffItem =  null
            this.assigmentUserId =  ''
            this.customerIds =  []
            this.regimeIds =  []
            this.stationIds =  []
            this.applicationStatuses =  []
            this.customerId = ''
            this.controlNumber = ''
            this.invoiceNo = ''
            this.tansadNo = ''
            this.requestType = ''
            this.fileNo = ''
            this.stationId = ''
            this.blNumber = ''
            this.regimeId = ''
            this.selectedCustomerName = ''
            this.voyageNumber = ''
            this.vesselName = ''
            this.tasacControlNumber = ''
            this.operationType = ''
            this.documentStatus = ''
            this.charged = ''
            this.cargoDeliveryType = ''
            this.destination = ''
            this.cfAgentId = ''
            this.consigneeName = ''
            this.doRequestStatus = ''
            this.traLicenceNumber = ''
            this.companyId = ''
            this.destinationPort = ''
            this.arrivalPort = ''
            this.dateCategory = ''
            this.vesselType = ''
            this.imo = ''
            this.maritimeVesselStatus = ''
            this.applicationStatus = ''
            this.name = '',
            this.accountNo = ''
            this.tinNo = '',
            this.TANSADNumber4 = ''
            this.TANSADNumber2 = ''
            this.TANSADNumber7 = ''
            this.BTNumber4 = ''
            this.BTNumber7 = ''
            this.vrn = '',
            this.payerName = ''
            this.tansadOrBtNo = ''
            this.paymentReference = ''
            this.isGovernment = ''
            this.cfaApplicationCustomer = {}
            localStorage.removeItem("cfa-application-customer")
            this.dcChargeCompany = {}
            localStorage.removeItem("dc-charge-company")
            this.allBillSummaryCustomer = {}
            localStorage.removeItem("all-bill-summary-customer");
            this.ediCompany = {}
            localStorage.removeItem("edi-company");
            this.doRequestCfaAgent = {}
            localStorage.removeItem("do-request-fca-agent");

        },

        resetParams() {

            this.startDate = ''
            this.endDate = ''
            this.billStatus = ''
            this.startDate = ''
            this.endDate = ''
            this.billType = ''
            this.dismissCase = null
            this.tansadNumber =  null
            this.tariffItem =  null
            this.assigmentUserId =  ''
            this.customerIds =  []
            this.regimeIds =  []
            this.stationIds =  []
            this.applicationStatuses =  []
            this.customerId = ''
            this.controlNumber = ''
            this.invoiceNo = ''
            this.tansadNo = ''
            this.requestType = ''
            this.fileNo = ''
            this.stationId = ''
            this.blNumber = ''
            this.vessel=''
            this.regimeId = ''
            this.selectedCustomerName = ''
            this.voyageNumber = ''
            this.vesselName = ''
            this.tasacControlNumber = ''
            this.operationType = ''
            this.documentStatus = ''
            this.charged = ''
            this.cargoDeliveryType = ''
            this.destination = ''
            this.cfAgentId = ''
            this.consigneeName = ''
            this.doRequestStatus = ''
            this.traLicenceNumber = ''
            this.companyId = ''
            this.destinationPort = ''
            this.arrivalPort = ''
            this.dateCategory = ''
            this.vesselType = ''
            this.imo = ''
            this.maritimeVesselStatus = ''
            this.applicationStatus = ''
            this.name = '',
            this.accountNo = ''
            this.tinNo = '',
            this.TANSADNumber4 = ''
            this.TANSADNumber2 = ''
            this.TANSADNumber7 = ''
            this.BTNumber4 = ''
            this.BTNumber7 = ''
            this.vrn = '',
            this.payerName = ''
            this.paymentReference = ''
            this.isGovernment = ''
            this.tansadOrBtNo = ''
            this.cfaApplicationCustomer = {}
            localStorage.removeItem("cfa-application-customer")
            this.dcChargeCompany = {}
            localStorage.removeItem("dc-charge-company")
            this.allBillSummaryCustomer = {}
            localStorage.removeItem("all-bill-summary-customer");
            this.ediCompany = {}
            localStorage.removeItem("edi-company");
            this.doRequestCfaAgent = {}
            localStorage.removeItem("do-request-fca-agent");


            if(localStorage.getItem("search-params") !== null || this.hasSearchRequest || this.getHasSearchedVariable) {

                this.hasSearchRequest = false

                localStorage.removeItem("search-params")
                localStorage.removeItem("cfa-customer")
                localStorage.removeItem('search-params-clist')
                localStorage.removeItem('search-params-rlist')
                localStorage.removeItem('search-params-slist')
                localStorage.removeItem('search-params-alist')
                this.$emit('resetParams' , 'resetAll')
                this.$store.dispatch("dcbilling/paramsSearching", '')

            }

        }
    }

}
</script>
<style scoped>

    /* .blink-me {
        animation: blinker .5s step-start infinite;
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    } */

    .blink-me {
        animation: blink 3s infinite;
    }
    @keyframes blink
    {
        0% { opacity: 1.0; }
        50% { opacity: 0.0; }
        100% { opacity: 1.0; }
    }

    .page-number:hover {
        cursor: pointer;
    }

    .pagination {
    display: inline-block;
    }

    .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    }

    .pagination a.active {
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    }

    .pagination a:hover:not(.active) {
    background-color: #ddd;
    border-radius: 5px;
    }

    .custom-table-header {
        background-color:rgba(33, 115, 208 , .7);
        margin-bottom:50px;
    }

    .custom-table-header th {
        color:#ffffff !important;
        font-weight: 900;
    }

    .normal-search {
        min-width: 400px;
    }

    ul.nav-justified li.first::after {
        content: '...'
    }

    ul.nav-justified li.last::before {
        content: '...'
    }

    ul.nav-justified {
        list-style-type: none;
        font-size: 17px;
    }

    ul.nav-justified li {
        display: inline;
        padding: 0 6px 0 6px;
        font-weight: bold;
        border: 1px solid #ddd;
        margin: 0 4px;
        border-radius: 3px;
    }

    ul.nav-justified li.auto-number:hover {
        color: blue;
        fill: blue;
        cursor: pointer;
        font-weight: bold;
        background-color: rgba(153, 186, 221, .5) !important;
        padding: 0 6px 0 6px;
        text-align: center;
        border-radius: 3px;
    }

    ul.nav-justified li:hover {
        cursor: pointer;
        font-weight: bold;
        padding: 0 6px 0 6px;
    }

    ul.nav-justified li.active-page {
        color: white;
        cursor: pointer;
        font-weight: bold;
        background-color: green !important;
        padding: 0 6px 0 6px;
        text-align: center;
        border-radius: 3px;
    }

    .table-hover tbody tr:hover {
        background-color: rgba(153, 186, 221, .5) !important;
        font-weight: normal !important;
    }

    tbody tr {
        color: #212529 !important;
        font-weight: 400 !important;
    }

    .table td, .table th {
        text-align: center;
    }

    .search .form-group .input-group #inputGroup {
        width: 250px;
        padding-top: 3.5px;
    }

    .search .form-group .input-group .input-group-addon:hover {
        cursor: pointer;
        background-color: rgba(220, 220, 220, .5);
    }

    .more-customer-details:hover {
        cursor: pointer;
        color: #d59a18;
    }

    .customer-info {
        position: absolute;
        bottom: 40px;
        background-color: #FFFFFF;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #d59a18;
        text-align: left
    }

    .popup-info {
        position: absolute;
        z-index: 5;
        /*bottom: 40px;*/
        background-color: #FFFFFF;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #d59a18;
        text-align: left
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
        background-color: #FFFFFF !important;
    }

    .custom-btn-name {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
        background-color: #FFFFFF !important;
    }

    .close-info {
        color: blue !important;
    }

    .close-info:hover {
        cursor: pointer;
        color: green !important;
    }

    .custom-btn-custom-name {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: 120px;
        background-color: #FFFFFF !important;
    }

    .btn-success-custom {
        padding: 0 4px 0 4px;
        color: #3cb371;
    }

    .btn-success-custom:hover {
        cursor: pointer;
        color: green;
    }

    .blur-popup {
        margin-top: 25px;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.2);
    }

    .refresh-all {
        margin-top: 6px;
        margin-right: 20px
    }

    .refresh-all:hover {
        cursor: pointer;
    }

    .search .form-group .input-group .input-group-addon {
        width: 40px;
        text-align: center;
        padding-top: 3.5px;
        background-color: rgba(220, 220, 220, .3);
    }

    .search .form-group .input-group .input-group-addon:hover {
        cursor: pointer;
        background-color: rgba(220, 220, 220, .5);
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .btn-space {
        margin-right: 10px !important;
    }

    .disable-highlight ul li {
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }

    .down-pagination {
        line-height: 5px;
        font-size: 20px;
        padding-right: 0 !important;
    }

    .up-pagination {
        font-size: 20px
    }

    .nc-icon-inner {
        font-size: 13px !important;
        fill: #000000;
    }

    .nc-icon-inner-disabled {
        font-size: 13px !important;
        fill: #dcdcdc;
    }

    .disabled-click {
        pointer-events: none !important;
        cursor: not-allowed !important;
        color: #dcdcdc !important;
        border-color: #dcdcdc !important;;
    }

    .enabled-click {
        pointer-events: auto;
    }

    .table-footer {
        background-color:rgba(249, 249, 249 , 1);
        padding-top: 10px;
    }
    .table-header {

        background-color:rgba(249, 249, 249 , 5);
        padding-top: 10px;
        padding-left:40px;
        padding-right:40px;
        margin-bottom: 5px;
        margin-top: 5px;
        /* border: 1px solid #dcdcdc; */
        /* margin-left: 60px; */
        /* border-radius: 3px; */
        font-size: 10px !important;
        line-height: 10px;
    }

    .background-color{
        background-color: rgba(223, 248, 222, .5);
    }

</style>
<style>

    .download-link {
        color: green;
        font-weight: bold;
    }

    .download-link:hover {
        cursor: pointer;
        color: #d59a18 !important;
    }

    .card label {
        /* color: rgba(213,154,24, .9) !important; */
        color: black !important;
    }

    .custom-btn, .custom-btn-all {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .custom-btn:hover, .custom-btn-all:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .pagination {
        float: right;
    }
    .page-item.active {
        border-radius: 0px;
        position: static !important;
        background-color: #3bb9eb !important;
        color: #4A4A4A !important;
    }
    .pagination li {
        float: left;
        padding: 5px 10px;
        text-decoration: none;
        border: 1px solid #524e4e;
        color: #094ba0;
        background-color: #ffffff;
        font-size: 1em;
        border-radius: 3px;
    }
    .pagination li.pagination-active {
        background-color: green;
    }

    .pagination  li:hover:not(.active) {
        background-color: #dcdcdc;
    }

    .custom-btn-all {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }

     /* The container must be positioned relative: */
    .custom-select {
    width:125px;
    border: 1px solid #524e4e;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        padding: 6px 0px;
        border-radius: 15px;
        text-align: center;
        font-size: 12px;
        line-height: 1.2;
    }

    .delete-circle {
        color: #ff0000;
        border: 1px solid #ff0000;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
    }
    .delete-circle:hover {
        color: #990000 !important;
        border: 1px solid #990000 !important;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4) !important;
    }
    .add-circle {
        color: #008000;
        border: 1px solid #008000;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
    }
    .edit-circle{
        color: #008000;
        border: 1px solid #008000;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
    }
    .edit-circle:hover {
        color: #008000 !important;
        border: 1px solid #008000 !important;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4) !important;
    }
    .invoice-circle{
        color: #d59a18;
        border: 1px solid #d59a18;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
    }
    .invoice-circle:hover {
        color: #d59a18 !important;
        border: 1px solid #d59a18 !important;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4) !important;
    }
    .invoice-circle-done{
        color:grey;
        border: 1px solid grey;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
    }
    .invoice-circle-done:hover{
        color: grey !important;
        border: 1px solid grey !important;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4) !important;
    }
    .delete-circle:hover {
        color: #004000 !important;
        border: 1px solid #004000 !important;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4) !important;
    }

    .red{
        background-color: red;
    }

    .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
</style>
