<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>

      <div class="text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Company Type List
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            v-if="finalUserPermissions.includes('COMPANYTYPE_CONFIG_POST')"
            @click.prevent="openModal(null , null , null, null , actionButton = 'AddModal')"
          >
            <font-awesome-icon icon="plus" />
          </span>
          <!--          <div class="fileUpload btn btn-info btn-fill" v-if="finalUserPermissions.includes('COMPANY_TYPE_ADD')">-->
          <!--            <i class="nc-icon nc-cloud-upload-94"></i><span> Upload Excel..</span>-->
          <!--            <form method="post" enctype="multipart/form-data">-->
          <!--              <input type="file" name="attachment" label="Upload File" class="upload" @change.prevent="uploadFile"/>-->
          <!--            </form>-->
          <!--          </div>-->
        </div>
      </div>
      <hr>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Company Type Name
            </th>
            <th scope="col">
              Company type Code
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getCompany"
            :key="list.companyTypeId"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>
              {{ list.companyTypeName }}
            </td><td>
              {{ list.companyTypeCode }}
            </td>

            <td class="special-limit">
              <div
                class="all-fa-icons edit-fa-icon"
                v-if="finalUserPermissions.includes('COMPANYTYPE_CONFIG_PUT')"
                @click.prevent="openModal(list.companyTypeId , list.companyTypeName , list.sector.sectorId ,
                                          list.companyTypeCode , actionButton = 'EditModal')"
                title="edit"
              >
                <font-awesome-icon icon="edit" />
              </div>

              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                v-if="finalUserPermissions.includes('COMPANYTYPE_CONFIG_{COMPANYTYPEID}_GET')"
                @click.prevent="openModal(list.companyTypeId , null , null , null, actionButton = 'ShowModal')"
                title="view"
              >
                <font-awesome-icon icon="eye" />
              </div>

              <div
                class="all-fa-icons fa-icons-space delete-fa-icon"
                v-if="finalUserPermissions.includes('COMPANYTYPE_CONFIG_{COMPANYTYPEID}_DELETE')"
                @click.prevent="deleteCompanyType(list.companyTypeId , list.companyTypeName)"
                title="delete"
              >
                <font-awesome-icon icon="trash-alt" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Company Type <b>{{ companyTypeName }} ({{ companyTypeCode }})</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register Company Type
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Company Type: <b>{{ data.companyTypeName }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="UploadModalDiv"
          >
            Upload Excel for Company Type
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--        EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editCompanyType"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <label for="myselect2">Select Sector</label>
                <select
                  id="myselect2"
                  class="form-control"
                  v-model="sectorId"
                >
                  <option
                    disabled
                    value=""
                  >
                    Select sector
                  </option>
                  <option
                    v-for="(sector, index) in getSector"
                    :value="sector.sectorId"
                    :selected="sectorId === sector.sectorId"
                    :key="index"
                  >
                    {{ sector.sectorName }}
                  </option>
                </select>
                <div v-if="sectorIdError">
                  <div
                    class="error"
                    v-if="!$v.sectorId.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Country Name"
                  placeholder="Country Name"
                  v-model="companyTypeName"
                  value="companyTypeName"
                  class="special-bottom"
                />
                <div v-if="companyTypeNameError">
                  <div
                    class="error"
                    v-if="!$v.companyTypeName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Country Code"
                  placeholder="Country Code"
                  v-model="companyTypeCode"
                  value="companyTypeCode"
                  class="special-bottom"
                />
                <div v-if="companyTypeCodeError">
                  <div
                    class="error"
                    v-if="!$v.companyTypeCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveCompanyType"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <label for="myselect">Select Sector</label>
                <select
                  id="myselect"
                  class="form-control"
                  v-model="companyType.sectorId"
                >
                  <option
                    disabled
                    value=""
                  >
                    Select sector
                  </option>
                  <option
                    v-for="(sector, index) in getSector"
                    :value="sector.sectorId"
                    :key="index"
                  >
                    {{ sector.sectorName }}
                  </option>
                </select>
                <div v-if="sectorIdError">
                  <div
                    class="error"
                    v-if="!$v.companyType.sectorId.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Company Type Name"
                  placeholder="Company Type Name"
                  v-model="companyType.companyTypeName"
                  class="special-bottom"
                />
                <div v-if="companyTypeNameError">
                  <div
                    class="error"
                    v-if="!$v.companyType.companyTypeName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Company Type Code"
                  placeholder="Company Type Code"
                  v-model="companyType.companyTypeCode"
                  class="special-bottom"
                />
                <div v-if="companyTypeCodeError">
                  <div
                    class="error"
                    v-if="!$v.companyType.companyTypeCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--                SHOW FORM-->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Country Name:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.companyTypeName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Country Code:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.companyTypeCode }}
                  </div>
                </div>
              </div>
            </div>

            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {

  name: 'CompanyTypeList',

  components: {

    PulseLoader,
    Modal

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Company Types List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadCompanyType()
    this.loadSector()
  },

  computed: {

    ...mapGetters('companytype', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete'

    ])

  },

  data () {
    return {

      array: [],
      getCompany: [],
      getSector: [],
      getCityArray: [],
      finalUserPermissions: [],
      data: {},

      color: 'green',
      size: '15px',
      loading: true,

      SuccessMessage: '',
      ErrorMessage: '',

      companyTypeName: '',
      companyTypeCode: '',
      companyTypeId: '',
      sectorId: '',

      error: '',

      fileData: '',
      file: '',

      companyType: {
        companyTypeName: '',
        companyTypeCode: '',
        sectorId: ''

      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,
      UploadModalDiv: false,

      companyTypeNameError: false,
      companyTypeCodeError: false,
      sectorIdError: false

    }
  },

  validations: {

    companyType: {

      companyTypeName: { required },
      companyTypeCode: { required },
      sectorId: { required }

    },

    companyTypeName: { required },
    companyTypeCode: { required },
    sectorId: { required }

  },

  methods: {

    loadCompanyType () {
      this.myloader = true

      API.getCompanyType().then(
        response => {
          if (response.data.status) {
            this.getCompany = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadSector () { // for sector select box
      API.getSector().then(
        response => {
          if (response.data.status) {
            this.getSector = response.data.data
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadSingleCompanyType (data) {
      API.getIndividualCompanyType(data).then(
        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        }
      ).catch(e => {
        // this.state.errors.push(e)
      })
    },

    saveCompanyType () {
      if (this.$v.companyType.companyTypeName.$invalid || this.$v.companyType.companyTypeCode.$invalid ||
          this.$v.companyType.sectorId.$invalid) {
        if (this.$v.companyType.companyTypeName.$invalid) {
          this.companyTypeNameError = true
        }
        if (this.$v.companyType.companyTypeCode.$invalid) {
          this.companyTypeCodeError = true
        }
        if (this.$v.companyType.sectorId.$invalid) {
          this.sectorIdError = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API.postCompanyType(JSON.stringify(this.companyType)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = '"' + response.data.data.companyTypeName + '" company type saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadCompanyType()
              this.editMyLoader = false
              this.companyType.CompanyTypeName = ''
              this.companyType.companyTypeCode = ''
              this.companyType.sectorId = ''
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Record not added'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.companyType.CompanyTypeName = ''
          this.companyType.companyTypeCode = ''
          this.companyType.sectorId = ''
          this.closeModal()
        })

        // }, 2000);
      }
    },

    deleteCompanyType (data, name) {
      this.$store.dispatch('companytype/nameBeforeDelete', name)

      this.$dialog.confirm('If you delete cargo type [ ' + name + " ], it'll be gone forever.", {

        loader: true
      })
        .then((dialog) => {
          API.deleteCompanyType(data).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ' Company type [ ' + this.getNameBeforeDelete + ' ] has been deleted'
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                window.scrollTo(10, 0)
                this.loadCompanyType()
                this.$el.scrollTop = 0
                this.$store.dispatch('companytype/nameBeforeDelete', '')
              } else {

              }
            }
          ).catch(() => {
            this.ErrorMessage = ' Record not deleted'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
          })

          dialog.close()
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editCompanyType () {
      if (this.$v.companyTypeName.$invalid || this.$v.companyTypeCode.$invalid ||
          this.$v.sectorId.$invalid) {
        if (this.$v.companyTypeName.$invalid) {
          this.companyTypeNameError = true
        }
        if (this.$v.companyTypeCode.$invalid) {
          this.companyTypeCodeError = true
        }
        if (this.$v.sectorId.$invalid) {
          this.sectorIdError = true
        }
      } else {
        this.editMyLoader = true

        this.array =
            {
              companyTypeName: this.companyTypeName,
              companyTypeCode: this.companyTypeCode,
              companyTypeId: this.companyTypeId,
              sectorId: this.sectorId
            }

        API.updateCompanyType(JSON.stringify(this.array)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit,
                  [response.data.data.companyTypeName, response.data.data.companyTypeCode]
                  , 'Company type', response.data.data.companyTypeName)}`
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              window.scrollTo(10, 0)
              this.closeModal()
              this.loadCompanyType()
              this.editMyLoader = false
              this.$store.dispatch('companytype/valuesBeforeEdit', [])
            } else {

            }
          }
        ).catch(e => {
          this.errors = e
          this.editMyLoader = false
          this.ErrorMessage = ' Record not edited'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (companyTypeId = null, companyTypeName = null, sectorId = null, companyTypeCode = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.ShowModalDiv = false
        this.UploadModalDiv = false
        this.companyTypeName = companyTypeName
        this.companyTypeCode = companyTypeCode
        this.companyTypeId = companyTypeId
        this.sectorId = sectorId
        this.$store.dispatch('companytype/valuesBeforeEdit', [this.companyTypeName, this.companyTypeCode])
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.UploadModalDiv = false
      } else {
        this.loadSingleCompanyType(companyTypeId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
        this.UploadModalDiv = false
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.UploadModalDiv = false

      this.companyType.CompanyTypeName = ''
      this.companyType.companyTypeCode = ''
      this.companyType.sectorId = ''

      this.companyTypeCodeError = false
      this.companyTypeNameError = false
      this.sectorIdError = false
    },

    uploadFile (event) {
      this.fileData = event.target.files[0]
      this.file = this.fileData.name

      this.myloader = true

      const formData = new FormData()

      formData.append('file', this.fileData)

      API.uploadCityExcel(formData).then(
        response => {
          if (response.data.status) {
            response.data.data.map((value) => {
              this.getCityArray.push(value.cityName)
            })

            this.SuccessAlert = true
            this.ErrorAlert = false
            this.SuccessMessage = ' Countries Added [ ' + this.getCityArray + ' ]'
            this.myloader = false
            this.loadCity()
            this.$el.scrollTop = 0
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not sent'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    }

  }

}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.50;
    text-decoration: none;
    /*top:-7px;*/
    font-weight: normal;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
