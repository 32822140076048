<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>

      <div class="text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Designation List
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            v-if="finalUserPermissions.includes('DESIGNATION_CONFIG_POST')"
            @click.prevent="openModal(null , null , null, null,actionButton = 'AddModal')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>
      <hr>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Title
            </th>
            <th scope="col">
              Abbreviation
            </th>
            <th scope="col">
              Status
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getDesignation"
            :key="list.designationId"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>
              {{ list.designationTitle }}
            </td>
            <td>
              {{ list.designationAbbr }}
            </td>
            <td>
              {{ (list.active === 1) ? "Active" : "Inactive" }}
            </td>

            <td class="special-limit">
              <div
                class="all-fa-icons fa-icons-space edit-fa-icon"
                v-if="finalUserPermissions.includes('DESIGNATION_CONFIG_PUT')"
                @click.prevent="openModal(list.designationId , list.designationTitle ,list.designationAbbr,
                                          list.active, actionButton = 'EditModal')"
                title="edit"
              >
                <font-awesome-icon icon="edit" />
              </div>

              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                v-if="finalUserPermissions.includes('DESIGNATION_CONFIG_{DESIGNATIONID}_GET')"
                @click.prevent="openModal(list.designationId , null ,null,null, actionButton = 'ShowModal')"
                title="view"
              >
                <font-awesome-icon icon="eye" />
              </div>

              <div
                class="all-fa-icons fa-icons-space delete-fa-icon"
                v-if="finalUserPermissions.includes('DESIGNATION_CONFIG_{DESIGNATIONID}_DELETE')"
                @click.prevent="deleteDesignation(list.designationId, list.designationTitle)"
                title="delete"
              >
                <font-awesome-icon icon="trash-alt" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Designation <b>{{ designationTitle }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register Designation
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Designation: <b>{{ data.designationTitle }}</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editDesignation"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Title"
                  placeholder="Title"
                  v-model="designationTitle"
                  value="designationTitle"
                  class="special-bottom"
                />
                <div v-if="designationTitleError">
                  <div
                    class="error"
                    v-if="!$v.designationTitle.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Abbreviation"
                  placeholder="Abbreviation"
                  v-model="designationAbbr"
                  value="designationTitle"
                  class="special-bottom"
                />
                <div v-if="designationAbbrError">
                  <div
                    class="error"
                    v-if="!$v.designationAbbr.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Status"
                  placeholder="Status"
                  v-model="active"
                  value="active"
                  class="special-bottom"
                />

                <div v-if="activeError">
                  <div
                    class="error"
                    v-if="!$v.active.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveDesignation"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Title"
                  placeholder="Title"
                  v-model="designationObject.designationTitle"
                  class="special-bottom"
                />
                <div v-if="designationTitleError">
                  <div
                    class="error"
                    v-if="!$v.designationObject.designationTitle.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Abbreviation"
                  placeholder="Abbreviation"
                  v-model="designationObject.designationAbbr"
                  class="special-bottom"
                />
                <div v-if="designationAbbrError">
                  <div
                    class="error"
                    v-if="!$v.designationObject.designationAbbr.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <!--<base-input type="text" label="Status" placeholder="Abbreviation"-->
                <!--v-model="designationObject.active" class="special-bottom"></base-input>-->
                <label>Select Status</label>
                <select
                  class="form-control"
                  @change="changed($event)"
                >
                  <option value="2">
                    Select Status
                  </option>
                  <option value="1">
                    Active
                  </option>
                  <option value="0">
                    InActive
                  </option>
                </select>
                <div v-if="activeError">
                  <div
                    class="error"
                    v-if="!$v.designationObject.active.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Title
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.designationTitle }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 text-bold">
                    Abbreviation
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.designationAbbr }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 text-bold">
                    Title
                  </div>
                  <div class="col-md-6 text-value">
                    {{ (data.active)=1 ?"Active": "Inactive" }}
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {

  name: 'DesignationList',

  components: {

    PulseLoader,
    Modal

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Designations List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadDesignation()
  },

  computed: {

    ...mapGetters([

      'getDesignationBeforeEdit'

    ])

  },

  data () {
    return {

      selectedValue: '',

      array: [],
      getDesignation: [],
      finalUserPermissions: [],

      data: {},

      color: 'green',
      size: '15px',
      loading: true,

      SuccessMessage: '',
      ErrorMessage: '',
      designationTitle: '',
      designationAbbr: '',
      active: '',
      // AddDesignationName:"",
      designationId: '',
      error: '',
      NameBeforeSubmit: '',

      activeStatus: [],
      designationObject: {
        designationTitle: '',
        designationAbbr: '',
        active: ''
      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,

      designationTitleError: false,
      designationAbbrError: false,
      activeError: false

    }
  },

  validations: {

    designation: {
      designationTitle: { required },
      designationAbbr: { required },
      active: { required }
    },
    designationTitle: { required },
    designationAbbr: { required },
    active: { required },

    designationObject: {
      designationTitle: { required },
      designationAbbr: { required },
      active: { required }
    }

  },

  methods: {

    changed (a) {
      this.designationObject.active = a.target.value
    },

    loadDesignation () {
      this.myloader = true

      API.getDesignation().then(
        response => {
          if (response.data.status) {
            this.getDesignation = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not added'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadSingleDesignation (data) {
      API.getIndividualDesignation(data).then(

        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        }
      ).catch(e => {
        // this.state.errors.push(e)
      })
    },

    saveDesignation () {
      if (this.$v.designationObject.designationTitle.$invalid || this.$v.designationObject.designationAbbr.$invalid ||
          this.$v.designationObject.active.$invalid) {
        if (this.$v.designationObject.designationTitle.$invalid) {
          this.designationTitleError = true
        }
        if (this.$v.designationObject.designationAbbr.$invalid) {
          this.designationAbbrError = true
        }
        if (this.$v.designationObject.active.$invalid) {
          this.activeError = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API.postDesignation(JSON.stringify(this.designationObject)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = '"' + response.data.data.designationTitle + '" Designation Saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadDesignation()
              this.editMyLoader = false
              this.designationObject.designationTitle = ''
              this.designationObject.designationAbbr = ''
              this.designationObject.active = ''
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Record not Added'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.designationObject.designationTitle = ''
          this.designationObject.designationAbbr = ''
          this.designationObject.active = ''
          this.closeModal()
        })

        // }, 2000);
      }
    },

    deleteDesignation (data, name) {
      this.$store.dispatch('commitDesignationNameBeforeEdit', name)

      this.$dialog.confirm('If you delete designation type [ ' + name + " ], it'll be gone forever.", {

        loader: true
      })
        .then((dialog) => {
          // setTimeout(() => {

          API.deleteDesignation(data).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ' Designation [ ' + name + ' ] has been deleted'
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                this.loadDesignation()
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Record not deleted'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
          })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editDesignation () {
      if (this.$v.designationTitle.$invalid || this.$v.designationAbbr.$invalid ||
          this.$v.active.$invalid) {
        if (this.$v.designationTitle.$invalid) {
          this.designationTitleError = true
        }
        if (this.$v.designationAbbr.$invalid) {
          this.designationAbbrError = true
        }
        if (this.$v.active.$invalid) {
          this.activeError = true
        }
      } else {
        this.editMyLoader = true

        this.array =
            {
              designationTitle: this.designationTitle,
              designationAbbr: this.designationAbbr,
              active: this.active,
              designationId: this.designationId
            }

        // console.log(JSON.stringify(this.array).replace(/[\[\]']+/g, ''));

        // setTimeout(() => {

        API.updateDesignation(JSON.stringify(this.array)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = ' [ ' + this.getDesignationBeforeEdit + ' ] record edited to [ ' + response.data.data.designationTitle + ' ]'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadDesignation()
              this.editMyLoader = false
            } else {

            }
          }
        ).catch(e => {
          this.errors = e
          this.editMyLoader = false
          this.ErrorMessage = ' Record not edited'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })

        // }, 2000);
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (designationId = null, designationTitle = null, designationAbbr = null, active = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.ShowModalDiv = false
        this.designationTitle = designationTitle
        this.designationAbbr = designationAbbr
        this.active = active
        this.designationId = designationId

        this.$store.dispatch('commitDesignationNameBeforeEdit', this.designationTitle)
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
      } else {
        this.loadSingleDesignation(designationId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false

      this.designationAbbrError = false
      this.designationTitleError = false
      this.activeError = false
    }

  }

}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
