<template>
  <div class="content">
    <card>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="text-left">
              List Of Added Tancis Vessel Documents
            </div>
          </div>
          <div
            class="col-md-4 text-center"
            v-if="!mailSent"
          >
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
          <div
            class="col-md-4"
            v-if="!mailSent"
          />
          <div
            class="col-md-8"
            v-if="mailSent"
          >
            <div class="text-success">
              {{ mailSuccess }}
            </div>
          </div>
        </div>
      </div>
      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-7 text-right">
              <form
                class="search"
                @submit.prevent="loadSearchRequestImportReconciliationSummary(pageNo , pageSize)"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParamenter"
                      @input="loadAllPageble"
                    >
                    <span
                      class="input-group-addon"
                      @click.prevent="loadSearchRequestImportReconciliationSummary(pageNo , pageSize)"
                    >
                      <font-awesome-icon icon="search" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              MRN
            </th>
            <th scope="col">
              TPA UID
            </th>
            <th scope="col">
              Call Sign
            </th>
            <th scope="col">
              Voyage Number
            </th>
            <th scope="col">
              Vessel name
            </th>
            <th scope="col">
              Departure
            </th>
            <th scope="col">
              Arrival
            </th>
            <th scope="col">
              Discrepancies
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(document, index) in getDocument"
            :key="document.documentId"
            v-if="!noDataFound"
          >
            <td scope="row">
              {{ index + 1 +(pageNo * pageSize) }}
            </td>
            <td>
              {{ document.mrn }}
            </td>
            <td scope="col">
              {{ document.tpaUid }}
            </td>
            <td scope="col">
              {{ document.callSign }}
            </td>
            <td
              scope="col"
              :class="[ document.hasDiscrepancies ? 'text-danger' : 'text-success' ]"
            >
              {{ document.voyage }}
            </td>
            <td scope="col">
              {{ document.vesselName }}
            </td>
            <td scope="col">
              {{ document.departureDate }}
            </td>
            <td
              scope="col"
              :class="[ document.hasDiscrepancies ? 'text-danger' : 'text-success' ]"
            >
              {{ document.arrivalDate }}
            </td>
            <!-- <td class="special-limit"> -->
            <td
              scope="col"
              :class="[ document.hasDiscrepancies ? 'text-danger' : 'text-success' ]"
            >
              {{ document.discrepancy }}
            </td>
            <td class="special-limit">
              <router-link
                class="all-fa-icons fa-icons-space envelop-fa-icon"
                title="Discrepancies"
                :to="{ name: 'ImportReconciliation', params: { voyage: document.voyage } }"
                v-if="document.hasDiscrepancies && finalUserPermissions.includes('TANCIS_RECONCILIATION_VIEW')"
              >
                <font-awesome-icon icon="file-contract" />
              </router-link>

              <router-link
                class="all-fa-icons fa-icons-space envelop-fa-icon"
                title="Discrepancies Summary"
                :to="{ name: 'ImportReconciliationSummaryVoyage', params: { voyage: document.voyage } }"
                v-if="document.hasDiscrepancies && finalUserPermissions.includes('TANCIS_DISCREPANCY_SUMMARY')
                  && finalUserPermissions.includes('TANCIS_DISCREPANCY_SUMMARY_GRAPH')"
              >
                <font-awesome-icon icon="chart-pie" />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>
    <!--    </div>-->
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <!--   <div class="col-md-12" v-if="EditModalDiv">
              Edit Voyage Number <br/>
              [ Document:<b>{{ documentTypeName }}</b> ]
              [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">[ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
            </div> -->
          <div
            class="col-md-12"
            v-if="SendEmailModalDiv"
          >
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
            <br><br>
            <span>You are about to send above document download link to <b>{{ companyEmail }}</b>
              onbehalf of company <b>{{ companyName }}</b>, are you sure?.</span>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>
    </modal>

    <modal-manifest v-if="showModalManifest">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div class="col-md-12 text-center">
            MANIFEST AND ASSOCIATED BILL OF LADING DETAILS
          </div>
          <div class="col-md-12">
            <hr>
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
          </div>
          <div class="col-md-12 text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="BLLoader"
            />
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">
                      #
                    </th>
                    <th scope="col">
                      B/L No.
                    </th>
                    <th scope="col">
                      B/L Type
                    </th>
                    <th scope="col">
                      Container No.
                    </th>
                    <th scope="col">
                      Container Size
                    </th>
                    <th scope="col">
                      Container Type
                    </th>
                    <th scope="col">
                      Destination place
                    </th>
                    <th scope="col">
                      Gross Volume
                    </th>
                    <th scope="col">
                      Gross Weight
                    </th>
                    <th scope="col">
                      Marks No.
                    </th>
                    <th scope="col">
                      Operation Type
                    </th>
                    <th scope="col">
                      Seal No.
                    </th>
                    <th scope="col">
                      Tonnage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bl, index) in getDocumentBL"
                    :key="bl.cargoOriginalId"
                  >
                    <td scope="row">
                      {{ index + 1 }}
                    </td>
                    <td>{{ bl.billOfLadingNo }}</td>
                    <td>{{ bl.billOfLadingType }}</td>
                    <td>{{ bl.containerNo }}</td>
                    <td>{{ bl.containerSize }}</td>
                    <td>{{ bl.containerType }}</td>
                    <td>{{ bl.destinationPlace }}</td>
                    <td>{{ bl.grossVolume }} ({{ bl.grossVolumeUnit }})</td>
                    <td>{{ bl.grossWeight }} ({{ bl.grossWeightUnit }})</td>
                    <td>{{ bl.marksNumber }}</td>
                    <td>{{ bl.operationType }}</td>
                    <td>{{ bl.sealNo }}</td>
                    <td>{{ bl.tonnage }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <hr>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div" />
              <div class="col-md-2 left loader-div-gif" />
              <div
                class="col-md-3"
                v-if="ShowModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal-manifest>
  </div>
</template>
<script>

import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import ModalManifest from '../../../components/Inputs/ModalManifest'
import Modal from '../../../components/Inputs/Modal'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {

  name: 'ImportReconciliationSummary',

  data () {
    return {

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      docSortBy: 'id',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,

      file: '',
      options: [],
      errors: [],
      array: {},
      fileData: '',
      UploadMessage: '',
      documentTypeId: '',
      getVessel: [],
      getVesselSchedule: [],
      getDocument: [],
      getDocumentBL: [],
      finalUserPermissions: [],
      permissionGroups: [],

      documentToDocument: {
        documentType: {
          documentTypeId: ''
        },
        vesselSchedule: {

          vesselScheduleId: 99809

        }
      },

      documentId: '',
      voyageNo: '',
      documentTypeName: '',
      controlNumber: '',
      vesselName: '',
      companyEmail: '',
      companyName: '',
      vesselScheduleId: '',
      documentUrl: '',

      color: 'green',
      size: '15px',
      loading: true,

      ManifestLoader: false,
      ManifestFinishLoader: false,
      myLoader: false,
      BLLoader: false,
      sendMyLoader: false,
      editMyLoader: '',
      emailAddress: '',
      mailSuccess: '',

      showModal: false,
      EditModalDiv: false,
      ShowModalDiv: false,
      SendEmailModalDiv: false,
      showModalManifest: false,
      mailSent: false,

      docTypeError: false,
      vesselScheduError: false

    }
  },

  validations: {

    documentToDocument: {
      documentType: {
        documentTypeId: {
          required
        }
      },
      vesselSchedule: {
        vesselScheduleId: {
          required
        }
      }
    }

  },

  components: {

    PulseLoader,
    Modal,
    'modal-manifest': ModalManifest

  },

  computed: {

    ...mapGetters([

      'getDocumentVoyageNumberBeforeEdit'

    ])

  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableImportReconciliationSummary(this.pageNo, this.pageSize)
  },

  methods: {

    loadSearchRequestImportReconciliationSummary (pageNo, pageSize) {
      this.myloader = true
      this.reloadAllRequest = true
      this.hasSearchedVariable = true

      API.getSearchPageableExportReconciliationSummary(pageNo, pageSize, this.searchParameter).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            if (response.data.data.length > 0) {
              this.getDocument = response.data.data
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            }
          } else {
            this.noDataFound = true
            this.pageCount = response.data.message
            this.docPageCount = this.pageCount
            this.docPageNo = this.pageNo
            this.docPageSize = this.pageSize
            this.getDocument = response.data.data
            this.myloader = false
          }
        }
      ).catch(e => {
        this.myloader = false
      })
    },

    loadPageableImportReconciliationSummary (pageNo, pageSize) {
      return new Promise((resolve, reject) => {
        this.myloader = true

        API.getPageableImportReconciliationSummary(pageNo, pageSize).then(
          response => {
            if (response.data.status) {
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getDocument = response.data.data
              this.myloader = false
            } else {

            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ' Requests Not loaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false

          reject(e)
        })
      })
    },

    onChange (event) {
      this.pageSize = event.target.value
      this.pageNo = 0
      if (this.hasSearchedVariable) {
        this.loadSearchRequestImportReconciliationSummary(this.pageNo, this.pageSize)
      } else {
        this.loadPageableImportReconciliationSummary(this.pageNo, this.pageSize)
      }
      this.docPageNo = this.pageNo
      this.docPageSize = this.pageSize
      this.docPageCount = this.pageCount
      this.docSortBy = this.sortBy
    },

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestImportReconciliationSummary(this.pageNo)
      } else {
        this.loadPageableImportReconciliationSummary(this.pageNo, this.pageSize)
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestImportReconciliationSummary(this.pageNo, this.pageSize)
      } else {
        this.loadPageableImportReconciliationSummary(this.pageNo, this.pageSize)
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.loadSearchRequestImportReconciliationSummary(this.pageNo, this.pageSize)
      } else {
        this.loadPageableImportReconciliationSummary(this.pageNo, this.pageSize)
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestImportReconciliationSummary(this.pageNo, this.pageSize)
      } else {
        this.loadPageableImportReconciliationSummary(this.pageNo, this.pageSize)
      }
    },

    reloadByIcon () {
      this.loadPageableImportReconciliationSummary(this.pageNo, this.pageSize)
      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParamenter = ''
    },

    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadPageableImportReconciliationSummary(this.pageNo, this.pageSize)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParamenter = ''
      }
    },

    loadDocument (pageNum, pageSizePassed) {
      this.myloader = true

      API.getTancisDocuments(pageNum, pageSizePassed).then(
        response => {
          if (response.data.status) {
            this.getDocument = response.data.data.content
            this.pageNumber = response.data.data.pageable.pageNumber
            this.pageSize = response.data.data.pageable.pageSize
            this.offset = response.data.data.pageable.offset
            this.totalPages = response.data.data.totalPages
            this.totalElements = response.data.data.totalElements
            this.first = response.data.data.first
            this.last = response.data.data.last
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {

      })
    },

    uploadFile (event) {
      this.fileData = event.target.files[0]

      this.file = this.fileData.name

      this.ManifestLoader = true

      const formData = new FormData()

      formData.append('file', this.fileData)

      setTimeout(() => {
        API.uploadTancisDocument(formData).then(
          response => {
            if (response.data.status) {
              this.ManifestLoader = false
              this.ManifestFinishLoader = true
              this.loadDocument(0, 0)
              this.file = ''
              this.docTypeError = false
              this.vesselScheduError = false
              setTimeout(() => {
                this.ManifestFinishLoader = false
              }, 7000)
            } else {

            }
          }
        ).catch(e => {

        })
      }, 2500)
    },

    openModal (documentId = null, docUrl = null, documentTypeName = null, documentTypeId = null,
      vesselScheduleId = null, controlNumber = null, vesselName = null, voyageNo = null,
      comEmail = null, comName = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.SendEmailModalDiv = false
        this.ShowModalDiv = false
        this.showModalManifest = false
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
        this.documentTypeId = documentTypeId
        this.vesselScheduleId = vesselScheduleId
        this.documentUrl = docUrl

        this.$store.dispatch('commitDocumentVoyageNumberBeforeEdit', this.voyageNo)
      } else if (actionButton === 'SendEmailModal') {
        this.EditModalDiv = false
        this.SendEmailModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.showModalManifest = false
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
        this.companyEmail = comEmail
        this.companyName = comName
      } else {
        this.loadSingleDocument(documentId)
        this.EditModalDiv = false
        this.SendEmailModalDiv = false
        this.ShowModalDiv = true
        this.showModal = false
        this.showModalManifest = true
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.SendEmailModalDiv = false
      this.showModalManifest = false
    }

  }

}
</script>
<style scoped>
  .disabled-click {
    pointer-events:none;
    color:#C0C0C0;
  }
  .enabled-click {
    cursor: pointer;
  }
  .disable-highlight {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
  }
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }
  .btn-space {
    margin-right: 10px !important;
  }

  .loader-div-gif {
    line-height: 34px;
  }

  .loader-div-gif-email {
    line-height: 54px;
  }

  .loader-div-email {
    line-height: 45px;
  }

  .special-limit {
    max-width: 170px !important;
    min-width: 150px !important;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 16px;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  #label-upload {
    position: relative;
    top: 16px;
  }

</style>
<style>
  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }
</style>
