<template>
    <transition name="modal col-md-12">
        <div>
        <div class="modal  modal-mask" style="display: block">
            <div class="modal-dialog medium-modal-dialog" role="document" :style="'max-width:' + maxWidth + 'px !important'">
            <div class="modal-content col-md-12">
                <div class="col-md-12" style="text-align:right !important;padding-top:10px;padding-right:25px;">
                    <!-- <button class="btn btn-primary mr-2 custom-btn" @click.prevent="downloadPdf" v-if="download">
                        Download
                    </button> -->
                    <button class="btn btn-default btn-fill btn-space custom-btn-all" @click.prevent="closePdfViewer">
                        Close
                    </button>
                </div>

                <div class="modal-body col-md-12">
                    <iframe :src="mySrc" width="100%" height="900px" style="border: 0; width:100%; height:900px;"></iframe>
                    <!-- <embed :src="mySrc" width="100%" height="900px"> -->
                </div>

                <div class="col-md-12" style="text-align:right !important;padding-bottom:10px;padding-right:25px;">
                    <!-- <button class="btn btn-primary mr-2 custom-btn" @click.prevent="downloadPdf" v-if="download">
                        Download
                    </button> -->
                    <button class="btn btn-default btn-fill btn-space custom-btn-all" @click.prevent="closePdfViewer">
                        Close
                    </button>
                </div>
            </div>
            </div>
        </div>
        </div>
    </transition>
</template>

<script>
export default {

    name: 'Modal',

    props: {

        maxWidth: {

            type: Number,
            default: () => 1200

        },

        mySrc: {

            type: String,
            default: () => ''

        },

        download: {

            type: Boolean,
            default: () => true

        }

    },

    methods: {

        closePdfViewer () {

            this.$emit('closePdfViewer')

        },

        downloadPdf () {

            this.$emit('downloadPdf')

        }

    }

}
</script>

<style>

    .modal-mask {
        position: fixed;
        z-index: 9990;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .8);
        display: table;
        transition: opacity .3s ease;
        overflow-y: auto !important;
    }

</style>

<style scoped>
.modal-footer {text-align: right !important;}
.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}
</style>
