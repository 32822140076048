<template>
  <div>
    <div slot="body">
      <card>
        <div>
          <div class="row">

            <div class="col-md-12">
              <base-input type="email" label="User Name (email address)" placeholder="User Name (email address)" v-model="user.username" class="special-bottom" :disabled="dataLoaded"/>
              <div v-if="usernameError">
                <div class="error" v-if="!$v.user.username.required">
                  This field is required
                </div>
              </div>
              <div v-if="incorrectEmailError" class="text-danger">
                incorrect email address
              </div>
            </div>
            <div class="col-md-6">
              <base-input type="text" label="First Name" placeholder="First Name" v-model="user.firstName" class="special-bottom" :disabled="dataLoaded"/>
              <div v-if="firstNameError">
                <div class="error" v-if="!$v.user.firstName.required">
                  This field is required
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <base-input type="text" label="Middle Name" placeholder="Middle Name" v-model="user.middleName" class="special-bottom" :disabled="dataLoaded"/>
              <div v-if="middleNameError">
                <div class="error" v-if="!$v.user.middleName.required">
                  This field is required
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <base-input type="text" label="Last Name" placeholder="Last Name" v-model="user.lastName" class="special-bottom" :disabled="dataLoaded"/>
              <div v-if="lastNameError">
                <div class="error" v-if="!$v.user.lastName.required">
                  This field is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <base-input type="number" label="PHONE NO" placeholder="Phone Number" v-model="user.phone" class="special-bottom" :disabled="dataLoaded"/>
              <div v-if="phoneError">
                <div class="error" v-if="!$v.user.phone.required">
                  This field is required
                </div>
              </div>
            </div>


          </div>
          <br>
          <div class="col-md-12">
            <span class="text-center">
              <pulse-loader :loading="loading" :color="color" :size="size"/>
            </span>
          </div>
          <div class="col-md-12 mt-2">
            <button class="btn btn-default btn-block" @click="saveUser">Save</button>
          </div>
        </div>
      </card>
    </div>
  </div>

</template>

<script>
  import API from "../../../../api";
  import {email, required} from "vuelidate/lib/validators";
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


  export default {
    name: "editCfAgentUser",

    props:['userId'],

    created() {
      this.getUserById(this.userId);
    },

    components:{
      PulseLoader
    },

    data () {
      return {

        array: [],
        getDesignation: [],
        getDepartment: [],

        data: {},

        color: 'green',
        size: '15px',
        loading: false,

        SuccessMessage: '',
        selectedRoles: [],
        ErrorMessage: '',
        username: '',
        firstName: '',
        enabled: '',
        middleName: '',
        lastName: '',
        email: '',
        error: '',
        dataLoaded:true,

        user: {

          checkno: null,
          type: null,
          tin: null,
          phone: "",
          username: "",
          firstName: "",
          middleName: "",
          lastName: "",
          email: "",
          sseToken: null,
          enabled: true,
          firstTime: true,
          createdTime: "",
          designationId: null,
          departmentId: null,
          customerType: "",
          updatedTime: null,
          roles: null,

        },
        SuccessAlert: false,
        permissionMyLoader: false,
        ErrorAlert: false,
        myLoader: false,
        usernameError: false,
        firstNameError: false,
        middleNameError: false,
        lastNameError: false,
        incorrectEmailError: false,
        emailError: false,
        phoneError:false

      }
    },

    validations: {

      user: {

        username: { required },
        firstName: { required },
        middleName: { required },
        lastName: { required },
        phone: {required}

      },

      searchParameter: { required },

      username: { required },
      firstName: { required },
      enabled: { required },
      middleName: { required },
      lastName: { required },
      email: { required, email },

    },

    methods:{

      getUserById(userId){
        this.dataLoaded=true;
        API.getUser(userId).then((resp)=>{
          this.user=resp.data.data;
          this.dataLoaded=false;
        }).catch((err)=>{

        });
      },

      saveUser () {
        if (this.$v.user.username.$invalid || this.$v.user.phone.$invalid  || this.$v.user.firstName.$invalid || this.$v.user.middleName.$invalid ||
          this.$v.user.lastName.$invalid) {
          if (this.$v.user.username.$invalid) {
            this.usernameError = true
          }
          if (this.$v.user.firstName.$invalid) {
            this.firstNameError = true
          }
          if (this.$v.user.middleName.$invalid) {
            this.middleNameError = true
          }
          if (this.$v.user.lastName.$invalid) {
            this.lastNameError = true
          }

          if (this.$v.user.phone.$invalid) {
            this.phoneError = true
          }

        } else {
          const atPos = this.user.username.indexOf('@');
          const dotPos = this.user.username.lastIndexOf('.');

          if (this.user.username.length <= 0) {
            this.incorrectEmailError = true;
            this.user.username = '';
          } else if (atPos < 1 || dotPos < atPos + 2 || dotPos + 2 >= this.user.username.length) {
            this.incorrectEmailError = true;
            this.user.username = '';
          } else {
            this.editMyLoader = true;
            console.log(this.user)

            // API.updateUser(JSON.stringify(this.user),this.userId).then(
            //   response => {
            //     if (response.data.status) {
            //       this.SuccessMessage = ', User with username [ ' + response.data.data.username + ' ] Saved';
            //       this.SuccessAlert = true;
            //       this.ErrorMessage = '';
            //       this.ErrorAlert = false;
            //       this.phoneError=false;
            //       this.editMyLoader = false;
            //       this.usernameError = false;
            //       this.firstNameError = false;
            //       this.middleNameError = false;
            //       this.lastNameError = false;
            //       this.emailError = false;
            //       this.incorrectEmailError = false;
            //
            //       this.user = {
            //
            //         checkno: null,
            //         type: null,
            //         tin: null,
            //         phone: "",
            //         username: "",
            //         firstName: "",
            //         middleName: "",
            //         sseToken: null,
            //         lastName: "",
            //         email: "",
            //         enabled: true,
            //         firstTime: true,
            //         createdTime: "",
            //         designationId: null,
            //         departmentId: null,
            //         customerType: "",
            //         updatedTime: null,
            //         roles: null,
            //         id:this.userId
            //
            //       };
            //       this.$emit('closeModal');
            //       this.$emit('loadUsers');
            //       this.notifyUser('success', ' Success!', `User added Successfully` )
            //     } else {
            //
            //     }
            //   }
            // ).catch(e => {
            //   console.log(e);
            //   this.editMyLoader = false;
            //   this.ErrorMessage = ' User not Added';
            //   this.SuccessMessage = '';
            //   this.SuccessAlert = false;
            //   this.ErrorAlert = true;
            //   this.editMyLoader = false;
            //   this.usernameError = false;
            //   this.firstNameError = false;
            //   this.middleNameError = false;
            //   this.lastNameError = false;
            //   this.emailError = false;
            //   this.incorrectEmailError = false;
            //   this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            //
            // })


          }
        }
      },
    }
  }
</script>

<style scoped>
  .error{
    color: red;
  }
</style>
