<template>
  <div>
    <div
      class="col-md-12 alert-dismissible fade show"
      role="alert"
      :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
      style="border-radius:5px;"
      @enter="enter"
    >
      <strong v-if="SuccessAlert">Success!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
      <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        v-if="SuccessAlert"
        @click="fadeMe"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        v-if="ErrorAlert"
        @click="fadeMe"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <br>
  </div>
</template>

<script>
export default {

  name: 'CrudNotification',

  props: {

    SuccessMessage: '',
    ErrorMessage: '',
    SuccessAlert: {

      type: Boolean,
      default: false

    },
    ErrorAlert: {

      type: Boolean,
      default: false

    }

  },

  methods: {

    fadeMe () {
      this.$emit('fadeMe')
    },

    enter () {
      this.$emit('enter')
    }

  }

}
</script>

<style scoped>

    .alert-success {
        padding-top:10px;
        padding-bottom: 10px;
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    .alert-danger {
        padding-top:10px;
        padding-bottom: 10px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

</style>
