<template>
    <div :key="requestId">
        <!-- Reloads component from notitifcation list, do not delete -->
        {{ ketRerenderComponentChat }}
        <card style="padding-right:10px;padding-left:10px;">
            <div class="row">
                <div class="col-md-8">
                    Conversion for Application [ File Number: <b>{{ this.fileNo }}</b> ] [ B/L Number: <b title="Go to Application" class="go-to-application" @click="goToApplication">{{ this.blNumber }}</b>  ]
                </div>
                <div class="col-md-4 text-right">
                    <span class="go-to-application" v-if="!hasMajorIssue" @click="goToApplicationAction(false)">click here to attach any additional documents</span>
                </div>
                <div class="col-md-12 mb-2">
                    <hr />
                </div>
                <div class="col-md-12 text-center pt-4" v-if="loadAllHeaderLoader">
                    <p>Loading messages, Please wait</p><pulse-loader
                        :color="color"
                        :size="size"
                    />
                </div>
            </div>

            <div class="chat-wrapper">
                <div class="chat-messages" id="message-div">
                    <span v-for="(message , index) in requestMessages" :key="index">
                        <div class="message" :class="message.customerId !== null ? 'admin' : ''">
                            <div>
                                <p class="message-header">
                                    <span class="message-user">{{ message.customerId !== null ? message.createdBy : 'TASAC-CFA' }}</span>
                                    <span class="message-date">{{ getFriendlyMessageSentTime(message.createdAt) }}</span>
                                </p>
                                <p class="message-content">{{ message.comment }}</p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        
            <div class="row">
                <div class="col-md-12">
                    <label>Please enter message</label>
                    <textarea
                        type="text"
                        placeholder="Please enter message"
                        class="form-control"
                        rows="5"
                        :disabled="sendRequestLoader"
                        v-model="tasacRejectMessageNew"/>
                    <div v-if="rejectedMessageError">
                        <div
                        class="text-danger"
                        v-if="!$v.tasacRejectMessageNew.required"
                        >
                        This field is required
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12">
                    <br />
                </div>
                <div class="col-md-12 text-left">
                    <label v-if="hasMajorIssue" :class="[hasMajorIssue ? 'text-danger' : 'text-warning']"> <b>{{ hasMajorIssueMessage }}</b> : </label>
                    <span class="go-to-application" v-if="hasMajorIssue" @click="goToApplicationAction(true)">click here to attend raised issues</span>
                    <br />
                    <span class="go-to-application" v-if="!hasMajorIssue" @click="goToApplicationAction(false)">click here to attach any additional documents</span>
                </div> -->
            </div>

            <br />

            <div class="text-center row">
                <div class="col-md-8 text-left">
                    <label v-if="hasMajorIssue" :class="[hasMajorIssue ? 'text-danger' : 'text-warning']"> <b>{{ hasMajorIssueMessage }}</b> : </label>
                    <span class="go-to-application" v-if="hasMajorIssue" @click="goToApplicationAction(true)">click here to attend raised issues</span>
                </div>
                <div class="col-md-2 text-right loader-div-gif mt-2">
                <pulse-loader
                    :loading="sendRequestLoader"
                    :color="color"
                    :size="size"
                />
                </div>
                <div class="col-md-2 text-right">
                <button
                    type="submit"
                    class="btn btn-success btn-space custom-btn mr-2"
                    data-dismiss="modal"
                    @click.prevent="messageChatRoom">
                    Send
                </button>
                <button class="btn btn-fill btn-outline-info float-right custom-btn-all" @click.prevent="goBack">
                    Close
                </button>
                </div>
            </div>
        </card>
    </div>
</template>

<script>

import API from '../../../api'
import { required } from 'vuelidate/lib/validators'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

    name: 'CustomerApplicationChatRoom',

    components: {

        PulseLoader

    },

    validations: {

        tasacRejectMessageNew: { required }

    },

    created () {

        if (this.$route.params.requestId) {

            this.$store.dispatch('pagenames/currentPageName', 'Conversion Room')
            this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
            this.requestMessages = []
            this.requestId = this.$route.params.requestId
            this.getAllMessagesPerRequest(this.$route.params.requestId)

        }
    
    },

    computed: {

        ketRerenderComponentChat: function () {
            
            this.requestId = this.$route.params.requestId
            this.getAllMessagesPerRequest(this.requestId)

        }

    },

    data () {

        return {

            pdfFormatAmendmentError: [],
            pdfFormatAmendmentSuccess: [],
            loadSingleEditableMessage: [],
            finalUserPermissions: [],
            requestMessages: [],
            hasMajorIssue: false,
            hasMajorIssueMessage: '',
            requestSingleMessage: {},

            color: 'green',
            size: '13px',
            size2: '13px',
            requestId: '',
            blNumber: '',
            fileNo: '',
            tasacRejectMessageNew: '',

            loading: true,
            sendRequestLoader: false,
            loadAllHeaderLoader: false,
            rejectedMessageError: false,

            file2: '',
            fileData2: '',

        }

    },

    methods: {

        messageChatRoom () {

            if (this.$v.tasacRejectMessageNew.$invalid) {

                this.rejectedMessageError = true

            } else {

                this.sendRequestLoader = true

                const myObject = {

                comment: this.tasacRejectMessageNew,
                requestCommentAttachmentDtos: [],
                requestId: Number(this.requestId)
                }

                API.sendNewMessageOnChatRoom(JSON.stringify(myObject)).then(
                response => {
                    if (response.data.status) {

                        this.tasacRejectMessageNew = ''
                        this.sendRequestLoader = false
                        this.getAllMessagesPerRequest(this.requestId)

                    } else {

                        this.tasacRejectMessageNew = ''
                        this.requestId = ''
                        this.sendRequestLoader = false

                    }
                }
                ).catch(() => {

                    this.tasacRejectMessageNew = ''
                    this.requestId = ''
                    this.sendRequestLoader = false

                })
            }

        },

        getAllMessagesPerRequest (data) {

            this.loadAllHeaderLoader = true

            API.getMessagesOnChatRoomPerRequest(data).then(
                response => {

                    if (response.data.status) {

                        this.requestMessages = response.data.data
                        this.hasMajorIssue = this.markHasMajorIssue(this.requestMessages)
                        // this.clearMessageAsRead(data)
                        this.getRequestDetails(data)
                        this.loadAllHeaderLoader = false

                        let objDiv = document.getElementById("message-div");
                        objDiv.scroll({ top: objDiv.scrollHeight, behavior: 'smooth' });

                    } else {}
                }

            ).catch(() => {})

        },

        markHasMajorIssue (data) {

            if(data.length > 0) {

                if(data[0].applicationStatus === 'Request Returned For Major Changes') {

                    this.hasMajorIssueMessage = 'Has Major Issue'
                    return true

                } else if(data[0].applicationStatus === 'Request Returned For Minor Changes') {

                    this.hasMajorIssueMessage = 'Has Minor Issue'
                    return true

                } else {

                    this.hasMajorIssueMessage = ''
                    return false

                }

            } else {

                this.hasMajorIssueMessage = ''
                return false

            }

        },

        // clearMessageAsRead (data) {

        //     API.markChatMessagesAsRead(data).then(
        //         response => {

        //             if (response.data.status) {

        //             } else {}

        //         }
        //     ).catch(() => {})

        // },

        getRequestDetails (data) {

            API.getSingleRequest(data).then(
                response => {

                    if (response.data.status) {

                        this.blNumber = response.data.data.blNumber
                        response.data.data.fileNo !== null && response.data.data.fileNo !== '' ? this.fileNo = response.data.data.fileNo : this.fileNo = 'NIL'
                        
                    } else {}

                }
            ).catch(() => {})

        },

        clearMessage () {

            this.tasacRejectMessageNew = ''

        },

        goBack () {

            if (window.$cookies.isKey('browser-local-cashed-url-back-to-chat')) {

                this.$router.push(window.$cookies.get('browser-local-cashed-url-back-to-chat'));
                window.$cookies.remove('browser-local-cashed-url-back-to-chat')

            } else {

                this.$router.go(-1);

            }

        },

        goToApplication () {

            this.$router.push({name: 'CustomerApplicationDetails', params: {requestId: this.$route.params.requestId}})

        },

        goToApplicationAction (isEdit) {

            if(isEdit) {

                document.cookie = 'browser-local-cashed-url-back-7=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
                this.$router.push({name: 'EditApplication', params: {requestId: this.$route.params.requestId}})

            } else {

                document.cookie = 'browser-local-cashed-url-back-11=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
                this.$router.push({name: 'additionalAttachmentToApplication', params: {requestId: this.$route.params.requestId}})

            }

        }

    }
    
}

</script>
<style lang="scss" scoped>

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.message-body {
    background-color: rgba(236, 236, 236, 1);
    padding: 5px 15px 5px 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.message-body-index {
    background-color: rgba(189, 195, 199, 1);
    padding: 5px 15px 5px 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.background-color-message-to-edit {
    background-color: #87cefa !important;
}

.edit-tasac-messages:hover {
    cursor: pointer;
    color: blue;
}

.edit-tasac-messages {
    color: green;
}

.chat-message-box {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: 1px solid #0000ff;
    // border-right: 1px solid #0000ff;
    margin-bottom: 15px;
    background-color: rgba(236, 236, 236, .4);
}

.chat-message-box-customer {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: 1px solid #008000;
    // border-left: 1px solid #008000;
    margin-bottom: 15px;
    background-color: #dcf8c6;
}

.attended {
    color:green;
}

.not-attended {
    color:red;
}

.fileUpload2 span {
    cursor: pointer;
}

.fileUpload2 {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    font-weight: normal;
}

.fileUpload2 input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.label2 {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
}

.label-upload2 {
    position: relative;
    top: 23px;
}

.go-to-application {
    color:blue;
}

.go-to-application:hover {
    color:#d59a18;
    cursor:pointer;
}

.chat-wrapper {
    display: flex;
    flex-direction: column;
    .chat-messages {
        overflow-y: auto;
        height: calc(100vh - 450px);
        padding: 0 1.3rem;
        .message {
        display: flex;
        div {
            width: 75%;
            // display: flex;
            // flex-direction: column;
            .message-header {
            display: flex;
            align-items: center;
            .message-date {
                color: var(--gray-600);
                margin-left: 1rem;
            }
            }
            .message-content {
            display: inline-flex;
            background-color: #add8e6;
            padding: 1rem;
            border-radius: 0.5rem;
            position: relative;
            &:after {
                bottom: 100%;
                left: calc(0% + 2rem);
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-bottom-color: #add8e6;
                border-width: 10px;
                margin-left: -10px;
            }
            }
        }
        &.admin {
            justify-content: flex-end;
            div {
            justify-content: flex-end;
            .message-header {
                justify-content: flex-end;
                .message-date {
                margin-right: 1rem;
                }
                .message-user {
                order: 2;
                margin-right: 1rem;
                }
            }
            .message-content {
                float: right;
                background-color: #dcf8c6;
                &:after {
                left: calc(100% - 2rem);
                border-bottom-color: #dcf8c6;
                }
            }
            }
        }
        }
    }
    .chat-form {
        padding: 0 1.3rem;
    }
}

</style>