<template>
    <div>
        <card
        style="font-size: 12px"
        v-if="!thisFirstRequestLoader && finalUserPermissions.includes('BILLS_GET-INVOICE-PDF_{BILLID}_GET')
                && singleRequest.status !== 'Canceled'">
        <div class="row">
            <div class="col-md-12">
            <ul
                class="nav-justified"
                style="padding-left: 10px;padding-right: 10px">
                <li>
                <button
                    @click="openInvoicePdfViewer(singleRequest.billId, 'view')"
                    class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                    Download Invoice
                </button>
                </li>

                <li v-if="singleRequest.status === 'Paid' && singleRequest.status !== 'Not Approved'
                    && singleRequest.status !== 'Canceled'">
                <button
                    @click="openReceiptPdfViewer(singleRequest.billId, 'view')"
                    class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                    Download Receipt
                </button>
                </li>

                <li v-if="!singleRequest.bulk" class="float-right">
                <button
                    @click="goBack"
                    class="btn btn-linen btn-linen-fill mr-2 custom-btn">
                    View Associated Application
                </button>
                </li>
            </ul>
            </div>
        </div>
        </card>
        <card style="font-size: 12px">
        <div class="row" v-if="thisFirstRequestLoader">
            <div class="col-md-12 text-center loading-text-color">
            <b>Please wait while system is loading bill details</b>
            </div>
            <div class="col-md-12 text-center">
                <br>
            </div>
            <div class="col-md-12 text-center">
                <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                />
            </div>
        </div>

        <div
            class="row"
            v-if="!thisFirstRequestLoader"
        >
            <div class="col-md-12">
            <div class="legend">
                <h6 class="legend-title">
                Bill Details
                </h6>

                <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Current Application Bill Stage / Status
                </div>
                <div
                    v-if="singleRequest.status === 'Submiting to GePG' "
                    class="col-md-3 div-table div-table-value"
                    style="color: #808080;font-weight: 900"
                >
                    {{ applicationStatus }}
                </div>
                <div
                    v-if="singleRequest.status === 'Bill Expired' "
                    class="col-md-3 div-table div-table-value"
                    style="color: red;font-weight: 900"
                >
                    {{ applicationStatus }}
                </div>
                <div
                    v-if="singleRequest.status === 'Not Paid' "
                    class="col-md-3 div-table div-table-value"
                    style="color: #808080;font-weight: 900"
                >
                    {{ applicationStatus }}
                </div>
                <div
                    v-if="singleRequest.status === 'Paid' "
                    class="col-md-3 div-table div-table-value"
                    style="color: green;font-weight: 900"
                >
                    {{ applicationStatus }}
                </div>
                <div
                    v-if="singleRequest.status === 'Canceled' "
                    class="col-md-3 div-table div-table-value"
                    style="color:red;font-weight: 900"
                >
                    <div class="row">
                    <div class="col-md-8">
                        {{ applicationStatus }}
                    </div>
                    <div class="col-md-4 tooltip-pipe">
                        <font-awesome-icon
                        icon="comment"
                        class="cancel-message text-right"
                        color="green"
                        />
                        <span class="tooltip-text text-center">
                        {{ cancelledMessage }}
                        </span>
                    </div>
                    </div>
                </div>
                <div
                    v-if="singleRequest.status === 'Waiting Control Number' "
                    class="col-md-3 div-table div-table-value"
                    style="color: #003d00;font-weight: 900"
                >
                    {{ applicationStatus }}
                </div>
                <div
                    v-if="singleRequest.status === 'Not Approved' "
                    class="col-md-3 div-table div-table-value"
                    style="color: #f4a460;font-weight: 900"
                >
                    {{ applicationStatus }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Bill Type
                </div>
                <div
                    class="col-md-3 div-table div-table-value"
                    v-if="singleRequest.bulk"
                >
                    Bulk ( {{ singleRequest.bulkBill.length }} Items )
                </div>
                <div
                    class="col-md-3 div-table div-table-value"
                    v-if="!singleRequest.bulk"
                >
                    Single
                </div>
                </div>

                <br>

                <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Bill Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.billNo }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    GEPG Control Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.controlNumber }}
                </div>
                </div>

                <br>

                <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Billed By
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.generatedBy }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Approved By
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.approvedBy }}
                </div>
                </div>

                <br>

                <div class="row div-table-row">
                <div class="col-md-2 div-table div-table-title">
                    Amount ({{ singleRequest.billCurrency }})
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ singleRequest.billCurrency === 'USD' ? putComma(amountDecimalPlaces(singleRequest.totalBillAmount)) : putComma(amountDecimalPlaces(singleRequest.totalBillAmountTzs)) }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                    VAT ({{ singleRequest.billCurrency }})
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ singleRequest.billCurrency === 'USD' ? putComma(amountDecimalPlaces(singleRequest.billVatAmount)) : putComma(amountDecimalPlaces(singleRequest.billVatAmountTzs)) }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                    Vated Amount ({{ singleRequest.billCurrency }})
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ singleRequest.billCurrency === 'USD' ? putComma(amountDecimalPlaces(singleRequest.totalVatedAmount)) : putComma(amountDecimalPlaces(singleRequest.billVatedAmountTzs)) }}
                </div>
                </div>

                <br>
                <div class="row div-table-row">
                <div class="col-md-2 div-table div-table-title">
                    Billed Date
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ dateTimeToHuman(singleRequest.dateBilled) }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                    Approved Date
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ dateTimeToHuman(singleRequest.startDate) }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                    Due Date
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ dateTimeToHuman(singleRequest.dueDate) }}
                </div>
                </div>
            </div>
            </div>

            <div
            class="col-md-12"
            v-if=" singleRequest.company !== null"
            >
            <div class="legend">
                <h6 class="legend-title">
                Customer Details
                </h6>

                <div class="row div-table-row">
                <div class="col-md-2 div-table div-table-title">
                    Name
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ singleRequest.company.name }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                    Email Address
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ singleRequest.company.email }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                    Mobile
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ singleRequest.company.mobile }}
                </div>
                </div>

                <br>

                <div class="row div-table-row">
                <div class="col-md-2 div-table div-table-title">
                    TIN
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ putHyphen(singleRequest.company.tin) }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                    VRN
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ singleRequest.company.vrn }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                    Address
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ singleRequest.company.address }}
                </div>
                </div>
            </div>
            </div>

            <div class="col-md-12">
            <div class="legend">
                <h6 class="legend-title">
                TASAC Service(s) Provided (Bill Description)
                </h6>

                <div class="row div-table-row">
                <div class="col-md-1 div-table div-table-title">
                    #
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Service Name
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Description
                </div>
                <div class="col-md-2 div-table div-table-title">
                    Quantity
                </div>
                <div class="col-md-1 div-table div-table-title">
                    Amount ({{singleRequest.billCurrency}})
                </div>
                <div class="col-md-1 div-table div-table-title">
                    VAT ({{singleRequest.billCurrency}})
                </div>
                <div class="col-md-1 div-table div-table-title">
                    Vated Amount ({{singleRequest.billCurrency}})
                </div>
                </div>

                <div class="row div-table-row" v-for="(service , index_service) in singleRequest.billItems" :key="index_service">
                <div class="col-md-1 div-table div-table-value">
                    {{ index_service + 1 }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ service.serviceName }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ service.description }}
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ service.quantity }}
                </div>
                <div class="col-md-1 div-table div-table-value">
                    {{singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(service.totalAmountTzs - service.billVatAmountTzs)) : putComma(amountDecimalPlaces(service.billAmount)) }}
                </div>
                <div class="col-md-1 div-table div-table-value">
                    {{singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(service.billVatAmountTzs)) : putComma(amountDecimalPlaces(service.billVatAmount)) }}
                </div>
                <div class="col-md-1 div-table div-table-value">
                    {{singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(service.totalAmountTzs)) : putComma(amountDecimalPlaces(service.totalAmount)) }}
                </div>
                </div>

                <div class="row div-table-row">
                <div class="col-md-7 div-table div-table-value text-center">
                    <b>TOTAL</b>
                </div>
                <div class="col-md-2 div-table div-table-value">
                    {{ singleRequest.billItems[0].quantity }}
                </div>
                <div class="col-md-1 div-table div-table-value">
                    {{ singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(singleRequest.totalBillAmountTzs)): putComma(amountDecimalPlaces(singleRequest.totalBillAmount))}}
                </div>
                <div class="col-md-1 div-table div-table-value">
                    {{ singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(singleRequest.billVatAmountTzs)): putComma(amountDecimalPlaces(singleRequest.billVatAmount))}}
                </div>
                <div class="col-md-1 div-table div-table-value">
                    {{ singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(singleRequest.billVatedAmountTzs)): putComma(amountDecimalPlaces(singleRequest.totalVatedAmount))}}
                </div>
                </div>
            </div>
            </div>

            <br v-if="singleRequest.bulkBill !== null && singleRequest.bulk">

            <div
            class="col-md-12"
            v-if="singleRequest.bulkBill !== null && singleRequest.bulk"
            >
            <div class="legend">
                <b><h6 class="legend-title">Bulk Bills</h6></b>
                <div class="row div-table-row mb-3">
                <div class="col-md-12">
                    <div class="col-md-12 text-center">
                    <pulse-loader
                        :loading="removeBulkBillingLoader"
                        :color="color"
                        :size="size"
                    />
                    </div>

                    <div class="float-right" v-if="itemToRemoveList.length !== 0">
                    <button class="btn-danger btn-fill custom-btn" @click="removeBulkItem">Remove from Bulk Bill</button>
                    </div>
                </div>
                </div>

                <div class="bulk-bill-scroll">
                    <div class="row div-table-row" v-for="(service , index_service) in singleRequest.bulkBill" :key="index_service">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-2 div-table div-table-title">
                                    Bill Batch Number
                                </div>
                                <div class="col-md-1 div-table div-table-title">
                                    Exchange Rate
                                </div>
                                <div class="col-md-2 div-table div-table-title">
                                    TANSAD Number
                                </div>
                                <div class="col-md-2 div-table div-table-title">
                                    VAT
                                </div>

                                <div class="col-md-2 div-table div-table-title">
                                    Amount
                                </div>
                                <div class="col-md-2 div-table div-table-title">
                                    Vated Amount
                                </div>
                                <div class="col-md-1 div-table div-table-title text-center">
                                    Action
                                </div>
                            </div>
                        </div>
                        <div class="col md-12 even-color">
                            <div class="row">
                                <div class="col-md-2 div-table div-table-value">
                                    {{ service.billNo }}
                                </div>
                                <div class="col-md-1 div-table div-table-value">
                                    {{ putComma(service.exchangeRate) }}
                                </div>
                                <div class="col-md-2 div-table div-table-value">
                                    {{ service.tansaNo }}
                                </div>
                                <div class="col-md-2 div-table div-table-value">
                                    {{ putComma(service.billVatAmountTzs) }}
                                </div>
                                <div class="col-md-2 div-table div-table-value">
                                    {{ putComma(service.totalBillAmountTzs) }}
                                </div>
                                <div class="col-md-2 div-table div-table-value">
                                    {{ putComma(service.billVatedAmountTzs) }}
                                </div>
                                <div class="col-md-1 div-table div-table-value text-center">
                                    <button @click="goToApplication(service.documentId)" class="btn btn-linen btn-linen-fill mr-2 custom-btn-in-table">
                                        view application
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                            <div class="col-md-1 div-table div-table-title">
                                Item #
                            </div>
                            <div class="col-md-6 div-table div-table-title">
                                Description
                            </div>
                            <div class="col-md-1 div-table div-table-title">
                                Quantity
                            </div>
                            <div class="col-md-2 div-table div-table-title">
                                Service Name
                            </div>

                            <div class="col-md-2 div-table div-table-title">
                                Total Amount (TZS) (VAT included)
                            </div>
                            </div>
                        </div>
                        <div class="col-md-12 odd-color"  v-for="(service2 , index_service2) in service.bulkBillItems" :key="index_service2">
                            <div class="row">
                                <div class="col-md-1 div-table div-table-value">
                                {{ index_service2 + 1}}
                                </div>
                                <div class="col-md-6 div-table div-table-value">
                                {{ service2.description }}
                                </div>
                                <div class="col-md-1 div-table div-table-value">
                                {{ service2.quantity }}
                                </div>
                                <div class="col-md-2 div-table div-table-value">
                                {{ service2.serviceName }}
                                </div>
                                <div class="col-md-2 div-table div-table-value">
                                {{ putComma(service2.totalAmountTzs) }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <br v-if="singleRequest.bulkBill === null && !singleRequest.bulk">

            <div class="col-md-12" v-if="singleRequest.bulkBill === null && !singleRequest.bulk && singleRequest.assessmentNoticeDto !== null">
            <div class="legend">
                <h6 class="legend-title">
                Assessment Notice Details
                </h6>

                <!-- <div class="legend">
                <h6 class="legend-title">
                    Container
                </h6>

                <div class="legend">
                    <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                        Size
                    </div>
                    <div class="col-md-3 div-table div-table-value" />
                    <div class="col-md-3 div-table div-table-title">
                        Number
                    </div>
                    <div class="col-md-3 div-table div-table-value" />
                    </div>

                    <br>

                    <div class="row div-table-row">
                    <div class="col-md-6 div-table div-table-title">
                        <b>Total</b>
                    </div>
                    <div class="col-md-6 div-table div-table-value" />
                    </div>
                </div>
                </div> -->

                <!-- <br> -->

                <!-- <div class="legend">
                <h6 class="legend-title">
                    Non Container
                </h6> -->

                <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                    Custom Value
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                    {{
                        singleRequest.assessmentNoticeDto.customValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") + " TZS" }}
                    </div>
                    <div class="col-md-3 div-table div-table-title">
                    Operation Type
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.assessmentNoticeDto.operationType === 'IMP' ? 'Import' : 'Export'}}
                    </div>
                </div>

                <br>

                <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                    Cargo Tonnage
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                    {{ putComma(singleRequest.assessmentNoticeDto.cargoTonnage) }}
                    </div>
                    <div class="col-md-3 div-table div-table-title">
                    Cargo Volume
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                    {{ putComma(singleRequest.assessmentNoticeDto.cargoVolume) }}
                    </div>
                </div>

                <br>

                <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                    Cargo CBM
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                    {{ putComma(singleRequest.assessmentNoticeDto.cargoCbm) }}
                    </div>
                    <div class="col-md-3 div-table div-table-title">
                    TANSAD Number - ( Invoice Number )
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.assessmentNoticeDto.tansadNo }} - ( {{ singleRequest.assessmentNoticeDto.invoiceNumber }} )
                    </div>
                </div>

                <br>

                <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                    Quantity
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                    {{ putComma(singleRequest.assessmentNoticeDto.quantity) }}
                    </div>
                    <div class="col-md-3 div-table div-table-title">
                    Tariff Used
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.assessmentNoticeDto.tariffItemName }}
                    </div>
                </div>
                <!-- </div> -->
            </div>

            <!-- <div class="legend">
                <h6 class="legend-title">
                Associated Attachment
                </h6>

                <div>
                <attachments :request-id="singleRequest.documentId"></attachments>
                </div>
            </div> -->
            </div>
        </div>
        <div
            class="row text-right"
            v-if="!thisFirstRequestLoader"
        >
            <div class="col-md-8 pt-3">
            </div>
            <div
            class="col-md-4"
            v-if="singleRequest.company !== null"
            >
            <button
                class="btn btn-default btn-fill custom-btn"
                @click.prevent="goBack"
            > Back
            </button>
            </div>
        </div>
        </card>
        <global-modal v-if="globalLoader">
        <div slot="globalLoader">
            <div class="row text-center">
            <div class="col-md-12">
                Preparing requested file, Please wait
            </div>
            <div class="col-md-12">
                <br>
            </div>
            <div class="col-md-12">
                <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                />
            </div>
            </div>
        </div>
        </global-modal>
        <global-modal v-if="myLoader">
        <div slot="globalLoader">
            <div class="row text-center">
            <div class="col-md-12">
                Please wait while system is requesting GePG control number
            </div>
            <div class="col-md-12">
                <br>
            </div>
            <div class="col-md-12">
                <pulse-loader :loading="loading" :color="color" :size="size" />
            </div>
            </div>
        </div>
        </global-modal>
        <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer" @downloadPdf="downloadPdf"></pdf-viewer>
    </div>
</template>

<script>

import API_REVENUE from '../../../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader'
import { mapActions, mapGetters } from 'vuex';
import CfaAttachments from '../../revenue/clearingAndForwarding/bills/cfaAttachments'
import PdfViewer from '../../../components/pdfViewer.vue'

export default {

    name: 'MyBillDetails',

    components: {
        attachments: CfaAttachments,
        PulseLoader,
        GlobalModal,
        PdfViewer

    },

    created () {

        this.finalUserPermissions = JSON.parse(
        localStorage.getItem('browser-local-permits')
        )

        if (this.$route.params.billId) {

        this.$store.dispatch('pagenames/currentPageName', 'Bill Details')

        this.loadThisRequestBillOnCreated(this.$route.params.billId)
        this.loadBulkBillItems();

        }

    },

    computed: {

        ...mapGetters('global', [

            'getGlobalLoader'

        ])

    },

    data () {

        return {

        finalUserPermissions: [],

        color: 'green',
        size: '12px',
        loading: true,
        removeBulkBillingLoader:false,
        singleRequest: {},

        myLoader: false,
        
        loadingBulkBillingItemId:0,
        selectedBillDescription:"",
        itemToRemoveList:[],
        thisFirstRequestLoader: false,
        applicationStatus: '',

        approveBillLoader: false,

        globalLoader: false,
        globalLoaderName: '',

        showModal: false,
        showModalBillDescription: false,
        cancelBillModal: false,
        cancelCharge: true,
        selectedBillId: '',
        customerNameForCancel: '',
        isCancelReason: false,
        cancelReason: '',
        cancelBillLoader: false,
        relativeControlNumberForCancel: '',
        cancelledMessage: '',
        selectedBillIdToEditComment:"",
        myBillId: '',
        myControlNo: '',
        openExtendedDays: false,
        extendedDays: '',
        todayDate: new Date().toJSON().slice(0, 10),
        extendedDaysError: false,

        billTransactions: [],
        showLogs: false,
        transactionLoader: false,
        bulkBillItems:[],

        scr: '',
        showPdfViewer: false,
        isMinioFile: 0

        }

    },

    methods: {

        ...mapActions('global', [

        'loadFileFromMinio'

        ]),

        downloadPdf () {

            if(this.isMinioFile === 1) { // MINIO

                this.openPdfViewer(this.singleRequest.assessmentNoticeDto.assessmentNoticeDocUrl, 'ASSESSMENT_FOR_APPLICATION_TANSAD_NUMBER_' + this.singleRequest.tansadno)

            } else if (this.isMinioFile === 2) { // MATRIX INVOICE

                this.openMatrixInvoicePdfViewer(this.singleRequest.billId)

            } else if (this.isMinioFile === 3) { // INVOICE

                this.openInvoicePdfViewer(this.singleRequest.billId)

            }  else { // RECEIPT

                this.openReceiptPdfViewer(this.singleRequest.billId)

            }

        },

        loadParameters (fileUrl, fileName) {

            return new Promise((resolve) => {

                this.$store.dispatch('global/fileUrling', fileUrl)
                this.$store.dispatch('global/fileNaming', fileName)

                resolve()
            })

        },

        loadBulkBillItems() {

        this.removeBulkBillingLoader=true;
        API_REVENUE.getBulkBillItems(this.$route.params.billId).then((resp) => {

            this.removeBulkBillingLoader=false;
            this.bulkBillItems=resp.data.data;

        }).catch(() => {

            this.removeBulkBillingLoader=false;
            this.loadNotification('error', 4000, 'Request Message',
            'Bulk Bill Items Not loaded, please contact system Administrator',
            'response', 1000 , true  , true);
        })

        },

        loadThisRequestBillOnCreated (data) {

            this.thisFirstRequestLoader = true

            API_REVENUE.getIndividualBill(data).then(
            response => {

                if (response.data.status) {

                this.singleRequest = response.data.data
                this.applicationStatus = this.getApplicationStatus(this.singleRequest.status, 1)
                this.thisFirstRequestLoader = false
                this.cancelledMessage = this.singleRequest.description

                } else {

                }

            }

            ).catch(() => {

            this.thisFirstRequestLoader = false

            })

        },

        openInvoicePdfViewer (billId, action) {

            this.isMinioFile = 3

            this.getInvoice(billId, action).then(() => {

                this.showPdfViewer = true

            }).catch(() => {})

        },

        openReceiptPdfViewer (billId, action) {

            this.isMinioFile = 4

            this.getReceipt(billId, action).then(() => {

                this.showPdfViewer = true

            }).catch(() => {})

        },

        closePdfViewer () {

            this.showPdfViewer = false
            this.isMinioFile = 0
            this.scr = ''

        },

        getInvoice (billId, action = 'download') {

            return new Promise((resolve, reject) => {

                this.globalLoader = true
                this.globalLoaderName = 'Invoice'

                API_REVENUE.downloadBillInvoice(billId).then(
                    response => {

                    if (response.status === 200) {

                        if(action === 'download') {

                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                            const link = document.createElement('a')
                            link.href = url
                            link.setAttribute('download', 'INVOICE_FOR_APPLICATION_WITH_TANSAD_NUMBER_' + this.singleRequest.tansadno + '.pdf') // or any other extension
                            document.body.appendChild(link)
                            link.click()
                            this.globalLoader = false
                            this.loadNotification('success', 4000, 'Request Message',
                            'Matrix printer Invoice has been successifully downloaded, please have a look at it',
                            'response', 1000 , true  , true);

                        } else {

                            this.scr = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                            this.globalLoader = false

                        }

                        resolve()

                    } else {

                        this.globalLoader = false
                        this.loadNotification('error', 4000, 'Request Message',
                        'No content found for requested matrix printer invoice, please try with different options',
                        'response', 1000 , true  , true);

                    }

                    }).catch(() => {

                    this.globalLoader = false
                    this.loadNotification('error', 4000, 'Request Message',
                    'No content found for requested matrix printer invoice, please try with different options',
                    'response', 1000 , true  , true);

                })

            })
        
        },

        getReceipt (billId, action = 'download') {

            return new Promise((resolve, reject) => {

                this.globalLoader = true
                this.globalLoaderName = 'Receipt'

                API_REVENUE.getReceipt(billId).then(
                    response => {

                        if(action === 'download') {

                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                            const link = document.createElement('a')
                            link.href = url
                            link.setAttribute('download', 'RECEIPT_FOR_APPLICATION_WITH_TANSAD_NUMBER_' + this.singleRequest.tansadno + '.pdf') // or any other extension
                            document.body.appendChild(link)
                            link.click()
                            this.myloader = false
                            this.globalLoader = false

                        } else {

                            this.scr = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                            this.globalLoader = false

                        }

                        resolve()

                    }
                ).catch(e => {
                    
                    this.myloader = false
                    this.globalLoader = false
                    this.loadNotification('error', 4000, 'Request Message',
                    'No content found for requested receipt, please try with different options',
                    'response', 1000 , true  , true);

                })

            })

        },

        goToApplication (id) {

            document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
            this.$router.push({name: 'ApplicationPage', params: {requestId: id}})
            // this.$router.push('/clearing-and-forwarding/' + this.requestType + '-' + this.$route.params.status + '-application-view/' + data.requestId);

        },

        goBack () {

            this.$router.go(-1)


        }

    }

}
</script>

<style scoped>

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .bill-data-550 {
        width: 550px;
    }

    .bill-data-265 {
        max-width: 265px;
    }

    .bill-data-269 {
        width: 276px;
    }

    .notice-table-label {
        width: 250px;
        background-color: #99badd;
        font-weight: normal;
    }

    .div-table-row {
        padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }

    .custom-table, .custom-table th, .custom-table td {
        border: 1px solid #DCDCDC;
        border-collapse: collapse;
    }

    .custom-table th, .custom-table td {
        padding: 8px;
        text-align: left;
    }

    .cancel-charge:hover {
        cursor: pointer !important;
    }

    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    .text-link {
        color: blue;
    }

    .text-link:hover {
        color: #d59a18;
        cursor: pointer;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    .even-color {
        background-color: #f5f5f5;
    }

    .odd-color {
        background-color: #f0f8ff;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }

    .custom-btn-in-table {
        /* margin-top: 2px !important; */
        /* margin-bottom: 2px !important; */
        padding: 1px 6px 2px 6px !important;
        font-size: 11px !important;
    }

    .btn-black:hover {
        border-color: #000000;
        color: #000000;
        background-color: #ffffff;
    }

    .btn-black {
        border-color: #808080;
        color: #808080;
    }

    .btn-black-fill {
        background-color: #808080;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-purple:hover {
        border-color: #800080;
        color: #800080;
        background-color: #ffffff;
    }

    .btn-purple {
        border-color: #9932cc;
        color: #9932cc;
    }

    .btn-purple-fill {
        background-color: #9932cc;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-blue:hover {
        border-color: #0000ff;
        color: #0000ff;
        background-color: #ffffff;
    }

    .btn-blue {
        border-color: #4169e1;
        color: #4169e1;
    }

    .btn-blue-fill {
        background-color: #4169e1;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-yellow:hover {
        border-color: #8b4513;
        color: #8b4513;
        background-color: #ffffff;
    }

    .btn-yellow {
        border-color: #a0522d;
        color: #a0522d;
    }

    .btn-yellow-fill {
        background-color: #a0522d;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-olive:hover {
        border-color: #4B0082;
        color: #4B0082;
        background-color: #ffffff;
    }

    .btn-olive {
        border-color: #6A5ACD;
        color: #6A5ACD;
    }

    .btn-olive-fill {
        background-color: #6A5ACD;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-linen:hover {
        border-color: #D2691E;
        color: #D2691E;
        background-color: #ffffff;
    }

    .btn-linen {
        border-color: rgba(210, 105, 30, .5);
        color: rgba(210, 105, 30, .5);
    }

    .btn-linen-fill {
        background-color: rgb(210, 105, 30);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-danger-custom:hover {
        border-color: #ff0000;
        color: #ff0000;
        background-color: #ffffff;
    }

    .btn-danger-custom {
        border-color: rgba(255, 0, 0, .5);
        color: rgba(255, 0, 0, .5);
    }

    .btn-danger-custom-fill {
        background-color: rgba(255, 0, 0, .5);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-success-custom:hover {
        border-color: #008000;
        color: #008000;
        background-color: #ffffff;
    }

    .btn-success-custom {
        border-color: rgba(0, 128, 0, .5);
        color: rgba(0, 128, 0, .5);
    }

    .btn-success-custom-fill {
        background-color: rgba(0, 128, 0, .8);
        opacity: 1;
        color: #FFFFFF;
    }

    .cancel-message:hover {
        cursor: pointer;
    }

    .tooltip-text {
        visibility: hidden;
        width: 100%;
        background-color: black;
        color: #fff;
        border-radius: 6px;
        padding: 5px 2px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
    }

    .tooltip-text::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

    .tooltip-pipe:hover .tooltip-text {
        visibility: visible;
        cursor: help;
    }

    .alert-success {
        padding-top:10px;
        padding-bottom: 10px;
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    .alert-danger {
        padding-top:10px;
        padding-bottom: 10px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    .bulk-bill-scroll {
        max-height: 618px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

</style>
