<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>

      <div class="text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Exchange Rate List
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            v-if="finalUserPermissions.includes('EXCHANGE_CONFIG_POST')"
            @click.prevent="openModal(null , null , null, null , null , actionButton = 'AddModal')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>
      <hr>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Exchange Rate
            </th>
            <th scope="col">
              Exchange Rate Date
            </th>
            <th scope="col">
              Status
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getExchangeRate"
            :key="list.exchangeRateId"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>
              {{ list.exchangeRate }} {{ list.exchangeCurrency }}
            </td>
            <td>
              {{ dateTimeToHuman(list.exchangeRateDate) }}
            </td>
            <td
              v-if="list.status"
              class="text-success"
            >
              Active
            </td>
            <td
              v-else
              class="text-muted"
            >
              Not Active
            </td>
            <td class="special-limit">
              <div
                class="all-fa-icons fa-icons-space edit-fa-icon"
                v-if="finalUserPermissions.includes('EXCHANGE_CONFIG_PUT')"
                @click.prevent="openModal(list.exchangeRateId , list.exchangeRateDate, list.exchangeRate ,
                                          list.exchangeCurrency, list.status, actionButton = 'EditModal')"
                title="edit"
              >
                <font-awesome-icon icon="edit" />
              </div>
              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                v-if="finalUserPermissions.includes('EXCHANGE_CONFIG_{EXCHANGERATEID}_GET')"
                @click.prevent="openModal(list.exchangeRateId , list.exchangeRateDate , null , null ,
                                          null , actionButton = 'ShowModal')"
                title="view"
              >
                <font-awesome-icon icon="eye" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Exchange Rate <b>{{ exchangeRate }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register Cargo type
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Exchange Rate: {{ data.exchangeRate }}
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editExchangeRate"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="date"
                  label="Exchange Date"
                  placeholder="Exchange Date"
                  v-model="exchangeRateDate"
                  value="exchangeRate"
                  class="special-bottom"
                />
                <div v-if="exchangeRateDateError">
                  <div
                    class="error"
                    v-if="!$v.exchangeRateDate.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Exchange Rate"
                  placeholder="Exchange Rate"
                  v-model="exchangeRate"
                  class="special-bottom"
                />
                <div v-if="exchangeRateError">
                  <div
                    class="error"
                    v-if="!$v.exchangeRate.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <label>Currency</label>
                <select
                  id="myselect1"
                  name="myselect"
                  class="form-control"
                  v-model="exchangeCurrency"
                >
                  <option
                    disabled
                    value=""
                  >
                    Select Currency
                  </option>
                  <option
                    value="USD"
                    :selected="exchangeCurrency === 'USD'"
                  >
                    USD
                  </option>
                  <option
                    value="POUND"
                    :selected="exchangeCurrency === 'POUND'"
                  >
                    POUND
                  </option>
                  <option
                    value="EURO"
                    :selected="exchangeCurrency === 'EURO'"
                  >
                    EURO
                  </option>
                </select>
                <div v-if="exchangeCurrencyError">
                  <div
                    class="error"
                    v-if="!$v.exchangeCurrency.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="enabled24"
                    value="false"
                    name="enabled"
                    v-model="exchangeRateStatus"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="enabled"
                  >Not Active</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="enabled22"
                    value="true"
                    name="enabled"
                    v-model="exchangeRateStatus"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="enabled2"
                  >Active</label>
                </div>
                <div v-if="exchangeStatusError">
                  <div
                    class="error"
                    v-if="!$v.exchangeRateStatus.required"
                  >
                    Select one of the two statuses
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveExchangeRate"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="date"
                  label="Exchange Date"
                  placeholder="Exchange Date"
                  v-model="exchangerateObject.exchangeRateDate"
                  class="special-bottom"
                />
                <div v-if="exchangeRateDateError">
                  <div
                    class="error"
                    v-if="!$v.exchangerateObject.exchangeRateDate.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Exchange Rate"
                  placeholder="Exchange Rate"
                  v-model="exchangerateObject.exchangeRate"
                  class="special-bottom"
                />
                <div v-if="exchangeRateError">
                  <div
                    class="error"
                    v-if="!$v.exchangerateObject.exchangeRate.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <label for="myselect">Select Currency</label>
                <select
                  id="myselect"
                  name="myselect"
                  class="form-control"
                  v-model="exchangerateObject.exchangeCurrency"
                >
                  <option
                    disabled
                    value=""
                  >
                    Select Currency
                  </option>
                  <option value="USD">
                    USD
                  </option>
                  <option value="POUND">
                    POUND
                  </option>
                  <option value="EURO">
                    EURO
                  </option>
                </select>
                <div v-if="exchangeCurrencyError">
                  <div
                    class="error"
                    v-if="!$v.exchangerateObject.exchangeCurrency.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="enabled"
                    value="false"
                    name="enabled"
                    v-model="exchangerateObject.status"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="enabled"
                  >Not Active</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="enabled2"
                    value="true"
                    name="enabled"
                    v-model="exchangerateObject.status"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="enabled2"
                  >Active</label>
                </div>
                <div v-if="exchangeStatusError">
                  <div
                    class="error"
                    v-if="!$v.exchangerateObject.status.required"
                  >
                    Select one of the two statuses
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    <b>Exchange Rate</b>
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.exchangeRate }}
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    <b>Exchange date</b>
                  </div>
                  <div class="col-md-6 text-value">
                    {{ dateTimeToHuman(exchangeRateDate) }}
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    <b>Exchange Currency</b>
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.exchangeCurrency }}
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    <b>Status</b>
                  </div>
                  <div
                    v-if="data.status"
                    class="text-success col-md-6 text-value"
                  >
                    Active
                  </div>
                  <div
                    v-else
                    class="text-muted col-md-6 text-value"
                  >
                    Not Active
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API_REVENUE from '../../../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'

export default {

  name: 'ExchangeRateList',

  components: {

    PulseLoader,
    Modal

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Exchange Rates List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadExchangeRate()
  },

  data () {
    return {

      array: [],
      getExchangeRate: [],
      finalUserPermissions: [],

      data: {},

      color: 'green',
      size: '15px',
      loading: true,

      SuccessMessage: '',
      ErrorMessage: '',
      exchangeRate: '',
      exchangeCurrency: '',
      exchangeRateDate: '',
      exchangeRateId: '',
      activeExchangeRate: '',
      exchangeRateStatus: '',

      error: '',
      exchangerateObject: {

        exchangeRate: '',
        exchangeCurrency: '',
        exchangeRateDate: '',
        status: ''

      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,
      vesselIdError: false,
      exchangeStatusError: false,

      exchangeRateError: false,
      exchangeCurrencyError: false,
      exchangeRateDateError: false

    }
  },

  validations: {

    exchangerateObject: {
      exchangeRate: { required },
      exchangeCurrency: { required },
      exchangeRateDate: { required },
      status: { required }

    },

    exchangeRate: { required },
    exchangeCurrency: { required },
    exchangeRateDate: { required }

  },

  computed: {

    ...mapGetters([

      'getExchangeRateBeforeEdit'

    ])

  },

  methods: {

    loadExchangeRate () {
      this.myloader = true

      API_REVENUE.getExchangeRate().then(
        response => {
          if (response.data.status) {
            this.getExchangeRate = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not Added'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadSingleExchangeRate (data) {
      API_REVENUE.getIndividualExchangeRate(data).then(
        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not Found'
          }
        }
      ).catch(e => {
        // this.state.errors.push(e)
      })
    },

    saveExchangeRate () {
      if (this.$v.exchangerateObject.exchangeCurrency.$invalid || this.$v.exchangerateObject.exchangeRate.$invalid ||
          this.$v.exchangerateObject.exchangeRateDate.$invalid || this.$v.exchangerateObject.status.$invalid) {
        if (this.$v.exchangerateObject.exchangeCurrency.$invalid) {
          this.exchangeCurrencyError = true
        }
        if (this.$v.exchangerateObject.exchangeRate.$invalid) {
          this.exchangeRateError = true
        }
        if (this.$v.exchangerateObject.exchangeRateDate.$invalid) {
          this.exchangeRateDateError = true
        }
        if (this.$v.exchangerateObject.status.$invalid) {
          this.exchangeStatusError = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API_REVENUE.postExchangeRate(JSON.stringify(this.exchangerateObject)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = ' [ ' + response.data.data.exchangeRate + ' ' +
                  response.data.data.exchangeCurrency + ' ] Exchange Rate Saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadExchangeRate()
              this.editMyLoader = false
              this.exchangerateObject.exchangeRate = ''
              this.exchangerateObject.exchangeCurrency = ''
              this.exchangerateObject.exchangeRateDate = ''
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ', Record not Added'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.exchangerateObject.exchangeRate = ''
          this.closeModal()
        })

        // }, 2000);
      }
    },

    deleteExchangeRate (data, name, currency) {
      this.$store.dispatch('commitExchangeRateBeforeEdit', name + ' ' + currency)

      this.$dialog.confirm('If you delete Exchange Rate [ ' + name + ' ' + currency + " ], it'll be gone forever.", {

        loader: true
      })
        .then((dialog) => {
          // setTimeout(() => {

          API_REVENUE.deleteExchangeRate(data).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ' Exchange Rate [ ' + this.getExchangeRateBeforeEdit + ' ] has been deleted'
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                this.loadExchangeRate()
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Record not Deleted'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
          })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editExchangeRate () {
      if (this.$v.exchangeCurrency.$invalid || this.$v.exchangeRate.$invalid ||
          this.$v.exchangeRateDate.$invalid) {
        if (this.$v.exchangeCurrency.$invalid) {
          this.exchangeCurrencyError = true
        }
        if (this.$v.exchangeRate.$invalid) {
          this.exchangeRateError = true
        }
        if (this.$v.exchangeRateDate.$invalid) {
          this.exchangeRateDateError = true
        }
      } else {
        this.editMyLoader = true

        this.array =
            {
              exchangeCurrency: this.exchangeCurrency,
              exchangeRate: this.exchangeRate,
              exchangeRateDate: this.exchangeRateDate,
              exchangeRateId: this.exchangeRateId,
              status: this.exchangeRateStatus
            }

        // setTimeout(() => {

        API_REVENUE.updateExchangeRate(JSON.stringify(this.array)).then(
          response => {
            if (response.data.status) {
              this.SuccessAlert = true
              this.SuccessMessage = ', Record edited'
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadExchangeRate()
              this.editMyLoader = false
            } else {

            }
          }
        ).catch(e => {
          this.errors = e
          this.editMyLoader = false
          this.ErrorMessage = ' Record not Edited'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })

        // }, 2000);
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (exchangeRateId = null, exchangeRateDate = null, exchangeRate = null, exchangeCurrency =
    null, status = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.exchangeRate = exchangeRate
        this.exchangeRateDate = exchangeRateDate
        this.exchangeRateId = exchangeRateId
        this.exchangeCurrency = exchangeCurrency
        this.exchangeRateStatus = status
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
      } else {
        this.loadSingleExchangeRate(exchangeRateId)
        this.exchangeRateDate = exchangeRateDate
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false

      this.data = {}

      this.exchangeRate = ''
      this.exchangeRateDate = ''
      this.exchangeRateId = ''
      this.exchangeCurrency = ''
      this.exchangeRateStatus = ''

      this.exchangeRateError = false
      this.exchangeCurrencyError = false
      this.exchangeRateDateError = false
    }

  }

}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
