<template>
    <div :key="this.$route.params.status">
        <card>
        <data-table
            :table-headers="tableHeaders"
            :table-data="filteredRequests"
            :table-data-keys="tableDataKeys"
            :pageSizeProp="pageSize"
            :pageNo="pageNo"
            :pageNumber="pageNumber"
            :allCustomers="allCustomers"
            :getRegimes="getRegimes"
            :is-hovered="isHovered"
            :my-loader="myLoader"
            :page-count="pageCount"
            :reload-all-request="reloadAllRequest"
            :my-first-loader="myFirstLoader"
            :no-data-found="noDataFound"
            :totalElements="totalElements"
            :totalPages="totalPages"
            :search-parameter-prop="searchParameter"
            :permissions="[ {key: 'searchPermission', value: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'} ]"
            :loading-title="'Please wait while system is loading accepted applications'"
            :has-filter="true"
            :hasManageButton="true"
            :whichModuleAndType="whichModuleAndType"
            @showToolTip="showToolTip"
            @hideToolTip="hideToolTip"
            @onChange="onChange"
            @goToApplication="goToApplication"
            @search="searchGeneralChargeParams"
            @loadAllPageable="loadPageableRequestOnCreated"
            @firstPage="firstPage"
            @prevPage="prevPage"
            @nextPage="nextPage"
            @lastPage="lastPage"
            @goToPage="goToApplication"
        >
            <div slot = "pagination">
            <paginate
                v-model="getIsPage"
                :page-count=parseInt(totalPages)
                :page-range="3"
                :margin-pages="2"
                :click-handler="goToPage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
            </paginate>
            </div>
        </data-table>
        </card>
        <global-modal v-if="myLoader">
        <div slot="globalLoader">
            <div class="row text-center">
            <div class="col-md-12">
                Loading Applications, Please wait
            </div>
            <div class="col-md-12">
                <br>
            </div>
            <div class="col-md-12">
                <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                />
            </div>
            </div>
        </div>
        </global-modal>
    </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../components/globalLoader'
import Modal from '../../components/Inputs/Modal'
import additionalFields from "./subComponents/additionalFields";
import API from "../../api";

export default {

name: 'CfaRequests',

components: {
    PulseLoader,
    GlobalModal,
    DataTable: () => import('../../components/DataTable/dataTable'),
    Modal,
    additionalFields
},

created () {

    this.loadData();

},

computed: {

    ...mapGetters('notifications', [
    'getNewAppPage'
    ]),

    ...mapGetters('clearingrequests', [

    'getRequestList',
    'getPageCount',
    'getMyFirstLoader',
    'getNoDataFound',
    'getReloadAllRequest',
    'getMyLoader',
    'getSearchParameter',
    'getHasSearchedVariable',
    'getPageNo',
    'getPageSize',
    'getTotalElement',
    'getTotalPage',
    'getPageNumber',
    'getIsFirst',
    'getIsLast',

    ]),
    getIsPage: {

    get: function () {
        return this.isPage
    },
    set: function (newValue) {
        this.isPage = newValue;
    }

    }

},

data () {
    return {

    pageNo: 0,
    pageSize: 15,
    pageCount: 1,
    sortBy: 'requestDate',

    color: 'green',
    size: '12px',
    loading: true,

    reloadAllRequest: false,
    noDataFound: false,
    searchParameter: '',
    totalElements: "",
    totalPages: '',
    isFirst: false,
    isLast: false,
    pageNumber: '',
    pdfFormatError: false,
    loadAllHeaderLoader: false,
    allAttachmentVerified: false,
    myLoader: false,
    myFirstLoader: false,
    whichModuleAndType: 'cfaApplication',
    status: 'APPROVED',
    requests: [],
    requestFlag: 'IMP',
    filteredRequests:[],
    isHovered: [],
    newParams: '',
    isPage: 0,
    pageNoPart: 0,

    ifThereIsParam: '',

    finalUserPermissions: [],

    tableHeaders: ['BL/Airway Bill/RCN', 'Customer Name', 'Requested Date'],
    tableDataKeys: ['blNumber', 'customer', 'requestDate'],

    showMarkDuplicate: false,
    getRegimes:[],
    allCustomers: [],


    }
},

validations: {

    searchParameter: { required }

},
watch: {

    '$route.path': function(val, oldVal){
    localStorage.removeItem("search-params");
    localStorage.removeItem("cfa-customer");
    localStorage.removeItem("page-no");
    localStorage.removeItem("page-size");
    this.loadData();
    }
},

methods: {

    ...mapActions('clearingrequests', [

        'loadClearingAndForwardingApplicationsOnCreated',
        'searchWithGeneralChargeParams',
        'searchClearingAndForwardingApplications',
        'loadAllClearingAndForwardingApplications',
        'loadClearingAndForwardingApplications',
        'markDuplicateApplication',
        'pageSizing',
        'pageNumbering'

    ]),


    loadData(){

    let type=`${this.$route.params.requestType.charAt(0).toUpperCase() + this.$route.params.requestType.slice(1) }`;
    let requestType=type;
    let status=`${this.$route.params.status.charAt(0).toUpperCase() +this.$route.params.status.slice(1) }`;
    let page=status + " " + requestType + " " + "Applications";
    this.$store.dispatch('pagenames/currentPageName', page);

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

    //load all request but check local storage first
    this.loadRequest();
    this.loadAllCustomers();
    this.loadRegimes();

    },

    loadRequest() {

    //check if any items has been serched before
    if (localStorage.getItem("search-params") !== null) {

        //set params to those stored in local storage
        this.newParams = "";
        this.newParams = localStorage.getItem("search-params");

        //get the size of page that was previously set eg size=15 so get 15
        let secondPart = this.newParams.split("size=")[1];
        //get the page number that was before eg page=20 so get 20
        this.pageNoPart = parseInt(secondPart.split("page=")[1]);
        //set the current page number eg if previously you were on page 20 get that page plus one because pages start from 0
        this.isPage = this.pageNoPart + 1;

        //this.searchGeneralChargeParams(this.newParams) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page with selected page bug
        this.searchGeneralChargeParams(this.newParams, this.pageNoPart) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page without selected page bug

    } else {

        //if search params is null check if page number and page size is available in local storage
        if (localStorage.getItem("page-no") !== null && localStorage.getItem("page-size") !== null) {

        //get page number and page size from local storage and set in local storage so that the page ccan go to this page number and have this page size
        this.$store.dispatch("clearingrequests/pageSizing", parseInt(localStorage.getItem("page-size")));
        this.$store.dispatch("clearingrequests/pageNumbering", parseInt(localStorage.getItem("page-no")));

        //load data passing page number and page size
        this.loadPageableRequestOnCreated(parseInt(localStorage.getItem("page-no")) , parseInt(localStorage.getItem("page-size")))
        this.isPage = parseInt(localStorage.getItem("page-no")) + 1

        } else {

        this.$store.dispatch("clearingrequests/pageSizing", this.pageSize);
        this.$store.dispatch("clearingrequests/pageNumbering", this.pageNo);

        this.loadPageableRequestOnCreated(this.pageNo , this.pageSize);
        this.isPage = this.pageNo + 1;

        }

        this.$store.dispatch("clearingrequests/hasSearchngParams", false);
        this.newParams = "";
        this.pageNoPart = 0;

    }

    this.filteredRequests = []

    },


    goToPage(page) {

    if(page > 0) {page = page - 1;}
    this.isPage = page + 1;

    this.pageNo = page;

    if (this.getHasSearchedVariable) {

        let firstPart = this.newParams.split("page=")[0];
        this.newParams = "";
        this.newParams = firstPart + '&page=' + this.pageNo;
        this.pageNoPart = this.isPage;
        this.searchGeneralChargeParams(this.newParams, this.pageNoPart);
        this.filteredRequests = []
    } else {

        this.loadPageableRequestsAfterCreated(this.pageNo , this.pageSize);
        this.filteredRequests = [];
    }

    },

    loadSearchParameters(params, fromApplicationPageNo) {

    let fromApplicationPageNoOrNot;
    typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0;

    let firstPart = params.split("&page=")[0];
    let secondPart = firstPart.split("size=")[0];
    this.newParams = "";

    this.isPage = fromApplicationPageNo;
    if(this.$route.params.status.toUpperCase()!=='ALL'){
        this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + fromApplicationPageNoOrNot +`&applicationStatus=${this.$route.params.status.toUpperCase()}` + `&requestType=${this.$route.params.requestType}`;
    }else {
        this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + fromApplicationPageNoOrNot + `&requestType=${this.$route.params.requestType}`;
    }

    return new Promise((resolve) => {

        this.$store.dispatch("clearingrequests/pageSizing", this.pageSize);
        this.$store.dispatch("clearingrequests/pageNumbering", 0);
        this.$store.dispatch("clearingrequests/paramsSearching", this.newParams);

        resolve();

    })

    },

    loadParameters (pageNo , pageSize) {

    let status;
    if(this.$route.params.status.toUpperCase()!=='ALL'){
        status=`&applicationStatus=${this.$route.params.status.toUpperCase()}` + `&requestType=${this.$route.params.requestType}`;
    }else {
        status=`&requestType=${this.$route.params.requestType}`;
    }

    return new Promise((resolve) => {
        this.$store.dispatch("clearingrequests/pageSizing", pageSize);
        this.$store.dispatch("clearingrequests/pageNumbering", pageNo);
        this.$store.dispatch('clearingrequests/requestStatusing',status);
        resolve()
    })
    },

    sortingData(data) {

    for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {
        ...data[x],
        requestDate: this.dateTimeToHuman(data[x].requestDate)
        };

        this.filteredRequests.push(requestObject)

    }

    },

    setAllGetters(flag) {

    this.requests = this.getRequestList;
    flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
    flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';

    this.noDataFound = this.getNoDataFound;
    this.SuccessMessage = this.getSuccessMessage;
    this.ErrorMessage = this.getErrorMessage;
    this.ErrorMessage = this.getErrorMessage;
    this.ErrorAlert = this.getErrorAlert;
    this.SuccessAlert = this.getSuccessAlert;
    this.pageNo = this.getPageNo;
    this.pageSize = this.getPageSize;

    this.totalElements = this.getTotalElement;
    this.totalPages = this.getTotalPage;
    this.pageNumber = this.getPageNumber;
    this.isFirst = this.getIsFirst;
    this.isLast = this.getIsLast;

    console.log('--',this.requests);

    this.sortingData(this.requests)

    },

    loadRegimes () {

    API.getRegime().then(
        response => {
        if (response.data.status) {

            this.getRegimes = response.data.data

        } else {

        }
        }
    ).catch(e => {

    })
    },

    loadAllCustomers() {

    this.myloader = true;

    API.getCustomerAll()
        .then((response) => {
        if (response.data.status) {
            this.allCustomers = response.data.data;
        } else {
        }
        })
        .catch((e) => {
        this.ErrorMessage =
            ", TASAC Customers not loaded, please contact system administrator";
        this.SuccessAlert = false;
        this.ErrorAlert = true;
        this.SuccessMessage = "";
        this.SuccessAlert = false;
        this.myloader = false;
        });

    },


    loadPageableRequestOnCreated (pageNo , pageSize) {

    this.loadParameters(pageNo , pageSize).then(() => {
        this.loadClearingAndForwardingApplicationsOnCreated({}).then(() => {
        this.setAllGetters('onCreated');
        }).catch(() => {

        });

        this.myFirstLoader = this.getMyFirstLoader
    }).catch(() => {

    })
    },


    loadPageableRequestsAfterCreated(pageNo , pageSize) {

    this.loadParameters(pageNo , pageSize).then(() => {

        this.loadClearingAndForwardingApplicationsOnCreated({}).then(() => {

        this.setAllGetters('afterCreated');

        });

        this.myLoader = this.getMyLoader;

    }).catch(() => {


    });

    },


    searchGeneralChargeParams(params, fromApplicationPageNo) {

    this.newParams = params;

    this.filteredRequests = [];

    this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

        this.searchWithGeneralChargeParams({}).then(() => {

        this.setAllGetters('onCreated');

        }).catch(() => {


        });

        this.myFirstLoader = this.getMyFirstLoader;

    }).catch(() => {


    });

    },

    onChange(event) {

    this.$store.dispatch("clearingrequests/pageSizing", event);
    this.$store.dispatch("clearingrequests/pageNumbering", 0);
    localStorage.removeItem("page-no");
    localStorage.removeItem("page-size");
    this.isPage = 0;

    this.pageSize = this.getPageSize;
    this.pageNo = this.getPageNo;

    if (this.getHasSearchedVariable) {
        let firstPart = this.newParams.split("&page=")[0];
        let secondPart = firstPart.split("size=")[0];
        let thirdPart = firstPart.split("size=")[1];
        this.newParams = "";
        this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + 0;
        this.pageNoPart = 0;

        this.searchGeneralChargeParams(this.newParams , this.pageNoPart);
        this.filteredRequests = []

    } else {
        this.loadPageableRequestOnCreated(this.pageNo , this.pageSize);
        this.filteredRequests = []
    }

    },

    nextPage () {
    this.$store.dispatch('clearingrequests/pageNumbering', this.pageNo = this.pageNo + 1)
    this.pageNo = this.getPageNo

    if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
    } else {
        this.loadClearingAndForwardingApplications({}).then(() => {
        this.requests = this.getRequestList
        this.myLoader = this.getMyLoader
        this.pageCount = this.getPageCount
        })

        this.myLoader = this.getMyLoader
    }
    },

    prevPage () {
    this.$store.dispatch('clearingrequests/pageNumbering', this.pageNo = this.pageNo - 1)
    this.pageNo = this.getPageNo

    if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
    } else {
        this.loadClearingAndForwardingApplications({}).then(() => {
        this.requests = this.getRequestList
        this.myLoader = this.getMyLoader
        this.pageCount = this.getPageCount
        })

        this.myLoader = this.getMyLoader
    }
    },

    firstPage () {
    this.$store.dispatch('clearingrequests/pageNumbering', 0)
    this.pageNo = this.getPageNo

    if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
    } else {
        this.loadClearingAndForwardingApplications({}).then(() => {
        this.requests = this.getRequestList
        this.myLoader = this.getMyLoader
        this.pageCount = this.getPageCount
        })

        this.myLoader = this.getMyLoader
    }
    },

    lastPage () {
    this.pageCount = this.getPageCount

    this.$store.dispatch('clearingrequests/pageNumbering', this.pageNo = this.pageCount - 1)
    this.pageNo = this.getPageNo

    if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
    } else {
        this.loadClearingAndForwardingApplications({}).then(() => {
        this.requests = this.getRequestList
        this.myLoader = this.getMyLoader
        this.pageCount = this.getPageCount
        });

        this.myLoader = this.getMyLoader
    }
    },

    showToolTip (id) {
    this.isHovered = [id]
    },

    hideToolTip (id) {
    this.isHovered.splice(this.isHovered.indexOf(id), 1)
    },

    goToApplication (data) {
    document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';

    this.$router.push('/clearing-and-forwarding/application-view/' + data.requestId);

    if (this.getHasSearchedVariable) {

        localStorage.setItem("search-params", this.newParams);
        localStorage.setItem("cfa-customer", JSON.stringify(this.allCustomers));
        this.newParams = ""
    } else {
        localStorage.setItem("page-no", this.getPageNo);
        localStorage.setItem("page-size", this.getPageSize);
    }

    },

}

}
</script>

<style scoped>

.btn-space {
    margin-right: 10px !important;
}

.btn-success-custom {
    padding: 0 4px 0 4px;
    color: #3cb371;
}

.btn-danger-custom {
    padding: 0 4px 0 4px;
    color: #e97451;
}

.btn-success-custom:hover {
    cursor: pointer;
    color: green;
}

.btn-danger-custom:hover {
    cursor: pointer;
    color: red;
}

.table-striped tr {
    line-height: 20px;
}

.disabled-click {
    pointer-events: none;
    color: #dcdcdc;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.enabled-click {
    pointer-events: auto;
}

.search .form-group .input-group .input-group-addon {
    width: 40px;
    text-align: center;
    padding-top: 3.5px;
    background-color: rgba(220, 220, 220, .3);
}

.disable-highlight:hover span {
    cursor: pointer;
}

.refresh-all {
    margin-top: 6px;
    margin-right: 20px
}

.refresh-all:hover {
    cursor: pointer;
}

.search .form-group .input-group #inputGroup {
    width: 250px;
    padding-top: 3.5px;
}

.search .form-group .input-group .input-group-addon:hover {
    cursor: pointer;
    background-color: rgba(220, 220, 220, .5);
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 90px;
    background-color: #FFFFFF !important;
}

.table td, .table th {
    text-align: center;
}

.text-center {
    text-align: center !important;
}

.table-hover tbody tr:hover {
    background-color: rgba(153, 186, 221, .5) !important;
}

.more-customer-details:hover {
    cursor: pointer;
    color: #d59a18;
}

.bg-grey {
    background-color: #dcdcdc;
}

.close-info {
    color: blue !important;
}

.close-info:hover {
    cursor: pointer;
    color: green !important;
}

</style>
