<template>
<div>
    <card>
        <data-table
            :tableHeaders="tableHeaders"
            :tableData="filteredRequests"
            :tableDataKeys="tableDataKeys"
            :pageSizeProp="pageSize"
            :pageNo="pageNo"
            :myLoader="myLoader"
            :reloadAllRequest="reloadAllRequest"
            :myFirstLoader="myFirstLoader"
            :noDataFound="noDataFound"
            :is-hovered="isHovered"
            :loadingTitle="'Please wait while system is loading bills'"
            :hasManageButton="false"
            :totalElements="totalElements"
            :totalPages="totalPages"
            :isFirst="isFirst"
            :isLast="isLast"
            :pageNumber="pageNumber"
            :whichModuleAndType="whichModuleAndType"
            :allCustomers="allCustomers"
            :permissions="[ {key: 'searchPermission', value: 'BILLS_SEARCH_BILLS_GET'} ]"
            @resetParams="resetParams"
            @showToolTip="showToolTip"
            @hideToolTip="hideToolTip"
            @search="searchGeneralParams"
            @onChange="onChange"
            @goToPage="goToPage">
            <div slot = "pagination">
            <paginate
                v-model="getIsPage"
                :page-count=parseInt(totalPages)
                :page-range="3"
                :margin-pages="2"
                :click-handler="goToPage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
            </paginate>
            </div>
        </data-table>
    </card>
    <global-modal v-if="myLoader">
    <div slot="globalLoader">
        <div class="row text-center">
        <div class="col-md-12">Loading, Please wait</div>
        <div class="col-md-12">
            <br/>
        </div>
        <div class="col-md-12">
            <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
        </div>
        </div>
    </div>
    </global-modal>
</div>
</template>

<script>

import API from "../../../api/index"
import {mapGetters, mapActions} from "vuex"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import GlobalModal from "../../../components/globalLoader"

export default {

    name: "AllBillStatusReport",

    components: {

    PulseLoader,
    GlobalModal,
    DataTable: () => import('../../../components/DataTable/dataTable')

    },

    async created() {

    this.loadAllCustomers()
    this.loadAllCompanies()
    
    this.$store.dispatch("pagenames/currentPageName", "All Bills' Status Summary Report")

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.resetParams()

    },

    computed: {

    ...mapGetters("notifications", [

        "getNewAppPage"

    ]),

    ...mapGetters("shippingtallybilling", [

        "getRequestList",
        "getPageCount",
        "getMyFirstLoader",
        "getNoDataFound",
        "getSuccessAlert",
        "getErrorMessage",
        "getErrorAlert",
        "getSuccessMessage",
        "getReloadAllRequest",
        "getMyLoader",
        "getSearchParameter",
        "getPageNo",
        "getPageSize",
        "getRequestId",
        "getTotalElement",
        "getTotalPage",
        "getPageNumber",
        "getIsFirst",
        "getIsLast",
        "getTalliedDocumentId",
        "getSearchParams"
    ]),

    getIsPage: {

        get: function () {
            return this.isPage
        },
        set: function (newValue) {
            this.isPage = newValue;
        }

        }

    },

    data() {

    return {

        pageNo: 0,
        pageSize: 15,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',

        color: "green",
        size: "12px",
        loading: true,
        whichModuleAndType: 'allBillSummary',

        hasSearchedVariable: false,
        reloadAllRequest: false,
        noDataFound: false,
        pdfFormatError: false,
        loadAllHeaderLoader: false,
        allAttachmentVerified: false,
        myLoader: false,
        myFirstLoader: false,
        isSearchable: false,
        requests: [],
        filteredRequests: [],
        allCustomers: [],
        cfaCustomers: [],
        dcCustomers: [],
        isHovered: [],
        newParams: '',
        isPage: 0,
        pageNoPart: 0,


        finalUserPermissions: [],
        tableHeaders: ['Bill Number','Tansad Number', 'Customer', 'Amount', 'VAT', 'Vated Amount', 'Paid Amount', 'Status', 'Billed Date', 'Due Date', 'Control Number'],
        tableDataKeys: ['billNo','tansadno', 'company', 'totalBillAmountTzs', 'billVatAmountTzs', 'billVatedAmountTzs', 'amountPaid', 'status', 'dateBilled', 'dueDate', 'controlNumber'],

    }

    },

    methods: {

    loadAllCustomers() {

        API.getCustomerAll()
            .then((response) => {

            if (response.data.status) {

                this.cfaCustomers = response.data.data;

                for(let x = 0 ; x < this.cfaCustomers.length ; x++) {

                    const requestObject = {
                        customerId: this.cfaCustomers[x].customerId,
                        name: this.cfaCustomers[x].name
                    }

                    this.allCustomers.push(requestObject)

                }

            } else {
            }
            })
            .catch(() => {});

    },

    loadAllCompanies() {

        API.getCompany()
            .then((response) => {

            if (response.data.status) {

                this.dcCustomers = response.data.data;

                for(let x = 0 ; x < this.dcCustomers.length ; x++) {

                    const requestObject = {
                        customerId: this.dcCustomers[x].companyId,
                        name: this.dcCustomers[x].companyName + '[ ' + this.dcCustomers[x].companyCode + ' ]'
                    }

                    this.allCustomers.push(requestObject)

                }

            } else {
            }
            })
            .catch(() => {});

    },

    resetParams () {

        this.$store.dispatch("shippingtallybilling/hasSearchngParams", false);

        this.loadPageableRequestOnCreated(0)
        this.isPage = 1
        this.filteredRequests = []

    },

    sortingData(data) {

        for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {
            ...data[x],
            dateBilled: this.dateTimeToHuman(data[x].dateBilled),
            dueDate: this.dateTimeToHuman(data[x].dueDate),
            totalBillAmountTzs: data[x].billCurrency === 'USD' ? this.putComma(this.amountDecimalPlaces(data[x].totalBillAmount)) + ' ' + data[x].billCurrency : this.putComma(this.amountDecimalPlaces(data[x].totalBillAmountTzs)) + ' ' + data[x].billCurrency,
            billVatAmountTzs: data[x].billCurrency === 'USD' ? this.putComma(this.amountDecimalPlaces(data[x].billVatAmount)) + ' ' + data[x].billCurrency : this.putComma(this.amountDecimalPlaces(data[x].billVatAmountTzs)) + ' ' + data[x].billCurrency,
            billVatedAmountTzs: data[x].billCurrency === 'USD' ? this.putComma(this.amountDecimalPlaces(data[x].totalVatedAmount)) + ' ' + data[x].billCurrency : this.putComma(this.amountDecimalPlaces(data[x].billVatedAmountTzs)) + ' ' + data[x].billCurrency,
            amountPaid: data[x].billCurrency === 'USD' ? this.putComma(this.amountDecimalPlaces(data[x].amountPaid)) + ' ' + data[x].billCurrency : this.putComma(this.amountDecimalPlaces(data[x].amountPaid)) + ' ' + data[x].billCurrency,
            }

            this.filteredRequests.push(requestObject)

        }

    },

    ...mapActions("shippingtallybilling", [

        "getAllBillsPageableOnCreated",
        "searchWithGeneralParams",
        "searchWithGeneralParamsForAllBills",
        "searchWithGeneralParamsForAllBillsAfterCreated"

    ]),

    loadSearchParameters(params, fromApplicationPageNo) {

        let firstPart = params.split("&page=")[0]
        let secondPart = firstPart.split("size=")[0]
        this.newParams = ""

        this.isPage = fromApplicationPageNo
        this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + 0

        return new Promise((resolve) => {

            this.$store.dispatch("shippingtallybilling/pageSizing", this.pageSize);
            this.$store.dispatch("shippingtallybilling/pageNumbering", 0);
            this.$store.dispatch("shippingtallybilling/paramsSearching", params);

            resolve();

        })

    },

    loadParameters(params, pageNo , pageSize) {

        return new Promise((resolve) => {

        this.$store.dispatch("shippingtallybilling/pageSizing", pageSize);
        this.$store.dispatch("shippingtallybilling/pageNumbering", pageNo);
        this.$store.dispatch("shippingtallybilling/paramsSearching", params);

        resolve();

        })

    },

    setAllGetters(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';

        this.noDataFound = this.getNoDataFound;
        this.SuccessMessage = this.getSuccessMessage;
        this.ErrorMessage = this.getErrorMessage;
        this.ErrorAlert = this.getErrorAlert;
        this.SuccessAlert = this.getSuccessAlert;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;

        this.sortingData(this.requests)

    },

    searchGeneralParams(params , fromApplicationPageNo) {

        this.hasSearchedVariable = true

        this.newParams = params

        this.filteredRequests = [];

        this.loadSearchParameters(params , fromApplicationPageNo).then(() => {

        this.searchWithGeneralParams({}).then(() => {

            this.setAllGetters('onCreated')

        }).catch(() => {


        });

        this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {


        });

    },

    loadPageableRequestOnCreated(page) {

        let params = ""

        params = '?size=' + this.pageSize + '&page=' + page

        this.hasSearchedVariable = false

        this.filteredRequests = [];

        this.loadParameters(params, page ,this.pageSize).then(() => {

        this.searchWithGeneralParamsForAllBills({}).then(() => {

            this.setAllGetters('onCreated')

        }).catch(() => {


        });

            this.myFirstLoader = this.getMyFirstLoader

        }).catch(() => {


        });

    },

    loadPageableRequestAfterCreated(page) {

        let params = ""

        params = '?size=' + this.pageSize + '&page=' + page

        this.hasSearchedVariable = false

        this.filteredRequests = [];

        this.loadParameters(params, page ,this.pageSize).then(() => {

        this.searchWithGeneralParamsForAllBillsAfterCreated({}).then(() => {

            this.setAllGetters('afterCreated')

        }).catch(() => {


        });

            this.myLoader = this.getMyLoader

        }).catch(() => {


        });

    },

    onChange(event) {

        this.$store.dispatch("shippingtallybilling/pageSizing", event);
        this.$store.dispatch("shippingtallybilling/pageNumbering", 0);
        localStorage.removeItem("page-no")
        localStorage.removeItem("page-size")
        this.isPage = 0

        this.pageSize = this.getPageSize;
        this.pageNo = this.getPageNo;

        if (this.hasSearchedVariable) {

            let firstPart = this.newParams.split("&page=")[0]
            let secondPart = firstPart.split("size=")[0]
            let thirdPart = firstPart.split("size=")[1]
            this.newParams = ""
            this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + 0
            this.pageNoPart = 0

            this.searchGeneralParams(this.newParams , this.pageNoPart);
            this.filteredRequests = []

        } else {

            this.loadPageableRequestAfterCreated(this.isPage);
            this.filteredRequests = []
        }

    },

    goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1

        this.pageNo = page;

        if (this.hasSearchedVariable) {

            let firstPart = this.newParams.split("page=")[0]
            this.newParams = ""
            this.newParams = firstPart + '&page=' + this.pageNo
            this.pageNoPart = this.isPage

            this.searchGeneralParams(this.newParams , this.pageNoPart);
            this.filteredRequests = []

        } else {

            this.loadPageableRequestAfterCreated(page);
            this.filteredRequests = []
        }

    },

    showToolTip (object) {

        this.isHovered = [object];
    },

    hideToolTip (object) {

        this.isHovered.splice(this.isHovered.indexOf(object), 1)

    }

},

destroyed() {

}


}
</script>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: auto;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  .is-active-new, .is-active-new :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: gray !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-verified, .is-active-verified :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-major, .is-active-major :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #2F4F4F !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-minor, .is-active-minor :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-returned, .is-active-returned :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: red !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-waiting-control-number, .is-active-waiting-control-number :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #a55690 !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

</style>
