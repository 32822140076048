<template>
  <div class="content">
    <div class="col-md-12">
      <card>
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-6"
              style="padding-left: 30px"
            >
              <div class="text-left">
                Documents Reconciliation Summary for voyage number <b>{{ voyage }}</b>
              </div>
            </div>
            <div class="col-md-3 text-left">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                v-if="myloader"
              />
            </div>
            <div class="col-md-3 text-right">
              <button
                type="button"
                class="btn btn-fill btn-outline-info float-right"
                @click="$router.go(-1)"
              >
                Back
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <br>
        </div>

        <div class="col-md-12 text-center">
          <div class="row">
            <div class="col-md-3" />
            <div class="col-md-6">
              <apexchart
                width="500"
                type="pie"
                :options="options"
                @dataPointSelection="dataPointSelectionHandler"
                :series="series"
                class="text-center my-chart"
              />
            </div>
            <div class="col-md-3" />
          </div>
        </div>

        <div
          class="col-md-12"
          v-for="(bl, index) in discrepancies"
          :key="bl.discrepancyId"
        >
          <import-individual-summary
            :voyage="bl.voyage"
            :field-name="bl.fieldName"
            :list-id="index + 1"
          />
        </div>

        <br>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6" />

            <div class="col-md-3 text-left" />
            <div class="col-md-3 text-right">
              <button
                type="button"
                class="btn btn-fill btn-outline-info float-right"
                @click="$router.go(-1)"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import API from '../../../api/index'
import ImportIndividualSummary from './ImportIndividualSummary.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import ChartCard from '../../../components/Cards/ChartCard.vue'

export default {

  name: 'ImportReconciledSummaryInd',

  components: {

    PulseLoader,
    ChartCard,
    importIndividualSummary: ImportIndividualSummary

  },

  data () {
    return {
      voyage: '',
      discrepancies: [],
      discrepancyArray: [],
      myLabel: [],
      mySeries: [],
      resultArray: [],
      selectedData: {},
      activetab: 1,
      activetabmain: 7,

      color: 'green',
      size: '15px',
      loading: true,
      myLoader: false

    }
  },
  created () {
    this.voyage = this.$route.params.voyage
    this.loadReconciliation()
    this.loadReconciliationAll()
  },

  computed: {

    series () {
      return this.mySeries
    },

    options () {
      return {
        labels: this.myLabel,

        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#4B0082', '#800000', '#B8860B', '#7B68EE'],

        theme: {

          monochrome: {

            enabled: false

          }

        },

        title: {

        },

        legend: {

          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          width: undefined,
          height: undefined,
          formatter: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 0,
          offsetY: 0,

          labels: {

            colors: undefined,
            useSeriesColors: true

          },

          markers: {

            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0

          },

          itemMargin: {

            horizontal: 2,
            vertical: 15

          },

          onItemClick: {

            toggleDataSeries: true

          },

          onItemHover: {

            highlightDataSeries: true

          }

        },

        responsive: [{

          breakpoint: 480,

          options: {

            chart: {

              width: 500,
              events: {

                dataPointSelection: (e, chart, opts) => {}

              }

            }

          }

        }]

      }
    }

  },
  methods: {

    dataPointSelectionHandler (e, chartContext, config) {
      // console.log(chartContext, config)
    },

    loadReconciliation () {
      this.myloader = true

      API.getTancisReconciliationSummaryForVoyage(this.voyage).then(
        response => {
          if (response.data.status) {
            this.discrepancies = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(() => {

      })
    },

    removeApexchartsLegendStyle () {
      const element = document.getElementsByClassName('my-chart')

      element.removeAttribute('style')
      element.style.backgroundColor = 'red'
    },

    loadReconciliationAll () {
      this.myloader = true

      API.getTancisIndividualReconciliationSummationByGroup(this.voyage).then(
        response => {
          if (response.data.status) {
            this.discrepancyArray = response.data.data
            this.myloader = false

            // let result = [];
            // this.discrepancyArray.reduce(function(res, value) {
            //   if (!res[value.fieldName]) {
            //
            //     res[value.fieldName] = { fieldName: value.fieldName, qty: 0 };
            //     result.push(res[value.fieldName])
            //
            //   }
            //   res[value.fieldName].qty += 1;
            //   return res;
            //
            // }, {});
            //
            // console.log(result);
            //
            // for(let x = 0 ; x < result.length ; x++) {
            //
            //     this.myLabel.push(result[x].fieldName);
            //     this.mySeries.push(result[x].qty);
            //
            // }

            for (let x = 0; x < this.discrepancyArray.length; x++) {
              this.myLabel.push(this.discrepancyArray[x].fieldName.replace(/([a-z])([A-Z])/g, '$1 $2'))
              this.mySeries.push(this.discrepancyArray[x].discrepancyCount)
            }
          } else {

          }
        }
      ).catch(() => {

      })
    }
  }

}
</script>
<style scoped>

  .my-chart .apexcharts-canvas .apexcharts-svg .apexcharts-legend  {
    text-align: left !important;
  }

</style>
<style>
  .notification-issues {
    /*position: absolute;*/
    background-color: #FB404B;
    text-align: center;
    border-radius: 10px;
    min-width: 18px;
    padding: 0 5px;
    height: 18px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
  }

  .notification-no-issues {
    background-color: green;
    text-align: center;
    border-radius: 10px;
    min-width: 18px;
    padding: 0 5px;
    height: 18px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
  }
</style>
