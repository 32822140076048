<template>
  <div class="content">
    <card>
      <div class="row">
        <div
          @click="toggle"
          class="col-md-12 toggle-code-key"
        >
          <div class="row">
            <div class="col-md-6">
              User icons color keys
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon
                icon="angle-down"
                v-show="!showSection"
              />
              <font-awesome-icon
                icon="angle-up"
                v-show="showSection"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          v-show="showSection"
        >
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 30px"
              v-show="showSection"
            >
              <b>icons keys</b>
            </div>
            <div class="col-md-12">
              <div
                class="col-md-12"
                v-show="showSection"
              >
                <div class="row">
                  <div class="col-md-12">
                    <span>
                      <font-awesome-icon
                        icon="download"
                        class="download-fa-icon"
                      /><i class=" text-muted">
                        Download Loading Permission</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card v-if="finalUserPermissions.includes('LOADINGPERMISSION_UPLOAD_POST')">
      <div>Add Loading Permission Document</div>
      <br>

      <form
        method="post"
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-3">
            <label>Search Carrier</label>

            <model-list-select
              :list="carrierNames2"
              v-model="exportdocument.carrierName"
              class="form-control"
              option-value="carrierName"
              option-text="carrierName"
              placeholder="carrier name"
            />

            <div v-if="carrierNameError">
              <div
                class="error"
                v-if="!$v.exportdocument.carrierName.required"
              >
                Select document type
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <base-input
              type="text"
              label="Voyage"
              placeholder="Voyage"
              v-model="exportdocument.TPACallId"
              class="special-bottom"
            />
            <div v-if="TPACallIdError">
              <div
                class="error"
                v-if="!$v.exportdocument.TPACallId.required"
              >
                This field is required
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="fileUpload btn btn-info btn-fill">
              <span>Choose Cargo Document..</span>
              <input
                type="file"
                name="attachment"
                label="Upload File"
                ref="file"
                class="upload"
                id="first_upload"
                @change.prevent="uploadFile"
              >
            </div>
            <label
              for="first_upload"
              id="label-upload"
            >
              {{ file ? 'Selected File: ' + '" ' + file + ' "' : 'No File Selected' }} </label>
          </div>
        </div>
        <div class="text-center">
          <div class="row">
            <span
              class="text-right col-md-6"
              v-if="ManifestLoader"
            >
              Uploading your {{ file ? '" ' + file + ' "' : 'Document' }}</span>
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="ManifestLoader"
              class="text-left col-md-6 loader-div-gif"
            />
            <span
              class="col-md-12 loader-div-gif text-center text-success"
              v-if="ManifestFinishLoader"
            >{{ responseMessage }}</span>
          </div>
        </div>
      </form>
    </card>
    <card>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="text-left">
              List of Loading Permission Documents
            </div>
          </div>
          <div
            class="col-md-4 text-center"
            v-if="!mailSent"
          >
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
          <div
            class="col-md-4"
            v-if="!mailSent"
          />
          <div
            class="col-md-8"
            v-if="mailSent"
          >
            <div class="text-success">
              {{ mailSuccess }}
            </div>
          </div>
        </div>
      </div>

      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('LOADINGPERMISSION_SEARCH-PAGEABLE_POST')"
            >
              <form
                class="search"
                @submit.prevent="searchRequestByName(pageNo , pageSize , sortBy)"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParamenter"
                      @input="loadAllPageble"
                    >
                    <span
                      class="input-group-addon"
                      @click.prevent="searchRequestByName(pageNo , pageSize , sortBy)"
                    >
                      <font-awesome-icon icon="search" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              TASAC Control Number
            </th>
            <th scope="col">
              Date Registered
            </th>
            <th scope="col">
              Carrier Name
            </th>
            <th scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(document, index) in getDocument"
            :key="document.exportDocumentId"
            v-if="!noDataFound"
          >
            <td scope="row">
              {{ index + 1 }}
            </td>
            <td>
              {{ document.tasacControlNumber }}
            </td>
            <td>
              {{ dateTimeToHuman(document.documentDate) }}
            </td>
            <td>
              {{ document.carrierName }}
            </td>
            <td>
              <div
                class="all-fa-icons download-fa-icon"
                @click.prevent="getFileFromMinio(document.documentUrl , 'ATTACHMENT')"
                title="download excel"
                v-if="finalUserPermissions.includes('LOADINGPERMISSION_UPLOAD_POST')"
              >
                <font-awesome-icon icon="download" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!-- START  OF BOTTOM PAGENATION-->
      <hr>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
            </div>

            <div
              class="col-md-9 text-right disable-highlight"
              unselectable="on"
              style="line-height: 5px;font-size: 20px"
            >
              <span
                class="btn-space"
                :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                @click="firstPage"
                title="go to first page"
              >First
              </span>
              <span
                class="btn-space"
                :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                @click="prevPage"
                title="go to previous page"
              >
                <font-awesome-icon icon="angle-double-left" />
              </span>

              <span class="btn-space" />
              <span class="btn-space" />

              <span
                class="btn-space"
                :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' :
                  'enabled-click']"
                @click="nextPage"
                title="go to next page"
              >
                <font-awesome-icon icon="angle-double-right" />
              </span>
              <span
                class="btn-space"
                :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' :
                  'enabled-click']"
                @click="lastPage"
                title="go to last page"
              >Last
              </span>
            </div>
          </div>
        </div>
      </div>

      <!--END OF BOTTOM PAGENATION-->
    </card>

    <!--    </div>-->
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Voyage Number <br>
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
          </div>
          <div
            class="col-md-12"
            v-if="SendEmailModalDiv"
          >
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
            <br><br>
            <span>You are about to send above document download link to <b>{{ companyEmail }}</b>
              onbehalf of company <b>{{ companyName }}</b>, are you sure?.</span>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--    SEND MAIL      -->
          <form
            method="POST"
            @submit.prevent="sendMail(documentId)"
            v-if="SendEmailModalDiv"
          >
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div-email">
                <span v-if="sendMyLoader">Sending your email ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif-email">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="sendMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="SendEmailModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space float-right"
                  data-dismiss="modal"
                >
                  Send
                </button>
              </div>
            </div>
          </form>

          <!--    EDIT VOYAGE NUMBER      -->
          <form
            method="POST"
            @submit.prevent="updateVoyageNumber"
            v-if="EditModalDiv"
          >
            <DIV class="row">
              <div class="col-md-12">
                <label>Select Voyage Number</label>
                <select
                  class="form-control"
                  v-model="vesselScheduleId"
                >
                  <option
                    value=""
                    select="selected"
                  >
                    Select voyage number
                  </option>
                  <option
                    v-for="(vessel, index) in getVesselSchedule" :key="index" :value="vessel.vesselScheduleId"
                  >
                    {{ vessel.voyageNo }}
                  </option>
                </select>
              </div>
            </DIV>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div-email">
                <span v-if="editMyLoader">Please wait sending your email ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif-email">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>

    <modal-manifest v-if="showModalManifest">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div class="col-md-12 text-center">
            MANIFEST AND ASSOCIATED BILL OF LADING DETAILS
          </div>
          <div class="col-md-12">
            <hr>
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
          </div>
          <div class="col-md-12 text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="BLLoader"
            />
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">
                      #
                    </th>
                    <th scope="col">
                      B/L No.
                    </th>
                    <th scope="col">
                      B/L Type
                    </th>
                    <th scope="col">
                      Container No.
                    </th>
                    <th scope="col">
                      Container Size
                    </th>
                    <th scope="col">
                      Container Type
                    </th>
                    <th scope="col">
                      Destination place
                    </th>
                    <th scope="col">
                      Gross Volume
                    </th>
                    <th scope="col">
                      Gross Weight
                    </th>
                    <th scope="col">
                      Marks No.
                    </th>
                    <th scope="col">
                      Operation Type
                    </th>
                    <th scope="col">
                      Seal No.
                    </th>
                    <th scope="col">
                      Tonnage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bl, index) in getDocumentBL"
                    :key="bl.cargoOriginalId"
                  >
                    <td scope="row">
                      {{ index + 1 }}
                    </td>
                    <td>{{ bl.billOfLadingNo }}</td>
                    <td>{{ bl.billOfLadingType }}</td>
                    <td>{{ bl.containerNo }}</td>
                    <td>{{ bl.containerSize }}</td>
                    <td>{{ bl.containerType }}</td>
                    <td>{{ bl.destinationPlace }}</td>
                    <td>{{ bl.grossVolume }} ({{ bl.grossVolumeUnit }})</td>
                    <td>{{ bl.grossWeight }} ({{ bl.grossWeightUnit }})</td>
                    <td>{{ bl.marksNumber }}</td>
                    <td>{{ bl.operationType }}</td>
                    <td>{{ bl.sealNo }}</td>
                    <td>{{ bl.tonnage }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div" />
              <div class="col-md-2 left loader-div-gif" />
              <div
                class="col-md-3"
                v-if="ShowModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal-manifest>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Downloading requested file, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>
<script>

import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import ModalManifest from '../../../components/Inputs/ModalManifest'
import Modal from '../../../components/Inputs/Modal'
import { ModelListSelect } from 'vue-search-select'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex';
import GlobalModal from '../../../components/globalLoader'

export default {

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Export Loading Permission documents')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadDocumentType()
    this.loadVessel()
    this.loadVesselSchedule()
    this.loadCarrierDocument()
    this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
  },

  data () {
    return {

      file: '',
      options: [],
      globalLoader: false,
      errors: [],
      array: {},
      fileData: '',
      documentTypeId: '',
      responseMessage: '',
      getVessel: [],
      getVesselSchedule: [],
      getDocument: [],
      getDocumentBL: [],
      finalUserPermissions: [],
      permissionGroups: [],
      getCarrierDocument: [],
      carrierNames: [],
      carrierNames2: [],

      exportdocument: {
        carrierName: '',
        TPACallId: ''
      },

      documentId: '',
      voyageNo: '',
      documentTypeName: '',
      controlNumber: '',
      vesselName: '',
      companyEmail: '',
      companyName: '',
      vesselScheduleId: '',
      documentUrl: '',

      color: 'green',
      size: '15px',
      loading: true,

      showSection: true,

      hasSearchedVariable: false,
      reloadAllRequest: false,
      sortBy: 'exportDocumentId',
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      docSortBy: 'id',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      noDataFound: '',

      ManifestLoader: false,
      ManifestFinishLoader: false,
      myLoader: false,
      BLLoader: false,
      sendMyLoader: false,
      editMyLoader: '',
      emailAddress: '',
      mailSuccess: '',

      showModal: false,
      EditModalDiv: false,
      ShowModalDiv: false,
      SendEmailModalDiv: false,
      showModalManifest: false,
      mailSent: false,

      carrierNameError: false,
      TPACallIdError: false

    }
  },

  validations: {

    exportdocument: {

      carrierName: {
        required
      },

      TPACallId: {
        required
      }
    },
    searchParameter: { required }

  },

  components: {

    PulseLoader,
    Modal,
    'modal-manifest': ModalManifest,
    ModelListSelect,
    GlobalModal,

  },

  computed: {

    ...mapGetters([

      'getDocumentVoyageNumberBeforeEdit'

    ]),

    ...mapGetters('global', [

        'getGlobalLoader'

      ])

  },

  methods: {

    ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    loadParameters (fileUrl, fileName) {
      
      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    getFileFromMinio (fileUrl, fileName) {

      this.loadParameters(fileUrl, fileName).then(() => {

      this.loadFileFromMinio({}).then((res) => {

          if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
              'File not found, please contact system admin',
              'response', 1000 , true  , true)

          } else {

            this.loadNotification('success', 4000, 'File Download',
              'File has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)


          }

            this.globalLoader = this.getGlobalLoader
        
        }).catch(() => {

          this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

          this.globalLoader = this.getGlobalLoader

        })

        this.globalLoader = this.getGlobalLoader

      }).catch(() => {

      })

    },

    toggle () {
      this.showSection = !this.showSection
    },

    loadDocument (pageNo, pageSize, sortBy) {
      this.myloader = true

      API.getPagableExportDocuments(pageNo, pageSize, sortBy).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            if (response.data.data.length > 0) {
              this.getDocument = response.data.data
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            }
          } else {
            this.noDataFound = true
            this.pageCount = response.data.message
            this.docPageCount = this.pageCount
            this.docPageNo = this.pageNo
            this.docPageSize = this.pageSize
            this.getPageableDoc = response.data.data
            this.myloader = false
          }
        }
      ).catch(e => {
        this.myloader = false
      })
    },

    searchRequestByName (pageNo, pageSize) {
      if (this.$v.searchParameter.$invalid) {

      } else {
        this.myloader = true
        this.reloadAllRequest = true
        this.hasSearchedVariable = true

        API.searchExportDocument(pageNo, pageSize, this.searchParameter).then(
          response => {
            if (response.data.status) {
              this.myloader = false

              if (response.data.data.length > 0) {
                this.getDocument = response.data.data
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.myloader = false
              } else {
                this.noDataFound = true
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.myloader = false
              }
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getDocument = response.data.data
              this.myloader = false
            }
          }
        ).catch(e => {
          this.myloader = false
        })
      }
    },

    onChange (event) {
      this.pageSize = event.target.value
      this.pageNo = 0
      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize)
      } else {
        this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
      }
      this.docPageNo = this.pageNo
      this.docPageSize = this.pageSize
      this.docPageCount = this.pageCount
      this.docSortBy = this.sortBy
    },

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize)
      } else {
        this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize)
      } else {
        this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize)
      } else {
        this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize)
      } else {
        this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
      }
    },

    reloadByIcon () {
      this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParamenter = ''
    },

    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParamenter = ''
      }
    },

    loadCarrierDocument () {
      API.getDocument().then(
        response => {
          if (response.data.status) {
            this.getCarrierDocument = response.data.data

            for (let i = 0; i < this.getCarrierDocument.length; i++) {
              this.carrierNames.push(this.getCarrierDocument[i].carrierName)

              this.carrierNames = Array.from(new Set(this.carrierNames))
            }

            for (let j = 0; j < this.carrierNames.length; j++) {
              const array = {

                carrierName: this.carrierNames[j]

              }

              this.carrierNames2.push(array)
            }
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not added'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadDocumentType () {
      API.getDocumentType().then(
        response => {
          if (response.data.status) {
            this.options = response.data.data
          } else {

          }
        }
      ).catch(e => {

      })
    },

    sendMail (data) {
      this.sendMyLoader = true

      // setTimeout(() => {

      API.sendDownloadLinkMail(data).then(
        response => {
          if (response.data.status) {
            this.mailSuccess = 'Mail has been sent to ' + this.companyEmail + ' for company ' + this.companyName
            this.mailSent = true
            this.closeModal()
            this.sendMyLoader = false

            setTimeout(() => {
              this.mailSuccess = ''
              this.mailSent = false
            }, 7000)
          } else {

          }
        }
      ).catch(e => {

      })

      // }, 1000);
    },

    loadVesselSchedule () {
      this.myloader = true

      API.getVesselSchedule()
        .then(response => {
          if (response.data.status) {
            this.getVesselSchedule = response.data.data
            this.myloader = false
          } else {
          }
        })
        .catch(e => {
          this.ErrorMessage = ' Record not loaded'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false
        })
    },

    updateVoyageNumber () {
      this.array = {

        documentId: this.documentId,
        controlNumber: this.controlNumber,
        documentUrl: this.documentUrl,
        documentType: {
          documentTypeId: this.documentTypeId
        },
        vesselSchedule: {
          vesselScheduleId: this.vesselScheduleId
        }

      }

      this.editMyLoader = true

      API.updateDocument(JSON.stringify(this.array))
        .then(response => {
          if (response.data.status) {
            this.editMyLoader = false
            this.documentToDocument.documentType.documentTypeId = ''
            this.documentToDocument.vesselSchedule.vesselScheduleId = ''
            this.vesselScheduleId = ''
            this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
            this.closeModal()
            window.scrollTo(10, 0)
            this.mailSuccess = 'Voyage number [ ' + this.getDocumentVoyageNumberBeforeEdit + ' ] ' +
                'for document [ ' + this.documentTypeName + ' ] changed to current value'
            this.mailSent = true

            setTimeout(() => {
              this.mailSuccess = ''
              this.mailSent = false
            }, 14000)
          } else {
          }
        })
        .catch(e => {
          this.ErrorMessage = ' Record not edited'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.editMyLoader = false
          this.closeModal()
        })
    },

    loadVessel () {
      API.getVessel()
        .then(response => {
          if (response.data.status) {
            this.getVessel = response.data.data
          } else {

          }
        })
        .catch(e => {

        })
    },

    loadSingleDocument (data) {
      this.BLLoader = true

      API.getIndividualDocument(data).then(
        response => {
          if (response.data.status) {
            this.BLLoader = false
            this.getDocumentBL = response.data.data.cargoManifestOriginal
          } else {

          }
        }
      ).catch(e => {
        this.BLLoader = false
        this.closeModal()
      })
    },

    uploadFile () {
      if (this.$v.exportdocument.carrierName.$invalid || this.$v.exportdocument.TPACallId.$invalid) {
        if (this.$v.exportdocument.carrierName.$invalid) { this.carrierNameError = true }
        if (this.$v.exportdocument.TPACallId.$invalid) { this.TPACallIdError = true }
      } else {
        this.fileData = this.$refs.file.files[0]

        this.file = this.fileData.name

        this.ManifestLoader = true

        const formData = new FormData()

        formData.append('file', this.fileData)
        formData.append('carrierName', this.exportdocument.carrierName)
        formData.append('tpaCallId', this.exportdocument.TPACallId)

        // for (let pair of formData.entries()) {
        //   console.log(pair[0]+ ', ' + pair[1]);
        // }

        setTimeout(() => {
          API.uploadExportDocument(formData).then(
            response => {
              if (response.data.status) {
                this.ManifestLoader = false
                this.ManifestFinishLoader = true
                this.responseMessage = 'Upload finished successifully'
                this.exportdocument.carrierName = ''
                this.exportdocument.TPACallId = ''
                this.loadDocument(this.pageNo, this.pageSize, this.sortBy)
                this.file = ''
                this.carrierNameError = false
                this.TPACallIdError = false

                setTimeout(() => {
                  this.ManifestFinishLoader = false
                }, 7000)
              } else {
                this.ManifestLoader = false
                this.ManifestFinishLoader = true
                this.responseMessage = response.data.data
                this.exportdocument.carrierName = ''
                this.exportdocument.TPACallId = ''
                this.file = ''
                this.carrierNameError = false
                this.TPACallIdError = false

                setTimeout(() => {
                  this.ManifestFinishLoader = false
                }, 7000)
              }
            }
          ).catch(e => {

          })
        }, 2500)
      }
    },

    openModal (documentId = null, docUrl = null, documentTypeName = null, documentTypeId = null,
      vesselScheduleId = null, controlNumber = null, vesselName = null, voyageNo = null,
      comEmail = null, comName = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.SendEmailModalDiv = false
        this.ShowModalDiv = false
        this.showModalManifest = false
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
        this.documentTypeId = documentTypeId
        this.vesselScheduleId = vesselScheduleId
        this.documentUrl = docUrl

        this.$store.dispatch('commitDocumentVoyageNumberBeforeEdit', this.voyageNo)
      } else if (actionButton === 'SendEmailModal') {
        this.EditModalDiv = false
        this.SendEmailModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.showModalManifest = false
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
        this.companyEmail = comEmail
        this.companyName = comName
      } else {
        this.loadSingleDocument(documentId)
        this.EditModalDiv = false
        this.SendEmailModalDiv = false
        this.ShowModalDiv = true
        this.showModal = false
        this.showModalManifest = true
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.SendEmailModalDiv = false
      this.showModalManifest = false
    }

  }

}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .loader-div-gif {
    line-height: 34px;
  }

  .loader-div-gif-email {
    line-height: 54px;
  }

  .loader-div-email {
    line-height: 45px;
  }

  .special-limit {
    max-width: 170px !important;
    min-width: 150px !important;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 16px;
    font-weight: normal;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  #label-upload {
    position: relative;
    top: 16px;
  }
  .special-bottom {
    margin-bottom: -1px;
  }
</style>
<style>
  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }
</style>
