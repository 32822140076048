<template>
    <card>
        <section>
            <div class="container py-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-timeline">

                            <div class="timeline" v-for="(timeline, index) in timelineData" :key="index" :class="[timeline.date !== null && timeline.date !== '' ? '' : 'is-active-timeline']">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon :icon="timeline.icon" :class="timeline.class"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">{{ timeline.status }}</span>
                                        <h3 class="title h4">{{ timeline.date !== null ? dateTimeToHuman(timeline.date) : '' }}</h3>
                                        <p class="description">
                                            {{ timeline.description }} 
                                        </p>
                                        <p class="description text-center" v-if="timeline.status === 'Received'"> <u><b>Associated Attachment(s)</b></u></p>
                                        <p class="description" v-if="timeline.status === 'Received'">
                                            <ul>
                                                <li v-for="(attachment, index_attachment) in selectedRequest.requestAttachDtos" :key="index_attachment" class="download-link mb-2"
                                                @click.prevent="openPdfViewer(attachment.attachmentUrl , (attachment.attachmentName).toUpperCase() + '_FOR_APPLICATION_WITH_FILE_NUMBER_' + selectedRequest.fileNo, 'view')">
                                                    {{ attachment.attachmentName }}
                                                </li>
                                            </ul>
                                        </p>

                                        <p class="description text-center" v-if="timeline.status === 'Accepted'"> <u><b>Key Application Details</b></u></p>
                                        <p class="description" v-if="timeline.status === 'Accepted'">
                                            <ul>
                                                <li class="mb-2"><b>File Number:</b> {{ selectedRequest.fileNo }}</li>
                                            </ul>
                                        </p>

                                        <p class="description text-center" v-if="timeline.status === 'Draft'"> <u><b>Key Application Details</b></u></p>
                                        <p class="description" v-if="timeline.status === 'Draft'">
                                            <ul>
                                                <li class="mb-2"><b>B/L:</b> {{ selectedRequest.blNumber }}</li>
                                                <li class="mb-2"><b>Application Type:</b> {{ selectedRequest.requestType === 'IMP' ? 'Import' : 'Export' }}</li>
                                                <li class="mb-2"><b>Customer:</b> {{ selectedRequest.customer.name }}</li>
                                                <li class="mb-2"><b>Product Type:</b> {{ selectedRequest.productType }}</li>
                                                <li class="mb-2"><b>Regime:</b> {{ selectedRequest.regime.regimeName }}</li>
                                            </ul>
                                        </p>

                                        <p class="description text-center" v-if="timeline.status === 'Tariffed'"> <u><b>Key Tariff Details</b></u></p>
                                        <p class="description" v-if="timeline.status === 'Tariffed'">
                                            <ul>
                                                <li class="mb-2"><b>Tariff Used:</b> {{ assessment.tariffItemName }}</li>
                                                <li class="mb-2"><b>TANSAD Number:</b> {{ assessment.tansadNo }}</li>
                                                <li class="mb-2"><b>Custom Value:</b> {{ putComma(assessment.customValue) }}</li>
                                                <li class="mb-2" v-if="assessment.cargoVolume !== 0"><b>Volume:</b> {{ putComma(assessment.cargoVolume) }}</li>
                                                <li class="mb-2" v-if="assessment.cargoTonnage !== 0"><b>Tonnage:</b> {{ putComma(assessment.cargoTonnage) }}</li>
                                                <li class="mb-2" v-if="assessment.cargoCbm !== 0"><b>CBM:</b> {{ putComma(assessment.cargoCbm) }}</li>
                                                <li class="mb-2" v-if="assessment.quantity !== 0"><b>Quantity:</b> {{ putComma(assessment.quantity) }}</li>
                                            </ul>
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="circle" class="class-new-app" style="position:absolute;bottom:40px;right:42px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Draft</span>
                                        <h3 class="title h4">02-05-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application drafted by customer but not sent to TASAC 
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="hands" class="class-new-app" style="position:absolute;bottom:40px;right:32px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Received</span>
                                        <h3 class="title h4">16-05-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application received by TASAC CFA 
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="exclamation-triangle" class="class-rejected-mojor-app" style="position:absolute;bottom:40px;right:35px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Major Rejected</span>
                                        <h3 class="title h4">17-05-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application to be rejected with major issues by TASAC CFA
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="exclamation-triangle" class="class-rejected-minor-app" style="position:absolute;bottom:40px;right:35px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Minor Rejected</span>
                                        <h3 class="title h4">17-05-2022 22:24:30</h3>
                                        <p class="description">
                                            Date and time for the application to be flaged with minor issues by TASAC CFA
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="edit" class="class-returned-app" style="position:absolute;bottom:40px;right:38px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Returned</span>
                                        <h3 class="title h4">22-05-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application to be returned to TASAC CFA by customer after attending major raised issues
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="check" class="class-approved-app" style="position:absolute;bottom:40px;right:38px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Accepted</span>
                                        <h3 class="title h4">18-05-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application accepted by TASAC CFA after verifying all details and attached documents
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="luggage-cart" class="class-assessed-app" style="position:absolute;bottom:40px;right:38px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Tariffed</span>
                                        <h3 class="title 4">26-05-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application to be tariffed by TASAC CFA for subjecting service charge
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="luggage-cart" class="class-rejected-assessment-app" style="position:absolute;bottom:40px;right:38px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Rejected Tariff</span>
                                        <h3 class="title 4">26-05-2022 15:24:30</h3>
                                        <p class="description">
                                            Date and time for the application tariff to be rejected by TASAC CFA
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="file-invoice-dollar" class="class-charged-app" style="position:absolute;bottom:40px;right:48px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Charged</span>
                                        <h3 class="title 4">26-05-2022 15:24:30</h3>
                                        <p class="description">
                                            Date and time for the application to be charged by TASAC CFA
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="file-invoice-dollar" class="class-rejected-charge-app" style="position:absolute;bottom:40px;right:48px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Rejected Charge</span>
                                        <h3 class="title 4">27-05-2022 18:24:30</h3>
                                        <p class="description">
                                            Date and time for the application charge to be rejected by TASAC FAU
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="file-invoice-dollar" class="class-billed-app" style="position:absolute;bottom:40px;right:48px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Billed</span>
                                        <h3 class="title 4">29-05-2022 18:24:30</h3>
                                        <p class="description">
                                            Date and time for the application charge to be billed by TASAC FAU
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="check" class="class-bill-approved-app" style="position:absolute;bottom:40px;right:38px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Bill Approved</span>
                                        <h3 class="title h4">02-06-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application bill to be generated by TASAC FAU
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="dollar-sign" class="class-paid-app" style="position:absolute;bottom:40px;right:58px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Bill Paid</span>
                                        <h3 class="title h4">15-06-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application bill to be paid by customer
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="times" class="class-dismissed-app" style="position:absolute;bottom:40px;right:55px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Dismissed</span>
                                        <h3 class="title h4">12-05-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application to be dismissed by TASAC CFA
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-content">
                                    <div class="circle">
                                        <span class="homebox">
                                            <font-awesome-icon icon="list" class="class-closed-app" style="position:absolute;bottom:40px;right:42px;"/>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <span class="year">Closed</span>
                                        <h3 class="title h4">12-05-2022 13:24:30</h3>
                                        <p class="description">
                                            Date and time for the application to be closed by TASAC CFA / FAU
                                        </p>
                                        <div class="icon"><span></span></div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="row text-right">
                    <div class="col-md-12">
                        <hr />
                    </div>
                    <div class="col-md-12">
                        <button class="btn btn-default btn-fill" @click.prevent="closeModal" >
                        Close
                        </button>
                    </div>
                </div>
            </div>
            </section>

            <global-modal v-if="globalLoader">
            <div slot="globalLoader">
                <div class="row text-center">
                <div class="col-md-12">
                    Preparing requested file, Please wait
                </div>
                <div class="col-md-12">
                    <br>
                </div>
                <div class="col-md-12">
                    <pulse-loader
                    :color="color"
                    :size="size"
                    />
                </div>
                </div>
            </div>
            </global-modal>

            <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
            @downloadPdf="openPdfViewer(attachmentUrlForDownload, attachmentNameForDownload + '_FOR_APPLICATION_WITH_FILE_NUMBER_' + selectedRequest.fileNo)"></pdf-viewer>
    </card>
</template>

<script>

import PdfViewer from '../../../components/pdfViewer.vue'
import GlobalModal from '../../../components/globalLoader'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { mapGetters, mapActions } from 'vuex'
import API from "../../../api";

export default {

    components: {

        PdfViewer,
        GlobalModal,
        PulseLoader

    },

    computed: {

        ...mapGetters('global', [

        'getGlobalLoader'

        ])

    },

    data () {

        return {

            globalLoader: false,
            showPdfViewer: false,
            scr: '',
            attachmentNameForDownload: '',
            attachmentUrlForDownload: '',
            color: 'green',
            size: '12px',
            mySize: '9px',

            timelineData: [

                { date: this.selectedRequest.requestDate, icon: 'circle', class: 'class-new-app mrPx42', status: 'Draft', description: 'Date and time for the application to be drafted by customer without compulsory attachments and not sent to TASAC '},
                { date: this.selectedRequest.submissionDate, icon: 'hands', class: 'class-new-app mrPx32', status: 'Received', description: 'Date and time for the application received by TASAC CFA'},
                { date: this.selectedRequest.majorRejectionDate, icon: 'exclamation-triangle', class: 'class-rejected-mojor-app mrPx35', status: 'Major Rejected', description: 'Date and time for the application to be rejected with major issues by TASAC CFA'},
                { date: this.selectedRequest.minorRejectionDate, icon: 'exclamation-triangle', class: 'class-rejected-minor-app mrPx35', status: 'Minor Rejected', description: ' Date and time for the application to be flaged with minor issues by TASAC CFA'},
                { date: this.selectedRequest.returnDate, icon: 'edit', class: 'class-returned-app mrPx38', status: 'Returned', description: 'Date and time for the application to be returned to TASAC CFA by customer after attending major raised issues'},
                { date: this.selectedRequest.approveDate, icon: 'check', class: 'class-approved-app mrPx38', status: 'Accepted', description: 'Date and time for the application accepted by TASAC CFA after verifying all details and attached documents'},
                { date: this.selectedRequest.assessmentDate, icon: 'luggage-cart', class: 'class-assessed-app mrPx38', status: 'Tariffed', description: 'Date and time for the application to be tariffed by TASAC CFA for subjecting service charge'},
                { date: this.selectedRequest.assessmentRejectionDate, icon: 'luggage-cart', class: 'class-rejected-assessment-app mrPx38', status: 'Rejected Tariff', description: 'Date and time for the application tariff to be rejected by TASAC CFA'},
                { date: this.selectedRequest.dismissedDate, icon: 'times', class: 'class-dismissed-app mrPx55', status: 'Dismissed', description: 'Date and time for the application to be dismissed by TASAC CFA'},
                { date: this.selectedRequest.chargeDate, icon: 'file-invoice-dollar', class: 'class-charged-app mrPx48', status: 'Charged', description: 'Date and time for the application to be charged by TASAC CFA'},
                { date: this.selectedRequest.chargeRejectionDate, icon: 'file-invoice-dollar', class: 'class-rejected-charge-app mrPx48', status: 'Rejected Charge', description: 'Date and time for the application charge to be rejected by TASAC FAU'},
                { date: this.selectedRequest.billingDate, icon: 'file-invoice-dollar', class: 'class-billed-app mrPx48', status: 'Billed', description: ' Date and time for the application charge to be billed by TASAC FAU'},
                { date: this.selectedRequest.billApprovalDate, icon: 'check', class: 'class-bill-approved-app mrPx38', status: 'Bill Approved', description: 'Date and time for the application bill to be generated by TASAC FAU'},
                { date: this.selectedRequest.billPaymentDate, icon: 'dollar-sign', class: 'class-paid-app mrPx58', status: 'Bill Paid', description: 'Date and time for the application bill to be paid by customer'},
                { date: this.selectedRequest.closingDate, icon: 'list', class: 'class-closed-app mrPx42', status: 'Closed', description: 'Date and time for the application to be closed by TASAC CFA / FAU'}

            ],

            assessment: {}

        }

    },

    created () {

    this.getAssesmentByRequestId()
    console.log('this.assessment', this.assessment)

    },

    props: {

        selectedRequest: {

            type: Object,
            required: false

        }

    },


    methods: {

        ...mapActions('global', [

            'loadFileFromMinio'

        ]),

        openPdfViewer (fileUrl, fileName, action) {

            console.log('nafika')

            this.attachmentNameForDownload = fileName
            this.attachmentUrlForDownload = fileUrl

            this.getFileFromMinio(fileUrl, fileName, action).then(() => {

                this.showPdfViewer = true

            }).catch(() => {})

        },

        closePdfViewer () {

            this.showPdfViewer = false
            this.scr = ''
            this.attachmentNameForDownload = ''
            this.attachmentUrlForDownload = ''

        },

        loadParameters (fileUrl, fileName) {

        return new Promise((resolve) => {

            this.$store.dispatch('global/fileUrling', fileUrl);
            this.$store.dispatch('global/fileNaming', fileName);

            resolve()

        })

        },

        getFileFromMinio (fileUrl, fileName, action) {

            return new Promise((resolve, reject) => {

                this.loadParameters(fileUrl, fileName).then(() => {

                this.loadFileFromMinio({action}).then((res) => {

                    if(res[1].status === 204) {

                        this.loadNotification('warn', 4000, '',
                        'File not found, please contact system admin',
                        'response', 1000 , true  , true)

                    } else {

                        this.scr = res[0]
                        resolve()


                    }

                        this.globalLoader = this.getGlobalLoader

                    }).catch(() => {

                    this.globalLoader = false

                    })

                    this.globalLoader = this.getGlobalLoader

                }).catch(() => {

                    this.globalLoader = false

                })

            })

        },

        closeModal() {

            this.$emit('closeModal')

        },

        getAssesmentByRequestId() {

            this.loadingPreData = true;

            this.edit = false;
            API.getRequestAssessmentNotice(this.selectedRequest.requestId).then((response) => {

                this.assessment = response.data.data

            }).catch((e)=>{

                this.myloader = false;
                this.loadingPreData = false

            })

        },

    }
    
}
</script>
<style scoped>
.download-link {
        color: green;
        font-weight: bold;
    }

    .download-link:hover {
        cursor: pointer;
        color: #d59a18 !important;
    }
.is-active-timeline { opacity:.1; }
.is-active-timeline:hover { cursor: not-allowed; }
.mrPx38 { position:absolute; bottom:40px; right: 38px }
.mrPx42 { position:absolute; bottom:40px; right: 42px }
.mrPx32 { position:absolute; bottom:40px; right: 32px }
.mrPx35 { position:absolute; bottom:40px; right: 35px }
.mrPx55 { position:absolute; bottom:40px; right: 55px }
.mrPx48 { position:absolute; bottom:40px; right: 48px }
.mrPx58 { position:absolute; bottom:40px; right: 58px }
.main-timeline{ position: relative; }
.main-timeline:before,
.main-timeline:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.main-timeline:before{
    content: "";
    width: 3px;
    height: 100%;
    background: #bababa;
    position: absolute;
    top: 0;
    left: 50%;
}
.main-timeline .timeline{
    width: 50%;
    float: left;
    position: relative;
    z-index: 1;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.main-timeline .timeline:first-child:before,
.main-timeline .timeline:last-child:before{
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    border: 4px solid rgba(211,207,205,1);
    position: absolute;
    top: 0;
    right: -14px;
    z-index: 1;
}
.main-timeline .timeline:last-child:before{
    top: auto;
    bottom: 0;
}
.main-timeline .timeline:last-child:nth-child(even):before{
    right: auto;
    left: -12px;
    bottom: -2px;
}
.main-timeline .timeline-content{
    text-align: center;
    margin-top: 8px;
    position: relative;
    transition: all 0.3s ease 0s;
}
.main-timeline .timeline-content:before{
    content: "";
    width: 100%;
    height: 5px;
    background: rgba(211,207,205,1);
    position: absolute;
    top: 88px;
    left: 0;
    z-index: -1;
}
.main-timeline .circle{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: #fff;
    border: 8px solid rgba(211,207,205,1);
    float: left;
    margin-right: 25px;
    position: relative;
}
.main-timeline .circle:before{
    content: "";
    width: 26px;
    height: 30px;
    background: rgba(211,207,205,1);
    margin: auto;
    position: absolute;
    top: 0;
    right: -33px;
    bottom: 0;
    z-index: -1;
    box-shadow: inset 7px 0 9px -7px #444;
}
.main-timeline .circle span{
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    line-height: 268px;
    font-size: 80px;
    color: #454344;
}
.main-timeline .circle span:before,
.main-timeline .circle span:after{
    content: "";
    width: 28px;
    height: 50px;
    background: #fff;
    border-radius: 0 0 0 21px;
    margin: auto;
    position: absolute;
    top: -54px;
    right: -33px;
    bottom: 0;
    z-index: -1;
}
.main-timeline .circle span:after{
    border-radius: 21px 0 0 0;
    top: 0;
    bottom: -56px;
}
.main-timeline .circle .img{
    vertical-align: initial;
    border-radius: 50%;
}
.main-timeline .content{
    display: table;
    padding-right: 40px;
    position: relative;
}
.main-timeline .year{
    display: block;
    padding: 10px;
    margin: 10px 0 50px 0;
    background: rgba(211,207,205,1);
    border-radius: 7px;
    font-size: 25px;
    color: #fff;
}
.main-timeline .title{
    font-size: 25px;
    font-weight: bold;
    color: rgba(211,207,205,1);
    margin-top: 0;
}
.main-timeline .description{
    font-size: 14px;
    color: #333;
    text-align: justify;
}
.main-timeline .icon{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    border: 4px solid rgba(211,207,205,1);
    position: absolute;
    top: 78px;
    right: -14px;
}
.main-timeline .icon:before{
    content: "";
    width: 15px;
    height: 25px;
    background: rgba(211,207,205,1);
    margin: auto;
    position: absolute;
    top: -1px;
    left: -15px;
    bottom: 0;
    z-index: -1;
}
.main-timeline .icon span:before,
.main-timeline .icon span:after{
    content: "";
    width: 21px;
    height: 25px;
    background: #fff;
    border-radius: 0 0 21px 0;
    margin: auto;
    position: absolute;
    top: -30px;
    left: -15px;
    bottom: 0;
    z-index: -1;
}
.main-timeline .icon span:after{
    border-radius: 0 21px 0 0;
    top: 0;
    left: -15px;
    bottom: -30px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .circle{
    float: right;
}
.main-timeline .timeline:nth-child(2n) .circle{
    margin: 0 0 0 25px;
}
.main-timeline .timeline:nth-child(2n) .circle:before{
    right: auto;
    left: -33px;
    box-shadow: -7px 0 9px -7px #444 inset;
}
.main-timeline .timeline:nth-child(2n) .circle span:before,
.main-timeline .timeline:nth-child(2n) .circle span:after{
    right: auto;
    left: -33px;
    border-radius: 0 0 21px 0;
}
.main-timeline .timeline:nth-child(2n) .circle span:after{
    border-radius: 0 21px 0 0;
}
.main-timeline .timeline:nth-child(2n) .content{
    padding: 0 0 0 40px;
    margin-left: 2px;
}
.main-timeline .timeline:nth-child(2n) .icon{
    right: auto;
    left: -14px;
}
.main-timeline .timeline:nth-child(2n) .icon:before,
.main-timeline .timeline:nth-child(2n) .icon span:before,
.main-timeline .timeline:nth-child(2n) .icon span:after{
    left: auto;
    right: -15px;
}
.main-timeline .timeline:nth-child(2n) .icon span:before{
    border-radius: 0 0 0 21px;
}
.main-timeline .timeline:nth-child(2n) .icon span:after{
    border-radius: 21px 0 0 0;
}
.main-timeline .timeline:nth-child(2){
    margin-top: 180px;
}
.main-timeline .timeline:nth-child(odd){
    margin: -175px 0 0 0;
}
.main-timeline .timeline:nth-child(even){
    margin-bottom: 180px;
}
.main-timeline .timeline:first-child,
.main-timeline .timeline:last-child:nth-child(even){
    margin: 0;
}
@media only screen and (max-width: 990px){
    .main-timeline:before{ left: 100%; }
    .main-timeline .timeline{
        width: 100%;
        float: none;
        margin-bottom: 20px !important;
    }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before{
        left: auto !important;
        right: -13px !important;
    }
    .main-timeline .timeline:nth-child(2n) .circle{
        float: left;
        margin: 0 25px 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .circle:before{
        right: -33px;
        left: auto;
        box-shadow: 7px 0 9px -7px #444 inset;
    }
    .main-timeline .timeline:nth-child(2n) .circle span:before,
    .main-timeline .timeline:nth-child(2n) .circle span:after{
        right: -33px;
        left: auto;
        border-radius: 0 0 0 21px;
    }
    .main-timeline .timeline:nth-child(2n) .circle span:after{
        border-radius: 21px 0 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .content{
        padding: 0 40px 0 0;
        margin-left: 0;
    }
    .main-timeline .timeline:nth-child(2n) .icon{
        right: -14px;
        left: auto;
    }
    .main-timeline .timeline:nth-child(2n) .icon:before,
    .main-timeline .timeline:nth-child(2n) .icon span:before,
    .main-timeline .timeline:nth-child(2n) .icon span:after{
        left: -15px;
        right: auto;
    }
    .main-timeline .timeline:nth-child(2n) .icon span:before{
        border-radius: 0 0 21px 0;
    }
    .main-timeline .timeline:nth-child(2n) .icon span:after{
        border-radius: 0 21px 0 0;
    }
    .main-timeline .timeline:nth-child(2),
    .main-timeline .timeline:nth-child(odd),
    .main-timeline .timeline:nth-child(even){
        margin: 0;
    }
}
@media only screen and (max-width: 480px){
    .main-timeline:before{ left: 0; }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before{
        left: -12px !important;
        right: auto !important;
    }
    .main-timeline .circle,
    .main-timeline .timeline:nth-child(2n) .circle{
        width: 130px;
        height: 130px;
        float: none;
        margin: 0 auto;
    }
    .main-timeline .timeline-content:before{
        width: 99.5%;
        top: 68px;
        left: 0.5%;
    }
    .main-timeline .circle span{
        line-height: 115px;
        font-size: 60px;
    }
    .main-timeline .circle:before,
    .main-timeline .circle span:before,
    .main-timeline .circle span:after,
    .main-timeline .icon{
        display: none;
    }
    .main-timeline .content,
    .main-timeline .timeline:nth-child(2n) .content{
        padding: 0 10px;
    }
    .main-timeline .year{
        margin-bottom: 15px;
    }
    .main-timeline .description{
        text-align: center;
    }
}
</style>
<style>

</style>