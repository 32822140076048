<template>
  <div>
    <div class="row text-center" v-if="additionalFieldFromTeSWSLoader">
      <div class="col-md-12 loading-text-color">
        Loading additional fields, please waiti
      </div>
      <div class="col-md-12">
        <pulse-loader :color="color" :size="size" />
      </div>
    </div>
    <div class="row" v-if="!additionalFieldFromTeSWSLoader">
    <div class="col-md-10">
      <b>Use below form to fill in other details associated with BL/Airway Bill/RCN </b> - [ <b>
      {{ requestData.blNumber }}</b> ]
    </div>
    <div class="col-md-2">
        <!-- <button type="submit" class="btn btn-primary btn-space float-right custom-btn"
              @click.prevent="updateSbmsBlFromTeswsBl"
              data-dismiss="modal" v-if="afterTansadTeswsBl !== '' && afterTansadTeswsBl.toUpperCase() !== requestData.blNumber.toUpperCase()">
        Replace B/L
      </button> -->
    </div>

    <div class="col-md-12">
      <hr />
    </div>

    <form class="col-md-12" method="post" @submit.prevent="saveOtherRequestDetails">
      <div class="row">
        <div class="col-md-3">
          <!--                    <base-input class="special-bottom" label="Cargo Volume" placeholder="Cargo Volume"-->
          <!--                                type="text" v-model="afterCargoVolume"></base-input>-->
          <!-- <base-input class="special-bottom" placeholder="Cargo Volume" @input="commafyThisAmountWithDecimal('afterCargoVolume')"
          label="Cargo Volume" type="text" v-model="afterCargoVolume"></base-input> -->
          <base-input class="special-bottom" placeholder="TANSAD/TeSWS Bill Of Lading"
          label="TANSAD/TeSWS Bill Of Lading" type="text" v-model="afterTansadTeswsBl"></base-input>
        </div>
        <div class="col-md-3">
          <!--                    <base-input class="special-bottom" label="Cargo Tonnage" placeholder="Cargo Tonnage"-->
          <!--                                type="text" v-model="afterCargoTonnage"></base-input>-->
          <base-input class="special-bottom" placeholder="Cargo Tonnage" @input="commafyThisAmountWithDecimal('afterCargoTonnage')"
          label="Cargo Tonnage" type="text" v-model="afterCargoTonnage"></base-input>
        </div>
        <div class="col-md-3">
          <!--                    <base-input class="special-bottom" label="Cargo Name" placeholder="Cargo Name"-->
          <!--                                type="text" v-model="afterCargoName"></base-input>-->
          <base-input class="special-bottom" label="Discharge Date" placeholder="Discharge Date"
                      type="date" v-model="afterDischargeDate"></base-input>
        </div>
        <div class="col-md-3">
          <!--                    <base-input class="special-bottom" label="Cargo CBM" placeholder="Cargo CBM"-->
          <!--                                type="text" v-model="afterCargoCBM"></base-input>-->
          <!-- <base-input class="special-bottom" placeholder="Cargo CBM" @input="commafyThisAmountWithDecimal('afterCargoCBM')"
          label="Cargo CBM" type="text" v-model="afterCargoCBM"></base-input> -->
          <base-input class="special-bottom" placeholder="Warehouse"
          label="Warehouse" type="text" v-model="afterWarehouse"></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <!--                    <base-input class="special-bottom" label="Weight" placeholder="Weight"-->
          <!--                                type="text" v-model="afterWeight"></base-input>-->
          <!-- <base-input class="special-bottom" placeholder="Weight" @input="commafyThisAmountWithDecimal('afterWeight')"
          label="Weight" type="text" v-model="afterWeight"></base-input> -->
          <base-input class="special-bottom" placeholder="Reference Number" label="Reference Number" type="text" v-model="afterPadReference"></base-input>
        </div>
        <!-- <div class="col-md-3">
          <base-input class="special-bottom" placeholder="Quantity" @input="commafyThisAmountWithDecimal('afterQuantity')"
          label="Quantity" type="text" v-model="afterQuantity"></base-input>
        </div> -->
        <div class="col-md-3">
          <!-- <base-input class="special-bottom" label="Pre Alert Date" placeholder="Pre Alert Date"
                      type="date" v-model="afterPreAlertDate"></base-input> -->
          <base-input class="special-bottom" label="Entry/Exit Office" placeholder="Entry/Exit Office"
                      type="text" v-model="afterEntryOrExitOffice"></base-input>
        </div>
        <!-- <div class="col-md-3">
          <base-input class="special-bottom" label="PO Number" placeholder="PO Number"
                      type="text" v-model="afterPONumber"></base-input>
        </div> -->
        <div class="col-md-3" style="margin-top: 2px">
          <base-input class="special-bottom" label="Litres" placeholder="Litres" @input="commafyThisAmountWithDecimal('afterLitres')"
          type="text" v-model="afterLitres"></base-input>
        </div>
        <div class="col-md-3">
          <base-input class="special-bottom" label="TANSAD Number" placeholder="TANSAD Number"
                      type="text" v-model="afterTansadNo"></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <base-input class="special-bottom" label="TANSAD Date" placeholder="TANSAD Date"
                      type="date" v-model="afterTansadDate"></base-input>
        </div>
        <div class="col-md-3">
          <!--                    <base-input class="special-bottom" label="Custom Value" placeholder="Custom Value"-->
          <!--                                type="text" v-model="afterCustomValue"></base-input>-->
          <base-input class="special-bottom" placeholder="Customs Value" @input="commafyThisAmountWithDecimal('afterCustomValue')"
          label="Customs Value" type="text" v-model="afterCustomValue"></base-input>
          <!--                    <div v-if="afterCustomValueError">-->
          <!--                      <div class="error" v-if="!$v.afterCustomValue.numeric">Invalid Input</div>-->
          <!--                    </div>-->
        </div>
        <!-- <div class="col-md-3">
          <base-input class="special-bottom" label="ETA/ETD" placeholder="ETA/ETD"
                      type="date" v-model="afterETA"></base-input>
        </div> -->
        <!-- <div class="col-md-3">
          <base-input class="special-bottom" label="ATA/ATD" placeholder="ATA/ATD"
                      type="date" v-model="afterATA"></base-input>
        </div> -->
        <div class="col-md-3">
          <!--                    <base-input class="special-bottom" label="Weight" placeholder="Weight"-->
          <!--                                type="text" v-model="afterWeight"></base-input>-->
          <base-input class="special-bottom" placeholder="Gross Weight" @input="commafyThisAmountWithDecimal('afterWeight')"
          label="Gross Weight" type="text" v-model="afterGrossWeight"></base-input>
        </div>
        <div class="col-md-3">
          <!--                    <base-input class="special-bottom" label="Weight" placeholder="Weight"-->
          <!--                                type="text" v-model="afterWeight"></base-input>-->
          <base-input class="special-bottom" placeholder="Net Weight" @input="commafyThisAmountWithDecimal('afterWeight')"
          label="Net Weight" type="text" v-model="afterNetWeight"></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!--                    <base-input class="special-bottom" label="Weight" placeholder="Weight"-->
          <!--                                type="text" v-model="afterWeight"></base-input>-->
          <base-input class="special-bottom" placeholder="Location Of Goods"
          label="Location Of Goods" type="text" v-model="afterLocationOfGoods"></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label>Search Package Type</label>
          <model-list-select :list="packageTypes"
            v-model="selectedPackageTypeId"
            class="form-control"
            option-value="packageTypeId"
            option-text="packageTypeName"
            placeholder="Package Type">
          </model-list-select>
        </div>
        <div class="col-6">
          <base-input class="special-bottom" label="Number Of Packages" placeholder="Number of Packages" @input="commafyThisAmountWithDecimal('packageQuantity')"
          value="numberOfPackages" type="text" v-model="packageQuantity"></base-input>
          <div v-if="packageError">
            <div class="text-danger">Number of Packages is Required</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label>HS Code</label>
          <multi-select v-model="afterHSCode" placeholder="Search and add / remove HS CODE"
            :options="newHsCode"
            :selected-options="afterHSCode"
            hide-selected-options
            @select="addTag"></multi-select>
        </div>
        <div class="col-md-12">
          <br/>
          <h6 class="text-center" v-if="afterHSCode.length === 0">selected Items will Appear here</h6>
          <h6 class="text-center" v-if="afterHSCode.length > 0">selected Items</h6>
          <table class="table table-striped" v-if="afterHSCode.length > 0">
            <thead>
            <tr>
              <th>Action</th>
              <th>HS Code</th>
              <th>Description</th>
              <th>Item No.</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in afterHSCode" :key="item.value">
              <td>
                <font-awesome-icon class="delete-fa-icon minus-container" icon="trash-alt" @click="deleteItem(item)"/>
              </td>
              <td>{{item.text.split("-")[0]}}</td>
              <td style="width: 80%">{{item.text.split("-")[1]}}</td>
              <td>
              <!--                <base-input class="special-bottom" min="1"-->
              <!--                            value="item.itemNumber" type="number"-->
              <!--                            v-model="item.itemNumber"></base-input>-->
                <input type="number" class="form-control" id="itemNumber" value="item.itemNumber" v-model="item.itemNumber">
                <div v-if="ItemNumberError">
                  <div class="error" v-if="item.itemNumber === '' ">This field is required</div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <hr/>
        </div>
      </div> -->

      <!-- <div class="row">
        <div v-for="(rowIN , index) in rowsInvoiceNumber" v-bind:key="index" class="col-md-8">
          <div class="row" style="padding-left: 10px">
            <div class="col-md-6">
              <label>Invoice Number <b>{{rowsInvoiceNumber.indexOf(rowIN) + 1}}</b></label>
              <base-input class="special-bottom" placeholder="Invoice Number"
                          type="text" v-model="rowsInvoiceNumber[index].invoiceNumber"></base-input>
              <div v-if="afterInvoiceNumberError">
                <div class="error" v-if="rowIN.invoiceNumber === '' ">This field is required</div>
              </div>
            </div>
            <div class="col-md-2 text-center" style="margin-top:40px;">
              <font-awesome-icon class="delete-fa-icon minus-container" icon="trash-alt" v-if="rowsInvoiceNumber.length > 1"
              title="remove Invoice Number" @click="deleteRowInvoiceNumber(rowsInvoiceNumber.indexOf(rowIN))"/>
            </div>
          </div>
        </div>
        <div class="col-md-4" style="margin-top:40px;">
          <font-awesome-icon class="plus-container" icon="plus" title="add Invoice Number" @click="addRowInvoiceNumber"/>
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-12">
          <hr/>
        </div>
      </div>

      <div class="row">
        <div v-for="(rowC , index) in rowsContainer" v-bind:key="index" class="col-md-10">
          <div class="row" style="padding-left: 10px">
            <div class="col-md-3">
              <label>Container Number <b>{{rowsContainer.indexOf(rowC) + 1}}</b></label>
              <base-input class="special-bottom" placeholder="Container Number"
                          type="text" v-model="rowC.afterContainerNumber"></base-input>
              <div v-if="afterContainerNumberError">
                <div class="error" v-if="rowC.afterContainerNumber === '' ">This field is required</div>
              </div>
            </div>
            <div class="col-md-4">
            <!--              <label>Container Size <b>{{rowsContainer.indexOf(rowC) + 1}}</b></label>-->
            <!--              <base-input class="special-bottom" placeholder="Container Size"-->
            <!--                          type="text" v-model="rowC.afterSize"></base-input>-->
              <label>Container Size <b>{{rowsContainer.indexOf(rowC) + 1}}</b></label>
              <model-list-select
                :list="containerSizes"
                v-model="rowC.afterSize"
                class="form-control"
                option-value="id"
                option-text="name"
                placeholder="Container Size"
              >
              </model-list-select>
              <div v-if="afterSizeError">
                <div class="error" v-if="rowC.afterSize === '' ">This field is required</div>
              </div>
            </div>
            <div class="col-md-3">
              <label>Container Seal Number <b>{{rowsContainer.indexOf(rowC) + 1}}</b></label>
              <base-input class="special-bottom" placeholder="Container Seal Number"
                          type="text" v-model="rowC.afterSealNo"></base-input>
              <div v-if="afterSealNoError">
                <div class="error" v-if="rowC.afterSealNo === '' ">This field is required</div>
              </div>
            </div>
            <div class="col-md-2 text-center" style="margin-top:40px;">
              <font-awesome-icon class="delete-fa-icon minus-container" icon="trash-alt"
                                title="remove Invoice Number" v-if="rowsContainer.length > 1"
                                @click="deleteRowContainer(rowsContainer.indexOf(rowC))"/>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-center" style="margin-top:40px;">
          <font-awesome-icon class="plus-container" icon="plus" title="add container details"
                            @click="addRowContainer"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label>Cargo Remark</label>
          <textarea class="special-bottom form-control col-md-12" label="Cargo Remark" rows="5"
                    placeholder="Cargo Remark"
                    type="date" v-model="afterCargoRemark"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="row" v-if="!additionalFieldFromTeSWSLoader">
    <div class="col-md-12 mt-3">
      <button class="btn btn-default btn-fill float-right custom-btn-all" @click.prevent="closeModal()">Close</button>
      <button type="submit" class="btn btn-primary btn-space float-right mr-2 custom-btn"
              @click.prevent="saveOtherRequestDetails"
              data-dismiss="modal" v-if="cargoId === null && !disabled">
        Save
      </button>
      <button type="submit" class="btn btn-primary btn-space float-right mr-2 custom-btn"
              @click.prevent="editOtherRequestDetails"
              data-dismiss="modal" v-if="cargoId !== null && !disabled">
        Save
      </button>
    </div>
  </div>
</div>


</template>

<script>
import API from "../../../api/index";
import API_CFA from "../../../api/cfa";
import  { ModelListSelect, MultiSelect } from 'vue-search-select';
import ContainerSizes from "../../../assets/files/container"
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

  name: "additionalFields",

  props: {

    requestData: {

        type: Object,
        default: {}

    },

    disabled: {

        type: Boolean,
        default: false

    }

  },

  components:{

    ModelListSelect,
    MultiSelect,
    PulseLoader

  },

  created() {

      this.loadHsCode();
      this.getPackageTypes();
      if(Object.keys(this.requestData).length) { 

        this.requestId = this.requestData.requestId
        this.loadAdditionalFieldFromTeSWS(this.requestId).then((response) => {

          this.getOtherRequestDetails(this.requestId, response)

        })

      }
      this.fomartContainerSize();

  },

  data(){

      return {

        color: 'green',
        size: '12px',
        loading: true,

        singleRequest: {},
        afterCargoVolume: "",
        afterCargoTonnage: "",
        afterCargoCBM: "",
        afterWeight: "",
        afterSize: "",
        afterSealNo: "",
        afterQuantity: "",
        afterPreAlertDate: "",
        afterPONumber: "",
        afterLitres: "",
        afterInvoiceNumber: "",
        afterHSCode: [],
        newHsCode: [],
        afterETA: "",
        afterATA: "",
        afterDischargeDate: "",
        afterCustomValue: "",
        afterCargoRemark: "",
        afterContainerNumber: "",
        packageTypes:[],
        selectedPackageTypeId:"",
        packageQuantity:"",
        packageError:false,
        ItemNumberError: false,
        afterTansadNo: '',
        afterTansadDate: '',
        afterGrossWeight: '',
        afterNetWeight: '',
        afterLocationOfGoods: '',
        afterPadReference: '',
        afterTansadTeswsBl: '',
        afterWarehouse: '',
        afterEntryOrExitOffice: '',
        rowsInvoiceNumber: [
          {'invoiceNumber': ''},
        ],
        cargoId:null,
        afterCargoVolumeError: false,
        afterCargoTonnageError: false,
        afterCargoCBMError: false,
        afterWeightError: false,
        afterSizeError: false,
        afterContainerNumberError: false,
        afterInvoiceNumberError: false,
        afterSealNoError: false,
        afterQuantityError: false,
        afterLitresError: false,
        afterCustomValueError: false,
        additionalFieldFromTeSWSLoader: false,
        requestId: '',

        rowsContainer: [
          {'afterContainerNumber': '', 'afterSize': '', 'afterSealNo': ''}
        ],

        containerSizes:[]

      }

  },

  methods:{

    updateSbmsBlFromTeswsBl () {

      this.$dialog.confirm('You are about to replace SBMS B/L with TANSAD/TeSWS B/L, are you sure?', {

        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, replace',
        cancelText: 'No, close',

      }).then((dialog) => {

        const request = {

          description: this.requestData.description,
          invoiceNo: this.requestData.invoiceNo,
          productType: this.requestData.productType,
          regimeId: this.requestData.regime.regimeId,
          requestId: this.requestData.requestId,
          requestName: null,
          requestType: this.requestData.requestType,
          poNumber: this.requestData.poNumber,
          cargoExpectedDate: this.requestData.cargoExpectedDate.split('+')[0] + '.000Z',
          blNumber: this.afterTansadTeswsBl

        }

        console.log('request', request)

        this.requestData.blNumber = this.afterTansadTeswsBl

        API.updateSingleRequest(JSON.stringify(request)).then(
            response => {

            if (response.data.status) {

                this.loadNotification('success', 5000, '', 'SMBS B/L has been successfully replaced with TANSAD/TeSWS B/L', 'response', 1000 , true  , true)

            } else {

                this.loadNotification('error', 5000, '', 'SMBS B/L not replaced, please retry', 'response', 1000 , true  , true)
                
            }
        }
        ).catch(() => {

            this.loadNotification('error', 5000, '', 'SMBS B/L not replaced, please retry', 'response', 1000 , true  , true)
            

        })

        dialog.close();

      });

    },

    commafyThisAmountWithDecimal (flag) {

          switch (flag) {

            case 'afterCargoVolume':

              this.afterCargoVolume = this.commafyAmountWithDecimal(this.afterCargoVolume);

              break;

            case 'afterLitres':

              this.afterLitres = this.commafyAmountWithDecimal(this.afterLitres);

              break;

            case 'afterCargoTonnage':

              this.afterCargoTonnage = this.commafyAmountWithDecimal(this.afterCargoTonnage);

              break;

            case 'afterQuantity':

              this.afterQuantity = this.commafyAmountWithoutDecimal(this.afterQuantity);

              break;

            case 'afterCargoCBM':

              this.afterCargoCBM = this.commafyAmountWithDecimal(this.afterCargoCBM);

              break;

            case 'afterCustomValue':

              this.afterCustomValue = this.commafyAmountWithDecimal(this.afterCustomValue);

              break;

            case 'packageQuantity':

              this.packageQuantity = this.commafyAmountWithoutDecimal(this.packageQuantity);

              break;

            default: //afterWeight

              this.afterWeight = this.commafyAmountWithDecimal(this.afterWeight);

              break;

          }

        },

    fomartContainerSize(){

      ContainerSizes.map((container) => {

        this.containerSizes.push(

          {name: 'Group:' + container.descriptionofCodeGroup + ' C-Code:' +container.containerCode, id: 'Group:' + container.descriptionofCodeGroup + ' Code:'+container.descriptionofContainerCode}

        );

      });

    },

    loadAdditionalFieldFromTeSWS (requestId) {

      this.additionalFieldFromTeSWSLoader = true

      return new Promise((resolve, reject) => {

          API_CFA.getAdditionalFieldFromTeSWS(requestId).then((response) => {

              if (response.data.status) {

                  // let objectArray = Object.entries(response.data.data)
                  // let newObjectArray = []
                  // for (let i = 0; i < objectArray.length; i++) {

                  //   let arrayObject = {

                  //       key: objectArray[i][0],
                  //       value: objectArray[i][1]

                  //   }

                  //   newObjectArray.push(arrayObject)
                    
                  // }
                  this.additionalFieldFromTeSWSLoader = false
                  resolve(response.data.data)

              }


          }).catch(()=>{

              this.additionalFieldFromTeSWSLoader = false
              reject()

            })

      })

    },

    getOtherRequestDetails(requestId, responseFromTeSWS) {

      this.additionalFieldFromTeSWSLoader = true

      API.getOtherRequestDetail(requestId).then(
        response => {

          if (response.data.status) {

            this.getSingleOtherDetails = response.data.data;
            
            // if (this.getSingleOtherDetails !== null) {

              if (this.getSingleOtherDetails !== null && this.getSingleOtherDetails.cargoHsCodes !== null && this.getSingleOtherDetails.cargoHsCodes.length !== 0) {

                for (let x = 0; x < this.getSingleOtherDetails.cargoHsCodes.length; x++) {

                  let IncomingObject = {

                    value: this.getSingleOtherDetails.cargoHsCodes[x].specifiedGood.specifiedGoodId + " - " + this.getSingleOtherDetails.cargoHsCodes[x].cargoHsCodeId,
                    text: this.getSingleOtherDetails.cargoHsCodes[x].specifiedGood.hsCode + " - " + this.getSingleOtherDetails.cargoHsCodes[x].specifiedGood.description,
                    itemNumber: this.getSingleOtherDetails.cargoHsCodes[x].cargoTitle

                  };

                  this.afterHSCode.push(IncomingObject);

                }

              } else if (responseFromTeSWS.cargoHsCodes !== null && responseFromTeSWS.cargoHsCodes.length !== 0) {

                for (let x = 0; x < responseFromTeSWS.cargoHsCodes.length; x++) {

                  let IncomingObject = {

                    value: responseFromTeSWS.cargoHsCodes[x].specifiedGood.specifiedGoodId + " - " + responseFromTeSWS.cargoHsCodes[x].cargoHsCodeId,
                    text: responseFromTeSWS.cargoHsCodes[x].specifiedGood.hsCode + " - " + responseFromTeSWS.cargoHsCodes[x].specifiedGood.description,
                    itemNumber: responseFromTeSWS.cargoHsCodes[x].cargoTitle

                  };

                  this.afterHSCode.push(IncomingObject);

                }

              } else {this.afterHSCode = []}


              if (this.getSingleOtherDetails !== null && this.getSingleOtherDetails.containerNumbers !== null && this.getSingleOtherDetails.containerNumbers.length !== 0) {

                this.containerNumbers = this.getSingleOtherDetails.containerNumbers;

              } if (responseFromTeSWS.containerNumbers !== null && responseFromTeSWS.containerNumbers.length !== 0) {

                this.containerNumbers = responseFromTeSWS.containerNumbers;

              } else {

                this.containerNumbers = [{'afterContainerNumber': '', 'afterSize': '', 'afterSealNo': ''}];

              }

              if (this.getSingleOtherDetails !== null && this.getSingleOtherDetails.invoices !== null && this.getSingleOtherDetails.invoices.length !== 0) {

                this.rowsInvoiceNumber = this.getSingleOtherDetails.invoices;

              } if (responseFromTeSWS.invoices !== null && responseFromTeSWS.invoices.length !== 0) {

                this.rowsInvoiceNumber = responseFromTeSWS.invoices;

              } else {

                this.rowsInvoiceNumber = [{'invoiceNumber': ''}];
              }

              if(this.getSingleOtherDetails !== null) {

                this.getSingleOtherDetails.customValue === null ? this.afterCustomValue = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.customValue)) : this.afterCustomValue = this.putComma(this.amountDecimalPlaces(this.getSingleOtherDetails.customValue))
                this.getSingleOtherDetails.cargoName === null ? this.afterCargoName = responseFromTeSWS.cargoName : this.afterCargoName = this.getSingleOtherDetails.cargoName
                this.getSingleOtherDetails.cargoCbm === null ? this.afterCargoCBM = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.cargoCbm)) : this.afterCargoCBM = this.putComma(this.amountDecimalPlaces(this.getSingleOtherDetails.cargoCbm))
                this.getSingleOtherDetails.cargoTonnage === null ? this.afterCargoTonnage = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.cargoTonnage)) : this.afterCargoTonnage = this.putComma(this.amountDecimalPlaces(this.getSingleOtherDetails.cargoTonnage))
                this.getSingleOtherDetails.cargoVolume === null ? this.afterCargoVolume = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.cargoVolume)) : this.afterCargoVolume = this.putComma(this.amountDecimalPlaces(this.getSingleOtherDetails.cargoVolume))
                this.getSingleOtherDetails.cargo_remarks === null ? this.afterCargoRemark = responseFromTeSWS.cargo_remarks : this.afterCargoRemark = this.getSingleOtherDetails.cargo_remarks
                this.getSingleOtherDetails.quantity === null ? this.afterQuantity = responseFromTeSWS.quantity : this.afterQuantity = this.getSingleOtherDetails.quantity
                this.afterSize = this.getSingleOtherDetails.size;
                this.getSingleOtherDetails.weight === null ? this.afterWeight = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.weight)) : this.afterWeight = this.putComma(this.amountDecimalPlaces(this.getSingleOtherDetails.weight))
                this.getSingleOtherDetails.litres === null ? this.afterLitres = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.litres)) : this.afterLitres = this.putComma(this.amountDecimalPlaces(this.getSingleOtherDetails.litres))
                this.afterSealNo = this.getSingleOtherDetails.sealNo;
                this.getSingleOtherDetails.packageTypeCd === null ? this.selectedPackageTypeId = responseFromTeSWS.packageTypeCd : this.selectedPackageTypeId = this.getSingleOtherDetails.packageTypeCd
                this.getSingleOtherDetails.numberOfPackages === null ? this.packageQuantity = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.numberOfPackages)) : this.packageQuantity = this.putComma(this.amountDecimalPlaces(this.getSingleOtherDetails.numberOfPackages), 0)
                this.getSingleOtherDetails.cargoId === null ? this.cargoId = responseFromTeSWS.cargoId : this.cargoId = this.getSingleOtherDetails.cargoId
                this.getSingleOtherDetails.poNumber === null ? this.afterPONumber = responseFromTeSWS.poNumber : this.afterPONumber = this.getSingleOtherDetails.poNumber
                this.getSingleOtherDetails.locationOfGoods === null ? this.afterLocationOfGoods = responseFromTeSWS.locationOfGoods : this.afterLocationOfGoods = this.getSingleOtherDetails.locationOfGoods
                this.getSingleOtherDetails.grossWeight === null ? this.afterGrossWeight = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.grossWeight)) : this.afterGrossWeight = this.putComma(this.amountDecimalPlaces(this.getSingleOtherDetails.grossWeight))
                this.getSingleOtherDetails.netWeight === null ? this.afterNetWeight = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.netWeight)) : this.afterNetWeight = this.putComma(this.amountDecimalPlaces(this.getSingleOtherDetails.netWeight))
                this.getSingleOtherDetails.tansadNo === null ? this.afterTansadNo = responseFromTeSWS.tansadNo : this.afterTansadNo = this.getSingleOtherDetails.tansadNo
                this.getSingleOtherDetails.tansadDate === null ? this.afterTansadDate = responseFromTeSWS.tansadDate.split('/')[2] + '-' + responseFromTeSWS.tansadDate.split('/')[1] + '-' + responseFromTeSWS.tansadDate.split('/')[0] 
                                      : 
                                      this.afterTansadDate = this.getSingleOtherDetails.tansadDate.split('/')[2] + '-' + this.getSingleOtherDetails.tansadDate.split('/')[1] + '-' + this.getSingleOtherDetails.tansadDate.split('/')[0]
                this.getSingleOtherDetails.padReference === null ? this.afterPadReference = responseFromTeSWS.padReference : this.afterPadReference = this.getSingleOtherDetails.padReference

              } else {

                this.afterCustomValue = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.customValue))
                this.afterCargoName = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.cargoName))
                this.afterCargoCBM = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.cargoCbm))
                this.afterCargoTonnage = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.cargoTonnage))
                this.afterCargoVolume = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.cargoVolume))
                this.afterCargoRemark = responseFromTeSWS.cargo_remarks
                this.afterQuantity = responseFromTeSWS.quantity
                // this.afterSize = this.getSingleOtherDetails.size;
                this.afterWeight = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.weight))
                this.afterLitres = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.litres))
                // this.afterSealNo = this.getSingleOtherDetails.sealNo;
                this.selectedPackageTypeId = responseFromTeSWS.packageTypeCd
                this.packageQuantity = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.numberOfPackages), 0)
                this.cargoId = responseFromTeSWS.cargoId
                this.afterPONumber = responseFromTeSWS.poNumber
                this.afterLocationOfGoods = responseFromTeSWS.locationOfGoods
                this.afterGrossWeight = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.grossWeight))
                this.afterNetWeight = this.putComma(this.amountDecimalPlaces(responseFromTeSWS.netWeight))
                this.afterTansadNo = responseFromTeSWS.tansadNo
                this.afterTansadDate = responseFromTeSWS.tansadDate !== null ? responseFromTeSWS.tansadDate.split('/')[2] + '-' + responseFromTeSWS.tansadDate.split('/')[1] + '-' + responseFromTeSWS.tansadDate.split('/')[0] : null
                this.afterPadReference = responseFromTeSWS.padReference

              }

              if(this.afterCustomValue === '0.00') { this.afterCustomValue = '' }
              if(this.afterCargoCBM === '0.00') { this.afterCargoCBM = '' }
              if(this.afterCargoTonnage === '0.00') { this.afterCargoTonnage = '' }
              if(this.afterCargoVolume === '0.00') { this.afterCargoVolume = '' }
              if(this.afterWeight === '0.00') { this.afterWeight = '' }
              if(this.afterLitres === '0.00') { this.afterLitres = '' }
              if(this.packageQuantity === '0.00') { this.packageQuantity = '' }
              if(this.afterGrossWeight === '0.00') { this.afterGrossWeight = '' }
              if(this.afterNetWeight === '0.00') { this.afterNetWeight = '' }

              for (let x = 0; x < this.packageTypes.length; x++) {

                if(parseInt(this.selectedPackageTypeId) === this.packageTypes[x].packageTypeIdToSave) {

                  this.selectedPackageTypeId = this.packageTypes[x].packageTypeCode
                  break

                }
                
              }

              if (this.getSingleOtherDetails !== null && this.getSingleOtherDetails.preAlertDate !== null) {

                this.afterPreAlertDate = this.dateToHuman(this.getSingleOtherDetails.preAlertDate).split('-')[2] + 
                                '-' +
                                this.dateToHuman(this.getSingleOtherDetails.preAlertDate).split('-')[1] + 
                                '-' + 
                                this.dateToHuman(this.getSingleOtherDetails.preAlertDate).split('-')[0];

              } else if (responseFromTeSWS.preAlertDate !== null) {

                this.afterPreAlertDate = this.dateToHuman(responseFromTeSWS.preAlertDate).split('-')[2] + 
                                '-' +
                                this.dateToHuman(responseFromTeSWS.preAlertDate).split('-')[1] + 
                                '-' + 
                                this.dateToHuman(responseFromTeSWS.preAlertDate).split('-')[0];

              } else {

                this.afterPreAlertDate = "";

              }

              if (this.getSingleOtherDetails !== null && this.getSingleOtherDetails.estimatedTimeArrival !== null) {

                this.afterETA = this.dateToHuman(this.getSingleOtherDetails.estimatedTimeArrival).split('-')[2] + 
                                '-' +
                                this.dateToHuman(this.getSingleOtherDetails.estimatedTimeArrival).split('-')[1] + 
                                '-' + 
                                this.dateToHuman(this.getSingleOtherDetails.estimatedTimeArrival).split('-')[0];
              
                this.alertDay = this.calculateExpectedTimeLeft(this.dateToHuman(this.getSingleOtherDetails.estimatedTimeArrival));

              } else if (responseFromTeSWS.estimatedTimeArrival !== null) {

                this.afterETA = this.dateToHuman(responseFromTeSWS.estimatedTimeArrival).split('-')[2] + 
                                '-' +
                                this.dateToHuman(responseFromTeSWS.estimatedTimeArrival).split('-')[1] + 
                                '-' + 
                                this.dateToHuman(responseFromTeSWS.estimatedTimeArrival).split('-')[0];

                this.alertDay = this.calculateExpectedTimeLeft(this.dateToHuman(responseFromTeSWS.estimatedTimeArrival));

              } else {

                this.afterETA = "";

              }

              if (this.getSingleOtherDetails !== null && this.getSingleOtherDetails.actualTimeArrival !== null) {

                this.afterATA = this.dateToHuman(this.getSingleOtherDetails.actualTimeArrival).split('-')[2] + 
                                '-' +
                                this.dateToHuman(this.getSingleOtherDetails.actualTimeArrival).split('-')[1] + 
                                '-' + 
                                this.dateToHuman(this.getSingleOtherDetails.actualTimeArrival).split('-')[0];

              } else if (responseFromTeSWS.actualTimeArrival !== null) { 

                this.afterATA = this.dateToHuman(responseFromTeSWS.actualTimeArrival).split('-')[2] + 
                                '-' +
                                this.dateToHuman(responseFromTeSWS.actualTimeArrival).split('-')[1] + 
                                '-' + 
                                this.dateToHuman(responseFromTeSWS.actualTimeArrival).split('-')[0];

              }  else {

                this.afterATA = "";

              }

              if (this.getSingleOtherDetails !== null && this.getSingleOtherDetails.dischargeDate !== null) {

                this.afterDischargeDate = this.dateToHuman(this.getSingleOtherDetails.dischargeDate).split('-')[2] + 
                                '-' +
                                this.dateToHuman(this.getSingleOtherDetails.dischargeDate).split('-')[1] + 
                                '-' + 
                                this.dateToHuman(this.getSingleOtherDetails.dischargeDate).split('-')[0];

              } else if (responseFromTeSWS.dischargeDate !== null) { 

                this.afterDischargeDate = this.dateToHuman(responseFromTeSWS.dischargeDate).split('-')[2] + 
                                '-' +
                                this.dateToHuman(responseFromTeSWS.dischargeDate).split('-')[1] + 
                                '-' + 
                                this.dateToHuman(responseFromTeSWS.dischargeDate).split('-')[0];

              } else {

                this.afterDischargeDate = ''

              }

              this.additionalFieldFromTeSWSLoader = false

            // }

          } else {}

        }).catch(() => {

          this.additionalFieldFromTeSWSLoader = false

      });

    },

    calculateExpectedTimeLeft (expectedDate) {

        // expectedDate = "02-12-2021"
        let dayMessage = ''
        let expectedDiff = ''

        if(expectedDate !== null && expectedDate !== '') {

            let expectedDate1 = expectedDate.split('-')
            let todayDate = new Date()

            // console.log('todayDate', todayDate.toISOString().split('T')[0])

            // console.log('today', this.$moment().format('DD-MM-YYYY'))
            // console.log('expectedDate', expectedDate)

            // console.log('this.$moment(expectedDate).format("YYYY, MM, DD")', this.$moment(expectedDate1[2] + '-' + expectedDate1[1] + '-' + expectedDate1[0]).format('YYYY, MM, DD'))
            // console.log('this.$moment().format("YYYY, MM, DD")', this.$moment(new Date()).format('YYYY, MM, DD'))

            // console.log('new Date', new Date(this.$moment(expectedDate1[2] + '-' + expectedDate1[1] + '-' + expectedDate1[0]).format('YYYY, MM, DD')))

            let expectedDateFormatted = this.$moment(new Date(this.$moment(expectedDate1[2] + '-' + expectedDate1[1] + '-' + expectedDate1[0]).format('YYYY, MM, DD')))
            let todayDateFormatted = this.$moment(new Date(this.$moment(todayDate.toISOString().split('T')[0]).format('YYYY, MM, DD')))
            // let expectedDateFormatted = this.$moment(this.$moment(new Date()).format('YYYY, MM, DD'));
            // console.log('a.diff 1', expectedDateFormatted.diff(todayDateFormatted, 'days'))

            expectedDiff = expectedDateFormatted.diff(todayDateFormatted, 'days')

            //{{ expectedDiff  <= 1 ? (-1*expectedDiff) === 1 ? 'Has passed by ' + (-1*expectedDiff) + ' day' : 'Has passed by ' + (-1*expectedDiff) + ' days'  :
            //expectedDiff === 1 ? expectedDiff + ' day remaining' : expectedDiff + ' days remaining' }}

            // console.log('this.expectedDiff', this.expectedDiff)

            if(expectedDiff < 0) {

                if((expectedDiff * -1) === 1) {

                    dayMessage = expectedDate + '( Passed by 1 day )'

                } else {

                    dayMessage = expectedDate + '( Passed by '+ (expectedDiff * -1) + ' days )'

                }

            } else if (expectedDiff === 0) {

                    dayMessage = expectedDate + '( Today )'

            } else {

                if(expectedDiff === 1) {

                    dayMessage = expectedDate + '( 1 day remaining )'

                } else {

                    dayMessage = expectedDate + '( ' + expectedDiff + ' days remaining )'

                }

            }


        }


        return dayMessage + '****' + expectedDiff;

    },

    loadHsCode() {

      API.getSpecifiedGoodAll().then(
        response => {

          if (response.data.status) {

            this.getHsCode = response.data.data;

            for (let x = 0; x < this.getHsCode.length; x++) {

              let object = {

                value: this.getHsCode[x].specifiedGoodId + " - " + this.initialValue,
                text: this.getHsCode[x].hsCode + " - " + this.getHsCode[x].description,
                itemNumber: ""


              };

              this.newHsCode.push(object);

            }
          } else {

          }
        }
      ).catch(() => {

        this.ErrorMessage = ", Record not added";
        this.SuccessAlert = false;
        this.ErrorAlert = true;
        this.SuccessMessage = "";
        this.SuccessAlert = false;
        this.myloader = false;

      });

    },

    getPackageTypes(){

      API.getPackageTypes().then((response)=>{

        response.data.data.map((data)=>{

          this.packageTypes.push({

            packageTypeCode:data.packageTypeCode,

            packageTypeId:data.packageTypeCode,

            packageTypeIdToSave:data.packageTypeId,

            packageTypeName:"(" + data.packageTypeCode + ") - " + data.packageTypeName

          })

        })


      }).catch(()=>{

      })

    },


    saveOtherRequestDetails() {

      let currentTime = "";
      let hours = new Date().getHours();
      let minute = new Date().getMinutes();
      let second = new Date().getSeconds();
      let millisecond = new Date().getMilliseconds();

      if (hours < 10) {
        hours = "0" + hours;
      }

      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }
      if (millisecond < 10) {
        millisecond = "00" + millisecond;
      }
      if (millisecond >= 10 && millisecond < 100) {
        millisecond = "0" + millisecond;
      }

      currentTime = "T" + hours + ":" + minute + ":" + second + "." + millisecond + "Z";

      let actualTimeArrival = "";
      let estimatedTimeArrival = "";
      let dischargeDate = "";
      let preAlertDate = "";
      let cargoHsCodes = [];
      let containerNumbers = [];
      let invoiceNumber = [];
      let selectedPackageTypeId = ''

      // HSCODE
      if(this.afterHSCode.length > 1) {

        for (let p = 0; p < this.afterHSCode.length; p++) {

          let hsCode = parseInt(this.afterHSCode[p].value.split("-")[0]);
          let hsCodeItemNumber = this.afterHSCode[p].itemNumber;

          let hsCodeObject = {

            "specifiedGoodId": hsCode,
            "cargoTitle": hsCodeItemNumber,

          };

          cargoHsCodes.push(hsCodeObject);

        }

      } else {

        cargoHsCodes = null

      }

      //CONTAINER
      let errorFree = 0;

      if (this.rowsContainer.length > 1) {

        for (let yxp = 0; yxp < this.rowsContainer.length; yxp++) {

          if (this.rowsContainer[yxp].afterSize === "" || this.rowsContainer[yxp].afterSealNo === ""
            || this.rowsContainer[yxp].afterContainerNumber === "") {

            if (this.rowsContainer[yxp].afterSealNo === "") {
              this.afterSealNoError = true;
            }
            if (this.rowsContainer[yxp].afterContainerNumber === "") {
              this.afterContainerNumberError = true;
            }
            if (this.rowsContainer[yxp].afterSize === "") {
              this.afterSizeError = true;
            }

            errorFree++;

          }

        }

      } else {

        containerNumbers = null

      }

      //PACKAGES
      let packageTypeError = 0;

      if(this.selectedPackageTypeId === "" && this.packageQuantity !== ""){

        this.packageError = true;
        packageTypeError++;

      }else if (this.selectedPackageTypeId !== "" && this.packageQuantity === "") {

        this.packageError = true;
        packageTypeError++;

      } else {

        this.packageError = false

      }

      if (errorFree === 0 && containerNumbers !== null) {

        for (let yx = 0; yx < this.rowsContainer.length; yx++) {

          let containerNumberObject = {

            "containerNumber": this.rowsContainer[yx].afterContainerNumber,
            "containerSealNo": this.rowsContainer[yx].afterSealNo,
            "containerSize": parseInt(this.rowsContainer[yx].afterSize)

          };

          containerNumbers.push(containerNumberObject);

        }

      }

      //INVOICE
      let errorFree1 = 0;

      if (this.rowsInvoiceNumber.length > 1) {

        for (let wyx = 0; wyx < this.rowsInvoiceNumber.length; wyx++) {

          if (this.rowsInvoiceNumber[wyx].invoiceNumber === "") {
            this.afterInvoiceNumberError = true;

            errorFree1++;
          }

        }

      } else {

        invoiceNumber = null

      }

      if (errorFree1 === 0 && invoiceNumber !== null) {

        for (let yxz = 0; yxz < this.rowsInvoiceNumber.length; yxz++) {

          let invoiceNumberObject = {

            "invoiceNumber": this.rowsInvoiceNumber[yxz].invoiceNumber

          };

          invoiceNumber.push(invoiceNumberObject);

        }

      }

      if (this.afterATA !== "") {

        actualTimeArrival = this.afterATA + currentTime

      }
      if (this.afterETA !== "") {

        estimatedTimeArrival = this.afterETA + currentTime

      }
      if (this.afterDischargeDate !== "") {

        dischargeDate = this.afterDischargeDate + currentTime

      }
      if (this.afterPreAlertDate !== "") {

        preAlertDate = this.afterPreAlertDate + currentTime

      }

      for (let x = 0; x < this.packageTypes.length; x++) {

        if(this.selectedPackageTypeId === this.packageTypes[x].packageTypeId) {

          selectedPackageTypeId = this.packageTypes[x].packageTypeIdToSave
          break

        }

        
      }

      let otherRequestDetails = {

        actualTimeArrival: actualTimeArrival !== '' ? actualTimeArrival : null,
        cargoGroupId: null,
        cargoCbm: this.afterCargoCBM !== '' ? parseFloat(this.removeComma(this.afterCargoCBM)) : null,
        cargoName: null,//this.afterCargoName,
        cargoTonnage: this.afterCargoTonnage !== '' ? parseFloat(this.removeComma(this.afterCargoTonnage)) : null,
        cargoVolume: this.afterCargoVolume !== '' ? parseFloat(this.removeComma(this.afterCargoVolume)) : null,
        cargo_remarks: this.afterCargoRemark,
        containerNumbers: containerNumbers,
        customValue: this.afterCustomValue !== '' ? parseFloat(this.removeComma(this.afterCustomValue)) : null,
        dischargeDate: dischargeDate !== '' ? dischargeDate : null,
        estimatedTimeArrival: estimatedTimeArrival !== '' ? estimatedTimeArrival : null,
        cargoHsCodes: cargoHsCodes,
        invoices: invoiceNumber,
        litres: this.afterLitres !== '' ? parseFloat(this.removeComma(this.afterLitres)) : null,
        poNumber: this.afterPONumber,
        preAlertDate: preAlertDate !== '' ? preAlertDate : null,
        quantity: this.afterQuantity !== '' ? parseFloat(this.removeComma(this.afterQuantity)) : null,
        requestId: this.requestId,
        sealNo: this.afterSealNo !== '' ? this.afterSealNo : null,
        size: this.afterSize !== '' ? this.afterSize : null,
        weight: this.afterWeight !== '' ? parseFloat(this.removeComma(this.afterWeight)) : null,
        packageTypeCd: selectedPackageTypeId,
        numberOfPackages: this.packageQuantity !== '' ? parseFloat(this.removeComma(this.packageQuantity)) : null,
        locationOfGoods: this.afterLocationOfGoods,
        grossWeight: this.afterGrossWeight !== '' ? parseFloat(this.removeComma(this.afterGrossWeight)) : null,
        netWeight: this.afterNetWeight !== '' ? parseFloat(this.removeComma(this.afterNetWeight)) : null,
        tansadNo: this.afterTansadNo,
        tansadDate: this.afterTansadDate.split('-')[2] + '/' + this.afterTansadDate.split('-')[1] + '/' + this.afterTansadDate.split('-')[0],
        padReference: this.afterPadReference

      };

      if (errorFree === 0 && errorFree1 === 0 && packageTypeError === 0) {

        this.otherRequestDetails = true;

        API.createOtherRequestDetails(JSON.stringify(otherRequestDetails)).then(
          response => {

            if (response.data.status) {

              this.ErrorMessage = "";
              this.SuccessAlert = true;
              this.ErrorAlert = false;

              this.loadNotification('success', 4000, 'Success',
                'Additional details have been successfully saved',
                'response', 1000 , true  , true);
              this.otherRequestDetails = false;
              window.scrollTo(10, 0);
              this.afterCargoVolume = "";
              this.afterCargoTonnage = "";
              this.afterCargoName = "";
              this.afterCargoCBM = "";
              this.afterWeight = "";
              this.afterSize = "";
              this.afterSealNo = "";
              this.afterQuantity = "";
              this.afterPreAlertDate = "";
              this.afterPONumber = "";
              this.afterLitres = "";
              this.afterInvoiceNumber = "";
              this.afterHSCode = [];
              this.afterETA = "";
              this.afterATA = "";
              this.afterDischargeDate = "";
              this.afterCustomValue = "";
              this.afterCargoRemark = "";
              this.afterContainerNumber = "";
              this.selectedPackageTypeId = "";
              this.packageQuantity = "",
              this.afterLocationOfGoods = ""
              this.afterGrossWeight = ""
              this.afterNetWeight = ""
              this.tansadNo = ""
              this.tansadDate = ""                

              actualTimeArrival = "";
              estimatedTimeArrival = "";
              dischargeDate = "";
              preAlertDate = "";
              selectedPackageTypeId = ''

              cargoHsCodes = [];
              containerNumbers = [];
              invoiceNumber = [];

              // this.loadAdditionalFieldFromTeSWS(this.requestId);
              this.loadAdditionalFieldFromTeSWS(this.requestId).then((response) => {

                  this.getOtherRequestDetails(this.requestId, response)

              })

            } else {
              this.loadNotification('error', 4000, 'Error',
                'Additional Details failed to be Saved',
                'response', 1000 , true  , true);

            }

          }).catch(() => {

          this.loadNotification('error', 4000, 'Error',
            'Additional Details failed to be Saved',
            'response', 1000 , true  , true);
          });

      }

    },

    editOtherRequestDetails() {

      let currentTime = "";
      let hours = new Date().getHours();
      let minute = new Date().getMinutes();
      let second = new Date().getSeconds();
      let millisecond = new Date().getMilliseconds();

      this.packageError = false

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }
      if (millisecond < 10) {
        millisecond = "00" + millisecond;
      }
      if (millisecond >= 10 && millisecond < 100) {
        millisecond = "0" + millisecond;
      }

      currentTime = "T" + hours + ":" + minute + ":" + second + "." + millisecond + "Z";

      let actualTimeArrival = "";
      let estimatedTimeArrival = "";
      let dischargeDate = "";
      let preAlertDate = "";
      let cargoHsCodes = [];
      let containerNumbers = [];
      let invoiceNumber = [];
      let packageTypeError = 0;
      let packageTypeId = "";
      let selectedPackageTypeId = ""

      // HSCODE
      if(this.afterHSCode.length > 1) {
        for (let p = 0; p < this.afterHSCode.length; p++) {

          let hsCode = parseInt(this.afterHSCode[p].value.split("-")[0]);
          let hsCodeId = parseInt(this.afterHSCode[p].value.split("-")[1]);
          let hsCodeItemNumber = this.afterHSCode[p].itemNumber;

          let hsCodeObject = {

            "specifiedGoodId": hsCode,
            "cargoTitle": hsCodeItemNumber,
            "cargoHsCodeId": hsCodeId

          };

          cargoHsCodes.push(hsCodeObject);

        }
      } else {

        cargoHsCodes = null

      }

      //CONTAINER
      let errorFree = 0;

      if (this.containerNumbers.length > 1) {

        for (let yxp = 0; yxp < this.containerNumbers.length; yxp++) {

          if (this.containerNumbers[yxp].containerNumber === "" || this.containerNumbers[yxp].containerSealNo === ""
            || this.containerNumbers[yxp].containerSize === "") {

            if (this.containerNumbers[yxp].containerSealNo === "") {
              this.afterSealNoError = true;
            }
            if (this.containerNumbers[yxp].containerNumber === "") {
              this.afterContainerNumberError = true;
            }
            if (this.containerNumbers[yxp].containerSize === "") {
              this.afterSizeError = true;
            }

            errorFree++;

          }

        }

      } else {

        containerNumbers = null

      }

      //PACKAGES
      if(this.selectedPackageTypeId === "" && this.selectedPackageTypeId === null && this.packageQuantity !== "" && this.packageQuantity !== null){

        this.packageError=true;
        packageTypeError++;

      }

      if(this.selectedPackageTypeId !== "" && this.selectedPackageTypeId !== null && this.packageQuantity === "" && this.packageQuantity === null){


        this.packageError=true;
        packageTypeError++;

      }

      if (errorFree === 0 && containerNumbers !== null) {

        for (let yx = 0; yx < this.containerNumbers.length; yx++) {

          let containerNumberObject = {

            "containerNumber": this.containerNumbers[yx].containerNumber,
            "containerSealNo": this.containerNumbers[yx].containerSealNo,
            "containerSize": parseInt(this.containerNumbers[yx].containerSize),
            "cargoContainerNumberId": parseInt(this.containerNumbers[yx].cargoContainerNumberId)

          };

          containerNumbers.push(containerNumberObject);

        }

      }

      //INVOICE
      let errorFree1 = 0;

      if (this.rowsInvoiceNumber.length > 1) {

        for (let wyx = 0; wyx < this.rowsInvoiceNumber.length; wyx++) {

          if (this.rowsInvoiceNumber[wyx].invoiceNumber === "") {
            this.afterInvoiceNumberError = true;

            errorFree1++;
          }

        }

      } else {

        invoiceNumber = null

      }

      if (errorFree1 === 0 && invoiceNumber !== null) {

        for (let yxz = 0; yxz < this.rowsInvoiceNumber.length; yxz++) {

          let invoiceNumberObject = {

            "invoiceNumber": this.rowsInvoiceNumber[yxz].invoiceNumber,
            "cargoInvoiceNumberId": this.rowsInvoiceNumber[yxz].cargoInvoiceNumberId

          };

          invoiceNumber.push(invoiceNumberObject);

        }

      }

      if (this.afterATA !== "") {

        actualTimeArrival = this.afterATA + currentTime

      }
      if (this.afterETA !== "") {

        estimatedTimeArrival = this.afterETA + currentTime

      }
      if (this.afterDischargeDate !== "") {

        dischargeDate = this.afterDischargeDate + currentTime

      }
      if (this.afterPreAlertDate !== "") {

        preAlertDate = this.afterPreAlertDate + currentTime

      }

      if(this.selectedPackageTypeId === null ) {

        packageTypeId = this.selectedPackageTypeId;

      } else {

        packageTypeId = this.selectedPackageTypeId.packageTypeId;

      }

      for (let x = 0; x < this.packageTypes.length; x++) {

        if(this.selectedPackageTypeId === this.packageTypes[x].packageTypeId) {

          selectedPackageTypeId = this.packageTypes[x].packageTypeIdToSave
          break

        }

        
      }

      let otherRequestDetails = {

        actualTimeArrival: actualTimeArrival !== '' ? actualTimeArrival : null,
        cargoGroupId: null,
        cargoCbm: this.afterCargoCBM !== '' ? parseFloat(this.removeComma(this.afterCargoCBM)) : null,
        cargoName: null,//this.afterCargoName,
        cargoTonnage: this.afterCargoTonnage !== '' ? parseFloat(this.removeComma(this.afterCargoTonnage)) : null,
        cargoVolume: this.afterCargoVolume !== '' ? parseFloat(this.removeComma(this.afterCargoVolume)) : null,
        cargo_remarks: this.afterCargoRemark,
        containerNumbers: containerNumbers,
        customValue: this.afterCustomValue !== '' ? parseFloat(this.removeComma(this.afterCustomValue)) : null,
        dischargeDate: dischargeDate !== '' ? dischargeDate : null,
        estimatedTimeArrival: estimatedTimeArrival !== '' ? estimatedTimeArrival : null,
        cargoHsCodes: cargoHsCodes,
        invoices: invoiceNumber,
        litres: this.afterLitres !== '' ? parseFloat(this.removeComma(this.afterLitres)) : null,
        poNumber: this.afterPONumber,
        preAlertDate: preAlertDate !== '' ? preAlertDate : null,
        quantity: this.afterQuantity !== '' ? parseFloat(this.removeComma(this.afterQuantity)) : null,
        requestId: this.requestId,
        // sealNo: this.afterSealNo !== '' ? this.afterSealNo : null,
        // size: this.afterSize !== '' ? this.afterSize : null,
        weight: this.afterWeight !== '' ? parseFloat(this.removeComma(this.afterWeight)) : null,
        packageTypeCd: selectedPackageTypeId,
        cargoId: this.cargoId,
        numberOfPackages: this.packageQuantity !== '' ? parseFloat(this.removeComma(this.packageQuantity)) : null,
        locationOfGoods: this.afterLocationOfGoods,
        grossWeight: this.afterGrossWeight !== '' ? parseFloat(this.removeComma(this.afterGrossWeight)) : null,
        netWeight: this.afterNetWeight !== '' ? parseFloat(this.removeComma(this.afterNetWeight)) : null,
        tansadNo: this.afterTansadNo,
        tansadDate: this.afterTansadDate.split('-')[2] + '/' + this.afterTansadDate.split('-')[1] + '/' + this.afterTansadDate.split('-')[0],
        padReference: this.afterPadReference

      };

      if (errorFree === 0 && errorFree1 === 0 && packageTypeError === 0) {

        this.otherRequestDetails = true;

        API.updateOtherRequestDetails(JSON.stringify(otherRequestDetails)).then(
          response => {

            if (response.data.status) {
              this.loadNotification('success', 4000, 'Success',
                'Details for This Request Have been Edited',
                'response', 1000 , true  , true);


              this.ErrorMessage = "";
              this.SuccessAlert = true;
              this.ErrorAlert = false;
              this.SuccessMessage = ", Aditional details have been successfully updated";
              this.otherRequestDetails = false;
              window.scrollTo(10, 0);
              this.afterCargoVolume = "";
              this.afterCargoTonnage = "";
              this.afterCargoName = "";
              this.afterCargoCBM = "";
              this.afterWeight = "";
              this.afterSize = "";
              this.afterSealNo = "";
              this.afterQuantity = "";
              this.afterPreAlertDate = "";
              this.afterPONumber = "";
              this.afterLitres = "";
              this.afterInvoiceNumber = "";
              this.afterHSCode = [];
              this.afterETA = "";
              this.afterATA = "";

              this.containerNumber = "";
              this.containerSize = "";
              this.containerSealNo = "";
              this.invoiceNumber = "";


              this.selectedPackageTypeId = "";
              this.packageQuantity = "";


              actualTimeArrival = "";
              estimatedTimeArrival = "";
              dischargeDate = "";
              preAlertDate = "";

              cargoHsCodes = [];
              containerNumbers = [];
              invoiceNumber = [];

              // this.loadAdditionalFieldFromTeSWS(this.requestId);
              this.loadAdditionalFieldFromTeSWS(this.requestId).then((response) => {

                  this.getOtherRequestDetails(this.requestId, response)

              })

            } else {

              this.loadNotification('error', 4000, 'Error',
                'Details for This Request Failed to be updated',
                'response', 1000 , true  , true);
            }

          }).catch(() => {
          this.loadNotification('error', 4000, 'Error',
            'Details for This Request Failed to be updated',
            'response', 1000 , true  , true);

        });

      }

    },


    addRowContainer: function () {

      this.rowsContainer.push({'afterContainerNumber': '', 'afterSize': '', 'afterSealNo': ''});

    },

    deleteRowContainer: function (rowC) {

      this.rowsContainer.splice(this.rowsContainer.indexOf(rowC), 1);

    },


    addTag(newTag) {
      this.afterHSCode = newTag;
    },

    deleteItem(option) {

      this.afterHSCode.splice(this.afterHSCode.indexOf(option), 1);

    },

    deleteRowInvoiceNumber: function (rowIN) {

      this.rowsInvoiceNumber.splice(this.rowsInvoiceNumber.indexOf(rowIN), 1);

    },

    addRowInvoiceNumber: function () {

      this.rowsInvoiceNumber.push({'invoiceNumber': ''});

    },

    closeModal(){
      this.$emit('closeModal')
    }

  }
}
</script>

<style scoped>
.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.plus-container {
  color: green;
}

.minus-container {
  color: red;
}

.plus-container:hover,
.minus-container:hover {
  cursor: pointer;
}
</style>
