<template>
<card>
<div class="row" style="margin-right:10px !important;margin-left:10px !important;background-color:rgba(249, 249, 249 , 5;">
    <div class="col-md-6" v-if="finalUserPermissions.includes('CUSTOMERS_ALL_GET')">
        <label class="typo__label" v-if="allUsers.length > 0">Search Assignee</label>
        <label class="typo__label" v-else>
            <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
            Loading assignees, please wait
        </label>
        <model-list-select
            :list="allUsers"
            v-model="AssigneeId"
            class="form-control"
            option-value="userId"
            option-text="fullName"
            placeholder="Search assignee"
        >
        </model-list-select>
    </div>
    <div class="col-md-6">
        <label class="typo__label" v-if="applicationActivities.length > 0">Select Activity</label>
        <label class="typo__label" v-else>
            <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
            Loading activities, please wait
        </label>
        <multiselect v-model="activityIds" 
        :options="applicationActivities"
        :close-on-select="false" 
        :clear-on-select="false" 
        :preserve-search="true"
        :multiple="true"
        :disabled="applicationActivities.length <= 0"
        placeholder="Select tariff Items" 
        label="activityName" 
        track-by="activityId" 
        :preselect-first="false"
        >
        </multiselect>
    </div>
    <div class="col-md-12">
    <br />
    </div>
    <div class="col-md-4">
        <label class="typo__label" v-if="applicationActivityStatuses.length > 0">Select activity status</label>
        <label class="typo__label" v-else>
            <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
            Loading activity statuses, please wait
        </label>
        <model-list-select
            :list="applicationActivityStatuses"
            v-model="applicationActivityStatusId"
            class="form-control"
            option-value="statusId"
            option-text="statusDescription"
            placeholder="Search status"
        >
        </model-list-select>
    </div>
    <div class="col-md-4">
    <label for="startDate" class="form-label">Start Date</label>
    <input type="date" class="form-control" id="startDate" v-model="startDate" placeholder="Start Date">
    </div>
    <div class="col-md-4">
    <label for="endDate" class="form-label">End Date</label>
    <input type="date" class="form-control" id="endDate" v-model="endDate" placeholder="End Date">
    </div>
    <div class="col-md-12">
    <hr />
    </div>
    <div class="col-md-12">
    <button
        @click.prevent="resetParams"
        class="btn btn-fill btn-outline-info float-right custom-btn-all"
        data-dismiss="modal"
    >
        Reset
    </button>
    <button
        @click.prevent="generateReport"
        class="btn mr-2 btn-primary float-right custom-btn"
        data-dismiss="modal"
    >
        Generate Report
    </button>
    </div>
    <div class="col-md-12">
    <hr />
    </div>
</div>
<div class="row">
    <div class="col-md-12 text-center">
    <button class="btn btn-primary btn-space custom-btn" @click.prevent="loadAllreports">Refresh Reports</button>
    </div>
    <div class="col-md-12">
    <data-table
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :pageSizeProp="pageSize"
        :pageNo="pageNo"
        :hasFilter="false"
        :myLoader="loading"
        :myFirstLoader="loading"
        :noDataFound="noDataFound"
        :searchParameterProp="searchParameter"
        :loadingTitle="'Please wait while system is loading reports'"
        :hasManageButton="false"
        :totalElements="totalElements"
        :totalPages="totalPages"
        :isFirst="isFirst"
        :isLast="isLast"
        :isHovered="[]"
        :pageNumber="pageNumber"
        :has-custom-button="true"
        custom-button-name="Download"
        :is-hovered="false"
        :permissions="[ {key: 'searchPermission', value: 'CFAREPORT_ASYNC_REPORT_GET'} ]"
        @fireCustomMethod="getFileFromMinio"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @onChange="onChange"
        @goToPage="goToPage"
        @goToBillGenerate="goToApplication"
    >
        <div slot = "pagination">
        <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </paginate>
        </div>
    </data-table>

    </div>
    <global-modal v-if="loadingDownload">
    <div slot="globalLoader">
        <div class="row text-center">
        <div class="col-md-12">
            {{ reportMessage }}, Please wait
        </div>
        <div class="col-md-12">
            <br>
        </div>
        <div class="col-md-12">
            <pulse-loader
            :color="color"
            :size="size"
            />
        </div>
        </div>
    </div>
    </global-modal>
</div>
</card>

</template>

<script>
    import API from "../../../api";
    import API_CFA from '../../../api/cfa'
    import {ModelListSelect} from 'vue-search-select';
    import PulseLoader from "vue-spinner/src/PulseLoader";
    import {mapActions, mapGetters} from "vuex";
    import Multiselect from 'vue-multiselect'
    import GlobalModal from '../../../components/globalLoader'


    export default {

    name: "report",

    components: {
        PulseLoader,
        ModelListSelect,
        GlobalModal,
        Multiselect,
        DataTable: () => import('../../../components/DataTable/dataTable'),
    },
    created() {

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.$store.dispatch("pagenames/currentPageName", "Application Activities Assignment Reports");
    this.loadAllSystemUsers()
    this.loadAllApplicationActivities()
    this.loadAllreports();
    this.loadAllApplicationActivityStatuses()
    },

    computed:{
    ...mapGetters('global' , [

        'getAllSystemUsers',
        'getAllApplicationActivities',
        'getApplicationActivityStatuses'

    ]),

    getIsPage: {

        get: function () {
        return this.isPage
        },
        set: function (newValue) {
        this.isPage = newValue;
        }

    }
    },

    data(){

        return{

        tableHeaders:['Description','From Date','To Date','Date Created', 'Generated By'],
        tableDataKeys:['description','startDate','endDate','createdDate', 'createdBy'],
        filteredRequests:[],
        finalUserPermissions: [],
        reportMessage: '',
        regimeIds: null,
        stationIds: null,
        customerIds: null,
        tariffItem: null,
        tansadNo: null,
        applicationStatuses: null,
        dismissCase: null,
        isMyAssignment: false,
        loading:false,
        loadingDownload: false,
        pageNo: 0,
        pageSize: 15,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',
        pageCount: 1,
        noDataFound:false,
        searchParameter: '',
        isPage: 0,

        params:"",
        color: 'green',
        size: '12px',
        size1: '9px',

        allUsers:[],
        applicationActivities: [],

        AssigneeId:"",
        startDate:"",
        endDate:"",
        activityIds:[],
        applicationActivityStatuses: [],
        applicationActivityStatusId: ''
        
        }
    },

    methods:{

    ...mapActions("global", [

        'loadFileFromMinio',
        'loadSystemUsers',
        'loadApplicationActivities',
        'loadApplicationActivityStatuses'
    ]),

    loadAllApplicationActivityStatuses() {

        this.loadApplicationActivityStatuses({}).then(() => {

            for(let i = 0 ; i < this.getApplicationActivityStatuses.length ; i++){

                if(this.getApplicationActivityStatuses[i].statusDescription !== 'Re Assigned'){

                    this.applicationActivityStatuses.push(this.getApplicationActivityStatuses[i]);

                }
                
            }

        }).catch(() => {})

    },

    loadAllSystemUsers() {

        this.loadSystemUsers({}).then(() => {

            this.allUsers = this.getAllSystemUsers;

        }).catch(() => {})

    },

    loadAllApplicationActivities() {

        this.loadApplicationActivities({}).then(() => {

            this.applicationActivities = this.getAllApplicationActivities;

        }).catch(() => {})

    },

    generateReport () {
        
        this.loadingDownload = true;
        let activityIds = []

        for(let x = 0 ; x < this.activityIds.length ; x++) {

            activityIds.push(this.activityIds[x].activityId)

        }

        this.multipleParams = {

            activityId: activityIds.length !== 0 ? this.activityIds : null,
            endDate: this.endDate !== '' ? this.endDate : null,
            startDate: this.startDate !== '' ? this.startDate : null,
            statusId: this.applicationActivityStatusId !== '' ? this.applicationActivityStatusId : null,
            userId: this.AssigneeId !== '' ? this.AssigneeId : null

        }

        API_CFA.applicationActivityAssignmentReport(this.multipleParams).then((response) => {

        this.loadingDownload = false;

        if(response.data.status){

            this.loadNotification('success', 6000, 'Report Generation',
                'Report request has been successfully sent',
                'response', 1000 , true  , true)

        }else {

            this.loadNotification('error', 6000, 'Report Generation',
                'No report found for requested inputs, please retry with different inputs',
                'response', 1000 , true  , true)

        }
        }).catch((err)=>{

        this.loadingDownload = false;
        this.loadNotification('error', 6000, 'Report Generation',
                'Report request not sent',
                'response', 1000 , true  , true)

        })

    },

    loadParameters (fileUrl, fileName) {

        return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl);
        this.$store.dispatch('global/fileNaming', fileName);

        resolve()
        })

    },


    getFileFromMinio (data) {

        this.loadParameters(data.attachmentUrl, 'CFAReport').then(() => {
        this.loadingDownload=true;
        this.loadFileFromMinio({}).then((res) => {

            if(res.status === 204) {

            this.loadNotification('warn', 4000, 'File Download',
                'File not found, please contact system admin',
                'response', 1000 , true  , true)

            } else {

            this.loadNotification('success', 4000, 'File Download',
                'File has been successfully downloaded, please have a look at it',
                'response', 1000 , true  , true)


            }

            this.loadingDownload = false;

        }).catch(() => {

            this.loadingDownload=false;
            this.loadNotification('error', 4000, 'File Download',
            'File not downloaded, please check your internet connection',
            'response', 1000 , true  , true);

            this.globalLoader = this.getGlobalLoader

        });

        this.globalLoader = this.getGlobalLoader

        }).catch(() => {

        })

    },

    sortingData(data) {

        this.filteredRequests = [];
        for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {
            ...data[x],
            startDate: data[x].startDate !== 'START' ? this.dateTimeToHuman(data[x].startDate) : data[x].startDate,
            endDate: data[x].endDate !== 'END' ? this.dateTimeToHuman(data[x].endDate) : data[x].endDate,
            createdDate: this.dateTimeToHuman(data[x].createdDate),
        };

        this.filteredRequests.push(requestObject)

        }

    },

    resetParams(){

        this.startDate = '';
        this.endDate = ''
        this.applicationActivityStatusId = ''
        this.AssigneeId = ''
        this.activityId = ''
    },

    loadAllreports(){

        this.reportMessage = 'Downloading requested report'
        this.loading = true;
        API.loadAllGeneratedAssignmentReports(this.pageNo,this.pageSize).then((response) => {
        
        if(response.data.status){

            this.loading = false;
            this.totalPages = response.data.data.totalPages;
            this.totalElements = response.data.data.totalElements;
            this.pageNumber = response.data.data.number;
            this.isFirst = response.data.data.first;
            this.isLast = response.data.data.isLast;

            if (response.data.data.content.length > 0) {

            this.noDataFound = false;
            this.sortingData(response.data.data.content);

            }else {

            this.noDataFound = true;

            }

        }else {

            this.notifyUser('error','Error','System Error please Contact System Administrator');

        }



        }).catch(()=>{

            this.loading = false;

        })
    },

    goToApplication(object) {

    },

    onChange(event) {
        this.pageSize=event;
        this.pageNo=0;
    },

    goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1;
        this.pageNo=page;

        this.loadAllreports();

    },

    showToolTip(id) {
        this.isHovered = [id]
    },

    hideToolTip(id) {
        this.isHovered.splice(this.isHovered.indexOf(id), 1)
    }
    }
}
</script>

<style scoped>
.back-color-vat {
background-color: rgba(216, 225, 232, .3);
}

.custom-btn, .custom-btn-all {
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.custom-btn:hover, .custom-btn-all:hover {
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.custom-btn {
margin-top: 5px !important;
margin-bottom: 5px !important;
padding: 5px 9px 5px 9px !important;
font-size: 14px !important;
width: auto;
background-color: #FFFFFF !important;
}
</style>
