<template>
    <div>
        <card>
            <ul class="nav-justified">
                <li>
                <a @click.prevent="setActiveOnClick('NewEDIDocument', 'New', 'EXPORT')" :class="[ isActive('NewEDIDocument') ? 'is-active-new-edi-document' : '' ]" href="#NewEDIDocument">New EDI documents from {{ firstNewManifestIncomingDateTime }} to today</a>
                </li>
                <li>
                <a @click.prevent="setActiveOnClick('OldEDIDocument', 'Old', 1)" :class="[ isActive('OldEDIDocument') ? 'is-active-old-edi-document' : '' ]" href="#OldEDIDocument">Old EDI documents before {{ firstNewManifestIncomingDate }}</a>
                </li>
            </ul>

            <div class="tab-content py-2" id = "EDITabContent">
                <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('NewEDIDocument') }" id="NewEDIDocument">
                    <data-table
                        :tableHeaders="tableHeadersNew"
                        :tableData="filteredRequests"
                        :tableDataKeys="tableDataKeysNew"
                        :pageSizeProp="pageSize"
                        :pageNo="pageNo"
                        :myLoader="myLoader"
                        :myFirstLoader="myFirstLoader"
                        :noDataFound="noDataFound"
                        :is-hovered="isHovered"
                        :loadingTitle="'Please wait while system is loading new export edi documents'"
                        :hasManageButton="false"
                        :totalElements="totalElements"
                        :totalPages="totalPages"
                        :isFirst="isFirst"
                        :isLast="isLast"
                        :edi-companies-loader="companiesLoader"
                        :pageNumber="pageNumber"
                        :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Manage' , 'class' : 'btn-success'}]"
                        :whichModuleAndType="whichModuleAndType"
                        :allCompanies="allCompanies"
                        :permissions="[ {key: 'searchPermission', value: 'V2_MANIFEST_SEARCH_POST'} ]"
                        @resetParams="resetParams"
                        @search="searchGeneralParams"
                        @onChange="onChange"
                        @goToPage="goToPage"
                        @goToFinishTallying="goToApplication">
                        <div slot = "pagination">
                        <paginate
                            v-model="getIsPage"
                            :page-count=parseInt(totalPages)
                            :page-range="3"
                            :margin-pages="2"
                            :click-handler="goToPage"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'pagination'"
                            :page-class="'page-item'">
                        </paginate>
                        </div>
                    </data-table>
                </div>
                <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('OldEDIDocument') }" id="OldEDIDocument">
                    <data-table
                        :tableHeaders="tableHeaders"
                        :tableData="filteredRequests"
                        :tableDataKeys="tableDataKeys"
                        :pageSizeProp="pageSize"
                        :pageNo="pageNo"
                        :myLoader="myLoader"
                        :myFirstLoader="myFirstLoader"
                        :noDataFound="noDataFound"
                        :is-hovered="isHovered"
                        :loadingTitle="'Please wait while system is loading old export edi documents'"
                        :hasManageButton="false"
                        :totalElements="totalElements"
                        :totalPages="totalPages"
                        :isFirst="isFirst"
                        :isLast="isLast"
                        :edi-companies-loader="companiesLoader"
                        :pageNumber="pageNumber"
                        :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Manage' , 'class' : 'btn-success'}]"
                        :whichModuleAndType="whichModuleAndType"
                        :allCompanies="allCompanies"
                        :permissions="[ {key: 'searchPermission', value: 'V2_MANIFEST_SEARCH_POST'} ]"
                        @resetParams="resetParams"
                        @search="searchGeneralParams"
                        @onChange="onChange"
                        @goToPage="goToPage"
                        @goToFinishTallying="goToApplication">
                        <div slot = "pagination">
                        <paginate
                            v-model="getIsPage"
                            :page-count=parseInt(totalPages)
                            :page-range="3"
                            :margin-pages="2"
                            :click-handler="goToPage"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'pagination'"
                            :page-class="'page-item'">
                        </paginate>
                        </div>
                    </data-table>
                </div>
            </div>
        </card>
        <global-modal v-if="myLoader">
            <div slot="globalLoader">
            <div class="row text-center">
                <div class="col-md-12">Loading, Please wait</div>
                <div class="col-md-12">
                <br/>
                </div>
                <div class="col-md-12">
                <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                </div>
            </div>
            </div>
        </global-modal>
    </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex"
import API from "../../../api/index"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import GlobalModal from "../../../components/globalLoader"

export default {

    name: 'exportEDIDocuments',

    components: {

        GlobalModal,
        PulseLoader,
        DataTable: () => import('../../../components/DataTable/dataTable.vue')

    },

    created () {

        if (localStorage.getItem("search-params") !== null && localStorage.getItem("search-params") === 'undefined') {

            localStorage.removeItem("search-params")
            localStorage.removeItem("cfa-customer")

        }

        this.loadAllCompanies();

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
        this.$store.dispatch('pagenames/currentPageName', 'Export EDI documents')

        if (localStorage.getItem("browser-tabs-index") !== null) {

            localStorage.getItem("browser-tabs-index").split('**')[1] === 'New' ? this.loadRequest(this.operationTypeToString) : this.loadRequest(this.operationType)
            window.$cookies.remove('browser-local-cashed-url-back')

        } else {

            this.tabStatus === 'New' ? this.loadRequest(this.operationTypeToString) : this.loadRequest(this.operationType)

        }

        if (localStorage.getItem("browser-tabs-index") !== null) {

            localStorage.removeItem("browser-tabs-index");

        }

    },

    computed: {

        ...mapGetters("edidocuments", [

            "getRequestList",
            "getPageCount",
            "getMyFirstLoader",
            "getNoDataFound",
            "getMyLoader",
            "getSearchParameter",
            "getHasSearchedVariable",
            "getPageNo",
            "getPageSize",
            "getTotalElement",
            "getTotalPage",
            "getPageNumber",
            "getIsFirst",
            "getIsLast",
            "getSearchParams",
            "getDocumentType"

        ]),

        ...mapGetters('global' , [

            'getallCompanies',
            'getAllCompaniesLoader',

        ]),

        getIsPage: {

            get: function () {
                return this.isPage
            },
            set: function (newValue) {
                this.isPage = newValue;
            }

        }

    },

    data () {

        return {

            activeItem: 'NewEDIDocument',
            pageNo: 0,
            pageSize: 15,
            totalElements: '',
            totalPages: '',
            isFirst: false,
            isLast: false,
            pageNumber: '',
            color: "green",
            size: "12px",
            loading: true,
            whichModuleAndType: 'dcEdi',
            operationType: 1,
            operationTypeToString: 'EXPORT',
            tabStatus: 'New',
            hasSearchedVariable: false,
            noDataFound: false,
            myLoader: false,
            myFirstLoader: false,
            isSearchable: false,
            requests: [],
            filteredRequests: [],
            allCompanies: [],
            isHovered: [],
            activeItem: 'NewEDIDocument',
            newParams: '',
            isPage: 0,
            pageNoPart: 0,
            firstNewManifestIncomingDateTime: '31-03-2021 16:21:46',
            firstNewManifestIncomingDate: '31-03-2021',

            finalUserPermissions: [],
            tableHeaders: ['TASAC Control Number','Carrier Code', 'Control Reference', 'Message Code', ' Voyage Number', 'Mailed', 'Charged'],
            tableDataKeys: ['tasacControlNumber','carrierName', 'controlRef', 'messageCode', 'voyageNumber', 'mailed', 'charged'],

            tableHeadersNew: ['TASAC Control Number', 'Carrier Code', 'Vessel Name', 'Message Code', 'Voyage Number', 'Mailed', 'Charged','No Bls', 'Created Date'],
            tableDataKeysNew: ['applicationReference', 'carrierId', 'transportMeansName', 'messageCode', 'voyageNumber' , 'mailed', 'charged','numberOfBls', 'createDateTime'],

        }

    },

    methods: {

        ...mapActions("edidocuments", [

            "loadEdiDocumentsOnCreated",
            "loadEdiDocumentsAfterCreated",
            "loadNewEdiDocumentsOnCreated",
            "loadNewEdiDocumentsAfterCreated",
            "searchWithGeneralParams"

        ]),

        ...mapActions("global", [

            'loadCompanies'

        ]),

        loadAllCompanies() {

            this.loadCompanies({}).then(() => {

                this.allCompanies = this.getallCompanies
                this.companiesLoader = this.getAllCompaniesLoader

            }).catch(() => {

                this.companiesLoader = this.getAllCompaniesLoader

            });

            this.companiesLoader = this.getAllCompaniesLoader

        },

        resetParams () {

            this.$store.dispatch("edidocuments/hasSearchngParams", false);
            if (localStorage.getItem("browser-tabs-index") !== null) {

                this.setActive(localStorage.getItem("browser-tabs-index").split('**')[0] , localStorage.getItem("browser-tabs-index").split('**')[1] , this.operationType);
            } else {

                this.setActive(this.activeItem , this.tabStatus , this.operationType);

            }
            this.newParams = ""
            this.pageNoPart = 0
            this.isPage = this.pageNoPart + 1
            this.filteredRequests = []

        },

        loadRequest(operationType) {

            this.$store.dispatch("edidocuments/hasSearchngParams", false);
            if (localStorage.getItem("browser-tabs-index") !== null) {

                this.setActive(localStorage.getItem("browser-tabs-index").split('**')[0] , localStorage.getItem("browser-tabs-index").split('**')[1], operationType);

            } else {

                this.setActive(this.activeItem , this.tabStatus, operationType);

            }

            localStorage.setItem("tab-status" , this.tabStatus)
            localStorage.setItem("operation-type" , operationType)
        },

        sortingData(data , dataType) {

            if(dataType === 'New') {

                for(let x = 0 ; x < data.length ; x++) {

                    const requestObject = {
                        ...data[x],
                        messageCode: data[x].messageTypeId !== null ? data[x].messageTypeId.toUpperCase() : '',
                        mailed: data[x].mailed ? 'YES' : 'NO',
                        charged: data[x].charged ? 'YES' : 'NO',
                        createDateTime: this.dateTimeToHuman(data[x].createDateTime)
                    }

                    this.filteredRequests.push(requestObject)

                }

            } else { //Old

                for(let x = 0 ; x < data.length ; x++) {

                    const requestObject = {
                        ...data[x],
                        messageCode: data[x].messageCode.toUpperCase(),
                        mailed: data[x].mailed ? 'YES' : 'NO',
                        charged: data[x].charged ? 'YES' : 'NO'
                    }

                    this.filteredRequests.push(requestObject)

                }

            }


        },

        loadParameters(pageNo , pageSize , operationType) {

            return new Promise((resolve) => {

            this.$store.dispatch("edidocuments/pageSizing", pageSize);
            this.$store.dispatch("edidocuments/pageNumbering", pageNo);

            if(operationType === 0) {

                this.$store.dispatch("edidocuments/documentCategorizing", operationType);
                this.operationType = operationType
                operationType = ""

            } else {

                this.$store.dispatch("edidocuments/documentCategorizingToString", operationType);
                this.operationTypeToString = operationType
                operationType = ""

            }

            resolve();

            })

        },

        loadSearchParameters(params, fromApplicationPageNo) {

            let fromApplicationPageNoOrNot
            typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0

            let firstPart = params.split("&page=")[0]
            let secondPart = firstPart.split("size=")[0]
            this.newParams = ""

            this.isPage = fromApplicationPageNo
            this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + fromApplicationPageNoOrNot

            return new Promise((resolve) => {

                this.$store.dispatch("edidocuments/pageSizing", this.pageSize);
                this.$store.dispatch("edidocuments/pageNumbering", 0);
                this.$store.dispatch("edidocuments/paramsSearching", params);

                resolve();

            })

        },

        setAllGetters(flag , dataType) {

            this.requests = this.getRequestList;
            flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
            flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';

            this.noDataFound = this.getNoDataFound;
            this.SuccessMessage = this.getSuccessMessage;
            this.ErrorMessage = this.getErrorMessage;
            this.ErrorAlert = this.getErrorAlert;
            this.SuccessAlert = this.getSuccessAlert;
            this.pageNo = this.getPageNo;
            this.pageSize = this.getPageSize;

            this.totalElements = this.getTotalElement;
            this.totalPages = this.getTotalPage;
            this.pageNumber = this.getPageNumber;
            this.isFirst = this.getIsFirst;
            this.isLast = this.getIsLast;

            this.sortingData(this.requests , dataType)

        },

        searchGeneralParams(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = [];

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

            this.searchWithGeneralParams({}).then(() => {

                this.setAllGetters('onCreated' , this.tabStatus)

            }).catch(() => {


            });

            this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {


            });

        },

        loadPageableRequestOnCreated(pageNo , pageSize , operationType) {

            if(this.tabStatus === 'New') {

                let operationTypeToString = 'EXPORT'

                this.loadParameters(pageNo , pageSize , operationTypeToString).then(() => {

                this.loadNewEdiDocumentsOnCreated({}).then(() => {

                    this.setAllGetters('onCreated' , this.tabStatus);

                }).catch(() => {


                });

                this.myFirstLoader = this.getMyFirstLoader;

                }).catch(() => {


                });

            } else {

                this.loadParameters(pageNo , pageSize , operationType).then(() => {

                this.loadEdiDocumentsOnCreated({}).then(() => {

                    this.setAllGetters('onCreated', this.tabStatus);

                }).catch(() => {


                });

                this.myFirstLoader = this.getMyFirstLoader;

                }).catch(() => {


                });

            }

        },

        loadPageableRequestsAfterCreated(pageNo , pageSize, operationType) {

            if(this.tabStatus === 'New') {

                let operationTypeToString = 'EXPORT'

                this.loadParameters(pageNo , pageSize , operationTypeToString).then(() => {

                this.loadNewEdiDocumentsAfterCreated({}).then(() => {

                    this.setAllGetters('afterCreated', this.tabStatus);

                });

                this.myLoader = this.getMyLoader;

                }).catch(() => {


                });

            } else {

                this.loadParameters(pageNo , pageSize, operationType).then(() => {

                this.loadEdiDocumentsAfterCreated({}).then(() => {

                    this.setAllGetters('afterCreated', this.tabStatus);

                });

                this.myLoader = this.getMyLoader;

                }).catch(() => {


                });

            }

        },

        goToApplication(object) {

            let status = ""
            this.tabStatus === "New" ? status = "new" : status = "old"

            document.cookie = "browser-local-cashed-url-back=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
            localStorage.setItem("browser-tabs-index", this.activeItem + '**' + this.tabStatus);

            if (this.getHasSearchedVariable) {

                localStorage.setItem("search-params", this.newParams);
                // localStorage.setItem("cfa-customer", JSON.stringify(this.allCompanies));
                this.newParams = ""

            } else {

                localStorage.setItem("page-no", this.getPageNo);
                localStorage.setItem("page-size", this.getPageSize);

            }

            if(this.tabStatus === "New") {

                this.$router.push({name: 'v2EdiDocumentDetails', params: {direction: 'export', documentId : object.cargoManifestId}})

            } else {

                this.$router.push({name: 'EdiDocument1', params: {flag: 'export', documentId : object.documentId, documentType: (object.messageCode).toLowerCase()}})

            }


        },

        goToPage(page) {

            if(page > 0) {page = page - 1;}
            this.isPage = page + 1

            this.pageNo = page;

            if (this.getHasSearchedVariable) {

                let firstPart = this.newParams.split("page=")[0]
                this.newParams = ""
                this.newParams = firstPart + '&page=' + this.pageNo
                this.pageNoPart = this.isPage

                this.searchGeneralParams(this.newParams, this.pageNoPart);
                this.filteredRequests = []

            } else {

                this.loadPageableRequestsAfterCreated(this.pageNo , this.pageSize, this.operationType);
                this.filteredRequests = []
            }

        },

        onChange(event) {

            this.$store.dispatch("edidocuments/pageSizing", event);
            this.$store.dispatch("edidocuments/pageNumbering", 0);
            localStorage.removeItem("page-no")
            localStorage.removeItem("page-size")
            this.isPage = 0

            this.pageSize = this.getPageSize;
            this.pageNo = this.getPageNo;

            if (this.getHasSearchedVariable) {

                let firstPart = this.newParams.split("&page=")[0]
                let secondPart = firstPart.split("size=")[0]
                this.newParams = ""
                this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + 0
                this.pageNoPart = 0

                this.searchGeneralParams(this.newParams , this.pageNoPart);
                this.filteredRequests = []

            } else {

                this.loadPageableRequestOnCreated(this.pageNo , this.pageSize, this.operationType);
                this.filteredRequests = []
            }

        },

        setActive(menuItem , tabStatus , operationType) {

            this.tabStatus = tabStatus

            if (localStorage.getItem("search-params") !== null) {

                this.newParams = ""
                this.newParams = localStorage.getItem("search-params")

                let secondPart = this.newParams.split("size=")[1]
                this.pageNoPart = parseInt(secondPart.split("page=")[1])
                this.isPage = this.pageNoPart + 1

                //this.searchGeneralChargeParams(this.newParams) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page with selected page bug
                this.searchGeneralParams(this.newParams, this.pageNoPart) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page without selected page bug

            } else {

                if (localStorage.getItem("page-no") !== null && localStorage.getItem("page-size") !== null) {

                    this.$store.dispatch("edidocuments/pageSizing", parseInt(localStorage.getItem("page-size")));
                    this.$store.dispatch("edidocuments/pageNumbering", parseInt(localStorage.getItem("page-no")));

                    this.loadPageableRequestOnCreated(parseInt(localStorage.getItem("page-no")) , parseInt(localStorage.getItem("page-size")) , operationType)
                    this.isPage = parseInt(localStorage.getItem("page-no")) + 1

                } else {

                    this.$store.dispatch("edidocuments/pageSizing", this.pageSize);
                    this.$store.dispatch("edidocuments/pageNumbering", this.pageNo);

                    this.loadPageableRequestOnCreated(this.pageNo , this.pageSize , operationType)
                    this.isPage = this.pageNo + 1

                }

                this.$store.dispatch("edidocuments/hasSearchngParams", false);
                this.newParams = ""
                this.pageNoPart = 0

            }

            this.activeItem = menuItem
            this.requests = []
            this.filteredRequests = []
            localStorage.setItem("tab-status" , tabStatus)

        },

        setActiveOnClick (menuItem, tabStatus , operationType) {

            this.tabStatus = tabStatus
            this.loadPageableRequestOnCreated(0 , 15 , operationType)
            this.activeItem = menuItem
            this.requests = []
            operationType === 0 ? this.operationType = operationType : this.operationTypeToString = operationType
            this.filteredRequests = []
            this.newParams = ""
            this.isPage = 1
            localStorage.setItem("tab-status" , tabStatus)
            localStorage.setItem("operation-type" , operationType)
            localStorage.removeItem("search-params")

        },

        isActive(menuItem) {

            return this.activeItem === menuItem;

        }

    }

}
</script>
<style scoped>
    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        /*background-color: #ededee;*/
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
        border-top: 1px solid #DCDCDC;
        border-left: 1px solid #DCDCDC;
        border-right: 1px solid #DCDCDC;
        border-bottom: none !important;
        margin-right: 15px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        font-size: 13px;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 8px 15px 8px 15px !important;
        width: auto;
        text-decoration: none;
        color: #000000;
        font-weight: bold;
        text-transform: uppercase;
    }

    ul.nav-justified li :hover {
        color: #d59a18;
        padding: 16px 20px 10px 21px;
        background-color: #ffffff;
    }

    .tab-content {
        border-top: 1px solid #DCDCDC;
        border-radius: 5px;
    }
    .is-active-new-edi-document, .is-active-new-edi-document :hover {
        color: #ffffff !important;
        padding: 0 0 2px 0;
        background-color: #3498db !important;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }

    .is-active-old-edi-document, .is-active-old-edi-document :hover {
        color: #ffffff !important;
        padding: 0 0 2px 0;
        background-color: #a55690 !important;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
</style>
<style>

</style>
