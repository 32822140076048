<template>

</template>

<script>
    export default {
        name: "shippingFeeConfigurations",

      created() {

      },

      methods:{


      }
    }
</script>booking/application

<style scoped>

</style>
