<template>
  <card>
    <div class="row back-color-vat">
      <div class="col-md-12">
        <label>Search Company</label>
        <model-list-select
          :list="allCompanies"
          v-model="companyId"
          class="form-control"
          option-value="companyId"
          option-text="companyName"
          placeholder="Company"
        >
        </model-list-select>
      </div>

      <div class="col-md-4">
        <label for="vg" class="form-label">Voyage Number</label>
        <input type="text" class="form-control" id="vg" v-model="voyageNumber" placeholder="Voyage Number">
      </div>
      <div class="col-md-4">
        <label for="vN" class="form-label">Vessel Name</label>
        <input type="text" class="form-control" id="vN" v-model="vesselName" placeholder="Voyage Number">
      </div>
      <div class="col-md-4">
        <label for="Cn" class="form-label">TASAC Control Number</label>
        <input type="text" class="form-control" id="Cn" v-model="tasacControlNumber" placeholder="Control Number">
      </div>
      <div class="col-md-12">
        <label>Operation Type</label>
        <model-list-select
          :list="opTypes"
          v-model="operationType"
          class="form-control"
          option-value="id"
          option-text="name"
          placeholder="Operation type"
        >
        </model-list-select>
      </div>
      <div class="col-md-4">
        <label>Charged Status</label>
        <model-list-select
          :list="isCharged"
          v-model="charged"
          class="form-control"
          option-value="id"
          option-text="name"
          placeholder="Request Type"
        >
        </model-list-select>
      </div>
      <div class="col-md-4">
        <label for="sDate" class="form-label">Start Date</label>
        <input type="date" class="form-control" id="sDate" v-model="startDate" placeholder="Start Date">
      </div>

      <div class="col-md-4">
        <label for="eDate" class="form-label">End Date</label>
        <input type="date" class="form-control" id="eDate" v-model="endDate" placeholder="End Date">
      </div>

      <div class="col-md-12">
        <button
          @click.prevent="resetParams"
          class="btn btn-fill btn-outline-info float-right custom-btn-all"
          data-dismiss="modal"
        >
          Clear filter
        </button>
        <button
          @click.prevent="generateReport"
          class="btn mr-2 btn-primary float-right custom-btn-all"
          data-dismiss="modal"
        >
          Generate Report
        </button>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12 text-center mt-2 mb-2">
        <button data-v-26cad49a="" data-v-061beae8="" class="btn btn-primary btn-space custom-btn" @click.prevent="loadAllreports">Refresh Reports</button>
      </div>
      <div class="col-md-12">
        <data-table
          :tableHeaders="tableHeaders"
          :tableData="filteredRequests"
          :tableDataKeys="tableDataKeys"
          :pageSizeProp="pageSize"
          :pageNo="pageNo"
          :hasFilter="false"
          :myLoader="loading"
          :myFirstLoader="loading"
          :noDataFound="noDataFound"
          :searchParameterProp="searchParameter"
          :loadingTitle="'Please wait while system is loading CFA applications charges'"
          :hasManageButton="false"
          :totalElements="totalElements"
          :totalPages="totalPages"
          :isFirst="isFirst"
          :isLast="isLast"
          :isHovered="[]"
          :pageNumber="pageNumber"
          :has-custom-button="true"
          custom-button-name="Download"
          :is-hovered="false"
          :permissions="[ {key: 'searchPermission', value: 'REPORTS_REPORT_GET'} ]"
          @fireCustomMethod="getFileFromMinio"
          @showToolTip="showToolTip"
          @hideToolTip="hideToolTip"
          @onChange="onChange"
          @goToPage="goToPage"
        >
          <div slot = "pagination">
            <paginate
              v-model="getIsPage"
              :page-count=parseInt(totalPages)
              :page-range="3"
              :margin-pages="2"
              :click-handler="goToPage"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          </div>
        </data-table>

      </div>
    </div>
  </card>

</template>

<script>
  import API from "../../../api";
  import DCAPI from "../../../api/dc";

  import {ModelListSelect} from 'vue-search-select';
  import PulseLoader from "vue-spinner/src/PulseLoader";
  import {mapActions, mapGetters} from "vuex";


  export default {
    name: "dcReport",

    components: {
      PulseLoader,
      ModelListSelect,
      DataTable: () => import('../../../components/DataTable/dataTable'),
    },
    created() {
      this.$store.dispatch("pagenames/currentPageName", "DC Reports");
      this.loadAllCompanies();
      this.loadAllreports();
    },

    computed:{
      ...mapGetters('global' , [

        'getAllCustomers',
        'getAllRegimes',
        'getAllStations'

      ]),

      getIsPage: {

        get: function () {
          return this.isPage
        },
        set: function (newValue) {
          this.isPage = newValue;
        }

      }
    },

    data(){
      return{
        tableHeaders:['Description','From Date','To Date','Date Created'],
        tableDataKeys:['description','startDate','endDate','createdDate'],
        filteredRequests:[],
        loading:false,
        pageNo: 0,
        pageSize: 15,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',
        pageCount: 1,
        noDataFound:false,
        searchParameter: '',
        isPage: 0,

        params:"",

        allCompanies:[],


        companyId:"",
        voyageNumber:"",
        vesselName:"",
        tasacControlNumber:"",
        operationType:"",
        startDate:"",
        endDate:"",
        charged:"",
        searchCategory:"NEW",

        isCharged:[
          {name: "Charged", id: 1},
          {name: "Not Charged", id: 0},
        ],

        opTypes:[
          {name: "Import", id: 'import'},
          {name: "Export", id: 'export'},
        ]

      }
    },

    methods:{

      ...mapActions("global", [

        'loadFileFromMinio'
      ]),


      loadParameters (fileUrl, fileName) {

        return new Promise((resolve) => {

          this.$store.dispatch('global/fileUrling', fileUrl);
          this.$store.dispatch('global/fileNaming', fileName);

          resolve()
        })

      },


      getFileFromMinio (data) {

        this.loadParameters(data.attachmentUrl, 'DCReport').then(() => {
          this.loading=true;
          this.loadFileFromMinio({}).then((res) => {

            if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
                'File not found, please contact system admin',
                'response', 1000 , true  , true)

            } else {

              this.loadNotification('success', 4000, 'File Download',
                'File has been successfully downloaded, please have a look at it',
                'response', 1000 , true  , true)


            }

            this.loading = false;

          }).catch(() => {
            this.loading=false;
            this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

            this.globalLoader = this.getGlobalLoader

          });

          this.globalLoader = this.getGlobalLoader

        }).catch(() => {

        })

      },

      sortingData(data) {
        this.filteredRequests=[];
        for(let x = 0 ; x < data.length ; x++) {

          const requestObject = {
            ...data[x],
            startDate: this.dateTimeToHuman(data[x].startDate),
            endDate: this.dateTimeToHuman(data[x].endDate),
            createdDate: this.dateTimeToHuman(data[x].createdDate),
          };

          this.filteredRequests.push(requestObject)

        }

      },

      resetParams(){

        this.companyId="";
        this.voyageNumber="";
        this.vesselName="";
        this.tasacControlNumber="";
        this.operationType="";
        this.startDate="";
        this.endDate="";
        this.endDate="";
        this.charged="";
        //this.searchCategory="";
      },

      loadAllCompanies() {
        this.myloader = true;

        API.getCompany()
          .then((response) => {
            if (response.data.status) {
              this.allCompanies = response.data.data;
              console.log('allCompanies',this.allCompanies);
            } else {

            }
          })
          .catch((e) => {
            this.myloader = false;
          });
      },



      generateReport(){
        this.loading=true;
        this.setparams();

        DCAPI.generateDCAsyncReport(this.params).then((resp)=>{
          this.loading=false;
          if(resp.data.status){
            this.notifyUser('success','Success','Report Generated');
          }else {
            this.notifyUser('error','Error','Report failed to be Generated');
          }
        }).catch((err)=>{
          this.loading=false;
          this.notifyUser('error','Error','Report failed to be Generated');
        })
      },

      setparams(){
        let params="";

        this.companyId!==""? params=params + "companyId=" + this.companyId:"";
        this.voyageNumber!==""? params!==""? params=params + "&voyageNumber=" + this.voyageNumber:params="voyageNumber=" + this.voyageNumber:"";
        this.vesselName!==""? params!==""? params=params + "&vesselName=" + this.vesselName:params="vesselName=" + this.vesselName:"";
        this.tasacControlNumber!==""? params!==""? params=params + "&tasacControlNumber=" + this.tasacControlNumber:params="tasacControlNumber=" + this.tasacControlNumber:"";
        this.operationType!==""? params!==""? params=params + "&operationType=" + this.operationType:params="operationType=" + this.operationType:"";
        this.startDate!==""? params!==""? params=params + "&startDate=" + this.startDate:params="startDate=" + this.startDate:"";
        this.endDate!==""? params!==""? params=params + "&endDate=" + this.endDate:params="endDate=" + this.endDate:"";
        this.charged!==""? params!==""? params=params + "&charged=" + this.charged:params="charged=" + this.charged:"";
        this.searchCategory!==""? params!==""? params=params + "&searchCategory=" + this.searchCategory:params="searchCategory=" + this.searchCategory:"";

        this.params=params;
      },

      loadAllreports(){
        this.loading=true;
        DCAPI.getAsyncReports(this.pageNo,this.pageSize).then((resp)=>{
          this.loading=false;
          if(resp.data.status){
            this.totalPages=resp.data.data.totalPages;
            this.totalElements=resp.data.data.totalElements;
            this.pageNumber=resp.data.data.number;
            this.isFirst=resp.data.data.first;
            this.isLast=resp.data.data.isLast;

            if (resp.data.data.content.length > 0) {
              this.noDataFound=false;
              this.sortingData(resp.data.data.content);
            }else {
              this.noDataFound=true;
            }

          }else {
            this.notifyUser('error','Error','System Error please Contact System Administrator');
          }



        }).catch((error)=>{
          this.loading=false;
        })
      },


      onChange(event) {
        this.pageSize=event;
        this.pageNo=0;
      },

      goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1;
        this.pageNo=this.isPage;

        this.loadAllreports();

      },

      showToolTip(id) {
        this.isHovered = [id]
      },

      hideToolTip(id) {
        this.isHovered.splice(this.isHovered.indexOf(id), 1)
      }
    }
  }
</script>

<style scoped>

</style>
