<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-success mr-2 custom-btn"
            style="float:right;"
            @click.prevent="openModal('ADD')"
          >
            <b>Add CF Agent</b>
          </button>
        </div>
      </div>
      <br>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="isPageable"
        :total-elements="totalElements"
        :no-data-found="noDataFound"
        :is-first="isFirst"
        :is-last="isLast"
        :total-pages="totalPages"
        :page-number="pageNumber"
        @goToPage="goToPage"
        :date-prop="'Created At '"
        :has-manage-button="false"
        :has-search-form="true"
        :has-view-button="true"
        :has-edit-button="true"
        :has-delete-button="true"
        :has-custom-button="true"
        :reload-all-request="true"
        @reloadByIcon="loadPageableCFAgentsOnCreated"
        custom-button-name="Users"
        @fireCustomMethod="goToUsers"
        @onChange="onChange"
        @goToView="goToView"
        @goToEdit="goToEdit"
        :goToDelete="goToDeleteCFAgent"
        @searchRequest="searchCfAgents"
        :loading-title="'Please wait while system is loading Clearing & Forwarding Agents'"
      />
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"

          >
            <h6 > {{modal_title}}</h6>
          </div>


          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      ADD FORM      -->
          <form
            method="POST"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="CF Agent Name"
                  placeholder="CF Agent Name"
                  v-model="cfAgent.agentName"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  :readonly="isReadOnly"
                />
                <div v-if="cfAgentNameError">
                  <div
                    class="error"
                    v-if="!$v.cfAgent.agentName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="number"
                  label="CF Agent Phone No."
                  placeholder="CF Agent Phone No. (255 xxxxxxxxx)"
                  v-model="cfAgent.agentPhone"
                  class="special-bottom"
                  :title="'Enter Phone number in format 255xxxxxxxxx'"
                  :readonly="isReadOnly"
                />
                <div v-if="cfAgentPhoneError">
                  <div
                    class="error"
                    v-if="!$v.cfAgent.agentPhone.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="email"
                  label="CF Agent Email"
                  placeholder="CF Agent Email"
                  v-model="cfAgent.agentEmail"
                  class="special-bottom"
                  :readonly="isReadOnly"
                />
                <div v-if="cfAgentEmailError">
                  <div
                    class="error"
                    v-if="!$v.cfAgent.agentEmail.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="number"
                  label="CF Agent TIN No."
                  placeholder="CF Agent TIN No."
                  v-model="cfAgent.agentTin"
                  class="special-bottom"
                  :readonly="isReadOnly"
                />
                <div v-if="cfAgentTinError">
                  <div
                    class="error"
                    v-if="!$v.cfAgent.agentTin.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  type="textarea"
                  label="CF Agent Address"
                  placeholder="CF Agent Address"
                  v-model="cfAgent.agentAddress"
                  class="special-bottom"
                  :is-text-area="true"
                  :readonly="isReadOnly"
                />
                <div v-if="cfAgentAddressError">
                  <div
                    class="error"
                    v-if="!$v.cfAgent.agentAddress.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ...</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>

                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  v-if="!isReadOnly && !EditModalDiv"
                  @click.prevent="saveFormDetails('ADD')"
                >
                  Save
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  v-if="!isReadOnly && EditModalDiv"
                  @click.prevent="saveFormDetails('EDIT')"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

        </card>
      </div>
    </modal>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { mapActions, mapGetters } from 'vuex'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import GlobalModal from '../../../components/globalLoader'
  import DataTable from '../../../components/DataTable/updatedTable'
  import Modal from '../../../components/Inputs/Modal'
  import CrudNotification from "../../../components/DataTable/crudNotification";
  import AGENCYAPI from "../../../api/agency";


  export default {
    name: 'CFAgent',
    components: {
      PulseLoader,
      GlobalModal,
      DataTable,
      Modal,
      CrudNotification
    },
    created () {
      this.$store.dispatch('pagenames/currentPageName', 'Clearing & Forwarding Agents')

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
      this.loadPageableCFAgentsOnCreated()
    },
    computed: {
      ...mapGetters('notifications', [

        'getNewAppPage'

      ]),
      ...mapGetters('CFAgent', [

        'getAgentRequestList',
        'getPageCount',
        'getMyFirstLoader',
        'getNoDataFound',
        'getSuccessAlert',
        'getErrorMessage',
        'getErrorAlert',
        'getSuccessMessage',
        'getMyLoader',
        'getSearchParameter',
        'getPageNo',
        'getRequestId'
      ])
    },
    validations: {

      code: { required },
      description: { required },
      name: { required },

      cfAgent: {
        agentAddress: { required } ,
        agentEmail: { required } ,
        agentName: { required } ,
        agentPhone: { required } ,
        agentTin: { required } ,
      },

    },

    data () {
      return {

        cfAgent: {
          agentAddress: '' ,
          agentEmail: '' ,
          agentName: '' ,
          agentPhone: '' ,
          agentTin: '' ,
        },


        cfAgentNameError: false,
        cfAgentPhoneError: false,
        cfAgentAddressError: false,
        cfAgentEmailError: false,
        cfAgentTinError: false,

        AddModalDiv: false,
        EditModalDiv: false,
        showModal: false,
        editMyLoader: false,

        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        sortBy: 'createdAt',
        totalPages: 1,
        isPageable: true,
        pageNumber: 0,
        isFirst: true,
        isLast: false,
        nodataFound: false,
        totalElements: '',
        color: 'green',
        size: '12px',

        loading: true,
        editable: true,
        searchParameter: '',
        hasSearchedVariable: false,
        reloadAllRequest: false,
        noDataFound: false,
        pdfFormatError: false,
        loadAllHeaderLoader: false,
        allAttachmentVerified: false,
        myLoader: false,
        myFirstLoader: false,
        isSearchable: false,
        requests: [],

        SuccessAlert: false,
        ErrorAlert: false,

        SuccessMessage: '',
        ErrorMessage: '',
        isReadOnly:false,
        isHovered: [],
        saveDetails: {},
        ifThereIsParam: '',
        editDetails: false,
        finalUserPermissions: [],
        modal_title:'',
        tableHeaders: [ 'Agent Name',  'Email', 'Phone No.', ],
        tableDataKeys: ['agentName',  'agentEmail', 'agentPhone' ]

      }
    },
    methods: {
      ...mapActions('CFAgent', [
        'getAllCfAgents',
        'addCFAgent',
        'editCFAgent',
        'deleteCFAgentDetails'
      ]),

      searchCfAgents(name){
        if(name!==''){
          this.myFirstLoader=true;
          AGENCYAPI.getCfAgentByAgentName(name).then((response)=>{
            this.myFirstLoader=false;
            this.requests=response.data.data;
            // response.data.data.map((data)=>{
            //   this.cfAgents.push({ code: data.cfAgentId, name: data.agentName, desc: 'desc01' },)
            // })

            this.pageCount = response.data.data.totalPages;
            this.totalPages = response.totalPages;
            this.pageNumber = 1;
            this.pageNo = 0;
          }).catch((e)=>{
            console.log(e);
            this.myFirstLoader=false;
          });
        }

      },
      goToUsers(data){
        console.log(data);
        this.$router.push({name:'CFAgentUsers',params:{
            cfAgentId:data.cfAgentId
          }});
      },

      loadParameters () {
        return new Promise((resolve) => {
          this.$store.dispatch('CFAgent/pageSizing', this.pageSize)
          this.$store.dispatch('CFAgent/pageNumbering', this.pageNo)
          resolve()
        })
      },

      loadSaveDetailsParameters () {
        return new Promise((resolve) => {
          this.$store.dispatch('CFAgent/savingDetails', this.saveDetails)
          resolve()
        })
      },
      loadPageableCFAgentsOnCreated () {
        this.loadParameters().then(() => {
          this.getAllCfAgents({}).then(() => {
            this.requests = this.getAgentRequestList.content
            this.pageCount = this.getPageCount
            this.myFirstLoader = this.getMyFirstLoader
            this.noDataFound = this.getNoDataFound
            this.totalPages = this.getAgentRequestList.totalPages;
            this.pageNumber = this.getAgentRequestList.number;
            this.isFirst   = this.getAgentRequestList.first;
            this.isLast = this.getAgentRequestList.last;
            this.pageNo = this.getPageNo;

            // this.pageNo = this.getPageNo
            // this.pageSize = this.getPageSize;
          }).catch(() => {})
          this.myFirstLoader = this.getMyFirstLoader
          // this.myLoader = true;
        })
      },
      onChange (event) {
        this.pageSize = event
        this.pageNo = 0
        this.loadPageableCFAgentsOnCreated()
      },
      goToView(data){
        this.cfAgent = data;
        this.openModal('VIEW');
      },

      goToEdit(data){
        this.cfAgent = JSON.parse(JSON.stringify(data));
        this.openModal('EDIT')
      },

      saveCFAgentDetails () {
        this.loadSaveDetailsParameters().then(() => {
          this.addCFAgent({}).then(() => {
            this.myFirstLoader = this.getMyFirstLoader
            this.ErrorAlert = this.getErrorAlert
            this.SuccessAlert = this.getSuccessAlert
            if  (this.SuccessAlert){
              this.notifyUser('success', 'CF Agent added successfully!', 'The Clearing & Forwarding Agent added successfully' )
              this.SuccessAlert = false;
            }
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }
            this.loadPageableCFAgentsOnCreated()

          }).catch(() => {
            this.ErrorAlert = this.getErrorAlert
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }
          })
          this.myFirstLoader = false
        })
        this.saveDetails = {}
      },
      editCFAgentDetails () {
        this.loadSaveDetailsParameters().then(() => {
          this.editCFAgent({}).then(() => {
            this.myFirstLoader = this.getMyFirstLoader
            this.ErrorAlert = this.getErrorAlert
            this.SuccessAlert = this.getSuccessAlert
            if  (this.SuccessAlert){
              this.notifyUser('success', 'CF Agent edited successfully!', 'The Clearing & Forwarding Agent has been edited successfully' )
              this.SuccessAlert = false;
            }
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }
            this.loadPageableCFAgentsOnCreated()

          }).catch(() => {
            this.ErrorAlert = this.getErrorAlert
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }
          })
          this.myFirstLoader = false
        })
        this.saveDetails = {}
      },
      goToDeleteCFAgent(data){
        let messageConfirm = {
          'title': '<em>' + data.agentName + '</em> to be deleted',
          'body': 'Are you sure you want to delete the clearing & forwarding agent?'
        }
        this.$dialog.confirm(messageConfirm, {
          loader: true,
          backdropClose: true,
          okText:'Yes',
          html:true
        }).then((dialog)=>{
          this.$store.dispatch('CFAgent/CFAgentId', data.cfAgentId);
          this.myLoader = true;
          this.deleteCFAgentDetails({}).then(()=>{
            this.myLoader = this.getMyFirstLoader;
            if  (this.SuccessAlert){
              this.notifyUser('success', 'CF Agent deleted successfully!', 'The Clearing & Forwarding Agent has been deleted successfully' )
              this.SuccessAlert = false;
            }
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }
            this.loadPageableCFAgentsOnCreated()
          }).catch(()=>{
            this.ErrorAlert = this.getErrorAlert
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }
            this.myLoader = false
          })
          dialog.close()
        }).catch((e)=>{

        })
      },

      goToApplication (data) {
        document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
        this.$router.push('/configurations/disbursement-service-category-and-items/' + data.serviceCategoryId)
      },
      saveFormDetails (FormType) {
        // console.log(this.cfAgent)
        if (this.$v.cfAgent.agentAddress.$invalid ||
            this.$v.cfAgent.agentEmail.$invalid ||
            this.$v.cfAgent.agentTin.$invalid ||
            this.$v.cfAgent.agentName.$invalid ||
            this.$v.cfAgent.agentPhone.$invalid) {
          if (this.$v.cfAgent.agentName.$invalid) {
            this.cfAgentNameError = true
          }
          if (this.$v.cfAgent.agentEmail.$invalid) {
            this.cfAgentEmailError = true
          }
          if (this.$v.cfAgent.agentTin.$invalid) {
            this.cfAgentTinError = true
          }
          if (this.$v.cfAgent.agentAddress.$invalid) {
            this.cfAgentAddressError = true
          }
          if (this.$v.cfAgent.agentPhone.$invalid) {
            this.cfAgentPhoneError = true
          }
        }else {
          this.saveDetails = this.cfAgent
          // this.saveDetails = {
          //   code: this.cfAgent.code,
          //   description: this.cfAgent.description,
          //   name: this.cfAgent.name
          // }
          // console.log(this.saveDetails)
          if (FormType === 'EDIT'){
            this.editCFAgentDetails()
          }else{
            this.saveCFAgentDetails()
          }
          this.closeModal()

        }
      },
      goToPage (page) {
        this.pageNo = page
        this.loadPageableCFAgentsOnCreated()
      },
      closeModal () {
        this.showModal = false
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.UploadModalDiv = false
        this.cfAgentNameError = false
        this.cfAgentPhoneError = false
        this.cfAgentAddressError = false
        this.cfAgentEmailError = false
        this.cfAgentTinError = false
        this.cfAgent = {
          agentAddress: '' ,
          agentEmail: '' ,
          agentName: '' ,
          agentPhone: '' ,
          agentTin: '' ,
        }

      },
      // editSaveFormDetails (details) {
      //   if (this.$v.name.$invalid || this.$v.code.$invalid || this.$v.description.$invalid) {
      //     if (this.$v.cfAgent.name.$invalid) {
      //       this.cfAgentNameError = true
      //     }
      //     if (this.$v.cfAgent.code.$invalid) {
      //       this.cfAgentCodeError = true
      //     }
      //     if (this.$v.cfAgent.description.$invalid) {
      //       this.cfAgentDescriptionError = true
      //     }
      //   } else {
      //     this.saveDetails = {
      //       active: true,
      //       code: this.code,
      //       description: this.description,
      //       name: this.name,
      //       serviceCategoryId: this.serviceCategoryId
      //     }
      //     this.editDisbursementCategories()
      //
      //     this.code = ''
      //     this.name = ''
      //     this.description = ''
      //     this.closeModal()
      //   }
      // },
      openModal (modalType) {
        if (modalType === 'ADD') {
          this.modal_title = 'Add Clearing & Forwarding Agent'
          this.EditModalDiv = false;
          this.AddModalDiv = true;
          this.showModal = true;
          this.isReadOnly = false;
        }
        if (modalType === 'EDIT'){
          this.modal_title = 'Edit Clearing & Forwarding Agent'
          this.EditModalDiv = true;
          this.AddModalDiv = true;
          this.showModal = true;
          this.isReadOnly = false;
        }
        if (modalType === 'VIEW'){
          this.modal_title = 'Clearing & Forwarding Agent Details'
          this.EditModalDiv = false;
          this.AddModalDiv = true
          this.isReadOnly = true;
          this.showModal = true;
        }
      },
      // editCFAgentDetails (details) {
      //   this.serviceCategoryId = details.serviceCategoryId
      //   this.code = details.code
      //   this.description = details.description
      //   this.name = details.name
      //   this.openModal('EDIT')
      // },
      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert
        } else {
          this.ErrorAlert = !this.ErrorAlert
        }
      },
      enter: function (el, done) {
        var that = this

        setTimeout(function () {
          that.SuccessAlert = false
          that.ErrorAlert = false
        }, 3000) // hide the message after 3 seconds
      }

    }

  }

</script>

<style scoped>
  /*.btn-space {*/
  /*  margin-right: 10px !important;*/
  /*}*/
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 120px;
    background-color: #FFFFFF !important;
  }

  /*.special-limit {*/
  /*  max-width: 100px !important;*/
  /*  min-width: 90px !important;*/
  /*}*/

  /*.loader-div {*/
  /*  line-height: 40px;*/
  /*}*/

  /*.loader-div-gif {*/
  /*  line-height: 50px;*/
  /*}*/

  /*.fileUpload {*/
  /*  position: relative;*/
  /*  overflow: hidden;*/
  /*  margin: 0.5em 5px;*/
  /*  font-size: 1em;*/
  /*  font-weight: 700;*/
  /*  letter-spacing: 0.02em;*/
  /*  line-height: 1.50;*/
  /*  text-decoration: none;*/
  /*}*/

  /*.fileUpload input.upload {*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*  right: 0;*/
  /*  margin: 0;*/
  /*  padding: 0;*/
  /*  cursor: pointer;*/
  /*  opacity: 0;*/
  /*  filter: alpha(opacity=0);*/
  /*}*/

  /*.fileUpload span {*/
  /*  cursor: pointer;*/
  /*}*/

  /*label {*/
  /*  position: relative;*/
  /*  top: -7px;*/
  /*  right: 0;*/
  /*  margin: 0;*/
  /*  padding: 0;*/
  /*}*/

  /*.special-bottom {*/
  /*  margin-bottom: -1px;*/
  /*}*/
</style>
