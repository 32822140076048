<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>

      <div class="row">
        <div class="col-md-4">
          <div class="text-left">
            City List
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
        </div>
        <div class="col-md-4 text-right">
          <span
            class="all-fa-icons btn-space"
            v-if="finalUserPermissions.includes('CITY_CONFIG_POST')"
            @click.prevent="openModal(null , null , null ,null,null , actionButton = 'AddModal')"
          >
            <font-awesome-icon icon="plus" />
          </span>
          <div
            class="fileUpload btn btn-info btn-fill"
            v-if="finalUserPermissions.includes('CITY_UPLOAD_POST')"
          >
            <i class="nc-icon nc-cloud-upload-94" /><span> Upload Excel..</span>
            <form
              method="post"
              enctype="multipart/form-data"
            >
              <input
                type="file"
                name="attachment"
                label="Upload File"
                class="upload"
                @change.prevent="uploadFile"
              >
            </form>
          </div>
        </div>
      </div>

      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('CITY_SEARCH-PAGEABLE_POST')"
            >
              <form
                class="search"
                @submit.prevent="loadSearchRequestCityList(pageNo , pageSize)"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParamenter"
                      @input="loadAllPageble"
                    >
                    <span
                      class="input-group-addon"
                      @click.prevent="loadSearchRequestCityList(pageNo , pageSize)"
                    >
                      <font-awesome-icon icon="search" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              City Name
            </th>
            <th scope="col">
              City Code
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getCity"
            :key="list.cityId"
            v-if="!noDataFound"
          >
            <th scope="row">
              {{ index + 1+(pageNo*pageSize) }}
            </th>
            <td>
              {{ list.cityName }}
            </td><td>
              {{ list.cityCode }}
            </td>

            <td class="special-limit">
              <div
                class="all-fa-icons fa-icons-space edit-fa-icon"
                v-if="finalUserPermissions.includes('CITY_CONFIG_PUT')"
                @click.prevent="openModal(list.cityId , list.cityName , list.countryId,
                                          list.cityCode , list.unLocCode, actionButton = 'EditModal')"
                title="edit"
              >
                <font-awesome-icon icon="edit" />
              </div>
              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                v-if="finalUserPermissions.includes('CITY_CONFIG_{CITYID}_GET')"
                @click.prevent="openModal(list.cityId , list.cityName, list.countryId , list.cityCode ,
                                          list.unLocCode , actionButton = 'ShowModal')"
                title="view"
              >
                <font-awesome-icon icon="eye" />
              </div>

              <div
                class="all-fa-icons fa-icons-space delete-fa-icon"
                v-if="finalUserPermissions.includes('CITY_CONFIG_{CITYID}_DELETE')"
                @click.prevent="deleteCity(list.cityId , list.cityName)"
                title="delete"
              >
                <font-awesome-icon icon="trash-alt" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Cargo Type <b>{{ cityName }} ({{ cityCode }})</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register City
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Country: <b>{{ data.cityName }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="UploadModalDiv"
          >
            Upload Excel for Country
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editCity"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <label>Search Country name</label>
                <model-list-select
                  :list="getCountry"
                  v-model="city.countryId"
                  class="form-control"
                  option-value="countryId"
                  option-text="countryName"
                  placeholder="Search Country"
                />
                <div v-if="countryIdError">
                  <div
                    class="error"
                    v-if="!$v.countryId.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="City Name"
                  placeholder="City Name"
                  v-model="cityName"
                  value="cityName"
                  class="special-bottom"
                />

                <div v-if="cityNameError">
                  <div
                    class="error"
                    v-if="!$v.cityName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <base-input
                  type="text"
                  label="City Code"
                  placeholder="City Code"
                  v-model="cityCode"
                  value="cityCode"
                  class="special-bottom"
                />
                <div v-if="cityCodeError">
                  <div
                    class="error"
                    v-if="!$v.cityCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Local Code"
                  placeholder="Local Code"
                  v-model="unLocCode"
                  value="unLocCode"
                  class="special-bottom"
                />
                <div v-if="cityLocCodeError">
                  <div
                    class="error"
                    v-if="!$v.unLocCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveCity"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <label>Search Country name</label>
                <model-list-select
                  :list="getCountry"
                  v-model="city.countryId"
                  class="form-control"
                  option-value="countryId"
                  option-text="countryName"
                  placeholder="Search Country"
                />
                <div v-if="countryIdError">
                  <div
                    class="error"
                    v-if="!$v.city.countryId.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="City Name"
                  placeholder="City Name"
                  v-model="city.cityName"
                  class="special-bottom"
                />
                <div v-if="cityNameError">
                  <div
                    class="error"
                    v-if="!$v.city.cityName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="City Code"
                  placeholder="City Code"
                  v-model="city.cityCode"
                  class="special-bottom"
                />
                <div v-if="cityCodeError">
                  <div
                    class="error"
                    v-if="!$v.city.cityCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Local Code"
                  placeholder="Local Code"
                  v-model="city.unLocCode"
                  class="special-bottom"
                />
                <div v-if="cityLocCodeError">
                  <div
                    class="error"
                    v-if="!$v.city.unLocCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    City Name:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.cityName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    City Code:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.cityCode }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Local Code:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.unLocCode }}
                  </div>
                </div>
              </div>
            </div>

            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'

export default {

  name: 'CityList',

  components: {

    PulseLoader,
    Modal,
    ModelListSelect

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Cities List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableCityList(this.pageNo, this.pageSize)
    this.loadCountry()
  },

  computed: {

    ...mapGetters('citylist', [

      'getCityNameBeforeEdit'

    ])

  },

  data () {
    return {

      array: [],
      getCity: [],
      getCountry: [],
      getCityArray: [],
      finalUserPermissions: [],

      pageNo: 0,
      pageSize: 15,
      sortBy: 'id',
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      docSortBy: 'id',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,

      data: {},

      color: 'green',
      size: '15px',
      loading: true,

      SuccessMessage: '',
      ErrorMessage: '',
      cityName: '',
      cityCode: '',
      unLocCode: '',
      CityName: '',
      CityCode: '',
      CityId: '',
      countryId: '',

      error: '',

      fileData: '',
      file: '',

      city: {
        cityName: '',
        cityCode: '',
        countryId: '',
        unLocCode: ''
      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,
      UploadModalDiv: false,

      cityNameError: false,
      cityCodeError: false,
      countryIdError: false,
      cityLocCodeError: false

    }
  },

  validations: {

    cityName: { required },
    cityCode: { required },
    unLocCode: { required },
    city: {
      cityName: { required },
      cityCode: { required },
      unLocCode: { required },
      countryId: { required },
      country: {
        countryId: { required }
      }
    },

    CountryName: { required },
    countryCode: { required },
    countryId: { required }

  },

  methods: {

    loadSearchRequestCityList (pageNo, pageSize) {
      this.myloader = true
      this.reloadAllRequest = true
      this.hasSearchedVariable = true

      API.getSearchPageableCityList(pageNo, pageSize, this.searchParameter).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            if (response.data.data.length > 0) {
              this.getCity = response.data.data
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            }
          } else {
            this.noDataFound = true
            this.pageCount = response.data.message
            this.docPageCount = this.pageCount
            this.docPageNo = this.pageNo
            this.docPageSize = this.pageSize
            this.getPageableDoc = response.data.data
            this.myloader = false
          }
        }
      ).catch(e => {
        this.myloader = false
      })
    },

    loadPageableCityList (pageNo, pageSize) {
      return new Promise((resolve, reject) => {
        this.myloader = true

        API.getPageableCityList(pageNo, pageSize).then(
          response => {
            if (response.data.status) {
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getCity = response.data.data
              this.myloader = false
            } else {

            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ' Requests Not loaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false

          reject(e)
        })
      })
    },

    onChange (event) {
      this.pageSize = event.target.value
      this.pageNo = 0
      if (this.hasSearchedVariable) {
        this.loadSearchRequestCityList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCityList(this.pageNo, this.pageSize)
      }
      this.docPageNo = this.pageNo
      this.docPageSize = this.pageSize
      this.docPageCount = this.pageCount
      this.docSortBy = this.sortBy
    },

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestCityList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCityList(this.pageNo, this.pageSize)
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestCityList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCityList(this.pageNo, this.pageSize)
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.loadSearchRequestCityList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCityList(this.pageNo, this.pageSize)
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestCityList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCityList(this.pageNo, this.pageSize)
      }
    },

    reloadByIcon () {
      this.loadPageableCityList(this.pageNo, this.pageSize)
      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParamenter = ''
    },

    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadPageableCityList(this.pageNo, this.pageSize)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParamenter = ''
      }
    },

    loadCity () {
      this.myloader = true

      API.getCity().then(
        response => {
          if (response.data.status) {
            this.getCity = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadCountry () { // for country select box
      API.getCountry().then(
        response => {
          if (response.data.status) {
            this.getCountry = response.data.data
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadSingleCity (data) {
      API.getIndividualCity(data).then(

        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        }
      ).catch(e => {

        // this.state.errors.push(e)

      })
    },

    saveCity () {
      if (this.$v.city.cityCode.$invalid || this.$v.city.cityName.$invalid ||
          this.$v.city.countryId.$invalid || this.$v.city.unLocCode.$invalid) {
        if (this.$v.city.cityName.$invalid) {
          this.cityNameError = true
        }
        if (this.$v.city.cityCode.$invalid) {
          this.cityCodeError = true
        }
        if (this.$v.city.countryId.$invalid) {
          this.countryIdError = true
        }
        if (this.$v.city.unLocCode.$invalid) {
          this.cityLocCodeError = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API.postCity(JSON.stringify(this.city)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = '"' + response.data.data.CityName + '" city saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadPageableCityList(this.pageNo, this.pageSize)
              this.editMyLoader = false
              this.city.CityName = ''
              this.city.CityCode = ''
              this.city.countryId = ''
              this.city.unLocCode = ''
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Record not added'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.city.CityName = ''
          this.closeModal()
        })

        // }, 2000);
      }
    },

    deleteCity (data, name) {
      this.$store.dispatch('citylist/commitCityNameBeforeEdit', name)

      this.$dialog.confirm('If you delete cargo type [ ' + name + " ], it'll be gone forever.", {

        loader: true
      })
        .then((dialog) => {
          // setTimeout(() => {

          API.deleteCity(data).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ' Country type [ ' + this.getCityNameBeforeEdit + ' ] has been deleted'
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                window.scrollTo(10, 0)
                this.loadCountry()
                this.loadPageableCityList(this.pageNo, this.pageSize)
                this.$el.scrollTop = 0
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Record not deleted'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
          })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editCity () {
      if (this.$v.cityCode.$invalid || this.$v.cityName.$invalid ||
          this.$v.countryId.$invalid || this.$v.unLocCode.$invalid) {
        if (this.$v.cityName.$invalid) {
          this.cityNameError = true
        }
        if (this.$v.cityCode.$invalid) {
          this.cityCodeError = true
        }
        if (this.$v.countryId.$invalid) {
          this.countryIdError = true
        }
        if (this.$v.unLocCode.$invalid) {
          this.cityLocCodeError = true
        }
      } else {
        this.editMyLoader = true

        this.array =
            {
              cityName: this.cityName,
              cityCode: this.cityCode,
              cityId: this.cityId,
              countryId: this.countryId,
              unLocCode: this.unLocCode
            }

        // setTimeout(() => {

        API.updateCity(JSON.stringify(this.array)).then(
          response => {
            if (response.data.status) {
              // this.SuccessMessage =  " " + this.countryName + " Record edited To " + response.data.data.countryName;
              this.SuccessMessage = ' Record edited'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              window.scrollTo(10, 0)
              this.closeModal()
              this.loadPageableCityList(this.pageNo, this.pageSize)
              this.editMyLoader = false
            } else {

            }
          }
        ).catch(e => {
          this.errors = e
          this.editMyLoader = false
          this.ErrorMessage = ' Record not edited'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })

        // }, 2000);
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (cityId = null, cityName = null, countryId = null, cityCode = null, unLocCode = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.ShowModalDiv = false
        this.UploadModalDiv = false
        this.cityName = cityName
        this.cityCode = cityCode
        this.cityId = cityId
        this.countryId = countryId
        this.unLocCode = unLocCode
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.UploadModalDiv = false
      } else {
        this.loadSingleCity(cityId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
        this.UploadModalDiv = false
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.UploadModalDiv = false
      this.ShowModalDiv = false

      this.city.CityName = ''
      this.city.CityCode = ''
      this.city.countryId = ''
      this.city.unLocCode = ''

      this.cityCodeError = false
      this.cityNameError = false
      this.countryIdError = false
      this.cityLocCodeError = false
    },

    uploadFile (event) {
      this.fileData = event.target.files[0]
      this.file = this.fileData.name

      this.myloader = true

      const formData = new FormData()

      formData.append('file', this.fileData)

      API.uploadCityExcel(formData).then(

        response => {
          if (response.data.status) {
            response.data.data.map((value) => {
              this.getCityArray.push(value.cityName)
            })

            this.SuccessAlert = true
            this.ErrorAlert = false
            this.SuccessMessage = ' Countries Added [ ' + this.getCityArray + ' ]'
            this.myloader = false
            this.loadPageableCityList(this.pageNo, this.pageSize)
            this.$el.scrollTop = 0
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not sent'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    }

  }

}
</script>
<style scoped>
  input.search {
    width: 20% !important;
  }
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.50;
    text-decoration: none;
  }
  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor:pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
