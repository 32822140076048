<template>
  <div>
    {{ enableLoginButton }}
    <div
      class="before-container container-fluid"
      v-if="!isMobile"></div>

    <div
      class="container"
      v-if="!isMobile"
    >
      <!--      <div-->
      <!--        class="row justify-content-center"-->
      <!--        v-if="getNoInternetConnection && !isMobile"-->
      <!--      >-->
      <!--        <div class="col-md-5 col-lg-5 col-sm-12 login-notifications">-->
      <!--          <div class="row">-->
      <!--            <div class="col-md-2 col-lg-2 col-sm-2 col-2 login-notification-icon text-left">-->
      <!--              <svg-icon-->
      <!--                class="nc-icon-inner"-->
      <!--                icon="info"-->
      <!--                :has-fill="true"-->
      <!--              />-->
      <!--            </div>-->

      <!--            <div class="col-md-10 col-lg-10 col-sm-10 col-10 login-notification-message text-right">-->
      <!--              <b>Connection to the server failed</b>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div-->
      <!--        class="row justify-content-center"-->
      <!--        v-if="hasExpired && !isMobile"-->
      <!--      >-->
      <!--        <div class="col-md-5 col-lg-5 col-sm-12 login-notifications">-->
      <!--          <div class="row">-->
      <!--            <div class="col-lg-2 col-md-2 col-sm-2 col-2 login-notification-icon text-left">-->
      <!--              <svg-icon-->
      <!--                class="nc-icon-inner"-->
      <!--                icon="info"-->
      <!--                :has-fill="true"-->
      <!--              />-->
      <!--            </div>-->

      <!--            <div class="col-lg-10 col-md-10 col-sm-10 col-10 login-notification-message text-right">-->
      <!--              <b>Session has expired, please login to continue</b>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div
        class="row justify-content-center"
        style="background-color: transparent;opacity: .9"
      >
        <div class="col-lg-5 col-md-7 col-sm-12">
          <div class="card shadow border-0">
            <div class="card-header bg-transparent pb-4">
              <div class="logo-img text-center">
                <img
                  src="/img/logo.png"
                  alt
                  style="width:300px;height:230px;"
                >
              </div>
            </div>
            <div class="text-center pb-4 text-black-50">
              <b class="color-blue">S</b>HIPPING <b class="color-blue">B</b>USINESS<b class="color-blue">
                M</b>ANAGEMENT <b class="color-blue">S</b>YSTEM
            </div>
            <div class="card-body px-lg-5 py-lg-2">
              <form @submit.prevent="submit">
                <div class="input-container">
                  <i class="nc-icon nc-circle-09 icon"></i>
                  <input
                    placeholder="username"
                    id="username"
                    autocomplete="off"
                    class="input-field"
                    @input="updateUsername"
                  >
                </div>

                <div class="input-container">
                  <i class="nc-icon nc-key-25 icon"></i>
                  <input
                    placeholder="password"
                    type="password"
                    id="password"
                    class="input-field"
                    @input="updatePassword"
                  >
                </div>

                <div class="text-center">
                  <div
                    class="form-group"
                    style="height: 25px"
                  >
                    <div>
                      <div
                        class="form-group text-danger"
                        v-if="showIncorrectError"
                      >
                        {{ incorrectError }}
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    name="button"
                    :class="[ markButtonDisabled ? 'btn-disabled' : 'btn']"
                    @keyup.enter="submit"
                    :disabled="disabledSignIn"
                  >
                    <div class="row">
                      <div :class="[ loadingPending ? 'col-md-6 text-right' : 'col-md-12 text-center' ]">
                        {{ signInText }}
                      </div>
                      <div
                        v-if="loadingPending"
                        :class="[ loadingPending ? 'col-md-6 text-left' : '' ]"
                      >
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          class="loader-div-gif"
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </form>
              <div class="row mt-3 forgot-password">
                <div
                  class="col-12 text-center"
                  id="fp"
                >
                  <router-link :to="{name : 'ForgotPassword'}" :disabled="!loadingPending">
                    <small>Forgot password?</small>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {{ isConnectionLost }}

    <div
      v-if="isMobile"
      style="text-align: justify;padding-right: 5px;padding-left: 5px"
    >
      <b>The content of this page is restricted to be accessed on any mobile device, please use desktop / laptop
        device
        to access it.
      </b>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import SvgIcon from '../components/SvgLoader/svgLoader';

export default {

  name: 'AuthLayout',

  components: {

    PulseLoader,
    SvgIcon

  },

  data () {
    return {

      year: new Date().getFullYear(),
      showMenu: false,

      hasExpired: false,

      model: {

        email: '',
        password: ''

      },

      isMobile: false,

      timer: null,
      signInText: 'Sign In',
      disabledSignIn: false,
      showIncorrectError: false,
      loadingPending: false,
      markButtonDisabled: false,
      incorrectError: '',

      color: 'DimGray',
      size: '12px',
      loading: true
    };
  },

  created () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    if (window.$cookies.isKey('browser-local-cashed-url')) {
      this.hasExpired = true;
    }

    window.addEventListener('resize', this.handleResize);
  },

  computed: {

    ...mapGetters('login', {

      loginPending: 'loginPending',
      getUsername: 'getUsername',
      getPassword: 'getPassword'
    }),

    ...mapGetters('global', [

      'getNoInternetConnection'

    ]),

    enableLoginButton () {
      if (this.getNoInternetConnection) {
        this.markButtonDisabled = false;
        this.loadingPending = false;
        this.disabledSignIn = false;
        this.signInText = 'Sign in';
      }
    },

    isConnectionLost () {
      let myIdle = '';

      if (this.getNoInternetConnection) {
        myIdle = this.getNoInternetConnection;

        if (document.getElementById('connection-lost')) { document.getElementById('connection-lost').style.display = 'block'; }

        this.loadConnectionLostNotification('warn', -1, 'Connection Message', 'Ooooops! connection to the server has failed', 'connection', 1000);
      } else {
        myIdle = this.getNoInternetConnection;

        if (document.getElementById('connection-lost')) { document.getElementById('connection-lost').style.display = 'none'; }
      }

      return myIdle;
    }

  },

  methods: {

    ...mapActions('login', ['login', 'logout', 'getUserData']),

    submit () {
      if (window.$cookies.isKey('browser-local-cashed-url')) {
        this.hasExpired = false;
      }

      this.markButtonDisabled = true;

      if (this.getUsername.length > 0 || this.getPassword.length > 0) {
        if (this.showIncorrectError) {
          this.showIncorrectError = false;
        }

        if (this.disabledSignIn) {
          return;
        }

        let redirect_to = this.$route.query.redirect;

        if (redirect_to == null) {
          redirect_to = '/overview';
        }

        this.signInText = 'Signing In...';
        this.disabledSignIn = true;
        this.loadingPending = true;

        localStorage.clear();

        this.login()
          .then(() => {
            this.getUserData()
              .then(() => {
                this.signInText = 'Logged In';

                this.timer = setInterval(() => {
                  this.loadingPending = false;

                  const userDetails = JSON.parse(localStorage.getItem('browser-local-permits'));

                  if (window.$cookies.isKey('browser-local-cashed-url') && window.$cookies.isKey('browser-local-cashed-url-agent')) {

                    if (window.$cookies.get('browser-local-permits-agent') === window.$cookies.get('browser-local-cashed-url-agent')) {

                      this.$router.push(window.$cookies.get('browser-local-cashed-url'));

                      window.$cookies.remove('browser-local-cashed-url');
                      window.$cookies.remove('browser-local-cashed-url-agent');

                    } else {

                      if (userDetails.includes('DASHBOARD_VIEW')) {
                        this.$router.push('/overview');
                        //window.location.replace('/overview');
                      }else if (userDetails.includes('CUSTOMER_DASHBOARD_VIEW')) {
                        this.$router.push('/overview/customer');
                        //window.location.replace('/overview/customer');

                      }else if (userDetails.includes('CFA_DASHBOARD_VIEW')) {
                        this.$router.push('/overview/clearing-and-forwarding');
                        //window.location.replace('/overview/clearing-and-forwarding');
                      } else if (userDetails.includes('DC_DASHBOARD_VIEW')) {

                        this.$router.push('/overview/document-control');
                        //window.location.replace('/overview/document-control');

                      }else if (userDetails.includes('SA_DASHBOARD_VIEW')) {
                        this.$router.push('/overview/ship-agency');
                        //window.location.replace('/overview/ship-agency');

                      }
                      else if (userDetails.includes('ST_DASHBOARD_VIEW')) {
                        this.$router.push('/overview/ship-tallying');
                        //window.location('/overview/ship-tallying')
                      }

                      window.$cookies.remove('browser-local-cashed-url');
                      window.$cookies.remove('browser-local-cashed-url-agent');
                    }

                  } else {

                    if (userDetails.includes('DASHBOARD_VIEW')) {
                      this.$router.push('/overview');
                      //window.location.replace('/overview')

                    }else if (userDetails.includes('CUSTOMER_DASHBOARD_VIEW')) {
                      this.$router.push('/overview/customer');
                      //window.location.replace('/overview/customer')
                    }else if (userDetails.includes('CFA_DASHBOARD_VIEW')) {
                      this.$router.push('/overview/clearing-and-forwarding');
                      //window.location.replace('/overview/clearing-and-forwarding')
                    }

                    else if (userDetails.includes('DC_DASHBOARD_VIEW')) {
                      this.$router.push('/overview/document-control');
                      //window.location.replace('/overview/document-control')
                    } else if (userDetails.includes('SA_DASHBOARD_VIEW')) {
                      this.$router.push('/overview/document-control');
                      //window.location.replace('/overview/document-control');

                    } else if (userDetails.includes('ST_DASHBOARD_VIEW')) {
                      this.$router.push('/overview/ship-tallying');
                      //window.location.replace('/overview/ship-tallying')
                    }

                  }
                }, 1600);
              }).catch(() => {
                this.incorrectError = 'Ooops something went wrong, please retry';

                this.signInText = 'Sign In';
                this.disabledSignIn = false;
                this.loadingPending = false;
                this.markButtonDisabled = false;
                this.showIncorrectError = true;
                document.getElementById('password').value = '';
                document.getElementById('username').value = '';

                this.logout();
              });
          }).catch((err) => {
            if (err.response.data.error === 'invalid_grant') {
              this.incorrectError = err.response.data.error_description;
            }

            this.signInText = 'Sign In';
            this.showIncorrectError = true;
            this.disabledSignIn = false;
            this.loadingPending = false;
            this.markButtonDisabled = false;
            document.getElementById('password').value = '';
            document.getElementById('username').value = '';
          });
      } else {
        this.incorrectError = "Invalid grant";
        document.getElementById('password').value = '';
        document.getElementById('username').value = '';
        this.showIncorrectError = true;
        this.markButtonDisabled = false;
      }
    },

    // Update username state
    updateUsername (e) {
      if (this.showIncorrectError) {
        this.showIncorrectError = false;
      }

      this.$store.commit('login/UPDATE_USERNAME', e.target.value);
    },
    // Update password state
    updatePassword (e) {
      if (this.showIncorrectError) {
        this.showIncorrectError = false;
      }

      this.$store.commit('login/UPDATE_PASSWORD', e.target.value);
    }

  },

  destroyed () {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  }
};

</script>

<style scoped>

    .container {
        /*position: fixed;*/
        /*top: 15%;*/
        /*!*left: 49.67%;*!*/
        /*left: 16%;*/
        /*!*transform: translate(-50%, -50%);*!*/
        padding-top: 120px;
        z-index: 2;
        text-align: center;
    }

    .forgot-password {
        color: #00BFFF;
        font-size: 18px;
    }

    div#fp a:hover {
        color: #000000;
    }

    div#cl a:hover {
        color: #000000;
    }

    .before-container {
        width: 100%;
        height: 100%;
        background-image: url("../../public/img/10.jpg"); /* The image used */
        background-color: #87CEFA; /* Used if the image is unavailable */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */

        /* filter: blur(2px);
        -webkit-filter: blur(2px); */

        /*opacity: .5;*/

        position: fixed;
        padding-top: 600px;
    }

    .loader-div-gif {
        line-height: 34px;
    }

    .input-container {
        display: -ms-flexbox; /* IE10 */
        display: flex;
        width: 100%;
        margin-bottom: 15px;
    }

    .icon {
        padding: 10px;
        background: dodgerblue;
        color: white;
        min-width: 50px;
        text-align: center;
    }

    .input-field {
        width: 100%;
        padding: 10px;
        outline: none;
    }

    .input-field:focus {
        border: 2px solid dodgerblue;
    }

    .btn {
        background-color: dodgerblue;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        width: 100%;
        opacity: 0.9;
        min-height: 55px;
        max-height: 55px;
    }

    .btn:hover {
        background-color: dodgerblue;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        width: 100%;
        opacity: 1;
        min-height: 55px;
        max-height: 55px;
    }

    .connection-error:hover {
        cursor: none;
    }

    .btn-disabled {
        background-color: #DCDCDC;
        cursor: not-allowed;
        color: #696969;
        padding: 10px 20px;
        border: none;
        width: 100%;
        opacity: 0.9;
        min-height: 55px;
        max-height: 55px;
    }

    .btn-disabled:hover {
        background-color: #DCDCDC;
        cursor: not-allowed;
        color: #696969;
        padding: 10px 20px;
        border: none;
        width: 100%;
        opacity: 1;
        min-height: 55px;
        max-height: 55px;
    }

    .nc-icon {
        padding-top: 14px;
        font-size: 23px;
    }

    .nc-icon-inner {
        font-size: 20px !important;
        padding-left: 30px;
        fill: #FFFFFF;
    }

    .login-notifications {
        padding: 10px;
        background-color: rgb(50,163,239);
        color: white;
        text-align: center;
        transition: opacity 0.6s;
        margin-bottom: 15px;
        max-height: 48px !important;
        z-index: 200;
        border-radius: 5px
    }

    .login-notification-message {
        padding-right: 20px;
    }

    .login-notification-icon {
        padding-left: 0;
        padding-top: 3px;
    }

</style>
<style>
    .color-blue {
        color: dodgerblue;
        font-weight: bold;
    }
</style>
