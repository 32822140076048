<template>
  <accordion
    :voyage="voyage"
    :field-name="fieldName"
    :total-discrepancies="discrepancies.length"
    :list-id="listId"
  >
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">
            #
          </th>
          <th scope="col">
            BL Number
          </th>
          <th scope="col">
            Voyage
          </th>
          <th scope="col">
            Field
          </th>
          <th scope="col">
            Controlled
          </th>
          <th scope="col">
            TANCIS
          </th>
          <th scope="col">
            Description
          </th>
          <th scope="col">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(bl, index) in discrepancies"
          :key="bl.discrepancyId"
        >
          <td scope="row">
            {{ index + 1 }}
          </td>
          <td>{{ bl.blNumber }}</td>
          <td>{{ bl.voyage }}</td>
          <td>{{ bl.fieldName }}</td>
          <td>{{ bl.originalValue ? bl.originalValue : "Nil" }}</td>
          <td>{{ bl.newValue }}</td>
          <td>{{ bl.description }}</td>

          <td :class="[ bl.originalValue ? 'text-warning' : 'text-danger']">
            {{ bl.originalValue ? "Altered" : "Not Found" }}
          </td>
        </tr>
      </tbody>
    </table>
  </accordion>
</template>
<script>
import API from '../../../api/index'
import Accordion from './Accordion.vue'

export default {

  name: 'ImportIndividualSummary',

  components: {
    Accordion

  },
  props: {
    voyage: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    listId: Number
  },

  data () {
    return {

      discrepancies: [],
      activetab: 1,
      activetabmain: 7,

      color: 'green',
      size: '15px',
      loading: true,
      myLoader: false

    }
  },
  created () {
    this.loadReconciliation()
  },
  methods: {
    loadReconciliation () {
      this.myloader = true

      API.getTancisReconciliationIndividualSummary(this.voyage, this.fieldName).then(
        response => {
          if (response.data.status) {
            this.discrepancies = response.data.data

            this.myloader = false
          } else {

          }
        }
      ).catch(e => {

      })
    }
  }

}
</script>
<style scoped>
  body {
    background-color: #f1f5f8;
  }
</style>
