<template>
  <div>
    <form
      @submit.prevent="loadReport"
      v-if="finalUserPermissions.includes('CFAREPORT_GET-CFA-REPORT_GET')"
    >
      <card>
        <div
          class="col-md-12 text-center"
          v-if="!areAllLoaded"
        >
          <b>CFA DCB REPORT DOWNLOAD</b> <i> ( if no option selected, report with all available options will be generated )</i>
        </div>
        <div
          class="col-md-12 text-center"
          v-if="areAllLoaded"
        >
          <div class="row">
            <div class="col-md-6 text-right">
              Please wait loading contents .....
            </div>
            <div class="col-md-6 text-left">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12 alert-dismissible fade show"
          role="alert"
          style="border-radius:5px;"
          :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
          @enter="enter"
        >
          <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
          <strong v-if="ErrorAlert">Notification!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

          <button
            aria-label="Close"
            class="close close-custom"
            data-dismiss="alert"
            style="line-height:2px;"
            type="button"
            v-if="SuccessAlert"
            @click="fadeMe"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <button
            aria-label="Close"
            class="close close-custom"
            data-dismiss="alert"
            style="line-height:2px;"
            type="button"
            v-if="ErrorAlert"
            @click="fadeMe"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <br>

        <div class="row">
          <div class="col-md-12 text-left">
            <b>Shipment type Option</b>
          </div>

          <div
            class="col-md-3 text-left"
            v-for="type in getRequestType"
            :key="type.requestTypeId"
          >
            <div class="custom-control">
              <input
                type="radio"
                name="rt001"
                :value="type.requestTypeId"
                v-model="requestTypeId"
              >&nbsp;
              <label
                :for="type.requestTypeId"
                class="roles"
              > {{ type.requestTypeName }}</label>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-12 text-left">
            <b>Regime Option</b>
          </div>

          <div
            class="col-md-3 text-left"
            v-for="regime in getRegime"
            :key="regime.regimeId"
          >
            <div class="custom-control">
              <input
                type="radio"
                name="reg001"
                :value="regime.regimeId"
                v-model="regimeId"
              >&nbsp;
              <label
                :for="regime.regimeId"
                class="roles"
              > {{ regime.regimeName }}</label>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-12 text-left">
            <b>Customer Name (TIN) Option</b>
          </div>

          <div
            class="col-md-4 text-left"
            v-for="customer in newAllCustomers"
            :key="customer.customerId"
          >
            <div class="custom-control">
              <input
                type="radio"
                name="cn001"
                :value="customer.customerId"
                v-model="customerId"
              >&nbsp;
              <label
                :for="customer.customerId"
                class="roles"
              > {{ customer.customerName }}</label>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-12 text-left">
            <b>Application Status Option</b>
          </div>

          <div
            class="col-md-4 text-left"
            v-for="app in AppStatus"
            :key="app.statusId"
          >
            <div class="custom-control">
              <input
                type="radio"
                name="as001"
                :value="app.statusId"
                v-model="status"
              >&nbsp;
              <label
                :for="app.statusId"
                class="roles"
              > {{ app.statusName }}</label>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-12 text-left">
            <b>Date range Option</b>
          </div>

          <div class="col-md-3">
            <base-input
              type="date"
              label="Start Date"
              v-model="startDate"
              :max="todayDate"
              @change="StartDateError = false"
            />
            <div v-if="StartDateError">
              <div class="error">
                This field is required
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <base-input
              type="date"
              label="End Date"
              v-model="endDate"
              :min="startDate"
              :max="todayDate"
              @change="EndDateError = false"
            />
            <div v-if="EndDateError">
              <div class="error">
                This field is required
              </div>
            </div>
          </div>
        </div>

        <hr>

        <br>

        <div class="text-center row">
          <div class="col-md-6 text-right loader-div">
            <span v-if="isGeneratingReport">Please wait system is generating requested report ....</span>
          </div>
          <div class="col-md-2 left loader-div-gif">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="isGeneratingReport"
            />
          </div>
          <div class="col-md-4">
            <button
              @click.prevent="resetParams"
              class="btn btn-fill btn-outline-info float-right"
              data-dismiss="modal"
            >
              Reset Options
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-space float-right"
              data-dismiss="modal"
            >
              Download Report
            </button>
          </div>
        </div>
      </card>
    </form>
  </div>
</template>

<script>

import API from '../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

  name: 'CfaDcb',

  components: {

    PulseLoader

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'CFA DCB Report Download')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadRequestType()
    this.loadRegime()
    this.loadAllCustomers()
  },

  data () {
    return {

      todayDate: new Date().toJSON().slice(0, 10),

      finalUserPermissions: [],

      reportCriteria: [

        { criteriaId: 1, criteriaName: 'All' },
        { criteriaId: 2, criteriaName: 'Shipment type' },
        { criteriaId: 3, criteriaName: 'Regime' },
        { criteriaId: 4, criteriaName: 'Client Name' },
        { criteriaId: 5, criteriaName: 'HS-Code' },
        { criteriaId: 6, criteriaName: 'Application Status' }

      ],

      AppStatus: [

        { statusId: 'SUBMITTED', statusName: 'Application Submitted' },
        { statusId: 'REJECTED_MAJOR', statusName: 'Application Returned For Major Changes' },
        { statusId: 'REJECTED_MINOR', statusName: 'Application Returned For Minor Changes' },
        { statusId: 'RETURNED', statusName: 'Application Resubmitted After Changes' },
        { statusId: 'APPROVED', statusName: 'Application Accepted' },
        { statusId: 'ASSESSED', statusName: 'Application Assessed' },
        { statusId: 'REJECTED_ASSESSMENT', statusName: 'Application Assessment Rejected' },
        { statusId: 'CHARGED', statusName: 'Application Charged' }

      ],

      requestTypeId: '',
      regimeId: '',
      customerId: '',
      status: '',
      startDate: '',
      endDate: '',
      allReport: '',
      allReportValue: '',

      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: '',

      color: 'green',
      size: '15px',
      loading: true,

      getRequestType: [],
      getRegime: [],
      allCustomers: [],
      newAllCustomers: [],
      getHsCode: [],

      StartDateError: false,
      EndDateError: false,
      criteriaError: false,
      isGeneratingReport: false,
      areAllLoaded: false

    }
  },

  methods: {

    loadReport () {
      let currentTime = ''
      let hours = new Date().getHours()
      let minute = new Date().getMinutes()
      let second = new Date().getSeconds()
      let millisecond = new Date().getMilliseconds()

      if (hours < 10) {
        hours = '0' + hours
      }
      if (minute < 10) {
        minute = '0' + minute
      }
      if (second < 10) {
        second = '0' + second
      }
      if (millisecond < 10) {
        millisecond = '00' + millisecond
      }
      if (millisecond >= 10 && millisecond < 100) {
        millisecond = '0' + millisecond
      }

      currentTime = 'T' + hours + ':' + minute + ':' + second + '.' + millisecond

      let startDate = ''
      let endDate = ''
      let requestTypeId = ''
      let regimeId = ''
      let customerId = ''
      let status = ''
      let params = ''

      if (this.startDate !== '') {
        startDate = '&startDate=' + this.startDate + currentTime
      } else {
        startDate = ''
      }

      if (this.endDate !== '') {
        endDate = '&endDate=' + this.endDate + currentTime
      } else {
        endDate = ''
      }

      if (this.requestTypeId !== '') {
        requestTypeId = '&requestTypeId=' + this.requestTypeId
      } else {
        requestTypeId = ''
      }

      if (this.regimeId !== '') {
        regimeId = '&regimeId=' + this.regimeId
      } else {
        regimeId = ''
      }

      if (this.status !== '') {
        status = '&status=' + this.status
      } else {
        status = ''
      }

      if (this.customerId !== '') {
        customerId = '&customerId=' + this.customerId
      } else {
        customerId = ''
      }

      if (customerId === '' && status === '' && regimeId === '' && requestTypeId === '' && endDate === '' &&
          startDate === '') {
        params = ''
      } else {
        params = '?' + startDate + endDate + requestTypeId + regimeId + customerId + status
      }

      if (this.startDate === '' && this.endDate !== '') {
        this.StartDateError = true
      } else if (this.startDate !== '' && this.endDate === '') {
        this.EndDateError = true
      } else {
        this.isGeneratingReport = true

        API.generateCfaReport(params).then(
          response => {
            if (response.message === 'No content found' || response.status === 204) {
              this.ErrorMessage = ', No report found on requested options'
              this.SuccessAlert = false
              this.ErrorAlert = true
              this.SuccessMessage = ''
              this.isGeneratingReport = false
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'CFA-REPORT.xlsx')
              document.body.appendChild(link)
              link.click()
              this.isGeneratingReport = false

              this.SuccessAlert = false
              this.ErrorAlert = false
              this.SuccessMessage = ''
              this.ErrorMessage = ''

              this.requestTypeId = ''
              this.regimeId = ''
              this.customerId = ''
              this.status = ''
              this.startDate = ''
              this.endDate = ''
              this.allReport = ''
            }
          }
        ).catch(() => {

        })
      }
    },

    resetParams () {
      this.requestTypeId = ''
      this.regimeId = ''
      this.customerId = ''
      this.status = ''
      this.startDate = ''
    },

    loadRequestType () {
      this.areAllLoaded = true

      API.getRequestType().then(
        response => {
          if (response.data.status) {
            this.getRequestType = response.data.data

            this.areAllLoaded = false
          } else {

          }
        }
      ).catch(() => {

      })
    },

    loadRegime () {
      this.areAllLoaded = true

      API.getRegime().then(
        response => {
          if (response.data.status) {
            this.getRegime = response.data.data

            this.areAllLoaded = false
          } else {

          }
        }
      ).catch(() => {

      })
    },

    loadAllCustomers () {
      this.areAllLoaded = true

      API.getCustomerAll().then(
        response => {
          if (response.data.status) {
            this.allCustomers = response.data.data

            this.customCustomerDetails()

            this.areAllLoaded = false
          } else {

          }
        }
      ).catch(() => {

      })
    },

    customCustomerDetails () {
      this.areAllLoaded = true

      for (let i = 0; i < this.allCustomers.length; i++) {
        const object = {

          customerId: this.allCustomers[i].customerId,
          customerName: this.allCustomers[i].name + ' - ( TIN ' + this.allCustomers[i].tin + ' )'

        }

        this.newAllCustomers.push(object)

        this.areAllLoaded = false
      }
    },

    loadHsCode () {
      this.areAllLoaded = true

      API.getHsCode().then(
        response => {
          if (response.data.status) {
            this.getHsCode = response.data.data

            this.areAllLoaded = false
          } else {

          }
        }
      ).catch(() => {

      })
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    }

  }

}
</script>

<style scoped>
  .btn-space {
    margin-right: 10px;
  }
</style>
