<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-12">
          Bill Action Color Keys
        </div>
        <div class="col-md-12">
          <hr>
        </div>
        <div class="col-md-12">
          <br>
          <div class="row">
            <div class="col-md-4">
              <span>
                <font-awesome-icon
                  icon="file-pdf"
                  color="red"
                /><i class=" text-muted"> Download Invoice PDF</i>
              </span>
            </div>
            <div class="col-md-4">
              <span>
                <font-awesome-icon
                  icon="file-pdf"
                  color="green"
                /><i
                  class=" text-muted"
                > Download Receipt PDF</i>
              </span>
            </div>
          </div>
          <br>
        </div>
      </div>
    </card>
    <card>
      <!--<div class="row">-->
      <!--<div class="col-md-12">-->
      <!--<label>Search Activity Name</label>-->
      <!--<multiselect :options="customerOptions"-->
      <!--v-model="selectedcustomers" label="name" track-by="code" placeholder="Type to search Customer" :searchable="true" :loading="isLoading" :close-on-select="true" :options-limit="300" :limit="3">-->
      <!--<template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.name }}</span><span class="custom__remove" @click="remove(option)">❌</span></span></template>-->
      <!--<template slot="clear" slot-scope="props">-->
      <!--<div class="multiselect__clear" v-if="selectedcustomers.length" @mousedown.prevent.stop="clearAll(props.search)"></div>-->
      <!--</template>-->
      <!--<span slot="noResult">Oops! No elements found. Consider changing the search query.</span>-->
      <!--</multiselect>-->
      <!--</div>-->
      <!--</div>-->

      <div class="row">
        <div class="col-md-12 text-left">
          <b>Application Status Option</b>
        </div>

        <div class="col-md-4 text-left">
          <div class="custom-control">
            <input
              type="radio"
              name="as001"
              v-model="status"
              value="Not Approved"
            >&nbsp;
            <label class="roles">Not Approved</label>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <div class="custom-control">
            <input
              type="radio"
              name="as001"
              v-model="status"
              value="Approved"
            >&nbsp;
            <label class="roles">Approved</label>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <div class="custom-control">
            <input
              type="radio"
              name="as001"
              v-model="status"
              value="Waiting Control Number"
            >&nbsp;
            <label class="roles">Waiting Control Number</label>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <div class="custom-control">
            <input
              type="radio"
              name="as001"
              v-model="status"
              value="Failed Submission to GePG"
            >&nbsp;
            <label class="roles">Failed to submit GEPG</label>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <div class="custom-control">
            <input
              type="radio"
              name="as001"
              v-model="status"
              value="Paid"
            >&nbsp;
            <label class="roles">Paid</label>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <div class="custom-control">
            <input
              type="radio"
              name="as001"
              v-model="status"
              value="Partial Paid"
            >&nbsp;
            <label class="roles">Partial Paid</label>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <div class="custom-control">
            <input
              type="radio"
              name="as001"
              v-model="status"
              value="Not Paid"
            >&nbsp;
            <label class="roles">Not Paid</label>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <div class="custom-control">
            <input
              type="radio"
              name="as001"
              v-model="status"
              value="Canceled"
            >&nbsp;
            <label class="roles">Cancelled</label>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12 text-left">
          <b>Date range Option</b>
        </div>
        <div class="col-md-3">
          <base-input
            type="date"
            label="Start Date"
            v-model="startDate"
            :max="todayDate"
            @change="StartDateError = false"
          />
          <div v-if="StartDateError">
            <div class="error">
              This field is required
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <base-input
            type="date"
            label="End Date"
            v-model="endDate"
            :min="startDate"
            :max="todayDate"
            @change="EndDateError = false"
          />
          <div v-if="EndDateError">
            <div class="error">
              This field is required
            </div>
          </div>
        </div>
      </div>
      <hr>

      <br>
      <div class="row">
        <div class="col-md-12">
          <button
            @click="reset"
            class="btn btn-fill btn-outline-info float-right mr-2"
            data-dismiss="modal"
          >
            Reset Options
          </button>
          <button
            class="btn btn-primary btn-space float-right mr-2"
            data-dismiss="modal"
            @click="getCfaBills"
          >
            Search
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="viewBillsLoader"
          />
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-md-3">
          Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
        </div>
        <div
          class="col-md-9 text-right disable-highlight"
          unselectable="on"
          style="line-height: 5px;font-size: 20px"
        >
          <span
            class="btn-space"
            :class="[
              this.pageNo === 0 || this.pageCount - 1 < 0
                ? 'disabled-click'
                : 'enabled-click'
            ]"
            @click="firstPage"
            title="go to first page"
          >First
          </span>
          <span
            class="btn-space mr-4"
            :class="[
              this.pageNo === 0 || this.pageCount - 1 < 0
                ? 'disabled-click'
                : 'enabled-click'
            ]"
            @click="prevPage"
            title="go to previous page"
          >
            <font-awesome-icon icon="angle-double-left" />
          </span>

          <span class="btn-space" />
          <span class="btn-space" />

          <span
            class="btn-space mr-2"
            :class="[
              this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0
                ? 'disabled-click'
                : 'enabled-click'
            ]"
            @click="nextPage"
            title="go to next page"
          >
            <font-awesome-icon icon="angle-double-right" />
          </span>
          <span
            class="btn-space"
            :class="[
              this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0
                ? 'disabled-click'
                : 'enabled-click'
            ]"
            @click="lastPage"
            title="go to last page"
          >Last
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            class="col-md-12 alert-dismissible fade show"
            role="alert"
            :class="[
              SuccessAlert ? 'alert-success' : '',
              ErrorAlert ? 'alert-danger' : ''
            ]"
            style="border-radius:5px;"
            @enter="enter"
          >
            <strong v-if="SuccessAlert">Successifully!</strong>
            <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
            <strong v-if="ErrorAlert">Error!</strong>
            <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

            <button
              type="button"
              class="close close-custom"
              data-dismiss="alert"
              aria-label="Close"
              v-if="SuccessAlert"
              style="line-height:2px;"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              type="button"
              class="close close-custom"
              data-dismiss="alert"
              aria-label="Close"
              v-if="ErrorAlert"
              style="line-height:2px;"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="viewBillsLoader"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  Bill Number
                </th>
                <th scope="col">
                  Control Number
                </th>
                <th scope="col">
                  Total Amount
                </th>
                <th scope="col">
                  Status
                </th>
                <th scope="col">
                  Date Billed
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(bill, index) in bills" :key="index" :class="bill.status == `Canceled` ? `alert alert-danger` : ``"
              >
                <th scope="row">
                  {{ index + 1 }}
                </th>
                <td>{{ bill.billNo }}</td>
                <td>{{ bill.controlNumber != null ? bill.controlNumber : 'NIL' }}</td>
                <td>{{ bill.billVatedAmountTzs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " TZS" }}</td>
                <td>{{ bill.status }}</td>
                <td>{{ dateToHuman(bill.dateBilled) }}</td>

                <td>
                  <div
                    class=" all-fa-icons edit-fa-icon"
                    title="Print Bill"
                    v-if="bill.status!==`Canceled`"
                  >
                    <font-awesome-icon
                      icon="file-pdf"
                      color="red"
                      v-if="finalUserPermissions.includes('BILLS_GET-INVOICE-PDF_{BILLID}_GET')"
                      @click="getInvoice(bill.billId)"
                    />
                  </div>
                </td>
                <td>
                  <div
                    class=" all-fa-icons edit-fa-icon"
                    title="Print Receipt"
                    v-if="bill.status===`Paid`"
                  >
                    <font-awesome-icon
                      icon="file-pdf"
                      color="green"
                      v-if="finalUserPermissions.includes('BILLS_GET-RECEIPT-PDF_{BILLID}_GET')"
                      @click="getReceipt(bill.billId)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
            </div>
            <div
              class="col-md-9 text-right disable-highlight"
              unselectable="on"
              style="line-height: 5px;font-size: 20px"
            >
              <span
                class="btn-space"
                :class="[
                  this.pageNo === 0 || this.pageCount - 1 < 0
                    ? 'disabled-click'
                    : 'enabled-click'
                ]"
                @click="firstPage"
                title="go to first page"
              >First
              </span>
              <span
                class="btn-space mr-4"
                :class="[
                  this.pageNo === 0 || this.pageCount - 1 < 0
                    ? 'disabled-click'
                    : 'enabled-click'
                ]"
                @click="prevPage"
                title="go to previous page"
              >
                <font-awesome-icon icon="angle-double-left" />
              </span>

              <span class="btn-space" />
              <span class="btn-space" />

              <span
                class="btn-space mr-2"
                :class="[
                  this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0
                    ? 'disabled-click'
                    : 'enabled-click'
                ]"
                @click="nextPage"
                title="go to next page"
              >
                <font-awesome-icon icon="angle-double-right" />
              </span>
              <span
                class="btn-space"
                :class="[
                  this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0
                    ? 'disabled-click'
                    : 'enabled-click'
                ]"
                @click="lastPage"
                title="go to last page"
              >Last
              </span>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import API from '../../../api'
import API_REVENUE from '../../../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { required } from 'vuelidate/lib/validators'
import Modal from '../../../components/Inputs/Modal'

export default {
  name: 'CustomerBills',

  components: {
    PulseLoader,
    Modal
  },

  validations: {
    cancelReason: { required }
  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Application Bills List')

    this.finalUserPermissions = JSON.parse(
      localStorage.getItem('browser-local-permits')
    )
    this.getCfaBills()
  },

  data () {
    return {
      viewBillsLoader: false,
      bills: [],
      cancelCharge: false,
      selectedBillId: '',
      customerNameForCancel: '',
      cancelReason: '',
      cancelLoader: false,
      isCancelReason: false,
      showModal: false,
      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      color: 'green',
      size: '15px',
      loading: true,
      todayDate: new Date().toJSON().slice(0, 10),
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      startDate: '',
      endDate: '',
      status: '',

      StartDateError: false,
      EndDateError: false,
      isLoading: false
    }
  },

  methods: {
    dateToHuman (stringDate) {
      if (stringDate !== null || stringDate !== undefined) {
        const splittedDate = stringDate.split('T')
        return splittedDate[0]
      }
    },

    reset () {
      this.startDate = ''
      this.endDate = ''
      this.status = ''
      this.getCfaBills()
    },

    getCfaBills () {
      const currentTime = 'T00:00:00'

      let startDate = ''
      let endDate = ''
      let status = ''
      let params = ''

      if (this.status !== '') {
        status = this.status
      }
      this.startDate != '' ? startDate = '&startBillDate=' + this.startDate + currentTime : startDate = ''
      this.endDate != '' ? endDate = '&endBillDate=' + this.endDate + currentTime : endDate = '';
      (this.startDate === '' && this.endDate === '') ? params = '' : params = startDate + endDate

      if (status != '') {
        params = params + '&billStatus=' + status
      }

      this.viewBillsLoader = true

      API_REVENUE.getPageableCustomerCFABills(this.pageNo, this.pageSize, params)
        .then(response => {
          this.bills = response.data.data
          this.pageCount = response.data.message
          this.viewBillsLoader = false
        })
        .catch((e) => {
          this.viewBillsLoader = false
          this.ErrorMessage = e.message
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
        })
    },

    getReceipt (billId) {
      this.viewBillsLoader = true

      API_REVENUE.getReceipt(billId).then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'receipt.pdf') // or any other extension
          document.body.appendChild(link)
          link.click()
          this.viewBillsLoader = false
        }
      ).catch(e => {
        this.ErrorMessage = e.message
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.viewBillsLoader = false
      })
    },

    getInvoice (billId) {
      this.viewBillsLoader = true

      API_REVENUE.downloadBillInvoice(billId).then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'invoice.pdf') // or any other extension
          document.body.appendChild(link)
          link.click()
          this.viewBillsLoader = false
        }
      ).catch(e => {
        this.ErrorMessage = e.message
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.viewBillsLoader = false
      })
    },

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.getCfaBills()
      } else {
        this.getCfaBills()
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.getCfaBills()
      } else {
        this.getCfaBills()
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.getCfaBills()
      } else {
        this.getCfaBills()
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.getCfaBills()
      } else {
        this.getCfaBills()
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
        this.singlePlanAddLoader = []
      } else {
        this.ErrorAlert = !this.ErrorAlert
        this.singlePlanAddLoader = []
      }
    },

    enter: function (el, done) {
      const that = this
      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    }
  }
}
</script>

<style scoped>
  .td {
    padding: 0 !important;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  .pointer {
    cursor: pointer;
  }

  .generate-btn {
    color: #ffffff;
  }

  .the-canvas {
    border: 1px solid black;
  }

  .blue {
    background-color: #0069d9;
  }

  .grey {
    background-color: #6c757d;
  }

  .grey:hover {
    background-color: #6c757d;
  }
</style>
