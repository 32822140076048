<template>
  <div>
    <card v-if="!assessmentNoticeLoader">
      <div class="row">
        <div class="col-md-6">
          {{ noticeType === 'assessment-notice' ? 'Assessment ' : 'Release ' }} Notice for Application: File No. - [ <b> {{ singleRequest.fileNo }} </b> ] |
          BL/Airway Bill/RCN - [ <b> {{ singleRequest.blNumber }} </b> ]
        </div>
        <div class="col-md-6 text-right">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="assessmentNoticeLoader"
          />
          <button
            class="btn btn-default btn-fill custom-btn-all"
            @click.prevent="goBack"
            v-if="!assessmentNoticeLoader"
          >
            Back
          </button>
        </div>
      </div>
    </card>

    <card v-if="assessmentNoticeLoader">
      <div
        class="row"
      >
        <div class="col-md-12 text-center" style="color:#d59a18;">
          <b>Please wait while system is loading {{ noticeType === 'assessment-notice' ? 'Assessment ' : 'Release ' }} Notice details</b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
          />
        </div>
      </div>
    </card>

    <card style="font-size: 11px" v-if="!assessmentNoticeLoader">
      <div>
            <div class="legend" v-if="typeof assessmentNotice.general !== 'undefined'">
              <table style="width:100%">
                <tbody>
                  <tr>
                    <th class="notice-table-label">
                      1. Mode of Declaration
                    </th>
                    <td>
                      {{ assessmentNotice.general.declarationModeCd }}
                      -
                      {{ assessmentNotice.general.declarationModeName }}
                    </td>
                    <th class="notice-table-label">
                      Processing Clearing Office
                    </th>
                    <td>
                      {{ assessmentNotice.general.customsOfficeCd }}
                      -
                      {{ assessmentNotice.general.customsOfficeName }}
                    </td>
                    <th class="notice-table-label">
                      TANSAD Number & Date
                    </th>
                    <td>
                      <span class="mr-4">{{ assessmentNotice.general.tansadNo }}</span>
                      <span>{{ assessmentNotice.general.tansadDt }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="legend"
              v-if="typeof assessmentNotice.general !== 'undefined'"
            >
              <h6 class="legend-title">
                2. Exporter/Consigner
              </h6>
              <table style="width:100%">
                <tbody>
                  <tr>
                    <th class="notice-table-label">
                      TIN
                    </th>
                    <td>
                      {{ assessmentNotice.general.exportCompanyTin }}
                    </td>
                    <th class="notice-table-label">
                      Name
                    </th>
                    <td>
                      {{ assessmentNotice.general.exportCompanyName }}
                    </td>
                    <th class="notice-table-label">
                      Address
                    </th>
                    <td>
                      {{ assessmentNotice.general.exportCompanyAddress }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="legend"
              v-if="typeof assessmentNotice.general !== 'undefined'"
            >
              <h6 class="legend-title">
                3 - 8
              </h6>
              <table style="width:100%">
                <tbody>
                  <tr>
                    <th class="notice-table-label">
                      3. No. of Items
                    </th>
                    <td>
                      {{ assessmentNotice.general.totalItemNo }}
                    </td>
                    <th class="notice-table-label">
                      4. CL. Plan
                    </th>
                    <td>
                      {{ assessmentNotice.general.clearancePlanCd }}
                    </td>
                    <th class="notice-table-label">
                      5. Security Account No
                    </th>
                    <td>
                      {{ assessmentNotice.general.securityAccountNo }}
                    </td>
                  </tr>
                  <tr>
                    <th class="notice-table-label">
                      Receipt Number & Date
                    </th>
                    <td>
                      <span class="mr-4">{{ assessmentNotice.general.firstReceiptNo }}</span>
                      <span>{{ assessmentNotice.general.firstReceiptDt }}</span>
                    </td>
                    <th class="notice-table-label">
                      6.Country Last Consignment
                    </th>
                    <td>
                      {{ assessmentNotice.general.consignmentCountryCd }}
                      -
                      {{ assessmentNotice.general.consignmentCountryName }}
                    </td>
                    <th class="notice-table-label">
                      7. Trading Country
                    </th>
                    <td>
                      {{ assessmentNotice.general.tradingCountryCd }}
                      -
                      {{ assessmentNotice.general.tradingCountryName }}
                    </td>
                  </tr>
                  <tr>
                    <th class="notice-table-label">
                      8.Country of Export
                    </th>
                    <td>
                      {{ assessmentNotice.general.exportCountryCd }}
                      -
                      {{ assessmentNotice.general.exportCountryName }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="legend"
              v-if="typeof assessmentNotice.general !== 'undefined' && assessmentNotice.general.importer !== null"
            >
              <h6 class="legend-title">
                9. Importer/Consigner
              </h6>
              <table style="width:100%">
                <tbody>
                  <tr>
                    <th class="notice-table-label">
                      TIN
                    </th>
                    <td>
                      {{ assessmentNotice.general.importer.tin }}
                    </td>
                    <th class="notice-table-label">
                      Name
                    </th>
                    <td>
                      {{ assessmentNotice.general.importer.companyName }}
                    </td>
                    <th class="notice-table-label">
                      Address
                    </th>
                    <td>
                      {{ assessmentNotice.general.importer.address }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="legend"
              v-if="typeof assessmentNotice.general !== 'undefined'"
            >
              <h6 class="legend-title">
                10 - 15
              </h6>

              <table style="width:100%">
                <tbody>
                  <tr>
                    <th class="notice-table-label">
                      10.Country of Destination
                    </th>
                    <td colspan="2">
                      {{ assessmentNotice.general.destinationCountryCd }}
                      -
                      {{ assessmentNotice.general.destinationCountryName }}
                    </td>
                    <th class="notice-table-label">
                      11.Delivery Term Code
                    </th>
                    <td colspan="2">
                      {{ assessmentNotice.general.deliveryTermCd }}
                      -
                      {{ assessmentNotice.general.deliveryTermName }}
                    </td>
                    <th class="notice-table-label">
                      Place
                    </th>
                    <td colspan="2">
                      {{ assessmentNotice.general.deliveryPlaceCd }}
                      -
                      {{ assessmentNotice.general.deliveryPlaceName }}
                    </td>
                  </tr>
                  <tr>
                    <th class="notice-table-label">
                      12.Nat of Trans
                    </th>
                    <td>{{ assessmentNotice.general.natureTrans1Cd }}</td>
                    <td>{{ assessmentNotice.general.natureTrans2Cd }}</td>
                    <th class="notice-table-label">
                      13.Exchange Rate
                    </th>
                    <td colspan="2">
                      {{ assessmentNotice.general.exchangeRate }}
                    </td>
                    <th class="notice-table-label">
                      14.Currency
                    </th>
                    <td colspan="2">
                      {{ assessmentNotice.general.currencyCd }}
                    </td>
                  </tr>
                  <tr>
                    <th class="notice-table-label">
                      Total Invoice Value
                    </th>
                    <td colspan="2">
                      {{
                        assessmentNotice.general.totalInvoiceAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <th class="notice-table-label">
                      15.Invoice No & Date
                    </th>
                    <td>{{ assessmentNotice.general.invoiceNo }}</td>
                    <td>{{ assessmentNotice.general.invoiceDay }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="legend"
              v-if="typeof assessmentNotice.general !== 'undefined'"
            >
              <h6 class="legend-title">
                16.Valuation Note
              </h6>
              <table style="width:100%">
                <tbody>
                  <tr>
                    <th class="notice-table-label ">
                      Freight Charges : Currency
                    </th>
                    <td>
                      {{
                        assessmentNotice.general.freightAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td class="">
                      {{ assessmentNotice.general.declaredInvoiceCur }}
                    </td>
                    <td class="" />
                    <th class="notice-table-label">
                      Insurance : Currency
                    </th>
                    <td>
                      {{
                        assessmentNotice.general.declaredInsuranceAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td class="">
                      {{ assessmentNotice.general.declaredInvoiceCur }}
                    </td>
                    <th class="notice-table-label">
                      Other Charges : Currency
                    </th>
                    <td>
                      {{
                        assessmentNotice.general.declaredOtherCostAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td class="">
                      {{ assessmentNotice.general.declaredInvoiceCur }}
                    </td>
                  </tr>
                  <tr>
                    <th class="notice-table-label">
                      Deductions :Currency
                    </th>
                    <td>
                      {{
                        assessmentNotice.general.declaredDeductionAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>{{ assessmentNotice.general.declaredInvoiceCur }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="legend"
              v-if=" typeof assessmentNotice.general !== 'undefined' && assessmentNotice.general.declarant !== null"
            >
              <h6 class="legend-title">
                17. Declarant/Agent
              </h6>
              <table style="width:100%">
                <tbody>
                  <tr>
                    <th class="notice-table-label">
                      TIN
                    </th>
                    <td>
                      {{ assessmentNotice.general.declarant.tin }}
                    </td>
                    <th class="notice-table-label">
                      Name
                    </th>
                    <td>
                      {{ assessmentNotice.general.declarant.companyName }}
                    </td>
                    <th class="notice-table-label">
                      Address
                    </th>
                    <td>
                      {{ assessmentNotice.general.declarant.address }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="legend"
              v-if="typeof assessmentNotice.general !== 'undefined'"
            >
              <h6 class="legend-title">
                18 - 23
              </h6>

              <table style="width:100%">
                <tbody>
                  <tr>
                    <th class="notice-table-label">
                      18.No Packgs( {{ assessmentNotice.general.packageTypeCd }} - {{
                        assessmentNotice.general.packageTypeName }})
                    </th>
                    <td>
                      {{ assessmentNotice.general.totalPackageNo }}
                    </td>
                    <th class="notice-table-label">
                      19.Gross Weight(Kgs)
                    </th>
                    <td>
                      {{
                        assessmentNotice.general.grossWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <th class="notice-table-label">
                      20.Net Weight(Kgs)
                    </th>
                    <td>
                      {{
                        assessmentNotice.general.netWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="notice-table-label">
                      21. Entry/Exit Office
                    </th>
                    <td>
                      {{ assessmentNotice.general.entryOfficeCd }} - {{
                        assessmentNotice.general.entryOfficeName }}
                    </td>
                    <th class="notice-table-label">
                      22. Loc.of Goods
                    </th>
                    <td>
                      {{ assessmentNotice.general.goodsLocationCd }} - {{
                        assessmentNotice.general.goodsLocationName }}
                    </td>
                    <th class="notice-table-label">
                      23. AWB/BL/RCN/Shipping Order
                    </th>
                    <td>
                      {{ assessmentNotice.general.blNo }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h5>Result of Valuation</h5>

            <div
              class="legend"
              v-if="typeof assessmentNotice.general !== 'undefined'"
            >
              <h6 class="legend-title">
                Result of Valuation
              </h6>
              <table style="width:100%">
                <thead>
                  <tr>
                    <th class="notice-table-label">
                      State
                    </th>
                    <th class="notice-table-label">
                      Currency
                    </th>
                    <th class="notice-table-label">
                      TOTAL FOB
                    </th>
                    <th class="notice-table-label">
                      Freight
                    </th>
                    <th class="notice-table-label">
                      Insurance
                    </th>
                    <th class="notice-table-label">
                      Other charges
                    </th>
                    <th class="notice-table-label">
                      Deductions
                    </th>
                    <th class="notice-table-label">
                      Total Customs Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="notice-table-label">
                      Declared
                    </td>
                    <td>{{ assessmentNotice.general.currencyCd }}</td>
                    <td>
                      {{
                        assessmentNotice.general.declaredInvoiceAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>
                      {{
                        assessmentNotice.general.freightAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>
                      {{
                        assessmentNotice.general.declaredInsuranceAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>
                      {{
                        assessmentNotice.general.declaredOtherCostAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>
                      {{
                        assessmentNotice.general.declaredDeductionAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>
                      {{
                        assessmentNotice.general.declaredTotalCustomsVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="notice-table-label">
                      Assessed
                    </td>
                    <td>{{ assessmentNotice.general.currencyCd }}</td>
                    <td>
                      {{
                        assessmentNotice.general.assessedInvoiceAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>
                      {{
                        assessmentNotice.general.assessedFreightAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>
                      {{ assessmentNotice.general.assessedInsuranceAmt !== null ?
                        assessmentNotice.general.assessedInsuranceAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") : ''
                      }}
                    </td>
                    <td>
                      {{
                        assessmentNotice.general.assessedOtherCostAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>
                      {{
                        assessmentNotice.general.assessedDeductionAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                    <td>
                      {{
                        assessmentNotice.general.assessedTotalCustomsVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="legend">
              <h6 class="legend-title">
                30
              </h6>

              <div
                class="legend-colorless"
                v-for="(item , index_item) in assessmentNotice.items"
              >
                <h6 class="legend-title-colorless">
                  Description for Item {{ item.itemNo }}
                </h6>

                <table style="width:100%">
                  <thead>
                    <tr>
                      <th class="notice-table-label border-right-0 text-nowrap">
                        Description for Item {{ item.itemNo }}
                      </th>
                      <td class="notice-table-label border-left-0" />
                      <th
                        class="notice-table-label"
                        colspan="2"
                      >
                        31. H.S Code
                      </th>
                      <th class="notice-table-label">
                        Original Country
                      </th>
                      <th class="notice-table-label">
                        Preference Code
                      </th>
                      <th class="notice-table-label">
                        EPC Code
                      </th>
                      <th class="notice-table-label">
                        APC Code
                      </th>
                      <th class="notice-table-label">
                        32. Value for Customs
                      </th>
                      <th class="notice-table-label">
                        33. FOB Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="2">
                        {{ item.goods1HsDescription }}
                      </td>
                      <td class="notice-table-label w-auto">
                        Declared
                      </td>
                      <td>{{ item.declaredHsCd }}</td>
                      <td>{{ item.declaredOriginCountryCd }}</td>
                      <td>{{ item.declaredPreferenceCd }}</td>
                      <td>{{ item.declaredEpcCd }}</td>
                      <td>{{ item.declaredApcCd }}</td>
                      <td>
                        {{ item.declaredCustomsVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}
                      </td>
                      <td>
                        {{ item.declaredInvoiceAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        {{ item.goods2HsDescription }}
                      </td>
                      <td class="notice-table-label w-auto">
                        Assessed
                      </td>
                      <td>{{ item.assessedHsCd }}</td>
                      <td>{{ item.assessedOriginCountryCd }}</td>
                      <td>{{ item.assessedPreferenceCd }}</td>
                      <td>{{ item.assessedEpcCd }}</td>
                      <td>{{ item.assessedApcCd }}</td>
                      <td>
                        {{ item.assessedCustomsVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}
                      </td>
                      <td>
                        {{ item.assessedInvoiceAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  class="legend"
                  v-for="(itemModal , index_itemModal) in item.itemModels"
                >
                  <h6 class="legend-title">
                    Item {{ index_itemModal + 1 }} Modal
                  </h6>
                  <table style="width:100%">
                    <thead>
                      <tr>
                        <th class="notice-table-label w-auto">
                          No
                        </th>
                        <th class="notice-table-label">
                          34. Model, Specification
                        </th>
                        <th class="notice-table-label">
                          Component
                        </th>
                        <th
                          class="notice-table-label"
                          colspan="2"
                        >
                          Quantity
                        </th>
                        <th class="notice-table-label">
                          Unit Price
                        </th>
                        <th class="notice-table-label">
                          Item Invoice Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">
                          {{ itemModal.modelNo }}
                        </td>
                        <td rowspan="2">
                          {{ itemModal.modelSpecification }}
                        </td>
                        <td rowspan="2">
                          {{ itemModal.component }}
                        </td>
                        <td class="legend-title-two">
                          Declared
                        </td>
                        <td>{{ itemModal.declaredQuantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }} ( {{ itemModal.declaredQuantityUnitCd }} )</td>
                        <td>{{ itemModal.declaredUnitPrice }}</td>
                        <td>{{ itemModal.declaredAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}</td>
                      </tr>
                      <tr>
                        <td class="legend-title-two">
                          Assessed
                        </td>
                        <td>{{ itemModal.assessedQuantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }} ( {{ itemModal.assessedQuantityUnitCd }} )</td>
                        <td>{{ itemModal.assessedUnitPrice }}</td>
                        <td>{{ itemModal.assessedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="legend">
                  <h6 class="legend-title">
                    24. Calculation of Duties/Taxes
                  </h6>
                  <table style="width:100%">
                    <thead>
                      <tr>
                        <th class="notice-table-label">
                          Tax Type
                        </th>
                        <th class="notice-table-label">
                          Duty/Tax Base
                        </th>
                        <th class="notice-table-label">
                          Rate
                        </th>
                        <th class="notice-table-label">
                          Amount
                        </th>
                        <th class="notice-table-label">
                          MOP
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="item.taxes.length > 0">
                      <tr v-for="(itemTax , index_itemTax) in item.taxes">
                        <td>{{ itemTax.taxTypeCd }} - {{ itemTax.taxTypeName }}</td>
                        <td>{{ itemTax.assessedTaxBaseAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}</td>
                        <td>{{ itemTax.assessedTaxRate }}</td>
                        <td>{{ itemTax.assessedTaxAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}</td>
                        <td>{{ itemTax.assessedMop }}</td>
                      </tr>
                      <tr>
                        <td
                          class="notice-table-label"
                          colspan="2"
                        >
                          <strong>Total taxes for item {{ item.itemNo }}</strong>
                        </td>
                        <td
                          class="notice-table-content text-right"
                          colspan="3"
                        >
                          <strong>{{ item.assessedTaxAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}</strong>
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-if="item.taxes.length <= 0">
                      <tr>
                        <td colspan="5">
                          <p class="py-2 mb-0">
                            <strong>Duties/Taxes Data Not Available</strong>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div
            class="legend"
            v-if="typeof assessmentNotice.general !== 'undefined'"
          >
            <h6 class="legend-title">
              GRAND TOTAL PAYABLE FOR THE ENTRY
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="notice-table-label">
                    Duties and Taxes
                  </th>
                  <td class="text-right-custom">
                    {{ assessmentNotice.general.totalDutyTaxAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}
                  </td>
                </tr>
                <tr>
                  <th class="notice-table-label">
                    Other Charge
                  </th>
                  <td class="text-right">
                    {{ assessmentNotice.general.otherChargeAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}
                  </td>
                </tr>
                <tr>
                  <th class="notice-table-label">
                    Guarantee / Deferment
                  </th>
                  <td class="text-right">
                    {{ assessmentNotice.general.guaranteeAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}
                  </td>
                </tr>
                <tr>
                  <th class="notice-table-label">
                    <strong>TOTAL PAYABLE</strong>
                  </th>
                  <td class="text-right">
                    <strong>{{ assessmentNotice.general.totalPayableTaxAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="legend">
            <h6 class="legend-title">
              Payment Note
            </h6>
            <table style="width:100%">
              <thead>
                <th class="notice-table-label">
                  No
                </th>
                <th class="notice-table-label">
                  Notice Bill No
                </th>
                <th class="notice-table-label">
                  Notice Date
                </th>
                <th class="notice-table-label">
                  Tax Amount
                </th>
                <th class="notice-table-label">
                  Notice Amount
                </th>
                <th class="notice-table-label">
                  Paid Amount
                </th>
              </thead>
              <tbody v-if="typeof assessmentNotice.bills !== 'undefined' && assessmentNotice.bills.length > 0">
                <tr v-for="(paymentNote , index_paymentNote) in assessmentNotice.bills">
                  <td>{{ paymentNote.assessmentBillSeq }}</td>
                  <td>
                    {{ paymentNote.noticeBillNo }}
                  </td>
                  <td>{{ paymentNote.billDt }}</td>
                  <td>{{ paymentNote.totalTaxAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}</td>
                  <td>{{ paymentNote.billTaxAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}</td>
                  <td>{{ paymentNote.paidAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}</td>
                </tr>
              </tbody>
              <tbody v-if="typeof assessmentNotice.bills !== 'undefined' && assessmentNotice.bills.length <= 0">
                <tr>
                  <td colspan="6">
                    <p class="py-2 mb-0">
                      <strong>Payment Note Data Not Available</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="legend" v-if="typeof assessmentNotice.bills !== 'undefined'">
            <h6 class="legend-title">
              Current
            </h6>
            <table style="width:100%">
              <tbody v-if="assessmentNotice.bills.length > 0">
                <tr v-for="(paymentNote , index_paymentNote) in assessmentNotice.bills">
                  <th class="notice-table-label">
                    Notice Bill Number
                  </th>
                  <td>
                    {{ paymentNote.noticeBillNo }}
                  </td>
                </tr>
              </tbody>
              <tbody v-if="assessmentNotice.bills.length <= 0">
                <tr>
                  <td class="text-center">
                    <p class="py-2 mb-0">
                      <strong>Notice Bill Number Not Available</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br>
            <table style="width:100%" v-if="assessmentNotice.resultBill !== null">
              <tbody v-if="assessmentNotice.resultBill !== {}">
                <tr>
                  <th class="notice-table-label">
                    Total Assessed Amount for the Declaration
                  </th>
                  <td class="text-right-custom">
                    {{ assessmentNotice.resultBill.totalTaxAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}
                  </td>
                </tr>
                <tr>
                  <th class="notice-table-label">
                    Amount Currently to be Paid
                  </th>
                  <td class="text-right-custom">
                    {{ assessmentNotice.resultBill.totalToBePaidAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }}
                  </td>
                </tr>
              </tbody>

              <tbody v-if="assessmentNotice.resultBill === {}">
                <tr>
                  <td>
                    <p class="py-2 mb-0 text-center">
                      <strong>Bill Assessment Data Not
                        Available</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="legend">
            <h6 class="legend-title">
              Notice List
            </h6>
            <table style="width:100%">
              <thead>
                <th class="notice-table-label">
                  Notice Bill No
                </th>
                <th class="notice-table-label">
                  Notice Date
                </th>
                <th class="notice-table-label">
                  GePG Control No
                </th>
                <th class="notice-table-label">
                  Notice Bill Type
                </th>
                <th class="notice-table-label">
                  Tax Amount
                </th>
                <th class="notice-table-label">
                  Receipt No.
                </th>
                <th class="notice-table-label">
                  Receipt Date
                </th>
                <th class="notice-table-label">
                  Payment Due Date
                </th>
                <th class="notice-table-label">
                  Disposal
                </th>
              </thead>
              <tbody v-if="typeof assessmentNotice.payments !== 'undefined' && assessmentNotice.payments.length > 0">
                <tr v-for="(payment , index_payment) in assessmentNotice.payments">
                  <td>
                    {{ payment.noticeBillNo }}
                  </td>
                  <td>{{ payment.billDt }}</td>
                  <td>{{ payment.gepgControlNo }}</td>
                  <td>{{ payment.billTypeCd }}</td>
                  <td>{{ payment.totalBillTaxAmt }}</td>
                  <td>{{ payment.receiptNo }}</td>
                  <td>{{ payment.receiptDt }}</td>
                  <td>{{ payment.paymentDeadlineDay }}</td>
                  <td>{{ payment.disposalYn === 'Y' ? 'YES' : 'NO' }}</td>
                </tr>
              </tbody>

              <tbody v-if=" typeof assessmentNotice.payments !== 'undefined' && assessmentNotice.payments.length <= 0">
                <tr>
                  <td colspan="9">
                    <p class="py-2 mb-0">
                      <strong>Notice List Data Not Available</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    </card>
  </div>
</template>

<script>

import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

  name: 'AssessmentView',

  data () {
    return {

      color: 'green',
      size: '12px',
      loading: true,

      assessmentNotice: {},
      singleRequest: {},
      assessmentNoticeLoader: false,
      requestId: '',
      noticeId: '',
      noticeType: ''

    }
  },

  created () {

    this.noticeType = this.$route.params.noticeType;
    this.$store.dispatch('pagenames/currentPageName',
      this.noticeType === 'assessment-notice' ? 'Assessment Notice' : 'Release Notice');

    if (this.$route.params.requestId && this.$route.params.noticeId) {
      this.requestId = this.$route.params.requestId;
      this.noticeId = this.$route.params.noticeId;

      this.loadAssessmentNoticePerRequest(this.noticeId, this.requestId)
    }
  },

  components: {

    PulseLoader

  },

  methods: {

    loadAssessmentNoticePerRequest (id, data) {

      this.assessmentNoticeLoader = true;
      let noticeType;

      API.getSingleRequest(data).then(
        response => {
          if (response.data.status) {
            this.singleRequest = response.data.data;

            if (this.noticeType === 'assessment-notice') {

              noticeType = API.getAssessmentNotice

            }

            if (this.noticeType === 'release-notice') {

              noticeType = API.getReleaseNotice
              
            }

            noticeType(id, data).then(
              response => {
                if (response.data.status) {
                  this.assessmentNotice = response.data.data;

                  this.assessmentNoticeLoader = false
                } else {

                }
              }
            ).catch(() => {
              this.assessmentNoticeLoader = false
            })
          } else {

          }
        }
      ).catch(() => {
        this.assessmentNoticeLoader = false
      })
    },

    goBack () {

      if (window.$cookies.isKey('browser-local-cashed-url-back-to-notice-list-inside')) {

        this.$router.push(window.$cookies.get('browser-local-cashed-url-back-to-notice-list-inside'));
        window.$cookies.remove('browser-local-cashed-url-back-to-notice-list-inside')

      } else {



      }
    }

  }

}
</script>

<style scoped>

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 2rem;
        border-radius: 3px;
        margin-top: 2rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .legend-colorless {
        position: relative;
        border: 1px solid #2f4f4f;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 2rem;
        border-radius: 3px;
        margin-top: 2rem;
    }

    .legend-title-colorless {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .legend-title-two {
        background-color: #99badd;
        font-weight: normal;
    }

    .legend-btn {
        position: absolute;
        top: -0.9rem;
        padding: 0.2rem 1rem;
        right: 25px;
    }

    .notice-table-content {
        width: 250px;
    }

    .notice-table-label {
        width: 250px;
        background-color: #99badd;
        font-weight: normal;
    }

    table, th, td {
        border: 1px solid #DCDCDC;
        border-collapse: collapse;
    }

    th, td {
        padding: 8px;
        text-align: left;
    }

    th.declaration-table-th {
        width: 40%;
        background-color: #99badd;
    }

    .text-right-custom {
        text-align: right !important;
    }

    .custom-btn-all {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }

</style>
