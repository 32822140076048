<template>
  <card>
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                BL/AIRWAY BILL/RCN
              </th>
              <th scope="col">
                FILE NUMBER
              </th>
              <th scope="col">
                CUSTOMER
              </th>
              <th scope="col">
                REQUESTED DATE
              </th>
              <th scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(list, index) in requests"
              :key="list.id"
            >
              <th scope="row">
                {{ index + 1 }}
              </th>
              <td>
                {{ list.blNumber }}
              </td>
              <td>
                {{ list.fileNo }}
              </td>
              <td v-if="list.customer !== null">
                {{ list.customer.name }}
              </td>
              <td v-else>
                <I>NIL</I>
              </td>
              <td>
                {{ dateTimeToHuman(list.requestDate) }}
              </td>
              <td>
                <div
                  class=" all-fa-icons edit-fa-icon"
                  title="Create Charge"
                >
                  <font-awesome-icon
                    icon="money-check"
                    @click="openModal(list)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          Charges
        </div>
      </div>
      <div slot="body">
        <card>
          <div
            class="col-md-12 alert-dismissible fade show"
            role="alert"
            :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
            style="border-radius:5px;"
          >
            <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
            <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-if="SuccessAlert"
              style="line-height:2px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-if="ErrorAlert"
              style="line-height:2px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <div>
              <div class="col-12">
                <strong>Customer Details</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 legend">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{{ selectectedRequest.customer ? selectectedRequest.customer.name:"" }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{{ selectectedRequest.customer ? selectectedRequest.customer.email :"" }}</td>
                    </tr>
                    <tr>
                      <td>Mobile</td>
                      <td>{{ selectectedRequest.customer ? selectectedRequest.customer.mobile:"" }}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{{ selectectedRequest.customer ? selectectedRequest.customer.address :"" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6 legend">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Tin Number</td>
                      <td>{{ selectectedRequest.customer ? selectectedRequest.customer.tin :"" }}</td>
                    </tr>
                    <tr>
                      <td>Vat Registration Number(VRN)</td>
                      <td>{{ selectectedRequest.customer ? selectectedRequest.customer.vrn :"" }}</td>
                    </tr>
                    <tr>
                      <td>Mobile</td>
                      <td>{{ selectectedRequest.customer ? selectectedRequest.customer.mobile :"" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div class="col-12">
                <strong>Application Details</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 legend">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Bl No</td>
                      <td>{{ selectectedRequest.blNumber }}</td>
                    </tr>
                    <tr>
                      <td>File Number</td>
                      <td>{{ selectectedRequest.fileNo }}</td>
                    </tr>
                    <tr>
                      <td>Transported By</td>
                      <td>{{ selectectedRequest.transportMethod ? selectectedRequest.transportMethod.transportMethodName :"" }}</td>
                    </tr>
                    <tr>
                      <td>Transport Description</td>
                      <td>{{ selectectedRequest.transportMethod ? selectectedRequest.transportMethod.transportMethodDescription:"" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6 legend">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Approved By</td>
                      <td>{{ selectectedRequest.approvedBy }}</td>
                    </tr>
                    <tr>
                      <td>Approved Date</td>
                      <td>{{ selectectedRequest.approveDate }}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>{{ selectectedRequest.description }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="currency">Currency</label>
                  <select
                    class="form-control"
                    id="currency"
                    v-model="currency"
                  >
                    <option :value="undefined" />
                    <option>TZS</option>
                    <option>USD</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="fee">Select Fee</label>
                  <select
                    class="form-control"
                    id="fee"
                    @change="onChange($event)"
                    v-model="fee"
                  >
                    <option :value="undefined" />
                    <option
                      v-for="(list,index) in fees"
                      :key="index"
                      :value="list.tariffFeeId"
                      v-if="list.isActive"
                    >
                      {{ list.tariffFeeName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12 text-center">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="saveCharge"
                />
              </div>
            </div>
            <div
              class="row"
              v-if="duplicate"
            >
              <div class="col-12 text-center">
                <small class="text-danger">Error Duplicate Charge</small>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="d-flex justify-content-between alert"
                  :class="[list.billed ? 'alert-dark':'alert-primary']"
                  role="alert"
                  v-for="list in charges"
                  :key="list.tariffFeeId"
                >
                  {{ list.tariffFeeName }}
                  <span
                    class="all-fa-icons btn-space"
                    title="Cancel Charge"
                    v-if="!list.saved"
                    @click="cancelFee(list.tariffFeeId)"
                  >
                    <font-awesome-icon icon="times" />

                  </span>
                </div>
              </div>
            </div>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-outline-info float-right"
                  @click="createCharges()"
                  :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                >
                  Save
                </button>
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                  :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                >
                  Back
                </button>
                <div class="text-center">
                  <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                    v-if="myloader"
                  />
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </card>
</template>
<script>
import API from '../../../api/index'
import API_REVENUE from '../../../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'

export default {
  name: 'Charges',

  components: {
    Modal,
    PulseLoader
  },
  created () {
    this.getApplicationRequests()
    this.getTariffFees()
  },

  data () {
    return {

      aplicationRequests: [],
      pageNo: 0,
      pageSize: 15,
      pageCount: '',
      showModal: false,
      selectectedRequest: {},
      selectedfee: '',
      fees: [],
      fee: undefined,
      disableButton: false,
      currency: undefined,
      saveCharge: false,

      numberofCharges: 5,
      charges: [],

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',

      color: 'green',
      size: '15px',
      loading: true,

      requestedCharges: [],

      duplicateCharge: false,
      duplicate: false,

      requests: [],
      myLoader: false
    }
  },

  methods: {

    createCharges () {
      this.saveCharge = true
      const that = this
      const storeCharges = this.charges.map(async (fee, index) => {
        if (fee.saved === undefined) {
          const response = await API.createCharges({
            chargeCurrency: that.currency,
            requestId: that.selectectedRequest.requestId,
            tariffFeeId: fee.tariffFeeId
          })

          fee.saved = true
        }
      })

      Promise.all(storeCharges).then((response) => {
        this.saveCharge = false
      })
    },

    getChargesForRequest () {
      this.saveCharge = true

      API.getChargesByRequestId(this.selectectedRequest.requestId).then((response) => {
        this.requestedCharges = response.data
        const newCharges = []
        const that = this
        this.fees.find((x) => {
          response.data.find((y) => {
            if (y.tariffFeeId === x.tariffFeeId) {
              newCharges.push({ saved: true, billed: y.billed, ...x })
            }
          })
        })

        this.charges = [...this.charges, ...newCharges]

        this.saveCharge = false
      }).catch((e) => {

      })
    },

    getTariffFees () {
      API_REVENUE.getActiveTariffFee().then((response) => {
        this.fees = response.data.data
      }).catch((e) => {
        console.log(e)
      })
    },

    cancelFee (feeId) {
      const data = this.charges.findIndex((x) => {
        if (!x.saved) {
          return x.tariffFeeId === JSON.parse(feeId)
        } else {
          return false
        }
      })
      this.charges.splice(data, 1)
    },
    getApplicationRequests () {
      API.getAssessedRequests(this.pageNo, this.pageSize).then(
        response => {
          if (response.data.status) {
            this.pageCount = response.data.message
            this.requests = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ', Requests Not loaded, please contact system administrator'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    onChange (e) {
      this.duplicate = false
      const duplicate = this.charges.findIndex((x) => {
        return x.tariffFeeId === JSON.parse(e.target.value)
      })

      if (duplicate === -1) {
        const data = this.fees.find((x) => {
          if (x.tariffFeeId === JSON.parse(e.target.value)) {
            return x
          }
        })
        this.charges.push(data)
        this.fee = undefined
      } else {
        this.duplicate = true
        this.fee = undefined
      }
    },

    openModal (selectectedRequest) {
      this.selectectedRequest = selectectedRequest
      this.getChargesForRequest()
      this.showModal = true
    },

    closeModal () {
      this.showModal = false
      this.selectectedRequest = {}
      this.charges = []
      this.currency = undefined
      this.duplicate = false
    },

    dateTimeToHuman (stringDate) {
      if (stringDate !== null) {
        const splittedDate = stringDate.split('T')

        const vuejsDate = splittedDate[0]

        const vuejsTime = splittedDate[1].split('.')

        return vuejsDate + ' ' + vuejsTime[0]
      }
    }
  }

}
</script>

<style scoped>

</style>
