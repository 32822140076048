<template>
  <div>
    <card>
      <div class="row">
        <div class="col-12">
          <crud-notification
            :SuccessMessage="SuccessMessage"
            :ErrorMessage="ErrorMessage"
            :SuccessAlert="SuccessAlert"
            :ErrorAlert="ErrorAlert"
            @fadeMe="fadeMe"
            @enter="enter">

          </crud-notification>
        </div>
        <div class="col-md-12">
          <br/>
        </div>
      </div>
      <data-table
        :tableHeaders="tableHeaders"
        :tableData="requests"
        :tableDataKeys="tableDataKeys"
        :myLoader="myLoader"
        :myFirstLoader="myFirstLoader"
        :noDataFound="noDataFound"
        :loadingTitle="'Please wait while system is loading agency charge details'"
        :hasManageButton="false"
        :hasSearchForm="false"
        :isPageable="false">

      </data-table>
      <div class="col-md-12">
        <hr>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9 left loader-div-gif"></div>
          <div class="col-md-3">
            <button type="button" class="btn btn-fill btn-outline-info float-right" @click="goBack">
              Back
            </button>
            <button type="button" class="btn btn-success mr-3 float-right" @click="generateBill"
                    v-if="requests.length > 0 && !requests[0].billed">
              Generate Bill
            </button>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">Please wait while system is generating requested bill</div>
          <div class="col-md-12">
            <br/>
          </div>
          <div class="col-md-12">
            <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import PulseLoader from "vue-spinner/src/PulseLoader.vue";
  import GlobalModal from "../../../../components/globalLoader";
  import DataTable from "../../../../components/DataTable/updatedTable";
  import CrudNotification from "../../../../components/DataTable/crudNotification";
  import API_REVENUE from "../../../../api/rev";

  export default {

    name: "generateBill",

    components: {

      PulseLoader,
      GlobalModal,
      DataTable,
      CrudNotification,

    },

    created() {

      this.$store.dispatch("pagenames/currentPageName", "Agency Charge Billing");
      this.shippingOrderId = this.$route.params.chargeId;
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadPageableRequestOnCreated();
      this.$store.dispatch("tallybilling/erroralerting", false);
      this.$store.dispatch("tallybilling/successalerting", false);

    },

    data() {

      return {

        color: "green",
        size: "12px",
        loading: true,

        noDataFound: false,
        myLoader: false,
        myFirstLoader: false,
        requests: [],
        shippingOrderId: "",
        finalUserPermissions: [],

        tableHeaders: ['Shipper Name', 'vessel Name', 'container Size', 'container Count', 'car Count', 'Total Weight', 'tariff Item', 'tariff Fee'],
        tableDataKeys: ['shipperName', 'vesselName', 'containerSize', 'containerCount', 'carCount', 'cargoTotalWeight', 'tariffDetails', 'tariffFeeId'],

        tariffItemsDetails: '',

        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: "",
        ErrorMessage: ""

      }

    },

    computed: {

      ...mapGetters("notifications", [

        "getNewAppPage"

      ]),

      ...mapGetters({

        getRequestList: "agencyChargeList/getRequestList",
        getSuccessMessage: "agencyChargeList/getSuccessMessage",
        getErrorAlert: "agencyChargeList/getErrorAlert",
        getErrorMessage: "agencyChargeList/getErrorMessage",
        getSuccessAlert: "agencyChargeList/getSuccessAlert",
        getMyLoader: "agencyChargeList/getMyLoader",
        getMyFirstLoader: "agencyChargeList/getMyFirstLoader",
        getNoDataFound: "agencyChargeList/getNoDataFound",
      }),

    },

    methods: {

      ...mapActions("agencyChargeList", [

        "getAllUnbilledShippingOrderChargeDetails",
        "generateBillForShippingOrderChargesAwaitingBilling"

      ]),

      loadParameters() {

        return new Promise((resolve) => {

          this.$store.dispatch("agencyChargeList/shippingOrderIding", this.shippingOrderId);

          resolve();

        })

      },

      setAllGetters(flag) {
        this.requests = this.getRequestList;
        // this.requests = this.formatDetails(this.getRequestList);
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;

      },
      formatDetails(data) {

        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].tariffDetails = 'test';
            API_REVENUE.getTariffItemsById(data[i].tariffItemId).then((response) => {
              data[i].tariffDetails = response.data.data.tariffItemName;
            
            }).catch((e) => {
              this.ErrorMessage = 'Failed To load Tariff'
              this.SuccessAlert = false
              this.ErrorAlert = true
              this.SuccessMessage = ''
            })
          }
        } else {

        }
      },

      getTariffItemwithId(data) {

        API_REVENUE.getTariffItemsById(data).then((response) => {
          this.tariffItemsDetails = response.data.data
        }).catch((e) => {
          this.ErrorMessage = 'Failed To load Tariff'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
        })
      },

      loadPageableRequestOnCreated() {

        this.loadParameters().then(() => {

          this.getAllUnbilledShippingOrderChargeDetails({}).then(() => {

            this.setAllGetters('onCreated');

          }).catch(() => {


          });

          this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {

        });

      },

      setGettersForNotification() {

        return new Promise((resolve) => {

          this.myLoader = this.getMyLoader;
          this.SuccessMessage = this.getSuccessMessage;
          this.ErrorMessage = this.getErrorMessage;
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessAlert = this.getSuccessAlert;

          resolve();

        });

      },

      generateBill() {

        this.$dialog.confirm("You are about to create bill for the currently selected tallying charge. <br><br>" +
          " Are you sure!.", {
          loader: true,
          html: true

        }).then((dialog) => {

          this.generateBillForShippingOrderChargesAwaitingBilling({}).then(() => {

            this.setGettersForNotification();

          }).catch(() => {


          });

          this.myLoader = this.getMyLoader;

          dialog.close();

        });


      },

      goBack() {

        if (window.$cookies.isKey('browser-local-cashed-url-back')) {

          this.$router.push(window.$cookies.get('browser-local-cashed-url-back'));

          window.$cookies.remove('browser-local-cashed-url-back');

        }

      },

      fadeMe: function () {

        if (this.SuccessAlert) {

          this.SuccessAlert = !this.SuccessAlert;
          this.$store.dispatch("agencyChargeList/successalerting", this.SuccessAlert);

        } else {

          this.ErrorAlert = !this.ErrorAlert;
          this.$store.dispatch("agencyChargeList/erroralerting", this.ErrorAlert);

        }

      },

      enter: function (el, done) {

        let that = this;

        setTimeout(function () {

          that.SuccessAlert = false;
          that.ErrorAlert = false;
          this.$store.dispatch("agencyChargeList/erroralerting", that.ErrorAlert);
          this.$store.dispatch("agencyChargeList/successalerting", that.SuccessAlert);

        }, 3000); // hide the message after 3 seconds
      },

    }

  }
</script>

<style scoped>

</style>
