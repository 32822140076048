<template>
  <div>
    Dashboard is coming soon
  </div>
</template>
<script>

export default {

  components: {

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Dashboard')
  },

  data () {
    return {

    }
  },

  methods: {

  }
}
</script>
<style scoped>

</style>
