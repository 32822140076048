<template>
  <div :key="status">
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="filteredRequests"
        :table-data-keys="tableDataKeys"
        :pageSizeProp="pageSize"
        :pageNo="pageNo"
        :pageNumber="pageNumber"
        :allCustomers="allCustomers"
        :stations="stations"
        :activeTariffItems="activeTariffItems"
        :getRegimes="getFilteredRegimes"
        :is-hovered="isHovered"
        :my-loader="myLoader"
        :totalPages="totalPages"
        :page-count="pageCount"
        :totalElements="totalElements"
        :my-first-loader="myFirstLoader"
        :no-data-found="noDataFound"
        :applicationStatusesProp="applicationStatuses"
        :permissions="[ { key: 'searchPermission', value: 'CLEARINGFORWARDING_LIST_SEARCH_REQUESTS_POST' } ]"
        :loading-title="'Please wait while system is loading ' + underscoreToSpaceString(status.toLowerCase()) + ' applications'"
        :has-filter="true"
        :hasManageButton="true"
        :coloredStatus="true"
        :applicationCurrentStatus="status"
        :whichModuleAndType="whichModuleAndType"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @onChange="onChange"
        @goToApplication="goToApplication"
        @filterWithMultipleParameters="searchGeneralChargeParams"
        @resetParams="resetParams"
        @goToPage="goToPage">
        <div slot = "pagination">
          <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
          </paginate>
        </div>
      </data-table>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading Applications, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import GlobalModal from '../../../components/globalLoader'
  import Modal from '../../../components/Inputs/Modal'
  import additionalFields from "./additionalFields";
  import { CFA_REQUEST_STATUSES } from '../../../mixins/constants'

  export default {

    name: 'CfaRequests',

    components: {

      PulseLoader,
      GlobalModal,
      DataTable: () => import('../../../components/DataTable/dataTable'),
      Modal,
      additionalFields

    },

    created () {

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

      this.loadRequest()
      this.loadAllCustomers()
      this.loadAllRegimes()
      this.loadAllActiveTariffItems()
      this.loadAllStations()

    },

    computed: {

      ...mapGetters('notifications', [

        'getNewAppPage'

      ]),

      ...mapGetters('global' , [

        'getActiveTariffItems',
        'getAllStations',
        'getAllCustomers',
        'getAllRegimes'

      ]),

      ...mapGetters('clearingrequests', [

        'getRequestList',
        'getPageCount',
        'getMyFirstLoader',
        'getNoDataFound',
        'getReloadAllRequest',
        'getMyLoader',
        'getSearchParameter',
        'getHasSearchedVariable',
        'getPageNo',
        'getPageSize',
        'getTotalElement',
        'getTotalPage',
        'getPageNumber',
        'getIsFirst',
        'getIsLast',
        'getSearchParams'

      ]),

      getIsPage: {

        get: function () {

          return this.isPage

        },

        set: function (newValue) {

          this.isPage = newValue

        }

      }

    },

    data () {

      return {

        pageNo: 0,
        pageSize: 15,
        pageCount: 1,

        color: 'green',
        size: '12px',
        loading: true,

        noDataFound: false,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',
        myLoader: false,
        myFirstLoader: false,
        whichModuleAndType: 'cfaApplication',
        applicationStatuses: CFA_REQUEST_STATUSES,
        status: '',
        requestType: '',
        requests: [],
        filteredRequests:[],
        isHovered: [],
        activeTariffItems: [],
        stations: [],
        isPage: 0,
        pageNoPart: 0,
        loadedParams: {},
        requestId: '',
        ifThereIsParam: '',

        finalUserPermissions: [],

        tableHeaders: [],
        tableDataKeys: [],

        showMarkDuplicate: false,
        getRegimes:[],
        getFilteredRegimes: [],
        allCustomers: [],

      }

    },

    watch: {

      '$route.path': function(val, oldVal){

        this.loadRequest()
        this.loadAllRegimes()

        if(this.loadedParams.applicationStatuses !== null) {

            if (!this.loadedParams.applicationStatuses.includes(this.applicationStatusAndUrlParamMapper(this.$route.params.status))) {

              localStorage.removeItem("search-params")
              localStorage.removeItem('search-params-clist')
              localStorage.removeItem('search-params-rlist')
              localStorage.removeItem('search-params-slist')
              localStorage.removeItem('search-params-alist')
              localStorage.removeItem('search-params-tlist')
              // this.resetParams()

            } else {

              localStorage.removeItem("search-params")
              localStorage.removeItem('search-params-clist')
              localStorage.removeItem('search-params-rlist')
              localStorage.removeItem('search-params-slist')
              localStorage.removeItem('search-params-alist')
              localStorage.removeItem('search-params-tlist')
              // this.resetParams()

            }

        } else {

              localStorage.removeItem("search-params")
              localStorage.removeItem('search-params-clist')
              localStorage.removeItem('search-params-rlist')
              localStorage.removeItem('search-params-slist')
              localStorage.removeItem('search-params-alist')
              localStorage.removeItem('search-params-tlist')
              // this.resetParams()

        }

      }

    },

    methods: {

      ...mapActions('clearingrequests', [

          'loadClearingAndForwardingApplicationsOnCreated',
          'searchWithGeneralChargeParams',
          'loadClearingAndForwardingApplications'

      ]),

      ...mapActions("global", [

          'loadActiveTariffItems',
          'loadStations',
          'loadCustomers',
          'loadRegimes'

      ]),

      loadAllRegimes() {

          let requestType = ''
          this.getFilteredRegimes = []
          this.requestType === 'import' ? requestType = 'IMP' : requestType = 'EXP'

          this.loadRegimes({}).then(() => {

              this.getRegimes = this.getAllRegimes;

              for (let i = 0; i < this.getRegimes.length; i++) {

                if(this.status !== 'all') {

                  if(this.getRegimes[i].operationType === requestType) {

                    this.getFilteredRegimes.push(this.getRegimes[i])

                  }

                } else {

                  this.getFilteredRegimes.push(this.getRegimes[i])

                }

              }

          }).catch(() => {});

      },

      loadAllCustomers() {

          this.loadCustomers({}).then(() => {

            this.allCustomers = this.getAllCustomers;

          }).catch(() => {})

      },

      loadAllActiveTariffItems() {

          this.loadActiveTariffItems({}).then(() => {

            this.activeTariffItems = this.getActiveTariffItems;

          }).catch(() => {})

      },

      loadAllStations() {

          this.loadStations({}).then(() => {

            this.stations = this.getAllStations;

          }).catch(() => {})

      },

      resetParams () {

        this.loadRequest()
        this.$store.dispatch("clearingrequests/hasSearchngParams", false)
        this.pageNoPart = 0
        this.isPage = this.pageNoPart + 1
        this.filteredRequests = []

      },

      autoNamingDate (status) {

          switch (status) {

            case 'SUBMITTED':

              return 'Requested'
              
              break;

            case 'APPROVED':

              return 'Accepted'
              
              break;

            case 'REJECTED_MAJOR':

              return 'Major Issue'
              
              break;

            case 'REJECTED_MINOR':

              return 'Minor Issue'
              
              break;

            case 'RETURNED':

              return 'Returned'
              
              break;

            case 'ASSESSED':

              return 'Tariff'
              
              break;

            case 'REJECTED_ASSESSMENT':

              return 'Rejected Tariff'
              
              break;

            case 'CHARGED':

              return 'Charged'
              
              break;

            case 'REJECTED_CHARGE':

              return 'Rejected Charge'
              
              break;

            case 'BILLED':

              return 'Billed'
              
              break;

            case 'BILL_APPROVED':

              return 'Bill Approved'
              
              break;

            case 'PAID':

              return 'Payment'
              
              break;

            case 'CLOSED':

              return 'Closed'
              
              break;

            case 'DISMISSED':

              return 'Dismissed'
              
              break;
          
            default: //ALL

              return 'Status'

              break;
          }

      },

      loadRequest() {

        this.status = this.hyphenToSpaceString(this.$route.params.status)
        this.requestType = this.$route.params.requestType;
        let pageTittle = this.underscoreToSpaceString(this.status.charAt(0).toUpperCase() + this.status.slice(1).toLowerCase()) + " " + this.requestType + " " + "applications"
        let trimmedPageTitle = ''
        if(pageTittle.includes('Request')) {

            trimmedPageTitle = pageTittle.replace('Request', '').trim()
            if(pageTittle.includes('assessed')) {

              trimmedPageTitle = trimmedPageTitle.replace('assessed', 'tariffed').trim()

            }
            if(pageTittle.includes('assessment rejected')) {

              trimmedPageTitle = trimmedPageTitle.replace('assessment rejected', 'rejected tariff').trim()

            }
            if(pageTittle.includes('submitted')) {

              trimmedPageTitle = trimmedPageTitle.replace('submitted', 'new').trim()

            }
            if(pageTittle.includes('is dismissed')) {

              trimmedPageTitle = trimmedPageTitle.replace('is dismissed', 'dismissed').trim()

            }

        } else {

          trimmedPageTitle = pageTittle

        }
        
        this.$store.dispatch('pagenames/currentPageName', trimmedPageTitle.charAt(0).toUpperCase() + trimmedPageTitle.slice(1).toLowerCase());

        if(this.status === 'all') {

          if(this.requestType === "import"){

            this.tableHeaders = ['BL/Airway Bill/RCN', 'Customer Name', this.autoNamingDate(this.applicationStatusAndUrlParamMapper(this.$route.params.status)) + ' Date', 'Status','Expected Time Arrival']

          } else {

            this.tableHeaders = ['BL/Airway Bill/RCN', 'Customer Name', this.autoNamingDate(this.applicationStatusAndUrlParamMapper(this.$route.params.status)) + ' Date', 'Status','Expected Time Departure']
          }
          this.tableDataKeys = ['blNumber', 'customer', 'requestDate', 'status','cargoExpectedTime']

        } else {

          if(this.requestType === "import"){

            this.tableHeaders = ['BL/Airway Bill/RCN', 'Customer Name', this.autoNamingDate(this.applicationStatusAndUrlParamMapper(this.$route.params.status)) + ' Date','Expected Time Arrival']

          } else {

            this.tableHeaders = ['BL/Airway Bill/RCN', 'Customer Name', this.autoNamingDate(this.applicationStatusAndUrlParamMapper(this.$route.params.status)) + ' Date','Expected Time Departure']
          }

          this.tableDataKeys = ['blNumber', 'customer', 'requestDate','cargoExpectedTime']

        }

        if (localStorage.getItem("search-params") !== null) {

            this.loadedParams = "";
            this.loadedParams = JSON.parse(localStorage.getItem("search-params"));

            if(this.loadedParams.applicationStatuses !== null) {

                if(this.loadedParams.applicationStatuses.length === 1 && !this.loadedParams.applicationStatuses.includes(this.applicationStatusAndUrlParamMapper(this.$route.params.status))) {

                  this.loadedParams = {

                        applicationStatuses: this.loadCommonValues('all'),
                        assigmentUserId: null,
                        blNumber: null,
                        customerIds: null,
                        dismissCase: null,
                        endDate: null,
                        fileNo: null,
                        regimeIds: null,
                        requestType: this.$route.params.requestType,
                        startDate: null,
                        stationIds: null,
                        tansadNumber: null,
                        tariffItem: null

                  },

                  localStorage.removeItem("search-params")
                  localStorage.removeItem('search-params-clist')
                  localStorage.removeItem('search-params-rlist')
                  localStorage.removeItem('search-params-slist')
                  localStorage.removeItem('search-params-alist')
                  localStorage.removeItem('search-params-tlist')

                  this.searchGeneralChargeParams(this.loadedParams, this.isPage)

                } else {

                  this.searchGeneralChargeParams(this.loadedParams, this.isPage)

                }

            } else {

                if(this.$route.params.status !== 'all') {

                  this.loadedParams = {

                        applicationStatuses: this.loadCommonValues('all'),
                        assigmentUserId: null,
                        blNumber: null,
                        customerIds: null,
                        dismissCase: null,
                        endDate: null,
                        fileNo: null,
                        regimeIds: null,
                        requestType: this.$route.params.requestType,
                        startDate: null,
                        stationIds: null,
                        tansadNumber: null,
                        tariffItem: null

                  },

                  localStorage.removeItem("search-params")
                  localStorage.removeItem('search-params-clist')
                  localStorage.removeItem('search-params-rlist')
                  localStorage.removeItem('search-params-slist')
                  localStorage.removeItem('search-params-alist')
                  localStorage.removeItem('search-params-tlist')

                  this.searchGeneralChargeParams(this.loadedParams, this.isPage)

                } else {

                  this.searchGeneralChargeParams(this.loadedParams, this.isPage)

                }

            }

            this.pageSize = this.loadedParams.pageSize
            this.pageNo = this.loadedParams.pageNo
            this.pageNoPart = this.pageNo
            this.isPage = this.pageNo + 1

        } else {

            this.loadedParams = {

                  applicationStatuses: this.loadCommonValues('all'),
                  assigmentUserId: null,
                  blNumber: null,
                  customerIds: null,
                  dismissCase: null,
                  endDate: null,
                  fileNo: null,
                  regimeIds: null,
                  requestType: this.$route.params.requestType,
                  startDate: null,
                  stationIds: null,
                  tansadNumber: null,
                  tariffItem: null

            },

            this.loadPageableRequestOnCreated(this.loadedParams , 0);
            this.isPage = this.pageNo + 1;
            this.$store.dispatch("clearingrequests/hasSearchngParams", false);
            this.pageNoPart = 0;

        }

            this.filteredRequests = []

      },

      loadCommonValues (status) {

        let applicationStatus = []

        if(this.$route.params.status === status) {

          applicationStatus = null

        } else {

            let myStatus = this.applicationStatusAndUrlParamMapper(this.$route.params.status)
            applicationStatus.push(myStatus)

        }

        return applicationStatus

      },

      loadSearchParameters(params, fromApplicationPageNo) {

        let newApplicationStatuses
        params.applicationStatuses === null ? newApplicationStatuses = this.loadCommonValues('all') : newApplicationStatuses = params.applicationStatuses
        const NewParams = { ...params, requestType: this.$route.params.requestType, applicationStatuses: newApplicationStatuses, pageNo: this.pageNo, pageSize: this.pageSize}
        let fromApplicationPageNoOrNot;
        typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0
        this.isPage = fromApplicationPageNo;
        this.loadedParams = NewParams
        return new Promise((resolve) => {

          this.$store.dispatch("clearingrequests/pageSizing", this.pageSize);
          this.$store.dispatch("clearingrequests/pageNumbering", this.pageNo);
          this.$store.dispatch("clearingrequests/paramsSearching", this.loadedParams);

          resolve();

        })

      },

      sortingData(data) {

        for(let x = 0 ; x < data.length ; x++) {

          if(this.status === 'all') {

            let status = ''

            // if(data[x].status.includes("Request")) {

            //   if((data[x].status.replace('Request', '')).trim().includes('is ')) {

            //       status = ((data[x].status.replace('Request', '')).trim().replace('is ', '')).trim()

            //   } else {

            //       status = (data[x].status.replace('Request', '')).trim()

            //   }

            // } else {

              status = data[x].status

            // }

            const requestObject = {
              ...data[x],
              requestDate: status !== 'Request Submitted' ? this.dateTimeToHuman(data[x].lastModifiedDate) : this.dateTimeToHuman(data[x].requestDate),
              cargoExpectedTime: data[x].cargoExpectedTime !== null ? this.dateToHuman(data[x].cargoExpectedTime): '',
              status: status
            };

            this.filteredRequests.push(requestObject)

          } else {

            const requestObject = {
              ...data[x],
              requestDate: data[x].status !== 'Request Submitted' ? this.dateTimeToHuman(data[x].lastModifiedDate) : this.dateTimeToHuman(data[x].requestDate),
              cargoExpectedTime: data[x].cargoExpectedTime !== null ? this.dateToHuman(data[x].cargoExpectedTime): '',
            };

            this.filteredRequests.push(requestObject)

          }

        }

      },

      setAllGetters(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';

        this.noDataFound = this.getNoDataFound;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;

        this.sortingData(this.requests)

      },

      loadPageableRequestOnCreated (params, fromApplicationPageNo) {

        this.filteredRequests = []

        this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

          this.loadClearingAndForwardingApplicationsOnCreated({}).then(() => {

            this.setAllGetters('onCreated');

          }).catch(() => {});

          this.myFirstLoader = this.getMyFirstLoader

        }).catch(() => {})

      },


      loadPageableRequestsAfterCreated(params, fromApplicationPageNo) {

        this.filteredRequests = []

        this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

          this.loadClearingAndForwardingApplications({}).then(() => {

            this.setAllGetters('afterCreated');

          });

          this.myLoader = this.getMyLoader;

        }).catch(() => {});

      },


      searchGeneralChargeParams(params, fromApplicationPageNo) {

        this.filteredRequests = []

        this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

          this.searchWithGeneralChargeParams({}).then(() => {

            this.setAllGetters('onCreated');

          }).catch(() => {});

          this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {


        });

      },

      onChange (event) {

          this.$store.dispatch("clearingrequests/pageSizing", event);
          this.$store.dispatch("clearingrequests/pageNumbering", 0);
          this.isPage = 0

          this.pageSize = Number(this.getPageSize);
          this.pageNo = this.getPageNo;
          this.pageNoPart = 0
          this.loadPageableRequestOnCreated(this.loadedParams, 0);
          this.filteredRequests = []

      },

      goToPage(page) {

          if(page > 0) {page = page - 1;}
          this.isPage = page + 1

          this.pageNo = page;
          this.pageNoPart = this.isPage
          this.loadPageableRequestsAfterCreated(this.loadedParams, this.pageNoPart);
          this.filteredRequests = []

      },

      showToolTip (id) {
        this.isHovered = [id]
      },

      hideToolTip (id) {
        this.isHovered.splice(this.isHovered.indexOf(id), 1)
      },

      goToApplication (data) {

        document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
        this.$router.push('/clearing-and-forwarding/' + this.requestType + '-' + this.$route.params.status + '-application-view/' + data.requestId);
        this.requestId = data.requestId

        if(this.loadedParams.customerIds !== null) {

          localStorage.setItem("search-params-clist", JSON.stringify(this.allCustomers));

        }
        if(this.loadedParams.regimeIds !== null) {

          localStorage.setItem("search-params-rlist", JSON.stringify(this.getFilteredRegimes));

        }
        if(this.loadedParams.stationIds !== null) {

          localStorage.setItem("search-params-slist", JSON.stringify(this.stations));

        }

        if(this.loadedParams.applicationStatuses !== null) {

          localStorage.setItem("search-params-alist", JSON.stringify(this.applicationStatuses));

        }

        if(this.loadedParams.tariffItem !== null) {

          localStorage.setItem("search-params-tlist", JSON.stringify(this.activeTariffItems));

        }

        localStorage.setItem("search-params", JSON.stringify(this.loadedParams));

      },

    },

    destroyed () {

      if(typeof this.$route.fullPath !== undefined) {

        if( this.$route.fullPath !== '/clearing-and-forwarding/import-new-applications' && this.$route.fullPath !== '/clearing-and-forwarding/' + this.requestType + '-' + this.$route.params.status + '-application-view/' + this.requestId) {

            localStorage.removeItem("search-params")
            localStorage.removeItem('search-params-clist')
            localStorage.removeItem('search-params-rlist')
            localStorage.removeItem('search-params-slist')
            localStorage.removeItem('search-params-alist')
            localStorage.removeItem('search-params-tlist')
            // this.resetParams()

        }

      }

    }

  }
</script>

<style scoped>

  .btn-space {
    margin-right: 10px !important;
  }

  .btn-success-custom {
    padding: 0 4px 0 4px;
    color: #3cb371;
  }

  .btn-danger-custom {
    padding: 0 4px 0 4px;
    color: #e97451;
  }

  .btn-success-custom:hover {
    cursor: pointer;
    color: green;
  }

  .btn-danger-custom:hover {
    cursor: pointer;
    color: red;
  }

  .table-striped tr {
    line-height: 20px;
  }

  .disabled-click {
    pointer-events: none;
    color: #dcdcdc;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  .enabled-click {
    pointer-events: auto;
  }

  .search .form-group .input-group .input-group-addon {
    width: 40px;
    text-align: center;
    padding-top: 3.5px;
    background-color: rgba(220, 220, 220, .3);
  }

  .disable-highlight:hover span {
    cursor: pointer;
  }

  .refresh-all {
    margin-top: 6px;
    margin-right: 20px
  }

  .refresh-all:hover {
    cursor: pointer;
  }

  .search .form-group .input-group #inputGroup {
    width: 250px;
    padding-top: 3.5px;
  }

  .search .form-group .input-group .input-group-addon:hover {
    cursor: pointer;
    background-color: rgba(220, 220, 220, .5);
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 90px;
    background-color: #FFFFFF !important;
  }

  .table td, .table th {
    text-align: center;
  }

  .text-center {
    text-align: center !important;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(153, 186, 221, .5) !important;
  }

  .more-customer-details:hover {
    cursor: pointer;
    color: #d59a18;
  }

  .bg-grey {
    background-color: #dcdcdc;
  }

  .close-info {
    color: blue !important;
  }

  .close-info:hover {
    cursor: pointer;
    color: green !important;
  }

</style>
