<template>
    <div>
        <card>
            <div class="row">
                <div class="col-md-4">
                    <label class="typo__label" v-if="!customerLoader && !companyLoader">Bill Type</label>
                    <label class="typo__label" v-else>
                        <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                        Loading bill type, please wait
                    </label>
                    <model-list-select
                    :list="billTypes"
                    v-model="billType"
                    class="form-control"
                    :isDisabled="customerLoader && companyLoader"
                    @input="setCutomers"
                    option-value="id"
                    option-text="name"
                    placeholder="Bill Type"
                    >
                    </model-list-select>
                </div>
                <div class="col-md-4" v-if="billTypeName === 'CFA'">
                    <label class="typo__label" v-if="!customerLoader">Search customer</label>
                    <label class="typo__label" v-else>
                        <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                        Loading customers, please wait
                    </label>
                    <list-select
                    :list="allUsers"
                    v-model="customerId"
                    :isDisabled="allCustomers.length <= 0"
                    class="form-control"
                    option-value="customerId"
                    :selected-item="selectedCustomer"
                    option-text="name"
                    placeholder="Search customer"
                    @select="populateCustomer"
                    >
                    </list-select>
                </div>
                <div class="col-md-4" v-if="billTypeName === 'DC'">
                    <label class="typo__label" v-if="!companyLoader">Search customer</label>
                    <label class="typo__label" v-else>
                        <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                        Loading customers, please wait
                    </label>
                    <list-select
                    :list="allUsers"
                    v-model="customerId"
                    :isDisabled="allCompanies.length <= 0"
                    class="form-control"
                    option-value="companyId"
                    :selected-item="selectedCustomer"
                    :custom-text="customCompanyCodeAndName"
                    placeholder="Search company"
                    @select="populateCustomer"
                    >
                    </list-select>
                </div>
            </div>

            <br/>

            <div class="text-center row">
                <div class="col-md-6 text-right loader-div">
                </div>
                <div class="col-md-2 left loader-div-gif">
                </div>
                <div class="col-md-4">
                    <!-- <button
                    @click.prevent="resetParams"
                    class="btn btn-fill btn-outline-info float-right custom-btn"
                    >
                    Cancel
                    </button> -->
                    <button
                    type="submit"
                    v-if="customerId !== ''"
                    @click.prevent="generateReport"
                    class="btn btn-primary btn-space float-right custom-btn mr-2"
                    >
                    Generate Report
                    </button>
                </div>
            </div>
        </card>
        <global-modal v-if="globalLoader">
        <div slot="globalLoader">
            <div class="row text-center">
            <div class="col-md-12">
                Downloading requested Report, Please wait
            </div>
            <div class="col-md-12">
                <br>
            </div>
            <div class="col-md-12">
                <pulse-loader
                :color="color"
                :size="size"
                />
            </div>
            </div>
        </div>
        </global-modal>
    </div>
</template>

<script>

import { BILL_CATEGORIES } from '../../../mixins/constants'
import API from "../../../api/index"
import API_REVENUE from '../../../api/rev'
import {ModelListSelect, ListSelect} from 'vue-search-select';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import GlobalModal from '../../../components/globalLoader'

export default {

    components: {

        ModelListSelect,
        ListSelect,
        PulseLoader,
        GlobalModal

    },
    
    data () {

        return {
            
            color: 'green',
            size: '12px',
            size1: '9px',

            allCustomers: [],
            billTypes: BILL_CATEGORIES,
            billType: '',
            allCustomers: [],
            allCompanies: [],
            allUsers: [],
            customerLoader: false,
            companyLoader: false,
            globalLoader: false,
            customerId: '',
            billTypeName: '',
            customerName: '',
            selectedCustomer: {}

        }

    },

    created () {

        this.$store.dispatch("pagenames/currentPageName", "Aged Receivable Reports");
        this.loadAllCustomers ()
        this.loadAllCompanies ()

    },

    methods: {

        customCompanyCodeAndName(item) {

            return `${item.companyName} ( ${item.companyCode} )`

        },

        populateCustomer (object) {

            this.selectedCustomer = object
            if(this.billTypeName === 'CFA') {

                this.customerId = this.selectedCustomer.customerId
                this.customerName = this.selectedCustomer.name

            } else {

                this.customerId = this.selectedCustomer.companyId
                this.customerName = this.selectedCustomer.companyName

            }

        },

        setCutomers(event) {

            this.allUsers = []
            this.name = ''
            this.value = ''

            switch (event) {

            case 'CFA':
                this.allUsers = this.allCustomers
                this.billTypeName = 'CFA'
                break;
            case 'tally':
                break;
            case 'agency':
                break;
            default:
                this.allUsers = this.allCompanies
                this.billTypeName = 'DC'
                break;

            }

        },

        loadAllCustomers() {

            this.customerLoader = true;

            API.getCustomerAll()
            .then((response) => {

                if (response.data.status) {

                    this.allCustomers = response.data.data;
                    this.customerLoader = false;

                } else {}
            })
            .catch(() => {

                this.customerLoader = false;

            });

        },

        loadAllCompanies() {

            this.companyLoader = true;

            API.getCompany()
            .then((response) => {

                if (response.data.status) {

                    this.allCompanies = response.data.data;
                    this.companyLoader = false;

                } else {}
            })
            .catch(() => {

                this.companyLoader = false;

            });

        },

        resetParams () {},

        generateReport () {

            this.globalLoader = true;

            API_REVENUE.downloadAgedReceivable(this.customerId , this.billType)
            .then((response) => {

                this.globalLoader = false;

                if (response.status === 200) {
                
                    const url = window.URL.createObjectURL(new Blob([response.data], {
                        type: 'application/octet-stream'
                    }));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", this.customerName + "_AGED_RECEIVABLE_REPORT.xlsx");
                    document.body.appendChild(link);
                    link.click();
                    this.loadNotification('success', 4000, 'ged Receivable Report',
                    'Aged receivable Report has been successifully downloaded, please have a look at it',
                    'response', 1000 , true  , true);
                    this.globalLoader = false
                    
                } else {
                    
                    this.loadNotification('error', 4000, 'Aged Receivable Report',
                    'No content found for aged receivable report, please try with different options',
                    'response', 1000 , true  , true);
                    this.globalLoader = false
                
                }
            })
            .catch(() => {

                this.loadNotification('error', 4000, 'ged Receivable Report',
                'No report found, please check your internet connection and try',
                'response', 1000, true, true);
                this.globalLoader = false

            });

        },

    }

}
</script>

<style scoped>
.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}
</style>