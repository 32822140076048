<template>
  <div class="full-screen">
    <div class="container text-muted">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1 class="title">
            Tanzania Shipping Agency Corporation
          </h1>
          <h2 class="title text-danger">
            Access Denied
          </h2>
          <h2 class="title">
            <b>Oops!</b> It seems that you dont have access to this page, Please contact system admin.
          </h2>
        </div>
        <div class="col-md-12 text-center">
          <font-awesome-icon
            class="ban-icon"
            icon="ban"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}

</script>
<style scoped>

  .full-screen {

    height: 500px;

  }

  .ban-icon {

    font-size: 450px;
    color: #DCDCDC;

  }

</style>
