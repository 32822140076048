<template>
  <div>
    <card style="font-size: 12px" v-if="!thisFirstRequestLoader && singleRequest.status !== applicationStatuses.REJECTED_MAJOR">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav-justified">
              <li
              v-if="singleRequest.status !== applicationStatuses.CLOSED && !singleRequest.isDuplicate
              && singleRequest.status !== applicationStatuses.SUBMITTED && singleRequest.fileNo !== null && singleRequest.status !== applicationStatuses.RETURNED && singleRequest.status !== applicationStatuses.DRAFT
              && singleRequest.status !== applicationStatuses.REJECTED_MAJOR">
                <button
                  @click.prevent="openModal(requestId, 'STAFF_ASSIGNMENT')"

                  class="btn btn-blue btn-blue-fill mr-2 custom-btn-top"
                > STAFF ASSIGNMENT
                </button>
              </li>
              <li v-if="!singleRequest.isDuplicate && singleRequest.status !== applicationStatuses.SUBMITTED && singleRequest.status !== applicationStatuses.RETURNED
              && singleRequest.status !== applicationStatuses.DRAFT && singleRequest.fileNo !== null && singleRequest.status !== applicationStatuses.REJECTED_MAJOR">
                <button
                  @click.prevent="openModal(requestId, 'UPDATE_ASSIGNED_ACTIVITY')"

                  class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn-top"
                > UPDATE ACTIVITY
                </button>
              </li>
              <li
                v-if="(singleRequest.status === applicationStatuses.BILLED
                || singleRequest.status === applicationStatuses.BILL_APPROVED
                || singleRequest.status === applicationStatuses.PAID || singleRequest.status === applicationStatuses.CLOSED) && !singleRequest.isDuplicate && singleRequest.status !== applicationStatuses.SUBMITTED
                && singleRequest.status !== applicationStatuses.RETURNED && singleRequest.fileNo !== null && singleRequest.status !== applicationStatuses.REJECTED_MAJOR">
                <button @click="openModal(requestId, 'CHARGING')" class="btn btn-olive btn-olive-fill mr-2 custom-btn-top"
                > VIEW CHARGE
                </button>
              </li>
              <li v-if="singleRequest.status !== applicationStatuses.SUBMITTED && singleRequest.status !== applicationStatuses.RETURNED
              && singleRequest.status !== applicationStatuses.DRAFT && singleRequest.status !== applicationStatuses.REJECTED_MAJOR && singleRequest.fileNo !== null">
                <button
                  @click.prevent="openModal(requestId, 'APPLICATION_ACTIVITY_LIST')"

                  class="btn btn-linen btn-linen-fill mr-2 custom-btn-top"
                > ACTIVITY LIST
                </button>
              </li>
              <li
                v-if="singleRequest.status !== applicationStatuses.REJECTED_MAJOR && singleRequest.status !== applicationStatuses.REJECTED_MINOR &&
              singleRequest.status!==applicationStatuses.REJECTED_CHARGE && !singleRequest.isDuplicate && singleRequest.status !== applicationStatuses.SUBMITTED
              && singleRequest.status !== applicationStatuses.RETURNED && singleRequest.status !== applicationStatuses.DRAFT && singleRequest.fileNo !== null
              && singleRequest.status !== applicationStatuses.REJECTED_MAJOR"
              >
                <button
                  @click.prevent="openModal(requestId, 'ASSESSMENT_NOTICE')"

                  class="btn btn-rosy-brown btn-rosy-brown-fill mr-2 custom-btn-top"
                > TARIFF
                </button>
              </li>
              <li
                v-if="(singleRequest.status === applicationStatuses.ASSESSED ||
              singleRequest.status === applicationStatuses.CHARGED || singleRequest.status === applicationStatuses.REJECTED_CHARGE) && !singleRequest.isDuplicate && singleRequest.status !== applicationStatuses.SUBMITTED
              && singleRequest.status !== applicationStatuses.RETURNED && singleRequest.fileNo !== null && singleRequest.status !== applicationStatuses.REJECTED_MAJOR"
              >
                <button
                  @click.prevent="openModal(requestId, 'CHARGING')"

                  class="btn btn-dark-blue-like btn-dark-blue-like-fill mr-2 custom-btn-top"
                > CHARGING
                </button>
              </li>
              <li v-if="singleRequest.status !== applicationStatuses.SUBMITTED
              && singleRequest.status !== applicationStatuses.RETURNED && singleRequest.status !== applicationStatuses.DRAFT
              && singleRequest.fileNo !== null && singleRequest.status !== applicationStatuses.REJECTED_MAJOR">
                <button
                  @click.prevent="openModal(singleRequest, 'ADDITIONAL_FIELDS')"
                  v-if="finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_PUT')"
                  class="btn btn-khaki btn-khaki-fill mr-2 custom-btn-top"
                > ADDITIONAL FIELDS
                </button>
              </li>
              <li v-if="singleRequest.status === applicationStatuses.DRAFT">
                  <button  @click.prevent="gotToAttachment" class="btn btn-purple btn-purple-fill mr-2 custom-btn-top"
                  v-if="finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_PUT')">
                      CONTINUE TO COMPULSORY ATTACHMENTS
                  </button>
              </li>
              <li v-if="singleRequest.status !== applicationStatuses.REJECTED_MAJOR && singleRequest.status !== applicationStatuses.CHARGED && singleRequest.status !== applicationStatuses.BILLED
                && singleRequest.status !== applicationStatuses.BILL_APPROVED && singleRequest.status !== applicationStatuses.PAID && finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_PUT')">
                  <button @click.prevent="gotToApplication" class="btn btn-dark-grey btn-dark-grey-fill mr-2 custom-btn-top">
                      EDIT APPLICATION
                  </button>
              </li>
              <li>
                <button
                  @click.prevent="duplicateToset=!duplicateToset"
                  class="btn btn-danger-custom btn-danger-custom-fill mr-2 custom-btn"
                  v-if="!singleRequest.isDuplicate && singleRequest.status !== applicationStatuses.CHARGED && singleRequest.status !== applicationStatuses.BILLED &&
                  singleRequest.status !== applicationStatuses.BILL_APPROVED && singleRequest.status !== applicationStatuses.BILLED && !duplicateToset && singleRequest.status !== applicationStatuses.REJECTED_MAJOR"
                >DISMISS
                </button>
              </li>
              <li v-if="(singleRequest.status === applicationStatuses.BILLED
                || singleRequest.status === applicationStatuses.BILL_APPROVED
                || singleRequest.status === applicationStatuses.PAID || singleRequest.status === applicationStatuses.CLOSED) && !singleRequest.isDuplicate && singleRequest.status !== applicationStatuses.SUBMITTED
                && singleRequest.status !== applicationStatuses.RETURNED && singleRequest.fileNo !== null && singleRequest.status !== applicationStatuses.REJECTED_MAJOR" class="float-right">
                <button
                    @click.prevent="goToBill"
                    class="btn btn-linen btn-linen-fill mr-2 custom-btn">
                    VIEW ASSOCIATED BILL
                </button>
                </li>
                <li>
                  <button
                    @click.prevent="openModal(singleRequest, 'APPLICATION_TIMELINE')"
                    class="btn btn-grey btn-grey-fill mr-2 custom-btn">
                    APPLICATION TIMELINE
                </button>
                </li>
            </ul>
          </div>
        </div>
        <div class="row mt-3" v-if="duplicateToset">
          <div class="col-md-12 mb-3 mt-2">
            <label>Dismissal Reason</label>
            <model-list-select
              :list="dismissCase"
              v-model="selectedDismissalCase"
              class="form-control"
              option-value="id"
              option-text="name"
              placeholder="Dismissal Reason"
            >
            </model-list-select>
          </div>
          <div v-if="selectedDismissalCaseError" class="col-md-12 text-center text-danger">
            <div v-if="!$v.selectedDismissalCase.required">
              <span>Dismissal Case is Required</span>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <div class="form-floating">
              <pulse-loader
                :loading="duplicateLoader"
                :color="color"
                :size="size"
              />
              <label for="floatingTextarea">Comments</label>
              <textarea class="form-control" placeholder="Write a comment here" id="floatingTextarea" v-model="duplicateComment"></textarea>
              <div v-if="duplicateCommentError">
                <div class="text-danger" v-if="!$v.duplicateComment.required">
                  <span>Comment is Required</span>
                </div>
              </div>
            </div>
            <button
              @click.prevent="duplicateToset=!duplicateToset"
              class="btn btn-default btn-fill mr-2 custom-btn float-right mt-3"
            >Back
            </button>
            <button class="btn btn-primary btn-default-fill mr-2 custom-btn float-right mt-3" @click.prevent="dismissApp" :disabled="singleRequest.isDuplicate">
              Dismiss
            </button>
          </div>
        </div>
    </card>
    <card style="font-size: 12px">
      <div
        class="row"
        v-if="thisFirstRequestLoader"
      >
        <div class="col-md-12 text-center loader-color">
          <b>Please wait while system is loading application details</b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
          />
        </div>
      </div>
      <div class="row" v-if="!thisFirstRequestLoader">
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Application Details
            </h6>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Current Application Stage / Status
              </div>
              <div
                class="col-md-9 div-table div-table-value" :class="getColoredStatus(singleRequest.status)">
                {{ applicationStatus }}
              </div>
            </div>

            <br v-if="singleRequest.isDuplicate"/>

            <div class="row div-table-row" v-if="singleRequest.isDuplicate">
              <div class="col-md-3 div-table div-table-title">
                Reason For Dismissal
              </div>
              <div class="col-md-9 div-table div-table-value">
                {{ singleRequest.rejectReason }}
              </div>
            </div>

            <br v-if="singleRequest.isDuplicate"/>

            <div class="row div-table-row" v-if="singleRequest.status===applicationStatuses.DISMISSED">
              <div class="col-md-3 div-table div-table-title mb-3">
                Dismissed Case
              </div>
              <div
                class="col-md-3 div-table div-table-value mb-3"
                style="font-weight: 900"
              >
                {{ underscoreToSpaceString(singleRequest.dismissCase) }}
              </div>
              <div class="col-md-3 div-table div-table-title mb-3">
                Dismissed By
              </div>
              <div
                class="col-md-3 div-table div-table-value mb-3"
                style="color: #696969;font-weight: 900"
              >
                {{ singleRequest.dismissedBy }}
              </div>

            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                File Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.fileNo }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Customer Name - TIN
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ typeof singleRequest.customer !== 'undefined' ?
                  (singleRequest.customer.name).toUpperCase() + ' - ' + singleRequest.customer.tin : '' }}
              </div>
            </div>

            <br>
            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    PO Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.poNumber }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    {{ singleRequest.requestType === 'IMP' ? 'Expected Time of Arrival (ETA)' : 'Expected Time of Departure (ETD)' }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                  <span :class="[expectedDiff <= 7 && singleRequest.cargoExpectedDate !== null ? 'blink-me' : '',
                expectedDiff < 4 ? 'text-danger' : '', expectedDiff < 8 && expectedDiff > 3 ? 'text-success' : '']">
                    {{ singleRequest.cargoExpectedDate !== null && singleRequest.cargoExpectedDate !== '' ? dateToHuman(singleRequest.cargoExpectedDate) : '' }}
                    <span class="float-right"> {{ singleRequest.cargoExpectedDate !== null ? '( ' + dayMessage + ' )' : ''}}</span></span>
                </div>
            </div>
            <br>
            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Product Type
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.productType }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Invoice Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ singleRequest.invoiceNo }}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                BL/Airway Bill/RCN
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.blNumber }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Regime Type
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ typeof singleRequest.regime !== 'undefined'
                  && singleRequest.regime !== null ? singleRequest.regime.regimeName : '' }}
              </div>
            </div>

            <br />

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Application Date
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ dateTimeToHuman(singleRequest.requestDate) }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Application Type
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.requestTypeName }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Application Description
              </div>
              <div class="col-md-9 div-table div-table-value">
                {{ singleRequest.description !== null && singleRequest.description.includes('\n') ? singleRequest.description.split('\n').join(', ') : singleRequest.description }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div
                class="col-md-12 div-table div-table-title"
                style="border-bottom: 1px solid #a9a9a9"
              >
                <div class="row">
                  <div class="text-right col-md-7 pr-5">
                    Associated Attachment(s)
                  </div>
                  <div class="tooltipe text-right col-md-5 pr-5 p-8">
                    <font-awesome-icon
                      icon="exclamation-triangle"
                      class="fa-lg text-danger"
                      v-if="allAttachmentVerified"
                    />
                    <span
                      class="tooltiptext2 text-center"
                      v-if="allAttachmentVerified"
                    >
                      It is important to view and verify all mandatory attachments
                      before accepting this application</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row div-table-row"
              v-if="typeof singleRequest.requestAttachDtos !== 'undefined'
                && singleRequest.requestAttachDtos.length > 0"
            >
              <div
                class="col-md-1 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                #
              </div>
              <div
                class="col-md-5 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                Attachment Name
              </div>
              <div
                class="col-md-2 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                Uploaded Date
              </div>
              <div
                class="col-md-1 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                View
              </div>
              <div
                class="col-md-2 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                View Status
              </div>
              <div class="col-md-1 div-table div-table-title text-center">
                Verification Status
              </div>
            </div>
            <div
              class="row div-table-row"
              v-if="typeof singleRequest.requestAttachDtos !== 'undefined'
                && singleRequest.requestAttachDtos.length > 0"
              v-for="(attachment , index_attachment) in singleRequest.requestAttachDtos" :key="index_attachment"
            >
              <div class="col-md-1 div-table div-table-value text-center">
                {{ index_attachment + 1 }}
              </div>
              <div class="col-md-5 div-table div-table-value">
                {{ attachment.attachmentName }} <!-- <sup class="text-danger blink-me" style="font-size:13px;">new</sup> -->
              </div>
              <div class="col-md-2 div-table div-table-value text-center">
                {{ dateTimeToHuman(attachment.createdDate) }}
              </div>
              <div
                class="col-md-1 div-table div-table-value text-center text-link"
                @click.prevent="openPdfViewer(attachment.attachmentUrl , (attachment.attachmentName).toUpperCase() + '_FOR_APPLICATION_WITH_FILE_NUMBER_' + singleRequest.fileNo, 'view', attachment.requestAttachmentId, attachment.isViewed)"
              >
                click to view
              </div>
              <div
                class="col-md-2 div-table div-table-value text-center text-danger"
                v-if="!attachment.isViewed"
              >
                Not Viewed
              </div>
              <div
                class="col-md-2 div-table div-table-value text-center text-success"
                v-if="attachment.isViewed"
              >
                Viewed
              </div>
              <div
                class="col-md-1 div-table div-table-value text-center text-danger"
                v-if="!attachment.verified"
              >
                Not Verified
              </div>
              <div
                class="col-md-1 div-table div-table-value text-center text-success"
                v-if="attachment.verified"
              >
                Verified
              </div>
              <!-- <div
                class="col-md-1 div-table div-table-value text-center"
                v-if="!attachment.verified"
              >
                <span
                  @click.prevent="verifyThisAttachment(attachment.requestAttachmentId)"
                  v-if="!verifiedHasId.includes(attachment.requestAttachmentId)
                    && singleRequest.status !== applicationStatuses.CLOSED && !singleRequest.isDuplicate && singleRequest.status !== applicationStatuses.DRAFT"
                  class="text-link"
                >
                  click to verify
                </span>
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="sizeVerify"
                  v-if="verifiedHasId.includes(attachment.requestAttachmentId)"
                />
              </div>
              <div
                class="col-md-1 div-table div-table-value"
                v-else>
                </div> -->
            </div>

            <div
              class="row div-table-row"
              v-if="typeof singleRequest.requestAttachDtos !== 'undefined'
                && singleRequest.requestAttachDtos.length <= 0"
            >
              <div class="col-md-12 div-table div-table-value text-center">
                <b>No attachment(s) found</b>
              </div>
            </div>
            <div class="row div-table-row" v-if="allAttachmentVerified">
              <div class="col-md-12 div-table div-table-value text-right pr-4">
                <b class="text-link" @click="verifyAllAttachmentAtOnce">Click to verify all attachments</b>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-12"
              v-if="this.finalUserPermissions.includes('BILLS_GET-INVOICE-PDF_{BILLID}_GET') && singleRequest.status ===applicationStatuses.BILLED || singleRequest.status===applicationStatuses.BILL_APPROVED || singleRequest.status===applicationStatuses.PAID">
          <div class="legend">
            <h6 class="legend-title">
              Bill Details
            </h6>
            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Download Bill
                <pulse-loader
                  :loading="getBillDetailsLoader"
                  :color="color"
                  :size="size"
                />
              </div>
              <div class="col-md-4 div-table div-table-value" style="color: #696969;font-weight: 900">
               <div class="row">
                 <div class="col-md-12">
                   <button
                     @click="getInvoice(billDetails.billId)"
                     class="btn btn-success-custom mr-2 custom-btn" :disabled="getBillDetailsLoader">
                     <font-awesome-icon
                       icon="file-pdf"
                       color="red"
                       size="lg"
                       :disabled="singleRequest.isDuplicate"
                     />&nbsp;&nbsp;
                     Download
                   </button>
                   &nbsp;&nbsp;&nbsp;
                   <button
                     v-if="singleRequest.status === applicationStatuses.PAID"
                     @click="getReceipt(singleRequest.billId)"
                     class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                     <font-awesome-icon
                       icon="file-pdf"
                       color="red"
                       size="lg"
                       :disabled="singleRequest.isDuplicate"
                     />&nbsp;&nbsp;
                     Download Receipt
                   </button>
                 </div>
               </div>
              </div>
              <div class="col-md-2 div-table div-table-title">
                Bill Details
              </div>
              <div class="col-md-4 div-table div-table-value">
                <button class="btn btn-primary btn-space custom-btn" type="button" @click.prevent="goToBill">View Details</button>
              </div>
            </div>
          </div>
        </div> -->


        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Receiving Point
            </h6>

            <div class="row div-table-row">
              <div
                class="col-md-12 div-table div-table-title"
                style="border-bottom: 1px solid #a9a9a9"
              >
                <div class="row">
                  <div class="text-right col-md-7 pr-5">
                    Saved Receiving Point
                  </div>
                  <div class="tooltipe text-right col-md-5 pr-5">
                    <i><font-awesome-icon
                      icon="exclamation-triangle"
                      class="fa-lg text-danger"
                      v-if="typeof singleRequest.station !== 'undefined'
                        && singleRequest.station === null
                        && typeof singleRequest.transportMethod !== 'undefined'
                        && singleRequest.transportMethod === null"
                    /></i>
                    <span
                      class="tooltiptext text-center"
                      v-if="typeof singleRequest.station !== 'undefined'
                        && singleRequest.station === null
                        && typeof singleRequest.transportMethod !== 'undefined'
                        && singleRequest.transportMethod === null"
                    >
                      Receiving Point is required before accepting this application</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row div-table-row"
              v-if="typeof singleRequest.station !== 'undefined'
                && singleRequest.station !== null && typeof singleRequest.transportMethod !== 'undefined'
                && singleRequest.transportMethod !== null"
            >
              <div
                class="col-md-1 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                #
              </div>
              <div
                class="col-md-5 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                Station Name
              </div>
              <div
                class="col-md-4 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                Transport Method
              </div>
              <div class="col-md-2 div-table div-table-title text-center">
                Action
              </div>
            </div>

            <div
              class="row div-table-row"
              v-if="typeof singleRequest.station !== 'undefined' && singleRequest.station !== null
                && typeof singleRequest.transportMethod !== 'undefined'
                && singleRequest.transportMethod !== null"
            >
              <div class="col-md-1 div-table div-table-value text-center">
                {{ typeof singleRequest.station !== 'undefined'
                  && singleRequest.station !== null ? '1' : '' }}
              </div>
              <div class="col-md-5 div-table div-table-value text-center">
                {{ typeof singleRequest.station !== 'undefined' && singleRequest.station !== null
                  ? singleRequest.station.stationName : '' }}
              </div>
              <div class="col-md-4 div-table div-table-value text-center">
                {{ typeof singleRequest.transportMethod !== 'undefined'
                  && singleRequest.transportMethod !== null ?
                    singleRequest.transportMethod.transportMethodName : '' }}
              </div>
              <div
                class="col-md-2 div-table div-table-value text-center text-link"
                v-if="singleRequest.status !== applicationStatuses.CLOSED
                  && singleRequest.status !== applicationStatuses.PAID && singleRequest.fileNo===null"
                @click.prevent="showUpdateReceivingPoint(singleRequest.station.stationId,singleRequest.transportMethod.transportMethodId)"
              >
                Update
              </div>
              <div
                class="col-md-2 div-table div-table-value text-center text-link"
                v-else></div>
            </div>

            <div
              class="row div-table-row"
              v-if="typeof singleRequest.station !== 'undefined' && singleRequest.station === null
                && typeof singleRequest.transportMethod !== 'undefined'
                && singleRequest.transportMethod === null"
            >
              <div class="col-md-12 div-table div-table-value text-center">
                <div class="row">
                  <div class="col-md-7 text-right">
                    <b>No receiving point(s) found</b>
                  </div>
                  <div class="col-md-5 text-right">
                    <b
                      class="text-link"
                      @click.prevent="showReceivingPoint"
                      v-if="singleRequest.status !== applicationStatuses.CLOSED
                        && singleRequest.status !== applicationStatuses.PAID && singleRequest.status !== applicationStatuses.DRAFT"
                    >Add</b>
                  </div>
                </div>
              </div>
            </div>

            <br>

            <form
              class="row row-receiving-point"
              v-if="showReceivingPointForm"
            >
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <label>Search Station</label>
                    <model-list-select
                      :list="getStation"
                      v-model="requestStation"
                      class="form-control"
                      option-value="stationId"
                      option-text="stationName"
                      placeholder="Station"
                    />
                    <div v-if="stationIdError">
                      <div
                        class="error"
                        v-if="!$v.requestStation.required"
                      >
                        This field is
                        required
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <label>Search Transport Method</label>
                    <model-list-select
                      :list="getTransportMethod"
                      v-model="requestTransportMethod"
                      class="form-control"
                      option-value="transportMethodId"
                      option-text="transportMethodName"
                      placeholder="Transport Method"
                    />
                    <div v-if="transportMethodIdError">
                      <div
                        class="error"
                        v-if="!$v.requestTransportMethod.required"
                      >
                        This field
                        is
                        required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-8 text-right loader-div-gif pt-3">
                    <div class="row">
                      <div class="col-md-10 text-right pt-1">
                        <span v-if="updatingReceivingPoint">Please wait while system is
                          saving receiving point</span>
                      </div>
                      <div class="col-md-2 text-left">
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          v-if="updatingReceivingPoint"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-right">
                    <button
                      type="submit"
                      class="btn btn-primary btn-space mr-2 custom-btn"
                      data-dismiss="modal"
                      @click.prevent="updateReceivingPoints(requestId)"
                      :disabled="singleRequest.isDuplicate"
                    >
                      Save Receiving Point
                    </button>

                    <button
                      class="btn btn-default btn-fill custom-btn-all"
                      @click.prevent="hideReceivingPoint"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Delivery / Exit Point
            </h6>

            <div class="row div-table-row text-center">
              <div
                class="col-md-12 div-table div-table-title"
                style="border-bottom: 1px solid #a9a9a9"
              >
                Saved Delivery / Exit Point(s)
              </div>
            </div>

            <div
              class="row div-table-row"
              v-if="getReceivingInfo.length > 0"
            >
              <div
                class="col-md-1 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                #
              </div>
              <div
                class="col-md-5 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                Station Name
              </div>
              <div
                class="col-md-4 div-table div-table-title text-center"
                style="border-right: 1px solid #a9a9a9"
              >
                Transport Method
              </div>
              <div class="col-md-2 div-table div-table-title text-center">
                Action
              </div>
            </div>

            <div
              v-if="!requestDeliveryInformationLoader"
              class="row div-table-row"
              v-for="(deliveryOrExitPoint, index_deliveryOrExitPoint) in getReceivingInfo"
              :class="[ currentUpdate.includes(deliveryOrExitPoint.requestDeliveryId)
                ? 'current-update' : '']" :key="index_deliveryOrExitPoint"
            >
              <div class="col-md-1 div-table div-table-value text-center">
                {{ index_deliveryOrExitPoint + 1 }}
              </div>
              <div class="col-md-5 div-table div-table-value text-center">
                {{ deliveryOrExitPoint.stationName }}
              </div>
              <div class="col-md-4 div-table div-table-value text-center">
                {{ deliveryOrExitPoint.transportMethodName }}
              </div>
              <div
                class="col-md-2 div-table div-table-value text-center"
                v-if="singleRequest.status !== applicationStatuses.CLOSED"
              >
                <div class="row">
                  <div
                    class="col-md-6 text-link text-right"
                    @click.prevent="showUpdateDeliveryOrExitPoint(deliveryOrExitPoint.requestDeliveryId,
                                                                  deliveryOrExitPoint.requestId, deliveryOrExitPoint.stationId,
                                                                  deliveryOrExitPoint.transport_method_Id, deliveryOrExitPoint.remarks, 0)"
                    v-if="singleRequest.status !== applicationStatuses.CLOSED
                      && singleRequest.status !== applicationStatuses.PAID && !singleRequest.isDuplicate && !deletingDeliveryOrExitPoint.includes(deliveryOrExitPoint.requestDeliveryId)"
                  >
                    Update
                  </div>
                  <div
                    class="col-md-6 remove-delivery-or-exit-point-in-table text-left"
                    @click.prevent="deleteFileNumberInformation(deliveryOrExitPoint.requestDeliveryId ,
                                                                deliveryOrExitPoint.requestId)"
                    v-if="singleRequest.status !== applicationStatuses.CLOSED
                      && singleRequest.status !== applicationStatuses.PAID && !singleRequest.isDuplicate && !deletingDeliveryOrExitPoint.includes(deliveryOrExitPoint.requestDeliveryId)"
                  >
                    Delete
                  </div>
                  <div
                    class="col-md-12 text-center"
                    v-if="deletingDeliveryOrExitPoint.includes(deliveryOrExitPoint.requestDeliveryId)"
                  >
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="mySize"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="row div-table-row">
              <div class="col-md-12 div-table div-table-value text-center">
                <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="mySize"
                    />
              </div>
            </div>

            <div
              class="row div-table-row"
              v-if="getReceivingInfo.length > 0 && !showUpdateDeliveryOrExitPointForm
                && singleRequest.status !== applicationStatuses.CLOSED
                && singleRequest.status !== applicationStatuses.PAID"
            >
              <div class="col-md-12 div-table div-table-value text-center" v-if="!showDeliveryOrExitPointForm">
                <div class="row">
                  <div class="col-md-12 text-right">
                    <b
                      v-if="!showDeliveryOrExitPointForm && singleRequest.status !== applicationStatuses.CLOSED && !singleRequest.isDuplicate && singleRequest.status !== applicationStatuses.DRAFT"
                      class="text-link"
                      @click.prevent="showDeliveryOrExitPoint"
                    >Add</b>
                    <!-- <b
                      v-if="showDeliveryOrExitPointForm && singleRequest.status !== applicationStatuses.CLOSED"
                      class="text-link"
                      @click.prevent="hideDeliveryOrExitPoint"
                    >Hide Form</b> -->
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row div-table-row"
              v-if="getReceivingInfo.length <= 0 && singleRequest.status !== applicationStatuses.CLOSED
                && singleRequest.status !== applicationStatuses.PAID"
            >
              <div class="col-md-12 div-table div-table-value text-center">
                <div class="row">
                  <div class="col-md-7 text-right">
                    <b>No Delivery / Exit point(s) found</b>
                  </div>
                  <div class="col-md-5 text-right">
                    <b
                      v-if="!showDeliveryOrExitPointForm && singleRequest.status !== applicationStatuses.CLOSED && singleRequest.status !== applicationStatuses.DRAFT"
                      class="text-link"
                      @click.prevent="showDeliveryOrExitPoint"
                    >Add</b>
                    <!-- <b
                      v-if="showDeliveryOrExitPointForm && singleRequest.status !== applicationStatuses.CLOSED"
                      class="text-link"
                      @click.prevent="hideDeliveryOrExitPoint"
                    >Hide Form</b> -->
                  </div>
                </div>
              </div>
            </div>

            <br>

            <form v-if="showDeliveryOrExitPointForm">
              <div
                class="row dynamic-bg  mr-2 ml-2"
                v-for="(row , index) in rows"
                :key="index"
              >
                <div class="col-md-12">
                  <div
                    class="row"
                  >
                    <div class="col-md-6">
                      <label>Search Station</label>
                      <model-list-select
                        :list="getStation"
                        v-model="row.deliveryStationId"
                        class="form-control"
                        option-value="stationId"
                        option-text="stationName"
                        placeholder="Station"
                      />
                      <div v-if="deliveryStationIdError.includes(rows.indexOf(row))">
                        <div
                          class="error"
                          v-if="row.deliveryStationId === '' "
                        >
                          This field
                          is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label>Search Transport Method</label>
                      <model-list-select
                        :list="getTransportMethod"
                        v-model="row.deliveryTransportMethodId"
                        class="form-control"
                        option-value="transportMethodId"
                        option-text="transportMethodName"
                        placeholder="Transport Method"
                      />
                      <div v-if="deliveryTransportMethodIdError.includes(rows.indexOf(row))">
                        <div
                          class="error"
                          v-if="row.deliveryTransportMethodId === '' "
                        >
                          This field is required
                        </div>
                      </div>
                    </div>

                    <!-- <div
                      class="col-md-2 text-center insert-bl"
                      title="save  station and transport method"
                      @click="saveFileNumberInformation(rows.indexOf(row))"
                    >
                      <div
                        class="save-bl"
                        v-if="!savingDeliveryOrExitPoint"
                      >
                        Save
                      </div>
                      <div v-if="savingDeliveryOrExitPoint">
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          v-if="savingDeliveryOrExitPoint"
                        />
                      </div>
                    </div> -->

                    <!-- <div
                      class="col-md-2 text-center remove-delivery-or-exit-point"
                      title="remove  station and transport method"
                      @click="deleteRow(rows.indexOf(row))"
                    >
                      Remove
                    </div> -->
                  </div>
                </div>

                <div class="col-md-12">
                <div class="row">
                  <div class="col-md-8 text-right loader-div-gif pt-3">
                    <div class="row">
                      <div class="col-md-10 text-right pt-1">
                        <span v-if="savingDeliveryOrExitPoint">Please wait while system is
                          saving delivery / exit point</span>
                      </div>
                      <div class="col-md-2 text-left">
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          v-if="savingDeliveryOrExitPoint"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-right">
                    <button
                      type="submit"
                      class="btn btn-primary btn-space mr-2 custom-btn"
                      data-dismiss="modal"
                      @click.prevent="saveFileNumberInformation(rows.indexOf(row))"
                      :disabled="singleRequest.isDuplicate"
                    >
                      Save Delivery / Exit Point
                    </button>

                    <button
                      class="btn btn-default btn-fill custom-btn-all"
                      @click.prevent="hideDeliveryOrExitPoint"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>

                <!-- <div class="col-md-12">
                  <label>Remark (if any)</label>
                  <textarea
                    class="special-bottom form-control col-md-12"
                    placeholder="Request Details"
                    rows="4"
                    type="textarea"
                    v-model="row.description"
                  />
                </div> -->
              </div>
              <!-- <div
                class="col-md-12 text-right text-link"
                style="padding-right: 70px"
                title="add station and transport method"
                @click="addRow"
              >
                Add new row
              </div> -->
            </form>

            <form v-if="showUpdateDeliveryOrExitPointForm">
              <div class="row dynamic-bg mr-2 ml-2">
                <div class="col-md-12">
                  <div
                    class="row"
                    style="padding-left: 10px"
                  >
                    <div class="col-md-6">
                      <label>Search Station</label>
                      <model-list-select
                        :list="getStation"
                        v-model="deliveryStationId"
                        class="form-control"
                        option-value="stationId"
                        option-text="stationName"
                        placeholder="Station"
                      />
                      <div v-if="deliveryUpdateStationIdError">
                        <div class="error">
                          This field
                          is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label>Search Transport Method</label>
                      <model-list-select
                        :list="getTransportMethod"
                        v-model="deliveryTransportMethodId"
                        class="form-control"
                        option-value="transportMethodId"
                        option-text="transportMethodName"
                        placeholder="Transport Method"
                      />
                      <div v-if="deliveryUpdateTransportMethodIdError">
                        <div class="error">
                          This field is required
                        </div>
                      </div>
                    </div>

                    <!-- <div
                      class="col-md-2 text-center insert-bl"
                      title="save  station and transport method"
                      @click="updateFileNumberInformation"
                    >
                      <div
                        class="save-bl"
                        v-if="!savingDeliveryOrExitPoint"
                      >
                        Update
                      </div>
                      <div v-if="savingDeliveryOrExitPoint">
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          v-if="savingDeliveryOrExitPoint"
                        />
                      </div>
                    </div>

                    <div
                      class="col-md-2 text-center remove-delivery-or-exit-point"
                      title="remove  station and transport method"
                      @click="hideUpdateDeliveryOrExitPoint"
                    >
                      Close
                    </div> -->
                  </div>
                </div>

                <div class="col-md-12">
                <div class="row">
                  <div class="col-md-8 text-right loader-div-gif pt-3">
                    <div class="row">
                      <div class="col-md-10 text-right pt-1">
                        <span v-if="updatingDeliveryOrExitPoint">Please wait while system is
                          saving delivery / exit point</span>
                      </div>
                      <div class="col-md-2 text-left">
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          v-if="updatingDeliveryOrExitPoint"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-right">
                    <button
                      type="submit"
                      class="btn btn-primary btn-space mr-2 custom-btn"
                      data-dismiss="modal"
                      @click.prevent="updateFileNumberInformation"
                      :disabled="singleRequest.isDuplicate"
                    >
                      Update Delivery / Exit Point
                    </button>

                    <button
                      class="btn btn-default btn-fill custom-btn-all"
                      @click.prevent="hideUpdateDeliveryOrExitPoint"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>

                <!-- <div class="col-md-12">
                  <label>Remark (if any)</label>
                  <textarea
                    class="special-bottom form-control col-md-12"
                    placeholder="Request Details"
                    rows="4"
                    type="textarea"
                    v-model="description"></textarea>
                </div> -->
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-12" v-if="singleRequest.status !== applicationStatuses.SUBMITTED && singleRequest.status !== applicationStatuses.RETURNED
        && !singleRequest.isDuplicate && singleRequest.status !== applicationStatuses.DRAFT && singleRequest.status !== applicationStatuses.REJECTED_MAJOR && singleRequest.status !== applicationStatuses.REJECTED_MINOR">
          <div class="legend">
            <h6 class="legend-title">
              Declaration and Notice List
            </h6>

            <form>
              <card v-if="singleRequest.referenceSerialNo !== null && singleRequest.referenceTIN !== null && singleRequest.referenceYear !== null">
                <div
                  class="col-md-12 alert-dismissible fade show"
                  role="alert"
                  :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
                  style="border-radius:5px;"
                  @enter="enter"
                >
                  <strong v-if="SuccessAlert">Successifully!</strong> <span
                    v-if="SuccessAlert"
                  >{{ SuccessMessage }}</span>
                  <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    v-if="SuccessAlert"
                    style="line-height:2px;"
                    @click="fadeMe"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    v-if="ErrorAlert"
                    style="line-height:2px;"
                    @click="fadeMe"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="col-md-12">
                  Please, use below information to retrieve current Application's Declaration / Notice list
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <base-input
                          class="special-bottom"
                          label="Customer TIN"
                          type="text"
                          :value="refTin"
                          v-model="refTin"
                          readonly
                        />
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <base-input
                          class="special-bottom"
                          label="Reference Year"
                          type="text"
                          :value="refYear"
                          v-model="refYear"
                          readonly
                        />
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <base-input
                          class="special-bottom"
                          label="Enter Application Ref No."
                          placeholder="Enter Application Ref No."
                          type="text"
                          :value="refNumber"
                          v-model="refNumber"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center row">
                  <div class="col-md-6 text-right">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="saveReferencesAndPullLoader"
                    />
                  </div>
                  <div class="col-md-6 text-right">
                    <button
                      type="submit"
                      class="btn btn-primary btn-space custom-btn"
                      @click.prevent="goToDeclaration"
                      :disabled="singleRequest.isDuplicate"
                    >View Declaration
                    </button>
                    <button
                      type="submit"
                      class="btn btn-danger btn-space custom-btn"
                      @click.prevent="deleteDeclaration"
                      v-if="singleRequest.status !== applicationStatuses.CHARGED && singleRequest.referenceSerialNo !== null && singleRequest.referenceTIN !== null && singleRequest.referenceYear !== null"
                    >
                      Reset Declaration
                    </button>

                    <button
                      type="submit"
                      class="btn btn-info btn-space custom-btn"
                      @click.prevent="goToNoticelist"
                    >View Notice list
                    </button>
                  </div>
                </div>
              </card>

              <card v-if="singleRequest.referenceSerialNo === null && singleRequest.referenceTIN === null && singleRequest.referenceYear === null">
                <div
                  class="col-md-12 alert-dismissible fade show"
                  role="alert"
                  :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
                  style="border-radius:5px;"
                  @enter="enter"
                >
                  <strong v-if="SuccessAlert">Successifully!</strong> <span
                    v-if="SuccessAlert"
                  >{{ SuccessMessage }}</span>
                  <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    v-if="SuccessAlert"
                    style="line-height:2px;"
                    @click="fadeMe"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    v-if="ErrorAlert"
                    style="line-height:2px;"
                    @click="fadeMe"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="col-md-12">
                  Please, provide below information to retrieve Application's Declaration
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <base-input
                          class="special-bottom"
                          label="Customer TIN"
                          type="text"
                          :value="customerTinToTesws"
                          v-model="customerTinToTesws"
                          readonly
                        />
                      </div>
                    </div>

                    <div
                      class="col-md-4"
                      style="padding-top: 15px;"
                    >
                      <div class="form-group">
                        <label>Search Year</label>
                        <model-list-select
                          :list="yearsToTesws"
                          v-model="yearToTesws"
                          class="form-control"
                          option-value="value"
                          option-text="text"
                          placeholder="Search year"
                        />
                        <div v-if="yearToTeswsError">
                          <div
                            class="error"
                            v-if="!$v.yearToTesws.required"
                          >
                            This field is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <base-input
                          class="special-bottom"
                          label="Enter Application Ref No."
                          placeholder="Enter Application Ref No."
                          type="text"
                          v-model="refNoToTesws"
                        />
                        <div v-if="refNoToTeswsError">
                          <div
                            class="error"
                            v-if="!$v.refNoToTesws.required"
                          >
                            This field is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center row">
                  <div class="col-md-6 text-right">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="saveReferencesAndPullLoader"
                    />
                  </div>
                  <div class="col-md-6 text-right">
                    <button
                      type="submit"
                      class="btn btn-primary btn-space custom-btn"
                      @click.prevent="goToDeclaration"
                    >
                      View Declaration
                    </button>
                  </div>
                </div>
              </card>
            </form>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="!thisFirstRequestLoader"
      >
        <div class="col-md-7 text-right">
          <div class="row">
            <div
              class="col-md-10 text-right pt-2"
              v-if="approvingLoader"
            >
              Please wait while system is approving this application
            </div>
            <div
              class="col-md-2 text-left pt-2"
              v-if="approvingLoader"
            >
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
              />
            </div>
          </div>
        </div>
        <div class="col-md-5 text-right">

            <!-- :class="[ approvingLoader ? 'btn-disabled' : '' ]" -->
            <!--  && singleRequest.station == null && singleRequest.transportMethod == null -->
          <button
            class="btn btn-danger btn-space custom-btn"
            data-dismiss="modal"
            @click.prevent="revokeMajorStatus(requestId, applicationStatuses.SUBMITTED)"
            v-if="singleRequest.status === applicationStatuses.REJECTED_MAJOR"
          >
            Revoke <b>HAS MAJOR ISSUE</b> Status
          </button>
          <button
            :disabled="approvingLoader"
            type="submit"
            class="btn btn-primary btn-space custom-btn"
            data-dismiss="modal"
            @click.prevent="affectSubmittedRequest(requestId, 'APPROVED')"
            v-if="!allAttachmentVerified &&
            (singleRequest.status === applicationStatuses.SUBMITTED || singleRequest.status === applicationStatuses.RETURNED || (singleRequest.status === applicationStatuses.REJECTED_MINOR
            && singleRequest.fileNo === null )) && singleRequest.station !== null && singleRequest.transportMethod !== null"
          > Accept to finish
          </button>

          <button
            class="btn btn-danger btn-space custom-btn"
            data-dismiss="modal"
            @click.prevent="goToChatRoom"
            v-if="(singleRequest.status === applicationStatuses.SUBMITTED || singleRequest.status === applicationStatuses.RETURNED)"
          > <!--  && viewAllAttachmentFlag === 0 -->
            Flag a problem
          </button>
          <button class="btn btn-primary btn-space custom-btn mr-2" @click.prevent="goToChatRoom" v-if="singleRequest.status !== applicationStatuses.SUBMITTED && singleRequest.status !== applicationStatuses.RETURNED
          && singleRequest.status !== applicationStatuses.DRAFT"><!--  && viewAllAttachmentFlag === 0 -->
              Chat room
          </button>
          <button
            class="btn btn-default btn-fill btn-space custom-btn-all"
            @click.prevent="goBack"
          > Back
          </button>
        </div>
      </div>
    </card>

    <modal v-if="showModal" :maxWidth="1800">
      <div slot="header" class="modal-title col-md-12"></div>
      <div slot="body">
        <assessment v-if="showAssementModal" @closeModal="closeModal" @setInit="setInit" :requestId="requestId" :statusProp="singleRequest.status" :regime="singleRequest.regime.regimeCode"></assessment>
        <charging v-if="charging" @closeModal="closeModal" @closeModalAndReload="closeModalAndReload" :requestId="requestId" :selectectedRequest="singleRequest"></charging>
        <additional-fields v-if="additionalFields" @closeModal="closeModal" :requestData="requestData" :disabled="disableAdditionalFields"></additional-fields>
        <assignment v-if="assignUserModal" @closeModal="closeModal" :requestId="requestId" :selectedRequest="singleRequest"></assignment>
        <activity v-if="NonMonetaryModalDiv" @closeModal="closeModal" :requestId="requestId" :selectedRequest="singleRequest"></activity>
        <application-timeline v-if="applicationTimeline" @closeModal="closeModal" :selectedRequest="singleRequest"/>

      </div>
    </modal>

    <modal-pace v-if="ShowPace">
      <div slot="header" class="modal-title col-md-12" v-if="ShowNonAndMonetaryListPlan">
        <b> Application Activities List </b>
      </div>
      <div slot="body" v-if="ShowNonAndMonetaryListPlan">
        <card>
          <data-table
            :table-headers="tableHeaders"
            :table-data="filteredRequests"
            :table-data-keys="tableDataKeys"
            :my-first-loader="myFirstLoader"
            :is-pageable="false"
            :no-data-found="noDataFound"
            :has-pagination="false"
            :has-filter="false"
            :has-manage-button="false"
            :has-view-more-assessment="false"
            :loading-title="'Please wait while the system is loading activities list for Application file number [ ' + singleRequest.fileNo + ' ]'"
            :permissions="[ {key: 'searchPermission', value: 'NOTICE_NOTICES_{REQUESTID}_GET'} ]"
            @viewDocument="openPdfViewer">
          </data-table>
          <!-- <div class="row">
            <div class="col-md-12" v-if="loadSavedNonMonetaryActivity.length > 0">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">
                    #
                  </th>
                  <th scope="col">
                    Activity Name
                  </th>
                  <th scope="col">
                    Activity Status Name
                  </th>
                  <th scope="col">
                    Activity Date
                  </th>
                  <th scope="col">
                    Activity Updated Date
                  </th>
                  <th scope="col">
                    Attachment Link
                  </th>
                  <th scope="col">
                    Activity Assigned User
                  </th>
                  <th scope="col">
                    Activity Remark
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(activity, index) in loadSavedNonMonetaryActivity" :key="activity.activityLogId">
                  <th scope="row">
                    {{ index + 1 }}
                  </th>
                  <td>
                    {{ activity.activityName }}
                  </td>
                  <td>
                    {{ activity.status }}
                  </td>
                  <td>
                    {{ dateTimeToHuman(activity.activityDate) }}
                  </td>
                  <td>
                    {{ dateTimeToHuman(activity.lastUpdateDate) }}
                  </td>
                  <td
                    v-if="activity.attachUrl !== null "
                    @click.prevent="openPdfViewer(activity.attachUrl , (activity.activityName).toUpperCase() + '_FOR_APPLICATION_WITH_FILE_NUMBER_' + singleRequest.fileNo, 'view', null, null)"
                    class="download-link"
                  >
                    click to view
                  </td>
                  <td v-else>
                    No Attachment
                  </td>
                  <td>
                    {{ activity.assignedUser }}
                  </td>
                  <td>
                    {{ activity.activityDescription }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12 text-center" v-else>
              <div class="row">
                <div class="col-md-12 text-center loader-color">
                </div>
                <div class="col-md-12 text-center">
                  <br>
                </div>
                <div class="col-md-12 text-center">
                  <pulse-loader
                    :color="color"
                    :size="size"
                  />
                </div>
              </div>
            </div>
          </div> -->
        </card>

        <br>
        <div class="text-center row">
          <div class="col-md-7 text-right loader-div"></div>
          <div class="col-md-2 left loader-div-gif">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="editMyLoader"></pulse-loader>
          </div>
          <div
            class="col-md-3"
            v-if="ShowPace"
          >
            <button
              type="button"
              class="btn btn-fill btn-outline-info float-right custom-btn-all"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>

    </modal-pace>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            {{ isRevokingMajorStatus ? 'Revoking HAS MAJOR ISSUE status, please wait' : 'Preparing requested file, Please wait' }}
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
    @downloadPdf="openPdfViewer(attachmentUrlForDownload, attachmentNameForDownload + '_FOR_APPLICATION_WITH_FILE_NUMBER_' + singleRequest.fileNo, '', null, null)"></pdf-viewer>
  </div>
</template>

<script>

import { ModelListSelect,MultiSelect } from 'vue-search-select'
import { required } from 'vuelidate/lib/validators'
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import GlobalModal from '../../../components/globalLoader'
import ModalPace from '../../../components/Inputs/ModalPlanAndCostEstimation'
import assessment from "./assessment";
import charging from "./charging";
import AdditionalFields from "./additionalFields";
import applicationTimeline from './applicationTimeline'
import { mapGetters, mapActions } from 'vuex'
import API_REVENUE from '../../../api/rev';
import API_CFA from '../../../api/cfa';
import Assignment from "./Assignment";
import Activity from "./Activity";
import PdfViewer from '../../../components/pdfViewer.vue'
import {CFA_REQUEST_STATUSE, TASAC_TIN} from "../../../mixins/constants";


export default {

  name: 'Application',

  components: {
    Activity,
    Assignment,
    AdditionalFields,
    applicationTimeline,
    PdfViewer,
    ModelListSelect,
    PulseLoader,
    Modal,
    GlobalModal,
    ModalPace,
    MultiSelect,
    assessment,
    charging,
    DataTable: () => import('../../../components/DataTable/dataTable')


  },

  created () {

    if (this.$route.params.requestId) {

      this.finalUserPermissions = JSON.parse(
      localStorage.getItem('browser-local-permits')
    )

      // this.$store.dispatch('pagenames/currentPageName', this.hyphenToSpaceString(this.$route.params.status.charAt(0).toUpperCase() + this.$route.params.status.slice(1).toLowerCase()) + ' application');
      this.$store.dispatch('pagenames/currentPageName', 'Application Details');

      this.setInit()

    }

  },

  computed: {

    ...mapGetters('global', [

      'getGlobalLoader'

    ])

  },

  data () {

    return {

      duplicateToset:false,
      duplicateComment:"",
      duplicateLoader:false,
      color: 'green',
      size: '12px',
      mySize: '9px',
      sizeVerify: '10px',
      loading: true,
      charging:false,
      showAssementModal:false,
      additionalFields:false,
      globalLoader: false,
      applicationStatus: '',
      showAppCharges: false,
      finalUserPermissions:[],
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: '',
      expectedDiff: '',
      dayMessage: '',
      SuccessAlertTop: false,
      ErrorAlertTop: false,
      SuccessMessageTop: '',
      ErrorMessageTop: '',
      scr: '',
      showPdfViewer: false,
      thisFirstRequestLoader: false,
      applicationStatuses: CFA_REQUEST_STATUSE,


      stationIdError: false,
      transportMethodIdError: false,
      updatingReceivingPoint: false,
      getStation: [],
      getTransportMethod: [],
      requestStation: '',
      requestTransportMethod: '',
      showReceivingPointForm: false,
      showDeliveryOrExitPointForm: false,
      requestId: '',
      requestDeliveryInformationLoader: false,
      isRevokingMajorStatus: false,

      rows: [
        { deliveryStationId: '', deliveryTransportMethodId: '', description: '' }
      ],
      dismissCase: [
        {name: "Duplicate", id: "DUPLICATE"},
        {name: "Order Cancellation", id: "ORDER_CANCELLATION"},
        {name: "Other", id: "OTHER"}
      ],
      selectedDismissalCase:"",
      selectedDismissalCaseError:false,
      duplicateCommentError:false,
      deliveryStationIdError: [],
      deliveryTransportMethodIdError: [],
      getReceivingInfo: [],
      updateDeliveryOrExitRequestId: '',
      updateDeliveryOrExitId: '',
      savingDeliveryOrExitPoint: false,
      verifiedHasId: [],
      responseAttachment: [],
      singleRequest: [],
      disableAdditionalFields:false,
      thisRequestLoader: false,
      allAttachmentVerified: false,
      requestData: {},

      ShowPace: false,
      ShowNonAndMonetaryListPlan: false,

      description: '',
      deliveryStationId: '',
      deliveryTransportMethodId: '',
      deliveryUpdateTransportMethodIdError: false,
      deliveryUpdateStationIdError: false,
      showUpdateDeliveryOrExitPointForm: false,
      updatingDeliveryOrExitPoint: false,
      deletingDeliveryOrExitPoint: [],
      currentUpdate: [],
      NonMonetaryModalDiv: false,
      showModal: false,
      assignUserModal: false,

      billDetails:{
        billId:null
      },
      loadSavedNonMonetaryActivity: [],
      editMyLoader: false,

      refTin: '',
      refYear: '',
      refNumber: '',
      saveReferencesAndPullLoader: false,
      yearsToTesws: [],

      refNoToTesws: '',
      yearToTesws: '',
      customerTinToTesws: TASAC_TIN,
      yearToTeswsError: false,
      refNoToTeswsError: false,

      approvingLoader: false,

      attachmentNameForDownload: '',
      attachmentUrlForDownload: '',

      finalUserPermissions: [],

      filteredRequests: [],
      noDataFound: false,
      myFirstLoader: false,
      tableHeaders: ['Activity Name', 'Activity Status Name', 'Activity Date', 'Activity Updated Date', 'Attachment Link', 'Activity Assigned User', 'Activity Remark'],
      tableDataKeys: ['activityName', 'status', 'activityDate', 'lastUpdateDate', 'attachUrl', 'assignedUser', 'activityDescription'],
      viewAllAttachmentFlag: 0,
      applicationTimeline: false

    }

  },

  validations: {

    requestStation: { required },
    requestTransportMethod: { required },
    selectedUserToReasign:{required},
    duplicateComment:{required},
    selectedDismissalCase:{required},

    activityName: { required },
    activityDescription: { required },
    statusDescription: { required },
    activityDate: { required },

    yearToTesws: { required },
    refNoToTesws: { required },
  },

  watch:{
    $route(to, from) {
      if(to !== from){
        this.setInit();
      }
    }
  },

  methods: {

    buttonClicked: function () {
      
      // this.$root.$emit('myEvent', 'new message!');
          this.$store.dispatch('global/autoRefreshingNotifications', true);
    },

    sortingData(data) {

        this.filteredRequests = []

        for(let x = 0 ; x < data.length ; x++) {

            let requestObject = {

                ...data[x],
                activityDate: this.dateTimeToHuman(data[x].activityDate),
                lastUpdateDate: this.dateTimeToHuman(data[x].lastUpdateDate)

            }

            this.filteredRequests.push(requestObject)

        }

    },

    ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    gotToAttachment () {

        let requestIdArrayProp = [{

            requestMyId: this.singleRequest.requestId,
            responseAttachment: []

        }]

        let requestBlArrayProp = [this.singleRequest.blNumber]

        let ifRequestSubmittedProp = true
        let fromCompulsaryButton = true

        this.$router.push({ name: 'TASACNewApplication', params: { requestIdArrayProp,  ifRequestSubmittedProp, requestBlArrayProp, fromCompulsaryButton} });

    },

    goToBill(){
      
      this.$router.push({name: 'ApplicationsCFABills', params: {billId: this.billDetails.billId}})

    },


    setInit(){

      this.requestId = this.$route.params.requestId;
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadStation();
      this.loadTransportMethod();
      this.loadThisRequestOnCreated(this.requestId);
      this.loadRequestDeliveryInformation(this.requestId);
      this.cookieUserId = window.$cookies.get('browser-local-permits-agent');
      this.yearsToTesws = this.autoYearGenerator(2019)
    },

    dismissApp(){
      if(this.selectedDismissalCase!=="" && this.duplicateComment!==""){
        this.duplicateLoader=true;

        this.selectedDismissalCaseError=false;
        API.dismissApplication(this.requestId,this.duplicateComment,this.selectedDismissalCase).then((resp)=>{
          this.duplicateLoader=false;
          this.duplicateToset=false;
          this.loadThisRequestOnCreated(this.requestId);
        }).catch((err)=>{
          this.duplicateLoader=false;
        })

      }else {
        this.selectedDismissalCase===""? this.selectedDismissalCaseError=true:this.selectedDismissalCaseError=false;
        this.duplicateComment===""? this.duplicateCommentError=true:this.duplicateCommentError=false;
      }

    },

    getBillDetails(data){

      this.getBillDetailsLoader=true;
      API_REVENUE.getBillByRequestId(data).then((resp)=>{

        this.getBillDetailsLoader=false;
        if(resp.data!==""){

          this.billDetails=resp.data;
        }
      }).catch(()=>{
        this.getBillDetailsLoader=false;
      });
    },

    loadParameters (fileUrl, fileName) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl);
        this.$store.dispatch('global/fileNaming', fileName);

        resolve()

      })

    },

    areAllApplicationAttachmentsViewed () {

      let flag = 0

      for (let i = 0; i < this.singleRequest.requestAttachDtos.length; i++) {

        if(!this.singleRequest.requestAttachDtos[i].isViewed) {

          flag = 1
          this.viewAllAttachmentFlag = 1

        }
        
      }

      return flag

    },

    verifyAllAttachmentAtOnce() {

      this.$dialog.confirm('You are about to verify all attachments associated with this application are you sure?', {

        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, verify all',
        cancelText: 'No, close',

      }).then((dialog) => {

        let isViewedFlag = this.areAllApplicationAttachmentsViewed()

        if(isViewedFlag === 1) {

          this.loadNotification('error', 4000, '', 'View all attachments to verify', 'response', 1000 , true  , true)

        } else {

          API_CFA.verifyAllApplicationAttachments(this.singleRequest.requestId).then(
          response => {

              if (response.data.status) {
                
                this.loadThisRequest(this.requestId);

              } else {

              }
            }
          ).catch(() => {

          })

        }

        dialog.close();

      });

    },

    readApplicationAttachment (data) {

      API_CFA.markAttachmentAsRead(data).then(
        response => {

          if (response.data.status) {
            
            this.loadThisRequest(this.requestId);

          } else {

          }
        }
      ).catch(() => {

      })
    },

    openPdfViewer (fileUrl, fileName, action, requestAttachmentId, isViewed) {

        this.attachmentNameForDownload = fileName
        this.attachmentUrlForDownload = fileUrl

        this.getFileFromMinio(fileUrl, fileName, action).then(() => {

            this.showPdfViewer = true
            if(requestAttachmentId !== null && !isViewed) {

                this.readApplicationAttachment(requestAttachmentId)

            }

        }).catch(() => {})

    },

    closePdfViewer () {

        this.showPdfViewer = false
        this.scr = ''
        this.attachmentNameForDownload = ''
        this.attachmentUrlForDownload = ''

    },

    getFileFromMinio (fileUrl, fileName, action) {

        return new Promise((resolve, reject) => {

            this.loadParameters(fileUrl, fileName).then(() => {

            this.loadFileFromMinio({action}).then((res) => {

                if(res[1].status === 204) {

                    this.loadNotification('warn', 4000, '',
                    'File not found, please contact system admin',
                    'response', 1000 , true  , true)

                } else {

                    this.scr = res[0]
                    resolve()


                }

                    this.globalLoader = this.getGlobalLoader

                }).catch(() => {

                this.globalLoader = false

                })

                this.globalLoader = this.getGlobalLoader

            }).catch(() => {

                this.globalLoader = false

            })

        })

    },

    // getFileFromMinio (fileUrl, fileName) {
    //   this.loadParameters(fileUrl, fileName).then(() => {
    //   this.loadFileFromMinio({}).then((res) => {
    //       if(res.status === 204) {
    //           this.loadNotification('warn', 4000, 'File Download',
    //           'File not found, please contact system admin',
    //           'response', 1000 , true  , true)
    //       } else {
    //         this.loadNotification('success', 4000, 'File Download',
    //           'File has been successfully downloaded, please have a look at it',
    //           'response', 1000 , true  , true)
    //       }
    //         this.globalLoader = this.getGlobalLoader
    //     }).catch(() => {
    //       this.loadNotification('error', 4000, 'File Download',
    //           'File not downloaded, please check your internet connection',
    //           'response', 1000 , true  , true);
    //       this.globalLoader = this.getGlobalLoader
    //     });
    //     this.globalLoader = this.getGlobalLoader
    //   }).catch(() => {

    //   })
    // },

    showReceivingPoint () {
      this.showReceivingPointForm = true
    },

    showUpdateReceivingPoint (stationId, transportMethodId) {
      this.showReceivingPointForm = true;
      this.requestTransportMethod = transportMethodId;
      this.requestStation = stationId
    },

    showDeliveryOrExitPoint () {

      this.showDeliveryOrExitPointForm = true
      this.showUpdateDeliveryOrExitPointForm = false;

    },

    showUpdateDeliveryOrExitPoint (deliveryId, requestId, stationId, transportMethodId, description) {
      this.currentUpdate = [];

      this.showUpdateDeliveryOrExitPointForm = true
      this.showDeliveryOrExitPointForm = false

      this.deliveryStationId = stationId;

      this.deliveryTransportMethodId = transportMethodId;

      this.description = description;

      this.updateDeliveryOrExitRequestId = requestId;

      this.updateDeliveryOrExitId = deliveryId;

      this.currentUpdate.push(deliveryId)
    },

    hideReceivingPoint () {
      this.showReceivingPointForm = false
    },

    hideDeliveryOrExitPoint () {
      this.showDeliveryOrExitPointForm = false
    },

    hideUpdateDeliveryOrExitPoint () {
      this.currentUpdate = [];

      this.showUpdateDeliveryOrExitPointForm = false;

      this.deliveryStationId = '';

      this.deliveryTransportMethodId = '';

      this.description = '';

      this.updateDeliveryOrExitRequestId = '';

      this.updateDeliveryOrExitId = ''
    },

    addRow: function () {
      this.rows.push({ deliveryStationId: '', deliveryTransportMethodId: '', description: '' })
    },
    deleteRow: function (row) {
      this.rows.splice(this.rows.indexOf(row), 1)
    },

    loadThisRequest (data) {

      this.thisRequestLoader = true;

      API.getFullRequestDetails(data).then(
        response => {

          if (response.data.status) {

            this.singleRequest = response.data.data;
            this.refTin = this.singleRequest.referenceTIN;
            this.refYear = this.singleRequest.referenceYear;
            this.refNumber = this.singleRequest.referenceSerialNo;
            this.applicationStatus = this.getApplicationStatus(this.singleRequest.status, 2)
            this.loadRequestAttachment(response.data.data.requestId);
            this.areAllApplicationAttachmentsViewed()

            this.thisRequestLoader = false

          } else {}
        }
      ).catch(() => {

        this.thisRequestLoader = false

      })
    },

    loadThisRequestOnCreated (data) {

      this.thisFirstRequestLoader = true;

      API.getFullRequestDetails(data).then(
        response => {

          if (response.data.status) {

            this.singleRequest = response.data.data;
            this.sortingData(this.singleRequest.activityLogs)
            if(response.data.data.status === this.applicationStatuses.DISMISSED){
              this.disableAdditionalFields=true;
            }

            this.getBillDetails(data);

            this.refTin = this.singleRequest.referenceTIN
            this.refYear = this.singleRequest.referenceYear
            this.refNumber = this.singleRequest.referenceSerialNo
            this.applicationStatus = this.getApplicationStatus(this.singleRequest.status, 2)
            this.loadRequestAttachment(response.data.data.requestId);
            this.areAllApplicationAttachmentsViewed()
            if(this.singleRequest.cargoExpectedDate !== null && this.singleRequest.cargoExpectedDate !== '') {this.calculateExpectedTimeLeft(this.dateToHuman(this.singleRequest.cargoExpectedDate))}

            this.thisFirstRequestLoader = false
          } else {

          }
        }
      ).catch(() => {
        this.thisFirstRequestLoader = false
      })
    },

    loadRequestAttachment (data) {

      API.getFullRequestDetails(data).then(
        response => {

          if (response.data.status) {

            this.responseAttachment = response.data.data.requestAttachDtos;

            for (let p = 0; p < this.responseAttachment.length; p++) {

              if (!this.responseAttachment[p].verified) {

                this.allAttachmentVerified = true;
                break

              } else {

                this.allAttachmentVerified = false

              }

            }

          } else {

          }
        }
      ).catch(() => {

      })
    },

    calculateExpectedTimeLeft (expectedDate) {

      // expectedDate = "02-12-2021"

      let expectedDate1 = expectedDate.split('-')
      let todayDate = new Date()

      // console.log('todayDate', todayDate.toISOString().split('T')[0])

      // console.log('today', this.$moment().format('DD-MM-YYYY'))
      // console.log('expectedDate', expectedDate)

      // console.log('this.$moment(expectedDate).format("YYYY, MM, DD")', this.$moment(expectedDate1[2] + '-' + expectedDate1[1] + '-' + expectedDate1[0]).format('YYYY, MM, DD'))
      // console.log('this.$moment().format("YYYY, MM, DD")', this.$moment(new Date()).format('YYYY, MM, DD'))

      // console.log('new Date', new Date(this.$moment(expectedDate1[2] + '-' + expectedDate1[1] + '-' + expectedDate1[0]).format('YYYY, MM, DD')))

      let expectedDateFormatted = this.$moment(new Date(this.$moment(expectedDate1[2] + '-' + expectedDate1[1] + '-' + expectedDate1[0]).format('YYYY, MM, DD')))
      let todayDateFormatted = this.$moment(new Date(this.$moment(todayDate.toISOString().split('T')[0]).format('YYYY, MM, DD')))
      // let expectedDateFormatted = this.$moment(this.$moment(new Date()).format('YYYY, MM, DD'));
      // console.log('a.diff 1', expectedDateFormatted.diff(todayDateFormatted, 'days'))

      this.expectedDiff = expectedDateFormatted.diff(todayDateFormatted, 'days')

      //{{ expectedDiff  <= 1 ? (-1*expectedDiff) === 1 ? 'Has passed by ' + (-1*expectedDiff) + ' day' : 'Has passed by ' + (-1*expectedDiff) + ' days'  :
      //expectedDiff === 1 ? expectedDiff + ' day remaining' : expectedDiff + ' days remaining' }}

      // console.log('this.expectedDiff', this.expectedDiff)

      if(this.expectedDiff < 0) {

        if((this.expectedDiff * -1) === 1) {

          this.dayMessage = 'Passed by 1 day'

        } else {

          this.dayMessage = 'Passed by '+ (this.expectedDiff * -1) + ' days'

        }

      } else if (this.expectedDiff === 0) {

        this.dayMessage = 'Today'

      } else {

        if(this.expectedDiff === 1) {

          this.dayMessage = '1 day remaining'

        } else {

          this.dayMessage = this.expectedDiff + ' days remaining'

        }

      }

    },

    verifyThisAttachment (data) {

      this.verifiedHasId.push(data);

      API.verifyAttachedAttachment(data).then((response) => {

        if (response.data.status) {

          this.loadThisRequest(this.requestId);

          this.verifiedHasId = []

        } else {

        }
      }).catch(() => {})
    },

    updateReceivingPoints (data) {
      if (this.$v.requestStation.$invalid || this.$v.requestTransportMethod.$invalid) {
        if (this.$v.requestStation.$invalid) {
          this.stationIdError = true
        }
        if (this.$v.requestTransportMethod.$invalid) {
          this.transportMethodIdError = true
        }
      } else {
        this.updatingReceivingPoint = true;

        API.updateReceivingPoint(data, this.requestStation, this.requestTransportMethod).then((response) => {
          if (response.data.status) {
            this.loadThisRequest(this.requestId);

            this.updatingReceivingPoint = false;

            this.requestStation = '';

            this.requestTransportMethod = '';

            this.showReceivingPointForm = false
          } else {
            this.updatingReceivingPoint = false
          }
        }).catch(() => {
          this.updatingReceivingPoint = false
        })
      }
    },

    saveFileNumberInformation (index) {
      if (this.rows[index].deliveryStationId === '' || this.rows[index].deliveryTransportMethodId === '') {
        if (this.rows[index].deliveryStationId === '') {
          this.deliveryStationIdError = [index]
        }

        if (this.rows[index].deliveryTransportMethodId === '') {
          this.deliveryTransportMethodIdError = [index]
        }
      } else {
        this.savingDeliveryOrExitPoint = true;

        const receivingData = {

          remarks: this.rows[index].description,
          requestId: this.requestId,
          stationId: this.rows[index].deliveryStationId,
          transportMethodId: this.rows[index].deliveryTransportMethodId

        };

        API.postRequestDeliveryInformation(JSON.stringify(receivingData)).then(
          response => {
            if (response.data.status) {
              this.savingDeliveryOrExitPoint = false;
              this.rows[index].deliveryStationId = '';
              this.rows[index].deliveryTransportMethodId = '';
              this.rows[index].description = '';
              this.loadThisRequest(this.requestId);
              this.loadRequestDeliveryInformation(this.requestId)
            } else {
              this.savingDeliveryOrExitPoint = false
            }
          }
        ).catch(() => {

        })
      }
    },

    updateFileNumberInformation () {
      if (this.deliveryStationId === '' || this.deliveryTransportMethodId === '') {
        if (this.deliveryStationId === '') {
          this.deliveryUpdateStationIdError = true
        }

        if (this.deliveryTransportMethodId === '') {
          this.deliveryUpdateTransportMethodIdError = true
        }
      } else {
        this.updatingDeliveryOrExitPoint = true;

        const receivingData = {

          remarks: this.description,
          requestId: this.updateDeliveryOrExitRequestId,
          stationId: this.deliveryStationId,
          transportMethodId: this.deliveryTransportMethodId,
          requestDeliveryId: this.updateDeliveryOrExitId

        };

        API.updateRequestReceivingPoint(JSON.stringify(receivingData)).then(
          response => {
            if (response.data.status) {
              this.updatingDeliveryOrExitPoint = false;
              this.deliveryStationId = '';
              this.deliveryTransportMethodId = '';
              this.description = '';
              this.updateDeliveryOrExitId = '';
              this.updateDeliveryOrExitRequestId = '';
              this.loadThisRequest(this.requestId);
              this.loadRequestDeliveryInformation(this.requestId);
              this.currentUpdate = []
              this.hideUpdateDeliveryOrExitPoint()
            } else {
              this.updatingDeliveryOrExitPoint = false
            }
          }
        ).catch(() => {

        })
      }
    },

    deleteFileNumberInformation (data, requestId) {

      this.deletingDeliveryOrExitPoint.push(data)

      API.deleteRequestReceivingPoint(data).then(
        response => {

          if (response.data.status) {

            this.deletingDeliveryOrExitPoint = [];
            this.loadThisRequest(requestId);
            this.loadRequestDeliveryInformation(requestId)

          } else {

            this.deletingDeliveryOrExitPoint = []

          }

        }

      ).catch(() => {})
    },

    loadRequestDeliveryInformation (data) {

      this.requestDeliveryInformationLoader = true

      API.getRequestDeliveryInformation(data).then(
        response => {

          if (response.data.status) {

            this.getReceivingInfo = response.data.data

          } else {}

          this.requestDeliveryInformationLoader = false

        }
      ).catch(() => {

        this.requestDeliveryInformationLoader = false

      })
    },

    goToChatRoom () {

      let isViewedFlag = this.areAllApplicationAttachmentsViewed()

      if(isViewedFlag === 1) {

        this.loadNotification('error', 4000, '', 'View all attachments in order to flag a problem', 'response', 1000 , true  , true)

      } else {

        document.cookie = 'browser-local-cashed-url-back-to-chat=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
        this.$router.push('/clearing-and-forwarding/conversion-room/' + this.requestId)

      }
    },

    goBack () {
      if (window.$cookies.isKey('browser-local-cashed-url-back')) {

        this.$router.push(window.$cookies.get('browser-local-cashed-url-back'));
        window.$cookies.remove('browser-local-cashed-url-back')

      } else {

        this.$router.go(-1);

      }

    },

    loadStation () {
      API.getStation().then(
        response => {
          if (response.data.status) {
            this.getStation = response.data.data
          } else {

          }
        }
      ).catch(() => {

      })
    },

    loadTransportMethod () {
      API.geTransportMethod().then(
        response => {
          if (response.data.status) {
            this.getTransportMethod = response.data.data
          } else {

          }
        }
      ).catch(() => {

      })
    },

    getAssignments (data) {
      this.assignedActivitiesPerUser = []
      this.notAssignedActivity = []
      this.optionId = []

      this.getAssignmentsLoader = true

      API.getAllAssignmentDetailedByRequest(data).then(
        response => {
          if (response.data.status) {
            this.getDetailedAssignment = response.data.data

            for (let i = 0; i < this.getDetailedAssignment.length; i++) {
              if (this.getDetailedAssignment[i].userId === parseInt(this.cookieUserId)) {
                this.assignedActivitiesPerUser.push(this.getDetailedAssignment[i]);

                this.assignedUserId = this.getDetailedAssignment[i].userId
              }
            }

            this.getAssignmentsLoader = false
            this.reaSignAction=false;
          } else {

          }
        }).catch(() => {
        this.getAssignmentsLoader = false
      });

      if (this.users !== null) {
        for (let i = 0; i < this.users.length; i++) {
          const myObject = {

            userId: this.users[i].id,
            fullName: this.users[i].firstName + ' ' + this.users[i].lastName

          };

          this.usersFull.push(myObject)
        }
      }
    },

    assignUser () {
      if (this.$v.assignedUserData.activityId.$invalid || this.$v.assignedUserData.remarks.$invalid ||
                    this.$v.assignedUserData.requestId.$invalid || this.$v.assignedUserData.statusId.$invalid ||
                    this.$v.assignedUserData.userId.$invalid) {
        if (this.$v.assignedUserData.activityId.$invalid) {
          this.isActivityId = true
        }
        if (this.$v.assignedUserData.remarks.$invalid) {
          this.isRemarks = true
        }
        if (this.$v.assignedUserData.requestId.$invalid) {
          this.isRequestId = true
        }
        if (this.$v.assignedUserData.statusId.$invalid) {
          this.isStatusId = true
        }
        if (this.$v.assignedUserData.userId.$invalid) {
          this.isUserId = true
        }
      } else {
        for (let i = 0; i < this.getDetailedAssignment.length; i++) {
          this.assignedList.push(this.getDetailedAssignment[i].activityId)

          if (this.getDetailedAssignment[i].activityId === this.assignedUserData.activityId &&
                            this.getDetailedAssignment[i].userId === this.assignedUserData.userId) {
            this.activityAlreadyAssigned = true
            this.assignedList = []
            break
          }

          if (this.getDetailedAssignment[i].activityId === this.assignedUserData.activityId) {
            this.activityAssignedName = this.getDetailedAssignment[i].activityName
            this.activityAssignedUserName = this.getDetailedAssignment[i].userName

            this.activityAlreadyAssigned = false
          }
        }

        if (!this.activityAlreadyAssigned) {
          if (this.assignedList.includes(this.assignedUserData.activityId)) {
            this.$dialog.confirm('Activity: [ ' + this.activityAssignedName + ' ] <br> has already been ' +
                                'assigned to <br> User: [ ' + this.activityAssignedUserName + ' ] <br><br> If you want to proceed use Reassign Function', {

              loader: true,
              html: true

            })
              .then((dialog) => {
                // this.myLoader = true
                //
                // this.activityAlreadyAssigned = false
                //
                // API.postAssignment(JSON.stringify(this.assignedUserData)).then(
                //   response => {
                //     if (response.data.status) {
                //       this.ErrorMessage = ''
                //       this.SuccessAlert = true
                //       this.ErrorAlert = false
                //       this.SuccessMessage = ', User Assigned to selected activity'
                //       this.myLoader = false
                //
                //       this.assignedUserData.activityId = ''
                //       this.assignedUserData.remarks = ''
                //       this.assignedUserData.statusId = ''
                //       this.assignedUserData.userId = ''
                //       this.assignedList = []
                //       this.usersfull = []
                //
                //       this.getAssignments(this.assignedUserData.requestId)
                //
                //       setTimeout(() => {
                //
                //       }, 3000)
                //     } else {
                //
                //     }
                //   }).catch(e => {
                //   this.assignedList = []
                //   this.assignedUserData.activityId = ''
                //   this.isActivityId = false
                //   this.assignedUserData.remarks = ''
                //   this.isRemarks = false
                //   this.assignedUserData.requestId = ''
                //   this.isRequestId = false
                //   this.assignedUserData.statusId = ''
                //   this.isStatusId = false
                //   this.assignedUserData.userId = ''
                //   this.isUserId = false
                //   this.myLoader = false
                // })

                dialog.close()
              })
          } else {
            this.myLoader = true

            this.activityAlreadyAssigned = false

            API.postAssignment(JSON.stringify(this.assignedUserData)).then(
              response => {
                if (response.data.status) {
                  this.ErrorMessage = ''
                  this.SuccessAlert = true
                  this.ErrorAlert = false
                  this.SuccessMessage = ', User Assigned to selected activity'
                  this.myLoader = false

                  this.assignedUserData.activityId = ''
                  this.assignedUserData.remarks = ''
                  this.assignedUserData.statusId = ''
                  this.assignedUserData.userId = ''
                  this.assignedList = []
                  this.usersfull = []

                  this.getAssignments(this.assignedUserData.requestId)

                  setTimeout(() => {

                  }, 3000)
                } else {

                }
              }).catch(() => {
              this.assignedList = []
              this.assignedUserData.activityId = ''
              this.isActivityId = false
              this.assignedUserData.remarks = ''
              this.isRemarks = false
              this.assignedUserData.requestId = ''
              this.isRequestId = false
              this.assignedUserData.statusId = ''
              this.isStatusId = false
              this.assignedUserData.userId = ''
              this.isUserId = false
              this.myLoader = false
            })
          }
        }
      }
    },

    getStatus () {
      API.getStatus().then(
        response => {
          if (response.data.status) {

            this.assignmentStatus = response.data.data;
            this.assignedUserData.statusId=4;
          } else {

          }
        }
      ).catch(e => {

      })
    },

    loadActivities () {
      API.getActivity().then(
        response => {
          if (response.data.status) {
            this.options = response.data.data
          } else {

          }
        }
      ).catch(() => {

      })
    },

    getUsers () {
      API.getUsers().then(
        response => {
          if (response.data.status) {
            this.users = response.data.data
          } else {

          }
        }).catch(() => {

      })
    },

    uploadFile () {
      this.fileData = this.$refs.file.files[0]

      this.file = this.fileData.name

      if (this.fileData.type !== 'application/pdf') {
        this.pdfFormatError = true
        this.myFlag = true
        this.fileData = ''
        this.file = ''
      } else {
        this.pdfFormatError = false
        this.myFlag = false
      }
    },

    updateTempStatus (a) {
      this.updatedAssignmentStatus = a
      this.updatedAssigment.statusId = this.updatedAssignmentStatus
    },

    assignedChanged (a) {
      for (let i = 0; i < this.getDetailedAssignment.length; i++) {
        if (this.getDetailedAssignment[i].assignmentId === a) {
          this.updatedAssignmentStatus = this.getDetailedAssignment[i].statusId
          this.updatedActivityId = this.getDetailedAssignment[i].activityId
          this.updatedAssigmentId = a
          this.updatedCreatedBy = this.getDetailedAssignment[i].createdBy
          this.updatedCreatedDate = this.getDetailedAssignment[i].createdDate
          this.updatedRemarks = this.getDetailedAssignment[i].remarks
          this.updatedRequestId = this.getDetailedAssignment[i].requestId
          this.updatedUserId = this.getDetailedAssignment[i].userId
        }
      }

      this.updatedAssigment = {

        activityId: this.updatedActivityId,
        assignmentId: this.updatedAssigmentId,
        createdBy: this.updatedCreatedBy,
        createdDate: this.updatedCreatedDate,
        remarks: this.updatedRemarks,
        requestId: this.updatedRequestId,
        statusId: this.updatedAssignmentStatus,
        userId: this.updatedUserId
      }
    },

    saveNonMonetaryActivity () {
      if (this.$v.activityName.$invalid || this.$v.activityDate.$invalid || this.$v.statusDescription.$invalid ||
                    this.$v.activityDescription.$invalid) {
        if (this.$v.activityName.$invalid) {
          this.activityNameError = true
        }
        if (this.$v.activityDescription.$invalid) {
          this.activityDescriptionError = true
        }
        if (this.$v.activityDate.$invalid) {
          this.activityDateError = true
        }
        if (this.$v.statusDescription.$invalid) {
          this.statusDescriptionError = true
        }
      } else {
        if (!this.myFlag) {
          this.pdfFormatError = false

          API.getAllAssignmentDetailedByRequest(this.requestId).then(
            response => {
              if (response.data.status) {
                for (let k = 0; k < response.data.data.length; k++) {
                  if (response.data.data[k].assignmentId === this.activityName) {
                    this.assignedActivityId = response.data.data[k].activityId

                    this.sendRequestLoader = true

                    const formData = new FormData()

                    formData.append('file', this.fileData)
                    formData.append('activityId', this.assignedActivityId)

                    if (this.activityDescription !== '') {
                      formData.append('activityDescription', this.activityDescription)
                    } else {
                      formData.append('activityDescription', '')
                    }

                    formData.append('activityDate', this.activityDate)
                    formData.append('statusId', this.statusDescription)
                    formData.append('requestId', this.requestId)
                    formData.append('userId', this.assignedUserId)

                    API.updateAssignment(JSON.stringify(this.updatedAssigment)).then(
                      response => {
                        if (response.data.status) {
                          this.assignedActivitiesPerUser = []

                          this.sendRequestLoader = false
                          this.updatedAssigment = {}
                          this.activityDescription = ''
                          this.fileData = ''
                          this.file = ''

                          API.postNonMonetaryActivity(formData).then(
                            response => {
                              if (response.data.status) {
                                this.SuccessMessage = ', Activity added to Application [ ' + this.requestName + ' ]'
                                this.SuccessAlert = true
                                this.ErrorAlert = false
                                this.sendRequestLoader = false
                                this.ErrorMessage = ''
                                this.activityName = ''
                                this.activityDate = ''
                                this.statusDescription = ''
                                this.assignedActivityId = ''
                                this.assignedUserId = ''
                                this.activityNameError = false
                                this.activityDateError = false
                                this.loadAssignedActivity()
                              } else {
                                this.ErrorMessage = ', Activity not added, please contact system administrator'
                                this.SuccessAlert = false
                                this.ErrorAlert = true
                                this.sendRequestLoader = false
                                this.SuccessMessage = ''
                                this.activityName = ''
                                this.activityDate = ''
                                this.fileData = ''
                                this.file = ''
                                this.activityNameError = false
                                this.activityDateError = false
                              }
                            }
                          ).catch(() => {

                          })

                          this.getAssignments(this.requestId)
                        } else {
                          this.sendRequestLoader = false
                        }
                      }
                    ).catch(() => {
                      this.sendRequestLoader = false
                    });

                    break
                  }
                }
              } else {

              }
            }).catch(() => {
            this.getAssignmentsLoader = false
          })
        }
      }
    },

    revokeMajorStatus (requestId, status) {

      this.$dialog.confirm('You are about to revoke "HAS MAJOR ISSUE" status to "NEW" status, are you sure?', {

        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, revoke',
        cancelText: 'No, close',

      }).then((dialog) => {

        API_CFA.updateApplicationStatus(requestId, status).then(
          response => {

            if (response.data.status) {

              this.loadThisRequest(this.requestId);

              this.isRevokingMajorStatus = false
              this.globalLoader = false
              this.loadNotification('success', 4000, '','Application has been revoked from "HAS MAJOR ISSUE" status for further processes','response', 1000 , true  , true)

            } else {

              this.isRevokingMajorStatus = false
              this.globalLoader = false
              this.loadNotification('error', 4000, '',response.data.message,'response', 1000 , true  , true)

            }
          }).catch(() => {

          this.isRevokingMajorStatus = false
          this.globalLoader = false
          this.loadNotification('error', 4000, '','Ooops something went wrong, please retry or contact system administrator','response', 1000 , true  , true)

        })

        dialog.close();

        });

    },

    affectSubmittedRequest (requestId, status) {

      let isViewedFlag = this.areAllApplicationAttachmentsViewed()

      if(isViewedFlag === 1) {

        this.loadNotification('error', 4000, '', 'View all attachments to continue', 'response', 1000 , true  , true)

      } else {

        this.approvingLoader = true;

          API.approveRequest(requestId).then(
          response => {

            if (response.data.status) {

              this.loadThisRequest(this.requestId);
              //confidRequestDeliveryInformation(this.requestId);

              this.approvingLoader = false;
              this.loadNotification('success', 4000, 'File Approval','Application has been approved for further processes','response', 1000 , true  , true)
              this.$store.dispatch('global/autoRefreshingNotifications', true);

            } else {

              this.approvingLoader = false;
              this.loadNotification('error', 4000, 'File Approval',response.data.message,'response', 1000 , true  , true)

            }
          }

        ).catch(() => {

          this.approvingLoader = false;
          this.loadNotification('error', 4000, 'File Approval','Ooops something went wrong, please retry or contact system administrator','response', 1000 , true  , true)

        })

      }
      
    },

    loadSavedNonActivity (data) {

      API.getNonMonetaryActivity(data)
        .then(response => {

          if (response.data.status) {

            this.loadSavedNonMonetaryActivity = response.data.data
            this.sortingData(this.loadSavedNonMonetaryActivity)

          } else {}

        })
        .catch(() => {})
    },

    deleteDeclaration(){

      this.$dialog.confirm('You are about to reset declaration details associated with this application, are you sure?', {

        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, reset',
        cancelText: 'No, close',

      }).then((dialog) => {

          this.saveReferencesAndPullLoader = true;

          API.deleteDeclaration(this.refNumber, this.refTin, this.refYear, this.requestId).then((response)=>{
              if (response.data.status) {
                this.setInit();
                this.saveReferencesAndPullLoader = false;
                this.loadNotification('success', 4000, 'Declaration Message',
                  'Declaration Deleted',
                  'response', 1000 , true  , true);
              }
          }).catch((e) => {
            this.saveReferencesAndPullLoader = false;
            this.loadNotification('error', 4000, 'Declaration Message',
              'Failed To Cancel Declaration',
              'response', 1000 , true  , true);
          })

        dialog.close();

      });

    },

    goToDeclaration () {
      this.ErrorMessage = '';
      this.SuccessAlert = false;
      this.ErrorAlert = false;
      this.SuccessMessage = '';

      if (this.refNumber !== null && this.refTin !== null && this.refYear !== null) {
        document.cookie = 'browser-local-cashed-url-back-to=' + this.$route.fullPath + ';Path = /;SameSite = Strict';

        this.$router.push('/clearing-and-forwarding/declaration/' + this.requestId)
      } else {
        if (this.$v.yearToTesws.$invalid) {
          this.yearToTeswsError = true
        } else if (this.$v.refNoToTesws.$invalid) {
          this.refNoToTeswsError = true
        } else {
          this.yearToTeswsError = false;
          this.refNoToTeswsError = false;

          // this.customerTinToTesws = '138486419';

          this.saveReferencesAndPullLoader = true;

          API.pullDeclarationFromTesws(this.refNoToTesws, this.customerTinToTesws, this.yearToTesws).then(
            response => {
              if (response.data.data === 'Done') {
                API.getDeclarationFromLocal(this.refNoToTesws, this.customerTinToTesws,
                  this.yearToTesws).then(
                  response => {
                    if (response.data.status) {
                      if (response.data.data !== null) {
                        API.saveThreeReferences(this.requestId, this.refNoToTesws,
                          this.customerTinToTesws, this.yearToTesws).then(
                          response => {
                            if (response.data.status) {
                              this.saveReferencesAndPullLoader = false;

                              document.cookie = 'browser-local-cashed-url-back-to=' +
                                                                    this.$route.fullPath + ';Path = /;SameSite = Strict';

                              this.$router.push('/clearing-and-forwarding/declaration/' +
                                                                    this.requestId)
                            } else {

                            }
                          }
                        ).catch(() => {
                          this.saveReferencesAndPullLoader = false
                        })
                      } else {
                        this.saveReferencesAndPullLoader = false;

                        this.loadNotification('error', 4000, 'Declaration Message',
                        'No Declaration details found for this Application',
                        'response', 1000 , true  , true);
                      }
                    } else {

                    }
                  }
                ).catch(() => {
                  this.saveReferencesAndPullLoader = false
                })
              } else {

                this.saveReferencesAndPullLoader = false;

                this.loadNotification('error', 4000, 'Declaration Message',
                response.data.message,
                'response', 1000 , true  , true);

              }
            }
          ).catch(() => {
            this.saveReferencesAndPullLoader = false
          })
        }
      }
    },

    goToNoticelist () {
      this.saveReferencesAndPullLoader = true;

      API.getNoticeListLocal(this.requestId).then(
        response => {
          if (response.data.status && response.data.data !== []) {
            document.cookie = 'browser-local-cashed-url-back-to=' +
                                this.$route.fullPath + ';Path = /;SameSite = Strict';

            this.$router.push('/clearing-and-forwarding/notice/list/' +
                                this.requestId)
          } else {
            this.saveReferencesAndPullLoader = false;
            this.loadNotification('error', 4000, '',
            typeof response.data.data !== 'undefined' ? response.data.data : response.data.message,
            'response', 1000 , true  , true);
          }
        }
      ).catch(() => {

        this.saveReferencesAndPullLoader = false
        this.loadNotification('error', 4000, '',
            'No Notice List found for this Application',
            'response', 1000 , true  , true);

      })
    },

    enter: function (el, done) {
      const that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    openModal (requestId, modalFlag) {

      if (modalFlag === 'STAFF_ASSIGNMENT') {

        this.showModal = true;
        this.assignUserModal = true;

      } else if (modalFlag === 'UPDATE_ASSIGNED_ACTIVITY') {

        this.showModal = true;
        this.NonMonetaryModalDiv = true;

      } else if (modalFlag === 'APPLICATION_ACTIVITY_LIST') {

        this.ShowNonAndMonetaryListPlan = true;
        this.ShowPace = true;
        this.loadSavedNonActivity(requestId)

      } else if (modalFlag === 'createChargeModal') {

        this.showModal = true;

      } else if(modalFlag === 'ASSESSMENT_NOTICE'){

        this.showModal = true;
        this.showAssementModal = true;

      }else if(modalFlag === 'CHARGING'){

        this.showModal = true;
        this.charging = true;

      }else if(modalFlag==='ADDITIONAL_FIELDS'){

        this.showModal = true;
        this.additionalFields = true;
        this.requestData = requestId

      } else if (modalFlag === 'APPLICATION_TIMELINE') {

        this.applicationTimeline = true
        this.showModal = true;

      }else {

      }

    },

    closeModalAndReload () {

        this.loadThisRequestOnCreated(this.requestId)
        this.assignUserModal= false;
        this.showModal = false;
        this.NonMonetaryModalDiv=false;
        this.assignUserModal = false;
        this.ShowNonAndMonetaryListPlan = false;
        this.ShowPace = false;
        this.pdfFormatError = false;
        this.showAssementModal=false;
        this.charging=false;
        this.additionalFields=false;
        this.applicationTimeline = false

    },

    closeModal () {

      // this.updatedCreatedBy = '';
      // this.updatedCreatedDate = '';
      // this.updatedRequestId = '';
      // this.updatedUserId = '';
      // if(charging !== null){

      //   this.loadThisRequestOnCreated(this.requestId)

      // }

      this.assignUserModal= false;
      this.showModal = false;
      this.NonMonetaryModalDiv=false;
      this.assignUserModal = false;
      this.ShowNonAndMonetaryListPlan = false;
      this.ShowPace = false;
      this.pdfFormatError = false;
      this.showAssementModal=false;
      this.charging=false;
      this.additionalFields=false;
      this.applicationTimeline = false
    },

    gotToApplication () {

        document.cookie = 'browser-local-cashed-url-back-7=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
        this.$router.push({name: 'TASACEditApplication', params: {requestId: this.singleRequest.requestId}}) //this.$route.params.status

    }

  }

}
</script>

<style scoped>

    /* .blink-me {

      animation: blinker 1s step-start infinite;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    } */

    .blink-me {
      animation: blink 3s infinite;
    }
    @keyframes blink
    {
      0% { opacity: 1.0; }
      50% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .notice-table-label {
        width: 250px;
        background-color: #99badd;
        font-weight: normal;
    }

    .custom-table, .custom-table th, .custom-table td {
        border: 1px solid #DCDCDC;
        border-collapse: collapse;
    }

    .custom-table th, .custom-table td {
        padding: 8px;
        text-align: left;
    }

    .add-receiving-point , .add-delivery-or-exit-point {
        color: green;
    }

    .remove-delivery-or-exit-point {
        color: red;
        margin-top: 35px;
    }

    .remove-delivery-or-exit-point-in-table {
        color: red;
    }

    .add-receiving-point:hover, .add-delivery-or-exit-point:hover, .remove-delivery-or-exit-point:hover, .remove-delivery-or-exit-point-in-table:hover {
        color: #d59a18;
        cursor: pointer;
    }

    .row-receiving-point {
        padding: 0 30px 0 30px;
    }

    .text-view {
        color: green;
    }

    .text-view:hover {
        counter-reset: blue;
        cursor: pointer;
    }

    .current-update {
        background-color: #dcdcdc !important;
    }

    .btn-space {
        margin-right: 10px !important;
    }

    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    .text-link {
        color: blue;
    }

    .text-link:hover {
        color: #d59a18;
        cursor: pointer;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    .fileUpload {
        position: relative;
        overflow: hidden;
        margin: 0.5em 5px;
        font-size: 1em;
        font-weight: 700;
        letter-spacing: 0.02em;
        line-height: 1.26;
        text-decoration: none;
        top: 16px;
        font-weight: normal;
    }

    .fileUpload input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .fileUpload span {
        cursor: pointer;
    }

    label {
        position: relative;
        top: -7px;
        right: 0;
        margin: 0;
        padding: 0;
    }

    #label-upload {
        position: relative;
        top: 16px;
    }

    .btn-disabled {
        background-color: #DCDCDC;
        cursor: not-allowed;
        color: #696969;
        padding: 10px 20px;
        border: none;
        width: 100%;
        opacity: 0.9;
        min-height: 55px;
        max-height: 55px;
    }

    .btn-disabled:hover {
        background-color: #DCDCDC;
        cursor: not-allowed;
        color: #696969;
        padding: 10px 20px;
        border: none;
        width: 100%;
        opacity: 1;
        min-height: 55px;
        max-height: 55px;
    }

    .application-status {
        /*background-color: #ededee;*/
        border-radius: 3px;
        text-transform: uppercase;
        font-size: 12px;
    }

    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 78%;
    }

    .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

    .tooltipe:hover .tooltiptext {
        visibility: visible;
        cursor: help;
    }

    .tooltiptext2 {
        visibility: hidden;
        width: 130px;
        background-color: black;
        color: #fff;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 78%;
    }

    .tooltiptext2::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

    .tooltipe:hover .tooltiptext2 {
        visibility: visible;
        cursor: help;
    }

    .div-table-row {
        padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }

    .insert-bl {
        margin-top: 36px;
        color: forestgreen;
        font-weight: bold;
    }

    .insert-bl .save-bl:hover {
        cursor: pointer;
    }

    .custom-btn-top {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }


    .custom-btn-all {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }

    .btn-blue:hover {
        border-color: #0000ff;
        color: #0000ff;
        background-color: #ffffff;
    }

    .btn-blue {
        border-color: #4169e1;
        color: #4169e1;
    }

    .btn-blue-fill {
        background-color: #4169e1;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-purple:hover {
        border-color: #800080;
        color: #800080;
        background-color: #ffffff;
    }

    .btn-purple {
        border-color: #9932cc;
        color: #9932cc;
    }

    .btn-purple-fill {
        background-color: #9932cc;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-dark-grey:hover {
        border-color: #008B8B;
        color: #008B8B;
        background-color: #ffffff;
    }

    .btn-dark-grey {
        border-color: #29c7c7;
        color: #29c7c7;
    }

    .btn-dark-grey-fill {
        background-color: #29c7c7;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-linen:hover {
        border-color: #D2691E;
        color: #D2691E;
        background-color: #ffffff;
    }

    .btn-linen {
        border-color: rgba(210, 105, 30, .5);
        color: rgba(210, 105, 30, .5);
    }

    .btn-linen-fill {
        background-color: rgb(210, 105, 30);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-grey:hover {
        border-color: #dcdcdc;
        color: #dcdcdc;
        background-color: #ffffff;
    }

    .btn-grey {
        border-color: rgba(93, 90, 90, .5);
        color: rgba(93, 90, 90, .5);
    }

    .btn-grey-fill {
        background-color: rgb(93, 90, 90);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-rosy-brown:hover {
        border-color: #775b5b;
        color: #775b5b;
        background-color: #ffffff;
    }

    .btn-rosy-brown {
        border-color: rgba(119,91,91, .5);
        color: rgba(119,91,91, .5);
    }

    .btn-rosy-brown-fill {
        background-color: rgb(119,91,91);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-khaki:hover {
        border-color: #989259;
        color: #989259;
        background-color: #ffffff;
    }

    .btn-khaki {
        border-color: rgba(152,146,89, .5);
        color: rgba(152,146,89, .5);
    }

    .btn-khaki-fill {
        background-color: rgb(152,146,89);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-dark-blue-like:hover {
        border-color:  #154360 ;
        color:  #154360 ;
        background-color: #ffffff;
    }

    .btn-dark-blue-like {
        border-color: rgba( 21, 67, 96 , .5);
        color: rgba( 21, 67, 96 , .5);
    }

    .btn-dark-blue-like-fill {
        background-color: rgb( 21, 67, 96 );
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-olive:hover {
        border-color: #4B0082;
        color: #4B0082;
        background-color: #ffffff;
    }

    .btn-olive {
        border-color: #6A5ACD;
        color: #6A5ACD;
    }

    .btn-olive-fill {
        background-color: #6A5ACD;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-success-custom:hover {
        border-color: #008000;
        color: #008000;
        background-color: #ffffff;
    }

    .btn-success-custom {
        border-color: rgba(0, 128, 0, .5);
        color: rgba(0, 128, 0, .5);
    }

    .btn-success-custom-fill {
        background-color: rgba(0, 128, 0, .8);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-danger-custom:hover {
        border-color: red;
        color: red;
        background-color: #ffffff;
    }

    .btn-danger-custom {
        border-color: rgba(255, 0, 0, .5);
        color: rgba(255, 0, 0, .5);
    }

    .btn-danger-custom-fill {
        background-color: rgba(255, 0, 0, .8);
        opacity: 1;
        color: #FFFFFF;
    }

    .alert-success {
        padding-top:10px;
        padding-bottom: 10px;
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    .alert-danger {
        padding-top:10px;
        padding-bottom: 10px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

  .error{
    color: red;
  }

    .custom-btn {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      padding: 5px 9px 5px 9px !important;
      font-size: 14px !important;
      width: auto;
    }

    .btn-space {
      margin-right: 10px !important;
    }

</style>
<style>

.title-color {
  color: #d59a18 !important;
}

.download-link {
  color: green;
  font-weight: bold;
}

.download-link:hover {
  cursor: pointer;
  color: #d59a18 !important;
}
</style>
