<template>
  <card>
    <div class="row">
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span
          v-if="SuccessAlert"
        >{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <br>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <model-list-select
          :list="commonCodeType"
          v-model="selectedCommonCode"
          class="form-control"
          option-value="commonCodeType"
          option-text="commonCodeType"
          placeholder="Common Code Type"
        />
      </div>

      <div class="col-md-3">
        <button
          type="submit"
          class="btn btn-primary btn-space"
          @click="updateCommonCode"
        >
          Update
        </button>
      </div>

      <div class="col-md-3">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        />
      </div>
    </div>
  </card>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import API from '../../../api/index'

export default {
  name: 'CommonCode',

  components: {
    ModelListSelect,
    PulseLoader
  },

  created () {
    this.getCommonCodeListTypes()
  },

  data () {
    return {
      commonCodeType: [],
      selectedCommonCode: '',
      color: 'green',
      size: '15px',
      loading: false,

      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: ''

    }
  },

  methods: {

    getCommonCodeListTypes () {
      API.getCommonCodeListTypes().then((response) => {
        response.data.data.map((data) => {
          this.commonCodeType.push({ commonCodeType: data })
        })
      }).catch(() => {
        this.ErrorMessage = ' Common Code types not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.loading = false
      })
    },

    updateCommonCode () {
      this.loading = true

      if (this.selectedCommonCode === '') {
        this.ErrorMessage = 'Pleas select Code'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.loading = false
      } else {
        API.updateCommonCode(this.selectedCommonCode).then((response) => {
          this.loading = false
          this.ErrorMessage = ''
          this.SuccessAlert = true
          this.ErrorAlert = false
          this.SuccessMessage = ', Updated ' + response.data.data
          this.SuccessAlert = true
        }).catch(() => {
          this.ErrorMessage = ', Record not updated'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.loading = false
        })
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    }
  }
}
</script>

<style scoped>

</style>
