<template>
  <div class="content">
    <card>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="text-left">
              List Of Added Tancis Vessel Documents
            </div>
          </div>
          <div
            class="col-md-4 text-center"
            v-if="!mailSent"
          >
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
          <div
            class="col-md-4"
            v-if="!mailSent"
          />
          <div
            class="col-md-8"
            v-if="mailSent"
          >
            <div class="text-success">
              {{ mailSuccess }}
            </div>
          </div>
        </div>
      </div>
      <br>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              TPA Call ID
            </th>
            <th scope="col">
              Carrier Name
            </th>
            <th scope="col">
              Discrepancies
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(document, index) in getDocument"
            :key="document.documentId"
          >
            <td scope="row">
              {{ index + 1 }}
            </td>
            <td>{{ document.tpaCallId }}</td>
            <td scope="col">
              {{ document.carrierName }}
            </td>
            <td
              scope="col"
              :class="[ document.discrepancyCount > 0 ? 'text-danger' : 'text-success' ]"
            >
              {{ document.discrepancyCount }}
            </td>
            <td class="special-limit">
              <router-link
                class="all-fa-icons fa-icons-space envelop-fa-icon"
                title="Discrepancies"
                :to="{ name: 'ExportReconciliation', params: { TPACallId: document.tpaCallId } }"
                v-if="document.discrepancyCount > 0 &&
                  finalUserPermissions.includes('TANCIS_EXPORT_RECONCILIATION_VIEW_SINGLE')"
              >
                <font-awesome-icon icon="file-contract" />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-12">
        <hr>
        <div class="row">
          <div
            class="col-md-12"
            v-if="totalPages > 1"
          >
            <div class="row">
              <div class="col-md-3">
                Showing page : <b>{{ pageNumber + 1 }} </b> of {{ totalPages }} total Elements {{ totalElements }}
              </div>

              <div
                class="col-md-9 text-right disable-highlight"
                unselectable="on"
                style="line-height: 5px;font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNumber === 0 ? 'disabled-click' :
                    'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNumber === 0 ? 'disabled-click' :
                    'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.totalPages - 1 === this.pageNumber ?
                    'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />
                </span>
                <span
                  class="btn-space"
                  :class="[ this.totalPages - 1 === this.pageNumber ?
                    'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="totalPages === 1"
          >
            Showing page <b>1 </b> of 1  total Elements {{ totalElements }}
          </div>
        </div>
      </div>
    </card>
    <!--    </div>-->
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <!--   <div class="col-md-12" v-if="EditModalDiv">
              Edit Voyage Number <br/>
              [ Document:<b>{{ documentTypeName }}</b> ]
              [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">[ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
            </div> -->
          <div
            class="col-md-12"
            v-if="SendEmailModalDiv"
          >
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
            <br><br>
            <span>You are about to send above document download link to <b>{{ companyEmail }}</b>
              onbehalf of company <b>{{ companyName }}</b>, are you sure?.</span>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>
    </modal>

    <modal-manifest v-if="showModalManifest">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div class="col-md-12 text-center">
            MANIFEST AND ASSOCIATED BILL OF LADING DETAILS
          </div>
          <div class="col-md-12">
            <hr>
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
          </div>
          <div class="col-md-12 text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="BLLoader"
            />
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">
                      #
                    </th>
                    <th scope="col">
                      B/L No.
                    </th>
                    <th scope="col">
                      B/L Type
                    </th>
                    <th scope="col">
                      Container No.
                    </th>
                    <th scope="col">
                      Container Size
                    </th>
                    <th scope="col">
                      Container Type
                    </th>
                    <th scope="col">
                      Destination place
                    </th>
                    <th scope="col">
                      Gross Volume
                    </th>
                    <th scope="col">
                      Gross Weight
                    </th>
                    <th scope="col">
                      Marks No.
                    </th>
                    <th scope="col">
                      Operation Type
                    </th>
                    <th scope="col">
                      Seal No.
                    </th>
                    <th scope="col">
                      Tonnage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bl, index) in getDocumentBL"
                    :key="bl.cargoOriginalId"
                  >
                    <td scope="row">
                      {{ index + 1 }}
                    </td>
                    <td>{{ bl.billOfLadingNo }}</td>
                    <td>{{ bl.billOfLadingType }}</td>
                    <td>{{ bl.containerNo }}</td>
                    <td>{{ bl.containerSize }}</td>
                    <td>{{ bl.containerType }}</td>
                    <td>{{ bl.destinationPlace }}</td>
                    <td>{{ bl.grossVolume }} ({{ bl.grossVolumeUnit }})</td>
                    <td>{{ bl.grossWeight }} ({{ bl.grossWeightUnit }})</td>
                    <td>{{ bl.marksNumber }}</td>
                    <td>{{ bl.operationType }}</td>
                    <td>{{ bl.sealNo }}</td>
                    <td>{{ bl.tonnage }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <hr>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div" />
              <div class="col-md-2 left loader-div-gif" />
              <div
                class="col-md-3"
                v-if="ShowModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal-manifest>
  </div>
</template>
<script>

import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import ModalManifest from '../../../components/Inputs/ModalManifest'
import Modal from '../../../components/Inputs/Modal'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {

  name: 'AddTancisExtractedCargoManifest',

  data () {
    return {
      pageNumber: 0,
      pageSize: 15,
      sortBy: '',
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      totalPages: 0,
      totalElements: 0,
      last: false,
      first: false,
      offset: 0,

      file: '',
      options: [],
      errors: [],
      array: {},
      fileData: '',
      UploadMessage: '',
      documentTypeId: '',
      getVessel: [],
      getVesselSchedule: [],
      getDocument: [],
      getDocumentBL: [],
      finalUserPermissions: [],
      permissionGroups: [],

      documentToDocument: {
        documentType: {
          documentTypeId: ''
        },
        vesselSchedule: {

          vesselScheduleId: 99809

        }
      },

      documentId: '',
      voyageNo: '',
      documentTypeName: '',
      controlNumber: '',
      vesselName: '',
      companyEmail: '',
      companyName: '',
      vesselScheduleId: '',
      documentUrl: '',

      color: 'green',
      size: '15px',
      loading: true,

      ManifestLoader: false,
      ManifestFinishLoader: false,
      myLoader: false,
      BLLoader: false,
      sendMyLoader: false,
      editMyLoader: '',
      emailAddress: '',
      mailSuccess: '',

      showModal: false,
      EditModalDiv: false,
      ShowModalDiv: false,
      SendEmailModalDiv: false,
      showModalManifest: false,
      mailSent: false,

      docTypeError: false,
      vesselScheduError: false

    }
  },

  validations: {

    documentToDocument: {
      documentType: {
        documentTypeId: {
          required
        }
      },
      vesselSchedule: {
        vesselScheduleId: {
          required
        }
      }
    }

  },

  components: {

    PulseLoader,
    Modal,
    'modal-manifest': ModalManifest

  },

  computed: {

    ...mapGetters([

      'getDocumentVoyageNumberBeforeEdit'

    ])

  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadDocument(this.pageNumber, this.pageSize)
  },

  methods: {

    loadDocument (pageNum, pageSizePassed) {
      this.myloader = true

      API.getTancisExportDocuments(pageNum, pageSizePassed).then(
        response => {
          if (response.data.status) {
            this.getDocument = response.data.data.content
            this.pageNumber = response.data.data.pageable.pageNumber
            this.pageSize = response.data.data.pageable.pageSize
            this.offset = response.data.data.pageable.offset
            this.totalPages = response.data.data.totalPages
            this.totalElements = response.data.data.totalElements
            this.first = response.data.data.first
            this.last = response.data.data.last
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {

      })
    },

    uploadFile (event) {
      this.fileData = event.target.files[0]

      this.file = this.fileData.name

      this.ManifestLoader = true

      const formData = new FormData()

      formData.append('file', this.fileData)

      setTimeout(() => {
        API.uploadTancisDocument(formData).then(
          response => {
            if (response.data.status) {
              this.ManifestLoader = false
              this.ManifestFinishLoader = true
              this.loadDocument(0, 0)
              this.file = ''
              this.docTypeError = false
              this.vesselScheduError = false
              setTimeout(() => {
                this.ManifestFinishLoader = false
              }, 7000)
            } else {

            }
          }
        ).catch(e => {

        })
      }, 2500)
    },
    onChange (event) {
      this.pageSize = event.target.value
      this.pageNumber = 0
      this.loadDocument(this.pageNo, this.pageSize)
    },

    nextPage () {
      this.pageNumber++
      this.loadDocument(this.pageNumber, this.pageSize)
    },

    prevPage () {
      this.pageNumber--

      this.loadDocument(this.pageNumber, this.pageSize)
    },

    firstPage () {
      this.loadDocument(0, this.pageSize)
    },

    lastPage () {
      this.loadDocument(this.totalPages - 1, this.pageSize)
    },

    openModal (documentId = null, docUrl = null, documentTypeName = null, documentTypeId = null, vesselScheduleId = null, controlNumber = null, vesselName = null, voyageNo = null, comEmail = null, comName = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.SendEmailModalDiv = false
        this.ShowModalDiv = false
        this.showModalManifest = false
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
        this.documentTypeId = documentTypeId
        this.vesselScheduleId = vesselScheduleId
        this.documentUrl = docUrl

        this.$store.dispatch('commitDocumentVoyageNumberBeforeEdit', this.voyageNo)
      } else if (actionButton === 'SendEmailModal') {
        this.EditModalDiv = false
        this.SendEmailModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.showModalManifest = false
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
        this.companyEmail = comEmail
        this.companyName = comName
      } else {
        this.loadSingleDocument(documentId)
        this.EditModalDiv = false
        this.SendEmailModalDiv = false
        this.ShowModalDiv = true
        this.showModal = false
        this.showModalManifest = true
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.SendEmailModalDiv = false
      this.showModalManifest = false
    }

  }

}
</script>
<style scoped>
  .disabled-click {
    pointer-events:none;
    color:#C0C0C0;
  }
  .enabled-click {
    cursor: pointer;
  }
  .disable-highlight {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
  }
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }
  .btn-space {
    margin-right: 10px !important;
  }

  .loader-div-gif {
    line-height: 34px;
  }

  .loader-div-gif-email {
    line-height: 54px;
  }

  .loader-div-email {
    line-height: 45px;
  }

  .special-limit {
    max-width: 170px !important;
    min-width: 150px !important;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 16px;
    font-weight: normal;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  #label-upload {
    position: relative;
    top: 16px;
  }

  /*.modal-dialog {*/
  /*  max-width: 1670px !important;*/
  /*}*/

  /*.is-invalid {*/
  /*  border: 1px solid #dc3545;*/
  /*  border-radius: 4px;*/
  /*  transition: border 300ms linear;*/
  /*}*/

</style>
<style>
  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }
</style>
