<template>
  <div class="content">
    <card v-if="finalUserPermissions.includes('CARGO_DOC_UPLOAD')">
      <div>Add Cargo Document</div>
      <br>
      <form
        method="post"
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-3">
            <label>Search document type</label>

            <model-list-select
              :list="options"
              v-model="documentToDocument.documentType.documentTypeId"
              class="form-control"
              option-value="documentTypeId"
              option-text="documentTypeName"
              placeholder="document type"
            />

            <div v-if="docTypeError">
              <div
                class="error"
                v-if="!$v.documentToDocument.documentType.documentTypeId.required"
              >
                Select document type
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <label>Search Voyage Number</label>

            <model-list-select
              :list="getVesselSchedule"
              v-model="documentToDocument.vesselSchedule.vesselScheduleId"
              class="form-control"
              option-value="vesselScheduleId"
              option-text="voyageNo"
              placeholder="attachment type"
            />

            <div v-if="vesselScheduError">
              <div
                class="error"
                v-if="!$v.documentToDocument.vesselSchedule.vesselScheduleId.required"
              >
                Select voyage number
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="fileUpload btn btn-info btn-fill">
              <span>Choose Cargo Document..</span>
              <input
                type="file"
                name="attachment"
                label="Upload File"
                ref="file"
                class="upload"
                id="first_upload"
                @change.prevent="uploadFile"
              >
            </div>
            <label
              for="first_upload"
              id="label-upload"
            >
              {{ file ? 'Selected File: ' + '" ' + file + ' "' : 'No File Selected' }} </label>
          </div>
        </div>
        <div class="text-center">
          <div class="row">
            <span
              class="text-right col-md-6"
              v-if="ManifestLoader"
            >
              Uploading your {{ file ? '" ' + file + ' "' : 'Document' }}</span>
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="ManifestLoader"
              class="text-left col-md-6 loader-div-gif"
            />
            <span
              class="col-md-12 loader-div-gif text-center text-success"
              v-if="ManifestFinishLoader"
            >
              Upload finished successifully</span>
          </div>
        </div>
      </form>
    </card>
    <card>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="text-left">
              List Of Added Cargo Documents
            </div>
          </div>
          <div
            class="col-md-4 text-center"
            v-if="!mailSent"
          >
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
          <div
            class="col-md-4"
            v-if="!mailSent"
          />
          <div
            class="col-md-8"
            v-if="mailSent"
          >
            <div class="text-success">
              {{ mailSuccess }}
            </div>
          </div>
        </div>
      </div>
      <br>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Document Type Name
            </th>
            <th scope="col">
              Control Number
            </th>
            <th scope="col">
              Voyage Number
            </th>
            <th scope="col">
              Vessel name
            </th>
            <th scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(document, index) in getDocument"
            :key="document.documentId"
          >
            <td scope="row">
              {{ index + 1 }}
            </td>
            <td>
              {{ document.documentType.documentTypeName }}
            </td>
            <td
              scope="col"
              v-if="document.controlNumber != null"
            >
              {{ document.controlNumber }}
            </td>
            <td
              scope="col"
              v-else=""
              class="text-muted"
            >
              <i>Not Applicable</i>
            </td>
            <td scope="col">
              {{ document.vesselSchedule.voyageNo }}
            </td>
            <td scope="col">
              {{ document.vessel.vesselName }}
            </td>
            <td>
              <div
                class=" all-fa-icons edit-fa-icon"
                @click.prevent="openModal(document.documentId ,
                                          document.documentUrl , document.documentType.documentTypeName , document.documentType.documentTypeId ,
                                          document.vesselSchedule.vesselScheduleId , document.controlNumber , document.vessel.vesselName ,
                                          document.vesselSchedule.voyageNo , null , null , actionButton = 'EditModal')"
                title="edit"
                v-if="finalUserPermissions.includes('CARGO_DOC_UPDATE')"
              >
                <font-awesome-icon icon="edit" />
              </div>

              <div
                class="all-fa-icons fa-icons-space envelop-fa-icon"
                @click.prevent="openModal(document.documentId , null ,
                                          document.documentType.documentTypeName , null , null , document.controlNumber ,
                                          document.vessel.vesselName , document.vesselSchedule.voyageNo , document.company.email ,
                                          document.company.companyName , actionButton = 'SendEmailModal')"
                title="send file via mail"
                v-if="finalUserPermissions.includes('CARGO_DOC_SEND_MAIL')"
              >
                <font-awesome-icon icon="envelope" />
              </div>

              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                @click.prevent="openModal(document.documentId , null ,
                                          document.documentType.documentTypeName , null , null , document.controlNumber ,
                                          document.vessel.vesselName , document.vesselSchedule.voyageNo , document.company.companyName ,
                                          null , actionButton = 'ShowModal')"
                title="view"
                v-if="finalUserPermissions.includes('CARGO_DOC_VIEW_SINGLE')
                  && document.controlNumber != null"
              >
                <font-awesome-icon icon="eye" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </card>
    <!--    </div>-->
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Voyage Number <br>
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
          </div>
          <div
            class="col-md-12"
            v-if="SendEmailModalDiv"
          >
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
            <br><br>
            <span>You are about to send above document download link to <b>{{ companyEmail }}</b>
              onbehalf of company <b>{{ companyName }}</b>, are you sure?.</span>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--    SEND MAIL      -->
          <form
            method="POST"
            @submit.prevent="sendMail(documentId)"
            v-if="SendEmailModalDiv"
          >
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div-email">
                <span v-if="sendMyLoader">Sending your email ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif-email">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="sendMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="SendEmailModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space float-right"
                  data-dismiss="modal"
                >
                  Send
                </button>
              </div>
            </div>
          </form>

          <!--    EDIT VOYAGE NUMBER      -->
          <form
            method="POST"
            @submit.prevent="updateVoyageNumber"
            v-if="EditModalDiv"
          >
            <DIV class="row">
              <div class="col-md-12">
                <label>Select Voyage Number</label>
                <select
                  class="form-control"
                  v-model="vesselScheduleId"
                >
                  <option
                    value=""
                    select="selected"
                  >
                    Select voyage number
                  </option>
                  <option
                    v-for="(vessel, index) in getVesselSchedule"
                    :value="vessel.vesselScheduleId"
                  >
                    {{ vessel.voyageNo }}
                  </option>
                </select>
              </div>
            </DIV>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div-email">
                <span v-if="editMyLoader">Please wait sending your email ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif-email">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>

    <modal-manifest v-if="showModalManifest">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div class="col-md-12 text-center">
            MANIFEST AND ASSOCIATED BILL OF LADING DETAILS
          </div>
          <div class="col-md-12">
            <hr>
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            [ Document:<b>{{ documentTypeName }}</b> ]
            [ <span> Vessel Name: <b>{{ vesselName }}</b></span> ]  <span v-if="controlNumber != null">
              [ Control Number: <b>{{ controlNumber }}</b>  ]</span> [ Voyage: <b>{{ voyageNo }}</b> ]
          </div>
          <div class="col-md-12 text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="BLLoader"
            />
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">
                      #
                    </th>
                    <th scope="col">
                      B/L No.
                    </th>
                    <th scope="col">
                      B/L Type
                    </th>
                    <th scope="col">
                      Container No.
                    </th>
                    <th scope="col">
                      Container Size
                    </th>
                    <th scope="col">
                      Container Type
                    </th>
                    <th scope="col">
                      Destination place
                    </th>
                    <th scope="col">
                      Gross Volume
                    </th>
                    <th scope="col">
                      Gross Weight
                    </th>
                    <th scope="col">
                      Marks No.
                    </th>
                    <th scope="col">
                      Operation Type
                    </th>
                    <th scope="col">
                      Seal No.
                    </th>
                    <th scope="col">
                      Tonnage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bl, index) in getDocumentBL"
                    :key="bl.cargoOriginalId"
                  >
                    <td scope="row">
                      {{ index + 1 }}
                    </td>
                    <td>{{ bl.billOfLadingNo }}</td>
                    <td>{{ bl.billOfLadingType }}</td>
                    <td>{{ bl.containerNo }}</td>
                    <td>{{ bl.containerSize }}</td>
                    <td>{{ bl.containerType }}</td>
                    <td>{{ bl.destinationPlace }}</td>
                    <td>{{ bl.grossVolume }} ({{ bl.grossVolumeUnit }})</td>
                    <td>{{ bl.grossWeight }} ({{ bl.grossWeightUnit }})</td>
                    <td>{{ bl.marksNumber }}</td>
                    <td>{{ bl.operationType }}</td>
                    <td>{{ bl.sealNo }}</td>
                    <td>{{ bl.tonnage }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div" />
              <div class="col-md-2 left loader-div-gif" />
              <div
                class="col-md-3"
                v-if="ShowModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal-manifest>
  </div>
</template>
<script>

import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import ModalManifest from '../../../components/Inputs/ModalManifest'
import Modal from '../../../components/Inputs/Modal'
import { ModelListSelect } from 'vue-search-select'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {

  data () {
    return {

      file: '',
      options: [],
      errors: [],
      array: {},
      fileData: '',
      UploadMessage: '',
      documentTypeId: '',
      getVessel: [],
      getVesselSchedule: [],
      getDocument: [],
      getDocumentBL: [],
      finalUserPermissions: [],
      permissionGroups: [],

      documentToDocument: {
        documentType: {
          documentTypeId: ''
        },
        vesselSchedule: {
          vesselScheduleId: ''
        }
      },

      documentId: '',
      voyageNo: '',
      documentTypeName: '',
      controlNumber: '',
      vesselName: '',
      companyEmail: '',
      companyName: '',
      vesselScheduleId: '',
      documentUrl: '',

      color: 'green',
      size: '15px',
      loading: true,

      ManifestLoader: false,
      ManifestFinishLoader: false,
      myLoader: false,
      BLLoader: false,
      sendMyLoader: false,
      editMyLoader: '',
      emailAddress: '',
      mailSuccess: '',

      showModal: false,
      EditModalDiv: false,
      ShowModalDiv: false,
      SendEmailModalDiv: false,
      showModalManifest: false,
      mailSent: false,

      docTypeError: false,
      vesselScheduError: false

    }
  },

  validations: {

    documentToDocument: {
      documentType: {
        documentTypeId: {
          required
        }
      },
      vesselSchedule: {
        vesselScheduleId: {
          required
        }
      }
    }

  },

  components: {

    PulseLoader,
    Modal,
    'modal-manifest': ModalManifest,
    ModelListSelect

  },

  computed: {

    ...mapGetters([

      'getDocumentVoyageNumberBeforeEdit'

    ])

  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadDocumentType()
    this.loadVessel()
    this.loadVesselSchedule()
    this.loadDocument()
  },

  methods: {

    loadDocumentType () {
      API.getDocumentType().then(
        response => {
          if (response.data.status) {
            this.options = response.data.data
          } else {

          }
        }
      ).catch(e => {

      })
    },

    sendMail (data) {
      this.sendMyLoader = true

      // setTimeout(() => {

      API.sendDownloadLinkMail(data).then(
        response => {
          if (response.data.status) {
            this.mailSuccess = 'Mail has been sent to ' + this.companyEmail + ' for company ' + this.companyName
            this.mailSent = true
            this.closeModal()
            this.sendMyLoader = false

            setTimeout(() => {
              this.mailSuccess = ''
              this.mailSent = false
            }, 7000)
          } else {

          }
        }
      ).catch(e => {

      })

      // }, 1000);
    },

    loadVesselSchedule () {
      this.myloader = true

      API.getVesselSchedule()
        .then(response => {
          if (response.data.status) {
            this.getVesselSchedule = response.data.data
            this.myloader = false
          } else {
          }
        })
        .catch(e => {
          this.ErrorMessage = ' Record not loaded'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false
        })
    },

    updateVoyageNumber () {
      this.array = {

        documentId: this.documentId,
        controlNumber: this.controlNumber,
        documentUrl: this.documentUrl,
        documentType: {
          documentTypeId: this.documentTypeId
        },
        vesselSchedule: {
          vesselScheduleId: this.vesselScheduleId
        }

      }

      this.editMyLoader = true

      API.updateDocument(JSON.stringify(this.array))
        .then(response => {
          if (response.data.status) {
            this.editMyLoader = false
            this.documentToDocument.documentType.documentTypeId = ''
            this.documentToDocument.vesselSchedule.vesselScheduleId = ''
            this.vesselScheduleId = ''
            this.loadDocument()
            this.closeModal()
            window.scrollTo(10, 0)
            this.mailSuccess = 'Voyage number [ ' + this.getDocumentVoyageNumberBeforeEdit + ' ] ' +
                'for document [ ' + this.documentTypeName + ' ] changed to current value'
            this.mailSent = true

            setTimeout(() => {
              this.mailSuccess = ''
              this.mailSent = false
            }, 14000)
          } else {
          }
        })
        .catch(e => {
          this.ErrorMessage = ' Record not edited'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.editMyLoader = false
          this.closeModal()
        })
    },

    loadVessel () {
      API.getVessel()
        .then(response => {
          if (response.data.status) {
            this.getVessel = response.data.data
          } else {

          }
        })
        .catch(e => {

        })
    },

    loadDocument () {
      API.getDocument().then(
        response => {
          if (response.data.status) {
            this.getDocument = response.data.data
          } else {

          }
        }
      ).catch(e => {

      })
    },

    loadSingleDocument (data) {
      this.BLLoader = true

      API.getIndividualDocument(data).then(
        response => {
          if (response.data.status) {
            this.BLLoader = false
            this.getDocumentBL = response.data.data.cargoManifestOriginal
          } else {

          }
        }
      ).catch(e => {
        this.BLLoader = false
        this.closeModal()
      })
    },

    uploadFile () {
      if (this.$v.documentToDocument.documentType.documentTypeId.$invalid ||
          this.$v.documentToDocument.vesselSchedule.vesselScheduleId.$invalid) {
        if (this.$v.documentToDocument.vesselSchedule.vesselScheduleId.$invalid) { this.vesselScheduError = true }
        if (this.$v.documentToDocument.documentType.documentTypeId.$invalid) { this.docTypeError = true }
      } else {
        this.fileData = this.$refs.file.files[0]

        this.file = this.fileData.name

        this.ManifestLoader = true

        const formData = new FormData()

        formData.append('file', this.fileData)
        formData.append('documentTypeId', this.documentToDocument.documentType.documentTypeId)
        formData.append('vesselScheduleId', this.documentToDocument.vesselSchedule.vesselScheduleId)

        setTimeout(() => {
          API.uploadDocument().then(
            response => {
              if (response.data.status) {
                this.ManifestLoader = false
                this.ManifestFinishLoader = true
                this.documentToDocument.documentType.documentTypeId = ''
                this.documentToDocument.vesselSchedule.vesselScheduleId = ''
                this.loadDocument()
                this.file = ''
                this.docTypeError = false
                this.vesselScheduError = false

                setTimeout(() => {
                  this.ManifestFinishLoader = false
                }, 7000)
              } else {

              }
            }
          ).catch(e => {

          })
        }, 2500)
      }
    },

    openModal (documentId = null, docUrl = null, documentTypeName = null, documentTypeId = null,
      vesselScheduleId = null, controlNumber = null, vesselName = null, voyageNo = null,
      comEmail = null, comName = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.SendEmailModalDiv = false
        this.ShowModalDiv = false
        this.showModalManifest = false
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
        this.documentTypeId = documentTypeId
        this.vesselScheduleId = vesselScheduleId
        this.documentUrl = docUrl

        this.$store.dispatch('commitDocumentVoyageNumberBeforeEdit', this.voyageNo)
      } else if (actionButton === 'SendEmailModal') {
        this.EditModalDiv = false
        this.SendEmailModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.showModalManifest = false
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
        this.companyEmail = comEmail
        this.companyName = comName
      } else {
        this.loadSingleDocument(documentId)
        this.EditModalDiv = false
        this.SendEmailModalDiv = false
        this.ShowModalDiv = true
        this.showModal = false
        this.showModalManifest = true
        this.documentId = documentId
        this.voyageNo = voyageNo
        this.vesselName = vesselName
        this.controlNumber = controlNumber
        this.documentTypeName = documentTypeName
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.SendEmailModalDiv = false
      this.showModalManifest = false
    }

  }

}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .loader-div-gif {
    line-height: 34px;
  }

  .loader-div-gif-email {
    line-height: 54px;
  }

  .loader-div-email {
    line-height: 45px;
  }

  .special-limit {
    max-width: 170px !important;
    min-width: 150px !important;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 16px;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  #label-upload {
    position: relative;
    top: 16px;
  }

</style>
<style>
  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }
</style>
