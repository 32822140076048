<template>
  <div>
    <div class="before-container" />

    <div class="container">
      <div
        class="row justify-content-center"
        style="background-color: transparent;opacity: .9"
      >
        <div class="col-lg-5 col-md-7">
          <div class="card shadow border-0">
            <div class="card-header bg-transparent pb-4">
              <div class="logo-img text-center">
                <img
                  src="/img/logo.png"
                  alt
                  style="width:300px;height:230px;"
                >
              </div>
            </div>
            <div class="text-center pb-4 text-black-50">
              <b class="color-blue">S</b>HIPPING <b class="color-blue">B</b>USINESS <b class="color-blue">M</b>ANAGEMENT <b class="color-blue">S</b>YSTEM
            </div>
            <div class="card-body px-lg-5 py-lg-2">
              <form
                role="form"
                @submit.prevent="sendRequest"
              >
                <div class="input-container">
                  <i class="nc-icon nc-email-85 icon" />
                  <input
                    placeholder="email"
                    id="username"
                    autocomplete="off"
                    class="input-field"
                    v-model="email"
                  >
                </div>

                <div class="text-center">
                  <div
                    class="form-group"
                    style="height: 25px"
                  >
                    <div>
                      <div
                        class="form-group text-danger"
                        v-if="showIncorrectError"
                      >
                        {{ incorrectError }}
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    name="button"
                    :class="[ markButtonDisabled ? 'btn-disabled' : 'btn' ]"
                    @keyup.enter="submit"
                    :disabled="disabledSignIn"
                  >
                    <div class="row">
                      <div :class="[ loadingPending && loadingPendingIcon ? 'col-md-7 text-right' : 'col-md-12 text-center' ]">
                        {{ signInText }}
                      </div>
                      <div
                        v-if="loadingPendingIcon"
                        :class="[ loadingPendingIcon ? 'col-md-5 text-left' : '' ]"
                      >
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          class="loader-div-gif"
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </form>
              <div class="row mt-3 forgot-password text-center">
                <div class="col-12">
                  <router-link :to="{name : 'login'}">
                    <small>Login</small>
                  </router-link>
                </div>
                <div class="col-6 text-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import API from '../api/index'

export default {

  name: 'AuthLayout',

  components: {

    PulseLoader

  },

  data () {
    return {

      email: '',

      timer: null,
      signInText: 'Send Request',
      disabledSignIn: false,
      showIncorrectError: false,
      loadingPending: false,
      loadingPendingIcon: false,
      incorrectError: '',
      markButtonDisabled: false,

      color: 'DimGray',
      size: '12px',
      loading: true
    }
  },

  methods: {

    sendRequest () {
      const atPos = this.email.indexOf('@')
      const dotPos = this.email.lastIndexOf('.')

      if (this.email.length <= 0) {
        this.incorrectError = 'Incorrect email address'
        this.email = ''
        this.showIncorrectError = true
      } else if (atPos < 1 || dotPos < atPos + 2 || dotPos + 2 >= this.email.length) {
        this.incorrectError = 'Incorrect email address'
        this.email = ''
        this.showIncorrectError = true
      } else {
        if (this.disabledSignIn) {
          return
        }

        this.signInText = 'Sending Request...'
        this.disabledSignIn = true
        this.loadingPending = true
        this.loadingPendingIcon = true
        this.markButtonDisabled = true

        if (this.showIncorrectError) {
          this.showIncorrectError = false
        }

        let redirect_to = this.$route.query.redirect

        if (redirect_to == null) {
          redirect_to = '/login'
        }

        const email = {

          email: this.email

        }

        return new Promise((resolve, reject) => {
          API.sendRequestResetPassword(JSON.stringify(email))

            .then(res => {
              if (res.data.status) {
                this.loadingPendingIcon = false
                this.signInText = 'Request Sent, check your email '
                this.email = ''

                this.timer = setInterval(() => {
                  this.loadingPending = false
                  this.markButtonDisabled = false
                  this.loadingPendingIcon = false

                  this.$router.push(redirect_to)
                }, 5000)
              } else {
                this.signInText = 'Send Request'
                this.incorrectError = 'Email address does not exist'
                this.showIncorrectError = true
                this.loadingPending = false
                this.loadingPendingIcon = false
                this.disabledSignIn = false
                this.markButtonDisabled = false
                this.email = ''
              }

              resolve()
            })

            .catch(err => {
              reject(err)
            })
        })
      }
    }

  },

  destroyed () {
    if (this.timer != null) {
      clearInterval(this.timer)
    }
  }
}

</script>

<style scoped>

  .container {
    position: absolute;
    top: 50%;
    left: 49.67%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .forgot-password {
    color: #00BFFF;
    font-size: 18px;
  }

  .forgot-password:hover div a small {
    color: #000000;
    font-size: 15px;
  }

  .before-container {
    width: 100%;
    height: 100%;
    background-image: url("../../public/img/10.jpg"); /* The image used */
    background-color: #87CEFA; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    /*filter: blur(10px);*/
    /*-webkit-filter: blur(10px);*/

    /*opacity: .5;*/

    position: absolute;
    padding-top: 200px;
  }

  .loader-div-gif {
    line-height: 34px;
  }

  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }

  .icon {
    padding: 10px;
    background: dodgerblue;
    color: white;
    min-width: 50px;
    text-align: center;
  }

  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
  }

  .input-field:focus {
    border: 2px solid dodgerblue;
  }

  .btn {
    background-color: dodgerblue;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
    min-height: 55px;
    max-height: 55px;
  }

  .btn:hover {
    background-color: dodgerblue;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 1;
    min-height: 55px;
    max-height: 55px;
  }

  .btn-disabled {
    background-color: #DCDCDC;
    cursor: not-allowed;
    color: #696969;
    padding: 10px 20px;
    border: none;
    width: 100%;
    opacity: 0.9;
    min-height: 55px;
    max-height: 55px;
  }

  .btn-disabled:hover {
    background-color: #DCDCDC;
    cursor: not-allowed;
    color: #696969;
    padding: 10px 20px;
    border: none;
    width: 100%;
    opacity: 1;
    min-height: 55px;
    max-height: 55px;
  }

  .nc-icon {
    padding-top: 14px;
    font-size: 23px;
  }

</style>
