<template>
  <card>
    <div class="row back-color-vat">
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-md-4">
            <label for="imo" class="form-label">imo</label>
            <input type="text" class="form-control" id="imo" v-model="imo" placeholder="IMO Number">
          </div>
          <div class="col-md-4">
            <label for="vesselName" class="form-label">VesselName</label>
            <input type="text" class="form-control" id="vesselName" v-model="vesselName" placeholder="VesselName">
          </div>
          <div class="col-md-4">
            <label>Vessel Type</label>
            <model-list-select
              :list="vesselTypes"
              v-model="vesselType"
              class="form-control"
              option-value="id"
              option-text="name"
              placeholder="Vessel Type"
            >
            </model-list-select>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-md-4">
            <label for="sDate" class="form-label">Start Date</label>
            <input type="date" class="form-control" id="sDate" v-model="startDate" placeholder="Start Date">
          </div>

          <div class="col-md-4">
            <label for="eDate" class="form-label">End Date</label>
            <input type="date" class="form-control" id="eDate" v-model="endDate" placeholder="End Date">
          </div>
          <div class="col-md-4">
            <label>Date Category</label>
            <model-list-select
              :list="dateCategories"
              v-model="dateCategory"
              class="form-control"
              option-value="id"
              option-text="name"
              placeholder="Date Category"
            >
            </model-list-select>
          </div>

        </div>
      </div>
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-md-4">
            <label for="arrivalPort" class="form-label">Arrival Port</label>
            <input type="text" class="form-control" id="arrivalPort" v-model="arrivalPort" placeholder="Arrival Port">
          </div>
          <div class="col-md-4">
            <label for="voyageNumber" class="form-label">Voyage Number</label>
            <input type="text" class="form-control" id="voyageNumber" v-model="voyageNumber" placeholder="Voyage Number">
          </div>
          <div class="col-md-4">
            <label for="destinationPort" class="form-label">Destination Port</label>
            <input type="text" class="form-control" id="destinationPort" v-model="destinationPort" placeholder="Destination Port">
          </div>
        </div>
      </div>
      <div class="col-md-12 mb-3">
        <label>Status</label>
        <model-list-select
          :list="statuses"
          v-model="status"
          class="form-control"
          option-value="id"
          option-text="name"
          placeholder="Status"
        >
        </model-list-select>
      </div>
      <div class="col-md-12">
        <button
          @click.prevent="resetParams"
          class="btn btn-fill btn-outline-info float-right custom-btn-all"
          data-dismiss="modal"
        >
          Clear filter
        </button>
        <button
          @click.prevent="generateReport"
          class="btn mr-2 btn-primary float-right custom-btn-all"
          data-dismiss="modal"
        >
          Generate Report
        </button>
      </div>

      <div class="col-md-12">
        <data-table
          :tableHeaders="tableHeaders"
          :tableData="filteredRequests"
          :tableDataKeys="tableDataKeys"
          :pageSizeProp="pageSize"
          :pageNo="pageNo"
          :hasFilter="false"
          :myLoader="loading"
          :myFirstLoader="loading"
          :noDataFound="noDataFound"
          :searchParameterProp="searchParameter"
          :loadingTitle="'Please wait while system is loading Bill of Lading List'"
          :hasManageButton="false"
          :totalElements="totalElements"
          :totalPages="totalPages"
          :isFirst="isFirst"
          :isLast="isLast"
          :isHovered="[]"
          :pageNumber="pageNumber"
          :has-custom-button="true"
          custom-button-name="Download"
          :is-hovered="false"
          :permissions="[ {key: 'searchPermission', value: 'CFAREPORT_ASYNC_REPORT_GET'} ]"
          @fireCustomMethod="downloadReport"
          @onChange="onChange"
        >
          <div slot = "pagination">
            <paginate
              v-model="getIsPage"
              :page-count=parseInt(totalPages)
              :page-range="3"
              :margin-pages="2"
              :click-handler="goToPage"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          </div>
        </data-table>

      </div>
    </div>
  </card>
</template>

<script>
  import API from "../../../api/agency";
  import {ModelListSelect} from 'vue-search-select';
  import PulseLoader from "vue-spinner/src/PulseLoader";
  import {mapActions} from "vuex";

    export default {
        name: "vesselPerformanceReport",
      components: {
        PulseLoader,
        ModelListSelect,
        DataTable: () => import('../../../components/DataTable/dataTable'),
      },

      created() {
        this.$store.dispatch("pagenames/currentPageName", "Vessel Performance Reports");
        this.loadAllreports();
      },

      computed:{
        getIsPage: {

          get: function () {
            return this.isPage
          },
          set: function (newValue) {
            this.isPage = newValue;
          }

        }
      },

      data(){
          return{
            tableHeaders:['Description','From Date','To Date','Date Created'],
            tableDataKeys:['description','startDate','endDate','createdDate'],
            filteredRequests:[],
            loading:false,
            pageNo: 0,
            pageSize: 15,
            totalElements: "",
            totalPages: "",
            isFirst: false,
            isLast: false,
            pageNumber: '',
            pageCount: 1,
            noDataFound:false,
            searchParameter: '',
            isPage: 0,

            params:"",
            vesselTypes:[
              {name: "DRY BULK", id: "DRY_BULK"},
              {name: "PURE CAR CARRIER", id: "PURE_CAR_CARRIER"},
              {name: "TANKER", id: "TANKER"},
              {name: "MILITARY", id: "MILITARY"},
              {name: "CRUISE", id: "CRUISE"},
              {name: "EXHIBITION", id: "EXHIBITION"},
              {name: "OTHER", id: "OTHER"},
            ],
            dateCategories:[
              {name: "EXPECTED ARRIVAL", id: "EXPECTED_ARRIVAL"},
              {name: "EXPECTED DEPARTURE", id: "EXPECTED_DEPARTURE"},
              {name: "ACTUAL ARRIVAL", id: "ACTUAL_ARRIVAL"},
              {name: "ACTUAL DEPARTURE", id: "ACTUAL_DEPARTURE"},
              {name: "CREATED", id: "CREATED"},
            ],
            statuses:[
              {name: "CREATED", id: "CREATED"},
              {name: "ACTIVE", id: "ACTIVE"},
              {name: "EXECUTED", id: "EXECUTED"},
              {name: "CANCELLED", id: "CANCELLED"},
              {name: "OTHER", id: "OTHER"},
            ],

            imo:"",
            vesselName:"",
            vesselType:"",
            startDate:"",
            endDate:"",
            dateCategory:"",
            arrivalPort:"",
            voyageNumber:"",
            destinationPort:"",
            status:""
          }
      },

      methods:{

        ...mapActions("global", [
          'loadFileFromMinio'
        ]),

        loadParameters (fileUrl, fileName) {

          return new Promise((resolve) => {

            this.$store.dispatch('global/fileUrling', fileUrl);
            this.$store.dispatch('global/fileNaming', fileName);

            resolve()
          })

        },

        setParams(){

          this.params='size=' + this.pageSize + '&page=' + this.pageNo;
          this.vesselName!==""? this.params= this.params  +  "&vesselName=" + this.vesselName:"";
          this.vesselType!==""? this.params= this.params  +  "&vesselType=" + this.vesselType:"";
          this.startDate!==""? this.params= this.params  +  "&startDate=" + this.startDate:"";
          this.endDate!==""? this.params= this.params  +  "&endDate=" + this.startDatendDatee:"";
          this.dateCategory!==""? this.params= this.params  +  "&dateCategory=" + this.dateCategory:"";
          this.arrivalPort!==""? this.params= this.params  +  "&arrivalPort=" + this.arrivalPort:"";
          this.voyageNumber!==""? this.params= this.params  +  "&voyageNumber=" + this.voyageNumber:"";
          this.destinationPort!==""? this.params= this.params  +  "&destinationPort=" + this.destinationPort:"";
          this.status!==""? this.params= this.params  +  "&status=" + this.status:"";

        },

        loadAllreports(){
          this.loading=true;
          API.getVesselPerformanceReports(this.pageNo,this.pageSize).then((resp)=>{
            this.loading=false;
            if(resp.data.status){
              this.totalPages=resp.data.data.totalPages;
              this.totalElements=resp.data.data.totalElements;
              this.pageNumber=resp.data.data.number;
              this.isFirst=resp.data.data.first;
              this.isLast=resp.data.data.isLast;

              if (resp.data.data.content.length > 0) {
                this.noDataFound=false;
                this.sortingData(resp.data.data.content);
              }else {
                this.noDataFound=true;
              }

            }else {
              this.notifyUser('error','Error','System Error please Contact System Administrator');
            }

          }).catch((error)=>{
            this.loading=false;
          })
        },

        downloadReport (data) {

          this.loadParameters(data.attachmentUrl, 'PerformanceReport').then(() => {
            this.loading=true;
            this.loadFileFromMinio({}).then((res) => {

              if(res.status === 204) {

                this.loadNotification('warn', 4000, 'File Download',
                  'File not found, please contact system admin',
                  'response', 1000 , true  , true)

              } else {

                this.loadNotification('success', 4000, 'File Download',
                  'File has been successfully downloaded, please have a look at it',
                  'response', 1000 , true  , true)


              }

              this.loading = false;

            }).catch(() => {
              this.loading=false;
              this.loadNotification('error', 4000, 'File Download',
                'File not downloaded, please check your internet connection',
                'response', 1000 , true  , true)

              // this.globalLoader = this.getGlobalLoader

            });

            // this.globalLoader = this.getGlobalLoader

          }).catch(() => {

          })

        },


        generateReport(){
          this.loading=true;
          this.setParams();

          API.generateVesselPerformance(this.params).then((resp)=>{
            this.loading=false;
            if(resp.data.status){
              this.notifyUser('success','Success','Report Generated');
            }else {
              this.notifyUser('error','Error','Report failed to be Generated');
            }
          }).catch((err)=>{
            this.loading=false;
            this.notifyUser('error','Error','Report failed to be Generated');
          })
        },

        sortingData(data) {
          this.filteredRequests=[];
          for(let x = 0 ; x < data.length ; x++) {

            const requestObject = {
              ...data[x],
              startDate: this.dateTimeToHuman(data[x].startDate),
              endDate: this.dateTimeToHuman(data[x].endDate),
              createdDate: this.dateTimeToHuman(data[x].createdDate),
            };

            this.filteredRequests.push(requestObject)

          }

        },

        resetParams(){
          this.imo="";
          this.vesselName="";
          this.vesselType="";
          this.startDate="";
          this.endDate="";
          this.dateCategory="";
          this.arrivalPort="";
          this.voyageNumber="";
          this.destinationPort="";
          this.status="";

        },

        onChange(event) {
          this.pageSize=event;
          this.pageNo=0;
          this.loadAllreports();
        },

        goToPage(page) {

          if(page > 0) {page = page - 1;}
          this.isPage = page + 1;
          this.pageNo=this.isPage;

          this.loadAllreports();

        },

      }

    }
</script>

<style scoped>
  .back-color-vat {
    background-color: rgba(216, 225, 232, .3);
  }

  .custom-btn, .custom-btn-all {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .custom-btn:hover, .custom-btn-all:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
</style>
