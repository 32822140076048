<template>
  <div>
    <card v-if="assessmentLoader">
      <div class="row">
        <div class="col-md-12 text-center loader-color">
            <b>Please wait while system is loading charge details</b>
        </div>
        <div class="col-md-12 text-center">
            <br>
        </div>
        <div class="col-md-12 text-center">
            <pulse-loader
            :color="color"
            :size="size"
            />
        </div>
      </div>
    </card>
    <card v-if="!assessmentLoader" style="font-size: 12px">

      <div class="legend">
            <h6 class="legend-title">
              Application Details
            </h6>

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    BL Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ selectectedRequest.blNumber }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    File Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.fileNo}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Mode of Transport
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.transportMethod ? selectectedRequest.transportMethod.transportMethodName : ""}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Regime
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.regime ? selectectedRequest.regime.regimeName : "" }}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    {{ selectectedRequest.requestType === 'IMP' ? 'Expected Date of Arrival (ETA)' : 'Expected Date of Departure (ETD)' }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.cargoExpectedDate !== null ? dateToHuman(selectectedRequest.cargoExpectedDate) : ''}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    PO Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.poNumber}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Operation Type
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.requestType === 'IMP' ? 'Import' : 'Export'}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Product Type
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.productType}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Approved By
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.approvedBy}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Approved Date
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{dateTimeToHuman(selectectedRequest.approveDate)}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Description
                </div>
                <div class="col-md-9 div-table div-table-value">
                    {{selectectedRequest.description}}
                </div>
            </div>
      </div>

      <div class="legend">
            <h6 class="legend-title">
              Customer Details
            </h6>

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Name
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.customer ? (selectectedRequest.customer.name).toUpperCase():"" }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    TIN Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.customer ? selectectedRequest.customer.tin :""}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Email Address
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.customer ? selectectedRequest.customer.email :""}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    VRN Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.customer ? selectectedRequest.customer.vrn :""}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Mobile Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.customer ? selectectedRequest.customer.mobile:""}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Address
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{selectectedRequest.customer ? selectectedRequest.customer.address :""}}
                </div>
            </div>
      </div>

      <!-- <div>
        <div class="col-12 title-color">
          <strong>CUSTOMER DETAILS</strong>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 legend">
          <table class="table">
            <tbody>
            <tr>
              <td><b>Name</b></td>
              <td>{{selectectedRequest.customer ? (selectectedRequest.customer.name).toUpperCase():"" }}</td>
            </tr>
            <tr>
              <td><b>Email Address</b></td>
              <td>{{selectectedRequest.customer ? selectectedRequest.customer.email :""}}</td>
            </tr>
            <tr>
              <td><b>Mobile Number</b></td>
              <td>{{selectectedRequest.customer ? selectectedRequest.customer.mobile:""}}</td>
            </tr>
            <tr>
              <td><b>Address</b></td>
              <td>{{selectectedRequest.customer ? selectectedRequest.customer.address :""}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6 legend">
          <table class="table">
            <tbody>
            <tr>
              <td><b>Tin Number</b></td>
              <td>{{selectectedRequest.customer ? selectectedRequest.customer.tin :""}}</td>
            </tr>
            <tr>
              <td><b>Vat Registration Number(VRN)</b></td>
              <td>{{selectectedRequest.customer ? selectectedRequest.customer.vrn :""}}</td>
            </tr>
            <tr>
              <td><b>Mobile</b></td>
              <td>{{selectectedRequest.customer ? selectectedRequest.customer.mobile :""}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div> -->

      <!-- <hr class="charge-custom-hr"/> -->

      <!-- <div>
        <div class="col-12 title-color">
          <strong>APPLICATION DETAILS</strong>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 legend">
          <table class="table">
            <tbody>
            <tr>
              <td><b>BL Number</b></td>
              <td>{{ selectectedRequest.blNumber }}</td>
            </tr>
            <tr>
              <td><b>File Number</b></td>
              <td>{{selectectedRequest.fileNo}}</td>
            </tr>
            <tr>
              <td><b>Transported By</b></td>
              <td>
                {{selectectedRequest.transportMethod ? selectectedRequest.transportMethod.transportMethodName : ""}}
              </td>
            </tr>
            <tr>
              <td><b>Transport Description</b></td>
              <td>
                {{selectectedRequest.transportMethod ? selectectedRequest.transportMethod.transportMethodDescription: ""}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6 legend">
          <table class="table">
            <tbody>
            <tr>
              <td><b>Approved By</b></td>
              <td>{{selectectedRequest.approvedBy}}</td>
            </tr>
            <tr>
              <td><b>Approved Date</b></td>
              <td>{{dateTimeToHuman(selectectedRequest.approveDate)}}</td>
            </tr>
            <tr>
              <td><b>Description</b></td>
              <td>{{selectectedRequest.description}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div> -->
      <!-- <hr class="charge-custom-hr"/> -->

      <div class="legend">
            <h6 class="legend-title">
              Tariff Details
            </h6>

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Tariff Item Name
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{getRequestAssNotes.tariffItemName}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Litre
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{getRequestAssNotes.cargoVolume === null && typeof getRequestAssNotes.cargoVolume === undefined ? "" : removeCommaForDecimal(getRequestAssNotes.cargoVolume)}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    TANSAD Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{getRequestAssNotes.tansadNo}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    {{ labelText }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{getRequestAssNotes.quantity === null && typeof getRequestAssNotes.quantity === undefined ? "" :getRequestAssNotes.quantity}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Custom Value
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{getRequestAssNotes.customValue === null && typeof getRequestAssNotes.customValue === undefined ? "" : removeCommaForDecimal(getRequestAssNotes.customValue) + " (TZS)"}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Cargo Tonnage
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ getRequestAssNotes.cargoTonnage === null && typeof getRequestAssNotes.cargoTonnage === undefined ? "" : removeCommaForDecimal(getRequestAssNotes.cargoTonnage) + " (Metric Tones)"}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    TRA Assessment Notice
                </div>
                <div class="col-md-3 div-table div-table-value" v-if="getRequestAssNotes.assessmentNoticeDocUrl !== '' && getRequestAssNotes.assessmentNoticeDocUrl !== null">
                    <span class="download-link" @click="openPdfViewer(getRequestAssNotes.assessmentNoticeDocUrl, 'TRA_ASSESSMENT_NOTICE_FOR_APPLICATION_WITH_FILE_NUMBER_' + selectectedRequest.fileNo, 'view')">click to view</span>
                </div>
                <div class="col-md-3 div-table div-table-value" v-else>
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Cargo CBM
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{getRequestAssNotes.cargoCbm}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Tariffed By
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{getRequestAssNotes.createdBy}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    Created Date
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{dateTimeToHuman(getRequestAssNotes.createdDate)}}
                </div>
            </div>

            <br />

            <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Vessel Name
                </div>
                <div class="col-md-9 div-table div-table-value">
                    {{getRequestAssNotes.vesselName}}
                </div>
            </div>

            <br v-if="getRequestAssNotes.additionalCargos != null && getRequestAssNotes.additionalCargos.length > 0"/>

            <div class="legend" v-if="getRequestAssNotes.additionalCargos != null && getRequestAssNotes.additionalCargos.length > 0">
              <h6 class="legend-title">
                Additional Information
              </h6>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                    Tariff Item Name
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ getRequestAssNotes.additionalCargos[0].tariffItemName }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                    {{ labelText }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                    {{ getRequestAssNotes.additionalCargos[0].quantity }}
                </div>
            </div>

            </div>

      </div>

      <!-- <div>
        <div class="col-12 title-color">
          <strong>TARIFF DETAILS</strong>
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-md-6 legend">
          <table class="table">
            <tbody>
            <tr>
              <td><b>tariff Item Name</b></td>
              <td>{{ getRequestAssNotes.tariffItemName }}</td>
            </tr>
            <tr>
              <td><b>tansad No</b></td>
              <td>{{getRequestAssNotes.tansadNo}}</td>
            </tr>
            <tr>
              <td><b>custom Value</b></td>
              <td v-if="getRequestAssNotes.customValue !== undefined">
                {{getRequestAssNotes.customValue === null ? "" :
                removeCommaForDecimal(getRequestAssNotes.customValue) + " TZS"}}
              </td>
            </tr>
            <tr>
              <td><b>Tariffed By</b></td>
              <td>
                {{getRequestAssNotes.createdBy}}
              </td>
            </tr>
            <tr>
              <td><b>TRA Assessment Notice</b></td>
              <td v-if="getRequestAssNotes.assessmentNoticeDocUrl !== '' && getRequestAssNotes.assessmentNoticeDocUrl !== null">
                <span class="download-link" @click="openPdfViewer(getRequestAssNotes.assessmentNoticeDocUrl, 'TRA_ASSESSMENT_NOTICE_FOR_' + selectectedRequest.fileNo, 'view')">click to view</span>
              </td>
              <td v-else>
                <span class="text-danger">no file found</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6 legend">
          <table class="table">
            <tbody>
            <tr>
              <td><b>Litre</b></td>
              <td v-if="getRequestAssNotes.cargoVolume !== undefined">
                {{getRequestAssNotes.cargoVolume === null ? "" :
                removeCommaForDecimal(getRequestAssNotes.cargoVolume)}}
              </td>
            </tr>
            <tr>
              <td><b>{{ labelText }}</b></td>
              <td v-if="getRequestAssNotes.quantity !== undefined">
                {{getRequestAssNotes.quantity === null ? "" :getRequestAssNotes.quantity}}
              </td>
            </tr>
            <tr>
              <td><b>Cargo Tonnage</b></td>
              <td v-if="getRequestAssNotes.cargoTonnage !== undefined">
                {{getRequestAssNotes.cargoTonnage === null ? "" :
                removeCommaForDecimal(getRequestAssNotes.cargoTonnage) + " Metric Tones"}}
              </td>
            </tr>
            <tr>
              <td><b>Created Date</b></td>
              <td v-if="getRequestAssNotes.createdDate !== undefined">
                {{dateTimeToHuman(getRequestAssNotes.createdDate)}}
              </td>
            </tr>
            <tr>
              <td><b>Cargo CBM</b></td>
              <td>{{getRequestAssNotes.cargoCbm}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <div class="row" v-if="getRequestAssNotes.additionalCargos != null && getRequestAssNotes.additionalCargos.length > 0">
        <div class="col-md-12 title-color">
          <strong>ADDITIONAL INFORMATION</strong>
        </div>
        <div class="col-md-6">
          <table class="table">
            <tbody>
            <tr>
              <td><b>tariff Item Name</b></td>
              <td>{{ getRequestAssNotes.additionalCargos[0].tariffItemName }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <table class="table">
            <tbody>
            <tr>
              <td><b>{{ labelText }}</b></td>
              <td>{{ getRequestAssNotes.additionalCargos[0].quantity }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div> -->

      <hr class="charge-custom-hr" v-if="requestCharges.length > 0"/>

      <div>
        <div class="row" v-if="!loadCharge">
          <table class="table table-striped" v-if="requestCharges.length > 0">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Charge Name</th>
              <th scope="col">Charged By</th>
              <th scope="col">Charged Date</th>
              <th scope="col">Canceled Date</th>
              <th scope="col">Is Cancelled</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(charged , index) in requestCharges" v-bind:key="charged.requestChargeId" v-if="charged.tariffFee !== null">
              <th scope="row">{{index + 1}}</th>
              <td>{{ charged.tariffFee.tariffFeeName }}</td>
              <td>
                {{charged.createdBy}}
              </td>
              <td>
                {{dateTimeToHuman(charged.createdDate)}}
              </td>
              <td>
                <span v-if="charged.isCancelled">
                  {{dateTimeToHuman(charged.lastModifiedDate)}}
                </span>
                <span v-else>
                  Non
                </span>
              </td>
              <td v-if="charged.isCancelled">
                <span class="text-danger mr-3">Cancelled</span>
                <span class="pointer" @click="showCancelledMessage(charged)">
                  <font-awesome-icon class="delete-fa-icon delete-icon" icon="comment" color="red"/>
                </span>
              </td>
              <td v-if="!charged.isCancelled">
                <span class="text-success mr-3">Active</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-else>
          <div class="col-md-12 text-center loader-color">
            <b>Loading Charges, Please wait</b>
            <pulse-loader :color="color" :size="size1" class="mr-4 pt-1"/>
          </div>
        </div>

        <hr class="charge-custom-hr"/>

        <div class="row" v-if="!intendToCancel && thereIsCancelled">
          <div class="col-12">
            <div class="form-group">
              <label for="fee">Select Fee</label>
              <select class="form-control" id="fee" @change="onChangeCharge($event)" v-model="fee" :disabled="fees.length <= 0">
                <option :value="undefined">Select fee</option>
                <option v-for="(list,index) in fees" :key="index" :value="list.tariffFeeId" v-if="list.isActive">
                  {{list.tariffFeeName}}
                </option>
              </select>
              <div class="text-danger" v-if="feeError">This field is required</div>
            </div>
          </div>
        </div>

        <div class="row" v-if="intendToCancel">
          <div class="col-12">
                  <span class="pointer-hover" @click="intendToCancel=false">
                      <font-awesome-icon icon="arrow-left" color="green" size="lg"/>
                    <strong> Back</strong>
                  </span>
          </div>
        </div>

        <div class="row" v-if="intendToCancel">
          <div class="col-12">
            <div class="form-group">
              <label for="reject">Reason To Reject</label>
              <textarea class="form-control" id="reject" rows="3" v-model="reasonReject"></textarea>
            </div>
            <div class="text-danger" v-if="cancelReasonError">This field is required</div>
          </div>
        </div>
        <div class="col-md-12" v-if="cancellMessageOn">
          <div class="form-group">
            <label for="message">Rejected Charge Message</label>
            <textarea class="form-control" id="message" rows="3" v-model="rejectedMessage" disabled></textarea>
          </div>
        </div>

        <div class="row" v-if="duplicate">
          <div class="col-12 text-center">
            <small class="text-danger">Error Duplicate Charge</small>
          </div>
        </div>
        <!-- <div class="row" v-if="noCurrency">
          <div class="col-12 text-center">
            <small class="text-danger">Please Select Currency</small>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between alert"
                v-bind:class="[list.billed ? 'alert-dark':'alert-primary']"
                role="alert" v-for="list in charges" :key="list.tariffFeeId">
              {{list.tariffFeeName }}
              <span class="all-fa-icons btn-space hover-me" title="Cancel Fee" v-if="!list.saved"
                    @click="cancelFee(list.tariffFeeId)">
                    <font-awesome-icon icon="times"/>

                    </span>
            </div>
          </div>
        </div>
        <br/>

        <div class="text-center row">
          <div class="col-md-8 text-right loader-div-gif">
            <div class="row">
              <div class="col-md-10" v-if="saveCharge">
                Please wait ....
              </div>
              <div class="col-md-2">
                <pulse-loader :loading="loading" :color="color" :size="size" v-if="saveCharge" style="margin-top: 4px;"></pulse-loader>
              </div>
            </div>
          </div>

          <div class="col-md-4 text-right">
            <button type="submit" class="btn btn-primary btn-space mr-2 custom-btn" data-dismiss="modal"
                    v-if="!intendToCancel && thereIsCancelled && selectectedRequest.status !== 'Request Charged'" :disabled="saveCharge"
                    @click="createCharges(selectectedRequest.fileNo , selectectedRequest.customer.name
                          , selectectedRequest.blNumber , getRequestAssNotes.tansadNo)">Send
            </button>
            <button class="btn btn-danger custom-btn mr-2" @click="rejectAssesment" v-if="thereIsCancelled && selectectedRequest.status !== 'Request Charged'" :disabled="saveCharge">Reject Tariff</button>
            <button class="btn btn-default btn-space btn-fill custom-btn-all" @click.prevent="closeModal()" v-if="!intendToCancel" :disabled="saveCharge">Close</button>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Preparing requested file, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
    @downloadPdf="openPdfViewer(getRequestAssNotes.assessmentNoticeDocUrl, 'TRA_ASSESSMENT_NOTICE_FOR_APPLICATION_WITH_FILE_NUMBER_' + selectectedRequest.fileNo)"></pdf-viewer>
  </div>

</template>

<script>
  import API from "../../../api";
  import API_REVENUE from '../../../api/rev'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import { mapActions, mapGetters } from 'vuex';
  import GlobalModal from '../../../components/globalLoader'
  import PdfViewer from '../../../components/pdfViewer.vue'

  export default {
        name: "charging",
      props:['requestId','selectectedRequest','disabled'],

      components:{

            PulseLoader,
            GlobalModal,
            PdfViewer
      },

      created() {

        this.getTariffFees().then(() => {

            this.getRequestCharges()
            this.getAssesmentByRequestId()

        }).catch(() => {})

      },

      computed: {

      ...mapGetters('global', [

        'getGlobalLoader'

      ])

    },

      data () {

        return {

          getRequestAssNotes: {},
          requestCharges: [],
          SuccessAlert:false,
          ErrorAlert: false,
          SuccessMessage: "",
          ErrorMessage: "",
          saveCharge: false,
          duplicate: false,
          intendToCancel:false,
          fee: undefined,
          fees: [],
          charges: [],
          chargesToSend:[],

          noCurrency: false,
          color: 'green',
          size: '15px',
          size1: '9px',
          loading: true,
          myloader: false,
          downloading: false,
          download: true,
          currency: 'TZS',
          reasonReject:"",
          globalLoader: false,
          cancellMessageOn:false,
          rejectedMessage:"",
          labelText: '',
          loadCharge: false,
          thereIsCancelled: false,
          feeError: false,
          scr: '',
          showPdfViewer: false,
          assessmentLoader: false,
          cancelReasonError:false

        }

      },

      methods:{

        ...mapActions('global', [

          'loadFileFromMinio'

        ]),

        showCancelledMessage(charge){

              this.rejectedMessage = charge.cancelReason;
              this.cancellMessageOn = !this.cancellMessageOn;

        },

        loadParameters (fileUrl, fileName) {

          return new Promise((resolve) => {

            this.$store.dispatch('global/fileUrling', fileUrl)
            this.$store.dispatch('global/fileNaming', fileName)

            resolve()
          })

        },

        openPdfViewer (fileUrl, fileName, action) {

            this.getFileFromMinio(fileUrl, fileName, action).then(() => {

                this.showPdfViewer = true

            }).catch(() => {})

        },

        closePdfViewer () {

            this.showPdfViewer = false
            this.scr = ''

        },

        getFileFromMinio (fileUrl, fileName, action) {

            return new Promise((resolve, reject) => {

                this.loadParameters(fileUrl, fileName).then(() => {

                this.loadFileFromMinio({action}).then((res) => {

                    if(res[1].status === 204) {

                        this.loadNotification('warn', 4000, '',
                        'File not found, please contact system admin',
                        'response', 1000 , true  , true)

                    } else {

                        // this.loadNotification('success', 4000, '',
                        // 'File has been successfully loaded, please have a look at it',
                        // 'response', 1000 , true  , true)
                        this.scr = res[0]
                        resolve()


                    }

                        this.globalLoader = this.getGlobalLoader

                    }).catch((error) => {

                    // this.loadNotification('error', 4000, '',
                    //     'File not loaded, please contact system admin',
                    //     'response', 1000 , true  , true)

                    this.globalLoader = false

                    })

                    this.globalLoader = this.getGlobalLoader

                }).catch(() => {

                    this.globalLoader = false

                })

            })

        },

        // getFileFromMinio (fileUrl, fileName) {

        //   this.loadParameters(fileUrl, fileName).then(() => {

        //   this.loadFileFromMinio({}).then((res) => {

        //       if(res.status === 204) {

        //           this.loadNotification('warn', 6000, 'File Download',
        //           'File not found, please contact system admin',
        //           'response', 1000 , true  , true)

        //       } else {

        //         this.loadNotification('success', 6000, 'File Download',
        //           'File has been successfully downloaded, please have a look at it',
        //           'response', 1000 , true  , true)


        //       }

        //         this.globalLoader = this.getGlobalLoader

        //     }).catch(() => {

        //       this.loadNotification('error', 4000, 'File Download',
        //           'File not downloaded, please check your internet connection',
        //           'response', 1000 , true  , true)

        //       this.globalLoader = this.getGlobalLoader

        //     })

        //     this.globalLoader = this.getGlobalLoader

        //   }).catch(() => {

        //   })

        // },

        removeCommaForDecimal(value) {

          let afterDecimal = "";
          let beforeDecimal = "";
          let valueComma = this.putComma(value);

          if(valueComma.includes(".")) {

            afterDecimal = this.removeComma(valueComma.split(".")[1]);
            beforeDecimal = valueComma.split(".")[0];

            return beforeDecimal  + '.' + afterDecimal ;

          } else {

            return valueComma

          }

        },

        // dateToHuman(stringDate) {

        //   if (stringDate !== null || typeof stringDate !== 'undefined') {

        //     let splittedDate = stringDate.split("T");

        //     return splittedDate[0];

        //   }

        // },

        onChangeCharge(e) {

          this.noCurrency = false
          this.feeError = false

          if (this.currency === 'TZS') {

            this.duplicate = false;

            let duplicate = this.charges.findIndex((x) => {

              return x.tariffFeeId === JSON.parse(e.target.value)

            });

            //check also the charges comming from the server make sure that if they contain the same tariff with
            //this that you want to input the emmit error duplicate else insert
            let duplicate2 = this.requestCharges.findIndex((x)=>{
              return x.tariffFee.tariffFeeId === JSON.parse(e.target.value)
            });

            if(duplicate2 !== -1) {

              if(!this.requestCharges[duplicate2].isCancelled) {

                duplicate = 999;

              }
            }


            if (duplicate === -1) {

              let data = this.fees.find((x) => {

                if (x.tariffFeeId === JSON.parse(e.target.value)) {

                  return x;

                }

              });

              this.charges.push({chargeCurrency: this.currency, ...data});

              this.fee = undefined;

              // this.currency = undefined;

            } else {

              // this.duplicate = true;
              this.loadNotification('error', 4000, '',
                        'Can not add duplicate fee',
                        'response', 1000 , true  , true)

              this.fee = undefined;
            }

          } else {

            this.noCurrency = true;

            //this.fee = undefined;
          }

        },

        createCharges(name, com, bl, tansad) {

          if(this.charges.length <= 0) {

            this.feeError = true

          } else {

            this.$dialog.confirm("You are about to create charges for <br><br>" +
            " Application File No. - [ " + name + " ] <br>" +
            " Company Name. - [ " + com + " ] <br>" +
            " B/L No. - [ " + bl + " ] <br>" +
            " TANSAD No. - [ " + tansad + " ] <br><br>" +
            " Are you sure!.", {
            loader: true,
            html: true

            }).then((dialog) => {

              this.saveCharge = true;

              let that = this;

              this.charges.map(async (fee, index) => {

                if (fee.saved === undefined) {

                  this.chargesToSend.push({

                    chargeCurrency: fee.chargeCurrency,

                    requestId: that.selectectedRequest.requestId,

                    tariffFeeId: fee.tariffFeeId

                  });

                  fee.saved = true;

                }
              });

              API.createCharges(this.chargesToSend).then((response) => {

                if (response.data.status) {

                  this.saveCharge = false;

                  this.getRequestCharges(that.requestId);
                  this.chargesToSend = [];
                  this.charges = []
                  this.loadNotification('success', 4000, 'Success',
                    'Charges Created Successfully',
                    'response', 1000 , true  , true);
                  this.closeModalAndReload()

                } else {

                  this.saveCharge = false;
                  this.loadNotification('error', 4000, 'Error',
                    response.data.message,
                    'response', 1000 , true  , true);

                }

              });

              dialog.close();

            });

          }

        },

        enter(el, done) {

          let that = this;

          setTimeout(function () {

            that.SuccessAlert = false;
            that.ErrorAlert = false;

          }, 3000); // hide the message after 3 seconds
        },

        fadeMe: function () {

          if (this.SuccessAlert) {

            this.SuccessAlert = !this.SuccessAlert;

          } else {

            this.ErrorAlert = !this.ErrorAlert;

          }

        },

        cancelFee(feeId) {

          let data = this.charges.findIndex((x) => {

            if (!x.saved) {

              return x.tariffFeeId === JSON.parse(feeId)

            } else {

              return false;

            }
          });

          this.charges.splice(data, 1)

        },

        getRequestCharges() {

          this.loadCharge = true

          API.getChargesPerRequest(this.requestId).then(
            response => {

              if (response.data.status) {

                this.requestCharges = response.data.data;
                this.allowCancelAssessment(this.requestCharges)

              } else {

              }
              this.loadCharge = false
            }
          ).catch(e => {

            this.loadCharge = false

          });

        },

        allowCancelAssessment (data) {

          if(data.length <= 0) {

            this.thereIsCancelled = true

          } else {

            for (let i = 0; i < data.length; i++) {

              if(data[i].isCancelled) {

                this.thereIsCancelled = true

              } else {

                this.thereIsCancelled = false

              }

            }

          }

        },

        rejectAssesment() {


          if(!this.intendToCancel){

            this.intendToCancel = true;

          } else {

            if(this.reasonReject!==""){
              this.saveCharge = true
              this.cancelReasonError=false;
              API.RejectRequestAssessmentNotice(this.reasonReject,this.getRequestAssNotes.requestId,this.getRequestAssNotes.assessmentNoticeId).then((response)=>{
                this.setActive("Assessed");
                this.ErrorMessage = "";
                this.SuccessAlert = true;
                this.ErrorAlert = false;
                this.SuccessMessage = "Tariff successfully rejected";
                this.SuccessAlert = true;
                this.reasonReject = "";
                this.intendToCancel = false;
                this.saveCharge = false

              }).catch((error)=>{

                this.ErrorMessage = "Failed To Reject contact system administrator";
                this.SuccessAlert = false;
                this.ErrorAlert = true;
                this.SuccessMessage = "";
                this.SuccessAlert = false;
                this.intendToCancel = false;
                this.reasonReject = "";
                this.saveCharge = false

                this.loadNotification('success', 4000, 'Success',
                  'Charges Rejected Successfully',
                  'response', 1000 , true  , true);
                this.closeModalAndReload();

              });

            }else {
              this.cancelReasonError=true;
            }




          }
        },

        closeModal(){

          this.$emit('closeModal',true)

        },

        closeModalAndReload () {

          this.$emit('closeModalAndReload',true)

        },

        getTariffFees() {

          return new Promise((resolve, reject) => {

            API_REVENUE.getActiveTariffFee().then((response) => {

              this.fees=[];
              this.fees.push(response.data.data[0])
              //this.fees = response.data.data;
              resolve()

            }).catch(() => {

              reject()

            })

          })

        },

        getAssesmentByRequestId() {

          this.edit = false;
          this.assessmentLoader = true

          API.getRequestAssessmentNotice(this.requestId).then((response)=>{

            if(response.data.data!==null){

              this.getRequestAssNotes = response.data.data

              if(this.getRequestAssNotes.tariffItemUnit === 'FEU' || this.getRequestAssNotes.tariffItemUnit === 'TEU' || this.getRequestAssNotes.tariffItemUnit === 'Containerized Consignment') {

                this.labelText = 'Container Quantity'

              } else {

                this.labelText = 'Unit'

              }

            }
            this.assessmentLoader = false

          }).catch((e)=>{
            console.log(e);
            this.ErrorMessage = ", Failed To Load Tariff";
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = "";
            this.SuccessAlert = false;
            this.myloader = false;
            this.assessmentLoader = false
          })
        }

      }
    }
</script>

<style scoped>

.hover-me:hover {

  cursor: pointer;
  color: red;
}

.div-table-row {
    padding: 0 15px 0 15px;
}

.div-table {
    padding: 9px 8px 9px 8px;
}

.div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
}

.div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
}

.legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
}

.legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
}

.pointer{
  cursor: pointer;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}
</style>
<style>
.charge-custom-hr {
  border-top: 1px solid #2470d1;
  /* border-top: 1px dashed #2470d1; */
  /* border-top: 1px dotted #2470d1; */
  /* border: 1px solid #2470d1; */
}
</style>
