<template>
  <div>
    <card>
      <!-- <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-primary btn-space float-right mr-2 custom-btn"
            v-if="requests.length > 0"
            data-dismiss="modal"
            @click.prevent="gotoBulkBilling">
            Bulk Billing
          </button>
        </div>
      </div> -->
      <data-table
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :pageSizeProp="parseInt(pageSize)"
        :pageNo="pageNo"
        :myLoader="myLoader"
        :myFirstLoader="myFirstLoader"
        :noDataFound="noDataFound"
        :searchParameterProp="searchParameter"
        :loadingTitle="'Please wait while system is loading tally sheet charges'"
        :hasManageButton="false"
        :totalElements="totalElements"
        :totalPages="totalPages"
        :isFirst="isFirst"
        :isLast="isLast"
        :hasFilter="false"
        :pageNumber="pageNumber"
        :buttonList="[ {'code' : 'BillGenerate' , 'name' : 'View Charge' , 'class' : 'btn-primary'} ]"
        :whichModuleAndType="whichModuleAndType"
        :is-hovered="isHovered"
        :getRegimes="getRegimes"
        :getStations="getStations"
        :allCustomers="allCustomers"
        :permissions="[ {key: 'searchPermission', value: 'CLEARINGFORWARDING_SEARCH_CHARGED_REQUESTS_GET'} ]"
        @resetParams="resetParams"
        @search="searchGeneralChargeParams"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @onChange="onChange"
        @goToPage="goToPage"
        @goToBillGenerate="goToApplication"
      >
      <div slot = "pagination">
        <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </paginate>
      </div>
      </data-table>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading Tally Sheet Charges, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import GlobalModal from '../../../../components/globalLoader';
  import API from "../../../../api";
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

  export default {

    name: 'chargedTallySheets',

    data() {
      return {

        finalUserPermissions: [],

        color: 'green',
        size: '13px',
        loading: true,

        requests: [],
        filteredRequests: [],
        noDataFound: false,
        reloadAllRequest: false,
        hasSearchedVariable: false,
        searchParameter: '',
        pageNo: 0,
        pageSize: 15,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',
        pageCount: 1,
        myLoader: false,
        isHovered: [],
        myFirstLoader: false,
        whichModuleAndType: 'cfaCharge',
        allCustomers: [],
        eachRequestId: [],
        chargeRequests: [],
        getRegimes: [],
        getStations: [],
        requestIds: [],
        newParams: '',
        isPage: 0,
        pageNoPart: 0,

        tableHeaders: ['VOYAGE', 'vessel Name', 'operation Type', 'cargo Type', 'location Type', 'number Of Gangs', 'Billed'],
        tableDataKeys: ['voyage', 'vesselName','operationType', 'cargoType', 'locationType', 'numberOfGangs', 'billed'],
      }
    },

    computed: {

      ...mapGetters({

        getRequestList: "tallyNewbilling/getRequestList",
        getSuccessMessage: "tallyNewbilling/getSuccessMessage",
        getErrorAlert: "tallyNewbilling/getErrorAlert",
        getErrorMessage: "tallyNewbilling/getErrorMessage",
        getSuccessAlert: "tallyNewbilling/getSuccessAlert",
        getMyLoader: "tallyNewbilling/getMyLoader",
        getMyFirstLoader: "tallyNewbilling/getMyFirstLoader",
        getPageNo: "tallyNewbilling/getPageNo",
        getPageSize: "tallyNewbilling/getPageSize",
        getRequestId: "tallyNewbilling/getRequestId",
        getTotalElement: "tallyNewbilling/getTotalElement",
        getTotalPage: "tallyNewbilling/getTotalPage",
        getPageNumber: "tallyNewbilling/getPageNumber",
        getIsFirst: "tallyNewbilling/getIsFirst",
        getIsLast: "tallyNewbilling/getIsLast",
        getSearchParams: "tallyNewbilling/getSearchParams",
        getHasSearchedVariable: "tallyNewbilling/getHasSearchedVariable"
      }),

      ...mapGetters('global' , [

        'getAllCustomers',
        'getAllRegimes',
        'getAllStations'

      ]),

      getIsPage: {

        get: function () {
            return this.isPage
        },
        set: function (newValue) {
            this.isPage = newValue;
        }

      }

    },

    components: {

      GlobalModal,
      PulseLoader,
      DataTable: () => import('../../../../components/DataTable/dataTable')

    },

    created() {

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
      this.loadRegimes();
      this.loadStations();
      this.$store.dispatch('pagenames/currentPageName', 'Tally Sheet Charges')
      this.loadRequest();
      this.loadAllCustomers();

    },

    methods: {

      ...mapActions("tallyNewbilling", [

        "getAllUnbilledTallySheetCharges",
        "getAllUnbilledTallySheetChargesAfterCreated",
        "searchWithGeneralChargeParams",

      ]),

      ...mapActions("global", [

        'loadCustomers',
        'loadRegimes',
        'loadStations'

      ]),

      loadAllCustomers() {

          this.loadCustomers({}).then(() => {

            this.allCustomers = this.getAllCustomers;

          }).catch(() => {


          });

      },

      resetParams () {

        this.loadPageableRequestOnCreated(this.pageNo , this.pageSize)
        this.$store.dispatch("tallyNewbilling/hasSearchngParams", false);
        this.newParams = ""
        this.pageNoPart = 0
        this.isPage = this.pageNoPart + 1
        this.filteredRequests = []

      },

      loadRequest() {
        if (localStorage.getItem("search-params") !== null) {

          this.newParams = ""
          this.newParams = localStorage.getItem("search-params")

          let secondPart = this.newParams.split("size=")[1]
          this.pageNoPart = parseInt(secondPart.split("page=")[1])
          this.isPage = this.pageNoPart + 1

          //this.searchGeneralChargeParams(this.newParams) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page with selected page bug
          this.searchGeneralChargeParams(this.newParams, this.pageNoPart) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page without selected page bug

        } else {

            if (localStorage.getItem("page-no") !== null && localStorage.getItem("page-size") !== null) {

                this.$store.dispatch("tallyNewbilling/pageSizing", parseInt(localStorage.getItem("page-size")));
                this.$store.dispatch("tallyNewbilling/pageNumbering", parseInt(localStorage.getItem("page-no")));

                this.loadPageableRequestOnCreated(parseInt(localStorage.getItem("page-no")) , parseInt(localStorage.getItem("page-size")))
                this.isPage = parseInt(localStorage.getItem("page-no")) + 1

            } else {

              this.$store.dispatch("tallyNewbilling/pageSizing", this.pageSize);
              this.$store.dispatch("tallyNewbilling/pageNumbering", this.pageNo);

              this.loadPageableRequestOnCreated(this.pageNo , this.pageSize)
              this.isPage = this.pageNo + 1

            }

            this.$store.dispatch("tallyNewbilling/hasSearchngParams", false);
            this.newParams = ""
            this.pageNoPart = 0

        }

        this.filteredRequests = []

      },

      sortingData(data) {

        for(let x = 0 ; x < data.length ; x++) {

          const requestObject = {
            ...data[x],
            tansadNo: data[x].notification !== null ? data[x].notification.tansadNo : '',
            voyage: data[x].notification !== null ? data[x].notification.voyage : '',
            vesselName: data[x].notification !== null ? data[x].notification.vesselName : '',
            cargoType: data[x].notification !== null ? data[x].notification.cargoType : '',
            locationType: data[x].notification !== null ? data[x].notification.locationType : '',
            numberOfGangs: data[x].notification !== null ? data[x].notification.numberOfGangs : '',
            billed: data[x].billed ? 'YES' : 'NO'
            }

            this.filteredRequests.push(requestObject)

        }

      },
      loadRegimes () {

        API.getRegime().then(
          response => {
            if (response.data.status) {

              this.getRegimes = response.data.data

            } else {

            }
          }
        ).catch(e => {

        })
      },

      loadStations () {

      API.getStation().then(
        response => {
          if (response.data.status) {

            this.getStations = response.data.data

          } else {

          }
        }
      ).catch(e => {

      })
    },

      loadParameters(pageNo , pageSize) {

        return new Promise((resolve) => {

          this.$store.dispatch("tallyNewbilling/pageSizing", pageSize);
          this.$store.dispatch("tallyNewbilling/pageNumbering", pageNo);

          resolve();

        })

      },

      loadSearchParameters(params, fromApplicationPageNo) {

        let fromApplicationPageNoOrNot
        typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0

        let firstPart = params.split("&page=")[0]
        let secondPart = firstPart.split("size=")[0]
        this.newParams = ""

        this.isPage = fromApplicationPageNo
        this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + fromApplicationPageNoOrNot

        return new Promise((resolve) => {

          this.$store.dispatch("tallyNewbilling/pageSizing", this.pageSize);
          this.$store.dispatch("tallyNewbilling/pageNumbering", 0);
          this.$store.dispatch("tallyNewbilling/paramsSearching", params);

          resolve();

        })

      },

      // gotoBulkBilling(){

      //   this.$router.push({name:"bulkBilling"})
      // },

      setAllGetters(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;
        this.SuccessMessage = this.getSuccessMessage;
        this.ErrorMessage = this.getErrorMessage;
        this.ErrorAlert = this.getErrorAlert;
        this.SuccessAlert = this.getSuccessAlert;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;

        this.sortingData(this.requests)

      },

      searchGeneralChargeParams(params, fromApplicationPageNo) {

        this.newParams = params

        this.filteredRequests = []

        this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

          this.searchWithGeneralChargeParams({}).then(() => {

            this.setAllGetters('onCreated')

          }).catch(() => {


          });

          this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {


        });

      },

      loadPageableRequestOnCreated(pageNo , pageSize) {

        this.loadParameters(pageNo , pageSize).then(() => {

          this.getAllUnbilledTallySheetCharges({}).then(() => {

            this.setAllGetters('onCreated')

          }).catch(() => {


          });

          this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {


        });

      },

      loadPageableRequestsAfterCreated(pageNo , pageSize) {

        this.loadParameters(pageNo , pageSize).then(() => {

          this.getAllUnbilledTallySheetChargesAfterCreated({}).then(() => {

            this.setAllGetters('afterCreated');

          });

          this.myLoader = this.getMyLoader;

        }).catch(() => {


        });

      },

      goToApplication(object) {

        document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
        // this.$router.push('/shipping-tally/tally-sheet-charge-view/' + object.tallyDocumentId);
        this.$router.push({name: "TallySheetChargeDetails", params : {
            obj: {...object}, chargeId: object.tallySheetChargeId }});
        if (this.getHasSearchedVariable) {

          localStorage.setItem("search-params", this.newParams);
          // localStorage.setItem("cfa-customer", JSON.stringify(this.allCustomers));
          this.newParams = ""
        } else {
          localStorage.setItem("page-no", this.getPageNo);
          localStorage.setItem("page-size", this.getPageSize);
        }

      },

      onChange(event) {

        this.$store.dispatch("tallyNewbilling/pageSizing", event);
        this.$store.dispatch("tallyNewbilling/pageNumbering", 0);
        localStorage.removeItem("page-no")
        localStorage.removeItem("page-size")
        this.isPage = 0

        this.pageSize = this.getPageSize;
        this.pageNo = this.getPageNo;

        if (this.getHasSearchedVariable) {

          let firstPart = this.newParams.split("&page=")[0]
          let secondPart = firstPart.split("size=")[0]
          let thirdPart = firstPart.split("size=")[1]
          this.newParams = ""
          this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + 0
          this.pageNoPart = 0

          this.searchGeneralChargeParams(this.newParams , this.pageNoPart);
          this.filteredRequests = []

        } else {

          this.loadPageableRequestOnCreated(this.pageNo , this.pageSize);
          this.filteredRequests = []
        }

      },

      goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1

        this.pageNo = page;

        if (this.getHasSearchedVariable) {

          let firstPart = this.newParams.split("page=")[0]
          this.newParams = ""
          this.newParams = firstPart + '&page=' + this.pageNo
          this.pageNoPart = this.isPage

          this.searchGeneralChargeParams(this.newParams, this.pageNoPart);
          this.filteredRequests = []

        } else {

          this.loadPageableRequestsAfterCreated(this.pageNo , this.pageSize);
          this.filteredRequests = []
        }

      },

      showToolTip(id) {
        this.isHovered = [id]
      },

      hideToolTip(id) {
        this.isHovered.splice(this.isHovered.indexOf(id), 1)
      }

    }

  }
</script>

<style scoped>

  .btn-space {
    margin-right: 10px !important;
  }

  .btn-success-custom {
    padding: 0 4px 0 4px;
    color: #3cb371;
  }

  .btn-danger-custom {
    padding: 0 4px 0 4px;
    color: #e97451;
  }

  .btn-success-custom:hover {
    cursor: pointer;
    color: green;
  }

  .btn-danger-custom:hover {
    cursor: pointer;
    color: red;
  }

  .table-striped tr {
    line-height: 20px;
  }

  .custom-text-filed {
    border-radius: 5px;
  }

  .btn-disabled:hover {
    cursor: not-allowed;
    color: #dcdcdc !important;
  }

  .my-green-bc {
    background-color: rgba(0, 128, 0, .1);
  }

  .remove-charge-from-list {
    color: red;
  }

  .remove-charge-from-list:hover {
    cursor: pointer;
  }

  .disabled-click {
    pointer-events: none;
    color: #dcdcdc;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
  }

  .enabled-click {
    pointer-events: auto;
  }

  .search .form-group .input-group .input-group-addon {
    width: 40px;
    text-align: center;
    padding-top: 3.5px;
    background-color: rgba(220, 220, 220, .3);
  }

  .disable-highlight:hover span {
    cursor: pointer;
  }

  .bg-grey {
    background-color: #dcdcdc;
  }

  .refresh-all {
    margin-top: 6px;
    margin-right: 20px
  }

  .refresh-all:hover {
    cursor: pointer;
  }

  .search .form-group .input-group #inputGroup {
    width: 250px;
    padding-top: 3.5px;
  }

  .search .form-group .input-group .input-group-addon:hover {
    cursor: pointer;
    background-color: rgba(220, 220, 220, .5);
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    background-color: #FFFFFF !important;
  }

  .table td, .table th {
    text-align: center;
  }

  .text-center {
    text-align: center !important;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(153, 186, 221, .5) !important;
  }

  .more-customer-details:hover {
    cursor: pointer;
    color: #d59a18;
  }

  .close-info {
    color: blue !important;
  }

  .close-info:hover {
    cursor: pointer;
    color: green !important;
  }

</style>
