<template>
  <div>
    <card v-if="!myFirstLoader">
      <div class="row">
        <div class="col-md-10">
          Notice List for Application: File No. - [ <b> {{ singleRequest.fileNo }} </b> ] |
          BL/Airway Bill/RCN - [ <b> {{ singleRequest.blNumber }} </b> ]
        </div>
        <div class="col-md-2 text-right">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="noticelistLoader"
          />

          <button
            class="btn btn-fill btn-outline-info float-right custom-btn-all"
            @click.prevent="goBack"
            v-if="!noticelistLoader"
          >
            Back
          </button>
          <!-- <button
            class="btn btn-primary btn-space  float-right custom-btn mr-2"
            @click.prevent="goToDeclaration"
            v-if="!noticelistLoader && !hasPreUrl"
          >
            View Declaration
          </button> -->
        </div>
      </div>
    </card>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="filteredRequests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :is-pageable="false"
        :no-data-found="noDataFound"
        :has-pagination="false"
        :has-filter="false"
        :has-manage-button="false"
        :has-view-more-assessment="true"
        :loading-title="'Please wait while the system is loading notice list for Application file number [ ' + singleRequest.fileNo + ' ]'"
        :permissions="[ {key: 'searchPermission', value: 'NOTICE_NOTICES_{REQUESTID}_GET'} ]"
        @goToViewMoreAssessment="getAssessmentNoticePerRequest">
      </data-table>

      <global-modal v-if="myLoader">
        <div slot="globalLoader">
          <div class="row text-center">
            <div class="col-md-12">
              {{ message }}
            </div>
            <div class="col-md-12">
              <br>
            </div>
            <div class="col-md-12">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
              />
            </div>
          </div>
        </div>
      </global-modal>

      <!-- <table
        class="table table-striped"
        v-if="!noDataFound"
      > -->
        <!-- <thead>
          <tr>
            <th>#</th>
            <th>BL/Airway Bill/RCN</th>
            <th>Notice Name</th>
            <th>TANSAD Serial no.</th>
            <th>Invoice No.</th>
            <th>Total Count</th>
            <th>Process Start Date</th>
            <th>Process End Date</th>
            <th>Status</th>
            <th
              class="special-limit"
              scope="col"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(noticelist , index) in noticelists">
            <td>{{ index + 1 }}</td>
            <td>{{ noticelist.blNo }}</td>
            <td>{{ noticelist.documentName }}</td>
            <td>{{ noticelist.tansadSerialNo }}</td>
            <td>{{ noticelist.invoiceNo }}</td>
            <td>{{ noticelist.totalCnt }}</td>
            <td>{{ noticelist.processStartDt }}</td>
            <td>{{ noticelist.processEndDt }}</td>
            <td>{{ noticelist.noticeStatus }}</td>
            <td>
              <span
                @click="getAssessmentNoticePerRequest(noticelist.noticeListId , requestId)"
                v-if="noticelist.documentName === 'Assessment Notice' && noticelist.documentName === 'Release Notice' && !hasNoticeId.includes(noticelist.noticeListId) "
                class="assessment-view"
              >
                view Assessment
              </span>
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                v-if="hasNoticeId.includes(noticelist.noticeListId)"
              />
            </td>
          </tr>
        </tbody> -->
      <!-- </table> -->
    </card>
  </div>
</template>

<script>

import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader';

export default {

  name: 'ListView',

  components: {

      PulseLoader,
      GlobalModal,
      DataTable: () => import('../../../components/DataTable/dataTable'),

  },

  data () {

    return {

      noDataFound: '',
      noticelistLoader: false,
      noticelists: [],
      requestId: '',
      hasNoticeId: [],
      singleRequest: {},
      message: '',
      filteredRequests: [],

      myFirstLoader: false,
      myLoader: false,
      globalLoader: false,
      preUrl: '/clearing-and-forwarding/declaration/',
      hasPreUrl: false,
      color: 'green',
      size: '12px',
      loading: true,

      tableHeaders: ['BL/Airway Bill/RCN', 'Notice Name', 'TANSAD Number', 'Invoice Number', 'Total Count', 'Process Start Date', 'Process End Date', 'Status'],
      tableDataKeys: ['blNo', 'documentName', 'tansadSerialNo', 'invoiceNo', 'totalCnt', 'processStartDt', 'processEndDt','noticeStatus'],

    }
  },

  created () {

    this.$store.dispatch('pagenames/currentPageName', 'Notice list');

    if (this.$route.params.requestId) {
      this.requestId = this.$route.params.requestId;

      this.loadRequestNoticelists(this.$route.params.requestId)
    }

    if (window.$cookies.isKey('browser-local-cashed-url-back-to-2')) {
      if (window.$cookies.get('browser-local-cashed-url-back-to-2').includes(this.preUrl)) {
        this.hasPreUrl = true
      }
    }

    if (window.$cookies.isKey('browser-local-cashed-url-back-to')) {
      if (window.$cookies.get('browser-local-cashed-url-back-to').includes(this.preUrl)) {
        this.hasPreUrl = true
      }
    }

  },

  methods: {

    loadRequestNoticelists (data) {
      this.noticelistLoader = true;
      this.myFirstLoader = true;

      API.getSingleRequest(data).then(
        response => {
          if (response.data.status) {
            this.singleRequest = response.data.data;
            API.getNoticeListLocal(data).then(
              response => {
                if (response.data.status) {
                  this.noticelists = response.data.data;
                  this.sortingData(this.noticelists)
                  this.noticelistLoader = false;
                  this.myFirstLoader = false;
                  this.noDataFound = false

                } else {
                  this.noDataFound = true
                }
              }
            ).catch(() => {
              this.noticelistLoader = false;
              this.myFirstLoader = false
            })
          } else {}
        }
      ).catch(() => {
        this.noticelistLoader = false;
        this.myFirstLoader = false
      })
    },

    sortingData(data) {

          for(let x = 0 ; x < data.length ; x++) {

              let requestObject = {

                  ...data[x],
                  tansadSerialNo: data[x].referenceYy + '-' + data[x].customsOfficeCd + '-' + data[x].tansadSerialNo

              }

              this.filteredRequests.push(requestObject)

          }

      },

    getAssessmentNoticePerRequest (data) {

      this.hasNoticeId.push(data.noticeListId);
      this.myLoader = true;
      this.message = 'Loading ' + data.documentName +' , Please wait';

      if (data.documentName === 'Release Notice') {
        this.getReleaseNoticeData(data.noticeListId, this.requestId);
      } 
      else if (data.documentName === 'Assessment Notice') {
        this.getAssessmentNoticeData(data.noticeListId, this.requestId);
      }
    },

    getAssessmentNoticeData(noticeListId, requestId) {
      API.getAssessmentNotice(noticeListId, requestId).then(
        response => {
          if (response.data.status) {
            this.hasNoticeId = [];
            document.cookie = 'browser-local-cashed-url-back-to-notice-list-inside=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
            this.$router.push('/clearing-and-forwarding/assessment-notice/view/' + noticeListId + '/' + requestId);
            this.myLoader = false;
            this.message = ''
          } else {
            this.myLoader = false;
            this.loadNotification('error', 4000, 'Notice List Message',
            typeof response.data.data !== 'undefined' ? response.data.data : response.data.message,
            'response', 1000 , true  , true)
            // this.message = 'Assessment not found'
          }

        }
      ).catch(() => {
        this.hasNoticeId = [];
        this.myLoader = false;
        this.message = ''
      })
    },

    getReleaseNoticeData(noticeListId, requestId) {
      API.getReleaseNotice(noticeListId, requestId).then(
        response => {
          
          if (response.data.status) {

            this.hasNoticeId = [];
            document.cookie = 'browser-local-cashed-url-back-to-notice-list-inside=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
              this.$router.push('/clearing-and-forwarding/release-notice/view/' + noticeListId + '/' + requestId);
            this.myLoader = false;
            this.message = ''

          } else {

            this.myLoader = false;
            this.loadNotification('error', 4000, 'Notice List Message',
            typeof response.data.data !== 'undefined' ? response.data.data : response.data.message,
            'response', 1000 , true  , true)

          }
        }
      ).catch(() => {
        this.hasNoticeId = [];
        this.myLoader = false;
        this.message = ''
      })
    },

    goToDeclaration () {
      this.noticelistLoader = true;
      API.getSingleRequest(this.requestId).then(
        response => {
          if (response.data.status) {
            if (response.data.data.referenceSerialNo !== null && response.data.data.referenceTIN !== null && response.data.data.referenceYear !== null) {
              document.cookie = 'browser-local-cashed-url-back-to=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
              this.$router.push('/clearing-and-forwarding/declaration/' + this.requestId);
              this.noticelistLoader = false
            }

          } else {}
        }

      ).catch(() => {
        this.noticelistLoader = false
      })
    },

    goBack () {
      if (window.$cookies.isKey('browser-local-cashed-url-back-to-notice-list')) {

        this.$router.push(window.$cookies.get('browser-local-cashed-url-back-to-notice-list'));
        window.$cookies.remove('browser-local-cashed-url-back-to-notice-list')

      } else if (window.$cookies.isKey('browser-local-cashed-url-back-to')) {
        
        this.$router.push(window.$cookies.get('browser-local-cashed-url-back-to'))
        window.$cookies.remove('browser-local-cashed-url-back-to')

      } else {

        this.$router.go(-1);

      }
      
    }
  }

}
</script>

<style scoped>

    .assessment-view {
        color: green;
    }

    .assessment-view:hover {
        color: blue;
        cursor: pointer;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
        background-color: #FFFFFF !important;
    }

    .custom-btn-all {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }

</style>
