<template>
  <div>
    <card
      style="font-size: 12px"
      v-if="!thisFirstRequestLoader && finalUserPermissions.includes('BILLS_GET-INVOICE-PDF_{BILLID}_GET')">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-justified"
            style="padding-left: 10px;padding-right: 10px">
            <li > <!-- v-if="singleRequest.status !== 'Not Approved'" -->
              <button
                @click="openInvoicePdfViewer(singleRequest.billId, 'view')"
                class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                Download Invoice
              </button>
            </li>

            <li> <!-- v-if="singleRequest.status !== 'Not Approved'" -->
              <button
                @click="openMatrixInvoicePdfViewer(singleRequest.billId, 'view')"
                class="btn btn-purple btn-purple-fill mr-2 custom-btn">
                Download Matrix Printer Invoice
              </button>
            </li>

            <li v-if="singleRequest.status === 'Paid'">
              <button
                @click="openReceiptPdfViewer(singleRequest.billId, 'view')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                Download Receipt
              </button>
            </li>

            <li v-if="singleRequest.status !== 'Paid' && singleRequest.status !== 'Canceled' && singleRequest.status !== 'Bill Expired'">
              <button
                @click="openModal(singleRequest , 'CANCEL_BILL')"
                class="btn btn-danger-custom btn-danger-custom-fill mr-2 custom-btn">
                Cancel Bill
              </button>
            </li>

            <li
              v-if="singleRequest.dueDate !== null && singleRequest.dueDate.split('T')[0] <= todayDate
                && singleRequest.status === 'Not Paid' && singleRequest.status !== 'Not Approved'
                && singleRequest.status !== 'Canceled' || singleRequest.status === 'Bill Expired'">
              <button
                @click="setExtendDaysParams(singleRequest.billId , singleRequest.controlNumber)"
                class="btn btn-yellow btn-yellow-fill mr-2 custom-btn">
                Extend Bill Expire Period
              </button>
            </li>

            <li
              v-if="singleRequest.status === 'Waiting Control Number' || singleRequest.status === 'Submiting to GePG'">
              <!-- singleRequest.dueDate !== null && singleRequest.dueDate.split('T')[0] >= todayDate && -->
              <button
                @click="resubmitBill(singleRequest.billId , singleRequest.company.name)"
                class="btn btn-olive btn-olive-fill mr-2 custom-btn">
                Resubmit Bill to GEPG
              </button>
            </li>

            <li
              v-if="singleRequest.fiscalization === null && singleRequest.status !== 'Not Approved'
                && singleRequest.status !== 'Canceled' && singleRequest.controlNumber !== 'null'
                && singleRequest.company !== null ">
              <button
                @click="fiscaliseBill(singleRequest.billId , singleRequest.company.name)"
                class="btn btn-black btn-black-fill mr-2 custom-btn">
                Reconciliation to TRA
              </button>
            </li>

            <li v-if="singleRequest.status !== 'Not Approved' && singleRequest.status !== 'Canceled'">
              <button
                @click="openModal(singleRequest , 'SHOW_LOGS')"
                class="btn btn-linen btn-linen-fill mr-2 custom-btn">
                Bill Logs
              </button>
            </li>

            <li v-if="singleRequest.billType === 'CFA'">
              <button
                @click="openPdfViewer(singleRequest.assessmentNoticeDto.assessmentNoticeDocUrl, 'ASSESSMENT_FOR_APPLICATION_FILE_NUMBER_' + singleRequest.tansadno, 'view')"
                class="btn btn-linen btn-linen-fill mr-2 custom-btn">
                View Assessment Document
              </button>
            </li>
            <li v-if="!singleRequest.bulk && singleRequest.billType === 'CFA'">
            <button
                @click="goToApplication(singleRequest.documentId)"
                class="btn btn-dark-blue-like btn-dark-blue-like-fill mr-2 custom-btn">
                View Associated Application
            </button>
            </li>
            <li class="float-right" v-if="singleRequest.status !== 'Not Approved' && singleRequest.status !== 'Canceled'
            && singleRequest.status !== 'Waiting Control Number' && singleRequest.status !== 'Submiting to GePG' && singleRequest.status !== 'Bill Expired'">
              <a :href="singleRequest.fiscalization" target="_blank" title="This link will take you to an external site">View Fiscalization</a>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card v-if="thisFirstRequestLoader">
      <div
        class="row"
      >
        <div class="col-md-12 text-center loader-color">
          <b>Please wait while system is loading bill details</b>
        </div>
        <div class="col-md-12 text-center">
            <br>
        </div>
        <div class="col-md-12 text-center">
            <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            />
        </div>
      </div>
    </card>
    <card style="font-size: 12px" v-if="!thisFirstRequestLoader">
      <div
        class="row"
        style="padding: 0 15px 0 15px"
        v-if="!thisFirstRequestLoader">
        <div
          class="col-md-12 alert-dismissible fade show"
          role="alert"
          :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
          style="border-radius:5px;"
          @enter="enter"
        >
          <strong v-if="SuccessAlert">Successfully!</strong> <span
            v-if="SuccessAlert"
          >{{ SuccessMessage }}</span>
          <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            v-if="SuccessAlert"
            style="line-height:2px;"
            @click="fadeMe"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            v-if="ErrorAlert"
            style="line-height:2px;"
            @click="fadeMe"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <br v-if="(SuccessAlert || ErrorAlert) && !thisFirstRequestLoader">
      <div
        class="row"
        v-if="!thisFirstRequestLoader"
      >
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Bill Details
            </h6>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Current Application Bill Stage / Status
              </div>
              <div
                v-if="singleRequest.status === 'Submiting to GePG' "
                class="col-md-3 div-table div-table-value"
                style="color: #808080;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Bill Expired' "
                class="col-md-3 div-table div-table-value"
                style="color: red;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Not Paid' "
                class="col-md-3 div-table div-table-value"
                style="color: #808080;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Paid' "
                class="col-md-3 div-table div-table-value"
                style="color: green;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Canceled' "
                class="col-md-3 div-table div-table-value"
                style="color:red;font-weight: 900"
              >
                <div class="row">
                  <div class="col-md-8">
                    {{ applicationStatus }}
                  </div>
                  <div class="col-md-4 tooltip-pipe">
                    <font-awesome-icon
                      icon="comment"
                      class="cancel-message text-right"
                      color="green"
                    />
                    <span class="tooltip-text text-center">
                      {{ cancelledMessage }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="singleRequest.status === 'Waiting Control Number' "
                class="col-md-3 div-table div-table-value"
                style="color: #003d00;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div
                v-if="singleRequest.status === 'Not Approved' "
                class="col-md-3 div-table div-table-value"
                style="color: #f4a460;font-weight: 900"
              >
                {{ applicationStatus }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Bill Type
              </div>
              <div
                class="col-md-3 div-table div-table-value"
                v-if="singleRequest.bulk"
              >
                Bulk ( {{ singleRequest.bulkBill.length }} Items )
              </div>
              <div
                class="col-md-3 div-table div-table-value"
                v-if="!singleRequest.bulk"
              >
                Single
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Bill Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.billNo }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                GEPG Control Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.controlNumber }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Billed By
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.generatedBy }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Approved By
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleRequest.approvedBy }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Amount ({{ singleRequest.billCurrency }})
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.billCurrency === 'USD' ? putComma(amountDecimalPlaces(singleRequest.totalBillAmount)) : putComma(amountDecimalPlaces(singleRequest.totalBillAmountTzs)) }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                VAT ({{ singleRequest.billCurrency }})
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.billCurrency === 'USD' ? putComma(amountDecimalPlaces(singleRequest.billVatAmount)) : putComma(amountDecimalPlaces(singleRequest.billVatAmountTzs)) }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Vated Amount ({{ singleRequest.billCurrency }})
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.billCurrency === 'USD' ? putComma(amountDecimalPlaces(singleRequest.totalVatedAmount)) : putComma(amountDecimalPlaces(singleRequest.billVatedAmountTzs)) }}
              </div>
            </div>

            <br>
            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Billed Date
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ dateTimeToHuman(singleRequest.dateBilled) }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Approved Date
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ dateTimeToHuman(singleRequest.startDate) }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Due Date
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ dateTimeToHuman(singleRequest.dueDate) }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-12"
          v-if=" singleRequest.company !== null"
        >
          <div class="legend">
            <h6 class="legend-title">
              Customer Details
            </h6>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Name
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.company.name }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Email Address
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.company.email }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Mobile
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.company.mobile }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                TIN
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ putHyphen(singleRequest.company.tin) }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                VRN
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.company.vrn }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Address
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.company.address }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              TASAC Service(s) Provided (Bill Description)
            </h6>

            <div class="row div-table-row">
              <div class="col-md-1 div-table div-table-title">
                #
              </div>
              <div class="col-md-3 div-table div-table-title">
                Service Name
              </div>
              <div class="col-md-3 div-table div-table-title">
                Description
              </div>
              <div class="col-md-2 div-table div-table-title">
                Quantity
              </div>
              <div class="col-md-1 div-table div-table-title">
                Amount ({{singleRequest.billCurrency}})
              </div>
              <div class="col-md-1 div-table div-table-title">
                VAT ({{singleRequest.billCurrency}})
              </div>
              <div class="col-md-1 div-table div-table-title">
                Vated Amount ({{singleRequest.billCurrency}})
              </div>
            </div>

            <div class="row div-table-row" v-for="(service , index_service) in singleRequest.billItems" :key="index_service">
              <div class="col-md-1 div-table div-table-value">
                {{ index_service + 1 }}
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ service.serviceName }}
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ service.description }}
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ service.additionalItems.length > 0 ? service.quantity + ' and ' + service.additionalItems[0].description : service.quantity }}
              </div>
              <div class="col-md-1 div-table div-table-value">
                {{singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(service.totalAmountTzs - service.billVatAmountTzs)) : putComma(amountDecimalPlaces(service.billAmount)) }}
              </div>
              <div class="col-md-1 div-table div-table-value">
                {{singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(service.billVatAmountTzs)) : putComma(amountDecimalPlaces(service.billVatAmount)) }}
              </div>
              <div class="col-md-1 div-table div-table-value">
                {{singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(service.totalAmountTzs)) : putComma(amountDecimalPlaces(service.totalAmount)) }}
              </div>
            </div>

            <div class="row div-table-row">
              <div class="col-md-7 div-table div-table-value text-center">
                <b>TOTAL</b>
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.billItems.length > 0 ? additionalCargo !== '' ? singleRequest.billItems[0].quantity + ' and ' + additionalCargo : singleRequest.billItems[0].quantity : '' }}
              </div>
              <div class="col-md-1 div-table div-table-value">
                {{ singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(singleRequest.totalBillAmountTzs)): putComma(amountDecimalPlaces(singleRequest.totalBillAmount))}}
              </div>
              <div class="col-md-1 div-table div-table-value">
                {{ singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(singleRequest.billVatAmountTzs)): putComma(amountDecimalPlaces(singleRequest.billVatAmount))}}
              </div>
              <div class="col-md-1 div-table div-table-value">
                {{ singleRequest.billCurrency === 'TZS'? putComma(amountDecimalPlaces(singleRequest.billVatedAmountTzs)): putComma(amountDecimalPlaces(singleRequest.totalVatedAmount))}}
              </div>
            </div>
          </div>
        </div>

        <br v-if="singleRequest.bulkBill !== null && singleRequest.bulk">

        <div
          class="col-md-12"
          v-if="singleRequest.bulkBill !== null && singleRequest.bulk"
        >
          <div class="legend">
            <b><h6 class="legend-title">Bulk Bills</h6></b>
            <div class="row div-table-row mb-3">
              <div class="col-md-12">
                <div class="col-md-12 text-center">
                  <pulse-loader
                    :loading="removeBulkBillingLoader"
                    :color="color"
                    :size="size"
                  />
                </div>

                <div class="float-right" v-if="itemToRemoveList.length !== 0">
                  <button class="btn btn-danger-custom btn-danger-custom-fill custom-btn" @click="removeBulkItem">Remove from Bulk Bill</button>
                </div>
              </div>
            </div>

            <div class="bulk-bill-scroll">
              <div class="row div-table-row"  v-for="(service , index_service) in singleRequest.bulkBill" :key="index_service">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-2 div-table div-table-title">
                            Bill Batch Number
                        </div>
                        <div class="col-md-1 div-table div-table-title">
                            Exchange Rate
                        </div>
                        <div class="col-md-2 div-table div-table-title">
                            TANSAD Number
                        </div>
                        <div class="col-md-2 div-table div-table-title">
                            VAT
                        </div>

                        <div class="col-md-2 div-table div-table-title">
                            Amount
                        </div>
                        <div class="col-md-2 div-table div-table-title">
                            Vated Amount
                        </div>
                        <div class="col-md-1 div-table div-table-title text-center">
                            Action
                        </div>
                    </div>
                </div>
                <div class="col md-12 even-color">
                    <div class="row">
                        <div class="col-md-2 div-table div-table-value">
                            {{ service.billNo }}
                        </div>
                        <div class="col-md-1 div-table div-table-value">
                            {{ putComma(service.exchangeRate) }}
                        </div>
                        <div class="col-md-2 div-table div-table-value">
                            {{ service.tansaNo }}
                        </div>
                        <div class="col-md-2 div-table div-table-value">
                            {{ putComma(service.billVatAmountTzs) }}
                        </div>
                        <div class="col-md-2 div-table div-table-value">
                            {{ putComma(service.totalBillAmountTzs) }}
                        </div>
                        <div class="col-md-2 div-table div-table-value">
                            {{ putComma(service.billVatedAmountTzs) }}
                        </div>
                        <div class="col-md-1 div-table div-table-value text-center">
                            <button @click="goToApplication(service.documentId)" class="btn btn-linen btn-linen-fill mr-2 custom-btn-in-table">
                                view application
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-1 div-table div-table-title">
                          Item #
                      </div>
                      <div class="col-md-4 div-table div-table-title">
                          Description
                      </div>
                      <div class="col-md-1 div-table div-table-title">
                          Quantity
                      </div>
                      <div class="col-md-2 div-table div-table-title">
                          Service Name
                      </div>
                      <div class="col-md-2 div-table div-table-title">
                          Total Amount (TZS) (VAT included)
                      </div>
                      <div class="col-md-2 div-table div-table-title text-center">
                          Action
                      </div>
                    </div>
                </div>
                <div class="col-md-12  odd-color" v-for="(service2 , index_service2) in service.bulkBillItems" :key="index_service2">
                  <div class="row">
                    <div class="col-md-1 div-table div-table-value">
                      {{ index_service + 1}}
                    </div>
                    <div class="col-md-4 div-table div-table-value">
                      {{ service2.description }}
                      <font-awesome-icon
                        icon="comment"
                        class="cancel-message text-right"
                        color="green"
                        @click="selecteCommentForbill(service2)"
                      />
                    </div>
                    <div class="col-md-1 div-table div-table-value">
                      {{ service2.quantity }}
                    </div>
                    <div class="col-md-2 div-table div-table-value">
                      {{ service2.serviceName }}
                    </div>
                    <div class="col-md-2 div-table div-table-value">
                      {{ putComma(service2.totalAmountTzs) }}
                    </div>
                    <div class="col-md-2 div-table div-table-value text-center">
                      <button class="btn btn-fill btn-sm btn-success custom-btn-in-table" @click="addItemToRemove(service2.bulkBillId)" v-if="itemToRemoveList.includes(service2.bulkBillId)===false && singleRequest.status ==='Not Approved'">
                        <span v-if="loadingBulkBillingItemId!==service2.bulkBillId">Select</span>
                      </button>
                      <button class="btn btn-sm btn-danger custom-btn-in-table" @click="removeItemToRemove(service2.bulkBillId)" v-if="itemToRemoveList.includes(service2.bulkBillId)">
                        <span v-if="loadingBulkBillingItemId!==service2.bulkBillId">Unselect</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                    <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <br v-if="singleRequest.bulkBill === null && !singleRequest.bulk">

        <div class="col-md-12" v-if="singleRequest.bulkBill === null && !singleRequest.bulk && singleRequest.assessmentNoticeDto !== null">
          <div class="legend">
            <h6 class="legend-title">
              Assessment Notice Details
            </h6>

            <!-- <div class="legend" style="display: none">
              <h6 class="legend-title">
                Container
              </h6>

              <div class="legend">
                <div class="row div-table-row">
                  <div class="col-md-3 div-table div-table-title">
                    Size
                  </div>
                  <div class="col-md-3 div-table div-table-value" />
                  <div class="col-md-3 div-table div-table-title">
                    Number
                  </div>
                  <div class="col-md-3 div-table div-table-value" />
                </div>

                <br>

                <div class="row div-table-row">
                  <div class="col-md-6 div-table div-table-title">
                    <b>Total</b>
                  </div>
                  <div class="col-md-6 div-table div-table-value" />
                </div>
              </div>
            </div>

            <br> -->

            <div class="legend">
              <h6 class="legend-title">
                Details
              </h6>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Custom Value (TZS)
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.customValue !== null ? putComma(singleRequest.assessmentNoticeDto.customValue) : '' }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Operation Type
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.operationType === 'IMP' ? 'Import' : 'Export'}}
                </div>
              </div>

              <br>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Cargo Tonnage
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.cargoTonnage === 0 || singleRequest.assessmentNoticeDto.cargoTonnage === null ? 'N/A' : putComma(singleRequest.assessmentNoticeDto.cargoTonnage) }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Litre
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.cargoVolume === 0 || singleRequest.assessmentNoticeDto.cargoVolume === null ? 'N/A' : putComma(singleRequest.assessmentNoticeDto.cargoVolume) }}
                </div>
              </div>

              <br>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Cargo CBM
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.cargoCbm === 0 || singleRequest.assessmentNoticeDto.cargoCbm === null ? 'N/A' : putComma(singleRequest.assessmentNoticeDto.cargoCbm) }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  TANSAD / BT Number
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.tansadNo }}
                </div>
              </div>

              <br>

              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  {{ labelText }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.quantity === 0 || singleRequest.assessmentNoticeDto.quantity === null ? 'N/A' : putComma(singleRequest.assessmentNoticeDto.quantity, 0) }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Tariff Used
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.tariffItemName }}
                </div>
              </div>
                <br />
              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Vessel Name
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.vesselName === '' || singleRequest.assessmentNoticeDto.vesselName === null ? 'N/A' : singleRequest.assessmentNoticeDto.vesselName }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Tariff Item Unit
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.tariffItemUnit }}
                </div>
              </div>
              <br />
              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  Invoice Number
                </div>
                <div class="col-md-9 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.invoiceNumber }}
                </div>
              </div>
              <br />
            <div class="legend" v-if="singleRequest.assessmentNoticeDto !== null && singleRequest.assessmentNoticeDto.additionalCargos !== null">
              <h6 class="legend-title" v-if="singleRequest.assessmentNoticeDto.multipleTariffs">
                Additional Information
              </h6>
              <div class="row div-table-row">
                <div class="col-md-3 div-table div-table-title">
                  {{ labelText }}
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ putComma(singleRequest.assessmentNoticeDto.additionalCargos[0].quantity, 0)}}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Tariff Used
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{ singleRequest.assessmentNoticeDto.additionalCargos[0].tariffItemName }}
                </div>
              </div>
            </div>
            </div>
          </div>

          <!-- <div class="legend">
            <h6 class="legend-title">
              Associated Attachment
            </h6>

            <div>
              <attachments :request-id="singleRequest.documentId"></attachments>
            </div>
          </div> -->
          </div>
      </div>
      <div
        class="row text-right"
        v-if="!thisFirstRequestLoader"
      >
        <div class="col-md-8 pt-3">
        </div>
        <div
          class="col-md-4"
          v-if="singleRequest.company !== null"
        >
          <button
            type="submit"
            class="btn btn-primary mr-2 custom-btn"
            v-if="!singleRequest.billed && singleRequest.status === 'Not Approved' "
            @click="approveBill(singleRequest.billId , singleRequest.company.name)"
            data-dismiss="modal"
          >
            <font-awesome-icon icon="thumbs-up" /> &nbsp; Approve Bill to GePG
          </button>
          <button
            class="btn btn-default btn-fill custom-btn"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
    </card>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            <!-- Downloading requested {{ globalLoaderName }}, Please wait -->
            Preparing requested file, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <modal v-if="showModalBillDescription">
      <div slot="body">
        <div class="text-center">
          <pulse-loader
            :loading="editBillItemLoader"
            :color="color"
            :size="size"
          />
        </div>
        <div class="form-group">
          <label for="description"><strong>Edit Bulk Bill Item Description</strong></label>
          <textarea class="form-control" id="description" rows="3" v-model="selectedBillDescription"></textarea>
          <button class="btn btn-success float-right mt-3 custom-btn" @click="editBulkBillSubDescription">Save</button>
          <button class="btn btn-fill btn-default float-right mt-3 mr-3 custom-btn" @click="closeModal">Close</button>
        </div>
      </div>
    </modal>
    <modal v-if="showModal">
      <div
        slot="body"
        v-if="cancelBillModal"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="cancel">Please provide reason for cancelling this bill [ <b>Bill Number
                : {{ relativeControlNumberForCancel }}</b> ]</label>
              <textarea
                class="form-control"
                id="cancel"
                rows="3"
                v-model="cancelReason"
              />
            </div>
            <div v-if="isCancelReason">
              <div
                class="error"
                v-if="!this.$v.cancelReason.required"
              >
                This field is required
              </div>
            </div>
          </div>
        </div>

        <div class="row text-right">
          <div class="col-md-8 pt-3">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="cancelBillLoader"
            />
          </div>
          <div class="col-md-4">
            <button
              class="btn btn-default btn-fill float-right custom-btn"
              @click="closeModal()"
            >
              Close
            </button>
            <button
              class="btn btn-danger btn-fill float-right mr-2 custom-btn"
              @click="cancelBill()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div
        slot="body"
        v-if="showLogs"
      >
        <div class="row">
          <div
            class="col-12 text-center"
            v-if="transactionLoader"
          >
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
          <card
            class="col-md-12"
            v-if="!transactionLoader"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">
                    #
                  </th>
                  <th scope="col">
                    Main Message
                  </th>
                  <th scope="col">
                    Transaction Date
                  </th>
                  <th scope="col">
                    Specific Message
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list , index) in billTransactions" :key="index">
                  <th scope="row">
                    {{ index + 1 }}
                  </th>
                  <td>{{ list.trxntype }}</td>
                  <td>{{ dateTimeToHuman(list.transSaveDate) }}</td>
                  <td>
                    <table>
                      <tr>
                        <td>Code</td>
                        <td>Message</td>
                      </tr>
                      <tr v-for="(list2 , index2) in list.transactionStatuses" :key="index2">
                        <td>{{ list2.statusCode }}</td>
                        <td>{{ list2.description }}</td>
                      </tr>
                    </table>
                  </td>
                  <td>{{ list.description }}</td>
                </tr>
              </tbody>
            </table>
          </card>
        </div>
        <hr>
        <div class="row text-right">
          <div class="col-md-12">
            <button
              class="btn btn-default btn-fill float-right"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </modal>
    <small-modal v-if="openExtendedDays">
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <p>
              You are about to extend GEPG control number validity period of the bill, are you sure?
            </p>
          </div>
        </div>
        <hr>
        <div
          class="row"
          v-if="extendedDaysError"
        >
          <div class="col-md-12 text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12 text-center">
            <button
              type="submit"
              class="btn btn-primary mr-2 custom-btn"
              data-dismiss="modal"
              @click="extendExpireTime"
            >
              Accept
            </button>

            <button
              type="submit"
              class="btn btn-default btn-fill mr-2 custom-btn"
              data-dismiss="modal"
              @click="closeModal"
            >
              decline
            </button>
          </div>
        </div>
      </div>
    </small-modal>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Please wait while system is requesting GePG control number
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader :loading="loading" :color="color" :size="size" />
          </div>
        </div>
      </div>
    </global-modal>
    <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer" @downloadPdf="downloadPdf"></pdf-viewer>
  </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import API_REVENUE from '../../../../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../../components/globalLoader'
import Modal from '../../../../components/Inputs/Modal'
import SmallModal from '../../../../components/Inputs/SmallModal'
  import PdfViewer from '../../../../components/pdfViewer.vue'
import { mapActions, mapGetters } from 'vuex';
// import CfaAttachments from './cfaAttachments';

export default {

  name: 'Application',

  components: {
    // attachments: CfaAttachments,

    PulseLoader,
    Modal,
    GlobalModal,
    SmallModal,
    PdfViewer

  },

  validations: {

    cancelReason: { required }

  },

  created () {

    this.finalUserPermissions = JSON.parse(
      localStorage.getItem('browser-local-permits')
    )

    if (this.$route.params.billId) {

      this.$store.dispatch('pagenames/currentPageName', 'Bill Details')

      this.requestId = this.$route.params.billId

      this.loadThisRequestBillOnCreated(this.$route.params.billId)

    }

  },

  computed: {

      ...mapGetters('global', [

        'getGlobalLoader'

      ])

    },

  data () {

    return {

      finalUserPermissions: [],

      color: 'green',
      size: '12px',
      loading: true,

      editBillItemLoader:false,
      removeBulkBillingLoader:false,
      requestId: '',
      singleRequest: {},
      labelText: '',
      SuccessAlert: false,
      ErrorAlert: false,
      myLoader: false,
      SuccessMessage: '',
      ErrorMessage: '',
      loadingBulkBillingItemId:0,
      selectedBillDescription:"",
      itemToRemoveList:[],
      thisFirstRequestLoader: false,
      applicationStatus: '',
      additionalCargo: '',
      approveBillLoader: false,

      globalLoader: false,
      globalLoaderName: '',

      showModal: false,
      showModalBillDescription: false,
      cancelBillModal: false,
      cancelCharge: true,
      selectedBillId: '',
      customerNameForCancel: '',
      isCancelReason: false,
      cancelReason: '',
      cancelBillLoader: false,
      relativeControlNumberForCancel: '',
      cancelledMessage: '',
      selectedBillIdToEditComment:"",
      myBillId: '',
      myControlNo: '',
      openExtendedDays: false,
      extendedDays: '',
      todayDate: new Date().toJSON().slice(0, 10),
      extendedDaysError: false,

      billTransactions: [],
      showLogs: false,
      transactionLoader: false,
      bulkBillItems:[],
      scr: '',
      showPdfViewer: false,
      isMinioFile: 0,

    }

  },

  methods: {

    ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    loadParameters (fileUrl, fileName) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    downloadPdf () {

      if(this.isMinioFile === 1) { // MINIO

        this.openPdfViewer(this.singleRequest.assessmentNoticeDto.assessmentNoticeDocUrl, 'ASSESSMENT_FOR_APPLICATION_TANSAD_NUMBER_' + this.singleRequest.tansadno)

      } else if (this.isMinioFile === 2) { // MATRIX INVOICE

        this.openMatrixInvoicePdfViewer(this.singleRequest.billId)

      } else if (this.isMinioFile === 3) { // INVOICE

        this.openInvoicePdfViewer(this.singleRequest.billId)

      }  else { // RECEIPT

        this.openReceiptPdfViewer(this.singleRequest.billId)

      }

    },

    openPdfViewer (fileUrl, fileName, action) {

        this.isMinioFile = 1

        this.getFileFromMinio(fileUrl, fileName, action).then(() => {

            this.showPdfViewer = true

        }).catch(() => {})

    },

    closePdfViewer () {

        this.showPdfViewer = false
        this.isMinioFile = 0
        this.scr = ''

    },

    getFileFromMinio (fileUrl, fileName, action) {

        return new Promise((resolve, reject) => {

            this.loadParameters(fileUrl, fileName).then(() => {

            this.loadFileFromMinio({action}).then((res) => {

                if(res[1].status === 204) {

                    this.loadNotification('info', 4000, '',
                    'File not found, please contact system admin',
                    'response', 1000 , true  , true)

                } else {

                    this.loadNotification('success', 4000, '',
                    'File has been successfully loaded, please have a look at it',
                    'response', 1000 , true  , true)
                    this.scr = res[0]
                    resolve()


                }

                    this.globalLoader = this.getGlobalLoader

                }).catch((error) => {

                this.loadNotification('error', 4000, '',
                    'File not loaded, please contact system admin',
                    'response', 1000 , true  , true)

                this.globalLoader = false

                })

                this.globalLoader = this.getGlobalLoader

            }).catch(() => {

                this.globalLoader = false

            })

        })

    },

    // getFileFromMinio (fileUrl, fileName) {

    //   this.loadParameters(fileUrl, fileName).then(() => {

    //   this.loadFileFromMinio({}).then((res) => {

    //       if(res.status === 204) {

    //           this.loadNotification('warn', 4000, 'File Download',
    //           'File not found, please contact system admin',
    //           'response', 1000 , true  , true)

    //       } else {

    //         this.loadNotification('success', 4000, 'File Download',
    //           'File has been successfully downloaded, please have a look at it',
    //           'response', 1000 , true  , true)


    //       }

    //         this.globalLoader = this.getGlobalLoader

    //     }).catch(() => {

    //       this.loadNotification('error', 4000, 'File Download',
    //           'File not downloaded, please check your internet connection',
    //           'response', 1000 , true  , true)

    //       this.globalLoader = false

    //     })

    //     this.globalLoader = this.getGlobalLoader

    //   }).catch(() => {

    //   })

    // },

    selecteCommentForbill(bill){

      this.selectedBillDescription=bill.description;
      this.selectedBillIdToEditComment=bill.bulkSubItemId;
      this.showModalBillDescription=true;

    },

    editBulkBillSubDescription(){

      this.editBillItemLoader=true;

      API_REVENUE.editBulkBillItemDescription(this.selectedBillIdToEditComment,this.selectedBillDescription).then((resp)=>{

        this.editBillItemLoader=false;

        if(resp.data.status){

          this.loadBulkBillItems();
          this.closeModal();
          this.loadNotification('success', 4000, 'Request Message',
            'Bulk Bill Item Edited Successfully',
            'response', 1000 , true  , true);

        }else {

          this.loadNotification('error', 4000, 'Request Message',
            `${resp.data.message}, please contact system Administrator`,
            'response', 1000 , true  , true);

        }

      }).catch(() => {

        this.editBillItemLoader=true;
        this.editBillItemLoader=false;

        this.loadNotification('error', 4000, 'Request Message',
          'Item failed to be edited, please contact system Administrator',
          'response', 1000 , true  , true);

      })

    },

    addItemToRemove(itemId) {

      this.itemToRemoveList.push(itemId);

    },

    removeItemToRemove(bulkBillId) {

      let index = this.itemToRemoveList.indexOf(bulkBillId);

      if(index !== -1){

        this.itemToRemoveList.splice(index, 1);

      }

    },


    removeBulkItem(){

      this.removeBulkBillingLoader = true;

      API_REVENUE.removeItemFromBulkBill(this.singleRequest.billId,this.itemToRemoveList).then((resp) => {
        this.removeBulkBillingLoader=false;
        this.itemToRemoveList=[];
        if(resp.data.status){
          this.loadBulkBillItems();
          this.loadNotification('success', 4000, 'Request Message',
            'Item Successfully removed',
            'response', 1000 , true  , true);
        }else {
          this.loadNotification('error', 4000, 'Request Message',
            `${resp.data.message}, please contact system Administrator`,
            'response', 1000 , true  , true);
        }


      }).catch(()=>{
        this.removeBulkBillingLoader=false;
        this.loadNotification('error', 4000, 'Request Message',
          'Item failed to be removed, please contact system Administrator',
          'response', 1000 , true  , true);
      });


    },

    loadThisRequestBillOnCreated (data) {

        this.thisFirstRequestLoader = true

        API_REVENUE.getIndividualBill(data).then(
          response => {

            if (response.data.status) {

              this.singleRequest = response.data.data
              if(this.singleRequest.assessmentNoticeDto !== null && Object.keys(this.singleRequest.assessmentNoticeDto).length > 0) {

                if(this.singleRequest.assessmentNoticeDto.tariffItemUnit === 'FEU' || this.singleRequest.assessmentNoticeDto.tariffItemUnit === 'TEU' || this.singleRequest.assessmentNoticeDto.tariffItemUnit === 'Containerized Consignment') {

                  this.labelText = 'Container Quantity'

                } else {

                  this.labelText = 'Unit'

                }

              }
              this.applicationStatus = this.getApplicationStatus(this.singleRequest.status, 1)
              this.thisFirstRequestLoader = false
              this.cancelledMessage = this.singleRequest.description
              this.singleRequest.billItems[0].additionalItems.length > 0 ? this.additionalCargo = this.singleRequest.billItems[0].additionalItems[0].description : ''

            } else {

            }

          }

        ).catch(() => {

          this.thisFirstRequestLoader = false

        })

    },

    approveBill (billId, name) {

      this.$dialog.confirm('You are about to approve bill for customer [ ' + name + ' ], Are you sure!.', {
        loader: true

      }).then((dialog) => {

        this.approveBillLoader = true;
        this.myLoader = true;

        API_REVENUE.approveCFABill(billId).then((response) => {
          if (response.data.status) {

            this.approveBillLoader = false
            this.myLoader = false;
            this.scrollToTop();
            this.loadThisRequestBillOnCreated(this.$route.params.billId)
            this.loadNotification('success', 4000, 'Request Message',
              'Bill Successfully approved and sent to GePG',
              'response', 1000 , true  , true);

          } else {

            this.myLoader = false
            this.approveBillLoader = false
            this.scrollToTop()
            this.ErrorMessage = ', Bill not approved, please contact system Administrator';
            this.loadNotification('error', 4000, 'Error',
              response.data.message,
              'response', 1000 , true  , true);

          }
        }).catch(() => {

          this.myLoader = false;
          this.approveBillLoader = false;
          this.scrollToTop();
          this.loadNotification('error', 4000, 'Request Message',
            'Bill not approved, please contact system Administrator',
            'response', 1000 , true  , true);

        });

        dialog.close()
      })

    },

    openMatrixInvoicePdfViewer (billId, action) {

        this.isMinioFile = 2

        this.getInvoiceForMatrixPrinter(billId, action).then(() => {

            this.showPdfViewer = true

        }).catch(() => {})

    },

    getInvoiceForMatrixPrinter (billId, action = 'download') {

        return new Promise((resolve, reject) => {

            this.globalLoader = true
            this.globalLoaderName = 'Invoice'

            API_REVENUE.downloadMatrixBillInvoice(billId).then(
                response => {

                if (response.status === 200) {

                    if(action === 'download') {

                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', 'INVOICE_FOR_APPLICATION_WITH_TANSAD_NUMBER_' + this.singleRequest.tansadno + '.pdf') // or any other extension
                        document.body.appendChild(link)
                        link.click()
                        this.globalLoader = false
                        this.loadNotification('success', 4000, 'Request Message',
                        'Matrix printer Invoice has been successifully downloaded, please have a look at it',
                        'response', 1000 , true  , true);

                    } else {

                        this.scr = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                        this.globalLoader = false

                    }

                    resolve()

                } else {

                    this.globalLoader = false
                    this.loadNotification('error', 4000, 'Request Message',
                    'No content found for requested matrix printer invoice, please try with different options',
                    'response', 1000 , true  , true);

                }

                }).catch(() => {

                this.globalLoader = false
                this.loadNotification('error', 4000, 'Request Message',
                'No content found for requested matrix printer invoice, please try with different options',
                'response', 1000 , true  , true);

            })

        })

    },

    // getInvoiceForMatrixPrinter (billId) {

    //   this.globalLoader = true
    //   this.globalLoaderName = 'Invoice'

    //   API_REVENUE.downloadMatrixBillInvoice(billId).then(
    //     response => {

    //       if (response.status === 200) {

    //         const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
    //         const link = document.createElement('a')
    //         link.href = url
    //         link.setAttribute('download', 'invoice.pdf') // or any other extension
    //         document.body.appendChild(link)
    //         link.click()
    //         this.globalLoader = false
    //         this.loadNotification('success', 4000, 'Request Message',
    //           'Invoice has been successifully downloaded, please have a look at it',
    //           'response', 1000 , true  , true);

    //       } else {

    //         this.globalLoader = false
    //         this.loadNotification('error', 4000, 'Request Message',
    //           'No content found for requested invoice, please try with different options',
    //           'response', 1000 , true  , true);

    //       }

    //     }).catch(() => {

    //     this.loadNotification('error', 4000, 'Request Message',
    //       'No content found for requested invoice, please try with different options',
    //       'response', 1000 , true  , true);
    //     this.globalLoader = false

    //   })
    // },

    openInvoicePdfViewer (billId, action) {

        this.isMinioFile = 3

        this.getInvoice(billId, action).then(() => {

            this.showPdfViewer = true

        }).catch(() => {})

    },

    getInvoice (billId, action = 'download') {

        return new Promise((resolve, reject) => {

            this.globalLoader = true
            this.globalLoaderName = 'Invoice'

            API_REVENUE.downloadBillInvoice(billId).then(
                response => {

                if (response.status === 200) {

                    if(action === 'download') {

                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', 'INVOICE_FOR_APPLICATION_WITH_TANSAD_NUMBER_' + this.singleRequest.tansadno + '.pdf') // or any other extension
                        document.body.appendChild(link)
                        link.click()
                        this.globalLoader = false
                        this.loadNotification('success', 4000, 'Request Message',
                        'Matrix printer Invoice has been successifully downloaded, please have a look at it',
                        'response', 1000 , true  , true);

                    } else {

                        this.scr = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                        this.globalLoader = false

                    }

                    resolve()

                } else {

                    this.globalLoader = false
                    this.loadNotification('error', 4000, 'Request Message',
                    'No content found for requested matrix printer invoice, please try with different options',
                    'response', 1000 , true  , true);

                }

                }).catch(() => {

                this.globalLoader = false
                this.loadNotification('error', 4000, 'Request Message',
                'No content found for requested matrix printer invoice, please try with different options',
                'response', 1000 , true  , true);

            })

        })

    },

    // getInvoice (billId) {

    //   return new Promise((resolve, reject) => {

    //       this.globalLoader = true
    //       this.globalLoaderName = 'Invoice'

    //       API_REVENUE.downloadBillInvoice(billId).then(
    //         response => {

    //           if (response.status === 200) {

    //             const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
    //             const link = document.createElement('a')
    //             link.href = url
    //             link.setAttribute('download', 'invoice.pdf') // or any other extension
    //             document.body.appendChild(link)
    //             link.click()
    //             this.globalLoader = false
    //             this.loadNotification('success', 4000, 'Request Message',
    //               'Matrix printer Invoice has been successifully downloaded, please have a look at it',
    //               'response', 1000 , true  , true);

    //           } else {

    //             this.globalLoader = false
    //             this.loadNotification('error', 4000, 'Request Message',
    //               'No content found for requested matrix printer invoice, please try with different options',
    //               'response', 1000 , true  , true);

    //           }

    //           resolve()

    //         }).catch(() => {

    //         this.globalLoader = false
    //         this.loadNotification('error', 4000, 'Request Message',
    //           'No content found for requested matrix printer invoice, please try with different options',
    //           'response', 1000 , true  , true);

    //       })

    //   })

    // },

    openReceiptPdfViewer (billId, action) {

        this.isMinioFile = 4

        this.getReceipt(billId, action).then(() => {

            this.showPdfViewer = true

        }).catch(() => {})

    },

    getReceipt (billId, action = 'download') {

        return new Promise((resolve, reject) => {

            this.globalLoader = true
            this.globalLoaderName = 'Receipt'

            API_REVENUE.getReceipt(billId).then(
                response => {

                    if(action === 'download') {

                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', 'RECEIPT_FOR_APPLICATION_WITH_TANSAD_NUMBER_' + this.singleRequest.tansadno + '.pdf') // or any other extension
                        document.body.appendChild(link)
                        link.click()
                        this.myloader = false
                        this.globalLoader = false

                    } else {

                        this.scr = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                        this.globalLoader = false

                    }

                    resolve()

                }
            ).catch(e => {

                this.myloader = false
                this.globalLoader = false
                this.loadNotification('error', 4000, 'Request Message',
                'No content found for requested receipt, please try with different options',
                'response', 1000 , true  , true);

            })

        })

    },

    // getReceipt (billId) {
    //   this.globalLoader = true
    //   this.globalLoaderName = 'Receipt'

    //   API_REVENUE.getReceipt(billId).then(
    //     response => {
    //       const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
    //       const link = document.createElement('a')
    //       link.href = url
    //       link.setAttribute('download', 'receipt.pdf') // or any other extension
    //       document.body.appendChild(link)
    //       link.click()
    //       this.myloader = false
    //       this.globalLoader = false
    //     }
    //   ).catch(e => {
    //     this.ErrorMessage = e.message
    //     this.SuccessAlert = false
    //     this.ErrorAlert = true
    //     this.SuccessMessage = ''
    //     this.SuccessAlert = false
    //     this.myloader = false
    //     this.globalLoader = false
    //   })
    // },

    cancelBill () {

      if (this.$v.cancelReason.$invalid) {

        this.isCancelReason = true

      } else {

        this.$dialog.confirm('You are about to cancel CFA bill for customer ' +
                        '[ ' + this.customerNameForCancel + ' ], Are you sure!.', {
          loader: true
        }).then((dialog) => {

          this.myLoader = true;

          this.cancelBillLoader = true

          API_REVENUE.cancelCfaBill(this.selectedBillId, this.cancelReason).then((response) => {

            if (response.data.status) {

              this.cancelBillLoader = false
              this.cancelReason = ''
              this.selectedBillId = ''
              this.relativeControlNumberForCancel = ''
              this.closeModal()
              this.loadThisRequestBillOnCreated(this.$route.params.billId)
              this.myLoader = false
              this.loadNotification('success', 4000, 'Request Message',
                'Bill has been successifully Cancelled',
                'response', 1000 , true  , true);

            } else {

              this.cancelBillLoader = false
              this.myLoader = false
              this.loadNotification('error', 4000, 'Request Message',
                'Bill not cancelled, please contact system administrator',
                'response', 1000 , true  , true);

            }
          }).catch(() => {

            this.cancelBillLoader = false
            this.myLoader = false
            this.loadNotification('error', 4000, 'Request Message',
              'Bill not cancelled, please contact system administrator',
              'response', 1000 , true  , true);

          })

          dialog.close()
        }).catch(() => {
          this.cancelBillLoader = false
          this.SuccessAlert = false
        })
      }
    },

    setExtendDaysParams (billId, controlNo) {
      this.myBillId = billId
      this.myControlNo = controlNo
      this.openExtendedDays = true
      this.showModal = false
    },

    extendExpireTime () {
      this.extendedDaysError = true

      this.extendedDays = 45

      API_REVENUE.extendBillExpireTime(this.myBillId, this.extendedDays).then(
        response => {

          if (response.data.status) {

            this.extendedDaysError = false
            this.myBillId = ''
            this.myControlNo = ''
            this.extendedDays = ''
            this.openExtendedDays = false
            this.loadThisRequestBillOnCreated(this.$route.params.billId)
            this.loadNotification('success', 4000, 'Request Message',
              'Bill Expire Time Extension for control number [ ' + this.myControlNo + ' ] has been sent',
              'response', 1000 , true  , true);

          } else {

            this.openExtendedDays = false
            this.myBillId = ''
            this.myControlNo = ''
            this.extendedDays = ''
            this.extendedDaysError = false
            this.loadNotification('error', 4000, 'Request Message',
              'GEPG control number [ ' + this.myControlNo + ' ] validity period of this bill not extended',
              'response', 1000 , true  , true);

          }
        }
      ).catch(() => {

        this.openExtendedDays = false
        this.showModal = false
        this.myBillId = ''
        this.myControlNo = ''
        this.extendedDays = ''
        this.extendedDaysError = false
        this.loadNotification('error', 4000, 'Request Message',
          'GEPG control number [ ' + this.myControlNo + ' ] validity period of this bill not extended',
          'response', 1000 , true  , true);
      })
    },

    resubmitBill (billId, name) {

      this.$dialog.confirm('You are about to resubmit bill for <br/> customer [ ' + name + ' ] to GEPG, Are you sure!.', {
        loader: true,
        html: true

      }).then((dialog) => {

        this.myLoader = true;

        API_REVENUE.resubmitCFABill(billId).then((response) => {

          if (response.data.status) {

            this.loadThisRequestBillOnCreated(this.$route.params.billId)
            this.myLoader = false
            this.loadNotification('success', 4000, 'Request Message',
              'Bill has been successifully resubmitted to GEPG',
              'response', 1000 , true  , true);

          } else {

            this.myLoader = false
            this.loadNotification('error', 4000, 'Request Message',
              'Bill not resubmitted to GEPG',
              'response', 1000 , true  , true);

          }
        }).catch(() => {

          this.myLoader = false
          this.loadNotification('error', 4000, 'Request Message',
            'Bill not resubmitted to GEPG',
            'response', 1000 , true  , true);
        })

        dialog.close()
      })

    },

    fiscaliseBill (billId, name) {
      this.$dialog.confirm('You are about to send Bill Details To TRA for <br/> Customer [ ' + name + ' ], Are you sure!.', {
        loader: true,
        html: true

      }).then((dialog) => {

        this.myLoader = true;

        API_REVENUE.fiscalize(billId).then((response) => {

          if (response.data.status) {

            this.loadThisRequestBillOnCreated(this.$route.params.billId)
            this.myLoader = false
            this.loadNotification('success', 4000, 'Request Message',
              'Bill details has been successifully sent to TRA',
              'response', 1000 , true  , true);

          } else {

            this.myLoader = false
            this.loadNotification('error', 4000, 'Request Message',
              'Bill details not sent to TRA',
              'response', 1000 , true  , true);

          }
        }).catch(() => {

          this.myLoader = false
          this.loadNotification('error', 4000, 'Request Message',
            'Bill details not sent to TRA',
            'response', 1000 , true  , true);

        })

        dialog.close()
      })
    },

    getBillLogs (billId) {
      this.transactionLoader = true

      API_REVENUE.getBillTransactions(billId).then((response) => {
        this.transactionLoader = false
        this.billTransactions = response.data.data
      }).catch(() => {
        this.transactionLoader = false
        this.ErrorMessage = ' Error, please contact system administrator'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
      })
    },

    openModal (requestData = null, actionButton) {
      if (actionButton === 'CANCEL_BILL') {
        this.cancelBillModal = true
        this.cancelCharge = true
        this.showModal = true
        this.showLogs = false
        this.selectedBillId = requestData.billId
        this.customerNameForCancel = requestData.company.name
        this.relativeControlNumberForCancel = requestData.billNo;
      }

      if (actionButton === 'SHOW_LOGS') {
        this.showLogs = true
        this.showModal = true
        this.cancelBillModal = false
        this.getBillLogs(requestData.billId)
      }
    },

    closeModal () {
      this.showModal = false
      this.cancelBillModal = false
      this.openExtendedDays = false
      this.extendedDaysError = false
      this.billTransactions = []
      this.showLogs = false;
      this.showModalBillDescription=false;
    },

    goBack () {

      if (window.$cookies.isKey('browser-local-cashed-url-back-2')) {
        this.$router.push(window.$cookies.get('browser-local-cashed-url-back-2'))

        window.$cookies.remove('browser-local-cashed-url-back-2')
      }
    },

    goToApplication (id) {

        document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
        this.$router.push({name: 'CustomerApplicationDetails', params: {requestId: id}})

    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000)
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    }

  }

}
</script>

<style scoped>

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .bill-data-550 {
        width: 550px;
    }

    .bill-data-265 {
        max-width: 265px;
    }

    .bill-data-269 {
        width: 276px;
    }

    .notice-table-label {
        width: 250px;
        background-color: #99badd;
        font-weight: normal;
    }

    .div-table-row {
        padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }

    .custom-table, .custom-table th, .custom-table td {
        border: 1px solid #DCDCDC;
        border-collapse: collapse;
    }

    .custom-table th, .custom-table td {
        padding: 8px;
        text-align: left;
    }

    .cancel-charge:hover {
        cursor: pointer !important;
    }

    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    .text-link {
        color: blue;
    }

    .text-link:hover {
        color: #d59a18;
        cursor: pointer;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    .even-color {
        background-color: #f5f5f5;
    }

    .odd-color {
        background-color: #f0f8ff;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }

    .custom-btn-in-table {
        /* margin-top: 2px !important; */
        /* margin-bottom: 2px !important; */
        padding: 1px 6px 2px 6px !important;
        font-size: 11px !important;
    }

    .btn-black:hover {
        border-color: #000000;
        color: #000000;
        background-color: #ffffff;
    }

    .btn-black {
        border-color: #808080;
        color: #808080;
    }

    .btn-black-fill {
        background-color: #808080;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-purple:hover {
        border-color: #800080;
        color: #800080;
        background-color: #ffffff;
    }

    .btn-purple {
        border-color: #9932cc;
        color: #9932cc;
    }

    .btn-purple-fill {
        background-color: #9932cc;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-blue:hover {
        border-color: #0000ff;
        color: #0000ff;
        background-color: #ffffff;
    }

    .btn-blue {
        border-color: #4169e1;
        color: #4169e1;
    }

    .btn-blue-fill {
        background-color: #4169e1;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-yellow:hover {
        border-color: #8b4513;
        color: #8b4513;
        background-color: #ffffff;
    }

    .btn-yellow {
        border-color: #a0522d;
        color: #a0522d;
    }

    .btn-yellow-fill {
        background-color: #a0522d;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-olive:hover {
        border-color: #4B0082;
        color: #4B0082;
        background-color: #ffffff;
    }

    .btn-olive {
        border-color: #6A5ACD;
        color: #6A5ACD;
    }

    .btn-olive-fill {
        background-color: #6A5ACD;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-linen:hover {
        border-color: #D2691E;
        color: #D2691E;
        background-color: #ffffff;
    }

    .btn-linen {
        border-color: rgba(210, 105, 30, .5);
        color: rgba(210, 105, 30, .5);
    }

    .btn-linen-fill {
        background-color: rgb(210, 105, 30);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-dark-blue-like:hover {
        border-color:  #154360 ;
        color:  #154360 ;
        background-color: #ffffff;
    }

    .btn-dark-blue-like {
        border-color: rgba( 21, 67, 96 , .5);
        color: rgba( 21, 67, 96 , .5);
    }

    .btn-dark-blue-like-fill {
        background-color: rgb( 21, 67, 96 );
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-danger-custom:hover {
        border-color: #ff0000;
        color: #ff0000;
        background-color: #ffffff;
    }

    .btn-danger-custom {
        border-color: rgba(255, 0, 0, .5);
        color: rgba(255, 0, 0, .5);
    }

    .btn-danger-custom-fill {
        background-color: rgba(255, 0, 0, .5);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-success-custom:hover {
        border-color: #008000;
        color: #008000;
        background-color: #ffffff;
    }

    .btn-success-custom {
        border-color: rgba(0, 128, 0, .5);
        color: rgba(0, 128, 0, .5);
    }

    .btn-success-custom-fill {
        background-color: rgba(0, 128, 0, .8);
        opacity: 1;
        color: #FFFFFF;
    }

    .cancel-message:hover {
        cursor: pointer;
    }

    .tooltip-text {
        visibility: hidden;
        width: 100%;
        background-color: black;
        color: #fff;
        border-radius: 6px;
        padding: 5px 2px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
    }

    .tooltip-text::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

    .tooltip-pipe:hover .tooltip-text {
        visibility: visible;
        cursor: help;
    }

    .alert-success {
        padding-top:10px;
        padding-bottom: 10px;
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    .alert-danger {
        padding-top:10px;
        padding-bottom: 10px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    .bulk-bill-scroll {
        max-height: 618px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

</style>
